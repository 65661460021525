import { ValueType } from 'interfaces/valueType.interface'

import { ApprovalsModel } from 'config/common/baseModel/Approvals'
import { IDTreatmentPremiumDealsModel } from 'config/common/baseModel/ida360/IDPremiumDeals'
import { IDPremiumRevenueModel } from 'config/common/baseModel/ida360/IDPremiumRevenue'

import {
  compose,
  renameColumn,
  updateColumnAttributes,
  whiteListColumn,
} from 'utils/overrideModel'

export const RSVCommercialRevenueModel = compose<typeof IDPremiumRevenueModel>(
  whiteListColumn([
    'organisations',
    'financialYear',
    'quarter',
    'candidates',
    'designationA',
    'designationB',
    'valueUsd',
    'areas',
    'sources',
  ]),
  updateColumnAttributes('quarter', {
    width: 100,
    sortOrderObject: ['Q4', 'Q3', 'Q2', 'Q1'],
  }),
  updateColumnAttributes('financialYear', {
    width: 130,
  }),
  updateColumnAttributes('designationB', {
    type: ValueType.MULTI,
  }),
  updateColumnAttributes('areas', {
    type: ValueType.MULTI,
  })
)(IDPremiumRevenueModel)

export const RSVCommercialApprovalModel = compose<typeof ApprovalsModel>(
  whiteListColumn([
    'area',
    'regulatoryOrganisation',
    'candidate',
    'designationA',
    'designationB',
    'sponsors',
    'approvalDate',
    'targetPopulations',
    'internalNotes',
    'associatedDocumentUrl',
  ]),
  renameColumn('sponsors', 'Company'),
  renameColumn('area', 'Country or Region'),
  renameColumn('targetPopulations', 'Target Population'),
  renameColumn('internalNotes', 'Notes'),
  renameColumn('associatedDocumentUrl', 'Source'),
  updateColumnAttributes('designationA', {
    width: 200,
  }),
  updateColumnAttributes('designationB', {
    width: 200,
  }),
  updateColumnAttributes('targetPopulations', {
    width: 300,
  }),
  updateColumnAttributes('internalNotes', {
    width: 350,
  })
)(ApprovalsModel)

export const RSVCommercialSupplyPriceModel = compose<
  typeof IDTreatmentPremiumDealsModel
>(
  whiteListColumn([
    'candidates',
    'designationA',
    'designationB',
    'suppliers',
    'pricePerUnitUsd',
    'quantityUnit',
    'yearDelivered',
    'recipients',
    'recipientRegion',
    'recipientIncomeBand',
    'categoryDetails',
    'market',
    'pricePerUnitMethodologyType',
    'pricePerUnitMethodologyDescription',
    'sources',
  ]),
  renameColumn('candidates', 'Candidate'),
  renameColumn('designationA', 'Vaccine or Non-Vaccine'),
  renameColumn('suppliers', 'Company'),
  renameColumn('designationB', 'Candidate Category'),
  renameColumn('categoryDetails', 'Deal Category'),
  renameColumn('quantityUnit', 'Unit'),
  updateColumnAttributes('pricePerUnitMethodologyDescription', {
    type: ValueType.LONG,
    width: 400,
  })
)(IDTreatmentPremiumDealsModel)
