import { IApp } from 'interfaces/navigationApp.interface'

import { AccessGroups } from 'enums/AccessGroups'

import { TreatmentsCountriesModel } from '../Therapeutics/TreatmentsModels'
import { TreatmentsCustomPages } from './TreatmentsCustomPages'
import { TreatmentsMenu, TreatmentsSlugs } from './TreatmentsMenu'
import { TreatmentsPages } from './TreatmentsPages'

const treatments: IApp<TreatmentsSlugs> = {
  name: 'Therapeutics',
  slug: 'covid-19-therapeutics',
  commonIdentifier: 'therapeutics',
  accessGroups: [AccessGroups.Treatments],
  endpoint: 'covid-treatments',
  menu: TreatmentsMenu,
  pages: TreatmentsPages,
  customPages: TreatmentsCustomPages,
  baseModels: [TreatmentsCountriesModel],
  disableAnalytics: true,
  createdAt: new Date(2020, 7, 26),
}

export default treatments
