import { SortOrders } from 'enums/SortOrders'

import {
  CandidateRankingModel,
  CountriesModel,
  DealsModel,
  MediaModel,
  PressReleasesModel,
  TweetsModel,
} from 'config/common/baseModel'

import {
  addOrOverrideColumn,
  compose,
  renameColumn,
  whiteListColumn,
} from 'utils/overrideModel'

export const DiagnosticsCandidateModel = compose<typeof CandidateRankingModel>(
  whiteListColumn<typeof CandidateRankingModel>([
    'linkAfTrUniqueTreatmentNameToBnf',
    'companies',
    'luCompaniesCountry',
    'afTargetType',
    'afTargetSubtype',
    'afDetectionPrinciple',
    'afDetectionPrincipleSubtype',
    'afSpecimenCollection',
    'afSensitivityPercent',
    'afSpecificityPercent',
    'afRapid',
    'afPriority',
    'afSetting',
    'afTestTimeMinutes',
    'afEmergencyApproval',
    'afFilingForApproval',
    'approvalStatus',
    'diagnosticMachine',
    'limitOfDetection',
  ]),
  renameColumn('linkAfTrUniqueTreatmentNameToBnf', 'Test name'),
  addOrOverrideColumn(
    {
      ...CandidateRankingModel.schema.columns.find(
        (x) => x.key === 'companies'
      ),
      key: 'companies',
      label: 'Manufacturer',
      sticky: 'left',
    },
    2
  )
)({
  ...CandidateRankingModel,
  name: 'Diagnostics',
  defaultSortKey: 'afPriority',
  defaultSortOrder: SortOrders.ASC,
})

const BaseDiagnosticsDealsModel = compose<typeof DealsModel>(
  renameColumn('lookupVaccineName', 'Test Name'),
  renameColumn('afPricePerVaccineTreatment', 'Price per Test')
)(DealsModel)

export const DiagnosticsDealsModel = compose<typeof BaseDiagnosticsDealsModel>(
  whiteListColumn([
    'lookupVaccineName',
    'afDealType',
    'afPricePerVaccineTreatment',
    'countries',
    'companyRegions',
    'mediaUrls',
    'pressUrls',
  ])
)(BaseDiagnosticsDealsModel)
export const DiagnosticsSupplyDealsModel = compose<
  typeof BaseDiagnosticsDealsModel
>(
  whiteListColumn([
    'lookupVaccineName',
    'afDealType',
    'organisations',
    'countries',
    'companyRegions',
    'afSupplyNumber',
    'afPotentialExpansion',
    'afSupplyCertainty',
    'afPublicSupplyNotes',
    'mediaUrls',
    'pressUrls',
    'afCostPerSupplyDealInDollars',
    'afDateDealMade',
    'luIncomeBand',
    'afPurchaseType',
    'afDonorCountry',
    'luCountrySourceOfSupply',
  ])
)(BaseDiagnosticsDealsModel)

export const DiagnosticsProductionDealsModel = compose<
  typeof BaseDiagnosticsDealsModel
>(
  whiteListColumn([
    'lookupVaccineName',
    'afDealType',
    'organisations',
    'countries',
    'afExpectedProduction',
    'mediaUrls',
    'pressUrls',
    'afTypeOfProduction',
    'afExpectedProduction2022',
    'afDateExpectedProductionLastAnnounced',
    'afPublicExpectedProductionNotes',
  ])
)(BaseDiagnosticsDealsModel)

export const DiagnosticsCountriesModel = whiteListColumn<typeof CountriesModel>(
  [
    'name',
    'population',
    'luInfectionRatePer10000',
    'afEconomyIncomeBand',
    'dailyTestsPerThousand',
    'afTestingPolicy',
    'afContactTracing',
    'afRestrictionsOnInternalMovement',
    'afInternationalTravelControls',
    'afNewTests',
    'afTotalTests',
    'afTotalTestsPerThousand',
    'afNewTestsPerThousand',
    'afNewTestsSmoothed',
    'afPositiveRate',
    'afCountryDeepDive',
    'afPolicy',
    'afPolicyLink',
  ]
)(CountriesModel)

export const DiagnosticsPressReleasesModel = compose<typeof PressReleasesModel>(
  renameColumn('vaccine', 'Candidates'),
  whiteListColumn([
    'title',
    'datePublished',
    'afTopic',
    'sourceUrl',
    'vaccine',
    'description',
    'language',
  ])
)(PressReleasesModel)

export const DiagnosticsMediaModel = compose<typeof MediaModel>(
  whiteListColumn([
    'title',
    'source',
    'afTopic',
    'datePublished',
    'link',
    'vaccine',
    'name',
    'description',
    'language',
  ])
)(MediaModel)

export const DiagnosticsTweetsModel = compose<typeof TweetsModel>(
  renameColumn('lookupVaccineFromCandidateList', 'Candidates'),
  whiteListColumn([
    'createdAt',
    'lookupScreenNameFromTwitterUsers',
    'lookupSpecialtiesFromTwitterUsers',
    'fullText',
    'lookupVaccineFromCandidateList',
    'afTweetType',
    'keywordsClean',
  ])
)(TweetsModel)
