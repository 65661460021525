/* eslint no-useless-escape: 0 */ // --> OFF
export function removeMarkdown(markdown: string) {
  return (
    markdown
      // Remove code blocks
      .replace(/```[\s\S]*?```/g, '')
      // Remove inline code
      // .replace(/`[^`]*`/g, '')
      // Remove images
      .replace(/!\[[^\]]*\]\([^\)]*\)/g, '')
      // Remove links
      .replace(/\[([^\]]*)\]\([^\)]*\)/g, '$1')
      // Remove bold and italic (underscore)
      .replace(/(\*\*|__)(.*?)\1/g, '$2')
      // Remove bold and italic (asterisk)
      .replace(/(\*|_)(.*?)\1/g, '$2')
      // Remove strikethrough
      .replace(/~~(.*?)~~/g, '$1')
      // Remove blockquotes
      .replace(/^\s{0,3}>\s?/gm, '')
      // Remove headings
      .replace(/^\#{1,6}\s?/gm, '')
      // Remove horizontal rules
      // .replace(/^(-\s*?|\*\s*?|_\s*?){3,}\s*$/gm, '')
      // Remove list items
      .replace(/^\s*[\*\+\-]\s+/gm, '')
      // Remove numbered lists
      // .replace(/^\s*\d+\.\s+/gm, '')
      // Remove remaining HTML tags
      .replace(/<\/?[^>]+(>|$)/g, '')
      // Remove remaining Markdown (e.g., extra asterisks or underscores)
      .replace(/([*_]{1,3})(\S.*?\S?)\1/g, '$2')
      // Remove any remaining extra spaces or newlines
      // .replace(/\n{2,}/g, '\n')
      .trim()
  )
}
