import { Box } from '@chakra-ui/react'

import formatNumber from 'utils/formatNumber'

interface ProgressBarProps {
  value: number
  label: string
  total: number
  color?: any
}

export const ProgressBar = (props: ProgressBarProps) => {
  const { total, label, value } = props

  const percentage = (value / total) * 100

  return (
    <Box width='100%' mb={4}>
      <Box
        display='flex'
        alignItems='center'
        lineHeight='none'
        mb={2}
        fontSize='sm'
      >
        <Box fontWeight={500} color='legacy-primary.500'>
          {label}
        </Box>
        <Box fontWeight={500} ml='auto' color='legacy-primary.500'>
          {value ? formatNumber(value) : 0}
        </Box>
      </Box>
      <Box bg={`blue.100`} rounded='2px' width='100%' overflow='hidden'>
        <Box
          width={percentage + '%'}
          bg={'blue.500'}
          color='white'
          lineHeight='none'
          rounded='full'
          fontSize='sm'
          fontWeight={600}
          height='8px'
        ></Box>
      </Box>
    </Box>
  )
}
