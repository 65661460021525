import { Box } from '@chakra-ui/react'

import Dashboard from 'routes/dashboard'

import {
  Panel,
  PanelBody,
  PanelHeader,
  PanelTitle,
  TableauEmbed,
} from 'components'

import { ISingleCustomPage } from 'interfaces/navigationPage.interface'

import { getMediaDefaultWidget } from 'config/common/baseModel/Media'
import { getPresentationDefaultWidget } from 'config/common/baseModel/Presentations'
import { getPressReleasesDefaultWidget } from 'config/common/baseModel/PressReleases'
import { getTweetsDefaultWidget } from 'config/common/baseModel/Tweets'

import { DiagnosticsSlugs } from './DiagnosticsMenu'
import { DiagnosticsPages } from './DiagnosticsPages'

const DashboardPage: ISingleCustomPage<DiagnosticsSlugs> = {
  path: '',
  component: () => (
    <Dashboard
      widgetTabs={[
        getMediaDefaultWidget({ view: DiagnosticsPages['Media'].views[0] }),
        getPresentationDefaultWidget({
          view: { name: 'Default', airtableName: 'api_diagnostics' },
        }),
        getPressReleasesDefaultWidget({
          view: DiagnosticsPages['PressReleases'].views[0],
        }),
        getTweetsDefaultWidget({
          view: DiagnosticsPages['Tweets'].views[0],
        }),
      ]}
    />
  ),
}
const TestingCapacityPage: ISingleCustomPage<DiagnosticsSlugs> = {
  path: 'testing-capacity',
  component: () => (
    <Box py={3} overflowX='hidden' overflowY='scroll' h='100%'>
      <Panel overflow='hidden' height='100%'>
        <PanelHeader>
          <Box display='flex' flexDirection='row' alignItems='center'>
            <PanelTitle>
              Test per 1000 people, test per case, positivity rate, and more,
              per country.
            </PanelTitle>
          </Box>
        </PanelHeader>
        <PanelBody height='100%'>
          <TableauEmbed path='/Diagdashboard/Diagstats_1' height={`1600px`} />
        </PanelBody>
      </Panel>
    </Box>
  ),
}

export const DiagnosticsCustomPages: Record<
  string,
  ISingleCustomPage<DiagnosticsSlugs>
> = {
  DashboardPage,
  TestingCapacityPage,
}
