import { Box, Heading, Text } from '@chakra-ui/react'
import * as Sentry from '@sentry/react'
import { Component, ErrorInfo } from 'react'

interface Props {
  children?: React.ReactNode
}

interface State {
  hasError: boolean
  error: Error | null
  info: ErrorInfo | null
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      hasError: false,
      error: null,
      info: null,
    }
  }
  componentDidCatch(error: Error, info: ErrorInfo) {
    this.setState({
      hasError: true,
      error: error,
      info: info,
    })
    Sentry.captureException(error)
  }
  render() {
    if (this.state.hasError) {
      return (
        <Box p={16} textAlign='center' data-cy='error'>
          <Heading mb={2} fontSize='2xl'>
            Something has gone wrong.
          </Heading>
          <Text maxW='lg' mx='auto'>
            We've been notified. If you see this error again, please get in
            touch with us at support@airfinity.com.
          </Text>
        </Box>
      )
    }
    return this.props.children
  }
}

export default ErrorBoundary
