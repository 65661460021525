import { Box, useDisclosure } from '@chakra-ui/react'
import { Popover, PopoverProps } from 'react-tiny-popover'

// https://stackoverflow.com/a/54178819/3101690
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>
type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>

export type DropdownPropTypes = {
  children: React.ReactNode
  closeOnClick?: boolean
} & PartialBy<PopoverProps, 'isOpen'>

export const Dropdown = ({
  children,
  content,
  closeOnClick = false,
  ...popoverProps
}: DropdownPropTypes) => {
  const { isOpen, onToggle } = useDisclosure()

  return (
    <Popover
      isOpen={isOpen}
      positions={['bottom', 'left', 'top', 'right']}
      padding={10}
      onClickOutside={onToggle}
      content={
        <Box {...(closeOnClick ? { onClick: onToggle } : {})}>
          <>{content}</>
        </Box>
      }
      {...popoverProps}
    >
      <Box onClick={onToggle}>{children}</Box>
    </Popover>
  )
}
