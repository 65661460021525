import { produce } from 'immer'

import {
  BooleanFilterInput,
  DateBetweenFilterInput,
  DateFilterInput,
  FilterPropTypes,
  MultiFilterInput,
  NumberBetweenFilterInput,
  NumberFilterInput,
  SimpleFilterInput,
} from './FilterComponents'
import { allFilterTypes, FilterType } from './filters'

type FilterTypeWithComponent<T> = {
  // Component to render for filter input
  component: React.FC<React.PropsWithChildren<FilterPropTypes<T>>>
} & FilterType<T>

export const allFilterTypesWithComponents = produce(
  allFilterTypes,
  (newFilterTypes: FilterTypeWithComponent<any>[]) => {
    newFilterTypes[
      newFilterTypes.findIndex((x) => x.key === 'startsWith')
    ].component = SimpleFilterInput

    newFilterTypes[
      newFilterTypes.findIndex((x) => x.key === 'containsAny')
    ].component = SimpleFilterInput

    newFilterTypes[
      newFilterTypes.findIndex((x) => x.key === 'doesNotContain')
    ].component = SimpleFilterInput

    newFilterTypes[
      newFilterTypes.findIndex((x) => x.key === 'includesAll')
    ].component = MultiFilterInput

    newFilterTypes[
      newFilterTypes.findIndex((x) => x.key === 'includesOnly')
    ].component = MultiFilterInput

    newFilterTypes[
      newFilterTypes.findIndex((x) => x.key === 'includesAny')
    ].component = MultiFilterInput

    newFilterTypes[
      newFilterTypes.findIndex((x) => x.key === 'doesNotInclude')
    ].component = MultiFilterInput

    newFilterTypes[
      newFilterTypes.findIndex((x) => x.key === 'includesSingle')
    ].component = MultiFilterInput

    newFilterTypes[
      newFilterTypes.findIndex((x) => x.key === 'doesNotIncludeSingle')
    ].component = MultiFilterInput

    newFilterTypes[
      newFilterTypes.findIndex((x) => x.key === 'isLessThanOrEqualToNumber')
    ].component = NumberFilterInput

    newFilterTypes[
      newFilterTypes.findIndex((x) => x.key === 'isGreaterThanOrEqualToNumber')
    ].component = NumberFilterInput

    newFilterTypes[
      newFilterTypes.findIndex((x) => x.key === 'isEqualToNumber')
    ].component = NumberFilterInput

    newFilterTypes[
      newFilterTypes.findIndex((x) => x.key === 'isBetweenNumber')
    ].component = NumberBetweenFilterInput

    newFilterTypes[
      newFilterTypes.findIndex((x) => x.key === 'isLessThanOrEqualToDate')
    ].component = DateFilterInput

    newFilterTypes[
      newFilterTypes.findIndex((x) => x.key === 'isGreaterThanOrEqualToDate')
    ].component = DateFilterInput

    newFilterTypes[
      newFilterTypes.findIndex((x) => x.key === 'isEqualToDate')
    ].component = DateFilterInput

    newFilterTypes[
      newFilterTypes.findIndex((x) => x.key === 'isBetweenDate')
    ].component = DateBetweenFilterInput

    newFilterTypes[
      newFilterTypes.findIndex((x) => x.key === 'isTrue')
    ].component = BooleanFilterInput

    newFilterTypes[
      newFilterTypes.findIndex((x) => x.key === 'isBlank')
    ].component = BooleanFilterInput
  }
) as FilterTypeWithComponent<any>[]
