import FullReportPage from 'routes/common/FullReportPage'
import Dashboard from 'routes/dashboard'

import { ISingleCustomPage } from 'interfaces/navigationPage.interface'

import { getMediaDefaultWidget } from 'config/common/baseModel/Media'
import { getPressReleasesDefaultWidget } from 'config/common/baseModel/PressReleases'
import { getTweetsDefaultWidget } from 'config/common/baseModel/Tweets'

import { CardioAntithromboticsSlugs } from './CardioAntithromboticsMenu'
import { CardioAntithromboticsPages } from './CardioAntithromboticsPages'

const DashboardPage: ISingleCustomPage<CardioAntithromboticsSlugs> = {
  path: '',
  component: () => (
    <Dashboard
      widgetTabs={[
        getMediaDefaultWidget({
          view: CardioAntithromboticsPages['CardioAntithromboticsNews']
            .views[0],
        }),
        getTweetsDefaultWidget({
          view: CardioAntithromboticsPages['CardioAntithromboticsTweets']
            .views[0],
        }),
        getPressReleasesDefaultWidget({
          view: CardioAntithromboticsPages['CardioAntithromboticsPressReleases']
            .views[0],
        }),
      ]}
    />
  ),
}
const CandidatePipeline: ISingleCustomPage<CardioAntithromboticsSlugs> = {
  path: ['candidates', 'pipeline'],
  component: () => <></>,
}

const ClinicalTrialsTimeline: ISingleCustomPage<CardioAntithromboticsSlugs> = {
  path: ['clinical-trials', 'timeline'],
  component: () => <></>,
}

const ClinicalTrialsInhibitor: ISingleCustomPage<CardioAntithromboticsSlugs> = {
  path: ['clinical-trials', 'fxi-and-fxii-inhibitor-timeline'],
  component: () => <></>,
}

const GlobalRevenuePage: ISingleCustomPage<CardioAntithromboticsSlugs> = {
  path: 'global-revenue',
  component: () => <></>,
}

const ProgressByPhase: ISingleCustomPage<CardioAntithromboticsSlugs> = {
  path: ['candidates', 'progress-by-phase'],
  component: () => <></>,
}

const Reports: ISingleCustomPage<CardioAntithromboticsSlugs> = {
  path: 'reports',
  component: () => <FullReportPage showCategory={false} />,
}

const SafetyOverview: ISingleCustomPage<CardioAntithromboticsSlugs> = {
  path: 'safety-overview',
  component: () => <></>,
}
export const CardioAntithromboticsCustomPages: Record<
  string,
  ISingleCustomPage<CardioAntithromboticsSlugs>
> = {
  GlobalRevenuePage,
  CandidatePipeline,
  Reports,
  ProgressByPhase,
  DashboardPage,
  ClinicalTrialsInhibitor,
  ClinicalTrialsTimeline,
  SafetyOverview,
}
