import { Box } from '@chakra-ui/react'
import _ from 'underscore'

import { IResponseBase } from 'api/types'

import { GraphTypes } from 'interfaces/charts.interface'
import { IModel } from 'interfaces/model.interface'
import { ValueType } from 'interfaces/valueType.interface'

import { relationGetDisplayValue } from 'utils/relational'
import { nativeSortTypeFromList } from 'utils/sort'

import BarChart from './BarChart'
import { PieChart } from './PieChart'
import { ProgressBars } from './ProgressBars'

type PropTypes = {
  type: GraphTypes
  data: IResponseBase<any>[]
  column: string
  model: IModel<any, any>
}

export type ChartPropTypes = {
  chartData: ChartData
  squashData?: boolean
}

export const GraphFactory = ({ type, data, column, model }: PropTypes) => {
  const chartData = mapData(data, column, model)
  const columnData = model.schema.columns.find((col) => col.key === column)

  return (
    <>
      {type === 'Pie' ? (
        <PieChart chartData={chartData} />
      ) : type === 'Progress' ? (
        <ProgressBars
          chartData={chartData}
          squashData={columnData?.graphSquashData}
        />
      ) : type === 'Bar' ? (
        <BarChart
          chartData={chartData}
          squashData={columnData?.graphSquashData}
        />
      ) : (
        <Box>Nothing to see here...</Box>
      )}
    </>
  )
}

type ChartData = {
  total: number
  column: string
  columnName: string
  data: ChartDataEntry[]
}

type ChartDataEntry = {
  name: string
  value: number
}

const mapData = (
  data: IResponseBase<any>[],
  column: string,
  model: IModel<any, any>
): ChartData => {
  const columnData = model.schema.columns.find((col) => col.key === column)
  const columnName = columnData?.label ?? ''
  const type = columnData?.type ?? ValueType.TEXT

  if ([ValueType.SINGLE, ValueType.MULTI].includes(type)) {
    const mappedData = Object.entries(
      _.groupBy(
        (data ?? [])
          .map((row) => relationGetDisplayValue(row[column]) ?? 'N/A')
          .flat(),
        (x) => x
      )
    )
      .map(([groupKey, grouped]) => ({
        name: groupKey,
        value: grouped.length,
      }))
      .sort(
        columnData?.graphObeyObjectSort
          ? nativeSortTypeFromList(columnData?.sortOrderObject ?? [], 'name')
          : (a, b) => a.value - b.value
      )

    const normalizedData = columnData?.graphObeyObjectSort
      ? mappedData
      : mappedData.reverse()

    const total = mappedData.reduce((acc, val) => acc + val.value, 0)

    return { total, column, columnName, data: normalizedData }
  } else if ([ValueType.NUMBER].includes(type)) {
    const mappedData = data
      .map((row) => {
        const currentColumn = parseInt(row[column] as string, 10)
        const keyColumn =
          relationGetDisplayValue(row[model.displayKey ?? ''])?.toString() ??
          'N/A'

        return {
          name: keyColumn,
          value: Number.isSafeInteger(currentColumn) ? currentColumn : 0,
        }
      })
      .sort((a, b) => a.value - b.value)

    const total = mappedData.reduce((acc, val) => acc + val.value, 0)

    return { total, column, columnName, data: mappedData.reverse() }
  }
  return { total: 0, column, columnName, data: [] }
}
