import { IModel } from 'interfaces/model.interface'
import { ValueType } from 'interfaces/valueType.interface'

import { SortOrders } from 'enums/SortOrders'

import Sentiments from '../tagColours/Sentiments'

export type ConferencePresentationsData =
  | 'title'
  | 'link'
  | 'eventName'
  | 'startDate'
  | 'endDate'
  | 'eventFormat'
  | 'sessionName'
  | 'afTopic'
  | 'afSentiment'
  | 'afVaccineName'
  | 'description'
  | 'sessionType'
  | 'titleClean'
  | 'descriptionClean'
  | 'candidateNameFromLinkVaccineCandidate'
  | 'candidateNameFromLinkTreatmentCandidate'
  | 'sessionTypeKey'

const allowedAirtables = [
  'front_end_export_flu',
  'front_end_export_rsv',
] as const

export const ConferencePresentationsModel: IModel<
  Record<ConferencePresentationsData, string>,
  typeof allowedAirtables
> = {
  allowedAirtables,
  endpoint: 'conference-presentation',
  name: 'Conference Presentations',
  entityName: 'Conference Presentations',
  searchField: 'title',
  detailViewType: 'Article',
  defaultSortObject: [
    {
      id: 'startDate',
      sortOrder: SortOrders.DESC,
    },
    {
      id: 'title',
      sortOrder: SortOrders.DESC,
    },
  ],
  detailView: {
    link: 'link',
    abstract: 'description',
  },
  displayKey: 'title',
  schema: {
    columns: [
      {
        key: 'title',
        width: 800,
        showDetail: false,
        cellFormat: {
          maxLines: 3,
        },
      },
      { key: 'link', width: 120, type: ValueType.URL },
      {
        key: 'eventName',
        label: 'Event name',
        width: 180,
        type: ValueType.MULTI,
      },
      {
        key: 'startDate',
        width: 120,
        type: ValueType.DATE,
        label: 'Start date',
      },
      { key: 'endDate', width: 120, type: ValueType.DATE, label: 'End date' },
      {
        key: 'eventFormat',
        type: ValueType.SINGLE,
        label: 'Event format',
      },
      {
        key: 'sessionName',
        label: 'Session name',
        width: 250,
        type: ValueType.SINGLE,
      },
      { key: 'afTopic', width: 250, type: ValueType.SINGLE, label: 'Topic' },
      {
        key: 'afSentiment',
        width: 100,
        type: ValueType.SINGLE,
        cellFormat: {
          colours: Sentiments,
        },
        label: 'Sentiment',
      },
      {
        key: 'afVaccineName',
        width: 300,
        type: ValueType.SINGLE,
        label: 'Vaccine',
      },
      {
        key: 'description',
        type: ValueType.LONG,
        width: 600,
        cellFormat: {
          maxLines: 4,
        },
        showTable: false,
      },
      {
        key: 'sessionType',
        label: 'Type',
        width: 150,
        type: ValueType.MULTI,
      },
      {
        key: 'titleClean',
        label: 'Title',
        width: 500,
        showDetail: false,
        type: ValueType.TEXT,
        cellFormat: {
          maxLines: 3,
        },
      },
      {
        key: 'descriptionClean',
        label: 'Description',
        width: 200,
        showDetail: false,
        type: ValueType.TEXT,
        cellFormat: {
          maxLines: 3,
        },
      },
      {
        key: 'candidateNameFromLinkVaccineCandidate',
        label: 'Vaccines',
        width: 180,
        type: ValueType.MULTI,
      },
      {
        key: 'candidateNameFromLinkTreatmentCandidate',
        label: 'Treatments',
        width: 180,
        type: ValueType.MULTI,
      },
      {
        // Only used for sorting
        key: 'sessionTypeKey',
        label: 'Session Type Key',
        showTable: false,
        showDetail: false,
        width: 100,
        type: ValueType.NUMBER,
      },
    ],
  },
}
