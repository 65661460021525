export default {
  '0% post-vaccination': 'rgb(207, 223, 255)',
  '3.2% post-challenge': 'rgb(208, 240, 253)',
  None: 'rgb(178, 21, 139)',
  'Trial ongoing, not yet reported': 'rgb(255, 218, 246)',
  'No statistically significant difference between vaccine and placebo groups':
    'rgb(238, 238, 238)',
  '3.2%': 'rgb(252, 180, 0)',
  'Not reported': 'rgb(237, 226, 254)',
  'Trial complete, no results reported yet': 'rgb(252, 180, 0)',
}
