import { SortOrders } from 'enums/SortOrders'

import {
  ClinicalTrialsModel,
  MediaModel,
  PersonModel,
  PresentationsModel,
  PressReleasesModel,
  TweetsModel,
} from 'config/common/baseModel'
import { CardioCandidateModel } from 'config/common/baseModel/cardio360/Cardio360Candidate'
import { CardioConferencePresentationsModel } from 'config/common/baseModel/cardio360/Cardio360ConferencePresentations'

import { compose, renameColumn, whiteListColumn } from 'utils/overrideModel'

export const CardioLipidCandidateModel = compose<typeof CardioCandidateModel>(
  whiteListColumn<typeof CardioCandidateModel>([
    'candidateName',
    'companies',
    'candidateType',
    'target',
    'phaseInActiveClinicalTrials',
    'routeOfAdministration',
    'lookupCandidatePublications',
  ])
)(CardioCandidateModel)

export const CardioLipidPublicationsModel = compose<typeof PresentationsModel>(
  whiteListColumn([
    'title',
    'journalTitle',
    'publicationDate',
    'lookupCandidateList',
    'afTopics',
    'afArticleType',
    'afSentiment',
    'linkAfLeadAuthor',
    'linkAfLastAuthor',
    'doiUrl',
  ])
)({
  ...PresentationsModel,
  name: 'Publications',
  endpoint: 'publication',
  defaultSortKey: 'publicationDate',
  defaultSortOrder: SortOrders.DESC,
  exportDisabled: true,
  detailView: {
    link: 'doiUrl',
    abstract: 'abstract',
  },
})

export const CardioLipidConferencePresentationsModel = compose<
  typeof CardioConferencePresentationsModel
>(
  whiteListColumn([
    'titleClean',
    'eventName',
    'sessionName',
    'lookupMappedSessionType',
    'linkOrganisation',
    'descriptionClean',
    'candidateList',
    'afTopics',
    'afSentiment',
    'presentingAuthor',
    'afIndependent',
  ])
)(CardioConferencePresentationsModel)

export const CardioLipidTrialsModel = compose<typeof ClinicalTrialsModel>(
  whiteListColumn([
    'trialId',
    'title',
    'acronym',
    'lookupCandidateName',
    'sponsorOrgSync',
    'phasesClean',
    'status',
    'enrolledAt',
    'primaryCompletionDate',
    'primaryOutcomeMeasures',
    'completedAt',
    'targetEnrollment',
    'participants',
    'studyType',
    'ages',
    'locations',
    'url',
  ]),
  renameColumn('lookupCandidateName', 'Candidate'),
  renameColumn('sponsorOrgSync', 'Sponsors')
)({
  ...ClinicalTrialsModel,
  endpoint: 'clinical-trial',
  defaultSortObject: [{ id: 'registeredAt', sortOrder: SortOrders.DESC }],
})

export const CardioLipidCandidateLoweringEfficacyModel = compose<
  typeof CardioCandidateModel
>(
  whiteListColumn<typeof CardioCandidateModel>([
    'candidateName',
    'brandNames',
    'companies',
    'approvedPopulationsForLipidLowering',
    'effectsOnLdlc',
    'effectsOnTotalCholesterol',
    'effectsOnVldlc',
    'effectsOnNonHdlc',
    'effectsOnHdlc',
    'effectsOnApob',
    'effectsOnApocIii',
    'effectsOnTriglycerides',
    'effectsOnLpa',
  ])
)(CardioCandidateModel)

export const CardioLipidCandidateCardiovascularOutcomesModel = compose<
  typeof CardioCandidateModel
>(
  whiteListColumn<typeof CardioCandidateModel>([
    'candidateName',
    'primaryCompositeCardiovascularEndpointDefinition',
    'cardiovascularOutcomesTrialsAndStatus',
    'primCompCardioEndPointHrAnd95PercentCi',
    'numberNeededToTreatPrimaryCompositeEndpoint',
    'deathFromCoronaryHeartDiseaseHrAnd95PercentCi',
    'myocardialInfarctionHrAnd95PercentCi',
    'strokeHrAnd95PercentCi',
    'hospitalisationDueToUnstableAnginaHrAnd95PercentCi',
    'cardiovascularDeathHrAnd95PercentCi',
    'coronaryRevascularisationHrAnd95PercentCi',
    'hospitalisationDueToHeartFailureHrAnd95PercentCi',
    'allCauseMortalityHrAnd95PercentCi',
  ])
)(CardioCandidateModel)

export const CardioLipidPressReleasesModel = whiteListColumn<
  typeof PressReleasesModel
>([
  'title',
  'datePublished',
  'lookupCandidateName',
  'lookupDrugTarget',
  'link',
])({
  ...PressReleasesModel,
  detailView: {
    link: 'link',
    abstract: 'description',
  },
})

export const CardioLipidMediaModel = compose<typeof MediaModel>(
  whiteListColumn([
    'title',
    'datePublished',
    'lookupCandidateName',
    'target',
    'link',
  ]),
  renameColumn('lookupCandidateName', 'Candidate Name')
)(MediaModel)

export const CardioLipidTweetsModel = whiteListColumn<typeof TweetsModel>([
  'createdAt',
  'fullText',
  'tweetUrl',
  'candidateName',
  'persons',
  'afTopics',
  'afSentiment',
  'followers',
  'luHIndex',
  'kolAffiliationAndCountry',
])(TweetsModel)

export const CardioLipidKolsModel = whiteListColumn<typeof PersonModel>([
  'afName',
  'afHIndex',
  'affiliations',
  'afInvestigatorOrCommitteeMemberOfClinicalTrial',
  'lookupTitleFromConferencePresentations2',
  'leadAuthorOfPublication',
  'lastAuthorOfPublication',
  'lookupFullTextFromTweets',
  'lookupTrialFromInvestigators',
])(PersonModel)
