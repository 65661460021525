import { Box, Link } from '@chakra-ui/react'
//@ts-ignore
import Linkify from 'react-linkify'

import { CleanResponseData } from 'api/types'

type PropTypes = {
  value: CleanResponseData
  width?: any
}

const Text = ({ value, width }: PropTypes) => {
  return (
    <Box width={width ? width : '100%'} lineHeight={1.5} fontSize='12px'>
      <Linkify
        componentDecorator={(
          decoratedHref: any,
          decoratedText: any,
          key: any
        ) => (
          <Link
            textDecoration='underline'
            isExternal
            href={decoratedHref}
            key={key}
          >
            {decoratedText}
          </Link>
        )}
      >
        {value}
      </Linkify>
    </Box>
  )
}

export default Text
