import { INavigationMenu } from 'interfaces/navigationMenu.interface'

export type InternalToolingSlugs = '' | 'internal-tooling'

const InternalToolingMenu: Array<INavigationMenu<InternalToolingSlugs>[]> = [
  [
    {
      title: 'Tableau Preview Tool',
      slug: '',
      description: '',
      createdAt: new Date(2022, 7, 11),
    },
  ],
]

export { InternalToolingMenu }
