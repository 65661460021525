import { UseQueryOptions, useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { recursiveCamelCase } from 'api/helper'
import useAxios from 'api/useAxios'

import countryCodeToNameMapping from './data/countryCodeToNameMapping'

const summaryKeys = [
  'weeklyCasesPerMillion',
  'changeInWeeklyCasesPerMillion',
  'weeklyDeathsPerMillion',
  'changeInWeeklyDeathsPerMillion',
  'changeInHospitalAdmissionsPerMillion',
  'weeklyHospitalAdmissionsPerMillion',
  'weeklyCasesPerHundredThousand',
  'changeInWeeklyCasesPerHundredThousand',
  'weeklyDeathsPerHundredThousand',
  'changeInWeeklyDeathsPerHundredThousand',
  'peopleVaccinatedPercentage',
  'peopleFullyVaccinatedPercentage',
  'boostersPercentage',
  'lastUpdatedVaccinatedPercentage',
  'changeInHospitalAdmissionsPerHundredThousand',
  'weeklyHospitalAdmissionsPerHundredThousand',
  'lastUpdatedHospitalAdmissions',
] as const

export type CountrySummaryKeys = typeof summaryKeys[number]

export type CountrySummary = {
  countryCode: keyof typeof countryCodeToNameMapping
  summaryData: Record<CountrySummaryKeys, number>
  continent: string
}

export function useCountrySummary(
  countryCodes?: CountrySummary['countryCode'][],
  extraProps?: UseQueryOptions<any, any>
) {
  const axios = useAxios()

  return useQuery<CountrySummary[], AxiosError>(
    ['countrySummary', countryCodes],
    async () => {
      return axios
        .get(`/covid/owid/country-summary/`, {
          params: {
            ...(countryCodes ? { country_codes: countryCodes.join(',') } : {}),
          },
        })
        .then((res) => recursiveCamelCase(res.data))
    },
    extraProps
  )
}
