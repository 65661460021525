import { useMutation, useQuery } from '@tanstack/react-query'

import { appsListUnion } from 'config/apps'

export type PreviousUserViewData = Partial<{
  [a in appsListUnion]: Partial<{
    [p in string]: number | null
  }>
}>

const localStorageKey = 'previousUserView'

export const usePreviousUserView = () => {
  return useQuery<PreviousUserViewData>(['previousUserView'], () =>
    JSON.parse(localStorage.getItem(localStorageKey) ?? '{}')
  )
}

// We don't need to invalidate the query above. That only makes performance worse since it'll then have to re-render.
// The data is still correct since it'll only matter when navigating pages. That will re-render the entire component.
export const usePreviousUserViewMutation = () => {
  return useMutation(async (newObject: PreviousUserViewData) => {
    return await localStorage.setItem(
      localStorageKey,
      JSON.stringify(newObject)
    )
  })
}
