import { Box, Flex, Text } from '@chakra-ui/react'
import { IResponseBase } from 'api/types'
import { InputObject } from '..'
import { useMemo, useState } from 'react'

import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import CardContainer from './CardContainer'
import moment from 'moment'
import { FilterType } from '../utils'
import { useTableChartFilters } from 'contexts/TableChartFilters'
import { endOfMonth, parse, startOfMonth } from 'date-fns'

interface OccurenceByMonthOutput {
  date: string
  cases: number
}

const TrialsLineChart = ({
  data,
  loading,
}: {
  data: IResponseBase[]
  loading: boolean
}) => {
  const [expanded, setExpanded] = useState<boolean>(false)
  const [hasFiltered, setHasFiltered] = useState<boolean>(false)
  const { addToFilters, tableFilters } = useTableChartFilters()

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <Flex
          flexDir={'column'}
          p={2}
          borderWidth='1px'
          borderRadius='md'
          bg='white'
          shadow='md'
          rowGap={1}
          textAlign={'center'}
        >
          <Flex alignItems={'center'} gap={1}>
            <Box h={4} w={4} bgColor={payload[0].color} rounded={'md'} />
            <Box color='black' fontSize={'sm'} display={'flex'} gap={1}>
              <Text color='gray.700'>Clinical Trials:</Text>
              <Text maxW='200px' fontWeight={'semibold'}>
                {payload[0].value.toLocaleString()}
              </Text>
            </Box>
          </Flex>
          <Text fontSize={'sm'} color='black' fontWeight={'semibold'}>
            {label}
          </Text>
        </Flex>
      )
    }

    return null
  }

  const countOccurrencesByMonth = (
    trialsArray: InputObject[]
  ): OccurenceByMonthOutput[] => {
    const counts: { [key: string]: number } = {}

    // count occurrences
    trialsArray.forEach((obj) => {
      if (obj.enroledAt) {
        const date = new Date(obj.enroledAt)
        const monthYear = `${date.toLocaleString('default', {
          month: 'short',
        })} ${date.getFullYear()}`
        counts[monthYear] = (counts[monthYear] || 0) + 1
      }
    })

    // format output and sort by date
    return Object.entries(counts)
      .map(([date, cases]) => ({ date, cases }))
      .sort((a, b) => {
        const dateA = moment(a.date, 'MMM YYYY').valueOf()
        const dateB = moment(b.date, 'MMM YYYY').valueOf()
        return dateA - dateB
      })
  }

  const result = useMemo(() => {
    return countOccurrencesByMonth(data)
  }, [data])

  const handleDateChange = (dateString: string) => {
    const tempFilters: FilterType[] = [...tableFilters] ?? []
    if (dateString === 'Cancel') {
      addToFilters([...tempFilters.filter((f) => f.column !== 'enroledAt')])
      setHasFiltered(false)
    } else {
      const startMonth = startOfMonth(parse(dateString, 'MMM yyyy', new Date()))
      const endMonth = endOfMonth(parse(dateString, 'MMM yyyy', new Date()))

      const newDateObj = [
        {
          column: 'enroledAt',
          filterValue: startMonth,
          type: 'isGreaterThanOrEqualToDate',
        },
        {
          column: 'enroledAt',
          filterValue: endMonth,
          type: 'isLessThanOrEqualToDate',
        },
      ]

      const columnExists = tempFilters.some((f) => f.column === 'enroledAt')
      if (columnExists) {
        addToFilters([
          ...tempFilters.filter((f) => f.column !== 'enroledAt'),
          ...newDateObj,
        ])
      } else {
        tempFilters.push(...newDateObj)
        addToFilters(tempFilters)
      }
      setHasFiltered(true)
    }
  }

  return (
    <CardContainer
      className='clinicalTrialsPerMonth'
      expanded={expanded}
      setExpanded={setExpanded}
      loading={loading}
    >
      <Box>
        <Flex alignItems={'center'} h='max-content'>
          <Text fontWeight='bold' fontSize={'sm'} color={'black'} mb={2}>
            Clinical Trials Per Month
          </Text>
        </Flex>
        <Box w={expanded ? '100%' : '260px'} h={expanded ? '480px' : '230px'}>
          {result.length > 0 ? (
            <ResponsiveContainer width='100%' height='100%'>
              <LineChart
                onClick={(state) => {
                  if (hasFiltered) {
                    handleDateChange('Cancel')
                  } else {
                    state.activeLabel && handleDateChange(state.activeLabel)
                  }
                }}
                data={result}
              >
                <XAxis
                  dataKey='date'
                  fontSize={'10px'}
                  height={15}
                  type='category'
                  domain={['dataMin', 'dataMax']}
                  tickCount={5}
                />
                <YAxis width={15} fontSize={'10px'} />
                <Tooltip content={<CustomTooltip />} />
                <Legend wrapperStyle={{ fontSize: '11px' }} />
                <CartesianGrid strokeDasharray='3 3' stroke='#e0e0e0' />

                <Line
                  type='monotone'
                  dataKey='cases'
                  stroke='#3b82f6'
                  strokeWidth={expanded ? 2 : 1}
                  dot={false}
                  name='Clinical Trials'
                  strokeLinecap='round'
                  isAnimationActive={false}
                />
              </LineChart>
            </ResponsiveContainer>
          ) : (
            <Flex h={'full'} justifyContent={'center'} alignItems={'center'}>
              <Text
                fontSize={'sm'}
                textAlign={'center'}
                fontWeight={'semibold'}
                color='gray.600'
              >
                No time series data found for current selection
              </Text>
            </Flex>
          )}
        </Box>
      </Box>
    </CardContainer>
  )
}

export default TrialsLineChart
