import { Box, Text } from '@chakra-ui/layout'
import _ from 'lodash'

import AlertCard, { alertRiskLevelOrder } from 'modules/Alerts'

import { AlertsType } from 'api/cms/alerts/useCountryAlerts'

import { formatDate } from 'utils/formatDate'

const customAlertsSort = (a: AlertsType, b: AlertsType) =>
  alertRiskLevelOrder.indexOf(a.type) - alertRiskLevelOrder.indexOf(b.type)

const sortAlerts = (alerts: AlertsType[]) => alerts.sort(customAlertsSort)
const Alert = ({
  index,
  displayDates,
  groupedAlerts,
  fullPage,
}: {
  index: number
  displayDates: string[]
  groupedAlerts: _.Dictionary<AlertsType[]>
  fullPage?: boolean
}) => {
  const date = displayDates[index]
  const dayAlerts = groupedAlerts[date]
  const dayIndex = index
  return (
    <>
      <Box mb='3'>
        <Box w='fit-content'>
          <Text fontSize='sm' color='legacy-primary.400'>
            {formatDate(new Date(date))} ({dayAlerts.length})
          </Text>
          <Box h='1px' bg='legacy-primary.200' mb='2' mr='-1rem' />
        </Box>
        {sortAlerts(dayAlerts).map((alert, index) => {
          return (
            <AlertCard
              key={index}
              alert={alert}
              fullPage={fullPage}
              forceOpen={fullPage && dayIndex === 0}
            />
          )
        })}
      </Box>
    </>
  )
}

export default Alert
