import GenericTable from 'modules/Tables'

import { IModel } from 'interfaces/model.interface'
import {
  INavigationPages,
  ISingleNavigationPage,
} from 'interfaces/navigationPage.interface'

import { PadSlugs } from './PadMenu'
import {
  PadCandidateRankingModel,
  PadClinicalTrialModel,
  PadRegulatoryCountryModel,
  PadRegulatoryTreatmentModel,
  PadMediaModel,
  PadPressReleasesModel,
  PadPublicationsModel,
} from './PadModels'

type PadNavigationPage<TModel extends IModel<any>> = ISingleNavigationPage<
  PadSlugs,
  TModel
>

const Candidates: PadNavigationPage<typeof PadCandidateRankingModel> = {
  key: 'Candidates',
  path: ['candidates', 'table'],
  component: GenericTable,
  model: {
    ...PadCandidateRankingModel,
  },
  views: [
    {
      name: 'All',
      airtableName: 'PAD Candidates_API_All',
      airtableBase: 'idtreatments360',
    },
  ],
}

const Publications: PadNavigationPage<typeof PadPublicationsModel> = {
  key: 'Publications',
  path: 'publications',
  component: GenericTable,
  model: PadPublicationsModel,
  views: [
    {
      name: 'All',
      airtableName: 'API_[PAD]_All',
      airtableBase: 'idtreatments360',
    },
  ],
}

const ClinicalTrial: PadNavigationPage<typeof PadClinicalTrialModel> = {
  key: 'ClinicalTrial',
  path: ['trials', 'all-data'],
  component: GenericTable,
  model: PadClinicalTrialModel,
  views: [
    {
      name: 'All',
      airtableName: 'PAD Trials - api - all',
      airtableBase: 'idtreatments360',
    },
  ],
}

const PressReleases: PadNavigationPage<typeof PadPressReleasesModel> = {
  key: 'PressReleases',
  path: null,
  component: GenericTable,
  model: PadPressReleasesModel,
  views: [
    {
      name: 'All',
      airtableName: 'api_app_press_releases_all',
      airtableBase: 'idtreatments360',
    },
  ],
}
const Media: PadNavigationPage<typeof PadMediaModel> = {
  key: 'Media',
  path: null,
  component: GenericTable,
  model: PadMediaModel,
  views: [
    {
      name: 'All',
      airtableName: 'api_app_PAD_all',
      airtableBase: 'idtreatments360',
    },
  ],
}
const RegulatoryCountry: PadNavigationPage<typeof PadRegulatoryCountryModel> = {
  key: 'RegulatoryCountry',
  path: ['regulatory', 'countries'],
  component: GenericTable,
  model: PadRegulatoryCountryModel,
  views: [
    {
      name: 'Country',
      airtableName: 'API-PAD-Antivirals - Approvals - Country',
      airtableBase: 'idtreatments360',
    },
  ],
}

const RegulatoryTreatment: PadNavigationPage<
  typeof PadRegulatoryTreatmentModel
> = {
  key: 'RegulatoryTreatment',
  path: ['regulatory', 'treatments'],
  component: GenericTable,
  model: PadRegulatoryTreatmentModel,
  views: [
    {
      name: 'Treatments',
      airtableName: 'API-PAD-Antivirals - Approvals - Treatments',
      airtableBase: 'idtreatments360',
    },
  ],
}

export const PadPages: INavigationPages<PadSlugs> = {
  Candidates,
  Publications,
  ClinicalTrial,
  PressReleases,
  Media,
  RegulatoryTreatment,
  RegulatoryCountry,
}
