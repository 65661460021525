import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Text,
  Textarea,
} from '@chakra-ui/react'
import { UseMutationResult } from '@tanstack/react-query'
import { AxiosError, AxiosResponse } from 'axios'
import { Field, Form, Formik } from 'formik'

import { useAppParams } from 'routes/utils'

import { LoadingSuccess } from 'components'

import { QuestionParam } from 'api/useSubmitQuestion'

import useTracking from 'tracking/useTracking'

interface ChatWidgetProps {
  apiStatus: UseMutationResult<
    AxiosResponse<any>,
    AxiosError<any>,
    QuestionParam,
    unknown
  >
}

const ChatWidgetBody = ({ apiStatus }: ChatWidgetProps) => {
  const { app, pageSlug, subPageSlug } = useAppParams()
  const [tracking] = useTracking()

  const handleSubmit = (values: { question: string }) => {
    apiStatus.mutate({
      app,
      message: values.question,
      url: window.location.href,
      page: pageSlug,
      subpage: subPageSlug,
    })
    tracking.sendAnalystQuestion({})
  }

  const validateQuestion = (value: string) => {
    let error
    if (!value) {
      error = 'Question is required'
    } else if (apiStatus.isError) {
      error = apiStatus.error?.message
    }
    return error
  }

  if (apiStatus.isLoading) {
    return <LoadingSuccess isLoading={apiStatus.isLoading} />
  }

  if (apiStatus.isError) {
    return (
      <Flex
        flexDirection='column'
        justifyContent='space-between'
        alignItems='center'
        textAlign='center'
      >
        <Text
          mx='auto'
          mt={12}
          as='p'
          color='error'
          fontWeight='normal'
          fontSize='md'
          px={4}
        >
          Something went wrong while trying to submit your question
        </Text>
        <Button
          variant='yellow'
          mx='auto'
          mt={12}
          onClick={() => apiStatus.reset()}
        >
          Try again
        </Button>
      </Flex>
    )
  }

  if (apiStatus.isLoading || apiStatus.isSuccess) {
    return (
      <>
        <LoadingSuccess isLoading={apiStatus.isLoading} />
        <Flex
          flexDirection='column'
          justifyContent='space-between'
          alignItems='center'
        >
          <Text mt={6} as='p' fontWeight='normal' fontSize='md'>
            An analyst will contact you via email shortly
          </Text>
          <Button
            variant='outline'
            mx='auto'
            mt={12}
            onClick={() => apiStatus.reset()}
          >
            Ask Another Question
          </Button>
        </Flex>
      </>
    )
  }

  return (
    <Formik
      initialValues={{ question: '' }}
      onSubmit={(values, actions) => {
        handleSubmit(values)
        actions.setSubmitting(false)
        apiStatus.isSuccess && actions.resetForm()
      }}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {(_props) => (
        <Form>
          <Field name='question' validate={validateQuestion}>
            {({ field, form }: any) => (
              <FormControl
                px='1.5rem'
                isInvalid={form.errors.question && form.touched.question}
                id='question'
              >
                <FormLabel
                  fontSize='medium'
                  htmlFor='question'
                  color='black'
                  my={5}
                >
                  How can we help you?
                </FormLabel>
                <Textarea
                  {...field}
                  autoFocus
                  id='question'
                  fontSize='large'
                  placeholder='Type your question here...'
                  minH='15rem'
                  resize='none'
                  borderRadius='sm'
                  border='1px solid'
                  borderColor='gray2'
                />
                <FormErrorMessage>{form.errors.question}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
          <Box px='1.5rem'>
            <Button
              variant='yellow'
              mt={3}
              float='right'
              isLoading={apiStatus.isLoading}
              type='submit'
              tabIndex={0}
            >
              Send Question
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  )
}

export default ChatWidgetBody
