import { INavigationMenu } from 'interfaces/navigationMenu.interface'

export const groupMenu = (menu: INavigationMenu<any>[]) => {
  return menu.reduce(
    (acc, val) => ({
      ...acc,
      [val.grouping ?? 'Default']: [
        ...(acc?.[val.grouping ?? 'Default'] ?? []),
        val,
      ],
    }),
    {} as Record<string, INavigationMenu<any>[]>
  )
}
