import { IModel } from 'interfaces/model.interface'
import { ValueType } from 'interfaces/valueType.interface'

import { SortOrders } from 'enums/SortOrders'

export type RWEInfosetMediaData =
  | 'title'
  | 'companies'
  | 'datePublished'
  | 'link'
  | 'keywordsClean'
  | 'country'
  | 'automatedTopicsCategory'
  | 'summary'
  | 'finalSummary'
  | 'category'
  | 'subcategory'
  | 'sourceType'
  | 'covid19'

const allowedAirtables = ['api_bayer_examples_final'] as const

export const RWEInfosetMediaModel: IModel<
  Record<RWEInfosetMediaData, string>,
  typeof allowedAirtables
> = {
  allowedAirtables,
  displayKey: 'title',
  name: 'Media',
  entityName: 'News',
  searchField: 'title',
  endpoint: 'media',
  defaultSortObject: [
    { id: 'datePublished', sortOrder: SortOrders.DESC },
    { id: 'companies', sortOrder: SortOrders.ASC },
    { id: 'country', sortOrder: SortOrders.ASC },
  ],
  defaultSortKey: 'datePublished',
  defaultSortOrder: SortOrders.DESC,
  detailViewType: 'Article',
  exportDisabled: true,
  detailView: {
    link: 'link',
    abstract: 'summary',
  },
  noPrint: true,
  schema: {
    columns: [
      { key: 'title', label: 'Title', width: 350, type: ValueType.TEXT },
      {
        key: 'companies',
        label: 'Companies',
        width: 300,
        type: ValueType.MULTI,
      },
      {
        key: 'datePublished',
        label: 'Date Published',
        width: 120,
        type: ValueType.DATE,
      },
      { key: 'link', label: 'Link', width: 120, type: ValueType.URL },
      {
        key: 'keywordsClean',
        label: 'Keywords Clean',
        width: 230,
        type: ValueType.MULTI,
      },
      { key: 'country', label: 'Country', width: 150, type: ValueType.MULTI },
      {
        key: 'automatedTopicsCategory',
        label: 'Automated Topics Category',
        width: 250,
        type: ValueType.MULTI,
      },
      { key: 'summary', label: 'Summary', width: 300, type: ValueType.TEXT },
      {
        key: 'finalSummary',
        label: 'Final Summary',
        width: 300,
        type: ValueType.TEXT,
      },
      { key: 'category', label: 'Category', width: 250, type: ValueType.TEXT },
      {
        key: 'subcategory',
        label: 'Subcategory',
        width: 250,
        type: ValueType.TEXT,
      },
      {
        key: 'sourceType',
        label: 'Source Type',
        width: 150,
        type: ValueType.SINGLE,
      },
      { key: 'covid19', label: 'Covid 19', width: 200, type: ValueType.MULTI },
    ],
  },
}
