import { Box, Image } from '@chakra-ui/react'

import { IApp } from 'interfaces/navigationApp.interface'

import { randomIntFromInterval } from 'utils/random'

import { RequestAccess } from '.'

export type BlurredFullPageUpsellPropTypes = {
  app: IApp<any>
  title: React.ReactNode
  imageSrc: string
  body: React.ReactNode
}

export const BlurredFullPageUpsell = ({
  app,
  title,
  imageSrc,
  body,
}: BlurredFullPageUpsellPropTypes) => {
  return (
    <>
      <Box
        position='absolute'
        opacity='0.3'
        bg='black'
        top={0}
        bottom={0}
        left={0}
        right={0}
      />
      <Image
        margin='auto'
        src={`/images/blurred/${randomIntFromInterval(1, 6)}.jpg`}
        w='100%'
        maxW='1360px'
      />
      <RequestAccess app={app} title={title} imageSrc={imageSrc} body={body} />
    </>
  )
}
