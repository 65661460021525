import { Box } from '@chakra-ui/react'

import TabbedDisplay from 'modules/TabbedDisplay'

import FullReportPage from 'routes/common/FullReportPage'
import ResearchBriefsPage from 'routes/common/FullReportPage/components/ResearchBriefs'

import { TableauEmbed } from 'components'

import { ISingleCustomPage } from 'interfaces/navigationPage.interface'

import { Covid19CommercialTherapeuticsSlugs } from './Covid19CommercialTherapeuticsMenu'

import NewNotePopover from 'components/PageHeader/NewNotePopover'

const Dashboard: ISingleCustomPage<Covid19CommercialTherapeuticsSlugs> = {
  path: ['demand', 'treatment-uptake'],
  component: () => (
    <Box>
      <NewNotePopover
        newUntil={new Date(2024, 8, 24)}
        message='This dashboard allows you to visualise realised and estimated treatment uptake by country. COVID-19 epidemiology metrics are also presented to understand how prescription and treatment-seeking behaviour align with fluctuating COVID-19 levels within the country. Get in touch with any questions or if you would like a demo from Airfinity analysts.'
      />
      <TableauEmbed path='/CB_uptake_new-YDtest/COVIDTxuptakedashboardnew' />
    </Box>
  ),
}

const DemandReport: ISingleCustomPage<Covid19CommercialTherapeuticsSlugs> = {
  path: ['demand', 'demand-report'],
  component: () => (
    <Box display={'flex'} flexDirection={'column'} overflowY={'auto'}>
      <NewNotePopover
        newUntil={new Date(2024, 8, 24)}
        message='Airfinity demand analysis has been compiled into two reports which will be updated as and when new developments occur. Read Airfinity’s analysis on forecasting COVID-19 therapeutic demand and modeling COVID-19 treatment uptake.'
      />
      <FullReportPage
        title='Treatment Demand Reports'
        showCategory={false}
        slugOverwrites={['full-report']}
      />
    </Box>
  ),
}

const SupplyOverview: ISingleCustomPage<Covid19CommercialTherapeuticsSlugs> = {
  path: ['supply', 'supply-deals-dashboard'],
  component: () => (
    <TableauEmbed path='/CandidateOverviewbyCountry/SupplyDashboard' />
  ),
}

const ProductionAgreements: ISingleCustomPage<Covid19CommercialTherapeuticsSlugs> =
  {
    path: ['manufacturing-and-production', 'production-agreements-dashboard'],
    component: () => (
      <TabbedDisplay
        tabs={[
          {
            heading: 'By Production Type',
            body: (
              <TableauEmbed path='/CandidateOverviewbyCountry/ProductionDashboard' />
            ),
          },
          {
            heading: 'By Licensing Agreement',
            body: (
              <TableauEmbed path='/CandidateOverviewbyCountry/LicensingDashboard' />
            ),
          },
        ]}
      />
    ),
  }

const Reports: ISingleCustomPage<Covid19CommercialTherapeuticsSlugs> = {
  path: ['reports', 'reports'],
  component: () => (
    <FullReportPage showCategory={false} slugOverwrites={['updates']} />
  ),
}

const ResearchBriefs: ISingleCustomPage<Covid19CommercialTherapeuticsSlugs> = {
  path: ['reports', 'research-briefs'],
  component: () => <ResearchBriefsPage />,
}

const RevenueDashboard: ISingleCustomPage<Covid19CommercialTherapeuticsSlugs> =
  {
    path: ['revenue', 'dashboard'],
    component: () => (
      <Box>
        <NewNotePopover
          newUntil={new Date(2024, 8, 24)}
          message='This Revenue Dashboard allows you to visualise the data contained within the Revenue table. This is a dynamic visual, use the axis options and filter options to configure the dashboard to suit your needs. Get in touch with any questions or if you would like a demo from Airfinity analysts.'
          navigate='/covid-19-commercial-therapeutics/revenue/table'
        />
        <TableauEmbed path='/COVIDRevenueTx/COVIDRevenueTreatments' />
      </Box>
    ),
  }

const PricingDashboard: ISingleCustomPage<Covid19CommercialTherapeuticsSlugs> =
  {
    path: ['supply', 'pricing-dashboard'],
    component: () => (
      <Box>
        <NewNotePopover
          newUntil={new Date(2024, 8, 24)}
          message='This Pricing Dashboard allows you to visualise the data contained within the Supply Deals table. This is a dynamic visual, use the axis options and filter options to configure the dashboard to suit your needs. Get in touch with any questions or if you would like a demo from Airfinity analysts.'
          navigate='/covid-19-commercial-therapeutics/supply/supply-deals-table'
        />
        <TableauEmbed path='/COVIDPriceTx/InfluenzaPricingDashboard' />
      </Box>
    ),
  }

export const Covid19CommercialTherapeuticsCustomPages: Record<
  string,
  ISingleCustomPage<Covid19CommercialTherapeuticsSlugs>
> = {
  Dashboard,
  DemandReport,
  SupplyOverview,
  Reports,
  ProductionAgreements,
  ResearchBriefs,
  RevenueDashboard,
  PricingDashboard,
}
