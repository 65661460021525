import { Box } from '@chakra-ui/react'
import { useEffect } from 'react'

import { Panel, PanelBody } from 'components/Panel'

import useTracking from 'tracking/useTracking'

import { RShinyPaths } from './paths'

export const RShinyEmbed = ({
  path,
  height,
}: {
  path: RShinyPaths
  height?: string
}) => {
  const [tracking] = useTracking()

  useEffect(() => {
    tracking.viewRShinyApp({})
  }, [tracking])

  return (
    <Box p={3}>
      <Box display='flex' pos='relative'>
        <Panel overflow='hidden' zIndex='1'>
          <PanelBody p={0}>
            <iframe
              id='embed1'
              title='Dashboard'
              src={`https://airfinity.shinyapps.io/${path}/`}
              style={{
                width: '1px',
                minWidth: '100%',
                height: height || 'calc(100vh - 125px  - 0.25rem)',
                background: 'white',
              }}
              sandbox='allow-same-origin allow-forms allow-scripts allow-downloads allow-popups allow-popups-to-escape-sandbox allow-top-navigation-by-user-activation'
              frameBorder='0'
            />
          </PanelBody>
        </Panel>
      </Box>
    </Box>
  )
}
