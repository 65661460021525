import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query'
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios'

import { appsListUnion } from 'config/apps'

import { UserView } from './types'
import useAxios from './useAxios'
import { getQueryKey } from './useUserViewData'

export default function useCreateUserViewMutation(
  app: appsListUnion,
  pageKey: string,
  extraProps?: UseMutationOptions<
    AxiosResponse<UserView>,
    AxiosError<any>,
    { userView: Pick<UserView, 'name' | 'view'> }
  >
) {
  const axios = useAxios()
  const queryKey = getQueryKey(app, pageKey)

  const queryClient = useQueryClient()

  return useMutation<
    AxiosResponse<UserView>,
    AxiosError<any>,
    { userView: Pick<UserView, 'name' | 'view'> }
  >(
    ({ userView }) =>
      createUserView(axios, {
        ...userView,
        pageId: pageKey,
        appId: app,
      }),
    {
      onMutate: (newData) => {
        queryClient.cancelQueries(queryKey)

        const previousData: UserView[] | undefined =
          queryClient.getQueryData(queryKey)

        queryClient.setQueryData(queryKey, [
          ...previousData!,
          {
            id: -1,
            ...newData.userView,
            pageId: pageKey,
            appId: app,
          },
        ])

        return () => queryClient.setQueryData(queryKey, previousData)
      },
      onError: (err, newData, rollback: any) => {
        rollback()
        queryClient.invalidateQueries(queryKey)
      },
      onSuccess: (data) => {
        const currentData: UserView[] | undefined =
          queryClient.getQueryData(queryKey)

        queryClient.setQueryData(
          queryKey,
          currentData?.filter((x) => x.id !== -1).concat(data.data)
        )
      },
      ...extraProps,
    }
  )
}

export const createUserView = async (
  axios: AxiosInstance,
  stateData: Omit<UserView, 'id'>
): Promise<AxiosResponse<UserView>> => {
  return await axios.post(`/user-views/`, {
    name: stateData.name,
    view: stateData.view,
    page_id: stateData.pageId,
    app_id: stateData.appId,
  })
}
