import GenericTable from 'modules/Tables'

import DiagnosticsDetailView from 'routes/apps/diagnostics/Candidate'

import { IModel } from 'interfaces/model.interface'
import {
  INavigationPages,
  ISingleNavigationPage,
} from 'interfaces/navigationPage.interface'

import { DiagnosticsUpcomingConferenesModel } from 'config/common/baseModel/DiagnosticsUpcomingConferecnesModel'

import { DiagnosticsSlugs } from './DiagnosticsMenu'
import {
  DiagnosticsCandidateModel,
  DiagnosticsCountriesModel,
  DiagnosticsDealsModel,
  DiagnosticsMediaModel,
  DiagnosticsPressReleasesModel,
  DiagnosticsProductionDealsModel,
  DiagnosticsSupplyDealsModel,
  DiagnosticsTweetsModel,
} from './DiagnosticsModel'

type DiagnosticsNavigationPage<TModel extends IModel<any>> =
  ISingleNavigationPage<DiagnosticsSlugs, TModel>

const Candidates: DiagnosticsNavigationPage<typeof DiagnosticsCandidateModel> =
  {
    key: 'Candidates',
    path: 'candidates',
    component: GenericTable,
    model: {
      ...DiagnosticsCandidateModel,
      renderDetailView: DiagnosticsDetailView,
    },
    views: [
      {
        name: 'All',
        airtableName: 'api_diagnostics',
      },
      {
        name: 'Priority 1',
        airtableName: 'api_diag_p1',
      },
      {
        name: 'Priority 2',
        airtableName: 'api_diag_p2',
      },
    ],
  }

const Conferences: DiagnosticsNavigationPage<
  typeof DiagnosticsUpcomingConferenesModel
> = {
  key: 'Conferences',
  path: 'conferences',
  component: GenericTable,
  model: DiagnosticsUpcomingConferenesModel,
  views: [
    {
      name: 'Default',
      airtableName: 'api_diag',
    },
  ],
}

const DealsPrice: DiagnosticsNavigationPage<typeof DiagnosticsDealsModel> = {
  key: 'DealsPrice',
  path: ['deals', 'price'],
  model: DiagnosticsDealsModel,
  component: GenericTable,
  views: [
    {
      name: 'Default',
      airtableName: 'api_price_per_diagnostic_test',
    },
  ],
}
const DealsSupply: DiagnosticsNavigationPage<
  typeof DiagnosticsSupplyDealsModel
> = {
  key: 'DealsSupply',
  path: ['deals', 'supply'],
  model: DiagnosticsSupplyDealsModel,
  component: GenericTable,
  views: [
    {
      name: 'Default',
      airtableName: 'api_supply_deals_diagnostics',
    },
  ],
}
const DealsProduction: DiagnosticsNavigationPage<
  typeof DiagnosticsProductionDealsModel
> = {
  key: 'DealsProduction',
  path: ['deals', 'production'],
  model: DiagnosticsProductionDealsModel,
  component: GenericTable,
  views: [
    {
      name: 'Default',
      airtableName: 'api_production_deals_diagnostics',
    },
  ],
}

const Countries: DiagnosticsNavigationPage<typeof DiagnosticsCountriesModel> = {
  key: 'Countries',
  path: 'countries',
  component: GenericTable,
  model: DiagnosticsCountriesModel,
  views: [
    {
      name: 'Default',
      airtableName: 'api_diag',
    },
  ],
}

const PressReleases: DiagnosticsNavigationPage<
  typeof DiagnosticsPressReleasesModel
> = {
  key: 'PressReleases',
  model: DiagnosticsPressReleasesModel,
  path: ['news', 'press-releases'],
  component: GenericTable,
  views: [
    {
      name: 'Default',
      airtableName: 'api_diagnostics',
    },
  ],
}

const Media: DiagnosticsNavigationPage<typeof DiagnosticsMediaModel> = {
  key: 'Media',
  model: DiagnosticsMediaModel,
  path: ['news', 'media'],
  component: GenericTable,
  views: [
    {
      name: 'Default',
      airtableName: 'api_diagnostics',
    },
  ],
}

const Tweets: DiagnosticsNavigationPage<typeof DiagnosticsTweetsModel> = {
  key: 'Tweets',
  path: ['social', 'tweets'],
  component: GenericTable,
  model: DiagnosticsTweetsModel,
  views: [
    {
      name: 'Default',
      airtableName: 'api_diagnostics',
    },
  ],
}

export const DiagnosticsPages: INavigationPages<DiagnosticsSlugs> = {
  Candidates,
  Conferences,
  DealsPrice,
  DealsSupply,
  DealsProduction,
  Countries,
  PressReleases,
  Media,
  Tweets,
}
