import { Heading, Box } from '@chakra-ui/react'

import { ItemComponentPropTypes } from 'modules/DetailDrawer'

import { IDTreatments360CandidateRankingData } from 'config/common/baseModel/idtreatments360/IDTreatments360Candidates'

export const DetailDrawerListCandidate = ({
  item,
  handleSelect,
}: ItemComponentPropTypes<
  Record<IDTreatments360CandidateRankingData, any>
>) => {
  const { linkAfTrUniqueTreatmentNameToBnf } = item

  return (
    <Box
      px={8}
      py={4}
      cursor='pointer'
      borderBottom='1px solid'
      borderColor='gray.100'
      onClick={() => handleSelect(item)}
      _hover={{
        bg: 'gray.100',
      }}
    >
      <Heading fontSize='md' fontWeight={500} mt={1}>
        {linkAfTrUniqueTreatmentNameToBnf}
      </Heading>
    </Box>
  )
}
