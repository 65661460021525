import { useContext } from 'react'
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom'

import Navigation from 'modules/Navigation'

import { AuthContext } from 'contexts'

import TutorialPage from './TutorialPage'

const Tutorial = () => {
  const { appListUserCanAccess, userCanAccessTutorial } =
    useContext(AuthContext)

  const navigate = useNavigate()

  if (!userCanAccessTutorial) {
    return <Navigate to={`/`} replace />
  }

  const appsWithTutorials = Object.values(appListUserCanAccess).filter(
    (app) => !!app.tutorial
  )
  const appsWithTutorialSlugs = appsWithTutorials.map((app) => app.slug)

  if (appsWithTutorialSlugs.length === 0) {
    return <Navigate to={`/`} replace />
  }

  return (
    <>
      <Navigation showSearchBar={false} onBackButton={() => navigate(-1)} />
      <Routes>
        {appsWithTutorialSlugs.map((appSlug) => (
          <Route key={appSlug} path={appSlug} element={<TutorialPage />} />
        ))}
        <Route
          path='*'
          element={
            <Navigate to={`/tutorial/${appsWithTutorialSlugs[0]}`} replace />
          }
        />
      </Routes>
    </>
  )
}

export default Tutorial
