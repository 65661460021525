import { WidgetProps } from 'routes/dashboard/WidgetComponents'
import WidgetComponentArticle from 'routes/dashboard/WidgetComponents/WidgetArticle'

import { IModel } from 'interfaces/model.interface'
import { ValueType } from 'interfaces/valueType.interface'

import { SortOrders } from 'enums/SortOrders'

import Tag from '../tagColours/Tag'
import Topic from '../tagColours/Topic'
import { customFilterNaNGraphData } from '../transformValue/charts'

export type PressReleasesData =
  | 'title'
  | 'datePublished'
  | 'afTopic'
  | 'sourceUrl'
  | 'companies'
  | 'vaccine'
  | 'description'
  | 'language'
  | 'afUniqueTreatmentName'
  | 'newsType'
  | 'newswireProviders'
  | 'links'
  | 'relevance'
  | 'afProject'
  | 'afTags'
  | 'id'
  | 'link'
  | 'afSentimentAnalysis'
  | 'afSubtopic'
  | 'afIndication'
  | 'candidateList'
  | 'candidateName'
  | 'luDrugClass'
  | 'source'
  | 'author'
  | 'afRelevant'
  | 'published'
  | 'lookupCandidateName'
  | 'lookupCompanies'
  | 'lookupDrugType'
  | 'lookupDrugTarget'
  | 'feedCategory'
  | 'feedType'
  | 'keyNewsAlert'
  | 'automatedTopicsCategory'
  | 'automatedTopicsSubCategory'
  | 'linkInfectiousDisease'
  | 'candidateNameFromVaccinesCandidateList'
  | 'candidateNameFromTreatmentsCandidateList'
  | 'company'
  | 'topic'
  | 'indication'
  | 'indications'
  | 'candidateClass'
  | 'feedOrganisationName'
  | 'grouping2'
  | 'infectiousDisease'

const allowedAirtables = [
  'api_app_treatments',
  'api',
  'api_variants',
  'api_diagnostics',
  'api_igm',
  'api_multiple_myeloma_360',
  'api_lipid_360',
  'front_end_export_influenza_default_view',
  'front_end_export_influenza_vaccines_view',
  'front_end_export_influenza_treatments_view',
  'front_end_export_rsv_default_view',
  'front_end_export_rsv_vaccines_view',
  'front_end_export_rsv_treatments_view',
  'api_anti-thrombotics_press_releases',
  'RNA_API_PressReleases',
  'API Front End Export',
  'Obesity Press Releases - Platform View',
  'Obesity Press Releases - Platform View - Commercial',
  'Obesity Press Releases - Platform View - Regulatory',
] as const

export const getPressReleasesDefaultWidget = ({
  view,
  model,
  title,
}: WidgetProps) => ({
  heading: title ?? 'Press Releases',
  body: (
    <WidgetComponentArticle<PressReleasesData>
      view={view}
      model={model ?? PressReleasesModel}
      url='sourceUrl'
      title='title'
      date='datePublished'
      keyNews='keyNewsAlert'
    />
  ),
})

export const PressReleasesModel: IModel<
  Record<PressReleasesData, string>,
  typeof allowedAirtables
> = {
  allowedAirtables,
  key: 'title',
  displayKey: 'title',
  searchField: 'title',
  detailViewType: 'Article',
  detailView: {
    link: 'sourceUrl',
    abstract: 'description',
  },
  exportDisabled: true,
  name: 'Press Releases',
  entityName: 'Press Releases',
  endpoint: 'press-release',
  defaultSortKey: 'datePublished',
  defaultSortOrder: SortOrders.DESC,
  noPrint: true,
  schema: {
    columns: [
      {
        key: 'title',
        label: 'Title',
        width: 800,
        showDetail: false,
        cellFormat: {
          maxLines: 3,
        },
      },
      {
        key: 'datePublished',
        type: ValueType.DATE,
        label: 'Publication date',
        width: 160,
      },
      {
        key: 'afTopic',
        type: ValueType.SINGLE,
        label: 'Topic',
        width: 200,
        graphType: 'Bar',
        cellFormat: {
          colours: Topic,
        },
      },
      {
        key: 'sourceUrl',
        type: ValueType.URL,
        label: 'Link',
        width: 120,
      },
      {
        key: 'companies',
        type: ValueType.MULTI,
        label: 'Companies',
        width: 240,
        graphType: 'Pie',
      },
      {
        key: 'afUniqueTreatmentName',
        type: ValueType.SINGLE,
        label: 'Vaccine',
        width: 200,
        graphType: 'Pie',
        customDataFilter: customFilterNaNGraphData,
      },
      {
        key: 'vaccine',
        type: ValueType.MULTI,
        label: 'Vaccines',
        width: 200,
        graphType: 'Pie',
      },
      {
        key: 'description',
        width: 600,
        detailType: 'LONG',
        showTable: false,
        label: 'Summary',
      },
      {
        key: 'language',
        type: ValueType.SINGLE,
        width: 120,
        label: 'Language',
        graphType: 'Bar',
      },
      { key: 'newsType', label: 'News type', type: ValueType.MULTI },
      {
        key: 'newswireProviders',
        label: 'Newswire Providers',
        width: 200,
        type: ValueType.MULTI,
      },
      {
        key: 'afTags',
        label: 'Tags',
        type: ValueType.MULTI,
        width: 260,
        graphType: 'Pie',
        cellFormat: {
          colours: Tag,
        },
      },
      { key: 'links', label: 'Links', width: 120, type: ValueType.URL },
      {
        key: 'relevance',
        label: 'Relevance',
        width: 200,
        type: ValueType.MULTI,
      },
      { key: 'afProject', label: 'Project', width: 200, type: ValueType.MULTI },
      { key: 'link', label: 'Link', width: 120, type: ValueType.URL },
      {
        key: 'afSentimentAnalysis',
        label: 'Sentiment Analysis',
        width: 150,
        type: ValueType.MULTI,
      },
      {
        key: 'afSubtopic',
        label: 'Subtopic',
        width: 230,
        type: ValueType.MULTI,
      },
      {
        key: 'afIndication',
        label: 'Indication',
        width: 230,
        type: ValueType.MULTI,
      },
      {
        key: 'candidateList',
        label: 'Candidate List',
        width: 150,
        type: ValueType.MULTI,
      },
      {
        key: 'candidateName',
        label: 'Candidate Name',
        width: 230,
        type: ValueType.MULTI,
      },
      {
        key: 'luDrugClass',
        label: 'Drug Class',
        width: 250,
        type: ValueType.MULTI,
      },
      { key: 'source', label: 'Source', width: 150, type: ValueType.TEXT },
      { key: 'author', label: 'Author', width: 150, type: ValueType.MULTI },
      {
        key: 'afRelevant',
        label: 'Relevant',
        width: 150,
        type: ValueType.MULTI,
      },
      {
        key: 'published',
        label: 'Published',
        width: 150,
        type: ValueType.TEXT,
      },
      {
        key: 'lookupCandidateName',
        label: 'Candidate Name',
        width: 200,
        type: ValueType.MULTI,
      },
      {
        key: 'lookupCompanies',
        label: 'Companies',
        width: 200,
        type: ValueType.MULTI,
      },
      {
        key: 'lookupDrugType',
        label: 'Drug Type',
        width: 200,
        type: ValueType.MULTI,
      },
      {
        key: 'lookupDrugTarget',
        label: 'Drug Target',
        width: 200,
        type: ValueType.MULTI,
      },
      {
        key: 'feedCategory',
        label: 'Feed Category',
        width: 200,
        type: ValueType.MULTI,
      },
      {
        key: 'feedType',
        label: 'Feed Type',
        width: 200,
        type: ValueType.MULTI,
      },
      {
        key: 'keyNewsAlert',
        label: 'Key News Alert',
        width: 120,
        type: ValueType.BOOLEAN,
      },
      {
        key: 'automatedTopicsCategory',
        type: ValueType.SINGLE,
        label: 'Topic',
        width: 200,
        graphType: 'Bar',
      },
      {
        key: 'automatedTopicsSubCategory',
        type: ValueType.SINGLE,
        label: 'SubTopic',
        width: 200,
        graphType: 'Bar',
      },
      {
        key: 'linkInfectiousDisease',
        label: 'Disease',
        width: 200,
        type: ValueType.MULTI,
      },
      {
        key: 'candidateNameFromVaccinesCandidateList',
        label: 'Vaccines Candidate List',
        width: 240,
        type: ValueType.MULTI,
      },
      {
        key: 'candidateNameFromTreatmentsCandidateList',
        label: 'Treatments Candidate List',
        width: 240,
        type: ValueType.MULTI,
      },
      {
        key: 'company',
        label: 'companies',
        width: 240,
        type: ValueType.MULTI,
      },
      {
        key: 'topic',
        label: 'Topic',
        width: 150,
        type: ValueType.MULTI,
      },
      {
        key: 'indication',
        label: 'Indication',
        width: 150,
        type: ValueType.MULTI,
      },
      {
        key: 'indications',
        label: 'Indication',
        width: 150,
        type: ValueType.MULTI,
      },
      {
        key: 'candidateClass',
        label: 'Candidate Class',
        width: 150,
        type: ValueType.MULTI,
      },
      {
        key: 'feedOrganisationName',
        label: 'Feed Organization Name',
        width: 150,
        type: ValueType.SINGLE,
      },
      {
        key: 'grouping2',
        label: 'Transmission Mode',
        width: 150,
        type: ValueType.MULTI,
      },
      {
        key: 'infectiousDisease',
        label: 'Infectious Disease',
        width: 150,
        type: ValueType.MULTI,
      },
    ],
  },
}
