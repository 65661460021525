import { UseGroupByOptions } from 'react-table'

import { isRelational } from 'utils/relational'

import { relationGetDisplayValue } from './relational'

export const parentColumnNames = [
  'afParentOrChild',
  'parentOrChild',
  'parentChild',
]

export const groupByFnBySplitName: UseGroupByOptions<any>['groupByFn'] = (
  rows,
  columnId
) => {
  return rows.reduce((prev, row) => {
    const splitKey = `${row.values[columnId]}`?.split('-') ?? []
    const resKey = splitKey.length > 0 ? splitKey[0] : ''

    prev[resKey] = Array.isArray(prev[resKey]) ? prev[resKey] : []
    prev[resKey].push(row)
    return prev
  }, {} as Record<string, any>)
}

export const groupByFnDefault: UseGroupByOptions<any>['groupByFn'] = (
  rows,
  columnId
) => {
  return rows.reduce((prev, row) => {
    const rawValue = relationGetDisplayValue(row.values[columnId])

    let resKey = rawValue
    // If we're dealing with array, it needs to be sorted so we don't end up with duplicates
    if (Array.isArray(rawValue)) {
      resKey = rawValue.sort().toString().replace(/ /g, '') ?? ''
    } else {
      resKey = rawValue?.toString().replace(/ /g, '') ?? ''
    }

    prev[resKey] = Array.isArray(prev[resKey]) ? prev[resKey] : []
    prev[resKey].push(row)
    return prev
  }, {} as Record<string, any>)
}

/**
 * Use this function to split the array of the column and group by the expanded rows
 * - The column grouped is expected to be an array
 *
 * Eg:
 * Row 1, Column X
 * ["1", "2"]
 *
 * Row 2, Column X
 * ["2", "3"]
 *
 * This will result the grouping to be
 * 1 -  Row 1
 * 2 -  Row 1 & 2
 * 3 -  Row 2
 */
export const groupByFnSplitArray: UseGroupByOptions<any>['groupByFn'] = (
  rows,
  columnId
) => {
  return rows.reduce((prev, row) => {
    const rawValue = relationGetDisplayValue(row.values[columnId])

    let resKey = rawValue

    if (!Array.isArray(rawValue)) {
      return prev
    }

    rawValue.forEach((val) => {
      resKey = val.replace(/ /g, '') ?? ''

      const newValue = isRelational(row.values[columnId])
        ? [row.values[columnId].find((x: any) => x.values === val)]
        : [val]

      prev[resKey] = Array.isArray(prev[resKey]) ? prev[resKey] : []
      prev[resKey].push({
        ...row,
        values: { ...row.values, [columnId]: newValue },
      })
    })

    return prev
  }, {} as Record<string, any>)
}

export const aggregateGetFirst = (values: any) => {
  if (values !== null && values !== undefined && Array.isArray(values)) {
    for (let value of values) {
      if (value !== null && value !== undefined && value.length !== 0) {
        return value
      }
    }
  }

  return ''
}
