import { IModel } from 'interfaces/model.interface'
import { ValueType } from 'interfaces/valueType.interface'

import { SortOrders } from 'enums/SortOrders'

import Priority from 'config/common/tagColours/Priority'

import Phase from '../../tagColours/Phase'
import StudyDesignClean from '../../tagColours/StudyDesignClean'
import StudyType from '../../tagColours/StudyType'

export type IDTreatments360ClinicalTrialsData =
  | 'id'
  | 'afProjectSubtype'
  | 'candidateList'
  | 'title'
  | 'acronym'
  | 'linkFundingBodyOrg'
  | 'collaborators'
  | 'linkResearchInstitute'
  | 'luPathogen'
  | 'luPathogenFamily'
  | 'sponsorPersonSync'
  | 'status'
  | 'phasesClean'
  | 'afUniqueTreatmentName'
  | 'priorityLookup'
  | 'afTrType'
  | 'studyTypeClean'
  | 'enrolledAt'
  | 'registeredAt'
  | 'primaryCompletionDate'
  | 'primaryOutcomeMeasures'
  | 'updatedAt'
  | 'targetEnrollment'
  | 'sponsor'
  | 'fStudyCompletion'
  | 'studyDesignClean'
  | 'conditions'
  | 'ages'
  | 'gender'
  | 'patientConditions'
  | 'stageOfIntervention'
  | 'patientSetting'
  | 'routesOfAdministrationClinicalTrial'
  | 'linkLocations'
  | 'outcomeMeasures'
  | 'url'
  | 'linkInfectiousDisease'
  | 'govId'
  | 'luAntiviralClass'
  | 'afStartDate'
  | 'completedAt'
  | 'participants'
const allowedAirtables = [
  'PAD Trials - api - all',
  'PAD Trials - api - Coronaviridae',
  'PAD Trials - api - Paramyxoviridae',
  'PAD Trials - api - Orthomyxoviridae',
] as const

export const IDTreatments360ClinicalTrialsModel: IModel<
  Record<IDTreatments360ClinicalTrialsData, string>,
  typeof allowedAirtables
> = {
  allowedAirtables,
  name: 'Clinical Trials',
  entityName: 'Clinical Trials',
  searchField: 'title',
  endpoint: 'clinical-trial',
  displayKey: 'govId',
  detailViewType: 'Generic',
  defaultSortKey: 'registeredAt',
  defaultSortOrder: SortOrders.DESC,
  schema: {
    columns: [
      {
        label: 'Phases',
        key: 'phasesClean',
        width: 140,
        type: ValueType.SINGLE,
        cellFormat: {
          colours: Phase,
        },
        sortOrderObject: [
          'III',
          'II/III',
          'I/II',
          'II',
          'I',
          'IV',
          'Not Applicable',
        ],
      },
      {
        key: 'candidateList',
        label: 'Candidate List',
        width: 150,
        type: ValueType.MULTI,
      },
      {
        key: 'primaryOutcomeMeasures',
        label: 'Primary outcome',
        width: 300,
        type: ValueType.LONG,
        cellFormat: {
          formatForOncology: true,
        },
      },
      {
        key: 'linkFundingBodyOrg',
        label: 'Funding Organisation',
        width: 180,
        type: ValueType.MULTI,
      },
      {
        key: 'linkResearchInstitute',
        label: 'Research Institute',
        width: 180,
        type: ValueType.MULTI,
      },

      {
        key: 'title',
        label: 'Title',
        width: 500,
      },
      {
        key: 'luPathogenFamily',
        label: 'Pathogen Family',
        width: 180,
        type: ValueType.MULTI,
      },
      {
        key: 'linkInfectiousDisease',
        label: 'Diseases',
        width: 180,
        type: ValueType.MULTI,
      },
      {
        key: 'linkLocations',
        label: 'Locations',
        width: 180,
        type: ValueType.MULTI,
      },
      {
        key: 'sponsorPersonSync',
        label: 'Sponsor Person',
        width: 180,
        type: ValueType.MULTI,
      },
      {
        key: 'studyTypeClean',
        label: 'Study Type',
        width: 130,
        type: ValueType.SINGLE,
        cellFormat: {
          colours: StudyType,
        },
      },
      {
        key: 'afUniqueTreatmentName',
        label: 'Treatments',
        type: ValueType.MULTI,
        width: 200,
      },
      {
        key: 'acronym',
        type: ValueType.TEXT,
        width: 120,
        label: 'Acronym',
      },
      {
        key: 'url',
        label: 'URL',
        type: ValueType.URL,
        width: 120,
      },
      {
        key: 'ages',
        label: 'Ages',
        width: 300,
        type: ValueType.SINGLE,
      },
      {
        key: 'gender',
        label: 'Gender',
        width: 85,
        type: ValueType.SINGLE,
      },
      {
        key: 'conditions',
        label: 'Conditions',
        width: 260,
      },
      {
        key: 'enrolledAt',
        label: 'Enrolled date',
        width: 120,
        type: ValueType.DATE,
      },
      {
        key: 'afStartDate',
        label: 'Start Date',
        width: 120,
        type: ValueType.DATE,
      },
      {
        label: 'Participants',
        key: 'participants',
        headerStyle: {
          textAlign: 'right',
        },
        cellFormat: {
          format: 'NUMBER',
        },
        type: ValueType.TEXT,
      },
      {
        key: 'completedAt',
        label: 'Completion Date',
        width: 120,
        type: ValueType.DATE,
      },

      {
        key: 'stageOfIntervention',
        label: 'Stage of intervention',
        type: ValueType.MULTI,
        width: 160,
      },
      {
        key: 'outcomeMeasures',
        label: 'Outcome measures',
        width: 260,
      },

      {
        key: 'primaryCompletionDate',
        label: 'Primary Completion Date',
        width: 200,
        type: ValueType.DATE,
      },
      {
        key: 'fStudyCompletion',
        label: 'Study Completion',
        width: 140,
        type: ValueType.DATE,
      },

      { key: 'status', label: 'Status', width: 150, type: ValueType.SINGLE },

      {
        key: 'targetEnrollment',
        label: 'Target Enrollment',
        width: 150,
        type: ValueType.NUMBER,
      },

      {
        key: 'registeredAt',
        label: 'Registered At',
        width: 130,
        type: ValueType.DATE,
      },
      {
        key: 'updatedAt',
        label: 'Updated At',
        width: 150,
        type: ValueType.DATE,
      },

      {
        key: 'afProjectSubtype',
        label: 'Project',
        width: 150,
        type: ValueType.MULTI,
      },

      {
        key: 'govId',
        label: 'ID',
        width: 140,
        type: ValueType.TEXT,
      },
      {
        key: 'studyDesignClean',
        label: 'Study Design Clean',
        width: 280,
        type: ValueType.MULTI,
        cellFormat: { colours: StudyDesignClean },
      },
      {
        key: 'patientSetting',
        label: 'Patient Setting',
        width: 250,
        type: ValueType.MULTI,
      },
      {
        key: 'afTrType',
        label: 'Type',
        width: 150,
        type: ValueType.MULTI,
      },

      {
        key: 'routesOfAdministrationClinicalTrial',
        label: 'Routes of Administration',
        width: 180,
        type: ValueType.MULTI,
      },
      {
        key: 'priorityLookup',
        label: 'Priority',
        cellFormat: {
          colours: Priority,
        },
        width: 180,
        type: ValueType.MULTI,
      },

      {
        key: 'conditions',
        label: 'Conditions',
        width: 250,
        type: ValueType.TEXT,
      },
      { key: 'sponsor', label: 'Sponsor', width: 200, type: ValueType.TEXT },

      {
        key: 'collaborators',
        label: 'Collaborator',
        width: 200,
        type: ValueType.TEXT,
      },
      {
        key: 'patientConditions',
        label: 'Patient Conditions',
        width: 150,
        type: ValueType.MULTI,
      },
      {
        key: 'luPathogen',
        label: 'Pathogen',
        width: 150,
        type: ValueType.MULTI,
      },
      {
        key: 'luAntiviralClass',
        label: 'Antiviral Class',
        width: 180,
        type: ValueType.MULTI,
      },
    ],
  },
}
