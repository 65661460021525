import { IModel } from 'interfaces/model.interface'
import { ValueType } from 'interfaces/valueType.interface'

import { SortOrders } from 'enums/SortOrders'

import DirectVSHostTargeted from 'config/common/tagColours/DirectVSHostTargeted'
import Impact from 'config/common/tagColours/Impact'
import Phase from 'config/common/tagColours/Phase'
import Priority from 'config/common/tagColours/Priority'

export type IDTreatments360CandidateRankingData =
  | 'linkAfTrUniqueTreatmentNameToBnf'
  | 'linkInfectiousDisease'
  | 'treatmentNameAlias'
  | 'linkCompanies'
  | 'researchInstitutions'
  | 'priority'
  | 'latestPhasePad'
  | 'luPathogen'
  | 'luPathogenType'
  | 'luPathogenFamily'
  | 'lookupAfTrRouteOfAdministrationFromBnf'
  | 'antibioticClass'
  | 'lookupAfTrTypeFromBnf'
  | 'viralStrainTargeted'
  | 'lookupAfTrGenericAvailableFromBnf'
  | 'lookupAfTrBnfUrlFromUrl'
  | 'lookupAfTrLicensedIndicationFromUrl'
  | 'lookupAfTrContraindicationsFromBnf'
  | 'lookupAfTrPharmacompassLinkFromBnf'
  | 'deals'
  | 'lookupAfTrMechanismOfActionFromBnf'
  | 'targetTreatments'
  | 'directVsHostTargeted'
  | 'treatmentTiming'
  | 'stageOfIntervention'
  | 'antiviralMechanismClass'
  | 'patientSettingFromClinicalTrialsRollup'
  | 'afTreatmentsFullCountryApproval'
  | 'airfinityComment'

const allowedAirtables = [
  'PAD Candidates_API_All',
  'PAD Candidates - Coronaviridae',
  'PAD Candidates - Paramyxoviridae',
  'PAD Candidates - Orthomyxoviridae',
] as const

export const IDTreatments360CandidateModel: IModel<
  Record<IDTreatments360CandidateRankingData, string>,
  typeof allowedAirtables
> = {
  allowedAirtables,
  endpoint: 'candidate',
  name: 'Candidates',
  entityName: 'Candidates',
  searchField: 'linkAfTrUniqueTreatmentNameToBnf',
  displayKey: 'linkAfTrUniqueTreatmentNameToBnf',
  defaultSortKey: 'priority',
  defaultSortOrder: SortOrders.ASC,
  schema: {
    columns: [
      {
        key: 'priority',
        type: ValueType.SINGLE,
        label: 'Priority',
        width: 100,
        sortOrderObject: [
          'Rejected',
          'Failed',
          'Discontinued',
          'Priority 3',
          'Priority 2',
          'Priority 1',
          'Approved',
        ],
        accessor: (d: any) => d.priority && d.priority.toString(),
        cellFormat: {
          colours: Priority,
        },
      },
      {
        key: 'researchInstitutions',
        label: 'Affiliated Institutions',
        type: ValueType.MULTI,
        width: 360,
      },

      {
        key: 'stageOfIntervention',
        label: 'Stage of Intervention',
        type: ValueType.MULTI,
        width: 160,
        cellFormat: {
          colours: Impact,
        },
      },
      {
        key: 'linkAfTrUniqueTreatmentNameToBnf',
        label: 'Name',
        width: 350,
      },

      {
        key: 'lookupAfTrMechanismOfActionFromBnf',
        label: 'Mechanism of action',
        type: ValueType.LONG,
        width: 400,
      },
      {
        key: 'lookupAfTrRouteOfAdministrationFromBnf',
        label: 'Administration route',
        type: ValueType.MULTI,
        width: 280,
      },
      {
        key: 'lookupAfTrTypeFromBnf',
        label: 'Technology Type',
        type: ValueType.MULTI,
        width: 280,
      },
      {
        key: 'antibioticClass',
        label: 'Sub-Type',
        type: ValueType.MULTI,
        width: 280,
      },
      {
        key: 'viralStrainTargeted',
        label: 'Target Strains',
        type: ValueType.MULTI,
        width: 280,
      },
      {
        key: 'lookupAfTrBnfUrlFromUrl',
        label: 'BNF URL',
        type: ValueType.URL,
        width: 120,
      },
      {
        key: 'lookupAfTrLicensedIndicationFromUrl',
        label: 'BNF Licensed indication',
        width: 320,
        type: ValueType.MULTI,
      },
      {
        key: 'lookupAfTrGenericAvailableFromBnf',
        label: 'Generic available',
        width: 130,
        type: ValueType.SINGLE,
        cellFormat: {
          colours: {
            Yes: 'rgb(147, 224, 136)',
            No: 'rgb(248, 43, 96)',
            'N/A': 'rgb(238, 238, 238)',
          },
        },
      },
      {
        key: 'lookupAfTrContraindicationsFromBnf',
        label: 'Contraindications',
        width: 320,
        type: ValueType.MULTI,
      },
      {
        key: 'lookupAfTrPharmacompassLinkFromBnf',
        width: 120,
        type: ValueType.URL,
        label: 'Pharmacompass link',
        showTable: false,
      },

      {
        key: 'antiviralMechanismClass',
        label: 'Mechanism of Action',
        width: 200,
        type: ValueType.MULTI,
      },
      {
        key: 'directVsHostTargeted',
        label: 'Direct Vs Host (Targeted)',
        width: 220,
        type: ValueType.MULTI,
        cellFormat: {
          colours: DirectVSHostTargeted,
        },
      },

      {
        key: 'airfinityComment',
        label: 'Airfinity Progress Comment',
        width: 240,
        type: ValueType.LONG,
        cellFormat: {
          maxLines: 1,
        },
      },
      {
        key: 'treatmentNameAlias',
        label: 'Alternative Names',
        width: 240,
        type: ValueType.MULTI,
      },
      {
        key: 'patientSettingFromClinicalTrialsRollup',
        label: 'Patient Setting',
        width: 180,
        type: ValueType.MULTI,
        cellFormat: {
          colours: Phase,
        },
      },
      {
        key: 'latestPhasePad',
        label: 'Phase',
        width: 180,
        type: ValueType.MULTI,
        sortOrderObject: [
          'Phase III/IV',
          'Phase III',
          'Phase II/III',
          'Phase II',
          'Phase I/II',
          'Phase I',
          'Phase 0',
          'Preclinical',
          'Phase IV',
          'Approved',
          'Product Launch',
          'Discontinued',
          'Inactive',
          'Unknown',
          'Not Applicable',
          'Paused',
          'Validation',
          'null',
        ],
        cellFormat: {
          colours: Phase,
        },
      },

      {
        key: 'targetTreatments',
        label: 'Target Domains',
        width: 200,
        type: ValueType.MULTI,
      },
      {
        key: 'afTreatmentsFullCountryApproval',
        label: 'Country Approval',
        width: 200,
        type: ValueType.MULTI,
      },

      {
        key: 'linkInfectiousDisease',
        label: 'Disease',
        width: 180,
        type: ValueType.MULTI,
      },
      {
        key: 'linkCompanies',
        label: 'Primary Developers',
        width: 180,
        type: ValueType.MULTI,
      },

      {
        key: 'luPathogen',
        label: 'Pathogen',
        width: 180,
        type: ValueType.TEXT,
      },
      {
        key: 'luPathogenType',
        label: 'Pathogen Types',
        width: 180,
        type: ValueType.MULTI,
      },
      {
        key: 'luPathogenFamily',
        label: 'Pathogen Family',
        width: 180,
        type: ValueType.MULTI,
      },
      {
        key: 'treatmentTiming',
        label: 'Treatment Timing',
        width: 180,
        type: ValueType.MULTI,
      },
    ],
  },
}
