export default {
  'N/A': 'rgb(252, 180, 0)',
  'Recent surgery': 'rgb(255, 218, 246)',
  Trauma: 'rgb(209, 247, 196)',
  Immobilisation: 'rgb(255, 8, 194)',
  'Bleeding risk vs clinical benefit': 'rgb(255, 111, 44)',
  'Permanent risk factors': 'rgb(184, 117, 3)',
  'Idiopathic thrombotic aetiology': 'rgb(237, 226, 254)',
  'Weight change': 'rgb(114, 221, 195)',
  'Idiopathic DVT': 'rgb(205, 176, 255)',
  'Idiopathic PE': 'rgb(139, 70, 255)',
  'Serum triglycerides': 'rgb(39, 80, 174)',
  Time: 'rgb(68, 68, 68)',
}
