import FullReportPage from 'routes/common/FullReportPage'
import Dashboard from 'routes/dashboard'

import { TableauEmbed } from 'components'

import { ISingleCustomPage } from 'interfaces/navigationPage.interface'

import { getMediaDefaultWidget } from 'config/common/baseModel/Media'
import { getPressReleasesDefaultWidget } from 'config/common/baseModel/PressReleases'

import { PNHSlugs } from './PNHMenu'
import { PNHPages } from './PNHPages'

const LatestInformation: ISingleCustomPage<PNHSlugs> = {
  path: '',
  component: () => (
    <Dashboard
      widgetTabs={[
        getMediaDefaultWidget({ view: PNHPages['Media'].views[0] }),
        getPressReleasesDefaultWidget({
          view: PNHPages['PressReleases'].views[0],
        }),
      ]}
    />
  ),
}

const Reports: ISingleCustomPage<PNHSlugs> = {
  path: 'reports',
  component: () => <FullReportPage showCategory={false} />,
}

const CandidatesPipeline: ISingleCustomPage<PNHSlugs> = {
  path: ['candidates', 'pipeline'],
  component: () => <TableauEmbed path='/PNHCandidateOverview/Sheet1' />,
}

const CandidatesProgressTracker: ISingleCustomPage<PNHSlugs> = {
  path: ['candidates', 'progress-tracker'],
  component: () => <TableauEmbed path='/PNHCPTV1/Pipeline' />,
}

const TrialsTimeline: ISingleCustomPage<PNHSlugs> = {
  path: ['trials', 'timeline'],
  component: () => <TableauEmbed path='/PNHOngoingTrials/Pipeline' />,
}

export const PNHCustomPages: Record<string, ISingleCustomPage<PNHSlugs>> = {
  LatestInformation,
  Reports,
  CandidatesPipeline,
  CandidatesProgressTracker,
  TrialsTimeline,
}
