import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { AxiosError, AxiosInstance } from 'axios'
import { useContext } from 'react'

import { AuthContext } from 'contexts'

import { recursiveCamelCase } from 'api/helper'
import { Bookmark } from 'api/types'
import useAxios from 'api/useAxios'

export const bookmarkQueryKey = ['bookmarks']

export default function useBookmarkData(
  extraProps?: UseQueryOptions<any, any>
) {
  const axios = useAxios()
  const { userInfo } = useContext(AuthContext)

  return useQuery<Bookmark[], AxiosError>(
    bookmarkQueryKey,
    () => {
      return fetchBookmark(axios)
    },
    { enabled: !!userInfo, ...extraProps }
  )
}

export const fetchBookmark = async (
  axios: AxiosInstance
): Promise<Bookmark[]> => {
  return axios(`/bookmark/`).then((res) => {
    return res.data.map(mapBookmarkResponse)
  })
}

export const mapBookmarkResponse = (data: any) => {
  return {
    id: data.id,
    app: data.app_id,
    type: data.bookmark_type,
    createdAt: data.created_at,
    metadata: recursiveCamelCase(data.metadata),
  } as Bookmark
}
