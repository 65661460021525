import { Information } from '@carbon/icons-react'
import { Box, Text, Heading, Flex } from '@chakra-ui/layout'
import { Icon } from '@chakra-ui/react'
import { useState } from 'react'
import { ArrowContainer, Popover } from 'react-tiny-popover'

import { alertTrend, alertTrendColor, alertTrendText } from 'modules/Alerts'

import { AlertTrend } from 'api/cms/alerts/useCountryAlerts'

const alertsTrends = ['IN', 'DE', 'ST'] as AlertTrend[]

const AlertTrendsLegend = () => {
  return (
    <Flex flexDir={'column'}>
      <Heading fontSize={'md'} my={2} display='flex'>
        Trend{' '}
        <Text as='i' fontSize={'sm'} whiteSpace='nowrap' mx={1} pt={'1px'}>
          (Available for local human outbreaks with consistent reporting)
        </Text>
      </Heading>
      <Flex alignItems='center' justifyContent={'flex-start'} gap='1.5rem'>
        {alertsTrends.map((trend) => {
          return (
            <Flex
              alignItems='center'
              justifyContent={'flex-start'}
              gap='4px'
              key={trend}
            >
              <Icon
                as={alertTrend[trend]}
                color={alertTrendColor[trend]}
                h='18px'
                w='20px'
              />
              <Text>{alertTrendText[trend]}</Text>
            </Flex>
          )
        })}
      </Flex>
    </Flex>
  )
}

const InfoPopover = () => {
  const [isOpen, setIsPopoverOpen] = useState(false)
  return (
    <Popover
      isOpen={isOpen}
      onClickOutside={() => setIsPopoverOpen(false)}
      content={({ position, childRect, popoverRect }) => (
        <ArrowContainer
          position={position}
          childRect={childRect}
          popoverRect={popoverRect}
          arrowColor='lightgrey'
          arrowSize={10}
          arrowStyle={{
            opacity: 0.7,
          }}
        >
          <Box
            bgColor='white'
            maxW={'550px'}
            marginTop='24px'
            borderRadius={15}
            p='24px'
            fontSize={'sm'}
            boxShadow='lg'
            border={'1px solid'}
            borderColor='gray.200'
          >
            Human outbreaks are categorised according to Airfinity’s Outbreak
            Risk index, which is calculated based on pathogen-specific factors,
            the availability of medical countermeasures, as well as a country’s
            level of preparedness.
            <AlertTrendsLegend />
          </Box>
        </ArrowContainer>
      )}
    >
      <Box
        onClick={() => setIsPopoverOpen((x) => !x)}
        width='fit-content'
        cursor='pointer'
        ml='1rem'
        display={'flex'}
      >
        <Icon m='0 5px 2px 0' boxSize='20px' as={Information} />
        <Text variant='body' textDecor={'underline'} mb='-6px' color='gray1'>
          Information
        </Text>
      </Box>
    </Popover>
  )
}

export default InfoPopover
