import { UseQueryOptions, useQuery } from '@tanstack/react-query'
import { AxiosError, AxiosInstance } from 'axios'

import { apps, appsListUnion } from 'config/apps'

import { recursiveCamelCase } from '../../helper'
import useAxios from '../../useAxios'
import { Report } from './types'

export default function useReportSingle(
  app: appsListUnion,
  id: string,
  extraKey?: string,
  extraProps?: UseQueryOptions<any, any>
) {
  const axios = useAxios()

  return useQuery<Report, AxiosError>(
    ['reportSingle', app, id, extraKey],
    () => fetchReportSingle(axios, app, id, extraKey),
    { ...extraProps, enabled: !!app }
  )
}

export const fetchReportSingle = async (
  axios: AxiosInstance,
  app: appsListUnion,
  id: string,
  extraKey = 'updates'
): Promise<Report> => {
  return axios
    .get(`/${apps[app].endpoint}/analyst-${extraKey}/${id}`)
    .then((res) => recursiveCamelCase(res.data))
}
