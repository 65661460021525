import { IApp } from 'interfaces/navigationApp.interface'

import { AccessGroups } from 'enums/AccessGroups'

import { DiagnosticsCustomPages } from './DiagnosticsCustomPages'
import { DiagnosticsMenu, DiagnosticsSlugs } from './DiagnosticsMenu'
import { DiagnosticsPages } from './DiagnosticsPages'

const diagnostics: IApp<DiagnosticsSlugs> = {
  name: 'COVID-19 Diagnostics',
  slug: 'covid-diagnostics',
  accessGroups: [AccessGroups.Diagnostics],
  endpoint: 'covid-diagnostics',
  menu: DiagnosticsMenu,
  pages: DiagnosticsPages,
  customPages: DiagnosticsCustomPages,
  createdAt: new Date(2021, 1, 18),
}

export default diagnostics
