import { Box, Flex } from '@chakra-ui/react'

import styles from './index.module.css'

export type LoadingSuccessPropTypes = {
  isLoading: boolean
}

export const LoadingSuccess = ({ isLoading }: LoadingSuccessPropTypes) => {
  return (
    <Flex
      flexDirection='column'
      justifyContent='space-between'
      alignItems='center'
      mt='1.5rem'
    >
      <Box
        mt={10}
        className={
          isLoading
            ? styles.circleLoader
            : `${styles.circleLoader} ${styles.loadComplete}`
        }
      >
        <Box
          className={`${styles.checkmark} ${styles.draw}`}
          style={{
            display: isLoading ? 'none' : 'block',
          }}
        />
      </Box>
    </Flex>
  )
}
