import { useContext } from 'react'
import { useLocation } from 'react-router-dom'

import { AppParams } from 'routes/utils'

import { AuthContext } from 'contexts'

import { INavigationMenu } from 'interfaces/navigationMenu.interface'

import useTracking from 'tracking/useTracking'

import { apps, appsListUnion } from 'config/apps'

import resolvePath from 'utils/resolvePath'

import ExpandableNavigationEntry from './ExpandableNavigationEntry'
import SimpleNavigationEntry from './SimpleNavigationEntry'

export type NavigationEntryPropTypes = {
  appParams: AppParams
  menu: INavigationMenu<any>
  onSelect?: () => void
  isFirst?: boolean
  isLast?: boolean
}

export default function NavigationEntry({
  appParams,
  menu,
  isFirst,
  isLast,
  onSelect,
}: NavigationEntryPropTypes) {
  const { userInfo } = useContext(AuthContext)
  const [tracking] = useTracking()
  const location = useLocation()

  const { app, pageSlug } = appParams
  const selectedApp = apps[app as appsListUnion]

  const premiumGroup = selectedApp.premiumGroup || []
  const premiumAccess =
    !menu.isPremium ||
    (menu.isPremium && userInfo?.groups.includes(premiumGroup[0]))

  if (menu.children && premiumAccess) {
    const basePath = resolvePath([selectedApp.slug, menu.slug])
    const defaultIsOpen =
      menu.defaultOpen || location.pathname.includes(basePath)

    return (
      <ExpandableNavigationEntry
        isFirst={isFirst}
        isLast={isLast}
        appParams={appParams}
        menu={menu}
        defaultIsOpen={defaultIsOpen}
        onSelect={(childMenuSlug) => {
          onSelect?.()
          tracking.openAppSubPage({
            app: selectedApp.slug,
            page: menu.slug,
            subPage: childMenuSlug,
          })
        }}
      />
    )
  }

  const isActive =
    pageSlug === menu.slug || (pageSlug === undefined && menu.slug === '')

  return (
    <SimpleNavigationEntry
      isFirst={isFirst}
      isLast={isLast}
      menu={menu}
      isActive={isActive}
      link={`/${app}/${menu.slug}`}
      onSelect={() => {
        onSelect?.()
        tracking.openAppPage({
          app: selectedApp.slug,
          page: menu.slug,
        })
      }}
      hasAccess={premiumAccess}
    />
  )
}
