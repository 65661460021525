import { HStack, Link, Text, VStack } from '@chakra-ui/react'

export const Footer = ({ fromNav = false }: { fromNav?: boolean }) => {
  const footerChildren = (
    <>
      <Text fontSize={fromNav ? '14px' : '16px'} fontWeight={500}>
        © Airfinity Limited {new Date().getFullYear()}
      </Text>
      <Link
        fontSize={fromNav ? '12px' : '14px'}
        href='https://www.iubenda.com/privacy-policy/33215690'
        title='Privacy Policy'
        isExternal
      >
        Privacy Policy
      </Link>
      <Link
        fontSize={fromNav ? '12px' : '14px'}
        href='https://www.iubenda.com/privacy-policy/33215690/cookie-policy'
        title='Cookie Policy'
        isExternal
      >
        Cookie Policy
      </Link>
    </>
  )

  if (fromNav) {
    return (
      <VStack
        flex={1}
        justifyContent='end'
        spacing={1}
        fontSize='14px'
        color='gray1'
        pb={5}
        pt={5}
        width={'100%'}
        alignSelf={'flex-end'}
      >
        {footerChildren}
      </VStack>
    )
  }

  return (
    <HStack
      justifyContent='start'
      spacing={8}
      fontSize='14px'
      color='gray1'
      pt={2}
    >
      {footerChildren}
    </HStack>
  )
}
