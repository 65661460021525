import { IModel } from 'interfaces/model.interface'
import { ValueType } from 'interfaces/valueType.interface'

import { SortOrders } from 'enums/SortOrders'

import Phase from 'config/common/tagColours/Phase'
import RouteOfAdministration from 'config/common/tagColours/RouteOfAdministration'

export type PNHCandidateListData =
  | 'candidateName'
  | 'companies'
  | 'candidateType'
  | 'target'
  | 'phaseInActiveClinicalTrials'
  | 'routeOfAdministration'
  | 'lookupCandidateClinicalTrials'
  | 'lookupCandidatePublications'
  | 'lookupPressReleases'
  | 'lookupCandidateMediaArticles'
  | 'lookupCandidateTweets'

const allowedAirtables = ['api_candidate_list'] as const

export const PNHCandidateModel: IModel<
  Record<PNHCandidateListData, string>,
  typeof allowedAirtables
> = {
  allowedAirtables,
  endpoint: 'candidate',
  name: 'Candidates',
  entityName: 'Candidates',
  searchField: 'candidateName',
  displayKey: 'candidateName',
  defaultSortKey: 'candidateName',
  defaultSortOrder: SortOrders.ASC,
  detailViewType: 'Generic',
  schema: {
    columns: [
      {
        key: 'candidateName',
        type: ValueType.TEXT,
        label: 'Name',
        width: 150,
      },
      {
        key: 'companies',
        label: 'Primary Developers',
        type: ValueType.MULTI,
        width: 150,
      },
      {
        key: 'candidateType',
        label: 'Technology Type',
        type: ValueType.MULTI,
        width: 150,
      },
      {
        key: 'target',
        label: 'Target Domains',
        type: ValueType.MULTI,
        width: 150,
      },
      {
        key: 'phaseInActiveClinicalTrials',
        label: 'Phase',
        type: ValueType.MULTI,
        width: 280,
        cellFormat: {
          colours: Phase,
        },
      },
      {
        key: 'routeOfAdministration',
        label: 'Route of Administration',
        width: 180,
        type: ValueType.MULTI,
        cellFormat: {
          colours: RouteOfAdministration,
        },
      },
      {
        key: 'lookupCandidateClinicalTrials',
        label: 'Candidate Clinical Trials',
        width: 180,
        type: ValueType.MULTI,
      },
      {
        key: 'lookupCandidatePublications',
        label: 'Candidate Publications',
        width: 180,
        type: ValueType.MULTI,
      },
      {
        key: 'lookupPressReleases',
        label: 'Candidate Press Releases',
        width: 180,
        type: ValueType.MULTI,
      },
      {
        key: 'lookupCandidateMediaArticles',
        label: 'Candidate Media Articles',
        width: 180,
        type: ValueType.MULTI,
      },
      {
        key: 'lookupCandidateTweets',
        label: 'Candidate Tweets',
        width: 180,
        type: ValueType.MULTI,
      },
    ],
  },
}
