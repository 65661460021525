import { Close } from '@carbon/icons-react'
import {
  Box,
  Button,
  Portal,
  Text,
  VStack,
  Link,
  useDisclosure,
  useOutsideClick,
} from '@chakra-ui/react'
import { useRef } from 'react'
import theme from 'theme'

import PrimaryLink from '../PrimaryLink'

const UpSellModal = ({ handleClose }: { handleClose: () => void }) => {
  return (
    <Box
      pos={'absolute'}
      top='1rem'
      right={'-1rem'}
      transform='translateX(75%)'
      bg='white'
      color='black'
      zIndex={99}
    >
      <VStack
        p='2rem'
        borderRadius={'1rem'}
        boxShadow={theme.boxShadow.xl}
        gap='1rem'
        pos='relative'
      >
        <Button
          borderRadius={'8px'}
          variant={'ghost'}
          pos='absolute'
          right={'1rem'}
          top='1rem'
          onClick={handleClose}
          size='sm'
        >
          <Close size={24} color='#0B0B0B' />
        </Button>
        <Text whiteSpace={'nowrap'} textAlign='center' fontSize={'14px'}>
          This is content is only available to <b>Premium Users</b>.
          <br />
          Please contact your account manager or email{' '}
          <Link href='mailto:support@airfinity.com' color='primary'>
            support@airfinity.com
          </Link>{' '}
          to unlock this data.
        </Text>
        <Link href='mailto:support@airfinity.com' color='legacy-primary.500'>
          <Button variant={'yellow'} fontSize={'14px'} px='1.5rem' py='1rem'>
            Email Support
          </Button>
        </Link>{' '}
      </VStack>
    </Box>
  )
}

const NoAccessNavigationEntry = ({
  isActive,

  isDropdownChild,
  title,
  isFirst,
  isLast,
}: {
  isActive?: boolean
  children?: React.ReactNode
  isDropdown?: boolean
  isDropdownChild?: boolean
  title?: string
  isFirst?: boolean
  isLast?: boolean
}) => {
  const modalRef = useRef(null)
  const { isOpen, onOpen, onClose } = useDisclosure()
  useOutsideClick({
    ref: modalRef,
    handler: onClose,
  })

  return (
    <Box pos='relative' cursor={'pointer'} ref={modalRef}>
      <Box opacity={'0.5'} onClick={onOpen}>
        <PrimaryLink
          isFirst={isFirst}
          isLast={isLast}
          isDropdownChild={isDropdownChild}
          isActive={isActive}
        >
          {title}
        </PrimaryLink>
      </Box>
      <Box
        right={'8px'}
        top='50%'
        pos='absolute'
        fontSize={'11px'}
        bg='yellow.400'
        px='0.5rem'
        borderRadius={'8px'}
        color='black'
        transform={'translateY(-50%)'}
        fontWeight='thin'
      >
        Premium
      </Box>
      {isOpen && (
        <Portal containerRef={modalRef}>
          <UpSellModal handleClose={onClose} />
        </Portal>
      )}
    </Box>
  )
}
export default NoAccessNavigationEntry
