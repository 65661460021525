import { Box, Button, Flex, useDisclosure } from '@chakra-ui/react'
import { useEffect, useRef, useState } from 'react'

import useAskAI from 'api/ai/useAI'

import BottomSection from './BottomSection'
import ChatSection from './ChatSection'
import EmptyScreen from './EmptyScreen'
import Header from './Header'
import { ThinkingWrapper, StreamingWrapper } from './ThinkingWrapper'
import WarningPopover from './WarningPopover'
import { FullPageError } from 'components'

export type RoleT = 'user' | 'assistant' | 'assistant-internal'

export type ChatT = {
  id: number
  role: RoleT
  content: string
  sources?: any
}

type AnswerT = {
  id: number
  role: RoleT
  content: string
  sources: any[]
}

export type ChatHistoryT = ChatT[]

export const assistantsId = {
  router: 'asst_MV7nFlGGTp16LVP5hFgXRzEb',
  reports: 'asst_HYr7mUPz0BdhZFvae4KpCRQY',
  ts_infections_biorisk: 'ts_infections_biorisk',
  table_disease_information: 'asst_G54JhZ3pckoUe3aLV1dWu5Rr',
  table_media: 'asst_ZqeqgW8LQ7B207NemU5FJDG5',
  table_press_releases: 'asst_Pik6se3doi48OK8FGcg3dCWU',
}

const TableMapping = {
  // 'Table 1': {
  //   assistant: assistantsId.ts_infections_biorisk,
  //   name: 'Infectious Diseases',
  //   url: '/biorisk/outbreak-progression',
  // },
  'Table 2': {
    assistant: assistantsId.table_press_releases,
    name: 'Press Releases',
    url: '/biorisk/news/press-releases',
  },
  'Table 3': {
    assistant: assistantsId.table_media,
    name: 'Media',
    url: '/biorisk/news/media',
  },
  'Table 4': {
    assistant: assistantsId.table_disease_information,
    name: 'Disease Information',
    url: '/biorisk/disease-information',
  },
} as { [key: string]: { name: string; url: string; assistant: string } }

const routerMapping = {
  Text: assistantsId.reports,
  // 'Table 1': TableMapping['Table 1'].assistant,
  'Table 2': TableMapping['Table 2'].assistant,
  'Table 3': TableMapping['Table 3'].assistant,
  'Table 4': TableMapping['Table 4'].assistant,
} as { [key: string]: string }

const DbAssistants = Object.values(TableMapping).map((table) => table.assistant)

const DEFAULT_ASSISTANT = assistantsId.router

const AiSearch = () => {
  const inputRef = useRef<HTMLInputElement>(null)
  const [error, setIsError] = useState(false)

  const { isOpen, onOpen, onClose } = useDisclosure()

  const [query, setQuery] = useState<string>('')
  const [answer, setAnswer] = useState<string>('')
  const [assistantId, setAssistantID] = useState(DEFAULT_ASSISTANT)

  const [chatHistory, setChatHistory] = useState<ChatHistoryT>([])
  const [streamingAnswer, setStreamingAnswer] = useState<string>('')

  const { ask: askAI, clearThread } = useAskAI()

  const divRef = useRef(null)

  const scrollToBottom = () => {
    if (divRef.current) {
      ;(divRef.current as any).scrollTop = (divRef.current as any).scrollHeight
    }
  }

  const search = async (forceQuery?: string, _id?: string) => {
    const searchQuery = query || forceQuery
    const _assistantID = _id || assistantId
    if (!searchQuery) return
    setAnswer('')

    setQuery('')
    const role = 'user' as RoleT
    const messages = [
      ...chatHistory?.map((chat) => ({
        id: chat.id,
        role: chat.role,
        content: chat.content,
      })),
      { id: chatHistory.length + 1, role, content: searchQuery },
    ]
    if (!_id) {
      setChatHistory((history) => [
        ...history,
        { id: history.length + 1, role: 'user', content: searchQuery },
      ])
    }
    setAnswer(' ')
    setTimeout(() => scrollToBottom(), 10)

    function cb(answer: AnswerT) {
      const newAnswer = { ...answer }
      const isRouter = assistantsId['router'] === _assistantID
      if (isRouter) {
        clearThread()
        const response = answer.content
        const route = TableMapping[response] || ''
        const routerAnswer = {
          ...newAnswer,
          role: 'assistant-internal' as RoleT,
          content: route.name
            ? `Searching on ${route.name} Table`
            : 'Searching on Reports',
        }
        const routedAssistant = routerMapping[response] || routerMapping.Text
        setChatHistory((history) => [
          ...history,
          { ...routerAnswer, id: history[history.length - 1]?.id + 1 },
        ])
        setAssistantID(routedAssistant)
        search(searchQuery, routedAssistant)
      } else {
        const isDbSearch = DbAssistants.includes(_assistantID)
        if (!isDbSearch && !newAnswer.sources.length) {
          newAnswer.content =
            'Sorry, I can not answer that question, try asking something else.'
        }
        setAnswer('')
        setStreamingAnswer('')
        setChatHistory((history) => [
          ...history,
          { ...newAnswer, id: history[history.length - 1]?.id + 1 },
        ])
        setTimeout(() => scrollToBottom(), 10)
      }
    }
    function streamCB(streamText: AnswerT) {
      const isRouter = assistantsId['router'] === _assistantID
      if (isRouter) return null
      setAnswer('')
      setStreamingAnswer((text) => text + streamText)
    }
    try {
      await askAI(messages, _assistantID, cb, streamCB)
    } catch (err) {
      setIsError(true)
      console.error('askAI error', err)
    }
  }

  useEffect(() => {
    setQuery('')
    setAnswer('')
  }, [])

  function handleAnotherQuestion() {
    setAssistantID(DEFAULT_ASSISTANT)
    setQuery('')
    setAnswer('')
    setChatHistory([])
    clearThread()
  }

  useEffect(() => {
    inputRef.current?.focus()
  }, [])

  if (error) return <FullPageError />

  return (
    <>
      <WarningPopover onOpen={onOpen} onClose={onClose} isOpen={isOpen} />
      <Box overflow='auto' h='85vh'>
        <Box flex={1} display='flex' flexDirection='column'>
          <Flex
            flexDir={'column'}
            border={'none'}
            borderColor={'gray3'}
            borderRadius={'6px'}
            mb={0}
            mt={'4px'}
          >
            <Header />
          </Flex>
          <Box
            height={[
              'calc(90vh - 200px)',
              'calc(90vh - 200px)',
              'calc(90vh - 200px)',
              'calc(90vh - 180px)',
            ]}
            overflow='scroll'
            mb='2rem'
            margin={'auto'}
            mt='1rem'
            w='50vw'
            ref={divRef}
          >
            {chatHistory.length === 0 && !streamingAnswer && (
              <EmptyScreen handleSuggestion={(query) => search(query)} />
            )}
            {chatHistory.map((message, index) => {
              return (
                <ChatSection
                  message={message}
                  key={index}
                  chatHistory={chatHistory}
                />
              )
            })}
            <StreamingWrapper answer={streamingAnswer} />
            <ThinkingWrapper answer={answer} assistantId={assistantId} />
            {chatHistory.length >= 3 && (
              <Flex justifyContent={'center'} mb='1rem' mt='2rem'>
                <Button variant={'yellow'} onClick={handleAnotherQuestion}>
                  Ask a different question
                </Button>
              </Flex>
            )}
            <BottomSection
              setQuery={setQuery}
              search={search}
              query={query}
              handleOpenWarningMessage={onOpen}
            />
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default AiSearch
