import {
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  useInfiniteQuery,
} from '@tanstack/react-query'
import { AxiosError, AxiosInstance } from 'axios'

import { Paginated } from 'api/types'
import { FetchCollectionQueries } from 'api/useCollectionData'

import { appsListUnion } from 'config/apps'

import { recursiveCamelCase } from '../../helper'
import useAxios from '../../useAxios'
import { NewsLetterT } from './types'

export function useNewsletters(
  app: appsListUnion,
  queries: FetchCollectionQueries,
  endpoint: string,
  extraProps?: UseInfiniteQueryOptions<Paginated<NewsLetterT[]>, AxiosError>
): UseInfiniteQueryResult<Paginated<NewsLetterT[]>, AxiosError> {
  const axios = useAxios()
  const query = useInfiniteQuery<Paginated<NewsLetterT[]>, AxiosError>(
    ['newsletters', app],
    ({ pageParam: offset }) => {
      return fetchNewsletter(axios, endpoint, { ...queries, offset })
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        const totalRowsFetched = allPages.reduce(
          (total, val) => total + val?.results?.length,
          0
        )
        const totalOverall = lastPage.count
        if (totalRowsFetched === totalOverall) {
          return false
        }
        const offset = totalRowsFetched
        return offset
      },
      ...extraProps,
    }
  )
  return query
}

function formatNewsletters(res: { results: NewsLetterT[] }): any {
  if (!res?.results?.length) return null
  return {
    ...res,
    results: res?.results.map((letter) => {
      return {
        ...letter,
        date: letter.date || letter.sentAt || '',
      }
    }),
  }
}

export const fetchNewsletter = async (
  axios: AxiosInstance,
  endpoint: string,
  queries: FetchCollectionQueries
): Promise<Paginated<NewsLetterT[]>> => {
  return axios
    .get(endpoint, {
      params: { ...queries, offset: queries.offset },
    })
    .then((res) => formatNewsletters(recursiveCamelCase(res.data)))
    .catch((err) => {
      console.error({ err })
      return null
    })
}
