import { UseQueryOptions, useQuery } from '@tanstack/react-query'
import { AxiosError, AxiosInstance } from 'axios'
import { TableState } from 'react-table'

import { apps, appsListUnion } from 'config/apps'

import { recursiveCamelCase } from './helper'
import { UserView } from './types'
import useAxios from './useAxios'

export const getQueryKey = (app: appsListUnion, pageKey: string) => {
  return ['userViews', app, pageKey]
}

export default function useUserViewData(
  app: appsListUnion,
  pageKey: string,
  defaultState: Partial<TableState>,
  extraProps?: UseQueryOptions<any, any>
) {
  const axios = useAxios()

  return useQuery<UserView[], AxiosError>(
    getQueryKey(app, pageKey),
    () => {
      return fetchUserView(axios, app, pageKey, defaultState)
    },
    extraProps
  )
}

export const fetchUserView = async (
  axios: AxiosInstance,
  app: appsListUnion,
  pageKey: string,
  defaultState: Partial<TableState>
): Promise<UserView[]> => {
  const appSlug = apps[app].slug

  return axios(`/user-views/?page_id=${pageKey}&app_id=${appSlug}`).then(
    (res) =>
      recursiveCamelCase(res.data)
        .concat([
          {
            id: 0,
            name: 'Default (System generated)',
            appId: '',
            pageId: '',
            view: defaultState,
          },
        ])
        .sort((a: UserView, b: UserView) => a.id - b.id)
  )
}
