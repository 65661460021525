import { OktaEmployeesClients } from 'constants/oktaGroups'
import { useContext, useEffect } from 'react'
import FullStory from 'react-fullstory'
import ReactGA from 'react-ga'

import { AuthContext } from 'contexts'
import { getIsGov } from 'contexts/ClientType'

const PROPORTION_OF_USERS_TO_ACTIVATE_FULL_STORY_FOR = 0.5

const GAFullStoryWrapper = () => {
  const { userInfo } = useContext(AuthContext)

  const activateFullStory =
    Math.random() < PROPORTION_OF_USERS_TO_ACTIVATE_FULL_STORY_FOR

  const isGov = getIsGov()
  const isProdEnv = process.env.NODE_ENV === 'production'
  const isEmployee = OktaEmployeesClients?.includes(userInfo?.client || '')

  const fullStoryActive = isProdEnv && !isEmployee && !isGov

  useEffect(() => {
    if (userInfo) {
      if (fullStoryActive) {
        ReactGA.initialize('UA-53810554-3')
        ReactGA.set({ page: window.location.pathname })
        ReactGA.pageview(window.location.pathname)
      }
    }
  }, [userInfo, fullStoryActive])

  if (activateFullStory && fullStoryActive) {
    return <FullStory org='587C7' />
  }
  return null
}

export default GAFullStoryWrapper
