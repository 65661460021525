import { createContext, useContext } from 'react'

import { AdvancedFilterUpdaterProp } from 'components/Table/AdvancedSearch/useAdvancedFilters'

import { IModel } from 'interfaces/model.interface'
import { IApp } from 'interfaces/navigationApp.interface'
import {
  IBaseView,
  ISingleNavigationPage,
  ViewData,
} from 'interfaces/navigationPage.interface'

// THIS IS A PROVIDER MODEL
// Make sure to use this properly.

// We use this to provide data regarding the page currently opened.
// This exposes data such as page, view and model needed to resolve the correct table data
// Be careful when using this as it might lead to bugs if not used correctly

type ProviderType = {
  model: IModel<any>
  app: IApp
  page: ISingleNavigationPage<any, IModel<any>>
  viewData: ViewData
  selectedView: IBaseView<any, any>
  handleFilters?: (filter: AdvancedFilterUpdaterProp) => void
  filters?: AdvancedFilterUpdaterProp
}

export const TablePageDataContext = createContext<ProviderType>({
  model: {
    allowedAirtables: [],
    endpoint: '',
    name: '',
    entityName: '',
    schema: { columns: [] },
  },
  app: {
    name: '',
    slug: 'covid-19-vaccines',
    accessGroups: [],
    menu: [],
    endpoint: '',
    pages: {},
    createdAt: new Date(),
  },
  page: {
    component: () => null,
    key: '',
    path: '',
    model: {
      allowedAirtables: [],
      endpoint: '',
      name: '',
      entityName: '',
      schema: { columns: [] },
    },
    views: [{ name: '', airtableName: '' }],
  },
  viewData: { airtableName: '' },
  selectedView: { name: 'All', airtableName: 'id_all' },
  handleFilters: () => {},
})

export const useTablePageData = (): ProviderType => {
  return useContext(TablePageDataContext)
}
