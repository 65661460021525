import { Heading, Box } from '@chakra-ui/react'

import { CountriesData } from 'config/common/baseModel'

import { ItemComponentPropTypes } from '.'

const DetailDrawerListCountry = ({
  item,
  handleSelect,
}: ItemComponentPropTypes<Record<CountriesData, any>>) => {
  const { name } = item

  return (
    <Box
      px={8}
      py={4}
      borderTop='1px solid'
      cursor='pointer'
      onClick={() => handleSelect(item)}
      borderColor='gray.100'
      _hover={{
        bg: 'gray.100',
      }}
    >
      <Heading fontSize='md' fontWeight={400}>
        {name}
      </Heading>
    </Box>
  )
}

export { DetailDrawerListCountry }
