export default {
  ' Arteriovenous malformations': 'rgb(205, 176, 255)',
  ' Oesophageal varices': 'rgb(102, 102, 102)',
  ' Vascular aneurysm': 'rgb(156, 199, 255)',
  '<6 months old, <10 days oral feeding, delivered at <37 weeks gestation':
    'rgb(209, 247, 196)',
  'Active bleeding': 'rgb(248, 43, 96)',
  'Acute Liver Disease': 'rgb(194, 245, 233)',
  'Antiphospholipid syndrome': 'rgb(24, 191, 255)',
  'Children and adolescents': 'rgb(11, 118, 183)',
  'Chronic or Unexplained Thrombocytopenia': 'rgb(204, 204, 204)',
  'Cirrhosis grade B or C': 'rgb(6, 160, 155)',
  'Concomitant treatment with any other anticoagulant (excluding during drug switching, or when necessary for use of a cardiovascular catheter':
    'rgb(255, 158, 183)',
  'Concomitant treatment with strong P-gp inhibitors': 'rgb(51, 138, 23)',
  'Concomitant use with strong or moderate CYP3A4 inhibitors':
    'rgb(184, 117, 3)',
  'Concurrent use of other anticoagulants': 'rgb(32, 201, 51)',
  'Creatinine clearance <30mL/min)': 'rgb(237, 226, 254)',
  'Creatinine clearance <95mL/min': 'rgb(68, 68, 68)',
  'Creatinine clearance >95mL/min': 'rgb(207, 223, 255)',
  'Creatinine clearance ≤15 mL/min': 'rgb(6, 160, 155)',
  'Current/recent GI ulcer': 'rgb(156, 199, 255)',
  'Elevated Liver enzymes (i.e. 2x upper normal limit)': 'rgb(237, 226, 254)',
  'Gall Bladder Disease': 'rgb(39, 80, 174)',
  'Hypersensitivity: Acacia': 'rgb(237, 226, 254)',
  'Hypersensitivity: Arginine Hydrochloride': 'rgb(24, 191, 255)',
  'Hypersensitivity: Ascorbyl palmitate': 'rgb(255, 8, 194)',
  'Hypersensitivity: Black iron oxide': 'rgb(215, 77, 38)',
  'Hypersensitivity: Carnauba wax': 'rgb(178, 21, 139)',
  'Hypersensitivity: Carrageenan': 'rgb(119, 209, 243)',
  'Hypersensitivity: Cellulose,microcrystaline': 'rgb(209, 247, 196)',
  'Hypersensitivity: Croscarmellose sodium': 'rgb(156, 199, 255)',
  'Hypersensitivity: Crospovidone (E1202)': 'rgb(147, 224, 136)',
  'Hypersensitivity: Evinacumab-dgnb': 'rgb(107, 28, 176)',
  'Hypersensitivity: Fenofibrate': 'rgb(255, 220, 229)',
  'Hypersensitivity: Fish': 'rgb(6, 160, 155)',
  'Hypersensitivity: Gelatine': 'rgb(119, 209, 243)',
  'Hypersensitivity: Glacial acetic acid': 'rgb(254, 226, 213)',
  'Hypersensitivity: Histidine': 'rgb(6, 160, 155)',
  'Hypersensitivity: Histidine hydrochloride monohydrate': 'rgb(11, 118, 183)',
  'Hypersensitivity: Hydrochloric Acid': 'rgb(238, 238, 238)',
  'Hypersensitivity: Hydroxypropyl cellulose (E463)': 'rgb(254, 226, 213)',
  'Hypersensitivity: Hypromellose (E464)': 'rgb(68, 68, 68)',
  'Hypersensitivity: Indigo carmine': 'rgb(45, 127, 249)',
  'Hypersensitivity: Iron oxide black': 'rgb(208, 240, 253)',
  'Hypersensitivity: Iron oxide red (E172)': 'rgb(194, 245, 233)',
  'Hypersensitivity: Iron oxide yellow (E172)': 'rgb(255, 218, 246)',
  'Hypersensitivity: Lactose': 'rgb(255, 8, 194)',
  'Hypersensitivity: Lactose monohydrate': 'rgb(11, 118, 183)',
  'Hypersensitivity: Lauroyl Macrogolglycerides Type 1500': 'rgb(6, 160, 155)',
  'Hypersensitivity: Macrogol (8000)': 'rgb(156, 199, 255)',
  'Hypersensitivity: Macrogol (20,000)': 'rgb(119, 209, 243)',
  'Hypersensitivity: Magnesium iterate': 'rgb(255, 234, 182)',
  'Hypersensitivity: Magnesium stearate': 'rgb(156, 199, 255)',
  'Hypersensitivity: Magnesium stearate (E470b)': 'rgb(51, 138, 23)',
  'Hypersensitivity: Mannitol (E421)': 'rgb(39, 80, 174)',
  'Hypersensitivity: Peanuts': 'rgb(237, 226, 254)',
  'Hypersensitivity: Phosphoric acid': 'rgb(107, 28, 176)',
  'Hypersensitivity: Polysorbate 20': 'rgb(32, 217, 210)',
  'Hypersensitivity: Polysorbate 80': 'rgb(204, 204, 204)',
  'Hypersensitivity: Potassium chloride': 'rgb(237, 226, 254)',
  'Hypersensitivity: Potassium hydroxide': 'rgb(248, 43, 96)',
  'Hypersensitivity: Povidone K29-32': 'rgb(24, 191, 255)',
  'Hypersensitivity: Praluent': 'rgb(255, 220, 229)',
  'Hypersensitivity: Proline': 'rgb(249, 157, 226)',
  'Hypersensitivity: Propylene Glycol': 'rgb(156, 199, 255)',
  'Hypersensitivity: Red iron oxide (E172)': 'rgb(186, 30, 69)',
  'Hypersensitivity: Repatha': 'rgb(255, 169, 129)',
  'Hypersensitivity: Shellac': 'rgb(156, 199, 255)',
  'Hypersensitivity: Shellfish': 'rgb(51, 138, 23)',
  'Hypersensitivity: Silica, colloidal anhydrous': 'rgb(114, 221, 195)',
  'Hypersensitivity: Sodium hydrogen carbonate': 'rgb(204, 204, 204)',
  'Hypersensitivity: Sodium hydroxide': 'rgb(208, 240, 253)',
  'Hypersensitivity: Sodium lauilsulfate': 'rgb(184, 117, 3)',
  'Hypersensitivity: Sodium starch glycolate': 'rgb(254, 226, 213)',
  'Hypersensitivity: Soya': 'rgb(252, 180, 0)',
  'Hypersensitivity: Sucrose': 'rgb(32, 201, 51)',
  'Hypersensitivity: Talc (E553b)': 'rgb(255, 220, 229)',
  'Hypersensitivity: Tartaric Acid': 'rgb(237, 226, 254)',
  'Hypersensitivity: Titanium dioxide (E171)': 'rgb(249, 157, 226)',
  'Hypersensitivity: Triacetin': 'rgb(6, 160, 155)',
  'Hypersensitivity: VASCEPA': 'rgb(209, 247, 196)',
  'Hypersensitivity: Water for Injections': 'rgb(139, 70, 255)',
  'Hypersensitivity: Yellow iron oxide': 'rgb(255, 8, 194)',
  'Hypersensitivity: Pregelatinised starch': 'rgb(205, 176, 255)',
  'Hypersensitvity: Dimeticone 350': 'rgb(39, 80, 174)',
  Hypertriglyceridaemia: 'rgb(255, 111, 44)',
  'Known or suspected oesophageal varices': 'rgb(255, 8, 194)',
  'Liver disease associated with coagulopathy and clinically relevant bleeding risk':
    'rgb(205, 176, 255)',
  'Major intraspinal or intracerebral vascular abnormalities':
    'rgb(178, 21, 139)',
  'Malignant neoplasm': 'rgb(194, 245, 233)',
  'Malignant neoplasm with high bleeding risk': 'rgb(255, 214, 110)',
  'Mechanical Prosthetic Heart Valve': 'rgb(147, 224, 136)',
  'Hypersensitivity: Microcrystalline cellulose': 'rgb(194, 245, 233)',
  'Moderate or severe hepatic impairment': 'rgb(11, 118, 183)',
  Myopathy: 'rgb(249, 157, 226)',
  'N/A': 'rgb(207, 223, 255)',
  'Nursing mothers': 'rgb(252, 180, 0)',
  Pancreatitis: 'rgb(255, 158, 183)',
  'Photosensitivity to fibrates': 'rgb(215, 77, 38)',
  'Photosensitivity to keptoprofen': 'rgb(156, 199, 255)',
  'Hypersensitivity: Pregelatinised starch (maize)': 'rgb(207, 223, 255)',
  'Pregnancy or breastfeeding': 'rgb(208, 240, 253)',
  'Previous Stroke': 'rgb(147, 224, 136)',
  'Prior Stoke - when used for prophylaxis of atherothrombotic events after ACS or atherothrombotic events in CAD or PAD':
    'rgb(204, 204, 204)',
  'Prosthetic heart valves': 'rgb(255, 218, 246)',
  'Recent brain/spinal injury': 'rgb(255, 234, 182)',
  'Recent brain/spine/ophthalmic surgery': 'rgb(255, 214, 110)',
  'Recent intracranial haemorrhage': 'rgb(32, 201, 51)',
  'Severe Hepatic disease/impairment reducing life expectancy':
    'rgb(194, 245, 233)',
  'Severe Hepatic impairment including biliary cirrhosis or active liver disease':
    'rgb(32, 201, 51)',
  'Severe Hypersensitivity (to Apixaban)': 'rgb(6, 160, 155)',
  'Severe Hypersensitivity (to Dabigatran)': 'rgb(205, 176, 255)',
  'Severe Hypersensitivity (to Rivaroxaban)': 'rgb(32, 201, 51)',
  'Severe Renal Impairment': 'rgb(255, 218, 246)',
  'Significant bleeding risk': 'rgb(6, 160, 155)',
  'Significant or Chronic Bowel Disease': 'rgb(255, 234, 182)',
  'Hypersensitivity: Sodium starch glycolate (Type A)': 'rgb(119, 209, 243)',
  'Transient ischaemic attack': 'rgb(204, 204, 204)',
  'Transient ischaemic attack, when used for prophylaxis of atherothrombotic events after ACS':
    'rgb(24, 191, 255)',
  'Uncontrolled severe hypertension': 'rgb(194, 245, 233)',
  'Hypersensitivity: all-rac-alpha-tocophero': 'rgb(204, 204, 204)',
  'Hypersensitivity: Gelatin': 'rgb(252, 180, 0)',
  'Hypersensitivity: Glycerol': 'rgb(119, 209, 243)',
  'Hypersensitivity: Liquid Mannitol (E965 ii)': 'rgb(24, 191, 255)',
  'Hypersensitivity: Liquid sorbitol, non-crystallising (E420 ii)':
    'rgb(255, 111, 44)',
  'Hypersensitivity: Soya Lecithin': 'rgb(114, 221, 195)',
  'Concomitant use of Simvastation(>40 mg, daily)': 'rgb(51, 138, 23)',
  'Hypersensitivity: Microcrystalline cellulose (E460)': 'rgb(255, 218, 246)',
  'Hypersensitivity:  Partially hydrolysed poly(vinyl alcohol) (E1203)':
    'rgb(156, 199, 255)',
  'Hypersensitivity: Macrogol/PEG (E1521)': 'rgb(6, 160, 155)',
  None: 'rgb(119, 209, 243)',
  'Moderate-severe renal impairment (eGFR <60ml/min/1.72m2)':
    'rgb(255, 111, 44)',
  'Severe hepatic impairment ': 'rgb(209, 247, 196)',
  'Age <18 yo': 'rgb(194, 245, 233)',
  Rhabdomyolysis: 'rgb(68, 68, 68)',
}
