import { IApp } from 'interfaces/navigationApp.interface'

import { AccessGroups } from 'enums/AccessGroups'

import { BioriskCustomPages } from './BioriskCustomPages'
import { BioriskMenu, BioriskSlugs } from './BioriskMenu'
import { BioriskPages } from './BioriskPages'

const biorisk: IApp<BioriskSlugs> = {
  name: 'Biorisk',
  slug: 'biorisk',
  accessGroups: [AccessGroups.Pandemic],
  endpoint: 'science-macro',
  menu: BioriskMenu,
  pages: BioriskPages,
  customPages: BioriskCustomPages,
  createdAt: new Date(2021, 10, 29),
}

export default biorisk
