const warningMessages = {
  candidates:
    'IDA tracks all vaccines and treatment (including prophylactics) candidates across the entire infectious disease landscape, excluding COVID-19, influenza and respiratory syncytial virus (RSV) as these are covered in separate offerings. Preclinical, clinical, approved, discontinued and inactive (no new activity for 2 years) candidates are included. Candidates are identified via clinical trials, financial reports, company websites, media, press releases and other secondary sources. IDA tracks global candidates via robust monitoring, covering 2,000+ media and press releases across 15 languages and local clinical trial registries via the WHO website.',
  clinicalTrials:
    "IDA tracks all vaccines and treatment (including prophylactics) clinical trials across the entire infectious disease landscape, excluding COVID-19, influenza and respiratory syncytial virus (RSV) as these are covered in separate offerings. Clinical trials are sourced from 'clinicaltrials.gov' and the WHO's International Clinical Trials Registry Platform (ICTRP).",
  newsAndPressReleases:
    'Airfinity tracks 2,000+ media and press releases across 15 languages.',
  approvals:
    'IDA tracks approvals for vaccines and treatments (including prophylactics) across the entire infectious disease landscape, excluding COVID-19, influenza and respiratory syncytial virus (RSV) as these are covered in separate offerings. The scope of approval types includes Full approval, Emergency use approval, WHO prequalification, as well as expedited designations from the FDA (US), EMA (European Union) or MHRA (UK), only. The historical scope of approvals for vaccines is limited to the G20 and those granted WHO prequalification while the historical scope for treatments is limited to approvals granted by the FDA, European Commission or MHRA. For certain countries, approvals may be incomplete due to the structure of the data available. For both vaccines and treatments, approvals granted after May 2022 are collected from all countries where reported in media. Approvals are collected from national drug approval databases, publications, financial reports, company websites, media, press releases and other secondary sources. Some approvals have been inferred based on the sale of the vaccine or treatment in a particular country.',
  revenue:
    "Airfinity tracks pharmaceutical companies' publicly shared quarterly earnings press releases to collect infectious disease vaccine product sales revenue data per quarter and region since 2012, where available. Annotation such as location (region) as well as vaccine name/s (candidates) are as stated in the source, and candidates are either confirmed or assumed based on the information available in the press release and vaccine launch timelines. For example, Pfizer's pneumococcal vaccines are reported as Prevnar family, or GSK's Gardasil vaccines are reported together in some years. In that case all matching vaccines have been linked, assuming that all contributed to the revenue. If reported in currency other than USD in the source, values have been exchanged to USD based on exchange rate that year.",
  supplyAndPrice:
    'Airfinity collects infectious disease vaccine supply and price information made publicly available via WHO MI4 dataset and tenders, as well as media and press releases, which are tracked daily. WHO MI4 data has been deanonymised to reveal countries and vaccine brands. Data is categorised under various deal types depending on level of data availability, for example by product or manufacturer. All data points are linked to a region and income band, and most are also linked to a country. Data dates back to 2005 for some vaccines.',
  candidatesAmr:
    'The WHO has published priority pathogen lists for pathogens that are demonstrating antimicrobial resistance (AMR). Using these priority pathogen lists, we examined our treatment candidates to see if they are targeting, for instance, general Staphylococcus aureus, or, an AMR strain, such as methicillin-resistant Staphylococcus aureus. The AMR strain the candidate is targeting can be found in the ‘Target Strains’ column. Information on resistant strains targeted was collected through clinical trials, financial reports, company websites, media, press releases and other secondary sources. The list of candidates is limited to clinical-stage treatments.',
  supplyPriceNewNote:
    '2,393 new records have been added to the Supply & Price section covering data up to and including 2023. This covers new information on vaccine volumes procured and prices paid by countries, regions and income bands, released via the MI4A/V3P vaccine purchase database. Usually country names are anonymised however Airfinity has been able to identify 80% (164/205) of the countries reporting, giving you the most granular supply and pricing information possible. ',
}

export default warningMessages
