import { Box } from '@chakra-ui/react'

import ArticleModal from 'modules/ArticleModal'
import GenericDrawer from 'modules/GenericDrawer'

import { IResponseBase } from 'api/types'

import { IModel } from 'interfaces/model.interface'
import { ViewData } from 'interfaces/navigationPage.interface'

type SelectedDrawerPropTypes<D extends Object> = {
  selectedRow: any
  model: IModel<D, any>
  viewData: ViewData
  setRow: (value: IResponseBase<Extract<keyof D, string>> | null) => void
}

/** We separate this to another component so our main table doesn't keep rerendering */
export default function SelectedDrawer<D extends Object>({
  selectedRow,
  model,
  viewData,
  setRow,
}: SelectedDrawerPropTypes<D>) {
  return (
    <Box className={model.noPrint === true ? 'no-print' : ''}>
      {selectedRow &&
        (model.renderDetailView ? (
          model.renderDetailView({
            model,
            data: selectedRow,
            setSelectedRow: setRow,
            viewData,
          })
        ) : model.detailViewType === 'Generic' ? (
          <GenericDrawer
            entry={selectedRow}
            title={model.displayKey}
            model={model}
            handleClose={() => setRow(null)}
            viewData={viewData}
          />
        ) : model.detailView ? (
          <ArticleModal
            isOpen={true}
            title={model.displayKey ? selectedRow[model.displayKey] : ''}
            link={selectedRow[model.detailView.link]}
            handleClose={() => setRow(null)}
            abstract={selectedRow[model.detailView.abstract]}
            noPrint={model.noPrint}
          />
        ) : null)}
    </Box>
  )
}
