import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import Map, {
  Source,
  Layer,
  MapRef,
  MapLayerMouseEvent,
} from 'react-map-gl/maplibre'
import './styles.css'

import { IResponseBase } from 'api/types'
import { dataLayer } from './map-styles'
import {
  FeatureCollection,
  GEO_POLYGONS,
  MAP_STYLES,
  countryNameMapping,
  getHighestNum,
  updatePercentiles,
} from './utils'
import { useTableChartFilters } from 'contexts/TableChartFilters'

import Popover from './Popover'
import HeatMapLegend from '../Cards/HeatMapLegend'
import { Box, Flex, Text } from '@chakra-ui/react'

function MapDashboard({ trialsData }: { trialsData: IResponseBase[] }) {
  const [allData, setAllData] = useState<FeatureCollection | null>(null)
  const [hoverInfo, setHoverInfo] = useState<any>(null)

  const mapRef = useRef<MapRef>(null)

  const { tableFilters, addToFilters } = useTableChartFilters()

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          GEO_POLYGONS['world'] || GEO_POLYGONS.world
        )
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`)
        }
        const json = await response.json()
        setAllData(json)
      } catch (err) {
        if (err instanceof Error && err.name !== 'AbortError') {
          console.error('Could not load data', err)
        }
      }
    }

    fetchData()
  }, [])

  const onClick = useCallback(
    (event: MapLayerMouseEvent) => {
      const mapLibreName = event.features?.[0]?.properties?.name
      if (!mapLibreName) return

      const airfinityName = countryNameMapping[mapLibreName] ?? mapLibreName

      const newFilters = tableFilters.filter((f) => f.column !== 'areas')
      if (newFilters.length === tableFilters.length) {
        newFilters.push({
          column: 'areas',
          filterValue: [{ label: airfinityName, value: airfinityName }],
          type: 'includesAll',
        })
      }
      addToFilters(newFilters)
    },
    [addToFilters, tableFilters]
  )

  const onHover = useCallback((event: MapLayerMouseEvent) => {
    const hoveredFeature = event.features?.[0]

    if (!hoveredFeature) {
      setHoverInfo(null)
      return
    }

    let properties = null
    if (hoveredFeature.properties?.properties) {
      try {
        properties = JSON.parse(hoveredFeature.properties.properties)
      } catch (error) {
        console.error('Failed to parse properties JSON', error)
      }
    }

    setHoverInfo({
      feature: hoveredFeature,
      properties,
      x: event.point.x,
      y: event.point.y,
    })
  }, [])

  const data = useMemo(() => {
    if (!allData) return []
    const res = allData && updatePercentiles(allData, trialsData)
    return res
  }, [allData, trialsData]) as unknown as FeatureCollection

  const highestNum = useMemo(() => getHighestNum(data), [data])

  const map = useMemo(() => {
    return (
      <Map
        renderWorldCopies={false}
        initialViewState={{
          longitude: 0,
          latitude: 0,
          zoom: 0,
        }}
        preserveDrawingBuffer={true}
        ref={mapRef}
        id='myMap'
        interactiveLayerIds={['data']}
        onMouseMove={onHover}
        onClick={onClick}
        mapStyle={MAP_STYLES['gray-labels']}
      >
        <Source type='geojson' data={data}>
          <Layer {...(dataLayer(highestNum) as any)} />
        </Source>
      </Map>
    )
  }, [data, highestNum, onClick, onHover])

  return (
    <Box
      position={'relative'}
      height={'full'}
      width={'full'}
      borderRadius={'sm'}
      border='1px'
      borderColor={'gray.200'}
    >
      {map}

      <Flex
        border={'1px'}
        borderColor={'gray.200'}
        h={7}
        w='max-content'
        px={2}
        position={'absolute'}
        top={1}
        left={1}
        alignItems={'center'}
        bg='gray.100'
        gap={1}
        borderRadius={'sm'}
      >
        <Text
          fontWeight={'semibold'}
          color={'gray.800'}
          lineHeight={'14px'}
          fontSize={'12px'}
        >
          Total trials
        </Text>
        <Text lineHeight={'14px'} fontSize={'12px'} fontWeight={'semibold'}>
          {trialsData.length}
        </Text>
      </Flex>
      <HeatMapLegend highestNum={highestNum} />
      {hoverInfo && <Popover hoverInfo={hoverInfo} />}
    </Box>
  )
}

export default MapDashboard
