import { isValid, parseISO } from 'date-fns'
import { IdType } from 'react-table'
import _ from 'underscore'

import { SelectOption } from 'components/Select'

import { IResponseBase } from 'api/types'

import { ValueType } from 'interfaces/valueType.interface'

import { relationGetDisplayValue } from 'utils/relational'

export type FilterType<T> = {
  key: FilterTypeKeys
  label: string
  // Function to filter the rows with
  func: (
    rows: IResponseBase[],
    id: IdType<any>,
    filterValue: T
  ) => IResponseBase[]
  // Function to determine whether the filter should resolve/calculate
  shouldCalculateFunc?: (filterValue: T) => boolean
  // Default value of the filter on initialize
  defaultValue?: T // Might want to make this more flexible
}

export type FilterTypeKeys =
  // String
  | 'startsWith'
  | 'containsAny'
  | 'doesNotContain'
  // Multi
  | 'includesAll'
  | 'includesAny'
  | 'doesNotInclude'
  | 'includesOnly'
  // Single
  | 'includesSingle'
  | 'doesNotIncludeSingle'
  // Number
  | 'isLessThanOrEqualToNumber'
  | 'isGreaterThanOrEqualToNumber'
  | 'isEqualToNumber'
  | 'isBetweenNumber'
  // Date
  | 'isLessThanOrEqualToDate'
  | 'isGreaterThanOrEqualToDate'
  | 'isEqualToDate'
  | 'isBetweenDate'
  // Boolean
  | 'isTrue'
  // Any
  | 'isBlank'

export function getAllowedFilterType(type?: ValueType): FilterType<any>[] {
  switch (type) {
    case ValueType.SINGLE:
      return [includesSingle, doesNotIncludeSingle, isBlank]
    case ValueType.MULTI:
      return [includesAll, includesAny, includesOnly, doesNotInclude, isBlank]
    case ValueType.DATE:
      return [
        isLessThanOrEqualToDate,
        isGreaterThanOrEqualToDate,
        isEqualToDate,
        isBetweenDate,
        isBlank,
      ]
    case ValueType.NUMBER:
      return [
        isLessThanOrEqualToNumber,
        isGreaterThanOrEqualToNumber,
        isEqualToNumber,
        isBetweenNumber,
        isBlank,
      ]
    case ValueType.BOOLEAN:
      return [isTrue, isBlank]
    case ValueType.URL:
    case ValueType.LOGO:
    case ValueType.LONG:
    case ValueType.FILES:
    case ValueType.TWEET:
    case ValueType.TEXT:
    default:
      return [startsWith, containsAny, doesNotContain, isBlank]
  }
}

export function getDefaultValueFromFilterTypeKey(key: FilterTypeKeys) {
  return allFilterTypes.find((x) => x.key === key)?.defaultValue ?? ''
}

// ===============================
// ========== STRING =============
// ===============================

const startsWith: FilterType<string> = {
  key: 'startsWith',
  label: 'starts with',
  func: (rows, column, filterValue) => {
    return rows.filter((row) => {
      const value = relationGetDisplayValue(row[column])
      if (typeof value === 'number') {
        return false
      }
      if (typeof value === 'string') {
        return value
          ?.toString()
          ?.toLowerCase()
          ?.startsWith(filterValue.toLowerCase())
      }
      if (!value) return false
      return value
        ?.flat()
        ?.toString()
        ?.toLowerCase()
        ?.startsWith(filterValue.toLowerCase())
    })
  },
}

const containsAny: FilterType<string> = {
  key: 'containsAny',
  label: 'contains any',
  func: (rows, column, filterValue) => {
    return rows.filter((row) => {
      const value = relationGetDisplayValue(row[column])
      if (typeof value === 'number') {
        return false
      }
      if (typeof value === 'string') {
        return value
          ?.toString()
          ?.toLowerCase()
          ?.includes(filterValue.toLowerCase())
      }
      return value
        ?.flat()
        ?.toString()
        ?.toLowerCase()
        ?.includes(filterValue.toLowerCase())
    })
  },
}

const doesNotContain: FilterType<string> = {
  key: 'doesNotContain',
  label: 'does not contain',
  func: (rows, column, filterValue) => {
    return rows.filter((row) => {
      const value = relationGetDisplayValue(row[column])
      if (typeof value === 'number') {
        return false
      }
      if (typeof value === 'string') {
        return !value
          ?.toString()
          ?.toLowerCase()
          ?.includes(filterValue.toLowerCase())
      }
      return !value
        ?.flat()
        ?.toString()
        ?.toLowerCase()
        ?.includes(filterValue.toLowerCase())
    })
  },
}

// ===============================
// =========== MULTI =============
// ===============================

const includesAll: FilterType<SelectOption<string>[]> = {
  key: 'includesAll',
  label: 'includes all',
  func: (rows, column, filterValue) => {
    return rows.filter(
      (row) =>
        filterValue?.every((x) => {
          let value = relationGetDisplayValue(row[column])

          if (typeof value === 'number') {
            return value === +x.value
          }

          if (typeof value === 'string') {
            value = value.split(',')
          }

          return value?.flat().includes(x.value) ?? false
        }) ?? false
    )
  },
  defaultValue: [],
  shouldCalculateFunc: (filterValue) => filterValue?.length > 0,
}

const includesOnly: FilterType<SelectOption<string>[]> = {
  key: 'includesOnly',
  label: 'includes only',
  func: (rows, column, filterValue) => {
    return rows.filter(
      (row) =>
        filterValue?.some((x: any) => {
          let value = relationGetDisplayValue(row[column])

          if (typeof value === 'number') {
            return value === +x.value
          }

          if (typeof value === 'string') {
            value = value.split(',')
          }

          return value?.flat().includes(x.value) && value?.flat().length === 1
        }) ?? false
    )
  },
  defaultValue: [],
  shouldCalculateFunc: (filterValue) => filterValue?.length > 0,
}

const includesAny: FilterType<SelectOption<string>[]> = {
  key: 'includesAny',
  label: 'includes any',
  func: (rows, column, filterValue) => {
    return rows.filter(
      (row) =>
        filterValue?.some((x: any) => {
          let value = relationGetDisplayValue(row[column])

          if (typeof value === 'number') {
            return value === +x.value
          }

          if (typeof value === 'string') {
            value = value.split(',')
          }

          return value?.flat().includes(x.value) ?? false
        }) ?? false
    )
  },
  defaultValue: [],
  shouldCalculateFunc: (filterValue) => filterValue?.length > 0,
}

const doesNotInclude: FilterType<SelectOption<string>[]> = {
  key: 'doesNotInclude',
  label: 'does not include',
  func: (rows, column, filterValue) => {
    return rows.filter(
      (row) =>
        !filterValue?.some((x: any) => {
          let value = relationGetDisplayValue(row[column])

          if (typeof value === 'number') {
            return value === +x.value
          }

          if (typeof value === 'string') {
            value = value.split(',')
          }

          return value?.flat().includes(x.value) ?? false
        })
    )
  },
  defaultValue: [],
  shouldCalculateFunc: (filterValue) => filterValue?.length > 0,
}

// ===============================
// =========== SINGLE ============
// ===============================

const includesSingle: FilterType<SelectOption<string>[]> = {
  key: 'includesSingle',
  label: 'includes',
  func: (rows, column, filterValue) => {
    return rows.filter((row) => {
      const columnData = relationGetDisplayValue(row[column])
      return (
        filterValue?.some((x: any) =>
          (Array.isArray(columnData) ? columnData : [columnData]).some((y) => {
            if (isNaN(x.value)) return y === x.value
            return y === +x.value
          })
        ) ?? false
      )
    })
  },
  defaultValue: [],
  shouldCalculateFunc: (filterValue) => filterValue?.length > 0,
}

const doesNotIncludeSingle: FilterType<SelectOption<string>[]> = {
  key: 'doesNotIncludeSingle',
  label: 'does not include',
  func: (rows, column, filterValue) => {
    return rows.filter((row) => {
      const columnData = relationGetDisplayValue(row[column])
      return !filterValue?.some((x: any) =>
        (Array.isArray(columnData) ? columnData : [columnData]).includes(
          isNaN(x.value) ? x.value : +x.value
        )
      )
    })
  },
  defaultValue: [],
  shouldCalculateFunc: (filterValue) => filterValue?.length > 0,
}

// ===============================
// =========== NUMBER ============
// ===============================

const processNumberRowData = (row: IResponseBase, column: string): number => {
  const rowData = relationGetDisplayValue(row[column])
  if (!rowData) return NaN

  const dataValue =
    typeof rowData === 'number' ? rowData : parseInt(rowData?.toString(), 10)

  return dataValue
}

const isLessThanOrEqualToNumber: FilterType<number> = {
  key: 'isLessThanOrEqualToNumber',
  label: 'is less than or equal to',
  func: (rows, column, filterValue) => {
    return rows.filter((row) => {
      const dataValue = processNumberRowData(row, column)

      return dataValue <= filterValue
    })
  },
}

const isGreaterThanOrEqualToNumber: FilterType<number> = {
  key: 'isGreaterThanOrEqualToNumber',
  label: 'is greater than or equal to',
  func: (rows, column, filterValue) => {
    return rows.filter((row) => {
      const dataValue = processNumberRowData(row, column)

      return dataValue >= filterValue
    })
  },
}

const isEqualToNumber: FilterType<number> = {
  key: 'isEqualToNumber',
  label: 'is equal to',
  func: (rows, column, filterValue) => {
    return rows.filter((row) => {
      const dataValue = processNumberRowData(row, column)

      return dataValue === filterValue
    })
  },
}

const isBetweenNumber: FilterType<number[]> = {
  key: 'isBetweenNumber',
  label: 'is between',
  func: (rows, column, filterValue) => {
    return rows.filter((row) => {
      const dataValue = processNumberRowData(row, column)

      const min = filterValue[0]
      const max = filterValue[1]

      return min < dataValue && dataValue < max
    })
  },
  shouldCalculateFunc: (filterValue) =>
    Number.isSafeInteger(filterValue[0]) &&
    Number.isSafeInteger(filterValue[1]),
  defaultValue: [0, 0],
}

// ===============================
// ============ DATE =============
// ===============================

const processDateRowData = (
  row: IResponseBase,
  column: string
): Date | boolean => {
  const rowData = relationGetDisplayValue(row[column])
  if (!rowData || typeof rowData !== 'string') return false

  const dataValue = parseISO(rowData)
  if (!isValid(dataValue)) return false

  return dataValue
}

const isLessThanOrEqualToDate: FilterType<Date> = {
  key: 'isLessThanOrEqualToDate',
  label: 'is less than or equal to',
  func: (rows, column, filterValue) => {
    return rows.filter((row) => {
      const dataValue = processDateRowData(row, column)
      if (!dataValue) return false

      return dataValue <= filterValue
    })
  },
  defaultValue: new Date(),
}

const isGreaterThanOrEqualToDate: FilterType<Date> = {
  key: 'isGreaterThanOrEqualToDate',
  label: 'is greater than or equal to',
  func: (rows, column, filterValue) => {
    return rows.filter((row) => {
      const dataValue = processDateRowData(row, column)
      if (!dataValue) return false

      return dataValue >= filterValue
    })
  },
  defaultValue: new Date(),
}

const isEqualToDate: FilterType<Date> = {
  key: 'isEqualToDate',
  label: 'is equal to',
  func: (rows, column, filterValue) => {
    return rows.filter((row) => {
      const dataValue = processDateRowData(row, column)
      if (!dataValue) return false

      return dataValue === filterValue
    })
  },
  defaultValue: new Date(),
}

const isBetweenDate: FilterType<Date[]> = {
  key: 'isBetweenDate',
  label: 'is between',
  func: (rows, column, filterValue) => {
    return rows.filter((row) => {
      const dataValue = processDateRowData(row, column)
      if (!dataValue) return false

      const min = filterValue[0]
      const max = filterValue[1]

      return min < dataValue && dataValue < max
    })
  },
  shouldCalculateFunc: (filterValue) =>
    _.isDate(filterValue[0]) && _.isDate(filterValue[1]),
  defaultValue: [new Date(), new Date()],
}

// ==================================
// ============ BOOLEAN =============
// ==================================

const isTrue: FilterType<boolean> = {
  key: 'isTrue',
  label: 'is true',
  func: (rows, column, filterValue) => {
    return rows.filter((row) => {
      const dataValue = !!row[column]
      return dataValue === filterValue
    })
  },
  shouldCalculateFunc: (filterValue) => typeof filterValue === 'boolean',
  defaultValue: false,
}

// ==============================
// ============ ANY =============
// ==============================

const isBlank: FilterType<boolean> = {
  key: 'isBlank',
  label: 'is blank',
  func: (rows, column, filterValue) => {
    return rows.filter((row) => {
      const dataValue = row[column]
      if (Array.isArray(dataValue)) {
        return (dataValue.length === 0) === filterValue
      }
      return !dataValue === filterValue
    })
  },
  shouldCalculateFunc: (filterValue) => typeof filterValue === 'boolean',
  defaultValue: false,
}

export const allFilterTypes: FilterType<any>[] = [
  startsWith,
  containsAny,
  doesNotContain,
  includesAll,
  includesAny,
  includesOnly,
  doesNotInclude,
  includesSingle,
  doesNotIncludeSingle,
  isLessThanOrEqualToNumber,
  isGreaterThanOrEqualToNumber,
  isEqualToNumber,
  isBetweenNumber,
  isLessThanOrEqualToDate,
  isGreaterThanOrEqualToDate,
  isEqualToDate,
  isBetweenDate,
  isTrue,
  isBlank,
]
