import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
} from '@chakra-ui/accordion'
import { useDisclosure } from '@chakra-ui/hooks'
import { useEffect } from 'react'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'

import { Report } from 'api/types'

const TableOfContent = ({
  report,
  onResize,
}: {
  report: Report
  onResize?: () => void
}) => {
  const { isOpen, onToggle } = useDisclosure()

  useEffect(() => {
    onResize?.()
  }, [onResize, isOpen])

  if (!report.tableContent || report.tableContent === '') return null

  return (
    <Accordion
      index={isOpen ? [0] : []}
      onChange={onToggle}
      allowToggle
      reduceMotion
    >
      <AccordionItem fontSize='sm' border='hidden'>
        <AccordionButton
          lineHeight='none'
          padding='0'
          fontSize='md'
          my={4}
          p={2}
          fontWeight={600}
          _focus={{ boxShadow: 'none' }}
        >
          Table of Contents
          <AccordionIcon ml='auto' />
        </AccordionButton>

        <AccordionPanel
          width='fit-content'
          py={1.2}
          color='gray.700'
          lineHeight='tall'
        >
          <ReactMarkdown rehypePlugins={[rehypeRaw]}>
            {report.tableContent}
          </ReactMarkdown>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  )
}

export default TableOfContent
