import { IApp } from 'interfaces/navigationApp.interface'

import { AccessGroups } from 'enums/AccessGroups'

import { ObesityEpidemiologyCustomPages } from './ObesityEpidemiologyCustomPages'
import {
  ObesityEpidemiologyMenu,
  ObesityEpidemiologySlugs,
} from './ObesityEpidemiologyMenu'
import { ObesityEpidemiologyPages } from './ObesityEpidemiologyPages'

const ObesityEpidemiology: IApp<ObesityEpidemiologySlugs> = {
  name: 'Epidemiology',
  slug: 'obesity-epidemiology',
  endpoint: 'obesity-360',
  accessGroups: [AccessGroups.Obesity360],
  menu: ObesityEpidemiologyMenu,
  pages: ObesityEpidemiologyPages,
  customPages: ObesityEpidemiologyCustomPages,
  appIdentifier: 'obesity-epidemiology',
  createdAt: new Date(2022, 6, 8),
  disableAnalytics: true,
}

export default ObesityEpidemiology
