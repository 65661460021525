import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios'

import useAxios from './useAxios'

export interface QuestionParam {
  app: string
  message: string
  page?: string
  subpage?: string
  url: string
}

export const submitQuestion = async (
  axios: AxiosInstance,
  app: string,
  message: string,
  url: string,
  page?: string,
  subpage?: string
) => {
  return await axios.post(`/submit-question/`, {
    app,
    message,
    page,
    subpage,
    url,
  })
}

const useSubmitQuestionMutation = (
  extraProps?: UseMutationOptions<
    AxiosResponse<any>,
    AxiosError<any>,
    QuestionParam
  >
) => {
  const axios = useAxios()

  return useMutation<AxiosResponse<any>, AxiosError<any>, QuestionParam>(
    ({ app, message, page, subpage, url }) => {
      return submitQuestion(axios, app, message, url, page, subpage)
    },
    extraProps
  )
}

export default useSubmitQuestionMutation
