import { IApp } from 'interfaces/navigationApp.interface'

import { AccessGroups } from 'enums/AccessGroups'

import { ModernaRSVCommercialCustomPages } from './ModernaRSVCommercialCustomPages'
import {
  ModernaRSVCommercialMenu,
  ModernaRSVCommercialSlugs,
} from './ModernaRSVCommercialMenu'

const ModernaRSVCommercialApp: IApp<ModernaRSVCommercialSlugs> = {
  name: 'RSV',
  slug: 'moderna-rsv-commercial',
  accessGroups: [AccessGroups.Moderna],
  commonIdentifier: 'rsv',
  endpoint: 'rsv-market-and-production',
  menu: ModernaRSVCommercialMenu,
  pages: {},
  customPages: ModernaRSVCommercialCustomPages,
  createdAt: new Date(2022, 5, 30),
}

export default ModernaRSVCommercialApp
