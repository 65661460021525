import { Information, Launch } from '@carbon/icons-react'
import { Box, Flex, Text } from '@chakra-ui/react'

import { useAppParams } from 'routes/utils'

import { Panel } from 'components'

import { apps } from 'config/apps'

import { isNew } from 'utils/isNew'
import useIsMobile from 'utils/useIsMobile'
import { NavLink } from 'react-router-dom'

interface EmbedNoteProps {
  heading: string
  text: string
  navigate?: string
}

const EmbedNote = ({ heading, text, navigate }: EmbedNoteProps) => {
  const [isMobile] = useIsMobile()
  const { pageSlug, app, subPageSlug } = useAppParams()
  const selectedApp = apps[app]
  const menus = selectedApp.menu.flat()

  const selectedMenu = menus.find((menu) => menu.slug === pageSlug)

  const page = selectedMenu?.children
    ? selectedMenu.children.find((menu) => menu.slug === subPageSlug)
    : selectedMenu

  if (page?.updatedAt && !isNew(page.updatedAt)) return null

  return (
    <Panel
      px={2}
      py={1}
      my={2}
      ml={-1}
      maxW='600px'
      w={isMobile ? '100%' : '33vw'}
      border='1px solid'
      borderColor='gray3'
      borderRadius='8px'
      mb={2}
      shadow={'sm'}
    >
      <Flex alignItems={'center'} gap={1}>
        <Information />
        <Text fontSize={'14px'} fontWeight={'semibold'}>
          {heading}
        </Text>
      </Flex>
      <Text fontSize={'13px'}>{text}</Text>
      {navigate && (
        <NavLink to={navigate}>
          <Box display={'flex'} alignItems={'center'} gap={1}>
            <Text fontSize={'13px'} fontWeight={'semibold'} color='black'>
              Navigate
            </Text>
            <Launch />
          </Box>
        </NavLink>
      )}
    </Panel>
  )
}

export default EmbedNote
