import { UseQueryOptions, useQuery } from '@tanstack/react-query'
import { AxiosError, AxiosInstance } from 'axios'

import { apps, appsListUnion } from 'config/apps'

import { recursiveCamelCase } from '../../helper'
import useAxios from '../../useAxios'
import { Highlight } from './types'

export default function useHighlights(
  app: appsListUnion,
  extraProps?: UseQueryOptions<any, any>
) {
  const axios = useAxios()

  return useQuery<Highlight[], AxiosError>(
    ['highlights', app],
    () => fetchHighlights(axios, app),
    { ...extraProps, enabled: !!app }
  )
}

export const fetchHighlights = async (
  axios: AxiosInstance,
  app: appsListUnion
): Promise<Highlight[]> => {
  return axios
    .get(`/${apps[app].endpoint}/analyst-highlights/`)
    .then((res) => recursiveCamelCase(res.data))
}
