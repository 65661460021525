export default {
  'Clinical Development': 'rgb(207, 223, 255)',
  'Efficacy and Safety': 'rgb(208, 240, 253)',
  'Finances and Stocks': 'rgb(194, 245, 233)',
  Funding: 'rgb(209, 247, 196)',
  'Manufacturing and Production': 'rgb(255, 234, 182)',
  'Preclinical Development': 'rgb(254, 226, 213)',
  Price: 'rgb(255, 220, 229)',
  'Strategy and Regulatory': 'rgb(255, 218, 246)',
  Supply: 'rgb(237, 226, 254)',
  'Vaccine Delivery': 'rgb(238, 238, 238)',
  Seroresponsiveness: 'rgb(255, 169, 129)',
  'Production of Viral Psuedotypes': 'rgb(68, 68, 68)',
  'Vaccine Development': 'rgb(255, 8, 194)',
  'Vaccine Protection': 'rgb(119, 209, 243)',
  'Safety and Efficacy': 'rgb(119, 209, 243)',
  Prophylactics: 'rgb(255, 218, 246)',
  'Adjuvant Development': 'rgb(51, 138, 23)',
  'Attitudes Towards Vaccination': 'rgb(51, 138, 23)',
  'Vaccine Uptake': 'rgb(255, 218, 246)',
  'Treatment Regimen': 'rgb(208, 240, 253)',
  'Cost-Effectiveness': 'rgb(205, 176, 255)',
  'Vaccine Progress': 'rgb(204, 204, 204)',
  'Treatment Development': 'rgb(156, 199, 255)',
  'Treatment Uptake': 'rgb(32, 217, 210)',
  'Vaccine Production': 'rgb(107, 28, 176)',
  'Disease Burden': 'rgb(255, 8, 194)',
  'Evaluation of Influenza Vacine': 'rgb(207, 223, 255)',
  'Vaccination Coverage RATE': 'rgb(207, 223, 255)',
  'Acute Coronary Syndrome': 'rgb(139, 70, 255)',
  'Acute Kidney Injury': 'rgb(255, 111, 44)',
  Adherence: 'rgb(255, 8, 194)',
  'Age-related Differences': 'rgb(24, 191, 255)',
  ALD: 'rgb(68, 68, 68)',
  'All-cause Mortality': 'rgb(248, 43, 96)',
  Amputation: 'rgb(209, 247, 196)',
  Angiodema: 'rgb(102, 102, 102)',
  'Animal Models': 'rgb(255, 218, 246)',
  Antioxidants: 'rgb(119, 209, 243)',
  APIs: 'rgb(252, 180, 0)',
  ApoAI: 'rgb(194, 245, 233)',
  ApoB: 'rgb(255, 8, 194)',
  'ApoC-III': 'rgb(6, 160, 155)',
  Approvals: 'rgb(147, 224, 136)',
  APTT: 'rgb(252, 180, 0)',
  Arthroplasty: 'rgb(32, 201, 51)',
  Ascites: 'rgb(156, 199, 255)',
  ASCVD: 'rgb(255, 158, 183)',
  Atherosclerosis: 'rgb(156, 199, 255)',
  'Atrial Fibrillation': 'rgb(255, 158, 183)',
  Biomarkers: 'rgb(11, 118, 183)',
  'Bioprosthetic Heart Valves': 'rgb(139, 70, 255)',
  Bleeding: 'rgb(207, 223, 255)',
  'Blood Pressure': 'rgb(238, 238, 238)',
  BMI: 'rgb(6, 160, 155)',
  CAD: 'rgb(209, 247, 196)',
  Cancer: 'rgb(248, 43, 96)',
  'Cardiac Structure/Function': 'rgb(255, 220, 229)',
  Cardiotoxicity: 'rgb(249, 157, 226)',
  'Cell Models': 'rgb(237, 226, 254)',
  'Cell Therapy': 'rgb(215, 77, 38)',
  'Cerebral Venous Sinus Thrombosis': 'rgb(51, 138, 23)',
  'Cerebral Venous Thrombosis': 'rgb(156, 199, 255)',
  CeVD: 'rgb(255, 158, 183)',
  CHAD: 'rgb(255, 111, 44)',
  'Chronic limb threatening ischaemia': 'rgb(194, 245, 233)',
  Cirrhosis: 'rgb(114, 221, 195)',
  'Clotting Time': 'rgb(255, 111, 44)',
  'Coincident disease state': 'rgb(255, 214, 110)',
  'Combination Therapies': 'rgb(45, 127, 249)',
  'Coronary Bypass Surgery': 'rgb(114, 221, 195)',
  'COVID-19': 'rgb(147, 224, 136)',
  'COVID-19 Vaccine': 'rgb(32, 217, 210)',
  'Critical Limb Ischaemia': 'rgb(254, 226, 213)',
  'CV Death': 'rgb(107, 28, 176)',
  'Deep Vein Thrombosis': 'rgb(205, 176, 255)',
  Dermic: 'rgb(252, 180, 0)',
  Diagnostics: 'rgb(32, 201, 51)',
  'Diastolic Function': 'rgb(255, 8, 194)',
  'Diet & Exercise': 'rgb(6, 160, 155)',
  Diuretic: 'rgb(255, 111, 44)',
  DOACs: 'rgb(255, 214, 110)',
  Dosing: 'rgb(32, 201, 51)',
  'Drug Availability': 'rgb(255, 158, 183)',
  'Drug Comparison': 'rgb(237, 226, 254)',
  'Drug Formulation': 'rgb(32, 217, 210)',
  'Drug-drug Interactions': 'rgb(207, 223, 255)',
  Economic: 'rgb(204, 204, 204)',
  Efficacy: 'rgb(237, 226, 254)',
  'Elderly Patients': 'rgb(255, 218, 246)',
  'Embolic Stroke': 'rgb(119, 209, 243)',
  'End-stage Renal Disease': 'rgb(249, 157, 226)',
  Epidemiology: 'rgb(6, 160, 155)',
  Epigenetics: 'rgb(255, 169, 129)',
  Ethnicity: 'rgb(139, 70, 255)',
  Excretion: 'rgb(39, 80, 174)',
  'Eye-related': 'rgb(255, 111, 44)',
  'Factor Xa Reversal': 'rgb(139, 70, 255)',
  'Familial Hypercholesterolaemia': 'rgb(205, 176, 255)',
  FCS: 'rgb(255, 214, 110)',
  'Femoropopliteal Occlusive Disease': 'rgb(255, 234, 182)',
  Fibrosis: 'rgb(207, 223, 255)',
  'FT not available': 'rgb(252, 180, 0)',
  FXI: 'rgb(32, 201, 51)',
  'Gastrointestinal Bleeding': 'rgb(32, 217, 210)',
  'Gender related differences': 'rgb(119, 209, 243)',
  'Gene Expression': 'rgb(204, 204, 204)',
  'Gene Therapy': 'rgb(237, 226, 254)',
  Guidelines: 'rgb(32, 217, 210)',
  'Gut Microbiota': 'rgb(45, 127, 249)',
  'Gut-related': 'rgb(32, 201, 51)',
  'HDL-C': 'rgb(255, 158, 183)',
  'Heart Failure': 'rgb(248, 43, 96)',
  'Hematological Events': 'rgb(32, 201, 51)',
  Hemodialysis: 'rgb(119, 209, 243)',
  'Hemorrhagic Risk': 'rgb(255, 169, 129)',
  'Hemorrhagic stroke': 'rgb(156, 199, 255)',
  Hemothorax: 'rgb(51, 138, 23)',
  'Hepatitis B': 'rgb(11, 118, 183)',
  'Hepatitis C': 'rgb(147, 224, 136)',
  HF: 'rgb(194, 245, 233)',
  HFpEF: 'rgb(51, 138, 23)',
  HFrEF: 'rgb(6, 160, 155)',
  HHF: 'rgb(178, 21, 139)',
  'Hip Fracture Patients': 'rgb(32, 201, 51)',
  HIV: 'rgb(255, 218, 246)',
  Hospitalisation: 'rgb(255, 218, 246)',
  hsCRP: 'rgb(255, 234, 182)',
  'Immunological Events': 'rgb(39, 80, 174)',
  Infection: 'rgb(147, 224, 136)',
  Inflammation: 'rgb(255, 234, 182)',
  'Intermittent Pneumatic Compression': 'rgb(39, 80, 174)',
  'Intracerebral Hemorrhage': 'rgb(255, 111, 44)',
  'Intracranial Atherosclerotic Disease': 'rgb(255, 220, 229)',
  'Intracranial Hemorrhage': 'rgb(255, 234, 182)',
  'Intravenous Anticoagulation': 'rgb(255, 111, 44)',
  'Ischaemic Stroke': 'rgb(255, 218, 246)',
  'LDL-C': 'rgb(255, 214, 110)',
  'Leg Clots': 'rgb(255, 169, 129)',
  Lipodystrophy: 'rgb(249, 157, 226)',
  'Liver Events': 'rgb(205, 176, 255)',
  'Lp(a)': 'rgb(102, 102, 102)',
  'Lumbar Degenerative Surgery': 'rgb(51, 138, 23)',
  MACE: 'rgb(208, 240, 253)',
  MALE: 'rgb(255, 8, 194)',
  'Myocardial Infarction (MI)': 'rgb(39, 80, 174)',
  NAFLD: 'rgb(24, 191, 255)',
  NASH: 'rgb(178, 21, 139)',
  Neurological: 'rgb(237, 226, 254)',
  'NNT data (Airfinity use only)': 'rgb(11, 118, 183)',
  'Non-haemorrhagic Stroke': 'rgb(254, 226, 213)',
  'Not Relevant': 'rgb(32, 217, 210)',
  'Novel Drug Target': 'rgb(209, 247, 196)',
  NVAF: 'rgb(237, 226, 254)',
  Obesity: 'rgb(39, 80, 174)',
  'Operative Procedures': 'rgb(32, 201, 51)',
  'Oral Anticoagulation': 'rgb(11, 118, 183)',
  Outcomes: 'rgb(255, 214, 110)',
  PAD: 'rgb(156, 199, 255)',
  'Pancreatic Events': 'rgb(207, 223, 255)',
  'Patient Satisfaction': 'rgb(102, 102, 102)',
  PCI: 'rgb(255, 169, 129)',
  'Percutaneous Coronary Intervention': 'rgb(238, 238, 238)',
  'Phase I Results': 'rgb(186, 30, 69)',
  'Phase II Trial Results': 'rgb(68, 68, 68)',
  'Phase III Results': 'rgb(184, 117, 3)',
  'PK/PD': 'rgb(208, 240, 253)',
  'Post-thrombotic Syndrome': 'rgb(102, 102, 102)',
  Pregnancy: 'rgb(45, 127, 249)',
  'Primary Prevention': 'rgb(255, 8, 194)',
  'Pulmonary Embolism': 'rgb(102, 102, 102)',
  'Quality of Life': 'rgb(209, 247, 196)',
  'Race-related Differences': 'rgb(255, 218, 246)',
  'Re-stenosis': 'rgb(6, 160, 155)',
  'Real-world Use': 'rgb(114, 221, 195)',
  'Reduced ejection fraction': 'rgb(139, 70, 255)',
  'Regulatory review': 'rgb(156, 199, 255)',
  'Renal Events': 'rgb(255, 218, 246)',
  'Renal events': 'rgb(102, 102, 102)',
  'Renal Function': 'rgb(255, 218, 246)',
  'Respiratory Events': 'rgb(249, 157, 226)',
  'Reversal Agents': 'rgb(194, 245, 233)',
  Safety: 'rgb(215, 77, 38)',
  'Sex-related Differences': 'rgb(114, 221, 195)',
  'Skeletal or Muscle': 'rgb(24, 191, 255)',
  Sleep: 'rgb(45, 127, 249)',
  'Socioeconomic Status': 'rgb(11, 118, 183)',
  'Solubility of APIs': 'rgb(178, 21, 139)',
  'Standard of Care': 'rgb(102, 102, 102)',
  Steatosis: 'rgb(114, 221, 195)',
  Stroke: 'rgb(238, 238, 238)',
  'Stroke Prevention': 'rgb(139, 70, 255)',
  'Systemic Atherosclerosis': 'rgb(204, 204, 204)',
  'Systemic Embolism': 'rgb(11, 118, 183)',
  T1D: 'rgb(255, 214, 110)',
  T2D: 'rgb(207, 223, 255)',
  TAVI: 'rgb(237, 226, 254)',
  Thromboelastography: 'rgb(119, 209, 243)',
  'Thromboembolic Events': 'rgb(11, 118, 183)',
  Thrombolysis: 'rgb(205, 176, 255)',
  'Thrombotic Thrombocytopenia': 'rgb(186, 30, 69)',
  'Timing of Anticoagulation': 'rgb(178, 21, 139)',
  Tolerability: 'rgb(6, 160, 155)',
  'Transient Ischemic Attack (TIA)': 'rgb(248, 43, 96)',
  'Trial Design': 'rgb(194, 245, 233)',
  Triglycerides: 'rgb(255, 169, 129)',
  'Valve Replacement': 'rgb(68, 68, 68)',
  'Vascular Disease': 'rgb(255, 218, 246)',
  'Venous Thromboembolism': 'rgb(249, 157, 226)',
  VKA: 'rgb(6, 160, 155)',
  'VLDL-C': 'rgb(208, 240, 253)',
  VTE: 'rgb(255, 111, 44)',
  Weight: 'rgb(254, 226, 213)',
  'Ischemic events': 'rgb(255, 158, 183)',
  Granulomatosis: 'rgb(255, 8, 194)',
  Haemoptysis: 'rgb(255, 218, 246)',
  Hypertension: 'rgb(215, 77, 38)',
  'Portal Vein Thrombosis': 'rgb(39, 80, 174)',
  'New Techniques': 'rgb(204, 204, 204)',
  Biomaterials: 'rgb(252, 180, 0)',
  hyperglycemia: 'rgb(39, 80, 174)',
  'non-HDL-C': 'rgb(255, 234, 182)',
  'In Silico': 'rgb(215, 77, 38)',
  Intracranial: 'rgb(255, 234, 182)',
  'Hematopoietic impairment': 'rgb(102, 102, 102)',
  'Factor XIa Reversal': 'rgb(178, 21, 139)',
  'extended prescription': 'rgb(254, 226, 213)',
  'Functional difficulty': 'rgb(39, 80, 174)',
  moo: 'rgb(45, 127, 249)',
  Death: 'rgb(237, 226, 254)',
  'Major bleeding': 'rgb(186, 30, 69)',
  FXII: 'rgb(255, 220, 229)',
  'Acute Compartment Syndrome': 'rgb(207, 223, 255)',
}
