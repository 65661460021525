import { IModel } from 'interfaces/model.interface'
import { ValueType } from 'interfaces/valueType.interface'

import { fourDecimalPlaces } from 'config/common/transformValue/decimal'
import { USDTransformFormat } from 'config/common/transformValue/money'

export type ResearchPowerIndexOrganisationsData =
  | 'name'
  | 'country'
  | 'leadAndLastAuthorPublicationLink'
  | 'normalisedQuantityByOrganisation20202021IncludingReviewsAuthorAffiliation'
  | 'ssovFinalRenormalisedQualityPublicationAuthorLocAllTimeInclReviews'
  | 'rpiImpactReNormalisedAuthorAffiliation2020And2021ByOrganisationIncludingReviews'
  | 'rpi3MetricByOrganisationIncludingReviewsByAuthorAffiliation'
  | 'fundingSource'
  | 'normalisedQuantityByOrganisation20202021IncludingReviewsFundingSource'
  | 'ssovFinalReNormalisedQualityPublicationFundingAllTimeInclReviews'
  | 'rpiImpactReNormalisedFundingSources20202021ByOrganisationIncludingReviews'
  | 'rpi3MetricByOrganisationIncludingReviewsByFundingSource'
  | 'countsQuantityAuthorAffiliationAllPapers20202021ByOrganisation'
  | 'counts20202021QuantityFundingSourcesIncludingReviewsByOrganisation'
  | 'averageRevenue2020And2021'
  | 'normalisedRevenueScore'
  | 'rankByRevenueScore'
  | 'fundingAgencyRankBy3MetricPublicationScore'
  | 'researchInstitutionRankBy3MetricPublicationScore'

const allowedAirtables = [
  'api_research_orgs_with_reviews',
  'api_funding_agency_with_reviews',
  'api_private_sector_company_revenue_score_ranking',
] as const

export const ResearchPowerIndexOrganisationsModel: IModel<
  Record<ResearchPowerIndexOrganisationsData, string>,
  typeof allowedAirtables
> = {
  allowedAirtables,
  displayKey: 'name',
  name: 'Organisations',
  entityName: 'Organisations',
  endpoint: 'organisation',
  detailViewType: 'Generic',
  searchField: 'name',
  schema: {
    columns: [
      { key: 'name', label: 'Name', width: 270, type: ValueType.TEXT },
      { key: 'country', label: 'Country', width: 160, type: ValueType.MULTI },
      {
        key: 'leadAndLastAuthorPublicationLink',
        label: 'Linked publications',
        width: 240,
        type: ValueType.MULTI,
      },
      {
        key: 'normalisedQuantityByOrganisation20202021IncludingReviewsAuthorAffiliation',
        label: 'Research Institution Quantity normalised metric',
        width: 200,
        type: ValueType.NUMBER,
        transformValue: fourDecimalPlaces,
      },
      {
        key: 'ssovFinalRenormalisedQualityPublicationAuthorLocAllTimeInclReviews',
        label: 'Research Institution Quality re-normalised metric',
        width: 200,
        type: ValueType.NUMBER,
        transformValue: fourDecimalPlaces,
      },
      {
        key: 'rpiImpactReNormalisedAuthorAffiliation2020And2021ByOrganisationIncludingReviews',
        label: 'Research Institution Impact re-normalised metric',
        width: 200,
        type: ValueType.NUMBER,
        transformValue: fourDecimalPlaces,
      },
      {
        key: 'rpi3MetricByOrganisationIncludingReviewsByAuthorAffiliation',
        label: 'Research Institution 3-metric score',
        width: 200,
        type: ValueType.NUMBER,
        transformValue: fourDecimalPlaces,
      },
      {
        key: 'fundingSource',
        label: 'Linked publications',
        width: 260,
        type: ValueType.MULTI,
      },
      {
        key: 'normalisedQuantityByOrganisation20202021IncludingReviewsFundingSource',
        label: 'Funding Agency Quantity normalised metric',
        width: 200,
        type: ValueType.NUMBER,
        transformValue: fourDecimalPlaces,
      },
      {
        key: 'ssovFinalReNormalisedQualityPublicationFundingAllTimeInclReviews',
        label: 'Funding Agency Quality re-normalised metric',
        width: 200,
        type: ValueType.NUMBER,
        transformValue: fourDecimalPlaces,
      },
      {
        key: 'rpiImpactReNormalisedFundingSources20202021ByOrganisationIncludingReviews',
        label: 'Funding Agency Impact re-normalised metric',
        width: 200,
        type: ValueType.NUMBER,
        transformValue: fourDecimalPlaces,
      },
      {
        key: 'rpi3MetricByOrganisationIncludingReviewsByFundingSource',
        label: 'Funding Agency 3-metric score',
        width: 200,
        type: ValueType.NUMBER,
        transformValue: fourDecimalPlaces,
      },
      {
        key: 'countsQuantityAuthorAffiliationAllPapers20202021ByOrganisation',
        label: 'Number of publications',
        width: 180,
        type: ValueType.NUMBER,
      },
      {
        key: 'counts20202021QuantityFundingSourcesIncludingReviewsByOrganisation',
        label: 'Number of publications',
        width: 180,
        type: ValueType.NUMBER,
      },
      {
        key: 'averageRevenue2020And2021',
        label: 'Average Revenue 2020 And 2021',
        width: 180,
        type: ValueType.TEXT,
        transformValue: USDTransformFormat,
      },
      {
        key: 'normalisedRevenueScore',
        label: 'Normalised Revenue Score',
        width: 180,
        type: ValueType.NUMBER,
      },
      {
        key: 'rankByRevenueScore',
        label: 'Rank by Revenue Score',
        width: 110,
        type: ValueType.NUMBER,
      },
      {
        key: 'fundingAgencyRankBy3MetricPublicationScore',
        label: 'Funding Agency rank by 3-metric publication score',
        width: 180,
        type: ValueType.NUMBER,
      },
      {
        key: 'researchInstitutionRankBy3MetricPublicationScore',
        label: 'Research Institution rank by 3-metric publication score',
        width: 200,
        type: ValueType.NUMBER,
      },
    ],
  },
}
