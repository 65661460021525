import React, { useState } from 'react'

type PropTypes = {
  children: React.ReactNode
  defaultIsOpen?: boolean
}

export function ExpandableContainer({
  children,
  defaultIsOpen = false,
}: PropTypes) {
  const [isOpen, handleToggle] = useState(defaultIsOpen)

  return (
    <ExpandableContext.Provider
      value={{
        isOpen: isOpen,
        toggle: () => {
          handleToggle(!isOpen)
        },
      }}
    >
      {children}
    </ExpandableContext.Provider>
  )
}

export const ExpandableContext = React.createContext({
  isOpen: false,
  toggle: () => {},
})
