export default {
  'Age 75+': 'rgb(209, 247, 196)',
  'Aged 75-79 Years': 'rgb(255, 234, 182)',
  'Aged 80+ Years': 'rgb(205, 176, 255)',
  'Concurrent amiodarone': 'rgb(204, 204, 204)',
  'Concurrent P-Glycoprotein inhibitors': 'rgb(11, 118, 183)',
  'Concurrent quinidine': 'rgb(248, 43, 96)',
  'Concurrent Verapamil': 'rgb(255, 234, 182)',
  'Creatinine clearance 15-29 mL/min': 'rgb(207, 223, 255)',
  'Creatinine Clearance 15-30mL/min': 'rgb(147, 224, 136)',
  'Creatinine clearance 15-49 mL/min': 'rgb(51, 138, 23)',
  'Creatinine clearance 15-50mL/min': 'rgb(209, 247, 196)',
  'Creatinine clearance 30-50mL/min': 'rgb(156, 199, 255)',
  Esophagitis: 'rgb(194, 245, 233)',
  Gastritis: 'rgb(24, 191, 255)',
  'Gastroesophageal reflux': 'rgb(255, 169, 129)',
  'Inadequate LDL-C response': 'rgb(255, 169, 129)',
  'Increased bleeding risk': 'rgb(204, 204, 204)',
  'LDL-C reduction >60%': 'rgb(194, 245, 233)',
  'N/A': 'rgb(207, 223, 255)',
  'Serum creatinine ≥133μmol/L': 'rgb(6, 160, 155)',
  'Serum triglycerides': 'rgb(207, 223, 255)',
  Time: 'rgb(39, 80, 174)',
  'Weight ≤60kg': 'rgb(205, 176, 255)',
  'Weight: 30-50kg': 'rgb(194, 245, 233)',
  'Present- but not defined': 'rgb(51, 138, 23)',
  'End stage renal disease (ESRD)': 'rgb(255, 220, 229)',
  'Hepatic impairment (Child-Pugh A)': 'rgb(107, 28, 176)',
}
