import { Spinner, Box } from '@chakra-ui/react'
import { createElement } from 'react'

import { useAppRoute } from 'routes/utils'

import { InlineError } from 'components'

import { EntitySearchType } from 'api/types'
import { useCollectionDataSingleRaw } from 'api/useCollectionDataSingle'

import { IModel } from 'interfaces/model.interface'
import { ViewData } from 'interfaces/navigationPage.interface'

import EntityPreviewDetailViewBase from './EntityPreviewDetailView'

type PropTypes = {
  model: IModel<any>
  entityData: EntitySearchType
}

export default function EntityPreview({ model, entityData }: PropTypes) {
  const app = useAppRoute()

  const viewData: ViewData = {
    airtableName: entityData.view,
    airtableBase: entityData.base as ViewData['airtableBase'],
  }

  const { isLoading, data, error } = useCollectionDataSingleRaw(
    app,
    model.endpoint,
    entityData.id,
    { airtableBase: viewData.airtableBase }
  )

  return (
    <>
      {isLoading && (
        <Box display='flex' height='100%' minH='200px'>
          <Spinner margin='auto' />
        </Box>
      )}
      {error && <InlineError />}
      {!isLoading &&
        data &&
        createElement(model?.renderPreviewView || EntityPreviewDetailViewBase, {
          model,
          data,
          viewData,
        })}
    </>
  )
}
