import { IModel } from 'interfaces/model.interface'
import { ValueType } from 'interfaces/valueType.interface'

import { SortOrders } from 'enums/SortOrders'

import Phase from '../../tagColours/Phase'

export type PNHClinicalTrialsData =
  | 'title'
  | 'phasesClean'
  | 'acronym'
  | 'status'
  | 'conditions'
  | 'interventions'
  | 'lookupCandidateName'
  | 'outcomeMeasures'
  | 'targetEnrollment'
  | 'primaryCompletionDate'
  | 'sponsorOrgSync'
  | 'locationsSync'
  | 'id'
  | 'afStartDate'
  | 'enrolledAt'
  | 'completedAt'
  | 'participants'
  | 'studyType'
  | 'ages'
  | 'locations'
  | 'url'
  | 'primaryOutcomeMeasures'
  | 'trialId'

const allowedAirtables = ['api_clinical_trials'] as const

export const PNHClinicalTrialsModel: IModel<
  Record<PNHClinicalTrialsData, string>,
  typeof allowedAirtables
> = {
  allowedAirtables,
  name: 'Clinical Trials',
  entityName: 'Clinical Trials',
  searchField: 'title',
  endpoint: 'clinical-trial',
  detailViewType: 'Generic',
  defaultSortKey: 'enrolledAt',
  defaultSortOrder: SortOrders.DESC,
  schema: {
    columns: [
      {
        key: 'id',
        label: 'Trial ID',
        width: 120,
        hiddenByDefault: true,
      },
      {
        key: 'trialId',
        label: 'Trial ID',
        width: 120,
      },
      {
        key: 'title',
        label: 'Title',
        width: 500,
      },
      {
        key: 'acronym',
        type: ValueType.TEXT,
        width: 150,
        label: 'Acronym',
      },
      {
        key: 'lookupCandidateName',
        label: 'Candidate',
        type: ValueType.MULTI,
        width: 150,
      },
      {
        key: 'sponsorOrgSync',
        label: 'Sponsors',
        type: ValueType.MULTI,
        width: 150,
      },
      {
        label: 'Phase',
        key: 'phasesClean',
        width: 150,
        type: ValueType.SINGLE,
        cellFormat: {
          colours: Phase,
        },
        sortOrderObject: [
          'III',
          'II/III',
          'I/II',
          'II',
          'I',
          'IV',
          'Not Applicable',
        ],
      },
      { key: 'status', label: 'Status', width: 150, type: ValueType.SINGLE },
      {
        key: 'afStartDate',
        label: 'Start Date',
        width: 150,
        type: ValueType.DATE,
      },
      {
        key: 'enrolledAt',
        label: 'Enrolled Date',
        width: 150,
        type: ValueType.DATE,
      },
      {
        key: 'primaryCompletionDate',
        label: 'Primary Completion Date',
        width: 200,
        type: ValueType.DATE,
      },
      {
        key: 'completedAt',
        label: 'Completion Date',
        width: 200,
        type: ValueType.DATE,
      },
      {
        key: 'targetEnrollment',
        label: 'Target Enrollment',
        type: ValueType.MULTI,
        width: 150,
      },
      {
        key: 'participants',
        label: 'Participants',
        type: ValueType.MULTI,
        width: 150,
      },
      {
        key: 'studyType',
        label: 'Study Type',
        type: ValueType.MULTI,
        width: 150,
      },
      {
        key: 'ages',
        label: 'Ages',
        type: ValueType.SINGLE,
        width: 150,
      },
      {
        key: 'primaryOutcomeMeasures',
        label: 'Primary outcome',
        width: 300,
        type: ValueType.LONG,
        cellFormat: {
          formatForOncology: true,
        },
      },
      {
        key: 'locations',
        label: 'Locations',
        type: ValueType.MULTI,
        width: 150,
      },
      {
        key: 'url',
        label: 'URL',
        type: ValueType.URL,
        width: 150,
      },
    ],
  },
}
