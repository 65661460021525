import {
  ArrowDown,
  ArrowUp,
  ChevronDown,
  ChevronSort,
  ChevronUp,
  Information,
  ViewOff,
} from '@carbon/icons-react'
import { Box, BoxProps, Icon, Skeleton, Text, Tooltip } from '@chakra-ui/react'
import _ from 'lodash'
import React, { useCallback } from 'react'
import { HeaderGroup } from 'react-table'

import { Dropdown } from 'components'

import useTracking from 'tracking/useTracking'

import { useTableParentProps } from './provider/TableParentProps'
import { useTableUseTableProps } from './provider/TableUseTableProps'

type PropTypes = {
  headerGroups: HeaderGroup<any>[]
  isLoading: boolean
  isChildTable?: boolean
}

const TableHeader = ({
  headerGroups,
  isLoading,
  isChildTable = false,
}: PropTypes) => {
  const { parentProps } = useTableParentProps()
  const { model } = parentProps

  return (
    <Box position='sticky' top='0px' zIndex={20}>
      {headerGroups.map((headerGroup) => {
        // Handle grouping/child table
        const headers =
          !!model.grouping && !!model.grouping.parentColumns
            ? isChildTable
              ? headerGroup.headers.filter((column) =>
                  !!model?.grouping?.childColumn
                    ? model?.grouping?.childColumn?.find((x) => x === column.id)
                    : !model?.grouping?.parentColumns?.find(
                        (x) => x === column.id
                      )
                )
              : headerGroup.headers.filter((column) =>
                  model?.grouping?.parentColumns?.find((x) => x === column.id)
                )
            : headerGroup.headers

        return (
          <Box {...headerGroup.getHeaderGroupProps()}>
            {headers.map((column, i) => {
              const columnCopy = { ...column }
              const headerProps = column.getHeaderProps()

              return (
                <HeaderCell
                  key={column.id}
                  column={columnCopy}
                  headerProps={headerProps}
                  isLoading={isLoading}
                />
              )
            })}
          </Box>
        )
      })}
    </Box>
  )
}

export default React.memo(TableHeader)

const SortMenuItem = ({
  onClick,
  isSelected,
  children,
  ...rest
}: {
  onClick: () => void
  isSelected?: boolean
  children: React.ReactNode
} & BoxProps) => {
  return (
    <Box
      color='black'
      bg='transparent'
      py={3}
      px='16px'
      fontSize='13px'
      borderBottom='1px solid'
      borderColor='gray3'
      _hover={{
        bg: 'gray4',
      }}
      cursor='pointer'
      fontWeight={isSelected ? 500 : 400}
      onClick={onClick}
      display='flex'
      alignItems='center'
      justifyContent='space-between'
      {...rest}
    >
      {children}
    </Box>
  )
}

const HeaderCell = React.memo(
  ({
    column,
    isLoading,
    headerProps,
  }: {
    column: any
    isLoading: any
    headerProps: any
  }) => {
    const { useTableData } = useTableUseTableProps()
    const [tracking] = useTracking()

    const onHide = useCallback(
      (index: string, val: boolean) => {
        tracking.tableColumnHide({ column: index, isVisible: val })
        useTableData?.toggleHideColumn(index)
      },
      [useTableData, tracking]
    )

    const toolTipText = column.tooltip || column.render('Header')

    const hasTooltip = !!column.tooltip

    return (
      <Box
        py={2}
        height='40px'
        className={`${column.class ?? ''} columnCell`}
        top={0}
        borderBottom='1px solid'
        borderRight='1px solid'
        borderColor='gray.300'
        {...column.style}
        {...headerProps}
        bg='gray5'
      >
        <Box
          position='relative'
          role='group'
          height='100%'
          width='100% !important'
          whiteSpace='nowrap'
          display='flex'
        >
          {isLoading ? (
            <Skeleton mx={3} my='auto' height='16px' width='100%' />
          ) : (
            <Box display='inline-flex' height='100%' width='100%'>
              <Box width='100%' overflow='hidden'>
                <Box
                  pl={3}
                  height='24px'
                  fontWeight={500}
                  fontSize='12px'
                  display='flex'
                  alignItems='center'
                >
                  <Text
                    textTransform='capitalize'
                    overflow='hidden'
                    textOverflow='ellipsis'
                    cursor={'default'}
                  >
                    <Tooltip
                      p='0.5rem'
                      bg='gray.700'
                      color='white'
                      label={toolTipText}
                      aria-label={toolTipText}
                    >
                      <Box fontSize={'12px'}>
                        {column.render('Header')}{' '}
                        {hasTooltip ? (
                          <Icon
                            as={Information}
                            mb='-4px'
                            width='16px'
                            height='16px'
                            ml='8px'
                          />
                        ) : (
                          ''
                        )}
                      </Box>
                    </Tooltip>
                  </Text>
                  <Box
                    {...column.getResizerProps()}
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                    }}
                    className={`resizer ${
                      column.isResizing ? 'isResizing' : ''
                    }`}
                  >
                    <Box className='resizer_indicator' />
                  </Box>
                </Box>
              </Box>
              <Box
                ml='auto'
                alignItems={'center'}
                position='relative'
                cursor='pointer'
                mt='auto'
              >
                <Dropdown
                  positions={['bottom', 'right']}
                  align='end'
                  closeOnClick
                  content={
                    <Box
                      shadow='lg'
                      bg='white'
                      border='1px solid'
                      borderColor='gray.200'
                      rounded='8px'
                      width='207px'
                    >
                      <SortMenuItem
                        isSelected={!column.isSorted}
                        onClick={() => column.clearSortBy()}
                        borderTopRadius='8px'
                      >
                        No Sort
                      </SortMenuItem>
                      <SortMenuItem
                        isSelected={column.isSorted && !column.isSortedDesc}
                        onClick={() => {
                          tracking.tableColumnSort({
                            column: column.id,
                            sortIndex: column.sortedIndex,
                          })
                          column.toggleSortBy(false, false)
                        }}
                      >
                        Sort Ascending
                        <Icon as={ArrowUp} boxSize='17px' />
                      </SortMenuItem>
                      <SortMenuItem
                        isSelected={column.isSorted && column.isSortedDesc}
                        onClick={() => {
                          tracking.tableColumnSort({
                            column: column.id,
                            sortIndex: column.sortedIndex,
                          })
                          column.toggleSortBy(true, false)
                        }}
                      >
                        Sort Descending
                        <Icon as={ArrowDown} boxSize='17px' />
                      </SortMenuItem>
                      <SortMenuItem
                        borderBottom='none'
                        borderBottomRadius='8px'
                        onClick={() => onHide(column.id, false)}
                      >
                        Hide Column
                        <Icon as={ViewOff} boxSize='17px' />
                      </SortMenuItem>
                    </Box>
                  }
                >
                  {!column.isSorted ? (
                    <Icon as={ChevronSort} boxSize='15px' mt={1} mr='10px' />
                  ) : column.isSortedDesc ? (
                    <Icon as={ChevronDown} boxSize='15px' mt={1} mr='10px' />
                  ) : (
                    <Icon as={ChevronUp} boxSize='15px' mt={1} mr='10px' />
                  )}
                </Dropdown>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    )
  },
  (prev, next) =>
    // There's a few ways we could go about this
    // - Pass each props
    // - Pass a modified object with only the things we use (without functions)
    // - Or do how we're doing right now
    prev.column.isResizing === next.column.isResizing &&
    prev.column.isSorted === next.column.isSorted &&
    prev.column.isSortedDesc === next.column.isSortedDesc &&
    _.isEqual(prev.headerProps, next.headerProps) &&
    prev.isLoading === next.isLoading
)
