export default {
  'intravenous injection': 'rgb(207, 223, 255)',
  Intravenous: 'rgb(207, 223, 255)',
  'intramuscular injection': 'rgb(208, 240, 253)',
  Intramuscular: 'rgb(208, 240, 253)',
  'intranasal spray': 'rgb(194, 245, 233)',
  Intranasal: 'rgb(194, 245, 233)',
  'oral tablet/pill': 'rgb(209, 247, 196)',
  'intradermal injection': 'rgb(255, 234, 182)',
  patch: 'rgb(107, 28, 176)',
  oral: 'rgb(156, 199, 255)',
  Oral: 'rgb(156, 199, 255)',
  Unknown: 'rgb(207, 223, 255)',
  inhaled: 'rgb(255, 234, 182)',
  'oral liquid/syrup': 'rgb(249, 157, 226)',
  Subcutaneous: 'rgb(255, 221, 228)',
}
