import { InputObject } from '.'
import { getAllowedFilterType } from 'components/Table/AdvancedSearch/filters'
import { useTablePageData } from 'modules/Tables/TablePageProvider'
import { SingleAdvancedFilter } from 'components/Table/AdvancedSearch/useAdvancedFilters'
import html2canvas from 'html2canvas'

export interface OccurenceByKeyOutput {
  label: string | number
  cases: number
  percentage: number
}

export type FilterType = SingleAdvancedFilter<any, any>

export const progressChartSelectOptions = [
  {
    label: 'Diseases',
    value: 'diseaseName',
  },
  {
    label: 'Country',
    value: 'areas',
  },
  {
    label: 'Developers',
    value: 'developers',
  },
  {
    label: 'Candidate',
    value: 'candidates',
  },
  {
    label: 'Phase',
    value: 'latestPhaseFinal',
  },
  {
    label: 'Pathogen Types',
    value: 'pathogenTypes',
  },
  {
    label: 'Sponsors',
    value: 'sponsors',
  },
]

export const countOccurrences = (
  arr: InputObject[],
  key: string,
  calculateByEntirety: boolean = false
): OccurenceByKeyOutput[] => {
  const counts = arr.reduce(
    (acc, obj) => {
      const value = obj[key]
      if (Array.isArray(value)) {
        value.forEach((v) => {
          acc[v] = (acc[v] || 0) + 1
        })
      } else if (value != null) {
        acc[value] = (acc[value] || 0) + 1
      }
      return acc
    },
    {} as Record<string, number>
  )

  const total = calculateByEntirety
    ? Object.values(counts).reduce((sum, count) => sum + count, 0)
    : Math.max(...Object.values(counts))

  return Object.entries(counts)
    .map(([value, count]) => ({
      label: value,
      cases: count,
      percentage: total > 0 ? Math.round((count / total) * 100) : 0,
    }))
    .sort((a, b) => b.cases - a.cases)
}

export const useGetFilterType = (property: string) => {
  const { model } = useTablePageData()
  return getAllowedFilterType(
    model.schema.columns.find((column) => column.key === property)?.type
  )
}

export const checkIfSelected = (
  tableFilters: SingleAdvancedFilter<any, any>[],
  column: string,
  label: string | number
) => {
  return tableFilters.some(
    (filter) =>
      filter.column === column &&
      Array.isArray(filter.filterValue) &&
      filter.filterValue.some((value: any) => value.label === label)
  )
}

export const getResultsValue = (
  results: OccurenceByKeyOutput[],
  value: string
) => {
  return results.find((v) => v.label === value)
}

export const exportToImage = async () => {
  const style = document.createElement('style')
  document.head.appendChild(style)
  style.sheet?.insertRule(
    'body > div:last-child img { display: inline-block; }'
  )
  const pdfContent = document.getElementById('divToPrint') as HTMLElement
  const svgElements = document.body.querySelectorAll('svg')
  svgElements.forEach(function (item) {
    item.setAttribute('width', String(item.getBoundingClientRect().width))
    item.setAttribute('height', String(item.getBoundingClientRect().height))
    item.style.width = ''
    item.style.height = ''
  })
  await html2canvas(pdfContent, {
    scale: 2,
    logging: true,
  }).then(async (canvas) => {
    const imgData = canvas.toDataURL('image/png')
    const link = document.createElement('a')
    link.href = imgData
    link.download = `IDA Trials Map Export - ${new Date().toLocaleDateString()}.png`
    link.click()
  })
}
