import { IModel } from 'interfaces/model.interface'
import { ValueType } from 'interfaces/valueType.interface'

import { SortOrders } from 'enums/SortOrders'

export type OutbreakTrackerData =
  | 'id'
  | 'area'
  | 'country'
  | 'dateEndNewInfections'
  | 'dateStartNewInfections'
  | 'disease'
  | 'diseaseName'
  | 'diseaseCategory'
  | 'diseaseModeOfTransmission'
  | 'diseasePathogenFamily'
  | 'diseasePathogenType'
  | 'diseaseRi'
  | 'regionImf'
  | 'continent'
  | 'sourceCumulativeInfections'
  | 'dateEndNewDeaths'
  | 'dateStartNewDeaths'
  | 'sourceNewDeaths'
  | 'valueNewDeaths'
  | 'sourceNewInfections'
  | 'valueCumulativeDeaths'
  | 'sourceCumulativeDeaths'
  | 'valueCumulativeInfections'
  | 'valueNewInfections'

const allowedAirtables = ['time_series_outbreak_tracker'] as const

export const OutbreakTrackerModel: IModel<
  Record<OutbreakTrackerData, string>,
  typeof allowedAirtables
> = {
  allowedAirtables,
  endpoint: 'time-series-outbreak-tracker',
  name: 'Outbreak Tracker',
  entityName: 'Disease',
  searchField: 'disease',
  detailViewType: 'Generic',
  displayKey: 'disease',
  defaultSortKey: 'dateEndNewInfections',
  defaultSortOrder: SortOrders.DESC,
  schema: {
    columns: [
      {
        key: 'disease',
        label: 'Disease',
        width: 250,
        type: ValueType.MULTI,
      },
      {
        key: 'diseaseName',
        label: 'Disease',
        width: 200,
        type: ValueType.MULTI,
      },
      {
        key: 'regionImf',
        label: 'Region',
        width: 190,
        type: ValueType.SINGLE,
      },
      {
        key: 'continent',
        label: 'Continent',
        width: 190,
        type: ValueType.SINGLE,
      },
      {
        key: 'country',
        label: 'Country',
        width: 150,
        type: ValueType.SINGLE,
      },
      {
        key: 'area',
        label: 'Area',
        width: 150,
        type: ValueType.SINGLE,
      },
      {
        key: 'valueNewInfections',
        label: 'New Cases',
        width: 120,
        type: ValueType.NUMBER,
      },
      {
        key: 'dateEndNewInfections',
        label: 'Reported On',
        width: 150,
        type: ValueType.DATE,
      },
      {
        key: 'dateStartNewInfections',
        label: 'Date Start',
        width: 150,
        type: ValueType.DATE,
      },
      {
        key: 'sourceNewInfections',
        label: 'New Cases Source',
        width: 300,
        type: ValueType.URL,
      },
      {
        key: 'sourceCumulativeInfections',
        label: 'Cumulative Cases Source',
        width: 300,
        type: ValueType.URL,
      },
      {
        key: 'valueCumulativeInfections',
        label: 'Cumulative Cases',
        width: 170,
        type: ValueType.NUMBER,
      },
      {
        key: 'valueNewDeaths',
        label: 'New Deaths',
        width: 120,
        type: ValueType.NUMBER,
      },
      {
        key: 'dateEndNewDeaths',
        label: 'Reported On',
        width: 150,
        type: ValueType.DATE,
      },
      {
        key: 'dateStartNewDeaths',
        label: 'Date Start',
        width: 150,
        type: ValueType.DATE,
      },
      {
        key: 'sourceNewDeaths',
        label: 'New Deaths Source',
        width: 300,
        type: ValueType.URL,
      },
      {
        key: 'valueCumulativeDeaths',
        label: 'Cumulative Deaths',
        width: 170,
        type: ValueType.NUMBER,
      },
      {
        key: 'sourceCumulativeDeaths',
        label: 'Cumulative Deaths Source',
        width: 250,
        type: ValueType.URL,
      },
      {
        key: 'diseaseRi',
        label: 'Disease RI',
        width: 120,
        type: ValueType.MULTI,
      },
      {
        key: 'diseasePathogenFamily',
        label: 'Pathogen Family',
        width: 150,
        type: ValueType.MULTI,
      },
      {
        key: 'diseasePathogenType',
        label: 'Type of Pathogen',
        width: 150,
        type: ValueType.MULTI,
      },
      {
        key: 'diseaseCategory',
        label: 'Disease Category',
        width: 150,
        type: ValueType.MULTI,
      },
      {
        key: 'diseaseModeOfTransmission',
        label: 'Mode of Transmission',
        width: 170,
        type: ValueType.MULTI,
      },
    ],
  },
}
