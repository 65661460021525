import { Download } from '@carbon/icons-react'
import { Box, Button, Icon, Skeleton, Text } from '@chakra-ui/react'
import { PDFDocument } from 'pdf-lib'
import { useContext, useEffect, useState } from 'react'
import { Document, Page } from 'react-pdf'

import { useAppParams, useAppRoute } from 'routes/utils'

import { AuthContext } from 'contexts'

import { useDownloadPdf, usePdfData } from 'components/PdfDownload/usePdfData'

import { useReportById } from 'api/cms/reports/useReports'

import { apps } from 'config/apps'

import UpSellSection from '../FullReportPage/components/UpSell'

const ReportPageByID = ({ id }: { id: string }) => {
  const [pages, setPages] = useState<number[]>([])
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  const selectedApp = useAppRoute()
  const appParams = useAppParams()
  const { userIsBasicWithinApp } = useContext(AuthContext)

  const isBasic = userIsBasicWithinApp(apps[selectedApp])
  const { data: reportData } = useReportById(selectedApp, id)

  const reportUrl = reportData?.report ?? ''

  const { data: pdfData, refetch: loadPdf } = usePdfData(reportUrl, true)
  const { mutate: downloadPdf } = useDownloadPdf(
    reportUrl || '',
    `Slides - ${new Date().toLocaleDateString()}`
  )
  async function getPages() {
    if (!pdfData || !reportUrl) return
    const pdfDoc = await PDFDocument.load(pdfData)
    if (!pdfDoc) return
    setPages(pdfDoc?.getPages().map((_, index) => index + 1))
  }

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    if (reportUrl) {
      loadPdf()
      getPages()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pdfData?.length, reportUrl])

  async function handleClick() {
    await downloadPdf()
  }

  if (isBasic) {
    return (
      <Box
        width='700px'
        margin={'auto'}
        height='80vh'
        display={'flex'}
        flexDir='column'
        justifyContent='center'
        alignItems={'center'}
        gap='1rem'
      >
        <Text fontSize='2xl'>Upgrade to a higher Tier to read the report.</Text>
        <UpSellSection
          details={`User wants to read the report ${reportData?.title} from page ${window.location.href}`}
          title={`${appParams.subPageSlug ?? ''} + /${
            appParams.subPageSlug ?? ''
          }`}
        />
      </Box>
    )
  }

  if (!pdfData || !reportUrl) return null

  const largeScreen = windowWidth > 1600

  return (
    <Box position={'relative'}>
      <Box
        display={'flex'}
        mb={2}
        w='100%'
        justifyContent={'flex-end'}
        zIndex={999}
      >
        <Button variant={'yellow'} marginLeft={'auto'} onClick={handleClick}>
          <Icon w='14px' as={Download} /> Download Slides
        </Button>
      </Box>
      <Document
        file={{ data: pdfData }}
        loading={
          <Box maxHeight={'700px'} maxW='fit-content' m='auto'>
            <Skeleton width='500px' height='200px' maxH={'300px'} m='auto' />
          </Box>
        }
        error={<Skeleton width='1200px' height='600px' m='auto' />}
      >
        {pages.map((x, index) => (
          <Box
            key={index}
            maxHeight={'700px'}
            maxW='fit-content'
            m={'auto'}
            mb='1rem'
          >
            <Page
              width={largeScreen ? 1200 : 1000}
              height={300}
              pageNumber={x}
              onError={() => console.error('error')}
              renderTextLayer={false}
              renderAnnotationLayer={false}
            />
          </Box>
        ))}
      </Document>
    </Box>
  )
}

export default ReportPageByID
