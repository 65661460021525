import { UseQueryOptions, useQuery } from '@tanstack/react-query'
import { AxiosError, AxiosInstance } from 'axios'

import { recursiveCamelCase } from './helper'
import { TermsAndConditions } from './types'
import useAxios from './useAxios'

export default function useTnCData(extraProps?: UseQueryOptions<any, any>) {
  const axios = useAxios()

  return useQuery<TermsAndConditions, AxiosError>(
    ['termsAndConditions'],
    () => {
      return fetchTermsAndConditions(axios)
    },
    extraProps
  )
}

export const fetchTermsAndConditions = async (
  axios: AxiosInstance
): Promise<TermsAndConditions> => {
  return axios(`/terms-and-conditions/`).then((x) => recursiveCamelCase(x.data))
}
