import { Box } from '@chakra-ui/react'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'

import {
  DrawerContent,
  DrawerContentContextContentType,
  Empty,
} from 'modules/DetailDrawer'
import SelectedDrawer from 'modules/Tables/SelectedDrawer'

import { useAppRoute } from 'routes/utils'

import { Table } from 'components'

import { flattenPaginatedData } from 'api/helper'
import { ResponseData, ICleanResponse, IResponseBase } from 'api/types'
import useCollectionData from 'api/useCollectionData'

import { IModel, ExtractModelDataUnion } from 'interfaces/model.interface'
import { ViewData } from 'interfaces/navigationPage.interface'

import generateColumns, { CustomColumn } from 'utils/generateColumns'
import { isRelational, relationGetIds } from 'utils/relational'

type PropTypes<TModel extends IModel<any>> = {
  ids: ResponseData
  model: TModel
  viewData: ViewData
  sortOrder?:
    | ExtractModelDataUnion<TModel>
    | ((data: ICleanResponse<ExtractModelDataUnion<TModel>>) => any)
  filterData?: (
    data: ICleanResponse<any>[] | undefined
  ) => ICleanResponse<any>[] | undefined
  exportName: string
  id?: number
}

export default function TableView<TModel extends IModel<any>>({
  id,
  ids: rawIds,
  model,
  viewData,
  sortOrder,
  filterData,
  exportName,
}: PropTypes<TModel>) {
  const content = useContext(DrawerContent)
  const ids = isRelational(rawIds)
    ? relationGetIds(rawIds)
    : (rawIds as string[])

  const app = useAppRoute()

  const {
    data: paginatedData,
    isLoading: loading,
    isFetching,
  } = useCollectionData(
    app,
    model.endpoint,
    {
      ids,
      airtableBase: viewData.airtableBase,
      // view
    },
    { enabled: !!ids && ids.length > 0 }
  )

  const isLoading = loading && isFetching

  const data = flattenPaginatedData(paginatedData?.pages)?.results

  const [selectedRow, setRow] = useState<IResponseBase<
    Extract<any, string>
  > | null>(null)

  const handleSelectRow = useCallback(
    (data: any) => {
      setRow(data)
    },
    [setRow]
  )
  const filteredData = useMemo(
    () => (filterData ? filterData(data) : data),
    [filterData, data]
  )

  useEffect(() => {
    content.setContent((x: DrawerContentContextContentType) => ({
      ...x,
      [id || 0]: filteredData?.length,
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredData?.length])

  if (!isLoading && filteredData?.length === 0) {
    return <Empty />
  }

  return (
    <Box p={2} display='flex' flexDir='column' height='100%'>
      <SelectedDrawer
        selectedRow={selectedRow}
        model={model}
        setRow={setRow}
        viewData={viewData}
      />
      <Table
        data={filteredData ?? []}
        model={model}
        isLoading={isLoading}
        columns={
          generateColumns(model.schema.columns) as CustomColumn<Object>[]
        }
        onRowClick={
          (model.detailViewType || model.renderDetailView) && handleSelectRow
        }
        searchField={model.searchField}
        searchable
        exportName={exportName}
      />
    </Box>
  )
}
