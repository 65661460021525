import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query'
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios'

import { Bookmark } from 'api/types'
import useAxios from 'api/useAxios'

import { bookmarkQueryKey, mapBookmarkResponse } from './useBookmarkList'

const queryKey = bookmarkQueryKey

export default function useCreateBookmarkMutation(
  extraProps?: UseMutationOptions<
    AxiosResponse<Bookmark>,
    AxiosError<any>,
    Omit<Bookmark, 'id'>
  >
) {
  const axios = useAxios()

  const queryClient = useQueryClient()

  return useMutation<
    AxiosResponse<Bookmark>,
    AxiosError<any>,
    Omit<Bookmark, 'id'>
  >((bookmarkItem) => createBookmark(axios, bookmarkItem), {
    onMutate: (newData) => {
      queryClient.cancelQueries(queryKey)

      const previousData: Bookmark[] | undefined =
        queryClient.getQueryData(queryKey)

      queryClient.setQueryData(queryKey, [
        ...previousData!,
        { id: -1, ...newData },
      ])

      return () => queryClient.setQueryData(queryKey, previousData)
    },
    onError: (err, newData, rollback: any) => {
      rollback()
      queryClient.invalidateQueries(queryKey)
    },
    onSuccess: (data) => {
      const currentData: Bookmark[] | undefined =
        queryClient.getQueryData(queryKey)

      queryClient.setQueryData(
        queryKey,
        currentData
          ?.filter((x) => x.id !== -1)
          .concat(mapBookmarkResponse(data.data))
      )
    },
    ...extraProps,
  })
}

export const createBookmark = async (
  axios: AxiosInstance,
  bookmarkItem: Omit<Bookmark, 'id'>
): Promise<AxiosResponse<Bookmark>> => {
  return await axios.post(`/bookmark/`, {
    app_id: bookmarkItem.app,
    metadata: bookmarkItem.metadata,
    bookmark_type: bookmarkItem.type,
  })
}
