import { Box, Button, HStack, Input, Text, VStack } from '@chakra-ui/react'
import _ from 'lodash'
import React, { useCallback, useImperativeHandle, useMemo, useRef } from 'react'
import { memo, useEffect, useState } from 'react'
import { Virtuoso } from 'react-virtuoso'

import { useTableChartFilters } from 'contexts/TableChartFilters'

import { getAllowedFilterType } from 'components/Table/AdvancedSearch/filters'
import { SingleAdvancedFilter } from 'components/Table/AdvancedSearch/useAdvancedFilters'

import { IModel } from 'interfaces/model.interface'
import { ValueType } from 'interfaces/valueType.interface'

import useTracking from 'tracking/useTracking'

import useIsMobile from 'utils/useIsMobile'

type LegendsType = { key: string; checked: boolean }

interface CustomLegendsProps {
  sortOrder?: string[]
  colors?: { [value: string]: string }
  model: IModel<any, any>
  columnKey: string
  title?: string
  chartData: any
  stackByValue: string
  loaded: boolean
  adjustOpen: boolean
  joinedValues: string[]
}

type FilterT = SingleAdvancedFilter<any, any>

const findFirstNonEmptyArray = (arrayOfObjects: any, propertyKey: string) => {
  const result = _.find(arrayOfObjects, (obj) => {
    const propertyValue = obj[propertyKey]

    return (
      (_.isArray(propertyValue) && propertyValue.length > 0) ||
      (_.isString(propertyValue) && propertyValue.trim() !== '')
    )
  })

  return result ? result[propertyKey] : null
}

function uniqueFilter(filter: FilterT | undefined) {
  const allowedFiltersTypes = [
    'includesAll',
    'includesAny',
    'includesOnly',
    'includesSingle',
  ]
  if (filter?.filterValue?.length !== 1) return false
  return allowedFiltersTypes.includes(filter?.type)
}

function getFiltersDepth(filters: FilterT[], xAxis: string, yAxis: string) {
  const isUniqueXFilter = uniqueFilter(
    filters.find((filter) => filter.column === xAxis)
  )
  const isUniqueYFilter = uniqueFilter(
    filters.find((filter) => filter.column === yAxis)
  )
  if (isUniqueXFilter && isUniqueYFilter) return 'x-y-Filter'
  if (isUniqueXFilter) return 'xFilter'
  if (isUniqueYFilter) return 'yFilter'
  return 'none'
}

const CustomLegends = React.forwardRef(
  (
    {
      colors,
      model,
      columnKey,
      title = 'Legend',
      chartData,
      stackByValue,
      loaded,
      adjustOpen,
      joinedValues,
      sortOrder,
    }: CustomLegendsProps,
    ref
  ) => {
    const [isMobile] = useIsMobile()

    const [tracking] = useTracking()

    const [legends, setLegends] = useState<Record<string, Array<LegendsType>>>({
      [columnKey]: [],
    })
    const [filterText, setFilterText] = useState<string>('')
    const [togglingSpecificBar, setTogglingSpecificBar] =
      useState<boolean>(false)
    const [deselectedAll, setDeselectedAll] = useState<boolean>(false)

    const { tableFilters, addToFilters, transformBy, stackBy } =
      useTableChartFilters()
    const prevTableFilters = useRef<FilterT[] | null>()

    const allowedFiltersBasedOnColumn = getAllowedFilterType(
      model.schema.columns.find((column) => column.key === columnKey)?.type
    )

    const filtersDepth = getFiltersDepth(
      tableFilters,
      transformBy.value,
      stackBy.value
    )

    const firstElementCheck = useMemo(
      () => findFirstNonEmptyArray(chartData, stackByValue),
      [chartData, stackByValue]
    )
    const getByKey = useMemo(
      () =>
        Array.isArray(firstElementCheck) && firstElementCheck[0].displayText,
      [firstElementCheck]
    )

    // these four filter types are most commonly used so we stick with these here
    // when we toggle by legends we want to exclude
    const filterTypeForExclude = allowedFiltersBasedOnColumn.find((column) =>
      column.key.includes('doesNot')
    )?.key
    // includesAny is used when we toggle by a specific bar in the chart
    const filterTypeForInclude = allowedFiltersBasedOnColumn.find(
      (column) =>
        column.key === 'startsWith' ||
        column.key === 'includesSingle' ||
        column.key === 'includesOnly' ||
        column.key === 'includesAny'
    )?.key

    const isSingleFilterType =
      model.schema.columns.find((column) => column.key === columnKey)?.type ===
      ValueType.SINGLE

    const filteredLegends =
      legends[columnKey] &&
      legends![columnKey].filter(({ key }) =>
        key.toLocaleLowerCase().includes(filterText.toLocaleLowerCase())
      )

    function handleSetLegendLogic(
      prevLegends: Record<string, LegendsType[]>,
      id: string | number
    ) {
      const data = prevLegends![columnKey] || []
      return {
        ...prevLegends,
        [columnKey]: data.map((it) => ({ ...it, checked: it.key === id })),
      }
    }

    function exitLevel2() {
      const _transform = transformBy.value
      const _stack = stackBy.value

      if (stackByValue === _transform) {
        const tempFilters: FilterT[] = [...tableFilters]

        const index = tempFilters.findIndex(
          ({ column }) => column === _transform || column === _stack
        )
        tempFilters.splice(index, 2)
        addToFilters(tempFilters)

        setDeselectedAll(false)

        setLegends((prevLegends) => ({
          ...prevLegends,
          [columnKey]: (prevLegends![columnKey] || []).map((it) => ({
            ...it,
            checked: filteredLegends.includes(it),
          })),
        }))
      }
    }

    function applyFilter(id: string | number) {
      setTogglingSpecificBar(true)
      setLegends((prevLegends) => handleSetLegendLogic(prevLegends, id))
      filterTableThroughChartToggle(id)
    }

    // called when clicking a bar in the chart
    const handleSpecificBarToggle = (
      yFilterValue: string | number,
      xFilterValue: string | number
    ) => {
      if (filtersDepth === 'x-y-Filter') {
        return exitLevel2()
      }

      const xFilterApplied = filtersDepth === 'xFilter'
      const yFilterApplied = filtersDepth === 'yFilter'
      const noFilter = filtersDepth === 'none'

      const isXLegend = stackByValue === transformBy.value
      const isYLegend = stackByValue === stackBy.value

      // (X filter is applied Or no filter is apply )and we want to apply the Y Filter
      // We need to make sure this only runs for the Y Legend
      if (isYLegend && (xFilterApplied || noFilter)) {
        applyFilter(yFilterValue)
      }

      // Y filter is already applied and we want to apply the X Filter
      // We need to make sure this only runs for the X Legend
      if (yFilterApplied && isXLegend) {
        applyFilter(xFilterValue)
      }
    }

    // called when toggling specific legend in left side
    const handleToggle = (key: string) => {
      setDeselectedAll(false)
      setLegends((prevLegends) => ({
        ...prevLegends,
        [columnKey]: (prevLegends![columnKey] || []).map((it) =>
          it.key === key ? { ...it, checked: !it.checked } : it
        ),
      }))
      filterTableByLegends(key)
    }

    // called when clicking select/deselect all button
    const handleToggleAll = (all: boolean) => {
      const tempFilters: FilterT[] = [...tableFilters] ?? []
      const index = tempFilters.findIndex((f) => f.column === columnKey)
      if (index !== -1) tempFilters.splice(index, 1)
      addToFilters(tempFilters)
      if (all) {
        setDeselectedAll(false)
      } else {
        setDeselectedAll(true)
      }
      setLegends((prevLegends) => ({
        ...prevLegends,
        [columnKey]: (prevLegends![columnKey] || []).map((it) =>
          filteredLegends.includes(it) ? { ...it, checked: all } : it
        ),
      }))
    }

    const filterTableThroughChartToggle = (key: string | number) => {
      const tempFilters: FilterT[] = [...tableFilters] ?? []
      const existingIndex = tempFilters.findIndex((f) => f.column === columnKey)
      if (existingIndex !== -1 && togglingSpecificBar) {
        tempFilters.splice(existingIndex, 1)
        addToFilters(tempFilters)
      } else {
        tracking.specificBarToggle({
          value: String(key),
          filterName: stackByValue,
        })
        if (existingIndex !== -1) tempFilters.splice(existingIndex, 1)
        const combined = key.toLocaleString().includes(',')
        const newFilter = [
          {
            column: columnKey,
            type: combined
              ? 'includesAny'
              : isSingleFilterType
                ? 'includesSingle'
                : 'includesAny',
            filterValue:
              filterTypeForInclude === 'startsWith'
                ? key
                : key
                    .toLocaleString()
                    .split(',')
                    .map((k) => {
                      const formattedId = k.trim()
                      return {
                        label: formattedId,
                        value: formattedId,
                      }
                    }),
          },
        ] as FilterT[]
        tempFilters.push(newFilter[0])
        addToFilters(tempFilters)
      }
    }

    const filterTableByLegends = (key: string, togglingAll?: boolean) => {
      const tempFilters: FilterT[] = [...tableFilters] ?? []

      const existingIndex = tempFilters.findIndex((f) => f.column === columnKey)
      if (existingIndex !== -1) {
        const keysArr = key.split(',').map((key) => key.trim())
        keysArr.forEach((key) => {
          const filterValueIndex = tempFilters[
            existingIndex
          ].filterValue.findIndex((item: any) => item.value === key)
          if (filterValueIndex !== -1) {
            tempFilters[existingIndex].filterValue.splice(filterValueIndex, 1)
            if (tempFilters[existingIndex].filterValue.length === 0) {
              tempFilters.splice(existingIndex, 1)
            }
          } else {
            let existingFilterValues = tempFilters[existingIndex].filterValue

            filterTypeForInclude === 'startsWith'
              ? (existingFilterValues += key)
              : existingFilterValues.push({ label: key, value: key })

            tempFilters[existingIndex].filterValue =
              filterTypeForInclude === 'startsWith'
                ? existingFilterValues
                : existingFilterValues.map((k: any) => {
                    return {
                      label: k.label,
                      value: k.value,
                    }
                  })
          }
        })
        addToFilters(tempFilters)
      } else {
        const newFilter = [
          {
            column: columnKey,
            type: togglingAll
              ? filterTypeForInclude
              : deselectedAll
                ? filterTypeForInclude
                : filterTypeForExclude,
            filterValue:
              filterTypeForInclude === 'startsWith'
                ? key
                : key
                    .toLocaleString()
                    .split(',')
                    .map((k) => {
                      const formattedId = k.trim()
                      return {
                        label: formattedId,
                        value: formattedId,
                      }
                    }),
          },
        ] as FilterT[]
        tempFilters.push(newFilter[0])
        addToFilters(tempFilters)
      }
    }

    // when filters are removed from the filter panel this is called to
    // retoggle the legend in the left side
    const reToggleLegend = useCallback(() => {
      // using ref to store previous versions of table filters
      // to determine which has been removed
      if (prevTableFilters.current) {
        // get removed filters
        const removedItems = prevTableFilters.current.filter(
          (prevItem) =>
            !tableFilters.some(
              (currItem) => prevItem.column === currItem.column
            )
        )
        if (tableFilters.length === 0) {
          setLegends((prevLegends) => ({
            ...prevLegends,
            [columnKey]: (prevLegends![columnKey] || []).map((obj) => {
              return {
                ...obj,
                checked: true,
              }
            }),
          }))
        }
        // only want to enter here if there are removed filters
        if (removedItems.length > 0) {
          if (togglingSpecificBar) {
            setLegends((prevLegends) => ({
              ...prevLegends,
              [columnKey]: (prevLegends![columnKey] || []).map((obj) => {
                return {
                  ...obj,
                  checked: true,
                }
              }),
            }))
            setTogglingSpecificBar(false)
          } else {
            // some filters are chosen from a dropdown, this if accounts for that
            if (Array.isArray(removedItems[0].filterValue)) {
              const filterValues: string[] = []
              removedItems[0].filterValue.forEach((filter) => {
                filterValues.push(filter.label)

                setLegends((prevLegends) => ({
                  ...prevLegends,
                  [columnKey]: (prevLegends![columnKey] || []).map((obj) =>
                    obj.key === filter.label ? { ...obj, checked: true } : obj
                  ),
                }))
              })
              // other filters are free text, this if accounts for that
            } else if (removedItems[0].filterValue !== '') {
              setLegends((prevLegends) => ({
                ...prevLegends,
                [columnKey]: (prevLegends![columnKey] || []).map((obj) =>
                  obj.key === removedItems[0].filterValue
                    ? { ...obj, checked: true }
                    : obj
                ),
              }))
            }
          }
        }
      }
      setDeselectedAll(false)
      // set ref to previous version of table filters
      prevTableFilters.current = tableFilters
    }, [columnKey, tableFilters, togglingSpecificBar])

    useEffect(() => {
      !deselectedAll && reToggleLegend()
    }, [deselectedAll, reToggleLegend, tableFilters])

    useImperativeHandle(ref, () => ({
      handleSpecificBarToggle,
    }))

    useEffect(() => {
      tableFilters.forEach((item) => {
        if (item.type === 'includesAny' || item.type === 'includesAll') {
          const filters: string[] = []
          item.filterValue.forEach((filterItem: any) => {
            filters.push(filterItem.value)
            setLegends((prevLegends) => ({
              ...prevLegends,
              [item.column]: (prevLegends![item.column] || []).map((it) =>
                filters.join(', ').includes(it.key)
                  ? { ...it, checked: true }
                  : { ...it, checked: false }
              ),
            }))
          })
        }
        if (item.type === 'doesNotInclude') {
          const filters: string[] = []
          item.filterValue.forEach((filterItem: any) => {
            filters.push(filterItem.value)
            setLegends((prevLegends) => ({
              ...prevLegends,
              [item.column]: (prevLegends![item.column] || []).map((it) =>
                filters.join(', ').includes(it.key)
                  ? { ...it, checked: false }
                  : { ...it, checked: true }
              ),
            }))
          })
        }
      })
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [adjustOpen])

    useEffect(() => {
      setLegends({})
      const join =
        typeof joinedValues !== 'undefined' && joinedValues.includes(columnKey)

      const uniqueNames = (
        getByKey
          ? [
              ...new Set(
                chartData.flatMap((obj: any) =>
                  obj[stackByValue].map((obj2: any) => obj2.displayText)
                )
              ),
            ]
          : [
              ...new Set(
                join
                  ? chartData.map(
                      (obj: any) =>
                        obj[stackByValue] &&
                        obj[stackByValue].toLocaleString().split(',').join(', ')
                    )
                  : chartData.flatMap((obj: any) => obj[stackByValue])
              ),
            ]
      ).filter((v) => v !== null)

      const filteredNames = sortOrder
        ? uniqueNames.filter((w) => sortOrder.includes(w as string))
        : uniqueNames

      setLegends((prevLegends) =>
        title === 'X-axis Options'
          ? {
              ...prevLegends,
              [columnKey]: [
                ...(prevLegends![columnKey] || []),
                ...filteredNames
                  .map((key: any) => {
                    return { key: key.toString(), checked: true }
                  })
                  .sort((a, b) => a.key.localeCompare(b.key)),
              ],
            }
          : {
              ...prevLegends,
              [columnKey]: [
                ...(prevLegends![columnKey] || []),
                ...filteredNames
                  .map((key: any) => {
                    return { key: key.toString(), checked: true }
                  })
                  .sort((a, b) => a.key.localeCompare(b.key)),
              ],
            }
      )

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stackByValue])

    return (
      <>
        <Box
          border='1px solid'
          borderColor='gray3'
          p={4}
          rounded='md'
          boxShadow='sm'
          width={isMobile ? 'full' : '25vw'}
          minH='350px'
        >
          <Text mb={2} fontWeight={'semibold'} width='100%' fontSize='sm'>
            {title}
          </Text>
          <Input
            value={filterText}
            onChange={(e) => setFilterText(e.target.value)}
            boxShadow='sm'
            placeholder='Search options'
            size={'sm'}
          />
          <HStack align={'left'} mt={2}>
            <Button
              size={'xs'}
              onClick={() => handleToggleAll(true)}
              variant={'solid'}
            >
              Select all
            </Button>
            <Button
              size={'xs'}
              onClick={() => handleToggleAll(false)}
              variant={'solid'}
            >
              Deselect all
            </Button>
          </HStack>
          {filteredLegends && (
            <>
              {filteredLegends.length > 0 ? (
                <Virtuoso
                  style={{
                    height: '200px',
                    marginTop: '10px',
                  }}
                  totalCount={filteredLegends.length}
                  itemContent={(index: number) => {
                    const { checked, key } = filteredLegends[index]
                    return (
                      <VStack py={2} align={'left'} gap={3} key={index}>
                        <div style={{ display: 'flex', gap: '5px' }}>
                          <input
                            id={key}
                            style={colors && { accentColor: colors[key] }}
                            type='checkbox'
                            checked={checked}
                            onChange={() => handleToggle(key)}
                          />
                          <label htmlFor={key} style={{ cursor: 'pointer' }}>
                            <Text
                              fontSize='xs'
                              fontWeight={'semibold'}
                              color='black'
                              py='2px'
                              px='4px'
                              borderRadius={'sm'}
                              style={
                                colors && {
                                  backgroundColor: colors[key],
                                }
                              }
                            >
                              {key}
                            </Text>
                          </label>
                        </div>
                      </VStack>
                    )
                  }}
                />
              ) : (
                <Box
                  h='200px'
                  alignItems={'center'}
                  display={'flex'}
                  justifyContent={'center'}
                  textAlign={'center'}
                >
                  <Text maxW={'350px'} textColor={'gray.800'}>
                    No legends found with search term{' '}
                    <Text as={'span'} fontWeight={'semibold'}>
                      {filterText}
                    </Text>
                  </Text>
                </Box>
              )}
            </>
          )}
        </Box>
      </>
    )
  }
)

export default memo(CustomLegends)
