import { INavigationMenu } from 'interfaces/navigationMenu.interface'

export type HealthSecurityPremiumSlugs =
  | ''
  | 'policies'
  | 'all'
  | 'vaccine-strategy'
  | 'initial-and-boosters'
  | 'spread-of-variants'
  | 'reports'
  | 'restrictions-risk'

const HealthSecurityPremiumMenu: Array<
  INavigationMenu<HealthSecurityPremiumSlugs>[]
> = [
  [
    {
      title: 'Epidemiology',
      slug: '',
      description:
        'Visualise trends in cases, hospitalisations, and more. Easily find the latest epidemiological numbers for your countries or regions of interest.',
      createdAt: new Date(2021, 10, 30),
    },
    {
      title: 'Reports',
      slug: 'reports',
      description:
        'All the weekly reports and country-level deep dive reports.',
      createdAt: new Date(2021, 3, 1),
    },
  ],
  [
    {
      title: 'Policies',
      slug: 'policies',
      description:
        'Global political responses to the Covid-19 Pandemic, including flight restrictions, lockdowns, and mask mandates.',
      createdAt: new Date(2021, 11, 1),
      newUntil: new Date(2021, 11, 14),
      children: [
        {
          title: 'Restrictions Risk',
          slug: 'restrictions-risk',
          createdAt: new Date(2022, 0, 25),
        },
      ],
    },
    {
      title: 'Vaccine Strategy',
      slug: 'vaccine-strategy',
      description:
        'How governments are using vaccines to combat the Covid-19 Pandemic, including booster policies, donations, country supply, and approvals.',
      createdAt: new Date(2021, 11, 1),
      newUntil: new Date(2021, 11, 14),
      children: [
        {
          title: 'Initial & Boosters',
          slug: 'initial-and-boosters',
          createdAt: new Date(2021, 11, 1),
          newUntil: new Date(2021, 11, 14),
        },
      ],
    },
    {
      title: 'Spread of Variants',
      slug: 'spread-of-variants',
      description:
        'Insight into variants, their spread, genomic differences, and what impact each may have on the efficacy of treatments and vaccines.',
      createdAt: new Date(2021, 11, 14),
    },
  ],
]

export { HealthSecurityPremiumMenu }
