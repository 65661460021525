import { Download } from '@carbon/icons-react'
import { Button, Spinner, useDisclosure } from '@chakra-ui/react'
import { useCallback } from 'react'

import { PdfBasicUpsell } from './PdfBasicUpsell'
import { useDownloadPdf } from './usePdfData'

export type DownloadPdfReportTriggerComponentProps = {
  isLoading: boolean
  disabled: boolean
  handleClick: () => void
}

export type DownloadPdfReportPropTypes = {
  url: string
  title: string
  disabled?: boolean
  onDownload?: () => void
  triggerComponent?: (
    props: DownloadPdfReportTriggerComponentProps
  ) => React.ReactNode
}

export const DownloadPdfReport = ({
  url,
  title,
  disabled = false,
  onDownload,
  triggerComponent,
}: DownloadPdfReportPropTypes) => {
  const { mutate: downloadPdf, isLoading } = useDownloadPdf(url || '', title)

  const {
    isOpen: upsellIsOpen,
    onOpen: upsellOnOpen,
    onClose: upsellOnClose,
  } = useDisclosure()

  const handleClick = useCallback(async () => {
    if (!url) {
      upsellOnOpen()
      return
    }
    await downloadPdf()
    onDownload?.()
  }, [downloadPdf, onDownload, upsellOnOpen, url])

  return (
    <>
      {!!triggerComponent ? (
        triggerComponent({ isLoading, disabled, handleClick })
      ) : (
        <>
          {isLoading ? (
            <Spinner />
          ) : (
            <Button
              variant='unstyled'
              disabled={disabled}
              onClick={handleClick}
              cursor={!isLoading && !disabled ? 'pointer' : 'default'}
            >
              <Download size={20} />
            </Button>
          )}
        </>
      )}

      <PdfBasicUpsell isOpen={upsellIsOpen} onClose={upsellOnClose} />
    </>
  )
}
