import { IModel } from 'interfaces/model.interface'
import { ValueType } from 'interfaces/valueType.interface'

import ArticleTypes from '../tagColours/ArticleTypes'
import TypeOfEfficacyEffectiveness from '../tagColours/TypeOfEfficacyEffectiveness'
import percent from '../transformValue/percentage'

export type MetaVaccineEfficacyData =
  | 'vaccineMetaNameShortenedFromCandidate'
  | 'typeOfEfficacyEffectiveness'
  | 'endpointMeasure'
  | 'efficacyEffectiveness'
  | 'lower95Ci'
  | 'upper95Ci'
  | 'population'
  | 'dose'
  | 'prevalentVoCVoIInPopulation'
  | 'studySize'
  | 'minimumDaysAfterDose'
  | 'maxDaysAfterDoseIfGiven'
  | 'sourceType'
  | 'sourceLink'
  | 'firstDoseVaccine'
  | 'secondDoseVaccine'
  | 'thirdDoseVaccine'
  | 'fourthDoseVaccine'
  | 'hybridImmunityPerformance'
  | 'publicationDate'
  | 'studyStartDate'
  | 'studyEndDate'
  | 'nameFromLocation'

const allowedAirtables = ['api_real_world', 'api_phase_iii'] as const

export const MetaVaccineEfficacyModel: IModel<
  Record<MetaVaccineEfficacyData, string>,
  typeof allowedAirtables
> = {
  allowedAirtables,
  endpoint: 'meta-vaccine-efficacy',
  name: 'Meta Vaccine Efficacy',
  entityName: 'Meta Vaccine Efficacy',
  detailViewType: 'Generic',
  displayKey: 'vaccineMetaNameShortenedFromCandidate',
  searchField: 'vaccineMetaNameShortenedFromCandidate',
  schema: {
    columns: [
      {
        key: 'vaccineMetaNameShortenedFromCandidate',
        label: 'Vaccine',
        type: ValueType.MULTI,
        width: 150,
      },
      {
        key: 'typeOfEfficacyEffectiveness',
        label: 'Efficacy/Effectiveness Type',
        type: ValueType.MULTI,
        width: 200,
        cellFormat: {
          colours: TypeOfEfficacyEffectiveness,
        },
      },
      {
        key: 'endpointMeasure',
        label: 'Endpoint Measure',
        type: ValueType.TEXT,
        width: 150,
      },
      {
        key: 'efficacyEffectiveness',
        label: 'Efficacy or Effectiveness',
        type: ValueType.TEXT,
        transformValue: percent,
        width: 180,
      },
      {
        key: 'lower95Ci',
        label: 'Lower 95% CI',
        type: ValueType.TEXT,
        transformValue: percent,
        width: 150,
      },
      {
        key: 'upper95Ci',
        label: 'Upper 95% CI',
        type: ValueType.TEXT,
        transformValue: percent,
        width: 150,
      },
      {
        key: 'population',
        label: 'Population',
        type: ValueType.MULTI,
        width: 150,
      },
      {
        key: 'dose',
        label: 'Dose',
        type: ValueType.MULTI,
        width: 150,
      },
      {
        key: 'prevalentVoCVoIInPopulation',
        label: 'Prevalent VoC/VoI in Population',
        type: ValueType.MULTI,
        width: 220,
      },
      {
        key: 'studySize',
        label: 'Study Size',
        type: ValueType.NUMBER,
        width: 150,
      },
      {
        key: 'minimumDaysAfterDose',
        label: 'Min Days After Dose',
        type: ValueType.NUMBER,
        width: 150,
      },
      {
        key: 'sourceType',
        label: 'Source Type',
        type: ValueType.MULTI,
        cellFormat: {
          colours: ArticleTypes,
        },
        width: 150,
      },
      {
        key: 'sourceLink',
        label: 'Source Link',
        type: ValueType.URL,
        width: 150,
      },
      {
        key: 'firstDoseVaccine',
        label: '1st Dose Vaccine',
        type: ValueType.MULTI,
        width: 150,
      },
      {
        key: 'secondDoseVaccine',
        label: '2nd Dose Vaccine',
        type: ValueType.MULTI,
        width: 150,
      },
      {
        key: 'thirdDoseVaccine',
        label: '3rd Dose Vaccine',
        type: ValueType.MULTI,
        width: 150,
      },
      {
        key: 'fourthDoseVaccine',
        label: '4th Dose Vaccine',
        type: ValueType.MULTI,
        width: 150,
      },
      {
        key: 'hybridImmunityPerformance',
        label: 'Hybrid Immunity Performance',
        type: ValueType.SINGLE,
        width: 220,
      },
      {
        key: 'publicationDate',
        label: 'Publication Date',
        type: ValueType.DATE,
        width: 150,
      },
      {
        key: 'studyStartDate',
        label: 'Study Start Date',
        type: ValueType.DATE,
        width: 150,
      },
      {
        key: 'studyEndDate',
        label: 'Study End Date',
        type: ValueType.DATE,
        width: 150,
      },
      {
        key: 'nameFromLocation',
        label: 'Study Location',
        type: ValueType.MULTI,
        width: 150,
      },
    ],
  },
}
