import { IApp } from 'interfaces/navigationApp.interface'

import { AccessGroups } from 'enums/AccessGroups'

import { InfluenzaCustomPages } from 'config/apps/Influenza/Science360/VaccinesTherapeutics/InfluenzaCustomPages'
import {
  InfluenzaMenu,
  InfluenzaSlugs,
} from 'config/apps/Influenza/Science360/VaccinesTherapeutics/InfluenzaMenu'
import { InfluenzaPages } from 'config/apps/Influenza/Science360/VaccinesTherapeutics/InfluenzaPages'
import { RespiratoryTimelineModel } from 'config/common/baseModel/respiratory360/RespiratoryTimeline'

const ModernaInfluenzaScience360App: IApp<InfluenzaSlugs> = {
  name: 'Influenza',
  slug: 'moderna-influenza-science360',
  accessGroups: [AccessGroups.Moderna],
  commonIdentifier: 'influenza',
  endpoint: 'influenza',
  menu: InfluenzaMenu,
  pages: InfluenzaPages,
  baseModels: [RespiratoryTimelineModel],
  customPages: InfluenzaCustomPages,
  createdAt: new Date(2022, 5, 30),
}

export default ModernaInfluenzaScience360App
