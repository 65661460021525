import { Box, Link, SimpleGrid, Text, VStack } from '@chakra-ui/react'
import Case from 'case'
import _ from 'lodash'
import { useContext } from 'react'
import { Link as ReactRouterLink } from 'react-router-dom'

import { AppParams, useAppParams } from 'routes/utils'

import { AuthContext } from 'contexts'

import { IApp } from 'interfaces/navigationApp.interface'

import useTracking from 'tracking/useTracking'

import {
  AppletGroupConfig,
  useAppletGroupFromApplet,
} from 'config/appletGroups'
import { useAppletFromApp } from 'config/applets'

import resolvePath from 'utils/resolvePath'

export type AppsSelectorBodyPropTypes = {
  appletGroupsToShow: AppletGroupConfig[]
  onSelectApp?: () => void
}

const AppCard = ({
  appletGroup,
  selectedAppletGroup,
  app,
  appParams,
  currentApp,
  currentPageSlug,
  onSelectApp,
}: {
  appletGroup: AppletGroupConfig
  selectedAppletGroup: AppletGroupConfig | undefined
  app: IApp<any> | undefined
  appParams: AppParams
  currentApp: any
  currentPageSlug: string
  onSelectApp: (() => void) | undefined
}) => {
  const [tracking] = useTracking()
  const currentlySelected = selectedAppletGroup?.key === appletGroup.key
  return (
    <VStack
      data-cy='app-card'
      justifyContent='space-between'
      border='1px solid'
      borderColor='gray.200'
      rounded='md'
      p='24px'
      minW={{ lg: '200px', md: '180px' }}
      boxShadow='sm'
      alignItems='flex-start'
      spacing={3}
      key={appletGroup.key}
      {...(currentlySelected ? { bgColor: 'gray4' } : {})}
    >
      <VStack alignItems='flex-start' spacing={1}>
        <Text color='black' fontSize='12px'>
          {Case.title(appletGroup.category)}
        </Text>
        <Text color='black' fontSize='15px' fontWeight='500'>
          {appletGroup.name}
        </Text>
        <Text fontSize='13px' color='gray1'>
          {appletGroup.description}
        </Text>
      </VStack>

      <Box>
        {currentlySelected && (
          <Text fontSize='12px' fontWeight={500}>
            Already Open
          </Text>
        )}
        {!currentlySelected && (
          <Link
            to={`/${app?.slug}${
              app?.defaultEntrySlug ? resolvePath([app?.defaultEntrySlug]) : ''
            }`}
            onClick={() => {
              appParams &&
                tracking.changeApp({
                  fromApp: currentApp,
                  fromPage: currentPageSlug,
                  toApp: app?.slug,
                })
              onSelectApp?.()
            }}
            as={ReactRouterLink}
            py={2}
            px={4}
            fontWeight={500}
            fontSize='12px'
            rounded='sm'
            bgColor='yellow.500'
            color='black'
            variant='button'
            _hover={{
              textDecor: 'none',
              boxShadow: 'base',
            }}
            data-cy='open-app-button'
          >
            Open App
          </Link>
        )}
      </Box>
    </VStack>
  )
}

const AppsSelectorBody = ({
  appletGroupsToShow,
  onSelectApp,
}: AppsSelectorBodyPropTypes) => {
  const appParams = useAppParams()
  const { app: currentApp, pageSlug: currentPageSlug } = appParams

  const selectedApplet = useAppletFromApp(currentApp)
  const selectedAppletGroup = useAppletGroupFromApplet(selectedApplet?.key!)
  const { appListUserCanAccess, appletsUserCanSee } = useContext(AuthContext)
  const appsByGroup = _.groupBy(appletGroupsToShow, (applet) => applet.group)

  function findApp(appletsUserCanSee: any, appletGroup: AppletGroupConfig) {
    return appListUserCanAccess.find(
      (app) => app.slug === appletsUserCanSee[appletGroup.applets[0]]?.apps?.[0]
    )
  }

  return (
    <VStack spacing='2rem' alignItems='flex-start'>
      {Object.keys(appsByGroup).map((group, index) => {
        return (
          <Box key={index}>
            <Text variant={'body-bold'} fontSize='18px' mb='12px'>
              {group}
            </Text>
            <SimpleGrid
              columns={{ base: 2, md: 2, lg: 3 }}
              width='100%'
              spacing='24px'
            >
              {appsByGroup[group].map((appletGroup, index) => {
                const app = findApp(appletsUserCanSee, appletGroup)
                return (
                  <AppCard
                    key={index}
                    appletGroup={appletGroup}
                    selectedAppletGroup={selectedAppletGroup}
                    app={app}
                    appParams={appParams}
                    currentApp={currentApp}
                    currentPageSlug={currentPageSlug}
                    onSelectApp={onSelectApp}
                  />
                )
              })}
            </SimpleGrid>
          </Box>
        )
      })}
    </VStack>
  )
}

export default AppsSelectorBody
