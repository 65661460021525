import { get } from 'lodash'
import moment from 'moment'
import { useCallback, useMemo } from 'react'
import { utils } from 'xlsx'

import { IResponseBase } from 'api/types'

import { CustomColumn } from './generateColumns'
import { relationGetDisplayValue } from './relational'

export type UseExportDataProps = {
  exportName: string
  columns: Array<CustomColumn<any>>
  data: IResponseBase<string>[] | undefined
}

export const useExportData = ({
  exportName,
  columns,
  data,
}: UseExportDataProps) => {
  const timestamp = useMemo(() => moment().format('YYYY_MM_DD'), [])
  const getFileName = useCallback(
    (extension: string) =>
      (exportName
        ? exportName + '_' + timestamp
        : `download.${extension}_` + timestamp) + `.${extension}`,
    [exportName, timestamp]
  )

  const headers = useMemo(() => columns.map((col) => col.Header), [columns])
  const rowsData = useMemo(
    () =>
      data?.map((row, i) =>
        columns.map((col) => {
          const field = relationGetDisplayValue(get(row, col.id ?? ''))

          // Handle array
          if (Array.isArray(field)) {
            return (
              field
                .join(';')
                .replace(/"/g, '""')
                // We need to cap the length of the array due to excel limitation
                // https://support.microsoft.com/en-us/office/excel-specifications-and-limits-1672b34d-7043-467e-8e27-269d656771c3?ui=en-us&rs=en-us&ad=us
                // Otherwise weird things will happen
                .slice(0, 32700)
            )
          }

          return field?.toString().replace(/"/g, '""').slice(0, 32700)
        })
      ) ?? [],
    [data, columns]
  )

  // Complete array of array data
  let aoaData = useMemo(() => [headers, ...rowsData], [headers, rowsData])

  const workbook = useExcelExport({ aoaData, columns })

  return { getFileName, workbook, aoaData }
}

type UseExcelExportProps = {
  aoaData: Array<Array<any>>
  columns: Array<CustomColumn<any>>
}

export const useExcelExport = ({ aoaData, columns }: UseExcelExportProps) => {
  const wb = useMemo(() => {
    // Excel
    const workbook = utils.book_new()
    const worksheet = utils.aoa_to_sheet(aoaData)

    // For excel, we need to handle number column type
    // @ts-ignore
    const numberColumnIndexes: number[] = (columns as Array<CustomColumn<any>>)
      .map((x, i) => (x.internalColumnDefinition?.type === 'NUMBER' ? i : null))
      .filter((x) => x !== null)

    // There doesn't seem to be a way to set the type on the column level so we're setting it on each cell
    numberColumnIndexes.forEach((columnIndex) => {
      // -1 to remove the header from the loop
      for (let j = 0; j < aoaData.length - 1; j++) {
        const cellRef = utils.encode_cell({ c: columnIndex, r: j + 1 }) // +1 to exclude header
        if (worksheet[cellRef]) {
          worksheet[cellRef].t = 'n'
        }
      }
    })
    worksheet['!cols'] = columns.map((col) => ({
      wpx: (col.width as number) ?? 80,
    }))

    utils.book_append_sheet(workbook, worksheet, 'Sheet 1')

    return workbook
  }, [aoaData, columns])

  return wb
}
