export const tooltipText = (
  <>
    <p>
      Airfinity has collaborated with Bloomberg News to map the post-pandemic
      resurgence of 13 select epidemic-prone infectious diseases. The spike size
      is determined by the change in reported cases from a post-pandemic year
      (2022-onwards), as of 1st June 2024, with highest reported incidence
      against a single pre-pandemic ‘baseline’ year within the 2017-2019 period,
      preceding the COVID-19 pandemic. The ‘baseline’ year for pertussis may be
      set to an earlier date, contingent on the timing of the most recent
      epidemic in the specific country pre-pandemic.
    </p>
    <p>
      The diseases included in this analysis are chickenpox, cholera, dengue,
      measles, Mycoplasma pneumoniae, parvovirus B19, invasive Group A
      Streptococcus, pertussis, influenza, respiratory syncytial virus (RSV),
      wild poliovirus type 1 (WPV1), vaccine-derived poliovirus and
      tuberculosis.
    </p>
    <p>
      The analysis relies heavily on data reported by all countries globally,
      which in turn depends on the robustness of their infectious disease
      surveillance and reporting systems. Consequently, the data by no means
      represents the true burden of these diseases within populations, as many
      cases might go undetected due to asymptomatic presentations and mild
      clinical symptoms, leading to undiagnosed cases, as well as limited
      resources for testing. Furthermore, the disease data presented here is not
      exhaustive, with large gaps for both notifiable and non-notifiable
      diseases. Some countries/regions might be overrepresented than others due
      to their superior surveillance and reporting systems. In addition, changes
      in testing levels post-pandemic likely affect recent disease incidence,
      resulting in large post-pandemic spikes that might incorrectly imply
      significantly higher disease incidence or change disease epidemiology.
    </p>
    <p>
      Although still incomplete, 2024 data is included in the analysis. This
      inclusion is based on the observation that certain diseases have exhibited
      an already higher number of cases in the first months of 2024 than in any
      other year since the pandemic subsided, sometimes exceeding pre-pandemic
      levels. In such instances, the fold change is calculated based on
      year-to-date cases for 2024, with the expectation that these figures will
      rise as more months’ data becomes available. For visualisation purposes,
      wild poliovirus type 1 and vaccine-derived poliovirus subtypes have been
      combined into a single disease named polio.
    </p>
  </>
)

const getOrdinal = (n: number) =>
  `${n}${
    n % 100 >= 11 && n % 100 <= 13
      ? 'th'
      : ['th', 'st', 'nd', 'rd'][n % 10] || 'th'
  }`

export const hoverTooltipHTML = ({ sourceData }: { sourceData: any }) => {
  return `<style>
  .table_component {
      overflow: auto;
      border-radius: 12px;
  }
  .caption {
      min-width: max-content;
      caption-side: top;
      text-align: left;
      font-weight: bold;
  }
  .container {
      display: flex;
      flex-direction: column;
      height: 100%;
      text-align: left;
  }
  .row {
      display: flex;
      padding: 2px 0;
  }
  .value {
      color: #ffffff;
      padding-left: 5px;
      font-weight: bold;
  }
  </style>
  
  <div class="table_component" role="region" tabindex="0">
      <div class="container">
          <div class="row">
              <div class="label">Disease:</div>
              <div class="value">${sourceData.disease}</div>
          </div>
          <div class="row">
              <div class="label">Country:</div>
              <div class="value">${sourceData.country}</div>
          </div>
          <div class="row">
              <div class="label">Fold change:</div>
              <div class="value">${sourceData.value}</div>
          </div>
          <div class="row">
          <div class="label">Cases per 100,000 in ${
            sourceData.comparisonYear
          }:</div>
          <div class="value">${sourceData.casesPerPrePandemic}</div>
          </div>
          <div class="row">
              <div class="label">Cases per 100,000 in ${
                sourceData.dateEndYear
              }:</div>
              <div class="value">${sourceData.casesPer}</div>
          </div>
          <div class="row">
              <div class="label">Country rank:</div>
              <div class="value">${getOrdinal(
                parseInt(sourceData.rank)
              )} out of ${
    sourceData.numCountriesIncrease
  } seeing an increase</div>
          </div>   
          <div class="row">
              <div class="label">Countries analysed:</div>
              <div class="value">${sourceData.numCountries}</div>
          </div>   
          <div class="row">
              <div class="label">Source:</div>
              <div class="value">${sourceData.sources}</div>
          </div>
      </div>
  </div>
  `
}
