export default {
  'Small Molecule': 'rgb(248, 43, 96)',
  'Monoclonal Antibody': 'rgb(255, 111, 44)',
  siRNA: 'rgb(255, 158, 183)',
  ASO: 'rgb(237, 226, 254)',
  unknown: 'rgb(204, 204, 204)',
  Protein: 'rgb(186, 30, 69)',
  'Fatty Acid': 'rgb(237, 226, 254)',
  'Combination Drug': 'rgb(11, 118, 183)',
  RNAi: 'rgb(32, 201, 51)',
  'Cell Therapy': 'rgb(207, 223, 255)',
  'Gene Therapy': 'rgb(208, 240, 253)',
  'DNA Aptamer': 'rgb(194, 245, 233)',
  Macromolecule: 'rgb(209, 247, 196)',
  'Antibody Fusion Protein': 'rgb(255, 234, 182)',
  'Adenovirus + Transgene': 'rgb(254, 226, 213)',
  Peptide: 'rgb(255, 220, 229)',
  'Traditional Medicine': 'rgb(255, 218, 246)',
  'Inactivated Virus': 'rgb(237, 226, 254)',
  'Stem Cells': 'rgb(255, 111, 44)',
  Lipase: 'rgb(119, 209, 243)',
  'Gene Editing': 'rgb(184, 117, 3)',
  'Gut Microbiota Replacement': 'rgb(107, 28, 176)',
  'Cell Matrix': 'rgb(107, 28, 176)',
  'Vascular Prosthesis': 'rgb(114, 221, 195)',
  'Biological Cellular Matrix': 'rgb(24, 191, 255)',
  Macrolide: 'rgb(147, 224, 136)',
  sm: 'rgb(204, 204, 204)',
  Vaccine: 'rgb(156, 199, 255)',
  Nuclease: 'rgb(156, 199, 255)',
  Polysaccharide: 'rgb(24, 191, 255)',
  'Monoclonal Antibody Fragment': 'rgb(238, 238, 238)',
  Antidote: 'rgb(119, 209, 243)',
  'Dual Therapy': 'rgb(45, 127, 249)',
  'Lignin polymer': 'rgb(205, 176, 255)',
}
