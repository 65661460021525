import { IModel } from 'interfaces/model.interface'
import { ValueType } from 'interfaces/valueType.interface'

import { SortOrders } from 'enums/SortOrders'

import Continents from '../tagColours/Continents'
import EconomyIncomeBand from '../tagColours/EconomyIncomeBand'
import floatToPercentageFormat from '../transformValue/percentage'

export type CountriesData =
  | 'name'
  | 'uiCandidateCount'
  | 'uiClinicalCandidateCount'
  | 'uiClinicalTrialsCount'
  | 'uiTrialToCandidateRatio'
  | 'uiVaccines'
  | 'afSupplyDealVaccine'
  | 'population'
  | 'afSumPotentialExpansion'
  | 'afSumSupplyAndPotentialExpansion'
  | 'afWillingnessToTakeA_COVID19Vaccine'
  | 'sumAgreedSupply'
  | 'luEmergencyApproval'
  | 'luFullApproval'
  | 'afGenomesPublished'
  | 'afGenomesPublishedPerCase'
  | 'luInfectionRatePer10000'
  | 'afTotalVaccinations'
  | 'afTotalDeliveries'
  | 'afVaccinesDelivered'
  | 'luCovaxH1Deliveries'
  | 'afPeopleVaccinatedOneDose'
  | 'afPeopleFullyVaccinated'
  | 'linkSuspendedUse'
  | 'luSuspendedUseVaccineName'
  | 'fAged20Plus'
  | 'afPrevalenceB117'
  | 'afPrevalenceB16172'
  | 'afPrevalenceB1351'
  | 'afPrevalenceP1'
  | 'sumCovaxSupply'
  | 'sumDonationsSupply'
  | 'sumDosesDonatedFromBilateralDeals'
  | 'afTotalCasesB16172'
  | 'afPolicyForPreviouslyInfectedNotes'
  | 'afPolicyForPreviouslyInfectedLink'
  | 'afPolicyForThirdDosesUrl'
  | 'afThirdDosePolicy'
  | 'afBoostersAdministered'
  | 'afBoostersAdministeredPerHundred'
  | 'visaguideVaccineInvalid'
  | 'visaguideVaccineValid'
  | 'afEconomyIncomeBand'
  | 'dailyTestsPerThousand'
  | 'afTestingPolicy'
  | 'afContactTracing'
  | 'afRestrictionsOnInternalMovement'
  | 'afInternationalTravelControls'
  | 'afNewTests'
  | 'afTotalTests'
  | 'afTotalTestsPerThousand'
  | 'afNewTestsPerThousand'
  | 'afNewTestsSmoothed'
  | 'afPositiveRate'
  | 'timeInterval'
  | 'boosterVaccineCandidate'
  | 'afStartDateForThirdDoses'
  | 'afCountryDeepDive'
  | 'afPolicy'
  | 'afPolicyLink'
  | 'fTotalDeliveries'
  | 'fDeliveriesToDateExcThoseDonated'
  | 'fBilateralDeliveryToDate'
  | 'fCovaxDeliveryToDate'
  | 'fDirectDonationDeliveryToDate'
  | 'afContinent'

const allowedAirtables = [
  'API',
  'api_supply_per_country',
  'api_variants',
  'api_diag',
  'api_supply_and_production',
] as const

export const CountriesModel: IModel<
  Record<CountriesData, string>,
  typeof allowedAirtables
> = {
  allowedAirtables,
  key: 'title',
  name: 'Countries',
  entityName: 'Countries',
  searchField: 'name',
  displayKey: 'name',
  detailViewType: 'Generic',
  defaultSortKey: 'uiTrialToCandidateRatio',
  defaultSortOrder: SortOrders.DESC,
  endpoint: 'country',
  schema: {
    columns: [
      { label: 'Name', key: 'name', width: 200 },
      {
        key: 'uiCandidateCount',
        type: ValueType.NUMBER,
        width: 140,
        label: '# of candidates',
      },
      {
        key: 'uiClinicalCandidateCount',
        type: ValueType.NUMBER,
        width: 150,
        label: '# of clinical candidates',
      },
      {
        key: 'uiTrialToCandidateRatio',
        width: 260,
        type: ValueType.TEXT,
        label: 'Trial to candidate ratio',
      },
      {
        key: 'uiVaccines',
        label: 'Vaccines',
        type: ValueType.MULTI,
        width: 430,
      },
      {
        key: 'afSupplyDealVaccine',
        label: 'COVID-19 vaccines secured',
        type: ValueType.MULTI,
        width: 430,
      },
      {
        key: 'population',
        label: 'Population',
        type: ValueType.NUMBER,
        width: 140,
      },
      {
        key: 'afSumPotentialExpansion',
        label: 'Potential expansion to order of COVID-19 vaccine doses',
        type: ValueType.NUMBER,
        width: 170,
      },
      {
        key: 'afSumSupplyAndPotentialExpansion',
        label:
          'Total supply of bilateral deals COVID-19 vaccine doses secured (inc. Potential expansions)',
        type: ValueType.NUMBER,
        width: 330,
      },
      {
        key: 'afWillingnessToTakeA_COVID19Vaccine',
        label: 'Willingness to take a COVID19 Vaccine',
        type: ValueType.TEXT,
        width: 300,
        transformValue: floatToPercentageFormat,
      },
      {
        key: 'sumAgreedSupply',
        label: 'Total supply of bilateral deals COVID-19 vaccine doses secured',
        type: ValueType.NUMBER,
        width: 130,
      },
      {
        key: 'luEmergencyApproval',
        label: 'Emergency Approval of COVID-19 vaccines',
        type: ValueType.MULTI,
        width: 280,
      },
      {
        key: 'luFullApproval',
        label: 'COVID-19 vaccines granted full approval',
        type: ValueType.MULTI,
        width: 280,
      },
      {
        key: 'afGenomesPublished',
        label: 'Genomes Published',
        type: ValueType.NUMBER,
        width: 150,
      },
      {
        key: 'afGenomesPublishedPerCase',
        label: 'Genomes Published Per Case',
        type: ValueType.TEXT,
        width: 170,
      },
      {
        key: 'luInfectionRatePer10000',
        label: 'Infection Rate per 10000',
        type: ValueType.TEXT,
        width: 170,
        tooltip:
          'Average number of COVID cases over the last 7 days/population * 10,000',
      },
      {
        key: 'afTotalVaccinations',
        label: 'Total vaccines administered to date',
        type: ValueType.NUMBER,
        width: 170,
      },
      {
        key: 'afTotalDeliveries',
        label: 'Total Deliveries to date of COVID-19 vaccine doses',
        type: ValueType.NUMBER,
        width: 170,
      },
      {
        key: 'afVaccinesDelivered',
        label: 'Vaccines Delivered',
        type: ValueType.MULTI,
        width: 350,
      },
      {
        key: 'luCovaxH1Deliveries',
        label: 'COVAX H1 Deliveries',
        type: ValueType.MULTI,
        width: 170,
      },
      {
        key: 'afPeopleVaccinatedOneDose',
        label: 'People Vaccinated One Dose',
        type: ValueType.NUMBER,
        width: 170,
      },
      {
        key: 'afPeopleFullyVaccinated',
        label: 'People Fully Vaccinated',
        type: ValueType.NUMBER,
        width: 170,
      },
      {
        key: 'linkSuspendedUse',
        label: 'Link Suspended Use',
        type: ValueType.MULTI,
        width: 200,
      },
      {
        key: 'luSuspendedUseVaccineName',
        label: 'Vaccine Suspended',
        type: ValueType.MULTI,
        width: 270,
      },
      {
        key: 'fAged20Plus',
        label: 'Aged 20+',
        type: ValueType.NUMBER,
        width: 150,
      },
      {
        key: 'afPrevalenceB117',
        label: 'Prevalence B.1.1.7',
        type: ValueType.NUMBER,
        width: 150,
      },
      {
        key: 'afPrevalenceB16172',
        label: 'Prevalence B.1.617.2',
        type: ValueType.NUMBER,
        width: 150,
      },
      {
        key: 'afPrevalenceB1351',
        label: 'Prevalence B.1.351',
        type: ValueType.NUMBER,
        width: 150,
      },
      {
        key: 'afPrevalenceP1',
        label: 'Prevalence P.1',
        type: ValueType.NUMBER,
        width: 150,
      },
      {
        key: 'sumCovaxSupply',
        label: 'Supply secured from COVAX in doses',
        width: 130,
        type: ValueType.NUMBER,
      },
      {
        key: 'sumDonationsSupply',
        label: 'Supply secured from donations in doses',
        width: 130,
        type: ValueType.NUMBER,
      },
      {
        key: 'sumDosesDonatedFromBilateralDeals',
        label:
          'Supply donated to other countries from bilateral deals in doses',
        width: 130,
        type: ValueType.NUMBER,
      },
      {
        key: 'afTotalCasesB16172',
        label: 'Total Cases B.1.617.2',
        width: 150,
        type: ValueType.NUMBER,
      },
      {
        key: 'afPolicyForPreviouslyInfectedNotes',
        label: 'Policy for previously infected',
        width: 230,
        type: ValueType.TEXT,
      },
      {
        key: 'afPolicyForPreviouslyInfectedLink',
        label: 'Policy for previously infected source',
        width: 120,
        type: ValueType.URL,
      },
      {
        key: 'afPolicyForThirdDosesUrl',
        label: 'Policy for third doses',
        width: 120,
        type: ValueType.URL,
      },
      {
        key: 'afThirdDosePolicy',
        label: 'Third Dose Policy',
        width: 230,
        type: ValueType.MULTI,
      },
      {
        key: 'afBoostersAdministered',
        label: 'Boosters Administered',
        width: 150,
        type: ValueType.NUMBER,
      },
      {
        key: 'afBoostersAdministeredPerHundred',
        label: 'Boosters Administered Per Hundred',
        width: 150,
        type: ValueType.NUMBER,
      },
      {
        key: 'visaguideVaccineInvalid',
        label: 'Vaccines not authorised for entry into the country',
        width: 200,
        type: ValueType.MULTI,
      },
      {
        key: 'visaguideVaccineValid',
        label: 'Vaccines authorised for entry into the country',
        width: 200,
        type: ValueType.MULTI,
      },
      {
        key: 'afEconomyIncomeBand',
        label: 'Economy Income Band',
        width: 200,
        type: ValueType.MULTI,
        cellFormat: {
          colours: EconomyIncomeBand,
        },
      },
      {
        key: 'dailyTestsPerThousand',
        label: 'Daily Tests Per Thousand',
        width: 200,
        type: ValueType.NUMBER,
      },
      {
        key: 'afTestingPolicy',
        label: 'Testing Policy',
        width: 180,
        type: ValueType.MULTI,
      },
      {
        key: 'afContactTracing',
        label: 'Contact Tracing',
        width: 180,
        type: ValueType.MULTI,
      },
      {
        key: 'afRestrictionsOnInternalMovement',
        label: 'Restrictions On Internal Movement',
        width: 230,
        type: ValueType.MULTI,
      },
      {
        key: 'afInternationalTravelControls',
        label: 'International Travel Controls',
        width: 200,
        type: ValueType.MULTI,
      },
      {
        key: 'afNewTests',
        label: 'New Tests',
        width: 150,
        type: ValueType.NUMBER,
      },
      {
        key: 'afTotalTests',
        label: 'Total Tests',
        width: 150,
        type: ValueType.NUMBER,
      },
      {
        key: 'afTotalTestsPerThousand',
        label: 'Total Tests Per Thousand',
        width: 180,
        type: ValueType.NUMBER,
      },
      {
        key: 'afNewTestsPerThousand',
        label: 'New Tests Per Thousand',
        width: 180,
        type: ValueType.NUMBER,
      },
      {
        key: 'afNewTestsSmoothed',
        label: 'New Tests Smoothed',
        width: 180,
        type: ValueType.NUMBER,
      },
      {
        key: 'afPositiveRate',
        label: 'Positive Rate',
        width: 150,
        type: ValueType.NUMBER,
      },
      {
        key: 'timeInterval',
        label: 'Time Interval',
        width: 150,
        type: ValueType.MULTI,
      },
      {
        key: 'boosterVaccineCandidate',
        label: 'Booster Vaccine Candidate',
        width: 180,
        type: ValueType.MULTI,
      },
      {
        key: 'afStartDateForThirdDoses',
        label: 'Start Date For Third Doses',
        width: 180,
        type: ValueType.DATE,
      },
      {
        key: 'afCountryDeepDive',
        label: 'Country Deep Dive',
        width: 180,
        type: ValueType.FILES,
      },
      {
        key: 'afPolicy',
        label: 'Policy',
        width: 180,
        type: ValueType.TEXT,
      },
      {
        key: 'afPolicyLink',
        label: 'Policy Link',
        width: 180,
        type: ValueType.URL,
      },
      {
        key: 'afContinent',
        label: 'Continent',
        width: 170,
        type: ValueType.MULTI,
        cellFormat: {
          colours: Continents,
        },
      },
      {
        key: 'fTotalDeliveries',
        label: 'Total Doses Delivered to Date (inc. those donated)',
        width: 330,
        type: ValueType.NUMBER,
      },
      {
        key: 'fDeliveriesToDateExcThoseDonated',
        label: 'Total Doses Delivered to Date (exc. those donated)',
        width: 330,
        type: ValueType.NUMBER,
      },
      {
        key: 'fBilateralDeliveryToDate',
        label: 'Doses Delivered to Date from Bilateral Deals',
        width: 300,
        type: ValueType.NUMBER,
      },
      {
        key: 'fCovaxDeliveryToDate',
        label: 'Doses Delivered to Date from COVAX',
        width: 250,
        type: ValueType.NUMBER,
      },
      {
        key: 'fDirectDonationDeliveryToDate',
        label: 'Doses Delivered to Date from Direct Donations from Countries',
        width: 400,
        type: ValueType.NUMBER,
      },
    ],
  },
}
