/**
 * This hook simply adds the `setState` function to allow us to override table state
 */
import { useCallback } from 'react'

// Actions
const setStateAction = 'setState'

export const useTableState = (hooks: any) => {
  hooks.stateReducers.push(reducer)
  hooks.useInstance.push(useInstance)
}

useTableState.pluginName = 'useTableState'

function reducer(state: any, action: any) {
  if (action.type === setStateAction) {
    return {
      ...state,
      ...action.newState,
    }
  }
}

function useInstance(instance: any) {
  const { dispatch } = instance

  const setState = useCallback(
    (newState: any) => {
      dispatch({
        type: setStateAction,
        newState,
      })
    },
    [dispatch]
  )

  Object.assign(instance, {
    setState,
  })
}

// eslint-disable-next-line
export interface UseTableStateInstanceProps<D> {
  setState: (state: any) => void
}
