import { IModel } from 'interfaces/model.interface'
import { ValueType } from 'interfaces/valueType.interface'

import { SortOrders } from 'enums/SortOrders'

export type ResearchPowerIndexTweetsData =
  | 'persons'
  | 'screenName'
  | 'createdAt'
  | 'fullText'
  | 'favoriteCount'
  | 'retweetCount'
  | 'createdAt'
  | 'relevant'

const allowedAirtables = ['api_front_end_view_twitter'] as const

export const ResearchPowerIndexTweetsModel: IModel<
  Record<ResearchPowerIndexTweetsData, string>,
  typeof allowedAirtables
> = {
  allowedAirtables,
  displayKey: 'persons',
  name: 'Tweets',
  entityName: 'Tweets',
  detailViewType: 'Generic',
  searchField: 'fullText',
  endpoint: 'tweet',
  defaultSortObject: [{ id: 'createdAt', sortOrder: SortOrders.DESC }],
  schema: {
    columns: [
      { key: 'persons', label: 'Persons', width: 200, type: ValueType.MULTI },
      {
        key: 'screenName',
        label: 'Screen Name',
        width: 140,
        type: ValueType.TEXT,
      },
      {
        key: 'createdAt',
        label: 'Created At',
        width: 120,
        type: ValueType.DATE,
      },
      {
        key: 'fullText',
        label: 'Tweet Text',
        width: 300,
        type: ValueType.TEXT,
      },
      {
        key: 'favoriteCount',
        label: 'Favorite Count',
        width: 120,
        type: ValueType.NUMBER,
      },
      {
        key: 'retweetCount',
        label: 'Retweet Count',
        width: 120,
        type: ValueType.NUMBER,
      },
      { key: 'relevant', label: 'Relevant', width: 200, type: ValueType.TEXT },
    ],
  },
}
