import { SortingRule, TableState } from 'react-table'

import { IModel } from 'interfaces/model.interface'

export const PAGINATION_SIZES = [100, 75, 50, 25]
export const BASE_STATE_PAGE_SIZE = 25

export const defaultState: TableState = {
  pageIndex: 0,
  pageSize: BASE_STATE_PAGE_SIZE,
  hiddenColumns: [],
  sortBy: [],
  advancedFilters: [],
  dropdowns: {},
  columnOrder: [],
  filters: [],
  expanded: {},
  globalFilter: undefined,
  groupBy: [],
  // @ts-ignore
  columnResizing: { columnWidths: {} },
}

export const getTableStateFromModel = (
  model: IModel<any>
): Partial<TableState> => {
  return {
    hiddenColumns: model.schema.columns
      .filter((x) => x.hiddenByDefault === true)
      .map((x) => x.key),

    sortBy: model.defaultSortObject
      ? (model.defaultSortObject.map((x) => ({
          id: x.id,
          desc: x.sortOrder && x.sortOrder === 'DESC',
        })) as SortingRule<{}>[])
      : // If we don't specify the full object, then we create ourself
        ([
          {
            id: model.defaultSortKey,
            desc: model.defaultSortOrder && model.defaultSortOrder === 'DESC',
          },
        ] as SortingRule<{}>[]),

    groupBy: !!model.grouping ? [model.grouping?.groupBy] : [],
  }
}
