import { Box, Skeleton } from '@chakra-ui/react'
import React, {
  useMemo,
  useCallback,
  useState,
  useContext,
  useEffect,
} from 'react'
import AutoSizer from 'react-virtualized-auto-sizer'
import { Virtuoso } from 'react-virtuoso'
import _ from 'underscore'

import ArticleModal from 'modules/ArticleModal'
import GenericDrawer from 'modules/GenericDrawer'

import { useAppRoute } from 'routes/utils'

import { flattenPaginatedData } from 'api/helper'
import { ICleanResponse, IResponseBase, ResponseData } from 'api/types'
import useCollectionData from 'api/useCollectionData'

import { IModel } from 'interfaces/model.interface'
import {
  ExtractModelData,
  ExtractModelDataUnion,
} from 'interfaces/model.interface'
import { ViewData } from 'interfaces/navigationPage.interface'

import { randomIntFromInterval } from 'utils/random'
import { isRelational, relationGetIds } from 'utils/relational'
import SortData from 'utils/sort'

import {
  DrawerContent,
  DrawerContentContextContentType,
} from '../DetailDrawerTabs'
import { Empty } from './DetailDrawerListEmpty'

export type ItemComponentPropTypes<D> = {
  item: ICleanResponse<Extract<keyof D, string>>
  handleSelect: (data: ICleanResponse<Extract<keyof D, string>>) => void
}

type PropTypes<TModel extends IModel<any>> = {
  ids: ResponseData
  model: TModel
  viewData: ViewData
  type?: string
  sortOrder?:
    | ExtractModelDataUnion<TModel>
    | ((data: ICleanResponse<ExtractModelDataUnion<TModel>>) => any)
  filterData?: (
    data: ICleanResponse<any>[] | undefined
  ) => ICleanResponse<any>[] | undefined
  itemComponent: React.FC<ItemComponentPropTypes<ExtractModelData<TModel>>>
  selectedItemComponent?: any
  id?: number
}

export function DetailDrawerList<TModel extends IModel<any>>({
  ids: rawIds,
  id,
  type,
  model,
  viewData,
  sortOrder,
  filterData,
  itemComponent,
}: PropTypes<TModel>) {
  const content = useContext(DrawerContent)
  const [selectedRow, setRow] = useState<IResponseBase | null>(null)

  const handleSelectRow = useCallback(
    (data: any) => {
      setRow(data)
    },
    [setRow]
  )

  const ids = isRelational(rawIds)
    ? relationGetIds(rawIds)
    : (rawIds as string[])

  const app = useAppRoute()

  const { data: paginatedData, isLoading } = useCollectionData(
    app,
    model.endpoint,
    {
      ids,
      airtableBase: viewData.airtableBase,
    }
  )

  const data = flattenPaginatedData(paginatedData?.pages)?.results

  const filteredData = useMemo(
    () => (filterData ? filterData(data) : data),
    [filterData, data]
  )

  const formattedData = useMemo(
    () =>
      filteredData
        ? sortOrder
          ? SortData(filteredData, sortOrder)
          : filteredData
        : [],
    [filteredData, sortOrder]
  )

  useEffect(() => {
    content.setContent((x: DrawerContentContextContentType) => ({
      ...x,
      [id || 0]: formattedData.length,
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formattedData.length])

  if (!isLoading && formattedData.length === 0) {
    return <Empty />
  }

  return (
    <Box
      width='100%'
      height='100%'
      className={model.noPrint === true ? 'no-print' : ''}
    >
      {selectedRow &&
        (type === 'PressReleases' ? (
          <ArticleModal
            isOpen={true}
            title={selectedRow.title}
            link={selectedRow.sourceUrl ?? selectedRow.link}
            handleClose={() => handleSelectRow(null)}
            abstract={selectedRow.description}
            noPrint={model.noPrint}
          />
        ) : type === 'Papers' ? (
          <ArticleModal
            isOpen={true}
            title={selectedRow.title}
            link={selectedRow.articleLink}
            handleClose={() => handleSelectRow(null)}
            abstract={selectedRow.abstract}
            noPrint={model.noPrint}
          />
        ) : type === 'Media' ? (
          <ArticleModal
            isOpen={true}
            title={selectedRow.title}
            link={selectedRow.link}
            handleClose={() => handleSelectRow(null)}
            abstract={selectedRow.description}
            noPrint={model.noPrint}
          />
        ) : (
          <GenericDrawer
            entry={selectedRow}
            isLoading={isLoading}
            title={model.displayKey}
            width='80%'
            model={model}
            viewData={viewData}
            handleClose={() => handleSelectRow(null)}
          />
        ))}

      {isLoading === true || !data ? (
        _.times(25, (i) => {
          return (
            <Box key={i} p={6} borderBottom='1px solid' borderColor='gray.100'>
              <Skeleton width={randomIntFromInterval(85, 95) + '%'} />
            </Box>
          )
        })
      ) : (
        <AutoSizer>
          {({ width, height }: any) => (
            <Virtuoso
              style={{ width, height }}
              totalCount={formattedData?.length}
              overscan={800}
              itemContent={(index: number) =>
                React.createElement(React.memo(itemComponent), {
                  key: index,
                  item: formattedData[index],
                  handleSelect: handleSelectRow,
                })
              }
            />
          )}
        </AutoSizer>
      )}
    </Box>
  )
}
