import { TableauEmbed } from 'components'

import { ISingleCustomPage } from 'interfaces/navigationPage.interface'

import { ObesityEpidemiologySlugs } from './ObesityEpidemiologyMenu'

const Dashboard: ISingleCustomPage<ObesityEpidemiologySlugs> = {
  path: [''],
  component: () => (
    <TableauEmbed path='/FrontendObesity-PopulationbreakdownbyBMI/Dashboard1' />
  ),
}

const ObesityRelatedDiseaseBurden: ISingleCustomPage<ObesityEpidemiologySlugs> =
  {
    path: ['obesity-related-disease-burden'],
    component: () => (
      <TableauEmbed path='/ObesityEpid/Obesity-relateddiseaseburden2' />
    ),
  }

export const ObesityEpidemiologyCustomPages: Record<
  string,
  ISingleCustomPage<ObesityEpidemiologySlugs>
> = {
  Dashboard,
  ObesityRelatedDiseaseBurden,
}
