import { Center, Text } from '@chakra-ui/react'
import { Box, Button } from '@chakra-ui/react'
import { useState } from 'react'
// @ts-ignore
import Clamp from 'react-multiline-clamp'

import { CleanResponseData } from 'api/types'

type PropTypes = {
  value: CleanResponseData
  maxLines?: number
  wrap?: boolean
  formatForOncology?: boolean
}

const Long = (props: PropTypes) => {
  const { value, maxLines, wrap } = props
  return (
    <Box
      overflow='hidden'
      lineHeight='1.7'
      p={1}
      whiteSpace='break-spaces'
      bg='gray.100'
      rounded='md'
    >
      {!wrap && (
        <Clamp lines={maxLines ?? 3}>
          {typeof value === 'string' ? (
            value.split('<br>').map((p, i) => (
              <Text fontSize='12px' key={i} display='inline'>
                {p}
              </Text>
            ))
          ) : (
            <Text fontSize='12px'>{value}</Text>
          )}
        </Clamp>
      )}
      {wrap && <WrappedLongView {...props} />}
    </Box>
  )
}

const WrappedLongView = ({ value, maxLines, formatForOncology }: PropTypes) => {
  const [isShowMore, setIsShowMore] = useState(false)

  if (typeof value !== 'string') {
    return (
      <Text fontSize='12px' whiteSpace='pre-wrap' p={4}>
        {value}
      </Text>
    )
  }

  const splittedValue = value.replace(/<br ?\/?>/g, '\n').split('\n')
  const canShowMore = splittedValue.length > (maxLines ?? 3)
  const numToSliceTo = !canShowMore || isShowMore ? undefined : maxLines ?? 3

  return (
    <Box p={4}>
      {formatForOncology
        ? value
            .replace(/<br ?\/?>/g, '\n')
            .split('\n')
            .slice(0, numToSliceTo)
            .map((p, i) => (
              <Text fontSize='12px' key={i} py={2} as='p'>
                {p}
              </Text>
            ))
        : value.split('\n').slice(0, numToSliceTo).join('\n')}
      <Center>
        {canShowMore && (
          <Button
            onClick={() => setIsShowMore((x) => !x)}
            size='xs'
            borderColor='blue.500'
            bg='white'
            color='blue.800'
          >
            Show {isShowMore ? 'Less' : 'More'}
          </Button>
        )}
      </Center>
    </Box>
  )
}

export default Long
