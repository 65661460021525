import { AuthContextProps } from 'react-oidc-context'

import { getGovConfig } from 'contexts/ClientType'

import config from '../config/oidc'

export const Logout = (auth: AuthContextProps, isGov: boolean) => {
  if (auth.isAuthenticated) {
    if (isGov) {
      const configInUse = isGov ? getGovConfig() : config.config
      const extraQueryParams = {
        logout_uri: window.location.origin + '/',
        client_id: configInUse?.client_id || '',
      }
      auth.signoutRedirect({ extraQueryParams })
    } else {
      auth.signoutRedirect()
    }
  } else {
    window.location.replace('/')
  }
}
