import { Box } from '@chakra-ui/react'

import { Select } from 'components'

const Filter = ({
  onChange,
  options,
  values,
  placeholder,
  isMulti = true,
  large = false,
  zIndex = 100,
}: {
  onChange: any
  options: string[]
  values?: { value: string; label: string }[] | { value: string; label: string }
  placeholder: string
  isMulti?: boolean
  large?: boolean
  zIndex?: number
}) => {
  return (
    <Box zIndex={zIndex} w={large ? '100%' : '60%'}>
      <Select
        value={values}
        isMulti={isMulti}
        onChange={onChange}
        options={options.map((option) => ({ label: option, value: option }))}
        placeholder={placeholder}
        borderColor='gray3'
        color='gray3'
      />
    </Box>
  )
}

export default Filter
