import { IModel } from 'interfaces/model.interface'
import { ValueType } from 'interfaces/valueType.interface'

import { SortOrders } from 'enums/SortOrders'

export type PersonData =
  | 'afName'
  | 'afHIndex'
  | 'afHIndexSourceUrl'
  | 'affiliations'
  | 'afInvestigatorOrCommitteeMemberOfClinicalTrial'
  | 'conferencePresentations'
  | 'leadAuthorOfPublication'
  | 'lastAuthorOfPublication'
  | 'tweets'
  | 'kolPicture'
  | 'luCandidateName'
  | 'lookupTrialFromInvestigators'
  | 'lookupFullTextFromTweets'
  | 'lookupTitleFromConferencePresentations2'
  | 'clinicalTrialsSponsor'
  | 'country'
  | 'afScreenName'
  | 'tweetFrequency'
  | 'afSpecialtyFromAffiliation'
  | 'kolRankingScore'

const allowedAirtables = [
  'api_igm',
  'api_multiple_myeloma',
  'api_multiple_myeloma_360',
  'api_kols_lipid_360',
  'api_KOLs_AT_360',
  '[API] KOLs - Vaccines',
  '[API] KOLs - Treatments',
  '[API] KOLs - Influenza',
  '[API] KOLs - RSV',
  'KOLs_Obesity_Ranking_Platform',
] as const

export const PersonModel: IModel<
  Record<PersonData, string>,
  typeof allowedAirtables
> = {
  allowedAirtables,
  endpoint: 'person',
  name: 'KOLs',
  entityName: 'KOLs',
  detailViewType: 'Generic',
  searchField: 'afName',
  displayKey: 'afName',
  defaultSortKey: 'afHIndex',
  defaultSortOrder: SortOrders.DESC,
  schema: {
    columns: [
      { key: 'afName', label: 'Name', width: 150, type: ValueType.TEXT },
      { key: 'afHIndex', label: 'H Index', width: 120, type: ValueType.NUMBER },
      {
        key: 'afHIndexSourceUrl',
        label: 'H Index Source Url',
        width: 150,
        type: ValueType.URL,
      },
      {
        key: 'affiliations',
        label: 'Affiliations',
        width: 250,
        type: ValueType.TEXT,
      },
      {
        key: 'afSpecialtyFromAffiliation',
        label: 'Affiliations',
        width: 250,
        type: ValueType.MULTI,
      },
      {
        key: 'afInvestigatorOrCommitteeMemberOfClinicalTrial',
        label: 'Investigator Or Committee Member Of Clinical Trial',
        width: 250,
        type: ValueType.MULTI,
      },
      {
        key: 'conferencePresentations',
        label: 'Conference Presentations',
        width: 200,
        type: ValueType.MULTI,
      },
      {
        key: 'leadAuthorOfPublication',
        label: 'Lead Author Of Publication',
        width: 200,
        type: ValueType.MULTI,
      },
      {
        key: 'lastAuthorOfPublication',
        label: 'Last Author Of Publication',
        width: 200,
        type: ValueType.MULTI,
      },
      { key: 'tweets', label: 'Tweets', width: 150, type: ValueType.MULTI },
      {
        key: 'luCandidateName',
        label: 'Product',
        width: 150,
        type: ValueType.MULTI,
      },
      {
        key: 'lookupTrialFromInvestigators',
        type: ValueType.MULTI,
        label: 'Affiliated Trial Name',
        width: 250,
      },
      {
        key: 'lookupFullTextFromTweets',
        type: ValueType.MULTI,
        label: 'Tweets',
        width: 250,
      },
      {
        key: 'lookupTitleFromConferencePresentations2',
        label: 'Conference Presentations',
        width: 200,
        type: ValueType.MULTI,
      },
      {
        key: 'clinicalTrialsSponsor',
        label: 'Clinical Trials Sponsor',
        width: 200,
        type: ValueType.MULTI,
      },
      {
        key: 'country',
        label: 'Country of Affiliation',
        width: 180,
        type: ValueType.MULTI,
      },
      {
        key: 'afScreenName',
        label: 'Handle',
        width: 180,
        type: ValueType.TEXT,
      },
      {
        key: 'tweetFrequency',
        label: 'Tweet Frequency',
        width: 180,
        type: ValueType.TEXT,
      },
      {
        key: 'kolRankingScore',
        label: 'Airfinity KOL Score',
        width: 180,
        type: ValueType.NUMBER,
      },
    ],
  },
}
