import { Box, Flex, Text } from '@chakra-ui/react'

import ReportPage from 'routes/common/FullReportPage'
import Dashboard from 'routes/dashboard'

import { TableauEmbed } from 'components'
import { NoFile } from 'components/EmptyScreen/NoFile'

import { ISingleCustomPage } from 'interfaces/navigationPage.interface'

import { getMediaDefaultWidget } from 'config/common/baseModel/Media'
import { getPressReleasesDefaultWidget } from 'config/common/baseModel/PressReleases'

import { ObesitySlugs } from './ObesityMenu'
import { ObesityPages } from './ObesityPages'

const ReportEmptyScreen = () => {
  return (
    <Box w={'100%'} maxW={'100%'}>
      <Flex
        flexDirection={'column'}
        justifyContent='center'
        alignItems={'center'}
        p='1rem'
        gap='1rem'
        mt='2rem'
      >
        <Text fontSize='xl' fontWeight={'semibold'}>
          New report coming soon
        </Text>
        <Box transform='scale(0.7)'>
          <NoFile />
        </Box>
      </Flex>
    </Box>
  )
}

const DashboardPage: ISingleCustomPage<ObesitySlugs> = {
  path: '',
  component: () => (
    <Dashboard
      widgetTabs={[
        getMediaDefaultWidget({
          view: ObesityPages['Media'].views[0],
        }),
        getPressReleasesDefaultWidget({
          view: ObesityPages['PressReleases'].views[0],
        }),
      ]}
      EmptyScreen={() => <ReportEmptyScreen />}
    />
  ),
}

const Reports: ISingleCustomPage<ObesitySlugs> = {
  path: 'reports',
  component: () => <ReportPage EmptyScreen={() => <ReportEmptyScreen />} />,
}

const CandidatesPipeline: ISingleCustomPage<ObesitySlugs> = {
  path: ['candidates', 'overview'],
  component: () => (
    <TableauEmbed path='/FrontendObesityPipeline/Candidateoverview_1' />
  ),
}

const TimelineDashboard: ISingleCustomPage<ObesitySlugs> = {
  path: ['trials', 'timeline'],
  component: () => (
    <TableauEmbed path='/FrontendObesityTrials/Trialsreachingcompletion_1' />
  ),
}
const ProgressTracker: ISingleCustomPage<ObesitySlugs> = {
  path: ['candidates', 'progress-tracker'],
  component: () => <TableauEmbed path='/ObesityCPT/OverallProgressbyPhase' />,
}
const ApprovalsForecast: ISingleCustomPage<ObesitySlugs> = {
  path: ['trials', 'approvals-forecast'],
  component: () => (
    <TableauEmbed path='/Obesitydrugsapprovalspredictor/Dashboard1' />
  ),
}
const EfficacyHeatMap: ISingleCustomPage<ObesitySlugs> = {
  path: ['results', 'efficacy-heat-map'],
  component: () => (
    <TableauEmbed path='/ObesityefficacyheatmapT2DandNon-T2Dsplit/Efficacyheatmap' />
  ),
}
const SafetyHeatMap: ISingleCustomPage<ObesitySlugs> = {
  path: ['results', 'safety-heat-map'],
  component: () => (
    <TableauEmbed path='/Obesitydrugssafetyheatmap/Non-T2Ddashboard' />
  ),
}
const SocialMediaCandidateMentions: ISingleCustomPage<ObesitySlugs> = {
  path: ['social-media', 'candidate-mentions'],
  component: () => (
    <TableauEmbed path='/FrontendObesityPipeline/Socialmediamentions_Past2Weeks' />
  ),
}

export const ObesityCustomPages: Record<
  string,
  ISingleCustomPage<ObesitySlugs>
> = {
  DashboardPage,
  Reports,
  CandidatesPipeline,
  TimelineDashboard,
  ProgressTracker,
  ApprovalsForecast,
  EfficacyHeatMap,
  SocialMediaCandidateMentions,
  SafetyHeatMap,
}
