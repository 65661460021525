export default {
  '28 days': 'rgb(207, 223, 255)',
  '8-12 weeks': 'rgb(212, 239, 252)',
  '12 weeks': 'rgb(201,244,233)',
  '3-4 weeks': 'rgb(214,247,198)',
  '10 weeks and over': 'rgb(253,236,185)',
  'at least 8 weeks after': 'rgb(251,221,229)',
  '4 weeks and over': 'rgb(249, 157, 227)',
  '365 days': 'rgb(208, 240, 253)',
  'Single dose': 'rgb(186, 30, 69)',
  '60 days': 'rgb(255, 214, 110)',
  '12-18 months': 'rgb(32, 201, 51)',
}
