import { useContext } from 'react'

import { AuthContext } from 'contexts'

import { appsListUnion } from 'config/apps'

export const appletList = [
  'covid-science360',
  'covid-commercial',
  'epidemiology',
  'influenza-science360',
  'influenza-commercial',
  'influenza-epidemiology',
  'rsv-science360',
  'rsv-epidemiology',
  'rsv-commercial',
  'covid-epid-data',
  'cardio-science360',
  'health-security',
  'diagnostics-science360',
  'booster-demand-forecast',
  'conference-data',
  'ieg-policy-surveillance',
  'research-power-index',
  'internal-tooling',
  'pad',
  'biorisk',
  'moderna-commercial',
  'moderna-epidemiology',
  'moderna-science360',
  'rna',
  'pnh',
  'ida-360',
  'obesity',
  'obesity-commercial',
  'obesity-epidemiology',
  'obesity-predictive-analytics',
] as const
export type appletListUnion = (typeof appletList)[number]

export type AppletConfig = {
  key: appletListUnion
  name: string
  apps: appsListUnion[]
}

export const applets: Record<appletListUnion, AppletConfig> = {
  /* -------------------------------------------------------------------------- */
  /*                                  COVID-19                                  */
  /* -------------------------------------------------------------------------- */
  'covid-science360': {
    key: 'covid-science360',
    name: 'Science360',
    apps: ['covid-19-vaccines', 'covid-19-therapeutics'],
  },
  'covid-commercial': {
    key: 'covid-commercial',
    name: 'Commercial',
    apps: ['covid-19-commercial-vaccines', 'covid-19-commercial-therapeutics'],
  },
  epidemiology: {
    key: 'epidemiology',
    name: 'Epidemiology',
    apps: ['epidemiology'],
  },
  /* -------------------------------------------------------------------------- */
  /*                                  INFLUENZA                                 */
  /* -------------------------------------------------------------------------- */
  'influenza-science360': {
    key: 'influenza-science360',
    name: 'Science360',
    apps: ['influenza'],
  },
  'influenza-commercial': {
    key: 'influenza-commercial',
    name: 'Commercial',
    apps: ['influenza-commercial'],
  },
  'influenza-epidemiology': {
    key: 'influenza-epidemiology',
    name: 'Epidemiology',
    apps: ['influenza-epidemiology'],
  },
  /* -------------------------------------------------------------------------- */
  /*                                     RSV                                    */
  /* -------------------------------------------------------------------------- */
  'rsv-science360': {
    key: 'rsv-science360',
    name: 'Science360',
    apps: ['rsv'],
  },
  'rsv-epidemiology': {
    key: 'rsv-epidemiology',
    name: 'Epidemiology',
    apps: ['rsv-epidemiology'],
  },
  'rsv-commercial': {
    key: 'rsv-commercial',
    name: 'Commercial',
    apps: ['rsv-commercial'],
  },
  /* -------------------------------------------------------------------------- */
  /*                                COVID-19 Epid                               */
  /* -------------------------------------------------------------------------- */
  'covid-epid-data': {
    key: 'covid-epid-data',
    name: 'EPID Plus',
    apps: ['covid-epid-data'],
  },
  /* -------------------------------------------------------------------------- */
  /*                                   Cardio                                   */
  /* -------------------------------------------------------------------------- */
  'cardio-science360': {
    key: 'cardio-science360',
    name: 'Science360',
    apps: ['cardio-lipid', 'cardio-antithrombotics'],
  },
  /* -------------------------------------------------------------------------- */
  /*                               Health Security                              */
  /* -------------------------------------------------------------------------- */
  'health-security': {
    key: 'health-security',
    name: 'Health Security',
    apps: ['health-security', 'health-security-premium'],
  },
  /* -------------------------------------------------------------------------- */
  /*                                 Diagnostics                                */
  /* -------------------------------------------------------------------------- */
  'diagnostics-science360': {
    key: 'diagnostics-science360',
    name: 'Science360',
    apps: ['covid-diagnostics'],
  },
  /* -------------------------------------------------------------------------- */
  /*                                Moderna                                     */
  /* -------------------------------------------------------------------------- */
  'moderna-commercial': {
    key: 'moderna-commercial',
    name: 'Commercial',
    apps: [
      'moderna-covid-19-commercial',
      'moderna-influenza-commercial',
      'moderna-rsv-commercial',
    ],
  },
  'moderna-epidemiology': {
    key: 'moderna-epidemiology',
    name: 'Epidemiology',
    apps: [
      'moderna-covid-19-epidemiology',
      'moderna-influenza-epidemiology',
      'moderna-rsv-epidemiology',
    ],
  },
  'moderna-science360': {
    key: 'moderna-science360',
    name: 'Science 360',
    apps: [
      'moderna-covid-19-science360',
      'moderna-influenza-science360',
      'moderna-rsv-science360',
    ],
  },
  /* -------------------------------------------------------------------------- */
  /*                    GSK COVID-19 Booster Demand Forecast                    */
  /* -------------------------------------------------------------------------- */
  'booster-demand-forecast': {
    key: 'booster-demand-forecast',
    name: 'Booster Demand Forecast',
    apps: ['booster-demand-forecast'],
  },
  /* -------------------------------------------------------------------------- */
  /*                          Cardio Conference Reports                         */
  /* -------------------------------------------------------------------------- */
  'conference-data': {
    key: 'conference-data',
    name: 'Conference Reports',
    apps: ['conference-data'],
  },
  /* -------------------------------------------------------------------------- */
  /*                          IEG Policy Surveillance                           */
  /* -------------------------------------------------------------------------- */
  'ieg-policy-surveillance': {
    key: 'ieg-policy-surveillance',
    name: 'IEG Policy Surveillance',
    apps: ['ieg-policy-surveillance'],
  },
  /* -------------------------------------------------------------------------- */
  /*                            Research Power Index                            */
  /* -------------------------------------------------------------------------- */
  'research-power-index': {
    key: 'research-power-index',
    name: 'Research Power Index',
    apps: ['research-power-index'],
  },
  /* -------------------------------------------------------------------------- */
  /*                                     Pad                                    */
  /* -------------------------------------------------------------------------- */
  pad: {
    key: 'pad',
    name: 'Pandemic Antiviral Discovery',
    apps: ['pad'],
  },
  /*                             Pandemic Preparedness                          */
  /* -------------------------------------------------------------------------- */
  biorisk: {
    key: 'biorisk',
    name: 'Biorisk',
    apps: ['biorisk'],
  },
  /* -------------------------------------------------------------------------- */
  /*                                  Internal                                  */
  /* -------------------------------------------------------------------------- */
  'internal-tooling': {
    key: 'internal-tooling',
    name: 'Internal Tooling',
    apps: ['internal-tooling'],
  },
  /* -------------------------------------------------------------------------- */
  /*                                    RNA                                     */
  /* -------------------------------------------------------------------------- */
  rna: {
    key: 'rna',
    name: 'RNA',
    apps: ['rna'],
  },
  /* -------------------------------------------------------------------------- */
  /*                                    RNA                                     */
  /* -------------------------------------------------------------------------- */
  pnh: {
    key: 'pnh',
    name: 'PNH',
    apps: ['pnh'],
  },
  /* -------------------------------------------------------------------------- */
  /*                                  IDA - Infectious Disease Analytics                                   */
  /* -------------------------------------------------------------------------- */
  'ida-360': {
    key: 'ida-360',
    name: 'IDA - Infectious Disease Analytics',
    apps: ['ida-360'],
  },
  /* -------------------------------------------------------------------------- */
  /*                                  Obesity                                   */
  /* -------------------------------------------------------------------------- */
  obesity: {
    key: 'obesity',
    name: 'Science360',
    apps: ['obesity-360'],
  },
  'obesity-commercial': {
    key: 'obesity-commercial',
    name: 'Commercial',
    apps: ['obesity-commercial'],
  },
  'obesity-epidemiology': {
    key: 'obesity-epidemiology',
    name: 'Epidemiology',
    apps: ['obesity-epidemiology'],
  },
  'obesity-predictive-analytics': {
    key: 'obesity-predictive-analytics',
    name: 'Predictive Analytics',
    apps: ['obesity-predictive-analytics'],
  },
}

// Theoretically, it is possible to have an app in multiple applets. In this case, we always get the first one
export const useAppletFromApp = (appSlug: appsListUnion) => {
  const { appletsUserCanSee } = useContext(AuthContext)

  return Object.values(appletsUserCanSee).find((applet) =>
    applet.apps.includes(appSlug)
  )
}
