import {
  CaretDown,
  CaretUp,
  Launch,
  Locked,
  ArrowUpRight,
  Calendar,
} from '@carbon/icons-react'
import {
  Box,
  Button,
  Flex,
  Icon,
  Image,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Text,
  Tooltip,
  Link,
  useDisclosure,
  HStack,
} from '@chakra-ui/react'
import { useEffect } from 'react'
import ReactMarkdown from 'react-markdown'
import { Link as ReactRouterLink } from 'react-router-dom'
import rehypeRaw from 'rehype-raw'

import { ViewPdfReport } from 'components'
import { usePdfData } from 'components/PdfDownload/usePdfData'

import { BriefsT } from 'api/cms/briefs/types'

import useTracking from 'tracking/useTracking'

import { appsListUnion } from 'config/apps'

import { formatUtc } from 'utils/formatDate'

import { areasMapping } from './Helpers'

const Pill = ({ children, tip }: any) => {
  return (
    <Tooltip label={tip}>
      <Box bg='gray.200' alignItems={'center'} px='8px' borderRadius={'xl'}>
        <Text fontSize='10px' fontWeight={'bold'}>
          {children}
        </Text>
      </Box>
    </Tooltip>
  )
}

function haveCommonElements(array1: string[], array2: string[]) {
  for (let i = 0; i < array1.length; i++) {
    if (array2.includes(array1[i])) {
      return true
    }
  }
  return false
}

const AppUpsell = ({ children }: any) => {
  return (
    <Popover placement='right' closeOnBlur>
      <PopoverTrigger>{children}</PopoverTrigger>
      <Portal>
        <PopoverContent>
          <Box bg='white' fontSize='sm' fontWeight={400}>
            <Box bg='gray.100' px={4} py={2} fontWeight={500} display='flex'>
              <Locked size={16} /> This App is locked
            </Box>
            <Box px={4} py={3} fontSize='sm'>
              Your Plan does not include access to this App, please contact{' '}
              <Link
                href='mailto:support@airfinity.com'
                color='legacy-primary.500'
              >
                support@airfinity.com
              </Link>{' '}
              to unlock this App.
            </Box>
          </Box>
        </PopoverContent>
      </Portal>
    </Popover>
  )
}

const ResearchBriefsNavigation = ({
  brief,
  appListUserCanAccess,
}: {
  brief: BriefsT
  appListUserCanAccess: appsListUnion[]
}) => {
  const [tracking] = useTracking()

  const hasAccess = haveCommonElements(appListUserCanAccess, brief.apps)

  const TextContent = (
    <HStack alignItems={'center'} justifyContent='flex-end'>
      <Text variant='body2-bold'>Read Similar Briefs</Text>
      <Icon w='16px' as={ArrowUpRight} />
    </HStack>
  )
  if (hasAccess) {
    return (
      <Link
        to={`/${brief.apps[0]}/reports/research-briefs`}
        as={ReactRouterLink}
        onClick={(e) => {
          e.stopPropagation()
          tracking.readSimilarBrief({
            ...brief,
          })
        }}
      >
        {TextContent}
      </Link>
    )
  } else {
    return (
      <AppUpsell>
        <Box onClick={(e) => e.stopPropagation()}>{TextContent}</Box>
      </AppUpsell>
    )
  }
}
const AppNavigation = ({
  brief,
  index,
  app,
  appListUserCanAccess,
}: {
  brief: BriefsT
  index: number
  app: string
  appListUserCanAccess: appsListUnion[]
}) => {
  const [tracking] = useTracking()

  const hasAccess = haveCommonElements(appListUserCanAccess, brief.apps)

  const TextContent = (
    <Box maxW={'150px'} minW={'150px'}>
      <Text
        fontWeight={'semibold'}
        color='legacy-primary.500'
        fontSize={'12px'}
        px='3px'
        py='2px'
        borderRadius={'lg'}
        textAlign='center'
        key={index}
        onClick={(e) => !hasAccess && e.stopPropagation()}
      >
        {app}
      </Text>
    </Box>
  )
  if (hasAccess) {
    return (
      <Link
        to={`/${brief.apps[index]}/`}
        onClick={(e) => {
          e.stopPropagation()
          tracking.navigateToResearchBriefFromList({
            app: brief.apps[index],
            ...brief,
          })
        }}
        as={ReactRouterLink}
      >
        {TextContent}
      </Link>
    )
  } else {
    return <AppUpsell>{TextContent}</AppUpsell>
  }
}

const BriefCard = ({
  brief,
  appListUserCanAccess,
}: {
  brief: BriefsT
  appListUserCanAccess: appsListUnion[]
}) => {
  const [tracking] = useTracking()

  const { isOpen, onToggle } = useDisclosure()
  const { title, description, datePublished, pdf } = brief

  const date = new Date(datePublished)

  const { refetch: loadPdf } = usePdfData(pdf ? pdf : '')

  useEffect(() => {
    isOpen && loadPdf()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  return (
    <Box
      border='1px solid'
      rounded={'4px'}
      borderColor='gray.200'
      display='flex'
      mb='0.75rem'
      overflow={'hidden'}
      pos='relative'
      cursor='pointer'
      onClick={() => {
        if (!isOpen)
          tracking.toggleResearchBriefOpen({
            ...brief,
          })
        onToggle()
      }}
      minH='60px'
    >
      <Flex
        color={`black`}
        bg={`yellow.400`}
        alignItems={'center'}
        minWidth='fit-content'
        justifyContent={'center'}
        flexDir={'column'}
        py='1rem'
        w={'100px'}
      >
        {brief.appsNames.map((app, index) => (
          <Flex
            key={`${index}-${app}`}
            flexDir='column'
            justifyContent={'center'}
            alignItems='center'
          >
            {index > 0 && index !== brief.apps.length && (
              <Text
                color='legacy-primary.500'
                fontSize={'12px'}
                whiteSpace='pre-wrap'
              >
                and
              </Text>
            )}
            <AppNavigation
              app={app}
              index={index}
              brief={brief}
              appListUserCanAccess={appListUserCanAccess}
            />
          </Flex>
        ))}
      </Flex>
      <Flex
        mx='0.5rem'
        mr='1rem'
        pt='0.5rem'
        pb='1rem'
        justifyContent={'center'}
        flexDir='column'
      >
        <Flex flexWrap={'wrap'} ml={6} my={2} gap='0.5rem'>
          <Pill tip={`Area of Interest: ${areasMapping[brief.areaOfInterest]}`}>
            {areasMapping[brief.areaOfInterest]}
          </Pill>
          {brief.areasOfContentType.map((content, index) => (
            <Pill key={index} tip={`Area of Content: ${content}`}>
              {content}
            </Pill>
          ))}
        </Flex>
        <Text variant='body-bold' color='primary' ml='1.5rem'>
          {title}
        </Text>
        <Flex ml={6} gap={2} mt={1} alignItems={'center'}>
          <Calendar />
          <Text variant='body3-bold'>
            Added on {formatUtc(date, 'dd MMM yyyy')}
          </Text>
        </Flex>
        {isOpen && (
          <>
            <Box p='1rem' ml='0.5rem' fontSize='14px'>
              <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                {description}
              </ReactMarkdown>
            </Box>
            <Image src={brief.previewImage} px='1rem' />
            <Box width='100px' mx='auto'>
              <ViewPdfReport
                triggerComponent={({ handleClick }) => (
                  <Button
                    variant={'yellow'}
                    mt='1rem'
                    onClick={(e) => {
                      tracking.readBriefFromList({
                        ...brief,
                      })
                      e.stopPropagation()
                      return handleClick()
                    }}
                    alignItems='center'
                    justifyContent={'center'}
                    gap={2}
                    display='flex'
                  >
                    <Text variant='body2-bold'>Read Brief</Text>
                    <Box height='12px' textAlign='center' borderRadius='full'>
                      <Icon w='12px' as={Launch} />
                    </Box>
                  </Button>
                )}
                url={brief.pdf}
              />
            </Box>
            <ResearchBriefsNavigation
              brief={brief}
              appListUserCanAccess={appListUserCanAccess}
            />
          </>
        )}
      </Flex>
      <Box pos='absolute' bottom='0px' right={'4px'} id='dropdown'>
        <Icon as={isOpen ? CaretUp : CaretDown} color={'legacy-primary.400'} />
      </Box>
    </Box>
  )
}

export default BriefCard
