import { Flex } from '@chakra-ui/react'
import { useContext } from 'react'

import PrimaryLink from '../PrimaryLink'
import { ExpandableContext } from './ExpandableContainer'

type PropTypes = {
  icon?: React.ReactNode
  children?: React.ReactNode
  isLast?: boolean
}

export default function DropdownToggle({ icon, children, isLast }: PropTypes) {
  const { toggle, isOpen } = useContext(ExpandableContext)

  return (
    <Flex
      onClick={toggle}
      overflowWrap='break-word'
      alignItems={'center'}
      data-cy='sidebar-navigation-expandable'
    >
      {!isOpen && icon}
      <PrimaryLink hasIcon={Boolean(icon)} isLast={isLast} isDropdown={true}>
        {children}
      </PrimaryLink>
    </Flex>
  )
}
