import { Heading, Box } from '@chakra-ui/react'
import Truncate from 'react-truncate'

import { MediaData } from 'config/common/baseModel'

import { formatDate } from 'utils/formatDate'

import { ItemComponentPropTypes } from '.'
import ArticleFooter from './components/ArticleFooter'

const DetailDrawerListMedia = ({
  item,
  handleSelect,
}: ItemComponentPropTypes<Record<MediaData, any>>) => {
  const { title, datePublished, description, link, source, author } = item

  const publishedBy = source ?? author

  return (
    <Box
      px={8}
      py={4}
      borderBottom='1px solid'
      borderColor='gray.100'
      cursor='pointer'
      onClick={() => handleSelect(item)}
      _hover={{
        bg: 'gray.100',
      }}
    >
      <Box
        mb={1}
        display='flex'
        alignItems='center'
        fontSize='xs'
        fontWeight={600}
        textTransform='uppercase'
        color='gray.700'
      >
        <Box mr={2}>{formatDate(datePublished)}</Box>
      </Box>
      <Heading fontSize='md' mb={2} fontWeight={500}>
        {title}
      </Heading>
      {!!publishedBy && (
        <Box mb={1} display='flex'>
          <Box mr={2} color='gray.700' fontSize='sm' fontWeight={600}>
            Published by {publishedBy}
          </Box>
        </Box>
      )}
      <Box mb={4} color='gray.800' fontSize='sm'>
        <Truncate lines={3} ellipsis={<span>...</span>} trimWhitespace={true}>
          {description}
        </Truncate>
      </Box>
      <ArticleFooter externalLink={link as string} />
    </Box>
  )
}

export { DetailDrawerListMedia }
