import { INavigationMenu } from 'interfaces/navigationMenu.interface'

export type CovidEpidDataSlugs = '' | 'covid-epid-data'

const CovidEpidDataMenu: Array<INavigationMenu<CovidEpidDataSlugs>[]> = [
  [
    {
      title: 'Dashboard',
      slug: '',
      description:
        'COVID country-level epidemiology data broken down by age group',
      createdAt: new Date(2021, 8, 30),
    },
  ],
]

export { CovidEpidDataMenu }
