import { UseDisclosureReturn, useDisclosure } from '@chakra-ui/react'
import { createContext, useContext } from 'react'

interface FilterModalContextType {
  disclosure: UseDisclosureReturn
}

export const FilterModalContext = createContext<
  FilterModalContextType | undefined
>(undefined)

export const useFilterModal = (): FilterModalContextType => {
  const context = useContext(FilterModalContext)
  if (!context) {
    throw new Error(
      'useDisclosure Context must be used within a FilterModalProvider'
    )
  }
  return context
}

export const FilterModalConsumer = FilterModalContext.Consumer

export const FilterModalProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const disclosure: UseDisclosureReturn = useDisclosure()

  return (
    <FilterModalContext.Provider
      value={{
        disclosure,
      }}
    >
      {children}
    </FilterModalContext.Provider>
  )
}
