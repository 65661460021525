import { Box, Button, Divider, Flex, Heading, VStack } from '@chakra-ui/react'
import { useState } from 'react'
import { Link } from 'react-router-dom'

import { useGlobalSearch } from 'contexts/GlobalSearch'

import { MenuWithMetaData } from 'interfaces/navigationMenu.interface'

import NewPageMiniCard from './NewPage/NewPageMiniCard'

type PropTypes = {
  newPageData: MenuWithMetaData[]
}

const defaultShown = 6

export default function NewPage({ newPageData }: PropTypes) {
  const [totalShown, setTotalShown] = useState(defaultShown)
  const { setOverlayShown } = useGlobalSearch()

  if (newPageData.length === 0) {
    return null
  }

  return (
    <Box mb={8}>
      <Box display='flex' alignItems='center' mb='8px' pl='40px'>
        <Heading fontSize='18px' fontWeight={600} color='primary'>
          Latest on Airfinity
        </Heading>
      </Box>
      <VStack w='100%' spacing='0' px='20px'>
        {newPageData
          .slice(0, totalShown)
          .map((menu, i) => {
            return (
              <Box
                key={i}
                w='100%'
                py='16px'
                pl='20px'
                _hover={{
                  bg: 'gray.50',
                }}
                onClick={() => setOverlayShown(false)}
              >
                <Link to={menu.fullPath}>
                  <NewPageMiniCard menuWithMetaData={menu} />
                </Link>
              </Box>
            )
          })
          .map((x, i, all) => [
            x,
            i !== all.length - 1 ? <Divider key={`d_${i}`} /> : null,
          ])
          .flat()}
      </VStack>
      {newPageData.length > totalShown && (
        <Flex justifyContent='flex-end' pl='40px'>
          <Button
            variant='link'
            onClick={() => setTotalShown((x) => x + defaultShown)}
          >
            View more...
          </Button>
        </Flex>
      )}
    </Box>
  )
}
