export default {
  'Priority 2': 'rgb(139, 70, 255)',
  'Priority 1': 'rgb(32, 201, 51)',
  Rejected: 'rgb(248, 43, 96)',
  'Priority 3': 'rgb(205, 176, 255)',
  'Phase III': 'rgb(207, 223, 255)',
  Preclinical: 'rgb(255, 234, 182)',
  'Repurposed vaccines': 'rgb(249, 157, 226)',
  'UK portfolio': 'rgb(248, 43, 96)',
  'Phase I or II': 'rgb(32, 217, 210)',
  Abandoned: 'rgb(248, 43, 96)',
  Discontinued: 'rgb(248, 43, 96)',
  'Phase IV': 'rgb(184, 117, 3)',
  Approved: 'rgb(114, 221, 195)',
}
