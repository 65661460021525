import GenericTable from 'modules/Tables'

import { IModel } from 'interfaces/model.interface'
import {
  INavigationPages,
  ISingleNavigationPage,
} from 'interfaces/navigationPage.interface'

import { VaccinesDealsAndDonationsDealsModel } from 'config/apps/Covid19/Science360/Vaccines/VaccinesModels'

import { CovidBoosterMarketForecastSlugs } from './CovidBoosterMarketForecastMenu'

type CovidBoosterMarketNavigationPage<TModel extends IModel<any>> =
  ISingleNavigationPage<CovidBoosterMarketForecastSlugs, TModel>

const DealsSupplyAndDonations: CovidBoosterMarketNavigationPage<
  typeof VaccinesDealsAndDonationsDealsModel
> = {
  key: 'DealsSupplyAndDonations',
  path: 'supply-deals-and-donations',
  model: VaccinesDealsAndDonationsDealsModel,
  component: GenericTable,
  views: [
    {
      name: 'All',
      airtableName: 'api_supply_all',
    },
    {
      name: 'Bilateral Deals',
      airtableName: 'api_supply_all_bilateral_deals',
    },
    {
      name: 'Bilateral Donations',
      airtableName: 'api_supply_all_bilateral_donations',
    },
    {
      name: 'COVAX Deliveries',
      airtableName: 'api_supply_all_covax_deliveries',
    },
  ],
}

export const CovidBoosterMarketForecastPages: INavigationPages<CovidBoosterMarketForecastSlugs> =
  {
    DealsSupplyAndDonations,
  }
