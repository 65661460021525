import { IApp } from 'interfaces/navigationApp.interface'

import { AccessGroups } from 'enums/AccessGroups'

import { EpidemiologyCustomPages } from 'config/apps/Covid19/Epidemiology/EpidemiologyCustomPages'
import {
  EpidemiologyMenu,
  EpidemiologySlugs,
} from 'config/apps/Covid19/Epidemiology/EpidemiologyMenu'
import { EpidemiologyPages } from 'config/apps/Covid19/Epidemiology/EpidemiologyPages'

const ModernaCovid19EpidemiologyApp: IApp<EpidemiologySlugs> = {
  name: 'COVID-19',
  slug: 'moderna-covid-19-epidemiology',
  accessGroups: [AccessGroups.Moderna],
  commonIdentifier: 'covid-19',
  endpoint: 'covid-epidemiology',
  menu: EpidemiologyMenu,
  pages: EpidemiologyPages,
  customPages: EpidemiologyCustomPages,
  disableAnalytics: true,
  createdAt: new Date(2022, 5, 30),
}

export default ModernaCovid19EpidemiologyApp
