import { IModel } from 'interfaces/model.interface'
import { ValueType } from 'interfaces/valueType.interface'

import ArticleTypes from 'config/common/tagColours/ArticleTypes'
import Phase from 'config/common/tagColours/Phase'
import TargetPopulation from 'config/common/tagColours/TargetPopulation'
import floatToPercentageFormat from 'config/common/transformValue/percentage'

export type RespiratoryResultsData =
  | 'candidateNameFromNotes'
  | 'lookupManualLatestPhase'
  | 'immunogenicityEndpoint'
  | 'seroconversion'
  | 'ninetyFiveCiUpper'
  | 'ninetyFiveCiLower'
  | 'gmtTitres'
  | 'url'
  | 'sourceType'
  | 'population'
  | 'efficacyEndpoint'
  | 'otherEfficacyMeasures'
  | 'safetyEndpoint'

const allowedAirtables = ['Vaccines', 'Non-vaccines'] as const

export const RespiratoryResultsModel: IModel<
  Record<RespiratoryResultsData, string>,
  typeof allowedAirtables
> = {
  allowedAirtables,
  displayKey: 'candidateNameFromNotes',
  name: 'Results',
  entityName: 'Deals',
  searchField: 'candidateNameFromNotes',
  endpoint: 'influenza-phase3-vaccine-and-treatment-result',
  excludeGeneratedCharts: true,
  detailViewType: 'Generic',
  schema: {
    columns: [
      {
        key: 'candidateNameFromNotes',
        label: 'Candidate Name',
        width: 200,
        type: ValueType.SINGLE,
      },
      {
        key: 'lookupManualLatestPhase',
        label: 'Phase',
        width: 150,
        type: ValueType.SINGLE,
        cellFormat: {
          colours: Phase,
        },
      },
      {
        key: 'immunogenicityEndpoint',
        label: 'Immunogenicity Endpoint',
        width: 180,
        type: ValueType.SINGLE,
      },
      {
        key: 'seroconversion',
        label: 'Seroconversion',
        width: 180,
        type: ValueType.NUMBER,
        transformValue: floatToPercentageFormat,
      },
      {
        key: 'ninetyFiveCiUpper',
        label: '95% CI: Upper',
        width: 180,
        type: ValueType.NUMBER,
        transformValue: floatToPercentageFormat,
      },
      {
        key: 'ninetyFiveCiLower',
        label: '95% CI: Lower',
        width: 180,
        type: ValueType.NUMBER,
        transformValue: floatToPercentageFormat,
      },
      {
        key: 'url',
        label: 'URL',
        width: 150,
        type: ValueType.URL,
      },
      {
        key: 'sourceType',
        label: 'Source Type',
        width: 180,
        type: ValueType.SINGLE,
        cellFormat: {
          colours: ArticleTypes,
        },
      },
      {
        key: 'population',
        label: 'Population',
        width: 180,
        type: ValueType.MULTI,
        cellFormat: {
          colours: TargetPopulation,
        },
      },
      {
        key: 'efficacyEndpoint',
        label: 'Efficacy Endpoint',
        width: 180,
        type: ValueType.TEXT,
      },
      {
        key: 'otherEfficacyMeasures',
        label: 'Other Efficacy Measures',
        width: 180,
        type: ValueType.TEXT,
      },
      {
        key: 'safetyEndpoint',
        label: 'Safety Endpoint',
        width: 180,
        type: ValueType.TEXT,
      },
      {
        key: 'gmtTitres',
        label: 'GMT Titres',
        width: 180,
        type: ValueType.NUMBER,
      },
    ],
  },
}
