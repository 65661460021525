export default {
  Review: 'blue.100',
  Commentary: 'purple.100',
  'Trial in animals': 'orange.100',
  'Mechanisitic study': 'green.100',
  'Journal Article': 'rgb(252, 180, 0)',
  'Journal article': 'rgb(252, 180, 0)',
  Journal: 'rgb(252, 180, 0)',
  'Clinical Study': 'rgb(208, 240, 253)',
  Preprint: 'rgb(194, 245, 233)',
  'Clinical Trials and Observations': 'rgb(255, 234, 182)',
  'Clinical Trial Protocol': 'rgb(254, 226, 213)',
  Comment: 'rgb(255, 220, 229)',
  Letter: 'rgb(255, 218, 246)',
  'Letter to Editor': 'rgb(237, 226, 254)',
  'Short Communication': 'rgb(194, 245, 233)',
  Overview: 'rgb(156, 199, 255)',
  'Case Report': 'rgb(119, 209, 243)',
  'Systematic Review': 'rgb(114, 221, 195)',
  'Meta-Analysis': 'rgb(147, 224, 136)',
  'Study Protocol': 'rgb(139, 70, 255)',
  Communication: 'rgb(107, 28, 176)',
  'Pharmacology Consult': 'rgb(119, 209, 243)',
  Report: 'rgb(6, 160, 155)',
  'Method Article': 'rgb(39, 80, 174)',
  'Short Report': 'rgb(156, 199, 255)',
  'Basic Science': 'rgb(208, 240, 253)',
  'Clinical Trial': 'rgb(255, 234, 182)',
  'Clinical trial': 'rgb(255, 124, 142)',
  'Correction / Erratum': 'rgb(254, 226, 213)',
  'Cost Analysis': 'rgb(255, 220, 229)',
  Other: 'rgb(255, 218, 246)',
  'Personal Opinion': 'rgb(237, 226, 254)',
  'Trial Protocol': 'rgb(156, 199, 255)',
  'FT not available': 'rgb(119, 209, 243)',
  'Cost analysis': 'rgb(255, 234, 182)',
  'Personal opinion': 'rgb(254, 226, 213)',
  'Guideline Recommendation': 'rgb(32, 217, 210)',
  Editorial: 'rgb(32, 217, 210)',
  'Comparative Study': 'rgb(32, 217, 210)',
  core: 'rgb(24, 191, 255)',
  'Press release': 'rgb(24, 191, 255)',
}
