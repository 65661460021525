import { useEffect, useState } from 'react'

export type FlourishEmbedPropTypes = {
  type: 'story' | 'visualisation'
  id: string
}

export const FlourishEmbed = ({ type, id }: FlourishEmbedPropTypes) => {
  const [height, setHeight] = useState(400)

  useEffect(() => {
    const handleHeightResize = (event: any) => {
      if (typeof event.data === 'string') {
        try {
          const data = JSON.parse(event.data)
          if (
            data?.sender === 'Flourish' &&
            data?.context === 'iframe.resize'
          ) {
            setHeight(data.height)
          }
        } catch (e) {
          console.error(
            'Failed to parse JSON when handling height resize (Flourish Embed)'
          )
        }
      }
    }
    window.addEventListener('message', handleHeightResize, false)
    return () => {
      window.removeEventListener('message', handleHeightResize)
    }
  }, [])

  return (
    <iframe
      id='flourish_viz'
      src={`https://flo.uri.sh/${type}/${id}/embed?auto=1`}
      style={{ width: '1px', minWidth: '100%', height }}
      scrolling='no'
      title='Interactive or visual content'
      sandbox='allow-same-origin allow-forms allow-scripts allow-downloads allow-popups allow-popups-to-escape-sandbox allow-top-navigation-by-user-activation'
      frameBorder='0'
    />
  )
}
