export default {
  'Randomised control trial ': 'rgb(207, 223, 255)',
  'Non-randomised control trial ': 'rgb(208, 240, 253)',
  'Expanded access': 'rgb(209, 247, 196)',
  Observational: 'rgb(255, 234, 182)',
  Sequential: 'rgb(254, 226, 213)',
  'Randomised parallel study': 'rgb(255, 220, 229)',
  'Non-randomised parallel': 'rgb(255, 218, 246)',
  'Single Group Assignment': 'rgb(237, 226, 254)',
  'N/A Sequential': 'rgb(139, 70, 255)',
  'Non-randomised sequential': 'rgb(32, 201, 51)',
  'Non-randomised Single Group Assignment': 'rgb(186, 30, 69)',
  'Randomised Single Group Assignment': 'rgb(156, 199, 255)',
  'Randomised sequential': 'rgb(178, 21, 139)',
}
