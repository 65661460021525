import { IApp } from 'interfaces/navigationApp.interface'

import { AccessGroups } from 'enums/AccessGroups'

import { IEGPolicySurveillanceCustomPage } from './IEGPolicySurveillanceCustomPages'
import {
  IEGPolicySurveillanceMenu,
  IEGPolicySurveillanceSlugs,
} from './IEGPolicySurveillanceMenu'
import { IEGPolicySurveillancePages } from './IEGPolicySurveillancePages'

const IEGPolicySurveillance: IApp<IEGPolicySurveillanceSlugs> = {
  name: 'IEG Policy Surveillance',
  slug: 'ieg-policy-surveillance',
  endpoint: 'real-world-evidence',
  accessGroups: [AccessGroups.IEGPolicySurveillance],
  menu: IEGPolicySurveillanceMenu,
  customPages: IEGPolicySurveillanceCustomPage,
  pages: IEGPolicySurveillancePages,
  appIdentifier: 'iegPolicySurveillance',
  createdAt: new Date(2022, 2, 3),
}

export default IEGPolicySurveillance
