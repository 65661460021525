// These functions are a copy of those in react-table
// It's here since at the moment, we'll need some babel transform to require the file directly even though we don't need them

export const actions = {
  init: "init",
};

export function functionalUpdate(updater, old) {
  return typeof updater === "function" ? updater(old) : updater;
}
