import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'

type PropTypes = {
  isOpen: boolean
  onClose: () => void
  onDeleteUserView: () => void
}

export default function DeleteUserViewModal({
  isOpen,
  onClose,
  onDeleteUserView,
}: PropTypes) {
  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader color='primary' fontWeight='600'>
          Delete View
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text color='primary' fontSize='14px' lineHeight='22px'>
            Are you sure you want to permanently delete this view?
          </Text>
        </ModalBody>

        <ModalFooter>
          <HStack spacing='16px'>
            <Button
              variant='outline'
              color='black'
              onClick={() => {
                onClose()
              }}
            >
              Cancel
            </Button>
            <Button
              variant='yellow'
              color='white'
              bgColor='error'
              onClick={() => {
                onDeleteUserView()
                onClose()
              }}
              width='88px'
            >
              Delete
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
