import _ from 'lodash'
import { isMoment } from 'moment'
import { SortByFn } from 'react-table'

import { ICleanResponse } from 'api/types'

function SortData<D>(
  data: ICleanResponse<Extract<keyof D, string>>[],
  sortAccessor:
    | Extract<keyof D, string>
    | ((data: ICleanResponse<Extract<keyof D, string>>) => any)
) {
  let sorted = _.sortBy(data, (obj) => {
    if (typeof sortAccessor === 'function') {
      return handleOtherData(sortAccessor(obj))
    }

    return handleOtherData(obj[sortAccessor])
  }).reverse()

  return sorted
}

const handleOtherData = (obj: any) => {
  if (isMoment(obj)) {
    if (!obj.isValid()) {
      return 0
    }
    return obj.unix()
  }
  return obj
}

export default SortData

export const sortTypeFromList =
  (valuesOrder: string[]): SortByFn<Record<any, any>> =>
  (a, b, columnId) => {
    const aScore = valuesOrder.indexOf(a.values[columnId])
    const bScore = valuesOrder.indexOf(b.values[columnId])

    if (aScore === -1 && bScore === -1) {
      return ('' + a.values[columnId]).localeCompare(b.values[columnId])
    } else if (aScore === -1 && bScore !== -1) {
      return 1
    } else if (aScore !== -1 && bScore === -1) {
      return -1
    } else if (aScore !== -1 && bScore !== -1) {
      return aScore - bScore
    } else {
      return 0
    }
  }

export const nativeSortTypeFromList =
  (valuesOrder: string[], accessor: any) => (a: any, b: any) => {
    const aScore = valuesOrder.indexOf(_.get(a, accessor))
    const bScore = valuesOrder.indexOf(_.get(b, accessor))

    if (aScore === -1 && bScore === -1) {
      return 0
    } else if (aScore === -1 && bScore !== -1) {
      return 1
    } else if (aScore !== -1 && bScore === -1) {
      return -1
    } else if (aScore !== -1 && bScore !== -1) {
      return aScore - bScore
    } else {
      return 0
    }
  }
