import _ from 'lodash'
import { memo } from 'react'
import { TableState } from 'react-table'

import { Table } from 'components'
import {
  UserViewConfig,
  UseUserViewsReturn,
} from 'components/Table/useUserViews'

import { IResponseBase } from 'api/types'

import { IModel } from 'interfaces/model.interface'

import generateColumns, { CustomColumn } from 'utils/generateColumns'

type MemoTablePropTypes = {
  data: IResponseBase<any>[] | undefined
  userViewConfig: UserViewConfig
  useUserViewsReturn: UseUserViewsReturn
  model: IModel<any, any>
  isLoading: boolean
  exportName: string
  handleSelectRow: (data: any) => void
  onStateChange: (newState: TableState) => void
  state?: Partial<TableState>
  total?: number
  tableName?: string
  callback?: (func: (e: any) => void) => void
  goToPageCallback?: (func: (e: any) => void) => void
  isRenderingDashboard?: boolean
}

function TableComponent({
  tableName,
  data,
  userViewConfig,
  useUserViewsReturn,
  model,
  isLoading,
  exportName,
  handleSelectRow,
  onStateChange,
  state,
  total,
  callback,
  goToPageCallback,
  isRenderingDashboard,
}: MemoTablePropTypes) {
  return (
    <Table
      tableName={tableName}
      userViewConfig={userViewConfig}
      useUserViewsReturn={useUserViewsReturn}
      data={data ?? []}
      model={model}
      searchField={model.searchField}
      searchable={true}
      onRowClick={
        (model.detailViewType || model.renderDetailView) && handleSelectRow
      }
      isLoading={isLoading}
      columns={generateColumns(model.schema.columns) as CustomColumn<Object>[]}
      exportName={exportName}
      extraUseTableOptions={{
        manualSortBy: true,
        manualPagination: true,
        manualGlobalFilter: true,
        manualAdvancedFilters: true,
        autoResetExpanded: false,
      }}
      onStateChange={onStateChange}
      state={state}
      total={total}
      goToPageCallback={goToPageCallback}
      callback={callback}
      isRenderingDashboard={isRenderingDashboard}
    />
  )
}

export default memo(TableComponent, _.isEqual)
