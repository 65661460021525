import { Box, Text } from '@chakra-ui/react'
import { Panel } from 'components/Panel'

const Popover = ({ hoverInfo }: { hoverInfo: any }) => {
  return (
    <Panel
      width={'max'}
      border='1px solid'
      borderColor='gray3'
      borderRadius='8px'
      className='tooltip'
      style={{ left: hoverInfo.x, top: hoverInfo.y, zIndex: 700 }}
      shadow={'md'}
      gap={2}
      p={1}
    >
      <Box fontSize={'14px'} p={1}>
        <Box display={'flex'} gap={1}>
          <Text color='gray.700'>Country:</Text>
          <Text maxW='200px' fontWeight={'semibold'}>
            {hoverInfo.properties.name}
          </Text>
        </Box>
        <Box display={'flex'} gap={1}>
          <Text color='gray.700'>Clinical Trials:</Text>
          <Text maxW='200px' fontWeight={'semibold'}>
            {hoverInfo.feature.properties.count}
          </Text>
        </Box>
      </Box>
    </Panel>
  )
}

export default Popover
