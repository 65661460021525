export default {
  Conference: 'blue.100',
  Journal: 'purple.100',
  Preprint: 'orange.100',
  'Pubmed Review': 'green.100',
  Pubmed: 'red.100',
  'Journal Review Article': 'yellow.100',
  'Journal Original Article': 'teal.100',
  'Journal Commentary': 'blue.100',
}
