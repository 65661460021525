import GenericTable from 'modules/Tables'

import { IModel } from 'interfaces/model.interface'
import {
  INavigationPages,
  ISingleNavigationPage,
} from 'interfaces/navigationPage.interface'

import { SortOrders } from 'enums/SortOrders'

import {
  InfluenzaDealsPriceModel,
  InfluenzaDealsSupplyModel,
  InfluenzaImmunisationScheduleModel,
  InfluenzaRevenueModel,
  InfluenzaTendersModel,
} from 'config/apps/Influenza/Commercial/VaccinesTherapeutics/InfluenzaCommericalModels'

import { InfluenzaCommercialSlugs } from './InfluenzaCommercialMenu'
import warningMessages from './InfluenzaWarningMessages'
import {
  warningMessagesRevenue,
  warningMessagesTenders,
} from './WarningMessages'

type InfluenzaNavigationPage<TModel extends IModel<any>> =
  ISingleNavigationPage<InfluenzaCommercialSlugs, TModel>

const InfluenzaCountriesImmunisationSchedule: InfluenzaNavigationPage<
  typeof InfluenzaImmunisationScheduleModel
> = {
  key: 'InfluenzaCountriesImmunisationSchedule',
  path: ['demand', 'immunisation-schedules'],
  model: {
    ...InfluenzaImmunisationScheduleModel,
    defaultSortObject: [
      { id: 'linkCountry', sortOrder: SortOrders.ASC },
      { id: 'influenzaSeason', sortOrder: SortOrders.DESC },
    ],
  },
  component: GenericTable,
  views: [
    {
      name: 'Default',
      airtableName: 'front_end_export',
      airtableBase: 'respiratory360',
    },
  ],
}

const InfluenzaDealsSupply: InfluenzaNavigationPage<
  typeof InfluenzaDealsSupplyModel
> = {
  key: 'InfluenzaDealsSupply',
  path: ['supply-pricing', 'supply-table'],
  model: {
    ...InfluenzaDealsSupplyModel,
    name: 'Supply',
    newNote: {
      text: warningMessages.supplyPriceNewNote,
      newUntil: new Date(2024, 8, 27),
    },
    warningMessage: warningMessages.supplyDeals,
    defaultSortObject: [
      {
        id: 'recipients',
        sortOrder: SortOrders.ASC,
      },
      {
        id: 'yearDelivered',
        sortOrder: SortOrders.DESC,
      },
    ],
  },
  component: GenericTable,
  views: [
    {
      name: 'Default',
      airtableName: 'flu_commercial_supply_deals',
      airtableBase: 'lzdb',
    },
    {
      name: 'Seasonal',
      airtableName: 'flu_commercial_supply_deals_seasonal',
      airtableBase: 'lzdb',
    },
    {
      name: 'Pandemic',
      airtableName: 'flu_commercial_supply_deals_pandemic',
      airtableBase: 'lzdb',
    },
  ],
}
const InfluenzaDealsPrice: InfluenzaNavigationPage<
  typeof InfluenzaDealsPriceModel
> = {
  key: 'InfluenzaDealsPrice',
  path: ['supply-pricing', 'pricing-table'],
  model: {
    ...InfluenzaDealsPriceModel,
    name: 'Pricing',
    defaultSortObject: [
      { id: 'recipients', sortOrder: SortOrders.ASC },
      { id: 'yearDelivered', sortOrder: SortOrders.DESC },
    ],
  },
  component: GenericTable,
  views: [
    {
      name: 'Default',
      airtableName: 'flu_pricing_deals',
      airtableBase: 'lzdb',
    },
    {
      name: 'Seasonal',
      airtableName: 'flu_pricing_deals_seasonal',
      airtableBase: 'lzdb',
    },
    {
      name: 'Pandemic',
      airtableName: 'flu_pricing_deals_pandemic',
      airtableBase: 'lzdb',
    },
  ],
}

const ModernaInfluenzaTenders: InfluenzaNavigationPage<
  typeof InfluenzaTendersModel
> = {
  key: 'InfluenzaDealsProduction',
  path: ['tenders', 'table'],
  model: {
    ...InfluenzaTendersModel,
    defaultSortKey: 'deadline',
    defaultSortOrder: SortOrders.DESC,
    warningMessage: warningMessagesTenders,
  },
  component: GenericTable,
  views: [
    {
      name: 'Default',
      airtableName: 'flu_tender',
      airtableBase: 'lzdb',
    },
  ],
}
const Revenue: InfluenzaNavigationPage<typeof InfluenzaRevenueModel> = {
  key: 'InfluenzaCommercialsTable',
  path: ['revenue', 'table'],
  model: {
    ...InfluenzaRevenueModel,
    warningMessage: warningMessagesRevenue,
  },
  component: GenericTable,
  views: [
    {
      name: 'Default',
      airtableName: 'flu_commercial_revenue',
      airtableBase: 'lzdb',
    },
    {
      name: 'Seasonal',
      airtableName: 'flu_commercial_revenue_vaccines_seasonal',
      airtableBase: 'lzdb',
    },
    {
      name: 'Pandemic',
      airtableName: 'flu_commercial_revenue_vaccines_pandemic',
      airtableBase: 'lzdb',
    },
  ],
}

export const InfluenzaCommercialPages: INavigationPages<InfluenzaCommercialSlugs> =
  {
    InfluenzaCountriesImmunisationSchedule,
    InfluenzaDealsSupply,
    InfluenzaDealsPrice,
    ModernaInfluenzaTenders,
    Revenue,
  }
