import { ChevronDown } from '@carbon/icons-react'
import { Button, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react'

import useTracking from 'tracking/useTracking'

type SortMenuProps = {
  setSortOrder: React.Dispatch<React.SetStateAction<'desc' | 'asc'>>
  sortOrder: 'asc' | 'desc'
}
export const SortMenu = ({ setSortOrder, sortOrder }: SortMenuProps) => {
  const [tracking] = useTracking()
  return (
    <Menu autoSelect={false} offset={[0, -2]}>
      <MenuButton
        as={Button}
        textAlign='left'
        rightIcon={<ChevronDown size={16} />}
        borderColor='gray3'
        color='black'
        _hover={{
          bg: 'none',
        }}
        width='127px'
        borderRadius='4px'
        px={4}
        py='10px'
        height='38px'
        _focus={{
          boxShadow: 'none',
        }}
        fontWeight={400}
        fontSize='12px'
        lineHeight='20px'
        ml='auto'
      >
        {sortOrder === 'asc' ? 'Ascending' : 'Descending'}
      </MenuButton>
      <MenuList
        fontWeight={400}
        fontSize='12px'
        lineHeight='20px'
        borderTopRadius='none'
        p={0}
        minWidth='127px'
      >
        <MenuItem
          onClick={() => {
            setSortOrder('asc')
            tracking.filterReports({
              filter: 'byDate',
            })
          }}
          borderBottom='1px solid'
          borderColor='gray3'
          {...(sortOrder === 'asc' && {
            fontWeight: 'semibold',
          })}
        >
          Ascending
        </MenuItem>
        <MenuItem
          onClick={() => {
            setSortOrder('desc')
            tracking.filterReports({
              filter: 'byDate',
            })
          }}
          borderBottomRadius='4px'
          {...(sortOrder === 'desc' && {
            fontWeight: 'semibold',
          })}
        >
          Descending
        </MenuItem>
      </MenuList>
    </Menu>
  )
}
