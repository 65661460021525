import { IModel } from 'interfaces/model.interface'
import { ValueType } from 'interfaces/valueType.interface'

export type OrganisationsData =
  | 'name'
  | 'companyUrl'
  | 'newsroomUrl'
  | 'country2'
  | 'numberOfTreatmentsByOrganisation'
  | 'numberOfApprovedTreatments'
  | 'numberOfApprovedTreatmentsByDeveloper'
  | 'numberOfPriority1Candidates'
  | 'lookupRepurposedTxFromNewCandidates'
  | 'priorityFromNewCandidates'
  | 'numberOfTreatmentsByManufacturer'
  | 'priorityFromAfTrApiHolder'
  | 'lookupRepurposedTxFromAfTrApiHolder'
  | 'treatmentName'
  | 'websiteUrl'
  | 'incomeBandFromCountry'
  | 'treatmentNameFromCandidatesOrganisations'
  | 'numberOfLatePhaseRcTs'
  | 'approvedTreatmentNameFromCandidatesOrganisations'
  | 'approvedTreatmentName'

const allowedAirtables = [
  'api_tt_organisations',
  'api_tt_manufacturers',
] as const

export const OrganisationsModel: IModel<
  Record<OrganisationsData, string>,
  typeof allowedAirtables
> = {
  allowedAirtables,
  displayKey: 'name',
  name: 'Organisations',
  entityName: 'Organisations',
  endpoint: 'organisation',
  detailViewType: 'Generic',
  searchField: 'name',
  defaultSortKey: 'name',
  schema: {
    columns: [
      {
        label: 'Name',
        key: 'name',
        type: ValueType.LOGO,
        accessor: (d: any) => ({
          label: d.name,
          url: d.companyUrl ?? d.websiteUrl,
        }),
        width: 300,
      },
      {
        key: 'companyUrl',
        label: 'Company URL',
        type: ValueType.URL,
        width: 150,
      },
      {
        key: 'newsroomUrl',
        label: 'Newsroom URL',
        width: 300,
        type: ValueType.URL,
      },
      {
        key: 'country2',
        label: 'Location',
        width: 250,
        type: ValueType.MULTI,
      },
      {
        key: 'numberOfTreatmentsByOrganisation',
        label: 'Number of Treatments',
        width: 250,
        type: ValueType.NUMBER,
      },
      {
        key: 'numberOfApprovedTreatments',
        label: 'Number of Approved Treatments',
        width: 250,
        type: ValueType.NUMBER,
      },
      {
        key: 'numberOfApprovedTreatmentsByDeveloper',
        label: 'Number of Approved Treatments',
        width: 250,
        type: ValueType.NUMBER,
      },
      {
        key: 'numberOfPriority1Candidates',
        label: 'Number of Priority 1 Candidates',
        width: 250,
        type: ValueType.NUMBER,
      },
      {
        key: 'approvedTreatmentNameFromCandidatesOrganisations',
        label: 'Approved Treatments',
        width: 250,
        type: ValueType.MULTI,
      },
      {
        key: 'lookupRepurposedTxFromNewCandidates',
        label: 'Repurposed Treatment',
        width: 250,
        type: ValueType.MULTI,
        showTable: false,
        showDetail: false,
      },
      {
        key: 'approvedTreatmentName',
        label: 'Approved treatments',
        width: 250,
        type: ValueType.MULTI,
      },
      {
        key: 'priorityFromNewCandidates',
        type: ValueType.MULTI,
        label: 'Candidate Priority',
        width: 100,
        showDetail: false,
        showTable: false,
      },
      {
        key: 'priorityFromAfTrApiHolder',
        type: ValueType.MULTI,
        label: 'Candidate Priority',
        width: 100,
        showDetail: false,
        showTable: false,
      },
      {
        key: 'lookupRepurposedTxFromAfTrApiHolder',
        type: ValueType.MULTI,
        label: 'Repurposed Treatment',
        width: 100,
        showDetail: false,
        showTable: false,
      },
      {
        key: 'numberOfTreatmentsByManufacturer',
        label: 'Number of Treatments',
        width: 180,
        type: ValueType.NUMBER,
      },
      {
        key: 'treatmentName',
        label: 'Treatment Name',
        width: 250,
        type: ValueType.MULTI,
      },
      {
        key: 'websiteUrl',
        label: 'URL',
        type: ValueType.URL,
        width: 150,
      },
      {
        key: 'incomeBandFromCountry',
        label: 'Income Band',
        type: ValueType.MULTI,
        width: 151,
      },
      {
        key: 'treatmentNameFromCandidatesOrganisations',
        label: 'Treatments',
        type: ValueType.MULTI,
        width: 151,
      },
      {
        key: 'numberOfLatePhaseRcTs',
        label: "Number of Late Phase (III/IV) RCTs'",
        type: ValueType.NUMBER,
        width: 250,
      },
    ],
  },
}
