import { IApp } from 'interfaces/navigationApp.interface'

import { resolveModel } from 'utils/model'

import { IModel } from '../interfaces/model.interface'

// This file remaps our entire config to generate all possible keys
// This is used to generate the databases used in indexeddb
// We use function here to work around dependency cycle issue (use before defined)
export const getIndexedDBKeys = (apps: Record<string, IApp<any>>): string[] =>
  // @ts-ignore
  Object.values(apps)
    .map((app) => {
      let appEndpoint = [app.endpoint]
      if (app.premiumSlug) appEndpoint.push(app.premiumSlug)
      return appEndpoint.map((appEndpoint) => {
        return Object.values(app.pages).map((page) => {
          const pageModel = page.model
          return page.views.map((view) => {
            const model: IModel<any> = resolveModel(pageModel, view)

            const modelEndpoint = model.endpoint

            const airtableName = view.airtableName
            const airtableBase = view.airtableBase ?? 'covid'
            const key = formatKey(
              appEndpoint,
              modelEndpoint,
              airtableName,
              airtableBase
            )
            return key
          })
        })
      })
    })
    .flat(Infinity)

export const formatKey = (
  appEndpoint: string,
  modelEndpoint: string,
  airtableName: string,
  airtableBase: string
) => {
  return `${appEndpoint}_${modelEndpoint}_${airtableName}_${airtableBase}`
}
