import { Box } from '@chakra-ui/react'

const Card: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  return (
    <Box
      bg='white'
      width='100%'
      mx='auto'
      rounded='sm'
      overflow='hidden'
      transition='all .25s'
      boxShadow='0 0 0px 1px rgba(00, 00, 00, 0.05), 0 2px 4px rgba(00,00,00,0.06)'
      _hover={{
        boxShadow:
          '0 0 0px 1px rgba(00, 00, 00, 0.05), 0 2px 6px rgba(00,00,00,0.15)',
      }}
    >
      {children}
    </Box>
  )
}

export default Card
