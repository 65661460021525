import { IModel } from 'interfaces/model.interface'
import { ValueType } from 'interfaces/valueType.interface'

import { SortOrders } from 'enums/SortOrders'

import DiseaseRI from 'config/common/tagColours/DiseaseRI'

export type DiseaseInformationData =
  | 'id'
  | 'diseaseName'
  | 'topLevelParentDiseases'
  | 'isCommunicable'
  | 'afDiseaseName'
  | 'afDiseaseRi'
  | 'pathogenFamily'
  | 'afModeOfTransmission'
  | 'pathogenType'
  | 'afDiseaseCategory'
  | 'transmissionR0Average'
  | 'characteristics'
  | 'rawCaseFatalityPercentage'
  | 'pathogens'
  | 'pathogenPriority'

const allowedAirtables = ['disease_information']

export const DiseaseInformationModel: IModel<
  Record<DiseaseInformationData, string>,
  typeof allowedAirtables
> = {
  allowedAirtables,
  endpoint: 'disease',
  name: 'Disease Information',
  entityName: 'Disease',
  searchField: 'diseaseName',
  detailViewType: 'Generic',
  displayKey: 'diseaseName',
  defaultSortKey: 'afDiseaseRi',
  defaultSortOrder: SortOrders.DESC,
  schema: {
    columns: [
      {
        key: 'diseaseName',
        label: 'Disease',
        width: 200,
        type: ValueType.SINGLE,
      },
      {
        key: 'topLevelParentDiseases',
        label: 'Parent Diseases',
        width: 200,
        type: ValueType.MULTI,
      },
      {
        key: 'isCommunicable',
        label: 'Communicable?',
        width: 150,
        type: ValueType.BOOLEAN,
      },
      {
        key: 'afDiseaseName',
        label: 'Disease',
        width: 200,
        type: ValueType.SINGLE,
      },
      {
        key: 'afDiseaseRi',
        label: 'Disease RI',
        width: 100,
        type: ValueType.SINGLE,
        cellFormat: {
          colours: DiseaseRI,
        },
      },
      {
        key: 'pathogenFamily',
        label: 'Pathogen Family',
        width: 150,
        type: ValueType.SINGLE,
      },
      {
        key: 'afModeOfTransmission',
        label: 'Mode of Transmission',
        width: 165,
        type: ValueType.MULTI,
      },
      {
        key: 'pathogenType',
        label: 'Pathogen Type',
        width: 120,
        type: ValueType.SINGLE,
        cellFormat: {
          colours: {
            Virus: '#ffba07',
            Bacteria: '#0d52ac',
            Parasite: '#006400',
            Fungi: '#f797ef',
          },
        },
      },
      {
        key: 'afDiseaseCategory',
        label: 'Disease Category',
        width: 250,
        type: ValueType.MULTI,
      },
      {
        key: 'transmissionR0Average',
        label: 'Average RO',
        width: 110,
        type: ValueType.NUMBER,
      },
      {
        key: 'characteristics',
        label: 'Characteristics',
        width: 250,
        type: ValueType.TEXT,
      },
      {
        key: 'rawCaseFatalityPercentage',
        label: 'Case Fatality Rate (%)',
        width: 165,
        type: ValueType.NUMBER,
      },
      {
        key: 'pathogens',
        label: 'Pathogens',
        width: 200,
        type: ValueType.MULTI,
      },
      {
        key: 'pathogenPriority',
        label: 'Priority Designation',
        width: 150,
        type: ValueType.MULTI,
      },
    ],
  },
}
