export default function floatToPercentageFormat(value: string) {
  const num = parseFloat(value)

  if (!num) return null

  return `${num.toLocaleString('en', {
    style: 'percent',
    maximumFractionDigits: 2,
  })}`
}
