import { SlideOver } from 'components'

import { BriefsT } from 'api/cms/briefs/types'
import { Report } from 'api/types'

import Modal from './Modal'

const SlideModal = ({
  selected,
  setSelected,
  type,
  isMobile = false,
}: {
  selected: Report | null
  type: string
  setSelected: (item: Report | BriefsT | null) => void
  isMobile?: boolean
}) => {
  return (
    <SlideOver
      show={selected !== null ? true : false}
      width={isMobile ? 410 : 484}
    >
      {selected && (
        <Modal
          report={selected}
          type={type}
          handleClose={() => setSelected(null)}
        />
      )}
    </SlideOver>
  )
}

export default SlideModal
