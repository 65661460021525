import { RenderDetailViewProps } from 'interfaces/model.interface'

import { IDTreatments360NewsPressData } from 'config/common/baseModel/ida360/IDTreatment360NewsAndPressReleases'

import Detail from './Detail'

export const IDANewsPressDetailView = ({
  model,
  data,
  setSelectedRow,
  viewData,
}: RenderDetailViewProps<Record<IDTreatments360NewsPressData, string>>) => {
  if (!data) {
    return null
  }
  return (
    <Detail
      model={model}
      newsPress={data}
      handleClose={() => setSelectedRow(null)}
      viewData={viewData}
    />
  )
}
