import { IModel } from 'interfaces/model.interface'
import { ValueType } from 'interfaces/valueType.interface'

import { SortOrders } from 'enums/SortOrders'

import Sentiments from '../../tagColours/Sentiments'

export type CardioSovTweetsData =
  | 'screenName'
  | 'createdAt'
  | 'tweetUrl'
  | 'fullText'
  | 'ecActiveSubstance'
  | 'sentiment'
  | 'retweetCount'
  | 'favoriteCount'
  | 'lang'
  | 'hashtags'
  | 'cashtags'
  | 'urls'
  | 'isRetweet'

const allowedAirtables = [
  'api_esc_2021_social_for_boehringer_ingelheim',
  'api_easd_2021_social_for_boehringer_ingelheim',
] as const

export const CardioSovESCTweetsModel: IModel<
  Record<CardioSovTweetsData, string>,
  typeof allowedAirtables
> = {
  allowedAirtables,
  displayKey: 'screenName',
  name: 'Tweets',
  entityName: 'Tweets',
  detailViewType: 'Generic',
  defaultSortKey: 'createdAt',
  defaultSortOrder: SortOrders.DESC,
  chartColumnBlackList: ['screenName'],
  searchField: 'fullText',
  endpoint: 'esc-2021-s-sov-tweet',
  schema: {
    columns: [
      {
        key: 'screenName',
        label: 'Screen Name',
        width: 180,
        type: ValueType.MULTI,
      },
      {
        key: 'createdAt',
        width: 140,
        type: ValueType.DATE,
        showDetail: false,
        label: 'Date',
      },
      { key: 'tweetUrl', label: 'Tweet Url', width: 120, type: ValueType.URL },
      {
        key: 'fullText',
        width: 800,
        type: ValueType.TWEET,
        cellFormat: { maxLines: 2 },
        showDetail: false,
        label: 'Tweet',
      },
      {
        key: 'ecActiveSubstance',
        label: 'Ec Active Substance',
        width: 170,
        type: ValueType.MULTI,
      },
      {
        key: 'sentiment',
        label: 'Sentiment',
        width: 140,
        type: ValueType.MULTI,
        cellFormat: {
          colours: Sentiments,
        },
      },
      {
        key: 'retweetCount',
        label: 'Retweet Count',
        width: 130,
        type: ValueType.NUMBER,
      },
      {
        key: 'favoriteCount',
        label: 'Favorite Count',
        width: 120,
        type: ValueType.NUMBER,
      },
      { key: 'lang', label: 'Language', width: 90, type: ValueType.SINGLE },
      { key: 'hashtags', label: 'Hashtags', width: 340, type: ValueType.MULTI },
      { key: 'cashtags', label: 'Cashtags', width: 100, type: ValueType.MULTI },
      { key: 'urls', label: 'URLs', width: 120, type: ValueType.URL },
      {
        key: 'isRetweet',
        label: 'Is Retweet',
        width: 100,
        type: ValueType.BOOLEAN,
      },
    ],
  },
}
