export default {
  'Viral titres': 'rgb(255, 220, 229)',
  'Lab confirmed RSV LRTI': 'rgb(255, 218, 246)',
  'Safety (AEs/SAEs)': 'rgb(237, 226, 254)',
  'First occurance of lab confirmed after 14 days till 180 days or end of flu season in different age groups examined as separate endpoints; Geometric Mean Titers for 4 Influenza Strains (HI Assay)':
    'rgb(238, 238, 238)',
  'Medically attended RSV LTRI': 'rgb(156, 199, 255)',
  'Obstetric or congenital complications': 'rgb(119, 209, 243)',
  'Antibody response': 'rgb(114, 221, 195)',
}
