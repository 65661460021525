import { Box } from '@chakra-ui/react'

const FormErrorMessage: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  return (
    <Box
      bg='red.100'
      px={3}
      fontSize='sm'
      color='red.800'
      mt={1}
      rounded='sm'
      py={1}
      lineHeight='normal'
    >
      {children}
    </Box>
  )
}

export default FormErrorMessage
