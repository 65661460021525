import { createContext, useContext } from 'react'
import { TableInstance } from 'react-table'

// THIS IS A PROVIDER MODEL
// Make sure to use this properly. This file is here due to that. Only scoped to handling the table

// We put the parent props here (Those passed to the table component) so that those deep in the stack can access
// variables such as model. This is especially useful child table since it's deep into the tree and we normally don't access it.

type ProviderType = { useTableData?: TableInstance<any> }

export const TableUseTablePropsContext = createContext<ProviderType>({
  useTableData: undefined,
})

export const useTableUseTableProps = (): ProviderType => {
  return useContext(TableUseTablePropsContext)
}
