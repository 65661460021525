import { Checkmark as CarbonCheckmark } from '@carbon/icons-react'
import { chakra, Checkbox } from '@chakra-ui/react'

export default {
  baseStyle: {
    control: {
      borderWidth: '1px',
    },
  },
  defaultProps: {
    colorScheme: 'yellow',
  },
}

const Checkmark = chakra(CarbonCheckmark)
// Default prop overrides
// https://github.com/chakra-ui/chakra-ui/issues/1424
Checkbox.defaultProps = {
  ...Checkbox.defaultProps,
  iconColor: 'black',
  // icon: createElement(Checkmark, { color: 'primary' }),
}
