import { Box, Text, Flex } from '@chakra-ui/layout'
import _ from 'lodash'
import { Virtuoso } from 'react-virtuoso'

import { useAppRoute } from 'routes/utils'

import { InlineLoading } from 'components'

import { AlertsType, useCountryAlerts } from 'api/cms/alerts/useCountryAlerts'
import { flattenPaginatedData } from 'api/helper'
import { IResponseBase, Paginated } from 'api/types'

import useIsMobile from 'utils/useIsMobile'

import Alert from './Alert'
import InfoPopover from './InfoPopover'
import Legend from './Legend'

const AlertsSection = ({ fullPage }: { fullPage?: boolean }) => {
  const selectedApp = useAppRoute()
  const [isMobile] = useIsMobile()

  const {
    data,
    isFetched,
    isError,
    hasNextPage: canFetchMore,
    fetchNextPage: fetchMore,
  } = useCountryAlerts(selectedApp, {
    limit: 100,
  })

  const alerts = flattenPaginatedData(
    data?.pages as unknown as Paginated<IResponseBase[]>[]
  )?.results as unknown as AlertsType[]

  if (isError) {
    return (
      <Flex w='100%' alignItems='center' justifyContent='center'>
        Sorry, something went wrong loading the alerts
      </Flex>
    )
  }
  if (!alerts.length && isFetched) {
    return (
      <Flex w='100%' alignItems='center' justifyContent='center'>
        No alerts at the moment
      </Flex>
    )
  }
  if (!alerts.length) {
    return (
      <Flex w='100%' alignItems='center' justifyContent='center'>
        <InlineLoading />
      </Flex>
    )
  }

  const groupedAlerts = _.groupBy(alerts, (alert) => alert.date_published)

  const sortedDates = Object.keys(groupedAlerts).sort(
    (a, b) => new Date(b).getTime() - new Date(a).getTime()
  )

  const displayDates = fullPage ? sortedDates : [sortedDates[0]]
  const total = Object.keys(displayDates).length

  return (
    <Box
      h={fullPage ? 'calc(100vh - 130px)' : '100%'}
      w='full'
      overflow={'hidden'}
    >
      <Flex
        alignItems='center'
        mb='0.75rem'
        flexDir={isMobile ? 'column' : 'row'}
      >
        <Flex alignItems='center'>
          <Text fontSize={['lg', 'lg', 'xl']} mr='0.5rem' fontWeight={'500'}>
            Alerts
          </Text>
          <InfoPopover />
        </Flex>
        {fullPage && <Legend />}
      </Flex>
      {fullPage ? (
        <Virtuoso
          style={{ height: '78vh' }}
          totalCount={total}
          endReached={() => {
            canFetchMore && fetchMore()
          }}
          itemContent={(index: number) => {
            return (
              <Alert
                index={index}
                displayDates={displayDates}
                groupedAlerts={groupedAlerts}
                fullPage={fullPage}
              />
            )
          }}
        />
      ) : (
        <Box overflowY='auto' h='100%'>
          <Alert
            index={0}
            displayDates={displayDates}
            groupedAlerts={groupedAlerts}
          />
          <Box mb='3rem' />
        </Box>
      )}
    </Box>
  )
}

export default AlertsSection
