import { Box, Flex, Input, Skeleton } from '@chakra-ui/react'
import _ from 'lodash'
import {
  useTable,
  useFlexLayout,
  TableOptions,
  useGlobalFilter,
  useSortBy,
} from 'react-table'

import { Arrow } from 'components'

import useTracking from 'tracking/useTracking'

import { randomIntFromInterval } from 'utils/random'

export type BasicTablePropTypes = TableOptions<any> & {
  showSearch?: boolean
  isLoading?: boolean
}

/**
 * This component is used to render a table when we don't need the full blown feature of our normal table
 */
export const BasicTable = ({
  showSearch,
  isLoading,
  ...props
}: BasicTablePropTypes) => {
  const {
    getTableProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setGlobalFilter,
  } = useTable(props, useFlexLayout, useGlobalFilter, useSortBy)

  const [tracking] = useTracking()

  return (
    <>
      {showSearch && (
        <>
          <Input
            maxW='500px'
            w='100%'
            mb={2}
            type='text'
            py={2}
            px={4}
            backgroundColor='white'
            value={state.globalFilter ?? ''}
            placeholder={`Search records...`}
            onChange={(e: any) => {
              setGlobalFilter(e.target.value)
            }}
            height='32px'
            rounded='none'
            onBlur={() =>
              state.globalFilter &&
              tracking.tableSearch({ searchValue: state.globalFilter })
            }
          />
        </>
      )}
      <Box {...getTableProps()} border='1px solid' borderColor='gray.200'>
        <Box bg='legacy-primary.50' color='legacy-primary.500'>
          {headerGroups.map((headerGroup) => (
            <Box {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any) => (
                <Box
                  {...column.getHeaderProps(headerProps)}
                  textAlign='center'
                  py={3}
                  px={2}
                  fontWeight='bold'
                  fontSize='sm'
                  onClick={(e) => {
                    tracking.tableColumnSort({
                      column: column.id,
                      sortIndex: column.sortedIndex,
                    })
                    column.getSortByToggleProps().onClick(e)
                  }}
                  cursor='pointer'
                >
                  {column.render('Header')}
                  {column.isSorted && (
                    <Box ml='auto' position='relative'>
                      <Box display='flex' width='15px'>
                        <Box
                          height='30px'
                          display='flex'
                          flexDirection='column'
                          justifyContent='center'
                          alignItems='center'
                          opacity={1}
                        >
                          {!column.isSortedDesc ? (
                            <Arrow direction='up' />
                          ) : (
                            <Arrow />
                          )}
                        </Box>
                      </Box>
                    </Box>
                  )}
                </Box>
              ))}
            </Box>
          ))}
        </Box>

        {!isLoading && (
          <Box>
            {rows.map((row) => {
              prepareRow(row)
              return (
                <Box {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <Flex
                        {...cell.getCellProps()}
                        borderBottom='1px solid'
                        borderColor='gray.200'
                        px={2}
                        alignItems='center'
                        fontWeight={500}
                        fontSize='sm'
                        color='rgb(31, 31, 31)'
                      >
                        {cell.render('Cell')}
                      </Flex>
                    )
                  })}
                </Box>
              )
            })}
          </Box>
        )}
        {isLoading && (
          <Box>
            {_.times(15, (index) => {
              return (
                <Box
                  key={index}
                  px={3}
                  borderBottom='1px solid'
                  borderRight='1px solid'
                  borderColor='gray.100'
                  width='100%'
                  flex={`1 0 auto`}
                  display='flex'
                  className='cell'
                  color='legacy-primary.500'
                  height='58px'
                >
                  <Skeleton
                    height='30px'
                    my='auto'
                    width={randomIntFromInterval(75, 100) + '%'}
                  />
                </Box>
              )
            })}
          </Box>
        )}
      </Box>
    </>
  )
}

const headerProps = (props: any, { column }: any) =>
  getStyles(props, column.align)

const getStyles = (props: any, align = 'flex-start') => [
  props,
  {
    style: {
      justifyContent: align,
      alignItems: 'flex-start',
      display: 'flex',
    },
  },
]
