export default {
  'Phase I': 'rgb(207, 223, 255)',
  I: 'rgb(207, 223, 255)',
  'Phase III': 'rgb(248, 43, 96)',
  III: 'rgb(248, 43, 96)',
  Preclinical: 'rgb(194, 245, 233)',
  'Phase I/II': 'rgb(209, 247, 196)',
  'I/II': 'rgb(209, 247, 196)',
  'Phase II': 'rgb(255, 234, 182)',
  II: 'rgb(255, 234, 182)',
  'Phase II/III': 'RGB(143, 195, 231)',
  'II/III': 'RGB(143, 195, 231)',
  'Phase IV': 'RGB(247, 131, 157)',
  IV: 'RGB(247, 131, 157)',
  Preregistration: 'rgb(139, 70, 255)',
  'Early phase I': 'rgb(254, 226, 213)',
  Terminated: 'rgb(252, 180, 0)',
  'Planned/Announced': 'rgb(207, 223, 255)',
  Discovery: 'rgb(255, 218, 246)',
  'Pre-clinical': 'rgb(178, 21, 139)',
  IIb: 'rgb(207, 241, 255)',
  IIa: 'rgb(237, 225, 255)',
  Approved: 'rgb(156, 199, 255)',
  'Phase 1': 'rgb(208, 240, 253)',
  'Phase 1|Phase 2': 'rgb(207, 223, 255)',
  'Phase 2': 'rgb(237, 226, 254)',
  'Phase 4': 'rgb(207, 223, 255)',
  'Phase IIa': 'rgb(51, 138, 23)',
  Discontinued: 'rgb(248, 43, 96)',
}
