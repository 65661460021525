import GenericTable from 'modules/Tables'
import BarChart from 'modules/Tables/Dashboard/AllDashboards/BarChart'

import { IModel } from 'interfaces/model.interface'
import {
  defaultDashboardConfig,
  INavigationPages,
  ISingleNavigationPage,
} from 'interfaces/navigationPage.interface'

import {
  InfluenzaMediaModel,
  InfluenzaPressReleasesModel,
  InfluenzaCandidatePapersModel,
  InfluenzaTrialsModel,
} from 'config/apps/Influenza/Science360/VaccinesTherapeutics/InfluenzaModels'

import { RSVSlugs } from './RSVMenu'
import {
  RsvApprovalsNonVaccinesModel,
  RsvApprovalsVaccinesModel,
  RSVCandidatesAllCombinedModel,
  RSVCandidatesCombinationVaccinesModel,
  RSVCandidatesTreatmentModel,
  RSVCandidatesVaccineModel,
  RSVToolMetaNonVaccineEfficacyModel,
  RSVToolMetaVaccineEfficacyModel,
} from './RSVModels'
import barChartMessages from 'modules/Tables/Dashboard/AllDashboards/BarChart/message'
import { fetchCollection } from 'api/useCollectionData'

type RSVNavigationPage<TModel extends IModel<any>> = ISingleNavigationPage<
  RSVSlugs,
  TModel
>

const RSVCandidatesVaccine: RSVNavigationPage<
  typeof RSVCandidatesVaccineModel
> = {
  key: 'RSVCandidatesVaccine',
  path: ['candidates', 'dashboard'],
  model: {
    ...RSVCandidatesVaccineModel,
    newNote: {
      text: barChartMessages,
      newUntil: new Date(2024, 8, 25),
    },
  },
  component: GenericTable,
  views: [
    {
      name: 'All',
      airtableName: 'rsv_vax_treatments_combined',
      airtableBase: 'respiratory360',
      overrideModel: () => ({
        ...RSVCandidatesAllCombinedModel,
        customAPIFunction: (axios) => {
          const resolveData = Promise.all([
            fetchCollection(axios, 'rsv', 'treatment-candidate', {
              airtableBase: 'respiratory360',
              view: 'front_end_export_rsv_treatments',
            }),
            fetchCollection(axios, 'rsv', 'vaccine-candidate', {
              airtableBase: 'respiratory360',
              view: 'front_end_export_rsv_vax',
            }),
          ]).then((d) => {
            return d[0].results
              .map((obj: any) => {
                const { treatmentType, vaccineType, ...rest } = obj
                return {
                  ...rest,
                  vaccineType: treatmentType ?? vaccineType,
                }
              })
              .concat(d[1].results)
          })

          return () => resolveData
        },
      }),
    },
    {
      name: 'Vaccines',
      airtableName: 'front_end_export_rsv_vax',
      airtableBase: 'respiratory360',
    },
    {
      name: 'Non-Vaccines',
      airtableName: 'front_end_export_rsv_treatments',
      airtableBase: 'respiratory360',
      overrideModel: RSVCandidatesTreatmentModel,
    },
    {
      name: 'Combination vaccines',
      airtableName: 'combination vaccines RSV',
      airtableBase: 'respiratory360',
      overrideModel: RSVCandidatesCombinationVaccinesModel,
    },
    {
      name: 'Infant',
      airtableName: 'front_end_export_rsv_treatments_infant',
      airtableBase: 'respiratory360',
    },
    {
      name: 'Paediatric',
      airtableName: 'front_end_export_rsv_treatments_paediatric',
      airtableBase: 'respiratory360',
    },
    {
      name: 'Maternal',
      airtableName: 'front_end_export_rsv_treatments_maternal',
      airtableBase: 'respiratory360',
    },
    {
      name: 'Elderly',
      airtableName: 'front_end_export_rsv_treatments_elderly',
      airtableBase: 'respiratory360',
    },
  ],
  autoGenerateDashboard: {
    ...defaultDashboardConfig,
    render: BarChart,
    verticalAxisLabel: 'Number of Candidates',
    filterBlacklistOptions: ['linkResearchInstitutions', 'alternateNames'],
    defaultHorizontalFilterValue: {
      label: 'Phase',
      value: 'manualLatestPhase',
    },
    defaultStackByFilterValue: {
      label: 'Target Population',
      value: 'afTargetPopulation',
    },
    sortingOrders: {
      manualLatestPhase: [
        'Preclinical',
        'Phase 0',
        'Phase I',
        'Phase I/II',
        'Phase II',
        'Phase II/III',
        'Phase III',
        'Approved',
        'Phase IV',
        'Discontinued',
        'Paused',
        'Inactive',
        'Not Applicable',
        'Unknown',
      ],
    },
  },
}

const RSVCandidatePapers: RSVNavigationPage<
  typeof InfluenzaCandidatePapersModel
> = {
  key: 'RSVCandidatePapers',
  path: ['science', 'publications'],
  model: InfluenzaCandidatePapersModel,
  component: GenericTable,
  views: [
    {
      name: 'Default',
      airtableName: 'front_end_export_rsv_default_view',
      airtableBase: 'respiratory360',
    },
    {
      name: 'Vaccines',
      airtableName: 'front_end_export_rsv_vaccines_view',
      airtableBase: 'respiratory360',
    },
    {
      name: 'Non-Vaccines',
      airtableName: 'front_end_export_rsv_treatments_view',
      airtableBase: 'respiratory360',
    },
  ],
}

const RSVResultsVaccines: RSVNavigationPage<
  typeof RSVToolMetaVaccineEfficacyModel
> = {
  key: 'RSVResultsVaccines',
  path: ['results', 'vaccines'],
  model: RSVToolMetaVaccineEfficacyModel,
  component: GenericTable,
  views: [
    {
      name: 'Default',
      airtableName: 'results_rsv_vaccine_trials_frontend',
      airtableBase: 'respiratory360',
    },
  ],
}
const RSVResultsNonVaccines: RSVNavigationPage<
  typeof RSVToolMetaVaccineEfficacyModel
> = {
  key: 'RSVResultsNonVaccines',
  path: ['results', 'non-vaccines'],
  model: RSVToolMetaNonVaccineEfficacyModel,
  component: GenericTable,
  views: [
    {
      name: 'Default',
      airtableName: 'results_rsv_treatments_frontend',
      airtableBase: 'respiratory360',
    },
  ],
}

const RSVTrials: RSVNavigationPage<typeof InfluenzaTrialsModel> = {
  key: 'RSVTrials',
  path: ['trials', 'all-data'],
  model: InfluenzaTrialsModel,
  component: GenericTable,
  views: [
    {
      name: 'Default',
      airtableName: 'front_end_export_rsv_default_view',
      airtableBase: 'respiratory360',
    },
    {
      name: 'Vaccines',
      airtableName: 'front_end_export_rsv_vaccines_view',
      airtableBase: 'respiratory360',
    },
    {
      name: 'Non-Vaccines',
      airtableName: 'front_end_export_rsv_treatments_view',
      airtableBase: 'respiratory360',
    },
  ],
}

const RSVNewsPressReleases: RSVNavigationPage<
  typeof InfluenzaPressReleasesModel
> = {
  key: 'RSVNewsPressReleases',
  path: null,
  model: InfluenzaPressReleasesModel,
  component: GenericTable,
  views: [
    {
      name: 'Default',
      airtableName: 'front_end_export_rsv_default_view',
      airtableBase: 'respiratory360',
    },
    {
      name: 'Vaccines',
      airtableName: 'front_end_export_rsv_vaccines_view',
      airtableBase: 'respiratory360',
    },
    {
      name: 'Non-Vaccines',
      airtableName: 'front_end_export_rsv_treatments_view',
      airtableBase: 'respiratory360',
    },
  ],
}
const RSVNewsMedia: RSVNavigationPage<typeof InfluenzaMediaModel> = {
  key: 'RSVNewsMedia',
  path: null,
  model: InfluenzaMediaModel,
  component: GenericTable,
  views: [
    {
      name: 'Default',
      airtableName: 'front_end_export_rsv_default_view',
      airtableBase: 'respiratory360',
    },
    {
      name: 'Vaccines',
      airtableName: 'front_end_export_rsv_vaccines_view',
      airtableBase: 'respiratory360',
    },
    {
      name: 'Non-Vaccines',
      airtableName: 'front_end_export_rsv_treatments_view',
      airtableBase: 'respiratory360',
    },
  ],
}

const ApprovalsVaccines: RSVNavigationPage<typeof RsvApprovalsVaccinesModel> = {
  key: 'Vaccines',
  path: ['approvals', 'table'],
  model: RsvApprovalsVaccinesModel,
  component: GenericTable,
  views: [
    {
      name: 'Vaccines',
      airtableName: 'api_RSV_Vaccines',
      airtableBase: 'respiratory360',
    },
    {
      name: 'NonVaccines',
      airtableName: 'api_RSV_Treatments',
      airtableBase: 'respiratory360',
      overrideModel: RsvApprovalsNonVaccinesModel,
    },
  ],
}

export const RSVPages: INavigationPages<RSVSlugs> = {
  RSVCandidatesVaccine,
  RSVCandidatePapers,
  RSVResultsVaccines,
  RSVResultsNonVaccines,
  RSVTrials,
  RSVNewsPressReleases,
  RSVNewsMedia,
  ApprovalsVaccines,
}
