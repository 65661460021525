import { WidgetProps } from 'routes/dashboard/WidgetComponents'
import WidgetComponentTweet from 'routes/dashboard/WidgetComponents/WidgetTweet'

import { IModel } from 'interfaces/model.interface'
import { ValueType } from 'interfaces/valueType.interface'

import { SortOrders } from 'enums/SortOrders'

import Sentiments from '../tagColours/Sentiments'

export type TweetsData =
  | 'createdAt'
  | 'lookupScreenNameFromTwitterUsers'
  | 'lookupSpecialtiesFromTwitterUsers'
  | 'fullText'
  | 'lookupVaccineFromCandidateList'
  | 'lookupAfUniqueTreatmentName'
  | 'afTweetType'
  | 'keywordsClean'
  | 'tweetUrl'
  | 'afCandidateNumber'
  | 'luCandidateName'
  | 'company'
  | 'drugClassGrouping'
  | 'luDrugClass'
  | 'screenName'
  | 'twitterPersons'
  | 'followerCount'
  | 'ourUserTags'
  | 'stakeholderType'
  | 'topic'
  | 'subtopic'
  | 'afIndication'
  | 'sentiment'
  | 'projectKeywordHit'
  | 'afRelevant'
  | 'published'
  | 'followers'
  | 'luHIndex'
  | 'candidateName'
  | 'persons'
  | 'afTopics'
  | 'afSentiment'
  | 'retweetCount'
  | 'favoriteCount'
  | 'automatedTopicsCategory'
  | 'automatedTopicsSubCategory'
  | 'candidateNameFromLinkVaccineCandidateList'
  | 'candidateNameFromLinkTreatmentCandidateList'
  | 'afTopic'
  | 'linkInfectiousDisease'
  | 'afCountryBased'
  | 'luCompany'
  | 'kolMentionedInTweet'
  | 'kolAffiliationAndCountry'
  | 'linkPerson'
  | 'lookupSpecialitiesFromTwitterUsers'
  | 'targetFromCandidates'

const allowedAirtables = [
  'api_app_treatments',
  'api_variants',
  'api_diagnostics',
  'api_igm',
  'api_multiple_myeloma',
  'api_multiple_myeloma_360',
  'api_lipid_360',
  'api_anti-thrombotics_tweets',
  'Obesity_Tweets_Platform',
] as const

export const getTweetsDefaultWidget = ({
  view,
  model,
  title,
}: WidgetProps) => ({
  heading: title ?? 'Tweets',
  body: (
    <WidgetComponentTweet<TweetsData>
      view={view}
      model={model ?? TweetsModel}
      tweet='fullText'
      createdAt='createdAt'
      type='afTweetType'
      user='lookupScreenNameFromTwitterUsers'
      url='tweetUrl'
    />
  ),
})

export const TweetsModel: IModel<
  Record<TweetsData, string>,
  typeof allowedAirtables
> = {
  allowedAirtables,
  displayKey: 'persons',
  name: 'Tweets',
  entityName: 'Tweets',
  detailViewType: 'Generic',
  defaultSortKey: 'createdAt',
  defaultSortOrder: SortOrders.DESC,
  chartColumnBlackList: ['screenName', 'luHIndex', 'followers'],
  searchField: 'fullText',
  endpoint: 'tweet',
  schema: {
    columns: [
      {
        key: 'createdAt',
        width: 140,
        type: ValueType.DATE,
        showDetail: false,
        label: 'Date',
      },
      {
        key: 'lookupScreenNameFromTwitterUsers',
        type: ValueType.SINGLE,
        label: 'User Handle',
        width: 170,
      },
      {
        key: 'lookupSpecialtiesFromTwitterUsers',
        type: ValueType.MULTI,
        width: 180,
        label: 'User Specialities',
      },
      {
        key: 'lookupSpecialitiesFromTwitterUsers',
        type: ValueType.MULTI,
        width: 180,
        label: 'User Specialities',
      },
      {
        key: 'fullText',
        width: 800,
        type: ValueType.TWEET,
        cellFormat: {
          maxLines: 2,
        },
        showDetail: false,
        label: 'Tweet',
      },
      {
        key: 'afTweetType',
        type: ValueType.MULTI,
        width: 150,
        label: 'Tweet type',
      },
      {
        key: 'keywordsClean',
        type: ValueType.MULTI,
        width: 200,
        label: 'Keywords',
      },
      {
        key: 'lookupAfUniqueTreatmentName',
        type: ValueType.MULTI,
        width: 300,
        label: 'Treatment',
      },
      {
        key: 'lookupVaccineFromCandidateList',
        type: ValueType.SINGLE,
        width: 300,
        label: 'Vaccine',
      },
      { key: 'tweetUrl', label: 'Tweet Url', width: 120, type: ValueType.URL },
      {
        key: 'afCandidateNumber',
        label: 'Candidate Number',
        width: 150,
        type: ValueType.MULTI,
      },
      {
        key: 'luCandidateName',
        label: 'Candidate Name',
        width: 150,
        type: ValueType.MULTI,
      },
      { key: 'company', label: 'Company', width: 130, type: ValueType.MULTI },
      { key: 'luCompany', label: 'Company', width: 130, type: ValueType.MULTI },
      {
        key: 'drugClassGrouping',
        label: 'Drug Class Grouping',
        width: 250,
        type: ValueType.MULTI,
      },
      {
        key: 'luDrugClass',
        label: 'Drug Class',
        width: 250,
        type: ValueType.MULTI,
      },
      {
        key: 'screenName',
        label: 'Screen Name',
        width: 180,
        type: ValueType.MULTI,
      },
      {
        key: 'twitterPersons',
        label: 'Twitter Persons',
        width: 170,
        type: ValueType.MULTI,
      },
      {
        key: 'followerCount',
        label: 'Follower Count',
        width: 140,
        type: ValueType.NUMBER,
      },
      {
        key: 'ourUserTags',
        label: 'Our User Tags',
        width: 230,
        type: ValueType.MULTI,
      },
      {
        key: 'stakeholderType',
        label: 'Stakeholder Type',
        width: 230,
        type: ValueType.MULTI,
      },
      { key: 'topic', label: 'Topic', width: 200, type: ValueType.MULTI },
      { key: 'subtopic', label: 'Subtopic', width: 230, type: ValueType.MULTI },
      {
        key: 'automatedTopicsCategory',
        label: 'Topic',
        width: 200,
        type: ValueType.MULTI,
      },
      {
        key: 'automatedTopicsSubCategory',
        label: 'Subtopic',
        width: 230,
        type: ValueType.MULTI,
      },
      {
        key: 'afIndication',
        label: 'Indication',
        width: 230,
        type: ValueType.MULTI,
      },
      {
        key: 'sentiment',
        label: 'Sentiment',
        width: 140,
        type: ValueType.MULTI,
        cellFormat: {
          colours: Sentiments,
        },
      },
      {
        key: 'projectKeywordHit',
        label: 'Project Keyword Hit',
        width: 200,
        type: ValueType.MULTI,
      },
      {
        key: 'afRelevant',
        label: 'Relevant',
        width: 200,
        type: ValueType.MULTI,
      },
      {
        key: 'published',
        label: 'Published',
        width: 200,
        type: ValueType.MULTI,
      },

      {
        key: 'followers',
        label: 'Followers',
        width: 100,
        type: ValueType.NUMBER,
      },
      { key: 'luHIndex', label: 'H Index', width: 100, type: ValueType.NUMBER },
      {
        key: 'candidateName',
        label: 'Candidate Name',
        width: 200,
        type: ValueType.MULTI,
      },
      { key: 'persons', label: 'Persons', width: 180, type: ValueType.MULTI },
      { key: 'afTopics', label: 'Topics', width: 200, type: ValueType.MULTI },
      {
        key: 'afSentiment',
        label: 'Sentiment',
        width: 180,
        type: ValueType.MULTI,
      },
      {
        key: 'retweetCount',
        label: 'Retweet Count',
        width: 130,
        type: ValueType.NUMBER,
      },
      {
        key: 'favoriteCount',
        label: 'Favorite Count',
        width: 120,
        type: ValueType.NUMBER,
      },
      {
        key: 'candidateNameFromLinkVaccineCandidateList',
        label: 'Vaccines Candidate List',
        width: 240,
        type: ValueType.MULTI,
      },
      {
        key: 'candidateNameFromLinkTreatmentCandidateList',
        label: 'Treatments Candidate List',
        width: 240,
        type: ValueType.MULTI,
      },
      {
        key: 'afTopic',
        label: 'Tweet Type',
        width: 200,
        type: ValueType.MULTI,
      },
      {
        key: 'linkInfectiousDisease',
        label: 'Infectious Disease',
        width: 200,
        type: ValueType.MULTI,
      },
      {
        key: 'afCountryBased',
        label: 'Country KOL is based',
        width: 200,
        type: ValueType.MULTI,
      },
      {
        key: 'kolMentionedInTweet',
        label: 'KOL Mentioned',
        width: 200,
        type: ValueType.MULTI,
      },
      {
        key: 'kolAffiliationAndCountry',
        label: 'KOL Affiliation and Country',
        width: 200,
        type: ValueType.MULTI,
      },
      {
        key: 'linkPerson',
        type: ValueType.SINGLE,
        label: 'User Handle',
        width: 170,
      },
      {
        key: 'targetFromCandidates',
        label: 'Target',
        width: 200,
        type: ValueType.MULTI,
      },
    ],
  },
}
