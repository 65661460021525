import { Box, Text } from '@chakra-ui/react'
import { SIDEBAR_WIDTH } from 'constants/misc'
import { NavLink, useMatch } from 'react-router-dom'

import PrimaryLink from 'modules/Navigation/SidebarNavigation/PrimaryLink'

import { useBookmark } from 'contexts/Bookmark'

import { apps, appsListUnion } from 'config/apps'

import BookmarkedReport from './components/BookmarkedReport'

export default function BookmarkPage() {
  const match = useMatch('/bookmarks/:app')
  const currentApp = match?.params.app as appsListUnion
  const { bookmarkedItems } = useBookmark()

  const reportBookmarks = bookmarkedItems.filter(
    (item) => item.type === 'report' && item.app === apps[currentApp].slug
  )

  // IMPROVEMENTS: Loading state in this component instead of the individual reports components
  // (Batch query)

  const appsWithBookmarks = [
    ...new Set(bookmarkedItems.map((item) => item.app)),
  ]

  return (
    <Box display='flex' width='100%' flex={1} overflow='hidden'>
      <Box
        overflow='auto'
        maxHeight='100%'
        width={SIDEBAR_WIDTH}
        display='flex'
        py={0}
        position='relative'
        flexDirection='column'
      >
        <Box borderBottom='1px solid' borderColor='gray.200' py={'2rem'}>
          <Text
            fontSize='lg'
            fontWeight={600}
            mb={'2rem'}
            color='gray.900'
            textAlign='center'
          >
            Bookmarks
          </Text>
          {appsWithBookmarks.map((appWithBookmark, i) => (
            <NavLink key={i} to={`/bookmarks/${apps[appWithBookmark].slug}`}>
              <PrimaryLink isActive={apps[appWithBookmark].slug === currentApp}>
                {apps[appWithBookmark].name}
              </PrimaryLink>
            </NavLink>
          ))}
        </Box>
      </Box>
      <Box
        width={`calc(100% - ${SIDEBAR_WIDTH})`}
        display='flex'
        flex={1}
        px={3}
        overflowY='auto'
        flexDirection='column'
        pt='1rem'
      >
        <Box maxW='4xl' mx='auto'>
          <Text
            fontSize='lg'
            fontWeight={600}
            mb='1rem'
            color='gray.900'
            textAlign='center'
          >
            {apps[currentApp].name} Bookmarks
          </Text>
          {reportBookmarks.map((bookmarkedReport) => (
            <BookmarkedReport
              report={bookmarkedReport}
              key={bookmarkedReport.id}
            />
          ))}
        </Box>
      </Box>
    </Box>
  )
}
