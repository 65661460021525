import { Grid } from '@chakra-ui/react'

import TabbedDisplay from 'modules/TabbedDisplay'

import { RShinyEmbed } from 'components'

import OverviewTab from './OverviewTab'

const Epidemiology = () => (
  <TabbedDisplay
    tabListWrapper={(props) => (
      <Grid templateColumns='repeat(4, 1fr)' gap={3} {...props} />
    )}
    tabs={[
      {
        heading: 'Overview',
        description:
          'Infections, Hospitalisations, Deaths, and Vaccination info per country.',
        body: OverviewTab,
      },
      {
        heading: 'People Protected',
        description:
          'Protection per country based on vaccinations, natural and waning immunity, and more.',
        body: <RShinyEmbed path='peopleProtected' height='100vh' />,
      },
    ]}
  />
)

export default Epidemiology
