interface ValidationRule {
  title: string
  regex: (str: string, newRePassword?: string, username?: string) => boolean
}

export const passwordValidation: Record<string, ValidationRule> = {
  lowerCase: {
    title: 'One lowercase',
    regex: (str: string) => str.toUpperCase() !== str,
  },
  upperCase: {
    title: 'One uppercase',
    regex: (str: string) => str.toLowerCase() !== str,
  },
  numbers: {
    title: 'One number ( 0 - 9 )',
    regex: (str: string) => /\d/.test(str),
  },
  length: {
    title: 'At least 8 characters',
    regex: (str: string) => str?.length > 7,
  },
  notUsername: {
    title: 'Does not contain part of the username',
    regex: (str: string, _, username: string = '') => {
      return !!str && !str.toLowerCase().includes(username.toLowerCase())
    },
  },
  samePasswords: {
    title: 'Both new passwords must match',
    regex: (str: string, rePassword: string = '', username: string = '') => {
      return !!str && str === rePassword
    },
  },
}

export const validatePassword = (
  newPassword: string,
  username: string | undefined,
  newRePassword: string
) => {
  return Object.values(passwordValidation).map((validator) => {
    const isValid = validator.regex(newPassword, newRePassword, username)
    return { title: validator.title, isValid }
  })
}

export const getIsPasswordValid = (
  passwordRulesValidations: { isValid: boolean; title: string }[]
) => {
  return passwordRulesValidations.reduce(
    (acc: boolean, val: { isValid: boolean }): boolean => {
      return !val.isValid ? false : acc
    },
    true
  )
}
