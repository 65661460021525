import { Box, Heading, Portal } from '@chakra-ui/react'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import EntityPreview from 'modules/EntityPreview'

import { useGlobalSearch } from 'contexts/GlobalSearch'

import { EntitySearchType } from 'api/types'

import { SearchEntryPropTypes } from 'interfaces/entity.interface'
import { IModel } from 'interfaces/model.interface'
import { ISingleNavigationPage } from 'interfaces/navigationPage.interface'

import { appsListUnion } from 'config/apps'

import resolvePath from 'utils/resolvePath'

import SearchEntityEntry from './SearchEntityEntry'
import { SearchResultsCount } from './SearchResultsCount'

type PropTypes = {
  data: EntitySearchType[]
  app: appsListUnion
  page: ISingleNavigationPage<any, IModel<any>>
  searchString: string
}

export default function EntitySection({
  app,
  page,
  data,
  searchString,
}: PropTypes) {
  const entity = { ...page.model.defaultEntity, ...page.entity }
  const { setOverlayShown } = useGlobalSearch()

  return (
    <Box mb={8}>
      <SearchResultsCount
        searchString={searchString}
        count={data?.length ?? 0}
      />
      <Box display='flex' alignItems='center' mb={4}>
        <Heading fontSize='18px' fontWeight={600} color='primary'>
          {page.model.entityName}
        </Heading>
      </Box>

      {data.map((entry, i) => {
        const entryProps: SearchEntryPropTypes = {
          data: entry,
          app,
          page,
          entityId: entry.id,
        }

        const model = page.model.previewOverrideModel
          ? typeof page.model.previewOverrideModel === 'function'
            ? page.model.previewOverrideModel(page.model)
            : page.model.previewOverrideModel
          : page.model

        const link = resolvePath([app, page.path, entry.id])

        const entityEntry = React.createElement(
          entity?.searchEntryComponent || SearchEntityEntry,
          entryProps
        )

        return (
          <Box key={i} onClick={() => setOverlayShown(false)}>
            <SectionEntry
              link={link}
              model={model}
              entityEntry={entityEntry}
              entry={entry}
            />
          </Box>
        )
      })}
    </Box>
  )
}

type SectionEntryPropTypes = {
  model: IModel<any, any>
  link: string
  entry: EntitySearchType
  entityEntry: React.ReactNode
}

const SectionEntry = ({
  model,
  link,
  entry,
  entityEntry,
}: SectionEntryPropTypes) => {
  const [isHovered, setIsHovered] = useState(false)
  return (
    <Box
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      position='relative'
    >
      <Link to={link}>
        <Box
          py={3}
          fontSize='14px'
          fontWeight={400}
          cursor='pointer'
          _hover={{
            bg: 'gray.50',
          }}
        >
          {entityEntry}
        </Box>
      </Link>
      {isHovered && (
        <Portal>
          <Box
            backgroundColor='white'
            zIndex={100}
            maxHeight='80vh'
            overflow='auto'
            p={0}
            top='50%'
            left='40%'
            transform='translateY(-40%)'
            width='700px'
            position='absolute'
            boxShadow='rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.06) 0px 2px 4px'
          >
            <Box height='75vh' overflow='hidden'>
              <EntityPreview model={model} entityData={entry} />
            </Box>
          </Box>
        </Portal>
      )}
    </Box>
  )
}
