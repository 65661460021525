export default {
  Antiviral: 'rgb(255, 220, 229)',
  Antivirals: 'rgb(255, 220, 229)',
  'Antibody (anti-inflammatory)': 'rgb(207, 223, 255)',
  'Antibody (passive immunotherapy)': 'rgb(207, 241, 255)',
  'Cell-based therapies': 'rgb(255, 233, 183)',
  Device: 'rgb(255, 221, 228)',
  Steroid: 'rgb(255, 168, 129)',
  Antithromobotic: 'rgb(255, 214, 109)',
  Antibiotic: 'rgb(237, 226, 254)',
  Immunomodulator: 'rgb(139, 70, 255)',
  'Anti-inflammatory': 'rgb(208, 240, 253)',
  'Monoclonal antibody': 'rgb(255, 158, 183)',
  Immunoprophylaxis: 'rgb(255, 8, 194)',
  'Immune globulin': 'rgb(139, 70, 255)',
  'Polyclonal antibody': 'rgb(186, 30, 69)',
  Homeopathy: 'rgb(207, 223, 255)',
}
