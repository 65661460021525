import { compareDesc } from 'date-fns'
import { useMemo } from 'react'

import { useAppRoute } from 'routes/utils'

import { MenuWithMetaData } from 'interfaces/navigationMenu.interface'

import { apps } from 'config/apps'

import { isNew } from 'utils/isNew'
import resolvePath from 'utils/resolvePath'

export default () => {
  const app = useAppRoute()
  const selectedApp = apps[app]

  // Find new pages on each app
  const newMenuWithMetaList: MenuWithMetaData[] = useMemo(
    () =>
      selectedApp.menu
        .flat()
        .map((menu) => {
          if (!!menu.children) {
            return menu.children
              .filter((childMenu) =>
                isNew(childMenu.createdAt, childMenu.newUntil)
              )
              .map(
                (childMenu) =>
                  ({
                    ...childMenu,
                    // Combine the title of the parent
                    title: `${childMenu.title}`,
                    displayPath: [
                      selectedApp.name,
                      menu.title,
                      childMenu.title,
                    ],
                    // Get the description from the parent
                    description: menu.description,
                    fullPath: resolvePath([
                      selectedApp.slug,
                      menu.slug,
                      childMenu.slug,
                    ]),
                    appSlug: selectedApp.slug,
                  } as MenuWithMetaData)
              )
          }

          if (isNew(menu.createdAt, menu.newUntil)) {
            return [
              {
                ...menu,
                displayPath: [selectedApp.name, menu.title],
                fullPath: resolvePath([selectedApp.slug, menu.slug]),
                appSlug: selectedApp.slug,
              } as MenuWithMetaData,
            ]
          }

          return []
        })
        .flat()
        .sort((a, b) => compareDesc(a.createdAt, b.createdAt)),
    [selectedApp.menu, selectedApp.name, selectedApp.slug]
  )

  return newMenuWithMetaList
}
