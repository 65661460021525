import { IModel } from 'interfaces/model.interface'
import { ValueType } from 'interfaces/valueType.interface'

import { SortOrders } from 'enums/SortOrders'

import Contraindications from 'config/common/tagColours/Contraindications'
import FactorsModulatingDose from 'config/common/tagColours/FactorsModulatingDose'
import FactorsModulatingTreatmentDuration from 'config/common/tagColours/FactorsModulatingTreatmentDuration'
import GuidelineSource from 'config/common/tagColours/GuidelineSource'
import Organisations from 'config/common/tagColours/Organisations'
import Prerequisites from 'config/common/tagColours/Prerequisites'

export type CardioIndicationsDataData =
  | 'name'
  | 'drugName'
  | 'organisation'
  | 'indication'
  | 'parentOrChild'
  | 'guidelineSource'
  | 'prerequisites'
  | 'doseSizeMg'
  | 'dosing'
  | 'durationOfTreatmentDays'
  | 'factorsModulatingTreatmentDuration'
  | 'factorsModulatingDose'
  | 'alteredDoseMg'
  | 'factorsModulatingDose_3'
  | 'doseModulationMg_3'
  | 'contraindications'
  | 'source'

const allowedAirtables = ['api_anti-thrombotics_indications'] as const

export const CardioIndicationsDataModel: IModel<
  Record<CardioIndicationsDataData, string>,
  typeof allowedAirtables
> = {
  allowedAirtables,
  endpoint: 'indications-data',
  entityName: 'Indications',
  name: 'Approved Indications',
  searchField: 'drugName',
  detailViewType: 'Generic',
  defaultSortObject: [
    {
      id: 'drugName',
      sortOrder: SortOrders.ASC,
    },
    {
      id: 'indication',
      sortOrder: SortOrders.ASC,
    },
    {
      id: 'guidelineSource',
      sortOrder: SortOrders.ASC,
    },
  ],
  displayKey: 'drugName',
  schema: {
    columns: [
      { key: 'name', label: 'Name', width: 200, type: ValueType.TEXT },
      { key: 'drugName', label: 'Drug Name', width: 200, type: ValueType.TEXT },
      {
        key: 'organisation',
        label: 'Organisation',
        width: 230,
        type: ValueType.MULTI,
        cellFormat: {
          colours: Organisations,
        },
      },
      {
        key: 'indication',
        label: 'Indication',
        width: 270,
        type: ValueType.TEXT,
      },
      {
        key: 'parentOrChild',
        label: 'Parent Or Child',
        width: 150,
        showDetail: false,
        type: ValueType.TEXT,
        hiddenByDefault: true,
      },
      {
        key: 'guidelineSource',
        label: 'Guideline Source',
        width: 150,
        type: ValueType.MULTI,
        cellFormat: {
          colours: GuidelineSource,
        },
      },
      {
        key: 'prerequisites',
        label: 'Prerequisites',
        width: 300,
        type: ValueType.MULTI,
        cellFormat: {
          colours: Prerequisites,
        },
      },
      {
        key: 'doseSizeMg',
        label: 'Dose Size (mg)',
        width: 100,
        type: ValueType.TEXT,
      },
      { key: 'dosing', label: 'Dosing', width: 100, type: ValueType.TEXT },
      {
        key: 'durationOfTreatmentDays',
        label: 'Duration Of Treatment (Days)',
        width: 200,
        type: ValueType.TEXT,
      },
      {
        key: 'factorsModulatingTreatmentDuration',
        label: 'Factors Modulating Treatment Duration',
        width: 240,
        type: ValueType.MULTI,
        cellFormat: {
          colours: FactorsModulatingTreatmentDuration,
        },
      },
      {
        key: 'factorsModulatingDose',
        label: 'Factors Modulating Dose',
        width: 280,
        type: ValueType.MULTI,
        cellFormat: {
          colours: FactorsModulatingDose,
        },
      },
      {
        key: 'alteredDoseMg',
        label: 'Altered Dose (mg)',
        width: 130,
        type: ValueType.TEXT,
      },
      {
        key: 'contraindications',
        label: 'Contraindications',
        width: 350,
        type: ValueType.MULTI,
        cellFormat: {
          colours: Contraindications,
        },
      },
      { key: 'source', label: 'Source', width: 120, type: ValueType.URL },
    ],
  },
}
