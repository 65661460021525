/**
 * This hook removes the "parent" row of a grouped table (both inside and outside subRows)
 */
import { Row, UseTableInstanceProps } from 'react-table'

export const useParentChildRows = (hooks: any) => {
  hooks.useInstance.push(useInstance)
}

useParentChildRows.pluginName = 'useParentChildRows'

function useInstance(instance: any) {
  const { rows, parentRowFn } = instance

  const filteredRows = parentRowFn
    ? rows
        .map((row: Row) => {
          if (!!row.subRows) {
            return { ...row, subRows: row.subRows.filter(parentRowFn) }
          }

          return row
        })
        // Hide parent rows without any child
        .filter(
          (row: Row) =>
            !instance.groupByFn || (!!row.subRows && row.subRows.length > 0)
        )
    : rows

  Object.assign(instance, {
    rows: filteredRows,
  })
}

export interface UseParentChildRowsOptions<D extends object> {
  parentRowFn?: (row: Row<D>) => boolean
}

export interface UseParentChildRowsInstanceProps<D extends object> {
  rows: UseTableInstanceProps<D>['rows']
}
