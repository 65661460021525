import { IApp } from 'interfaces/navigationApp.interface'

import { AccessGroups } from 'enums/AccessGroups'

import { InternalToolingCustomPages } from './InternalToolingCustomPages'
import {
  InternalToolingMenu,
  InternalToolingSlugs,
} from './InternalToolingMenu'

const InternalTooling: IApp<InternalToolingSlugs> = {
  name: 'Internal Tooling',
  slug: 'internal-tooling',
  endpoint: 'internal-tooling',
  accessGroups: [AccessGroups.InternalTooling],
  menu: InternalToolingMenu,
  customPages: InternalToolingCustomPages,
  pages: {},
  appIdentifier: 'InternalTooling',
  createdAt: new Date(2022, 7, 11),
}

export default InternalTooling
