import { UseQueryOptions, useQuery } from '@tanstack/react-query'

import useAxios from '../useAxios'

export default function useAPIReadme(extraProps?: UseQueryOptions<any, any>) {
  const axios = useAxios()

  return useQuery<string>(
    ['apiReadme'],
    async () => {
      const res = await axios('/public-api/readme/')

      return res.data.data
    },
    extraProps
  )
}
