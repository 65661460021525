import { Box, chakra } from '@chakra-ui/react'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import useComponentSize from '@rehooks/component-size'
import React, { useContext, useRef, useState } from 'react'

// THIS IS A PROVIDER MODEL
// Make sure to use this properly. This file is here due to that. Only scoped to handling the table

// We're tracking the size and scroll position so that we can calculate what we need for child tables

type ProviderType = {
  position: IPosition
  size: ComponentSize
}

const TableSizeContext = React.createContext<ProviderType>({
  position: { x: 0, y: 0 },
  size: { width: 0, height: 0 },
})

type IPosition = {
  x: number
  y: number
}

type ComponentSize = {
  width: number
  height: number
}

export const useTableLayout = (): ProviderType => {
  return useContext(TableSizeContext)
}

function TableLayoutBox(props: {
  className?: string | undefined
  children?: React.ReactNode
}) {
  const [position, setPosition] = useState<IPosition>({
    x: 0,
    y: 0,
  })

  let ref = useRef<any>()
  let childRef = useRef<any>()

  let size = useComponentSize(ref)

  useScrollPosition(
    ({ currPos }) => {
      setPosition(currPos)
    },
    [],
    childRef,
    false,
    0,
    ref
  )

  return (
    <TableSizeContext.Provider
      value={{
        position,
        size,
      }}
    >
      <Box
        ref={ref}
        className={props.className}
        sx={{
          '&::-webkit-scrollbar': {
            width: '5px',
            height: '5px',
          },
          '&::-webkit-scrollbar-track': {
            width: '5px',
          },
          '&::-webkit-scrollbar-thumb': {
            background: 'gray3',
            borderRadius: '5px',
          },
        }}
      >
        <Box ref={childRef}>{props.children}</Box>
      </Box>
    </TableSizeContext.Provider>
  )
}

export default chakra(TableLayoutBox)
