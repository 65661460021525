export default {
  Unknown: 'rgb(207, 223, 255)',
  'Infection by Blastocystis species': 'rgb(209, 247, 196)',
  'Infection by Cryptosporidium parvum or Giardia lamblia':
    'rgb(255, 158, 183)',
  'Management of elevated intraocular pressure in patients with ocular hypertension or open-angle glaucoma':
    'rgb(215, 77, 38)',
  'High blood pressure': 'rgb(255, 8, 194)',
  'First-line treatment for locally advanced or metastatic non-small cell lung cancer':
    'rgb(24, 191, 255)',
  'Treatment of locally advanced or metastatic pancreatic cancer':
    'rgb(102, 102, 102)',
  'Treatment of advanced or metastatic bladder cancer': 'rgb(204, 204, 204)',
  'Treatment of locally advanced or metastatic epithelial ovarian cancer ':
    'rgb(248, 43, 96)',
  'Treatment of metastatic breast cancer': 'rgb(255, 214, 110)',
  'HIV prevention': 'rgb(208, 240, 253)',
  'Unresectable or metastatic melanoma with a BRAF V600 mutation':
    'rgb(204, 204, 204)',
  'Advanced non-small cell lung cancer with a BRAF V600 mutation':
    'rgb(252, 180, 0)',
  'Severe orthostatic hypotension': 'rgb(238, 238, 238)',
  'Pain and inflammation in rheumatic disease': 'rgb(107, 28, 176)',
  'Serious lower respiratory-tract disease caused by respiratory syncytial virus':
    'rgb(139, 70, 255)',
  Bronchiolitis: 'rgb(119, 209, 243)',
  'Life-threatening RSV, parainfluenza virus, and adenovirus infection in immunocompromised children':
    'rgb(147, 224, 136)',
  'Chronic hepatitis C': 'rgb(207, 223, 255)',
  'Treatment of relapsing-remitting multiple sclerosis.': 'rgb(254, 226, 213)',
  'Primary hypercholesterolaemia': 'rgb(207, 223, 255)',
  'Heterozygous familial hypercholesterolaemia': 'rgb(215, 77, 38)',
  'Homozygous familial hypercholestrolaemia': 'rgb(139, 70, 255)',
  'Primary prevention of cardiovascular events in patients at high risk of a first cardiovascular event':
    'rgb(24, 191, 255)',
  'Secondary prevention of cardiovascular event': 'rgb(114, 221, 195)',
  'Treatment of acute uncomplicated influenza': 'rgb(255, 214, 110)',
  'Post-exposure prophylaxis of influenza': 'rgb(107, 28, 176)',
  'Pulmonary hypertension': 'rgb(24, 191, 255)',
  'Mixed hyperlipidaemia ': 'rgb(11, 118, 183)',
  'Severe hypertriglyceridaemia': 'rgb(215, 77, 38)',
  Osteoarthritis: 'rgb(6, 160, 155)',
  'Rheumatoid arthritis': 'rgb(68, 68, 68)',
  'Ankylosing spondylitis': 'rgb(255, 111, 44)',
  'Congestive cardiac insufficiency': 'rgb(39, 80, 174)',
  Arrhythmias: 'rgb(208, 240, 253)',
  'Heart Failure': 'rgb(209, 247, 196)',
  'Psoriatic arthritis': 'rgb(255, 220, 229)',
  'Axial spondyloarthritis': 'rgb(24, 191, 255)',
  Psoriasis: 'rgb(255, 234, 182)',
}
