import { Box, BoxProps } from '@chakra-ui/react'

const Panel: React.FC<React.PropsWithChildren<BoxProps>> = ({
  children,
  ...rest
}) => {
  return (
    <Box width='100%' {...rest}>
      {children}
    </Box>
  )
}

const PanelBody: React.FC<React.PropsWithChildren<BoxProps>> = ({
  children,
  p = 4,
  height = '100%',
}) => {
  return (
    <Box p={p} height={height}>
      {children}
    </Box>
  )
}

const PanelHeader: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  return (
    <Box pb={5} pt={3} px={4} lineHeight='none'>
      {children}
    </Box>
  )
}

const PanelTitle: React.FC<React.PropsWithChildren<BoxProps>> = ({
  children,
}) => {
  return (
    <Box
      fontSize='lg'
      fontWeight={600}
      textTransform='capitalize'
      color='legacy-primary.500'
    >
      {children}
    </Box>
  )
}

export { Panel, PanelHeader, PanelBody, PanelTitle }
