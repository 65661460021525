import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios'

import useAxios from 'api/useAxios'

export default function useFeedback(
  extraProps?: UseMutationOptions<
    AxiosResponse<any>,
    AxiosError<any>,
    { context: string; feedback: string }
  >
) {
  const axios = useAxios()

  return useMutation<
    AxiosResponse<any>,
    AxiosError<any>,
    { context: string; feedback: string }
  >(({ context, feedback }) => {
    return sendFeedback(axios, context, feedback)
  }, extraProps)
}

export const sendFeedback = async (
  axios: AxiosInstance,
  context: string,
  feedback: string
) => {
  return await axios
    .post(`/user-llm-feedback/`, { feedback, context })
    .then((x) => x.data)
}
