import {
  Box,
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'
import { useState } from 'react'

import useTracking from 'tracking/useTracking'

type PropTypes = {
  currentViewName: string
  isOpen: boolean
  onClose: () => void
  onRenameUserView: (newTitle: string) => void
}

export default function RenameUserViewModal({
  currentViewName,
  isOpen,
  onClose,
  onRenameUserView,
}: PropTypes) {
  const [tracking] = useTracking()
  const [viewName, setViewName] = useState(currentViewName)

  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader color='primary' fontWeight='600'>
          Rename View
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text color='primary' fontSize='14px' lineHeight='22px'>
            You’re about to rename this view
          </Text>
          <Box mb='24px' />
          <Input
            placeholder='Enter view name'
            value={viewName}
            onChange={(e) => setViewName(e.target.value)}
          />
        </ModalBody>

        <ModalFooter>
          <Button
            variant='yellow'
            onClick={() => {
              tracking.createNewUserView({})
              setViewName('')
              onRenameUserView(viewName)
              onClose()
            }}
          >
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
