import { Box, Text } from '@chakra-ui/react'
import moment from 'moment'

import { CleanResponseData } from 'api/types'

type PropTypes = {
  value: CleanResponseData
  dateFormat?: string
}

const NA = () => {
  return (
    <Box color='gray.600' fontSize='12px'>
      N/A
    </Box>
  )
}

const DateCell = ({ value, dateFormat = 'Do MMM YYYY' }: PropTypes) => {
  let date = value
  let dateParseFormat = 'YYYY-MM-DD'
  let isValid = moment(date, dateParseFormat).isValid()
  let isPast = moment(date, dateParseFormat).isBefore(new Date())

  return isValid ? (
    <Text
      whiteSpace='nowrap'
      display='flex'
      alignItems='center'
      color={isPast ? 'gray.700' : 'gray.900'}
      fontSize='12px'
    >
      {moment(date, dateParseFormat).format(dateFormat)}
    </Text>
  ) : (
    <NA />
  )
}

export default DateCell
