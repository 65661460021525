export default {
  'Pregnant Individuals': 'rgb(207, 223, 255)',
  'High Risk Individuals': 'rgb(208, 240, 253)',
  '0 to 5': 'rgb(194, 245, 233)',
  '5 to 14': 'rgb(209, 247, 196)',
  '65+': 'rgb(255, 234, 182)',
  '6 months and older': 'rgb(254, 226, 213)',
  'Immunocompromised Individuals': 'rgb(255, 220, 229)',
  'Aged 2-10': 'rgb(238, 238, 238)',
  'Aged 65+': 'rgb(32, 201, 51)',
  'Aged 2-15': 'rgb(255, 218, 246)',
  'Aged 50-64': 'rgb(252, 180, 0)',
  'Aged 6 months-5 years': 'rgb(119, 209, 243)',
  'Healthcare Workers': 'rgb(238, 238, 238)',
  'Aged 5-11': 'rgb(249, 157, 226)',
  'Aboriginal people 6 months and older': 'rgb(209, 247, 196)',
  'People with Chronic Illness': 'rgb(207, 223, 255)',
  'Aged 60+': 'rgb(205, 176, 255)',
  'People with frequent public contacts': 'rgb(147, 224, 136)',
  'People and staff cared for in community facilities': 'rgb(32, 201, 51)',
  'patients aged >6 months with underlying chronic conditions (pulmonary, cardiac, hepatic, renal, metabolic, BMI >35, neuromuscular, immune disorders)':
    'rgb(139, 70, 255)',
  'people in instituational care': 'rgb(255, 158, 183)',
  'children aged 6 months to 18 years undergoing long-term aspirin therapy':
    'rgb(255, 8, 194)',
  'people living with at-risk people': 'rgb(147, 224, 136)',
  'children under 6 months': 'rgb(147, 224, 136)',
  'aged 50-65': 'rgb(32, 217, 210)',
  'people with chronic conditions (cardiac, hepatic, renal, metabolic, immune disorders)':
    'rgb(255, 111, 44)',
  'people providing care to high-risk individuals': 'rgb(238, 238, 238)',
  'people in contact with high-risk individuals': 'rgb(254, 226, 213)',
  'Aged 2-6': 'rgb(147, 224, 136)',
  'People Considered Obese': 'rgb(255, 220, 229)',
  'Close family and friends of people who are immunocompromised':
    'rgb(119, 209, 243)',
  'Close family and friends of infants under 6 months of age with high risk factors':
    'rgb(249, 157, 226)',
  'People living in care homes': 'rgb(207, 223, 255)',
  'People in direct contact with poultry and wild birds': 'rgb(249, 157, 226)',
  'Transplant patients': 'rgb(255, 169, 129)',
  'Aged 2-17': 'rgb(238, 238, 238)',
  'People with Morbid Obesity': 'rgb(255, 158, 183)',
  'Children on long term aspirin therapy': 'rgb(248, 43, 96)',
  'People with Down syndrome': 'rgb(204, 204, 204)',
  'People in regular contact with pigs, poultry or waterfowl':
    'rgb(194, 245, 233)',
  'Those with a condition that can compromise respiratory function':
    'rgb(237, 226, 254)',
  'Aged 60-64 with dsyfunctions of the heart, kidney, or respiratory organs':
    'rgb(255, 8, 194)',
  'Aged 60-64 with HIV': 'rgb(45, 127, 249)',
  'Children with chronic conditions': 'rgb(237, 226, 254)',
  'HIV+ individuals': 'rgb(156, 199, 255)',
  'Aged under 65 with chronic conditions': 'rgb(255, 220, 229)',
  'Aged 4 or under with a history of respiratory illness': 'rgb(255, 218, 246)',
  'Premature babies aged 6 months to 5 years': 'rgb(178, 21, 139)',
  'Pig breeders': 'rgb(39, 80, 174)',
  '6 months-13 years': 'rgb(156, 199, 255)',
  'Essential Workers (Police, Firemen)': 'rgb(237, 226, 254)',
  'Infants under 2 who were born prematurely': 'rgb(51, 138, 23)',
  'people in regular contact with high risk individuals': 'rgb(39, 80, 174)',
  'Adults and children (>6 months) with certain medical conditions':
    'rgb(255, 220, 229)',
  'Aged 18+': 'rgb(114, 221, 195)',
  Carers: 'rgb(32, 217, 210)',
  'Aged 15-64': 'rgb(114, 221, 195)',
  'State security forces': 'rgb(11, 118, 183)',
  'Aged 6 months -2 years': 'rgb(254, 226, 213)',
  'Other Emergency Services': 'rgb(207, 223, 255)',
  'Aged 6 months-12 years': 'rgb(39, 80, 174)',
  'People living in social care and nursing institutions': 'rgb(51, 138, 23)',
  'Aged 2-18 with certain medical conditions': 'rgb(255, 218, 246)',
  Newborns: 'rgb(24, 191, 255)',
  'Livestock Workers': 'rgb(156, 199, 255)',
  'Aged 0-5': 'rgb(194, 245, 233)',
  'Children under 7': 'rgb(204, 204, 204)',
  'Conscripts beginning military service': 'rgb(6, 160, 155)',
  '6 months-6 years': 'rgb(207, 223, 255)',
  'Aged 60-64': 'rgb(102, 102, 102)',
  Veterinarians: 'rgb(139, 70, 255)',
  'Blood Donors': 'rgb(32, 201, 51)',
  'Employees of social care centres': 'rgb(238, 238, 238)',
  'Homeless People': 'rgb(255, 158, 183)',
  'Aged 6 months-5th Grade': 'rgb(114, 221, 195)',
  'Premature babies': 'rgb(255, 220, 229)',
  'Workers in preschool and school education up to 5th grade':
    'rgb(255, 111, 44)',
  'Sex Workers': 'rgb(147, 224, 136)',
  'Armed Forces': 'rgb(252, 180, 0)',
  'Ministry of Energy Workers': 'rgb(6, 160, 155)',
  'Carriers (public transport, buses, trucks, ports, railways)':
    'rgb(184, 117, 3)',
  'People residing in extreme areas': 'rgb(156, 199, 255)',
  'Garbage collectors': 'rgb(255, 8, 194)',
  'Aged 40+': 'rgb(255, 158, 183)',
  'Aged 0-11': 'rgb(207, 223, 255)',
  'Aged 0-8': 'rgb(102, 102, 102)',
  Educators: 'rgb(209, 247, 196)',
  'Aged 58+': 'rgb(156, 199, 255)',
  'Aged 55+': 'rgb(45, 127, 249)',
  'Aged 6 months-18 years': 'rgb(68, 68, 68)',
  Firemen: 'rgb(32, 217, 210)',
  'Aged 6-59 months': 'rgb(208, 240, 253)',
  'Aged 2-12': 'rgb(51, 138, 23)',
  'Aged 6 months-6 years': 'rgb(147, 224, 136)',
  'Aged 5-6': 'rgb(51, 138, 23)',
  'Early Retirees': 'rgb(204, 204, 204)',
  'Aged 6 Months - 4 Years': 'rgb(6, 160, 155)',
  'Aged 6+ months': 'rgb(215, 77, 38)',
  'Aged 0-6 months': 'rgb(184, 117, 3)',
  'Aged 0-7': 'rgb(255, 234, 182)',
  'Aged 6 months-13 years': 'rgb(178, 21, 139)',
  'Aged 50+': 'rgb(119, 209, 243)',
  'Aged 6 months-3 years': 'rgb(255, 8, 194)',
  'Aged 6 months-5 yeard': 'rgb(45, 127, 249)',
  'Aged 6 months-10 years': 'rgb(204, 204, 204)',
  'Aged 6 months-4 years': 'rgb(209, 247, 196)',
  'Aged 6 months-2 years': 'rgb(255, 158, 183)',
}
