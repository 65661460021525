import { PoolRouterOptions } from 'PoolRouter'
import React, { useContext } from 'react'

enum ClientType {
  normal,
  government,
}

type ClientTypeProviderT = {
  type: ClientType
  isGov: boolean
}

export const ClientTypeContext = React.createContext<ClientTypeProviderT>({
  type: ClientType.normal,
  isGov: false,
})

export const useClientType = (): ClientTypeProviderT => {
  return useContext(ClientTypeContext)
}

export const ClientTypeConsumer = ClientTypeContext.Consumer

function getPoolFromQueryParams() {
  const searchParams = new URLSearchParams(window.location.search)
  let poolQuery = searchParams.get('pool')
  const stateQuery = searchParams.get('state')
  if (!poolQuery && stateQuery) {
    const decoded = decodeURI(stateQuery)
    const searchParams = new URLSearchParams(decoded)
    poolQuery = searchParams.get('pool')
  }
  return poolQuery
}

export function getSelectedPool() {
  const poolQuery = getPoolFromQueryParams()
  const pool = window.sessionStorage.getItem('pool')
  const selectedPool = PoolRouterOptions.find(
    (option) => option.id === poolQuery || option.id === pool
  )
  return selectedPool
}

export function hasGovPool() {
  const availablePools = PoolRouterOptions.map((option) => option.id)
  return availablePools.includes(getSelectedPool()?.id || '')
}

export function getGovConfig() {
  if (hasGovPool()) {
    return getSelectedPool()?.config
  }
  return null
}

export function getIsGov() {
  const domain = window.location.host
  if (domain.includes('gov')) return true
  return false
}

export const ClientTypeProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  let type = ClientType.normal

  if (getIsGov()) type = ClientType.government

  return (
    <ClientTypeContext.Provider
      value={{ type, isGov: type === ClientType.government }}
    >
      {children}
    </ClientTypeContext.Provider>
  )
}
