import { UseQueryResult } from '@tanstack/react-query'
import _ from 'lodash'
import {
  Dispatch,
  SetStateAction,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react'
import { TableState } from 'react-table'

import { SelectOption } from 'components/Select'

import { UserView } from 'api/types'

type PropTypes = {
  userViewConfig?: UserViewConfig
  setState: (state: any) => void
  stateIsReady: boolean
}

export type UserViewConfig = {
  userViewQuery: UseQueryResult<UserView[]>
  createUserView: (newView: Pick<UserView, 'name' | 'view'>) => any
  deleteUserView: (id: number) => any
  updateUserView: (id: number, newView: Partial<Omit<UserView, 'id'>>) => any
  initialSelectedUserViewId: number | null
  onUserViewIndexChange?: (index: number | null) => void
}

export type UseUserViewsReturn = {
  selectedUserViewIndex: number
  selectedUserViewIndexRaw: number | null
  setSelectedUserViewIndex: Dispatch<SetStateAction<number | null>>
  userViewSelected: boolean
  stateIsReady: boolean
}

type SerializeStateType = TableState<any> & {
  dropdowns?: {
    stackBy: SelectOption<string>
    transformBy: SelectOption<string>
  }
}

export const serializeState = (state: SerializeStateType) => {
  return {
    ...Object.fromEntries(
      Object.entries(_.cloneDeep(state)).filter(([key, val]) =>
        [
          // List of states to persist
          'advancedFilters',
          'columnOrder',
          'hiddenColumns',
          'sortBy',
          'dropdowns',
        ].includes(key)
      )
    ),
  }
}

export default function useUserViews({
  userViewConfig,
  setState,
  stateIsReady,
}: PropTypes): UseUserViewsReturn {
  const {
    userViewQuery,
    initialSelectedUserViewId,
    onUserViewIndexChange = () => {},
  } = userViewConfig ?? {
    userViewQuery: { data: undefined },
    initialSelectedUserViewId: 0,
    onUserViewIndexChange: () => {},
  }

  const { data: userViews } = userViewQuery

  // Initialize with null so that we can differentiate if the data is loaded
  const [selectedUserViewIndexRaw, setSelectedUserViewIndex] = useState<
    number | null
  >(null)
  const selectedUserViewIndex = selectedUserViewIndexRaw ?? 0

  // After a refactor, we don't show the default userView anymore
  // However, we're keeping the 0 index for now. If it is 0, it just means that no user view is selected
  const userViewSelected = selectedUserViewIndex > 0

  // Handle initial load of selected user view
  useEffect(() => {
    // On remote loaded but local still null
    if (selectedUserViewIndexRaw === null && !!userViews) {
      // Set it to the selected user view
      const userViewIndex = userViews?.findIndex(
        (x) => x.id === initialSelectedUserViewId
      )
      // Otherwise we choose the first item (The default/original view)
      setSelectedUserViewIndex(userViewIndex === -1 ? 0 : userViewIndex)
    }
  }, [selectedUserViewIndexRaw, initialSelectedUserViewId, userViews])

  // Callback event on index change
  useEffect(() => {
    if (!!userViews && userViews.length > (selectedUserViewIndexRaw ?? 0)) {
      onUserViewIndexChange(userViews[selectedUserViewIndexRaw ?? 0].id)
    }
  }, [onUserViewIndexChange, selectedUserViewIndexRaw, userViews])

  // Set selected view to last item if selected is bigger than total
  useEffect(() => {
    if (!!userViews && userViews.length <= selectedUserViewIndex) {
      setSelectedUserViewIndex(userViews.length - 1)
    }
  }, [userViews, selectedUserViewIndex])

  // Update the state of the table depending on the user selected view
  useLayoutEffect(() => {
    if (!!userViews) {
      setState(userViews[selectedUserViewIndex]?.view)
    }
  }, [userViews, selectedUserViewIndex, setState])

  if (!userViewConfig) {
    return {
      selectedUserViewIndex: 0,
      selectedUserViewIndexRaw: null,
      setSelectedUserViewIndex: () => {},
      userViewSelected: false,
      stateIsReady: false,
    }
  }

  return {
    selectedUserViewIndex,
    selectedUserViewIndexRaw,
    setSelectedUserViewIndex,
    userViewSelected,
    stateIsReady,
  }
}
