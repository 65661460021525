import { IModel } from 'interfaces/model.interface'
import { ValueType } from 'interfaces/valueType.interface'

export type VaccinesUnderReviewData =
  | 'vaccineFromLinkCandidateList'
  | 'regulatoryBody'
  | 'status'
  | 'source'
  | 'companiesFromLinkCandidateList'
  | 'researchInstitutionFromLinkCandidateList'
const allowedAirtables = ['api'] as const

export const VaccinesUnderReviewModel: IModel<
  Record<VaccinesUnderReviewData, string>,
  typeof allowedAirtables
> = {
  allowedAirtables,
  displayKey: 'vaccineFromLinkCandidateList',
  name: 'Vaccines Under Review',
  entityName: 'Vaccines Under Review',
  endpoint: 'vaccines-under-review',
  detailViewType: 'Generic',
  searchField: 'vaccineFromLinkCandidateList',
  defaultSortKey: 'vaccineFromLinkCandidateList',
  schema: {
    columns: [
      {
        key: 'vaccineFromLinkCandidateList',
        label: 'Vaccine',
        width: 200,
        type: ValueType.MULTI,
      },
      {
        key: 'status',
        label: 'Regulatory Status',
        width: 200,
        type: ValueType.TEXT,
      },
      {
        key: 'regulatoryBody',
        label: 'Regulatory Body',
        width: 200,
        type: ValueType.TEXT,
      },
      {
        key: 'companiesFromLinkCandidateList',
        label: 'Companies',
        width: 200,
        type: ValueType.MULTI,
      },
      {
        key: 'researchInstitutionFromLinkCandidateList',
        label: 'Research Institutions',
        width: 200,
        type: ValueType.MULTI,
      },
      {
        key: 'source',
        label: 'Source',
        width: 200,
        type: ValueType.URL,
      },
    ],
  },
}
