function numberWithCommas(x: any) {
  if (x !== null && x !== undefined) {
    return parseFloat(x).toLocaleString()
  } else {
    return null
  }
}

export const compactNumberWithPrefix = (num: number, digits: number = 1) => {
  return Intl.NumberFormat('en-US', {
    notation: 'compact',
    maximumFractionDigits: digits,
  }).format(Number(num))
}

export default numberWithCommas
