import { Box, Button, Flex, Link, SlideFade, Spinner } from '@chakra-ui/react'
import _ from 'lodash'
import { memo, useCallback, useState } from 'react'
import { ArrowContainer, Popover } from 'react-tiny-popover'
import TweetEmbed from 'react-tweet-embed'

import { InlineLoading } from 'components'

import { WidgetComponentPropsTypes } from 'interfaces/widget.interface'

import useTracking from 'tracking/useTracking'

import { formatDate } from 'utils/formatDate'

import { useWidgetData } from '.'

type CustomProp<TData extends string> = {
  tweet: TData
  createdAt: TData
  type: TData
  user: TData
  url: TData
}

const EmbeddedTweet = memo(
  ({ tweetId, onTweetLoad }: { tweetId: string; onTweetLoad: () => void }) => {
    return (
      <TweetEmbed
        tweetId={tweetId}
        onTweetLoadSuccess={onTweetLoad}
        placeholder={
          <Box display='flex' height='100%'>
            <Spinner margin='auto' />
          </Box>
        }
      />
    )
  }
)

const Tweet = memo(
  ({
    url,
    createdAt,
    type,
    tweet,
    user,
  }: {
    url: string
    createdAt: string
    type: string | string[]
    tweet: string
    user: string
  }) => {
    const splittedUrl = url?.split('/')
    const tweetId = splittedUrl ? splittedUrl[splittedUrl.length - 1] : null

    const [isHovered, setIsHovered] = useState(false)
    // Delay opening the preview window by some time
    const debouncedHover = _.debounce(() => setIsHovered(true), 500)

    const [tweetLoaded, setTweetLoaded] = useState(false)

    const onTweetLoad = useCallback(() => {
      setTweetLoaded(true)
    }, [setTweetLoaded])

    return (
      <Box
        onMouseEnter={() => debouncedHover()}
        onMouseLeave={() => {
          debouncedHover.cancel()
          setIsHovered(false)
        }}
      >
        <Popover
          isOpen={isHovered}
          positions={['right', 'left', 'bottom', 'top']}
          content={({ position, childRect, popoverRect }) =>
            !tweetId ? (
              <></>
            ) : (
              <SlideFade in={true} onClick={(e) => e.stopPropagation()}>
                <ArrowContainer
                  position={position}
                  childRect={childRect}
                  popoverRect={popoverRect}
                  arrowColor='lightgrey'
                  arrowSize={10}
                  arrowStyle={{
                    opacity: 0.7,
                  }}
                >
                  <Box
                    bgColor='white'
                    height='20vh'
                    boxShadow='base'
                    // Follows default tweet style
                    width='325px'
                    marginTop='-10px'
                    borderRadius={15}
                  >
                    {!tweetLoaded && (
                      <Box bg='gray.200' display='flex' height='100%'>
                        <Spinner margin='auto' />
                      </Box>
                    )}
                    <Box
                      {...(tweetLoaded
                        ? {}
                        : { width: 0, height: 0, opacity: 0 })}
                    >
                      <EmbeddedTweet
                        key={tweetId}
                        tweetId={tweetId}
                        onTweetLoad={onTweetLoad}
                      />
                    </Box>
                  </Box>
                </ArrowContainer>
              </SlideFade>
            )
          }
        >
          <Link
            display='flex'
            flexDir='column'
            px={4}
            href={url ?? '#'}
            target='_blank'
            py={3}
            width='100%'
            fontWeight={500}
            fontSize='sm'
            lineHeight='1.4'
            borderBottom='1px solid'
            borderColor='gray.100'
            _hover={{
              bg: 'gray.300',
              textDecoration: 'none',
            }}
          >
            <Box
              textTransform='uppercase'
              fontSize='xs'
              color='gray.600'
              lineHeight='none'
              mb={1}
            >
              {formatDate(createdAt)}
            </Box>
            <Box color='gray' mb={2} fontSize='xs'>
              {Array.isArray(type) ? (type as string[]).join(', ') : type}
            </Box>
            <Box mb={1}>{tweet}</Box>
            <Box fontSize='sm' color='gray.700'>
              {user}
            </Box>
          </Link>
        </Popover>
      </Box>
    )
  }
)

export default function WidgetComponentTweet<TData extends string>({
  maxItems,
  model,
  view,
  tweet,
  createdAt,
  type,
  user,
  url,
}: WidgetComponentPropsTypes & CustomProp<TData>) {
  const [tracking] = useTracking()
  const { isLoading, data, canFetchMore, fetchMore, isFetchingNextPage } =
    useWidgetData({
      maxItems,
      model,
      view,
    })

  if (isLoading || !data) {
    return <InlineLoading />
  }
  return (
    <>
      {data.map((row, i) => (
        <Tweet
          key={`tweet_${i}`}
          url={row[url] as string}
          createdAt={row[createdAt] as string}
          type={row[type] as string}
          tweet={row[tweet] as string}
          user={row[user] as string}
        />
      ))}
      {canFetchMore && (
        <Flex p='1rem' w='100%' justifyContent='center'>
          <Button
            variant='outline'
            onClick={() => {
              tracking.loadMoreItems({ section: view.airtableName })
              fetchMore()
            }}
          >
            {isFetchingNextPage ? <Spinner /> : 'Load More'}
          </Button>
        </Flex>
      )}
    </>
  )
}
