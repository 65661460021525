import { Box, Flex, Text } from '@chakra-ui/react'

import { ItemComponentPropTypes } from 'modules/DetailDrawer'

import { IDTreatments360RevenueData } from 'config/common/baseModel/ida360/IDPremiumRevenue'

const DetailDrawerListRevenue = ({
  item,
  handleSelect,
}: ItemComponentPropTypes<Record<IDTreatments360RevenueData, any>>) => {
  const {
    organisations,
    financialQuarter,
    valueUsd,
    areas,
    diseases,
    rssArticles,
  } = item

  return (
    <Box
      px={8}
      py={4}
      borderBottom='1px solid'
      borderColor='gray.100'
      cursor='pointer'
      onClick={() => handleSelect(item)}
      _hover={{
        bg: 'gray.100',
      }}
    >
      <Box fontSize='base' fontWeight='500'>
        {organisations}
      </Box>
      <Text fontSize='xs' fontWeight={600} color='gray.700'>
        Financial Quarter: {financialQuarter || 'N/A'}
      </Text>
      <Text fontSize='xs' fontWeight={600} color='gray.700'>
        Diseases: {diseases || 'N/A'}
      </Text>
      <Text fontSize='xs' fontWeight={600} color='gray.700'>
        Revenue (USD):{valueUsd || 'N/A'}
      </Text>
      <Text fontSize='xs' fontWeight={600} color='gray.700'>
        Location: {areas || 'N/A'}
      </Text>
      <Flex display='flex' justifyContent='space-between' mb={2}>
        <Text fontSize='xs' fontWeight={600} color='gray.700'>
          Source:{' '}
          {(rssArticles as any[])?.map((article: any) => {
            return (
              <Box as='span' fontSize='xs' fontWeight={500} width='100%'>
                {(article as any).title}
              </Box>
            )
          })}
        </Text>
      </Flex>
    </Box>
  )
}

export { DetailDrawerListRevenue }
