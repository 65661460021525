import { extendTheme } from '@chakra-ui/react'

import Button from './Button'
import Checkbox from './Checkbox'
import Heading from './Heading'
import Spinner from './Spinner'
import Text from './Text'

const theme = extendTheme({
  components: {
    Button,
    Spinner,
    Checkbox,
    Heading,
    Text,
    CloseButton: {
      baseStyle: {
        _focus: {
          boxShadow: 'none',
        },
      },
    },
    Modal: {
      baseStyle: {
        dialog: {
          borderRadius: '8px',
        },
        closeButton: {
          borderRadius: '4px',
          _focus: {
            boxShadow: 'none',
          },
        },
      },
    },
    Tabs: {
      baseStyle: {
        tab: {
          _focus: {
            boxShadow: 'none',
          },
        },
      },
      variants: {
        custom: {
          tab: {
            px: 3,
            fontWeight: 500,
            fontSize: 'sm',
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
            _hover: {
              background: 'yellow.500',
              borderColor: 'yellow.500',
              transition: 'all 0.2s',
            },
            textOverflow: 'ellipsis',
            borderWidth: 1,
            cursor: 'pointer',
            color: 'primary',
            borderRadius: '4px',
            borderColor: 'primary',
            _selected: {
              borderColor: 'yellow.500',
              backgroundColor: 'yellow.500',
            },
          },
        },
        line: {
          tab: {
            color: 'primary',
            fontWeight: 400,
            _selected: {
              color: 'primary',
              fontWeight: 600,
              borderColor: 'secondary',
            },
          },
        },
      },
    },
    Link: {
      baseStyle: {
        _focus: {
          boxShadow: 'none',
        },
      },
    },
  },
  colors: {
    // Colors according to style guide
    primary: '#0B0B0B',
    secondary: '#FFED00',
    secondaryLight: '#FFF9A6',
    secondaryLightest: '#FFFCCE',

    gray1: '#575756',
    gray2: '#A8A8A8',
    gray3: '#E5E5E5',
    gray4: '#F6F6F6',
    gray5: '#FAFAFA',

    error: '#EE4E6B',
    success: '#53B869',
    warning: '#F9B635',

    interactive: '#0f62fe',

    iqtBlack: '#0C031F',
    iqtBlue: '#027BFC',

    data1: '#000000',
    data2: '#046A6A',
    data3: '#2DC5C5',
    data4: '#FF6DB6',
    data5: '#FFB6DB',
    data6: '#7025BB',
    data7: '#006DDB',
    data8: '#B66DFF',
    data9: '#6DB6FF',
    data10: '#9BF47B',
    data11: '#F0D026',
    data12: '#924900',
    data13: '#DB6D00',
    data14: '#B4895F',
    data15: '#00B1FF',

    // Legacy
    brand: '#1f1f1f',
    brandSecondary: '#151515',
    black: '#0B0B0B',
    blue: {
      '50': '#f2f6fc',
      '100': '#e6edf8',
      '200': '#c0d1ee',
      '300': '#99b5e3',
      '400': '#4d7ecf',
      '500': '#0147ba',
      '600': '#0140a7',
      '700': '#01358c',
      '800': '#012b70',
      '900': '#00235b',
    },
    'legacy-primary': {
      '50': '#f4f4f4',
      '100': '#e9e9e9',
      '200': '#c7c7c7',
      '300': '#a5a5a5',
      '400': '#626262',
      '500': '#1f1f1f',
      '600': '#1c1c1c',
      '700': '#171717',
      '800': '#131313',
      '900': '#0f0f0f',
    },
    gray: {
      '50': '#FAFAFA',
      '100': '#F6F6F6',
      '200': '#E5E5E5',
      '300': '#e3e3e3',
      '400': '#A8A8A8',
      '500': '#575756',
      '600': '#a6a6a6',
      '700': '#8a8a8a',
      '800': '#6e6e6e',
      '900': '#5a5a5a',
    },
    yellow: {
      '50': '#FFFDE5',
      '100': '#FFFAB8',
      '200': '#FFF78A',
      '300': '#FFF35C',
      '400': '#FFF02E',
      '500': '#FFED00',
      '600': '#CCBE00',
      '700': '#998E00',
      '800': '#665F00',
      '900': '#332F00',
    },
  },
  fontSizes: {
    xs: '12px',
    sm: '14px',
    md: '16px',
    base: '12px',
    lg: '18px',
    xl: '20px',
    '2xl': '24px',
    '3xl': '28px',
    '4xl': '36px',
    '5xl': '48px',
    '6xl': '64px',
  },
  radii: {
    none: '0px',
    sm: '3px',
    md: '5px',
    lg: '8px',
    full: '9999px',
  },
  fonts: {
    heading:
      "IBM Plex Sans, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'",
    body: "IBM Plex Sans, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'",
    mono: `SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace`,
  },
  boxShadow: {
    left: '-11px 0px 49px -14px rgba(32,32,32,0.57)',
    xl: 'rgba(0, 0, 0, 0.35) 0px 5px 15px;',
  },
})

export default theme
