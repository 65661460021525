import { UseQueryOptions, useQuery } from '@tanstack/react-query'
import { AxiosError, AxiosInstance } from 'axios'

import { apps, appsListUnion } from 'config/apps'

import { recursiveCamelCase } from './helper'
import { ReportSearchType } from './types'
import useAxios from './useAxios'

export default function useSearchReports(
  app: appsListUnion,
  searchString: string,
  extraProps?: UseQueryOptions<any, any>,
  hasReports = true
) {
  const axios = useAxios()

  const res = useQuery<ReportSearchType[], AxiosError>(
    ['searchReports', app, searchString],
    () => searchReports(axios, app, searchString),
    { enabled: searchString !== '', ...extraProps }
  )

  return hasReports ? res : { data: [], isLoading: false, error: false }
}

export const searchReports = async (
  axios: AxiosInstance,
  app: appsListUnion,
  searchString: string
): Promise<ReportSearchType[]> => {
  return axios
    .get(
      `/${apps[app].endpoint}/analyst-updates-search/?search_term=${searchString}`
    )
    .then((res) => recursiveCamelCase(res.data))
}
