import { Checkmark } from '@carbon/icons-react'
import { Box, Heading, List, ListItem, Text } from '@chakra-ui/react'
import { useContext, useState } from 'react'

import { AuthContext } from 'contexts'

import useUserChangePasswordMutation from 'api/useUserChangePasswordMutation'

import PasswordsForm from './PasswordsForms'
import { getIsPasswordValid, validatePassword } from './helpers'

type PasswordRuleType = { title: string; isValid: boolean }
interface PasswordRulesPropTypes {
  passwordValidityInfo: PasswordRuleType[]
}

const PasswordRules = ({ passwordValidityInfo }: PasswordRulesPropTypes) => {
  return (
    <List spacing={2} fontSize='xs' mt={3}>
      <Text as='span'>New password must contain:</Text>
      {passwordValidityInfo.map((validity, index) => {
        return (
          <ListItem display='flex' alignItems='center' key={index}>
            {validity.isValid && <Checkmark size={16} color={'green'} />}
            <Text
              color={validity.isValid ? 'gray.900' : 'gray.600'}
              ml={2}
              as='span'
            >
              {validity?.title}
            </Text>
          </ListItem>
        )
      })}
    </List>
  )
}

const passwordDefaultState = {
  newPassword: '',
  oldPassword: '',
  newRePassword: '',
}

const SecurityPage = () => {
  const { userInfo } = useContext(AuthContext)

  const [passwords, setPasswords] = useState(passwordDefaultState)
  const apiStatus = useUserChangePasswordMutation({
    onSuccess: () => setPasswords(passwordDefaultState),
  })
  const changePassword = apiStatus.mutate

  const { newPassword, oldPassword, newRePassword } = passwords

  const passwordValidityInfo = validatePassword(
    newPassword,
    userInfo?.name,
    newRePassword
  )
  const isFormValid = getIsPasswordValid(passwordValidityInfo)

  const handleChangePassword = () => {
    if (isFormValid) {
      changePassword({
        oldPassword,
        newPassword,
      })
    }
  }

  return (
    <Box px={10} py={10}>
      <Box px={8} py={8} borderWidth='1px' maxW='100%' bg={'white'}>
        <Heading color={'legacy-primary.900'} size='lg' mb={1}>
          Change Password
        </Heading>
        <Box mb={5}>
          <Text as='span'>
            Choose a strong password and don't reuse old passwords
          </Text>
        </Box>
        <PasswordsForm
          setPassword={setPasswords}
          handleChangePassword={handleChangePassword}
          apiStatus={apiStatus}
          passwords={passwords}
          isFormValid={isFormValid}
        />
        <PasswordRules passwordValidityInfo={passwordValidityInfo} />
      </Box>
    </Box>
  )
}

export default SecurityPage
