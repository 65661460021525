import { Box } from '@chakra-ui/react'

import FullReportPage from 'routes/common/FullReportPage'

import { FlourishEmbed, PageHeader } from 'components'

import { ISingleCustomPage } from 'interfaces/navigationPage.interface'

import { CardioConferenceSlugs } from './CardioConferenceMenu'

const Reports: ISingleCustomPage<CardioConferenceSlugs> = {
  path: '',
  component: () => (
    <Box>
      <Box px={3} py={3}>
        <PageHeader title={'Methodology and SSoV scores'} />
        <FlourishEmbed type='story' id='1115796' />
      </Box>
      <FullReportPage showCategory={false} />
    </Box>
  ),
}

export const CardioConferenceCustomPages: Record<
  string,
  ISingleCustomPage<CardioConferenceSlugs>
> = {
  Reports,
}
