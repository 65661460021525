import { Maximize } from '@carbon/icons-react'
import { Box, Button, Card, Spinner } from '@chakra-ui/react'
import useKeyPress from 'utils/useKeyPress'

type CardContainerProps = {
  children: React.ReactNode
  loading: boolean
  expanded: boolean
  setExpanded: React.Dispatch<React.SetStateAction<boolean>>
  className: string
}

const CardContainer = ({
  children,
  loading,
  expanded,
  setExpanded,
  className,
}: CardContainerProps) => {
  useKeyPress('Escape', () => {
    if (expanded) setExpanded(false)
  })
  if (expanded) {
    return (
      <>
        <Box
          onClick={() => setExpanded(false)}
          p='1rem'
          position={'absolute'}
          bgColor={'black'}
          top={0}
          left={0}
          zIndex={100}
          width='100%'
          opacity={'50%'}
          height='calc(100%)'
          rounded='lg'
        />
        <Card
          p='1rem'
          position={'absolute'}
          top={'20px'}
          left={5}
          zIndex={110}
          width='calc(100% - 40px)'
          height='calc(100% - 40px)'
        >
          {loading ? (
            <Spinner
              position={'absolute'}
              zIndex={20}
              top={1}
              right={1}
              ml={2}
              size={'sm'}
            />
          ) : (
            <ToggleButton expanded={expanded} setExpanded={setExpanded} />
          )}
          {children}
        </Card>
      </>
    )
  }

  return (
    <Card
      border='1px'
      borderColor={'gray.200'}
      p={2}
      w={'280px'}
      h='full'
      shadow={'none'}
      position={'relative'}
      className={className}
    >
      {loading ? (
        <Spinner
          position={'absolute'}
          zIndex={20}
          top={1}
          right={1}
          ml={2}
          size={'sm'}
        />
      ) : (
        <ToggleButton expanded={expanded} setExpanded={setExpanded} />
      )}
      {children}
    </Card>
  )
}

export default CardContainer

const ToggleButton = ({
  expanded,
  setExpanded,
}: Pick<CardContainerProps, 'expanded' | 'setExpanded'>) => (
  <Button
    onClick={() => setExpanded(!expanded)}
    position='absolute'
    zIndex={10}
    top={1}
    right={1}
    opacity={0.5}
    _hover={{ opacity: 1 }}
    variant='solid'
    size='xs'
    px={2}
    rounded='md'
    fontSize='12px'
  >
    <Maximize size={12} />
  </Button>
)
