import { INavigationMenu } from 'interfaces/navigationMenu.interface'

export type IEGPolicySurveillanceSlugs = '' | 'all-news'

const IEGPolicySurveillanceMenu: Array<
  INavigationMenu<IEGPolicySurveillanceSlugs>[]
> = [
  [
    {
      title: 'Dashboard',
      slug: '',
      description: '',
      createdAt: new Date(2022, 1, 28),
    },
    {
      title: 'All News',
      slug: 'all-news',
      description: '',
      createdAt: new Date(2022, 1, 28),
    },
  ],
]

export { IEGPolicySurveillanceMenu }
