import { ValueType } from 'interfaces/valueType.interface'

import { ApprovalsModel } from 'config/common/baseModel/Approvals'

import {
  compose,
  renameColumn,
  updateColumnAttributes,
  whiteListColumn,
} from 'utils/overrideModel'

export const ObesityApprovalModel = compose<typeof ApprovalsModel>(
  whiteListColumn([
    'luCandidateName',
    'linkCompany',
    'technologyTypeFromLinkCandidates',
    'target',
    'luRouteOfAdministration',
    'regulatoryBody',
    'linkCountry',
    'approvalType',
    'expeditedStatusDesignation',
    'approvalDate',
    'approvalLabelPopulationGeneral',
    'approvalLabelInfo',
    'associatedDocumentUrl',
    'approvalLabel',
  ]),
  updateColumnAttributes('approvalLabelPopulationGeneral', {
    type: ValueType.MULTI,
    width: 150,
  }),
  renameColumn('luCandidateName', 'Candidate'),
  renameColumn('regulatoryBody', 'Regulatory Body')
)(ApprovalsModel)
