import { Heading, Box } from '@chakra-ui/react'

import { CandidateRankingData } from 'config/common/baseModel'

import { ItemComponentPropTypes } from '.'

export const DetailDrawerListCandidate = ({
  item,
  handleSelect,
}: ItemComponentPropTypes<Record<CandidateRankingData, any>>) => {
  const { name } = item

  return (
    <Box
      px={8}
      py={4}
      cursor='pointer'
      borderBottom='1px solid'
      borderColor='gray.100'
      onClick={() => handleSelect(item)}
      _hover={{
        bg: 'gray.100',
      }}
    >
      <Heading fontSize='md' fontWeight={500} mt={1}>
        {name}
      </Heading>
    </Box>
  )
}
