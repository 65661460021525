import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios'

import useAxios from 'api/useAxios'

export default function useAppUpgradeMutation(
  extraProps?: UseMutationOptions<
    AxiosResponse<any>,
    AxiosError<any>,
    { appEndpoint: string }
  >
) {
  const axios = useAxios()

  return useMutation<
    AxiosResponse<any>,
    AxiosError<any>,
    { appEndpoint: string; url: string; page: string; subpage: string }
  >(({ appEndpoint, url, page, subpage }) => {
    return appUpgrade(axios, appEndpoint, url, page, subpage)
  }, extraProps)
}

export const appUpgrade = async (
  axios: AxiosInstance,
  appEndpoint: string,
  url: string,
  page: string,
  subpage: string
) => {
  return await axios
    .post(`${appEndpoint}/upgrade/`, { url, page, subpage })
    .then((x) => x.data)
}
