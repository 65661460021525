import { Box, Flex, Text } from '@chakra-ui/react'
import { isValid } from 'date-fns'
import { CellProps, Column, Row } from 'react-table'

import { BasicTable, InlineError } from 'components'

import countryCodeToNameMapping from 'api/data/countryCodeToNameMapping'
import { CountrySummary, useCountrySummary } from 'api/useCountrySummary'

import { calculateStatColor } from 'utils/calculateStatColor'
import { formatUtc } from 'utils/formatDate'

import { StatsCell, StatsCellPropTypes } from './StatsCell'

type Unit = 'hundredThousand' | 'million'

const mapData = (countrySummaryData: CountrySummary[], unit: Unit) => {
  return countrySummaryData.map((d) => ({
    country: countryCodeToNameMapping[d.countryCode],
    weeklyInfections: {
      totalCount:
        unit === 'hundredThousand'
          ? d.summaryData.weeklyCasesPerHundredThousand
          : d.summaryData.weeklyCasesPerMillion,
      deltaCount:
        unit === 'hundredThousand'
          ? d.summaryData.changeInWeeklyCasesPerHundredThousand
          : d.summaryData.changeInWeeklyCasesPerMillion,
    },
    weeklyHospitalisations: {
      totalCount:
        unit === 'hundredThousand'
          ? d.summaryData.weeklyHospitalAdmissionsPerHundredThousand
          : d.summaryData.weeklyHospitalAdmissionsPerMillion,
      deltaCount:
        unit === 'hundredThousand'
          ? d.summaryData.changeInHospitalAdmissionsPerHundredThousand
          : d.summaryData.changeInHospitalAdmissionsPerMillion,
      lastUpdated: d.summaryData.lastUpdatedHospitalAdmissions
        ? new Date(d.summaryData.lastUpdatedHospitalAdmissions)
        : null,
    },
    weeklyDeaths: {
      totalCount:
        unit === 'hundredThousand'
          ? d.summaryData.weeklyDeathsPerHundredThousand
          : d.summaryData.weeklyDeathsPerMillion,
      deltaCount:
        unit === 'hundredThousand'
          ? d.summaryData.changeInWeeklyDeathsPerHundredThousand
          : d.summaryData.changeInWeeklyDeathsPerMillion,
    },
    vaccinations: [
      d.summaryData.peopleVaccinatedPercentage,
      d.summaryData.peopleFullyVaccinatedPercentage,
      d.summaryData.boostersPercentage,

      // Special
      d.summaryData.lastUpdatedVaccinatedPercentage
        ? new Date(d.summaryData.lastUpdatedVaccinatedPercentage)
        : null,
    ],
  }))
}

const statsCellSortType = (a: Row, b: Row, columnId: string) => {
  return a.values[columnId].deltaCount - b.values[columnId].deltaCount
}

export type ColumnType<D extends object = {}> = Column<D> & {
  align?: 'left' | 'right' | 'center'
}
const columns = (unit: Unit): ColumnType<any>[] => {
  const perText = unit === 'hundredThousand' ? '100,000' : '1M'
  return [
    {
      id: 'country',
      accessor: 'country',
      Header: 'Country',
    },
    {
      id: 'weeklyInfections',
      accessor: 'weeklyInfections',
      Header: `Weekly Infections per ${perText}`,
      align: 'center',
      sortType: statsCellSortType,
      Cell: ({ value }: CellProps<any>) => {
        return <StatsCell {...(value as StatsCellPropTypes)} />
      },
    },
    {
      id: 'weeklyHospitalisations',
      accessor: 'weeklyHospitalisations',
      Header: `Weekly Hospitalisations per ${perText}`,
      align: 'center',
      sortType: statsCellSortType,
      Cell: ({ value }: CellProps<any>) => {
        return <StatsCell {...(value as StatsCellPropTypes)} />
      },
    },
    {
      id: 'weeklyDeaths',
      accessor: 'weeklyDeaths',
      Header: `Weekly Deaths per ${perText}`,
      align: 'center',
      sortType: statsCellSortType,
      Cell: ({ value }: CellProps<any>) => {
        return <StatsCell {...(value as StatsCellPropTypes)} />
      },
    },
    {
      id: 'vaccinations',
      accessor: 'vaccinations',
      Header: '1st, 2nd, Booster Vaccinations %',
      align: 'center',
      sortType: (a: Row, b: Row, columnId: string) => {
        return a.values[columnId][2] - b.values[columnId][2]
      },
      Cell: ({ value }: CellProps<any>) => {
        const lastUpdated = value[3]
        return (
          <Flex w='100%' flexDir='column' justifyContent='center'>
            <Flex w='100%' justifyContent='center' alignItems='center'>
              <Text fontSize='lg' color={calculateStatColor(value[0], 0, 100)}>
                {value[0] ?? '-'}
              </Text>
              <Text fontSize='lg'>, </Text>
              <Text fontSize='lg' color={calculateStatColor(value[1], 0, 100)}>
                {value[1] ?? '-'}
              </Text>
              <Text fontSize='lg'>, </Text>
              <Text fontSize='lg' color={calculateStatColor(value[2], 0, 100)}>
                {value[2] ?? '-'}
              </Text>
            </Flex>
            {lastUpdated && isValid(lastUpdated) && (
              <Text textAlign='center' fontSize='xs' color='gray.500'>
                Updated {formatUtc(lastUpdated, 'yyyy-MM-dd')}
              </Text>
            )}
          </Flex>
        )
      },
    },
  ]
}

export type CountriesSummaryTablePropTypes = {
  countryCodes?: CountrySummary['countryCode'][]
  unit?: Unit
}

const CountriesSummaryTable = ({
  countryCodes,
  unit = 'hundredThousand',
}: CountriesSummaryTablePropTypes) => {
  const { data, isLoading, isError } = useCountrySummary(countryCodes)

  if (isError) {
    return <InlineError />
  }

  return (
    <Box mb={10}>
      <Text float='right' color='gray.800'>
        *Estimated raw data
      </Text>
      <BasicTable
        columns={columns(unit)}
        data={
          mapData(data ?? [], unit)?.sort((a, b) => {
            return ('' + a.country).localeCompare(b.country)
          }) ?? []
        }
        isLoading={isLoading}
        showSearch
      />
    </Box>
  )
}

export default CountriesSummaryTable
