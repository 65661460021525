import GenericTable from 'modules/Tables'

import { IModel } from 'interfaces/model.interface'
import {
  INavigationPages,
  ISingleNavigationPage,
} from 'interfaces/navigationPage.interface'

import { RWEInfosetMediaModel } from 'config/common/baseModel/rweinfoset/RWEInfosetMedia'

import { IEGPolicySurveillanceSlugs } from './IEGPolicySurveillanceMenu'
import { AllNewsModel } from './IEGPolicySurveillanceModels'

type IEGPolicySurveillanceNavigationPage<TModel extends IModel<any>> =
  ISingleNavigationPage<IEGPolicySurveillanceSlugs, TModel>

// This page combines 2 different endpoint.
const AllNews: IEGPolicySurveillanceNavigationPage<
  typeof RWEInfosetMediaModel
> = {
  key: 'AllNews',
  path: 'all-news',
  component: GenericTable,
  // The schema is the same so we just choose 1
  // Combining of data is handled in model
  model: AllNewsModel,
  views: [
    // This isn't actually used for now since we're using customAPIFunction
    {
      name: 'Default',
      airtableName: 'api_bayer_examples_final',
      airtableBase: 'rweinfoset',
    },
  ],
}

export const IEGPolicySurveillancePages: INavigationPages<IEGPolicySurveillanceSlugs> =
  {
    AllNews,
  }
