export default {
  'None/minimal': 'rgb(209, 247, 196)',
  Low: 'green.400',
  Moderate: 'RGB(249, 160, 63)',
  High: 'rgb(248, 43, 96)',
  Unknown: 'rgb(186, 30, 69)',
  Mild: 'RGB(119, 182, 234)',
  Prevention: 'RGB(245, 202, 93)',
  Rehabilitation: 'RGB(235, 167, 169)',
  Critical: 'RGB(150, 2, 0)',
  Severe: 'RGB(185, 183, 255)',
}
