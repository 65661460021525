import GenericTable from 'modules/Tables'

import { IModel } from 'interfaces/model.interface'
import {
  INavigationPages,
  ISingleNavigationPage,
} from 'interfaces/navigationPage.interface'

import {
  LongCovidPapersModel,
  VariantsMediaModel,
  VariantsPressReleasesModel,
} from 'config/apps/Biorisk/Custom/Biorisk/BioriskModels'

import { DemoCountriesModel } from '../Science360/Vaccines/VaccinesModels'
import { EpidemiologySlugs } from './EpidemiologyMenu'

type EpidemiologyNavigationPage<TModel extends IModel<any>> =
  ISingleNavigationPage<EpidemiologySlugs, TModel>

const VariantsMedia: EpidemiologyNavigationPage<typeof VariantsMediaModel> = {
  key: 'Media',
  model: {
    ...VariantsMediaModel,
    excludeGeneratedCharts: true,
    hideTableHeader: true,
    name: 'News Reports',
  },
  path: null,
  component: GenericTable,
  views: [
    {
      name: 'Default',
      airtableName: 'api_variants',
    },
  ],
}

const VariantsPressReleases: EpidemiologyNavigationPage<
  typeof VariantsPressReleasesModel
> = {
  key: 'PressReleases',
  model: {
    ...VariantsPressReleasesModel,
    hideTableHeader: true,
    excludeGeneratedCharts: true,
  },
  path: null,
  component: GenericTable,
  views: [
    {
      name: 'Default',
      airtableName: 'api_variants',
    },
  ],
}

const Overview: EpidemiologyNavigationPage<typeof DemoCountriesModel> = {
  key: 'Overview',
  path: null,
  model: {
    ...DemoCountriesModel,
    name: 'Overview',
    excludeGeneratedCharts: true,
  },
  component: GenericTable,
  views: [
    {
      name: 'Default',
      airtableName: 'API',
    },
  ],
}
const LongCovidPresentations: EpidemiologyNavigationPage<
  typeof LongCovidPapersModel
> = {
  key: 'LongCovidFrontend',
  path: null,
  model: {
    ...LongCovidPapersModel,
    name: 'Publications',
  },
  component: GenericTable,
  views: [
    {
      name: 'Default',
      airtableName: 'Long COVID_frontend',
    },
  ],
}

export const EpidemiologyPages: INavigationPages<EpidemiologySlugs> = {
  VariantsPressReleases,
  VariantsMedia,
  LongCovidPresentations,
  Overview,
}

export const LongCovidPages: INavigationPages<EpidemiologySlugs> = {
  LongCovidPresentations,
}
