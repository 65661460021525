import { ValueType } from 'interfaces/valueType.interface'

import {
  CandidateRankingModel,
  MediaModel,
  PresentationsModel,
  PressReleasesModel,
} from 'config/common/baseModel'
import { DiseaseInformationModel } from 'config/common/baseModel/biorisk/DiseaseInformation'
import { OutbreakTrackerModel } from 'config/common/baseModel/biorisk/OutbreakTracker'
import { customFilterNaNGraphData } from 'config/common/transformValue/charts'

import {
  addOrOverrideColumn,
  compose,
  renameColumn,
  updateColumnAttributes,
  whiteListColumn,
} from 'utils/overrideModel'

export const VariantsCandidateRankingMutationsModel = compose<
  typeof CandidateRankingModel
>(
  renameColumn('countryFirstDetected', 'Country First Detected'),
  whiteListColumn<typeof CandidateRankingModel>([
    'linkAfTrUniqueTreatmentNameToBnf',
    'afSynonymousMutations',
    'presentations',
    'pressReleasesByTitle',
    'mediaByTitle',
    'lookupAfTrOtherNamesFromBnf',
    'linkClinicalTrials',
    'afImplications',
    'afImpactVaccineEfficacy',
    'afImpactTreatmentEfficacy',
    'keywordList',
    'afImpactVaccineSera',
  ])
)({
  ...CandidateRankingModel,
  detailViewType: 'Generic',
  name: 'Variants',
})

export const BioRiskScientificPapersModel = compose<typeof PresentationsModel>(
  whiteListColumn([
    'title',
    'linkJournals',
    'publicationDate',
    'disease',
    'abstract',
    'articleType',
    'grouping2',
    'lookupJournalImpactScore',
    'articleLink',
  ])
)({
  ...PresentationsModel,
  name: 'Publications',
  exportDisabled: true,
  endpoint: 'publication',
})

export const VariantsPressReleasesModel = compose<typeof PressReleasesModel>(
  addOrOverrideColumn<typeof PressReleasesModel>(
    {
      key: 'afUniqueTreatmentName',
      type: ValueType.MULTI,
      label: 'Name',
      width: 200,
      graphType: 'Pie',
      customDataFilter: (data) =>
        customFilterNaNGraphData(data, 'afUniqueTreatmentName'),
    },
    5
  ),
  whiteListColumn([
    'title',
    'links',
    'datePublished',
    'description',
    'afUniqueTreatmentName',
    'companies',
    'afTopic',
    'language',
    'afTags',
  ])
)(PressReleasesModel)

export const BioRiskPressReleasesModel = compose<typeof PressReleasesModel>(
  whiteListColumn([
    'title',
    'description',
    'datePublished',
    'infectiousDisease',
    'grouping2',
    'companies',
    'author',
    'feedOrganisationName',
    'link',
    'afTags',
    'language',
  ])
)(PressReleasesModel)

export const VariantsMediaModel = compose<typeof MediaModel>(
  addOrOverrideColumn<typeof MediaModel>(
    {
      key: 'afUniqueTreatmentName',
      type: ValueType.MULTI,
      label: 'Name',
      width: 200,
      graphType: 'Pie',
      customDataFilter: (data) =>
        customFilterNaNGraphData(data, 'afUniqueTreatmentName'),
    },
    5
  ),
  addOrOverrideColumn<typeof MediaModel>(
    {
      key: 'author',
      type: ValueType.MULTI,
      label: 'Authors',
      width: 200,
      customDataFilter: (data) => {
        return data?.filter((dataItem) => dataItem.author !== null)
      },
    },
    6
  ),
  whiteListColumn([
    'title',
    'mediaProvider',
    'datePublished',
    'description',
    'link',
    'language',
    'author',
    'companyResearchInstitution',
    'afTopic',
    'afTags',
    'afUniqueTreatmentName',
  ])
)(MediaModel)

export const BioRiskMediaModel = compose<typeof MediaModel>(
  whiteListColumn([
    'title',
    'description',
    'datePublished',
    'infectiousDisease',
    'grouping2',
    'link',
    'companies',
    'afSentimentAnalysis',
    'author',
    'afTags',
    'language',
  ])
)(MediaModel)

export const TreatmentOtherPapersModel = whiteListColumn<
  typeof PresentationsModel
>([
  'title',
  'journals',
  'channel',
  'publicationDate',
  'articleLink',
  'candidates',
  'abstract',
  'reviewStatus',
  'articleType',
  'journalImpactScore',
  'afRiskFactor',
])({
  ...PresentationsModel,
  name: 'Papers',
})
export const OutbreakOverviewTrackerModel = whiteListColumn<
  typeof OutbreakTrackerModel
>([
  'diseaseName',
  'continent',
  'country',
  'area',
  'valueNewInfections',
  'dateEndNewInfections',
  'sourceNewInfections',
  'valueNewDeaths',
  'dateEndNewDeaths',
  'sourceNewDeaths',
  'diseaseRi',
  'diseasePathogenFamily',
  'diseasePathogenType',
  'diseaseCategory',
  'diseaseModeOfTransmission',
])({
  ...OutbreakTrackerModel,
  name: 'Outbreak Tracker',
})

export const BioriskDiseaseInformationModel = compose<
  typeof DiseaseInformationModel
>(
  whiteListColumn([
    'afDiseaseName',
    'pathogenType',
    'pathogenFamily',
    'afModeOfTransmission',
    'rawCaseFatalityPercentage',
    'transmissionR0Average',
    'pathogenPriority',
    'characteristics',
    'afDiseaseRi',
  ]),
  updateColumnAttributes('characteristics', {
    type: ValueType.LONG,
    width: 500,
  })
)(DiseaseInformationModel)

export const LongCovidPapersModel = compose<typeof PresentationsModel>(
  renameColumn('channel', 'Article Type'),
  updateColumnAttributes('title', {
    type: ValueType.LONG,
  }),
  whiteListColumn(['title', 'journals', 'abstract', 'channel', 'articleLink'])
)({ ...PresentationsModel, name: 'Publications' })
