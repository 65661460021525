import { IApp } from 'interfaces/navigationApp.interface'

import { AccessGroups } from 'enums/AccessGroups'

import { CardioLipidCustomPages } from './CardioLipidCustomPages'
import { CardioLipidMenu, CardioLipidSlugs } from './CardioLipidMenu'
import { CardioLipidPages } from './CardioLipidPages'

const cardioLipid: IApp<CardioLipidSlugs> = {
  name: 'Lipid Lowering Therapies',
  slug: 'cardio-lipid',
  accessGroups: [AccessGroups.CardioLipid],
  endpoint: 'cardio-lipid',
  menu: CardioLipidMenu,
  pages: CardioLipidPages,
  customPages: CardioLipidCustomPages,
  createdAt: new Date(2021, 3, 19),
}

export default cardioLipid
