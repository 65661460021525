export default {
  'Genetic/Rare Disorders': 'rgb(209,226,255)',
  'Cardiology/CVD': 'rgba(209,226,255,255)',
  'Chronic Disease': 'rgba(224,218,253,255)',
  'Immune Disorder': 'rgba(1,100,0,255)',
  'Infectious Disease': 'rgba(207,245,209,255)',
  'Inflammatory Disease': 'rgba(160,198,255,255)',
  'Metabolic Disorder': 'rgba(255,224,204,255)',
  'Neurodegenerative Disorder': 'rgba(255,212,224,255)',
  'Neurological Disease': 'rgba(221,3,168,255)',
  Oncology: 'rgba(196,236,255,255)',
  Opthamology: 'rgba(255,234,182,255)',
  'Respiratory Disease': 'rgba(221,3,168,255)',
  Unconfirmed: 'rgba(250,210,252,255)',
  'Wound Healing': 'rgba(193,245,240,255)',
  Injury: 'rgba(255,234,182,255)',
  'Hormonal disorder': 'rgba(196,199,205,255)',
  'Congential Disorder': 'rgba(255,186,3,255)',
  'Genetic Disorders': 'rgba(209,226,255,255)',
  Renal: 'rgba(229,233,240,255)',
}
