import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'

const APPROVED_AI = 'hasApprovedAI'

const WarningPopover = ({
  onOpen,
  onClose,
  isOpen,
}: {
  onOpen: () => void
  onClose: () => void
  isOpen: boolean
}) => {
  useEffect(() => {
    if (
      localStorage.getItem(APPROVED_AI) !== 'true' &&
      window.location.hostname !== 'localhost'
    ) {
      onOpen()
    }
    // eslint-disable-next-line
  }, [])

  function handleAccept() {
    onClose()
    localStorage.setItem(APPROVED_AI, 'true')
  }
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size='2xl'>
        <ModalOverlay />
        <ModalContent mt='10%'>
          <ModalHeader>Data Logging and Access Policy</ModalHeader>
          <ModalCloseButton />
          <ModalBody p='2rem'>
            <Flex flexDir={'column'} gap='1rem'>
              <Text>
                Please be advised that all text entered into this LLM interface
                is logged for monitoring and quality purposes.
              </Text>
              <Text fontWeight={'bold'}>
                Only questions, answers, and feedback entered into this LLM
                interface are logged. No other information is collected. All
                collected data except the feedback is anonymized to protect your
                privacy.
              </Text>
              <Text>
                However, access to this logged information is strictly limited.
                Only a select group of authorized employees have permission to
                view and handle this data.
              </Text>
              <Text>
                Your privacy is important to us, and we have implemented robust
                measures to protect your information.
              </Text>
              <Text>
                By using this interface, you consent to these conditions.
              </Text>
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Link to='/biorisk'>
              <Button variant='ghost'>Exit</Button>
            </Link>
            <Button variant='yellow' mr={3} onClick={handleAccept}>
              Accept
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default WarningPopover
