import { IModel } from 'interfaces/model.interface'
import { ValueType } from 'interfaces/valueType.interface'

import { SortOrders } from 'enums/SortOrders'

export type PNHPublicationsData =
  | 'publicationDate'
  | 'title'
  | 'afArticleType'
  | 'lookupCandidateList'
  | 'afTopics'
  | 'afSentiment'
  | 'linkAfLeadAuthor'
  | 'linkAfLastAuthor'
  | 'linkJournals'
  | 'doiUrl'

const allowedAirtables = ['api_publications'] as const

export const PNHPublicationsModel: IModel<
  Record<PNHPublicationsData, string>,
  typeof allowedAirtables
> = {
  allowedAirtables,
  name: 'Publications',
  entityName: 'Publications',
  searchField: 'title',
  endpoint: 'publication',
  detailViewType: 'Generic',
  defaultSortKey: 'publicationDate',
  defaultSortOrder: SortOrders.DESC,
  schema: {
    columns: [
      {
        label: 'Publication Date',
        key: 'publicationDate',
        width: 150,
        type: ValueType.DATE,
      },
      {
        key: 'title',
        label: 'Title',
        width: 500,
      },
      {
        key: 'afArticleType',
        type: ValueType.MULTI,
        width: 150,
        label: 'Article Type',
      },
      {
        key: 'lookupCandidateList',
        label: 'Candidates',
        width: 150,
        type: ValueType.MULTI,
      },
      {
        key: 'afTopics',
        label: 'Topics',
        width: 150,
        type: ValueType.MULTI,
      },
      {
        key: 'afSentiment',
        label: 'Sentiment',
        type: ValueType.SINGLE,
        width: 150,
      },
      {
        key: 'linkAfLeadAuthor',
        label: 'Lead Author',
        type: ValueType.MULTI,
        width: 150,
      },
      {
        key: 'linkAfLastAuthor',
        label: 'Last Author',
        type: ValueType.MULTI,
        width: 150,
      },
      {
        key: 'linkJournals',
        label: 'Journal',
        type: ValueType.MULTI,
        width: 150,
      },
      {
        key: 'doiUrl',
        label: 'Article Link',
        type: ValueType.URL,
        width: 150,
      },
    ],
  },
}
