const warningMessages = {
  eligiblePopulation:
    'The eligibility data is based on publicly available resources, these sources may become outdated over time if the eligibility criteria are updated. Countries may specify eligibility criteria for the type of treatment (e.g. COVID-19 oral antivirals) rather than specific treatments in the guidelines referenced. Not all conditions may be captured in the eligible populations indicated if the guidelines reach a high level of specificity.',
  developers:
    'The developers table shows all organisations that have developed, or have contributed to the development of a COVID-19 therapeutics candidate. The organisations listed are included following announcements, public documents and other publicly available information.',
  manufacturers:
    'The manufacturer table shows all known organisations that have manufactured or have entered into a contract to manufacture a COVID-19 therapeutic candidate (this does not include all supply chain stages, but only companies providing a finished pharmaceutical product, active pharmaceutical ingredient of fill finish/packaging leading to a finished product). The organisations listed are included following announcements, public documents and other publicly available information.',
  revenue: `Airfinity tracks pharmaceutical companies' publicly shared quarterly earnings press releases to collect COVID-19 treatment product sales revenue data per quarter and region since 2020, where available. Annotation such as location (region) as well as treatment name/s (candidates) are as stated in the source. If reported in currency other than USD in the source, values have been exchanged to USD based on exchange rate that year. If more than one company reported revenue for the same product, e.g. AstraZeneca and Sobi for Synagis, both are presented in the dataset.`,
  supplyDeals: `Airfinity collects COVID-19 therapeutics supply deal and price information made publicly available via media, press releases and other secondary sites.`,
  revenueNewNote: `Data has been collected on COVID-19 treatment revenues from pharmaceutical companies' publicly shared quarterly earnings press releases. Locations (regions) and candidates have been linked, where available. Get in touch with any questions or if you would like a demo from Airfinity analysts.`,
}

export default warningMessages
