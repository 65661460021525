import { IModel } from 'interfaces/model.interface'
import { IBaseView } from 'interfaces/navigationPage.interface'

import callFunctionOrReturn from './callFunctionOrReturn'

export const resolveModel = (
  pageModel: IModel<any>,
  view: IBaseView<any, any>
) => {
  const model: IModel<any> = view.overrideModel
    ? callFunctionOrReturn(view.overrideModel, pageModel)
    : pageModel

  return model
}

export const getCustomSortOrderFromModel = (model: IModel<any>) => {
  return Object.fromEntries(
    model.schema.columns
      .filter((x) => !!x.sortOrderObject)
      .map((column) => [column.key, column.sortOrderObject!])
  )
}
