import { Box, Text } from '@chakra-ui/react'
import { BarDatum, BarTooltipProps } from '@nivo/bar'
import { useEffect, useMemo, useState } from 'react'

import { compactNumberWithPrefix } from 'utils/formatNumber'

interface CustomTooltipProps {
  sumByKey: boolean
  horizontalKey: string
  stackByKey: string
  numElementsX: number
}

const CustomTooltip: React.FC<
  BarTooltipProps<BarDatum> & CustomTooltipProps
> = ({ ...props }) => {
  const {
    color,
    value,
    label,
    sumByKey,
    horizontalKey,
    stackByKey,
    index,
    numElementsX,
  } = props
  const [tooltipSize, setTooltipSize] = useState<{
    width: number
    height: number
  }>({ width: 0, height: 0 })

  useEffect(() => {
    const tooltip = document.querySelector('.nivo_tooltip')
    if (tooltip) {
      const { width, height } = tooltip.getBoundingClientRect()
      setTooltipSize({ width, height })
    }
  }, [label])

  const properIndex = index + 1
  const isFirstHalf = properIndex < numElementsX / 2
  const translateX = useMemo(
    () => (isFirstHalf ? 20 : -tooltipSize.width),
    [isFirstHalf, tooltipSize.width]
  )

  const [stackByValue, horizontalValue] = label.split(' - ')
  return (
    <Box
      style={{
        position: 'absolute',
        transform: `translate(${translateX}px, 0px)`,
      }}
      className={'nivo_tooltip'}
      w={'max-content'}
      shadow={'md'}
      border='1px'
      borderColor={'gray.300'}
      gap={2}
      borderRadius={'5px'}
      p={1}
      bg='white'
    >
      <Box bgColor={color} h={5} w={'full'} borderRadius={'2px'} />
      <Box fontSize={'14px'} p={1}>
        <Box display={'flex'} gap={1}>
          <Text color='gray.700'>{horizontalKey}:</Text>
          <Text maxW='200px' fontWeight={'semibold'}>
            {horizontalValue}
          </Text>
        </Box>
        <Box display={'flex'} gap={1}>
          <Text color='gray.700'>{stackByKey}:</Text>
          <Text maxW='200px' fontWeight={'semibold'}>
            {stackByValue}
          </Text>
        </Box>
        <Box display={'flex'} gap={1}>
          <Text color='gray.700'>Total:</Text>
          <Text fontWeight={'bold'}>
            {sumByKey ? compactNumberWithPrefix(Number(value)) : value}
          </Text>
        </Box>
        <Box fontSize={'14px'} color='gray.700'>
          <Text pt={2}>Click to filter by: </Text>
          <Text>{stackByValue}</Text>
        </Box>
      </Box>
    </Box>
  )
}

export default CustomTooltip
