import {
  Box,
  Button,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'

const PresentationModal = ({
  isOpen,
  handleClose,
  title,
  readMoreLabel = 'Read full article',
  abstract,
  link,
  noPrint,
}: {
  isOpen: boolean
  title?: any
  readMoreLabel?: string
  abstract?: any
  link?: any
  handleClose?: any
  noPrint?: boolean
}) => {
  return (
    <Modal size='3xl' isOpen={isOpen} onClose={handleClose} isCentered>
      <ModalOverlay />
      <ModalContent
        className={noPrint === true ? 'no-print' : ''}
        overflow='hidden'
      >
        <ModalCloseButton />
        <ModalBody p={12}>
          <Heading fontSize='xl' mb={4} color='legacy-primary.500'>
            {title}
          </Heading>

          {abstract ? (
            <Box mt={4} borderTop='1px solid' borderColor='gray.100' pt={4}>
              <Text
                fontSize='md'
                maxHeight='60vh'
                overflowY='scroll'
                lineHeight='1.8'
                color='legacy-primary.500'
              >
                {abstract}
              </Text>
            </Box>
          ) : (
            <Box fontSize='sm'>No abstract available.</Box>
          )}
        </ModalBody>
        <ModalFooter>
          {!!link && (
            <Button
              as='a'
              color='black'
              variant='outline'
              target='_blank'
              href={link}
              size='sm'
            >
              {readMoreLabel}
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default PresentationModal
