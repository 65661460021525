import { StarFilled } from '@carbon/icons-react'
import _ from 'lodash'

import { CleanResponseData } from 'api/types'

type PropTypes = {
  value: CleanResponseData
}

export default ({ value }: PropTypes) => {
  const intVal = Number.isInteger(value)
    ? (value as number)
    : typeof value === 'string'
    ? parseInt(value, 10)
    : 0

  return (
    <>
      {_.range(intVal).map((x) => (
        <StarFilled size={16} key={x} color='#005cff' />
      ))}
    </>
  )
}
