import {
  INavigationPages,
  ISingleNavigationPage,
} from 'interfaces/navigationPage.interface'

import { EpidemiologyPages } from 'config/apps/Covid19/Epidemiology/EpidemiologyPages'

import { HealthSecurityPremiumSlugs } from './HealthSecurityPremiumMenu'

export const HealthSecurityPremiumPages: INavigationPages<HealthSecurityPremiumSlugs> =
  {
    Overview: {
      ...(EpidemiologyPages.Overview as ISingleNavigationPage<
        HealthSecurityPremiumSlugs,
        any
      >),
      path: ['vaccine-strategy', 'initial-and-boosters'],
    },
  }
