import { Box, Text } from '@chakra-ui/react'
import { SIDEBAR_WIDTH } from 'constants/misc'
import { useContext } from 'react'
import { NavLink, useMatch } from 'react-router-dom'

import PrimaryLink from 'modules/Navigation/SidebarNavigation/PrimaryLink'

import { AuthContext } from 'contexts'

import { apps, appsListUnion } from 'config/apps'

export default function TutorialPage() {
  const match = useMatch('/tutorial/:app')
  const currentApp = match?.params.app as appsListUnion

  const { appListUserCanAccess } = useContext(AuthContext)

  const appsWithTutorials = Object.values(appListUserCanAccess).filter(
    (app) => !!app.tutorial
  )

  return (
    <Box display='flex' width='100%' flex={1} overflow='hidden'>
      <Box
        overflow='auto'
        maxHeight='100%'
        width={SIDEBAR_WIDTH}
        display='flex'
        py={0}
        position='relative'
        flexDirection='column'
      >
        <Box py='2rem'>
          <Text
            fontSize='lg'
            fontWeight={600}
            mb={'2rem'}
            color='gray.900'
            textAlign='center'
          >
            Tutorials
          </Text>
          {appsWithTutorials.map((app, i) => (
            <NavLink key={i} to={`/tutorial/${app.slug}`}>
              <PrimaryLink isActive={app.slug === currentApp}>
                {app?.tutorial?.[0].name}
              </PrimaryLink>
            </NavLink>
          ))}
        </Box>
      </Box>
      <Box
        width={`calc(100% - ${SIDEBAR_WIDTH})`}
        display='flex'
        flex={1}
        pl={3}
        pr={3}
        overflowY='auto'
        flexDirection='column'
        pt={'2rem'}
      >
        <Text fontSize='lg' fontWeight={600} mb={1} color='gray.900' px='2rem'>
          {apps[currentApp].tutorial?.[0]?.name} Walkthroughs
        </Text>

        {apps[currentApp].tutorial?.map((tutorial) => (
          <Box mb={4} p='2rem' key={tutorial.url}>
            <video controls style={{ minWidth: '100%' }}>
              <source src={tutorial.url} />
              Your browser does not support the video tag.
            </video>
          </Box>
        ))}
      </Box>
    </Box>
  )
}
