import { useContext, useEffect } from 'react'

import { useAppRoute } from 'routes/utils'

import { AuthContext } from 'contexts'

import { FullPageError, FullPageLoading } from 'components'
import { usePdfData } from 'components/PdfDownload/usePdfData'

import { useReportById } from 'api/cms/reports/useReports'
import { Report } from 'api/types'

import { apps } from 'config/apps'

import { CombinationsReport } from '../Science360/Vaccines/CombinationVaccinesReports'

const CustomCombinationReports = ({ report }: { report: Report }) => {
  const { data: pdfData, refetch: loadPdf, error } = usePdfData(report.report)

  useEffect(() => {
    report.report && loadPdf()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (error) {
    return <FullPageError />
  }
  return (
    <CombinationsReport
      report={report}
      title={''}
      reportsLoading={false}
      pdfData={pdfData}
    />
  )
}

export const CustomReportPage = ({ id }: { id: string }) => {
  const selectedApp = useAppRoute()
  const { userIsBasicWithinApp } = useContext(AuthContext)
  const {
    data: reportData,
    isError,
    isLoading,
  } = useReportById(selectedApp, id)

  const isBasic = userIsBasicWithinApp(apps[selectedApp])

  if (isError) {
    return <FullPageError />
  }

  if (isLoading) {
    return <FullPageLoading text='Loading Report...' />
  }

  const report = isBasic ? { ...reportData, report: '' } : reportData

  if (!report) return <FullPageError />

  return <CustomCombinationReports report={report} />
}
