import { MachineLearning, User, Cognitive } from '@carbon/icons-react'
import {
  Box,
  ButtonSpinner,
  Button,
  Flex,
  Text,
  Tooltip,
} from '@chakra-ui/react'
import _ from 'lodash'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'

import { ViewPdfReport } from 'components'

import { ChatHistoryT, ChatT, RoleT } from '.'
import FeedbackBar from './Feedback/FeedbackBar'

function removeConsecutiveDuplicateReferences(text: string) {
  return text.replaceAll(/( \*\*\[\d+\]\*\*)(?:\1)+/gm, '$1')
}

const ChatIcon = ({ role }: { role: RoleT }) => {
  if (role === 'user') return <User color='white' size={'18px'} />
  if (role === 'assistant-internal')
    return <Cognitive color='#0B0B0B' size={'18px'} />
  return <MachineLearning color='#0B0B0B' size={'18px'} />
}

const SourcesSection = ({
  hasSources,
  sourcesKeys,
}: {
  hasSources: boolean
  sourcesKeys: string[]
}) => {
  if (hasSources) {
    return (
      <Box marginLeft='42px' mt='8px'>
        <Text variant={'body-bold'} mr='8px' cursor='pointer'>
          Sources
        </Text>
        <Flex gap='8px' overflowY={'auto'}>
          {sourcesKeys?.map((report: any, index: number) => {
            const num = index + 1
            if (!report) return null
            let title = report.title
            let date = report.date
            let disableButton = false
            if (report.title === 'Report not found') {
              title = 'Alerts'
              date = ''
              disableButton = true
            }
            return (
              <ViewPdfReport
                key={num}
                url={report?.url}
                pageIndex={0}
                triggerComponent={({ isLoading, disabled, handleClick }) => (
                  <Tooltip
                    p='0.5rem'
                    bg='gray.700'
                    color='white'
                    label={`${title} - ${date}`}
                  >
                    <Button
                      onClick={(e) => {
                        if (disableButton) return null
                        e.stopPropagation()
                        handleClick()
                      }}
                      boxShadow='none !important'
                      outline='none'
                      disabled={disabled || disableButton}
                      cursor={
                        !isLoading && !disabled && !disableButton
                          ? 'pointer'
                          : 'default'
                      }
                      variant='outline'
                      borderColor='gray.200'
                      rounded='8px'
                      py='0px'
                      px='8px'
                      bg='beige'
                    >
                      {isLoading ? (
                        <ButtonSpinner />
                      ) : (
                        <Text variant={'body'} whiteSpace={'nowrap'}>
                          [{num}]
                        </Text>
                      )}
                    </Button>
                  </Tooltip>
                )}
              />
            )
          })}
        </Flex>
      </Box>
    )
  }
  return null
}

const ChatSection = ({
  message,
  chatHistory,
}: {
  message: ChatT
  chatHistory: ChatHistoryT
}) => {
  const sources = message.sources

  const isUser = message.role === 'user'
  const isInternal = message.role === 'assistant-internal'

  const hasSources = sources?.length > 0
  let sourcesKeys: string[] = []
  let sourcesValues: any[] = []

  // Now we have the sources grouped by file_id
  // so we take any one of the values as the value since the file_citation is the same
  if (hasSources) {
    const _groupSources = _.groupBy(
      sources,
      (source) => source.file_citation.file_id
    )
    const sourceMapping = Object.keys(_groupSources).reduce((acc, key) => {
      const source = _groupSources[key][0]

      // Safeguard against incorrect types
      if (
        typeof source.file_citation === 'object' &&
        source.file_citation !== null
      ) {
        return {
          ...acc,
          [key]: {
            title: source.file_citation.report_name, // Use the correct property here
            date: source.file_citation.date || 'N/A', // Provide a fallback if date is missing
            url: source.file_citation.url,
          },
        }
      } else {
        console.error(
          `Unexpected type for file_citation:`,
          source.file_citation
        )
        return acc // Return accumulator unchanged if something's wrong
      }
    }, {})
    sourcesKeys = Object.keys(sourceMapping)
    sourcesValues = Object.values(sourceMapping)
  }

  const _content =
    message.sources?.reduce((acc: string, source: any) => {
      const id = sourcesKeys.indexOf(source.file_citation.file_id)
      return acc.replace(source.text, ` **[${id + 1}]**`)
    }, message.content) ?? message.content

  const content = removeConsecutiveDuplicateReferences(_content)

  return (
    <>
      {isInternal && (
        <Text color='gray.800' as='i' fontSize='md' ml='1rem'>
          Internal Reasoning
        </Text>
      )}
      <Flex
        pos='relative'
        gap='8px'
        flexDir={'column'}
        mb={isInternal ? '0.5rem' : '2rem'}
        border={isUser || isInternal ? '' : '1px solid'}
        borderColor={'#e3e2df'}
        w='fit-content'
        borderRadius={'12px'}
        p='1rem'
        ml={isUser ? 'auto' : ''}
        mr={isUser ? '' : 'auto'}
        bg={isUser ? '#f6f6f6' : ''}
        bgGradient={
          isUser || isInternal
            ? ''
            : 'linear-gradient(rgba(53, 27, 97, 0.04), rgba(53, 27, 97, 0))'
        }
      >
        <Flex
          minW={'80px'}
          gap='16px'
          alignItems='flex-start'
          _firstLetter={{
            textTransform: 'uppercase',
          }}
        >
          <Box
            bg={isUser ? 'black' : '#FFED00'}
            p='4px'
            borderRadius={'6px'}
            fontWeight={'bold'}
          >
            <ChatIcon role={message.role} />
          </Box>

          <ReactMarkdown rehypePlugins={[rehypeRaw]} className='ai markdown'>
            {content}
          </ReactMarkdown>
        </Flex>
        <SourcesSection hasSources={hasSources} sourcesKeys={sourcesValues} />
        <FeedbackBar
          content={content}
          isInternal={isInternal}
          isUser={isUser}
          chatHistory={chatHistory}
        />
      </Flex>
    </>
  )
}

export default ChatSection
