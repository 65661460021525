import { Box } from '@chakra-ui/react'

import Details from 'modules/Summary'

import { IResponseBase } from 'api/types'

import { IModel } from 'interfaces/model.interface'
import { ViewData } from 'interfaces/navigationPage.interface'

type PropTypes = {
  rowData: IResponseBase
  model: IModel<any>
  viewData: ViewData
}

export const DetailDrawerSummary = ({
  rowData,
  model,
  viewData,
}: PropTypes) => {
  return (
    <Box>
      <Details data={rowData} model={model} viewData={viewData} />
    </Box>
  )
}
