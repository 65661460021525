import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  CloseButton,
  Heading,
  Text,
  Checkbox,
} from '@chakra-ui/react'
import { useState } from 'react'
import ReactMarkdown from 'react-markdown'
import { Navigate } from 'react-router-dom'
import rehypeRaw from 'rehype-raw'

import Navigation from 'modules/Navigation'

import { InlineError, LoadingAnimation } from 'components'

import useTnCAgreeMutation from 'api/useTnCAgreeMutation'
import useTnCData from 'api/useTnCData'

export default function TermsAndConditions() {
  const [agreed, setAgreed] = useState<boolean | null>(null)

  const { data: termsAndConditions, isLoading, isError } = useTnCData()

  const [agreedToTerms, setAgreedToTerms] = useState(false)

  const {
    mutate,
    isError: mutationIsError,
    isLoading: mutationIsLoading,
    reset,
  } = useTnCAgreeMutation()

  const agreeToTerms = (lastUpdated: string) => {
    mutate(
      { date: lastUpdated },
      {
        onSuccess: () => {
          setAgreed(true)
        },
      }
    )
  }

  const cancelTerms = async () => {
    setAgreed(false)
  }

  if (isError) {
    return <InlineError />
  }

  if (isLoading || !termsAndConditions) {
    return <LoadingAnimation />
  }

  if (agreed !== null) {
    return <Navigate to={{ pathname: '/' }} replace />
  }

  return (
    <>
      <Navigation showSearchBar={false} />
      <Box
        backgroundColor='white'
        py={10}
        px={10}
        maxWidth='1200px'
        margin='0px auto'
        overflow='auto'
      >
        <Heading mb={5} size='lg'>
          {termsAndConditions.name}
        </Heading>
        <Box
          border='1px'
          borderColor='gray.300'
          maxHeight='50vh'
          overflow='auto'
          px={10}
          py={5}
          backgroundColor='gray.100'
        >
          <ReactMarkdown rehypePlugins={[rehypeRaw]}>
            {termsAndConditions.content}
          </ReactMarkdown>
        </Box>
        {termsAndConditions.message && (
          <Text mt={5}>{termsAndConditions.message}</Text>
        )}
        {mutationIsError && (
          <Alert status='error'>
            <AlertIcon />
            <AlertTitle mr={2}>Error</AlertTitle>
            <AlertDescription>
              Sorry, something went wrong. Please try again.
            </AlertDescription>
            <CloseButton
              position='absolute'
              right='8px'
              top='8px'
              onClick={reset}
            />
          </Alert>
        )}
        <Text>
          <br />
          <b>Accept Terms</b>
          <br />
          <Checkbox onChange={(e) => setAgreedToTerms(e.target.checked)}>
            I agree to the Terms of Service <br />
          </Checkbox>
        </Text>
        <Box mt={2}>
          <Button
            isLoading={mutationIsLoading}
            onClick={() => agreeToTerms(termsAndConditions.lastUpdated)}
            colorScheme='green'
            disabled={!agreedToTerms}
          >
            Agree
          </Button>
          <Button ml={2} onClick={cancelTerms}>
            Cancel
          </Button>
        </Box>
      </Box>
    </>
  )
}
