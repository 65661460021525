import FullReportPage from 'routes/common/FullReportPage'

import { ISingleCustomPage } from 'interfaces/navigationPage.interface'

import { ResearchPowerIndexSlugs } from './ResearchPowerIndexMenu'

const Reports: ISingleCustomPage<ResearchPowerIndexSlugs> = {
  path: 'reports',
  component: () => <FullReportPage showCategory={false} />,
}

export const ResearchPowerIndexCustomPages: Record<
  string,
  ISingleCustomPage<ResearchPowerIndexSlugs>
> = {
  Reports,
}
