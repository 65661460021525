import { Heading, Box } from '@chakra-ui/react'
import Truncate from 'react-truncate'

import { ItemComponentPropTypes } from 'modules/DetailDrawer'
import ArticleFooter from 'modules/DetailDrawer/DetailDrawerList/components/ArticleFooter'

import { Tag } from 'components'

import { IDTreatments360PressReleasesData } from 'config/common/baseModel/idtreatments360/IDTreatments360PressReleases'

import { formatDate } from 'utils/formatDate'

const DetailDrawerListPressRelease = ({
  item,
  handleSelect,
}: ItemComponentPropTypes<Record<IDTreatments360PressReleasesData, any>>) => {
  const {
    title,
    datePublished,
    abstract,
    articleLink,
    company,
    afTags,
    doiUrl,
    link,
  } = item

  const externalLink = articleLink ?? doiUrl ?? link

  return (
    <Box
      px={8}
      py={4}
      borderBottom='1px solid'
      borderColor='gray.100'
      cursor='pointer'
      onClick={() => handleSelect(item)}
      _hover={{
        bg: 'gray.100',
      }}
    >
      <Box
        mb={1}
        display='flex'
        alignItems='center'
        fontSize='xs'
        fontWeight={600}
        textTransform='uppercase'
        color='gray.700'
      >
        <Box mr={2}>{formatDate(datePublished)}</Box>
      </Box>
      {afTags && Array.isArray(afTags) && (
        <Box mb={2}>
          {afTags.map((topic: any, i: number) => {
            return (
              <Tag
                key={i}
                fontSize='xs'
                color='blue'
                mr={1}
                px='8px'
                rounded='full'
              >
                {topic}
              </Tag>
            )
          })}
        </Box>
      )}
      <Heading fontSize='md' mb={2} fontWeight={500}>
        {title}
      </Heading>
      {company && Array.isArray(company) && (
        <Box mb={2}>
          {company.map(({ displayText }: any, i: number) => {
            return (
              <Tag
                key={i}
                fontSize='xs'
                color='green'
                mr={1}
                px='8px'
                rounded='full'
              >
                {displayText}
              </Tag>
            )
          })}
        </Box>
      )}

      <Box mb={4} color='gray.800' fontSize='sm'>
        <Truncate lines={3} ellipsis={<span>...</span>} trimWhitespace={true}>
          {abstract}
        </Truncate>
      </Box>
      <ArticleFooter externalLink={externalLink as string} />
    </Box>
  )
}

export { DetailDrawerListPressRelease }
