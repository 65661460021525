import { Box, BoxProps } from '@chakra-ui/react'

export type DropdownMenuItemPropTypes = {
  children: React.ReactNode
  isSelected: boolean
  onClick?: () => void
} & BoxProps

export const DropdownMenuItem = ({
  children,
  isSelected = false,
  onClick,
  ...boxProps
}: DropdownMenuItemPropTypes) => {
  return (
    <Box
      px={2}
      py={2}
      _hover={{ bg: 'gray.200' }}
      fontWeight={500}
      fontSize='base'
      cursor='pointer'
      bg={isSelected ? 'gray.100' : 'transparent'}
      onClick={onClick}
      {...boxProps}
    >
      {children}
    </Box>
  )
}
