import { ChevronLeft, ChevronRight } from '@carbon/icons-react'
import { Box, Button, ButtonProps, Skeleton } from '@chakra-ui/react'
import { Row } from 'react-table'

import useTracking from 'tracking/useTracking'

import useIsMobile from 'utils/useIsMobile'

import PageSizeButton from './Toolbar/PageSizeButton'

interface PaginationProps<D extends Object> {
  showAll: boolean
  canPreviousPage?: boolean
  canNextPage?: boolean
  gotoPage?: (pageNumber: number) => void
  page: Array<Row<D>>
  nextPage: any
  isLoading: boolean
  previousPage: any
  pageIndex: number
  pageCount: number
  pageSize: number
  total: number
  setPageSize: (size: number) => void
}

const PageButton = ({
  children,
  isDisabled,
  ...rest
}: {
  children: React.ReactNode
  isDisabled: boolean
} & ButtonProps) => {
  return (
    <Button
      isDisabled={isDisabled}
      textAlign='center'
      py='8px'
      px='12px'
      lineHeight='text'
      backgroundColor='white'
      cursor='pointer'
      fontSize='sm'
      color='black'
      height='32px'
      border='1px solid black'
      rounded='4px'
      fontWeight={400}
      _hover={{
        bg: 'white',
        color: 'black',
      }}
      _disabled={{
        _hover: {
          bg: 'gray.100',
          color: 'gray.400',
          borderColor: 'gray.400',
        },
        bg: 'gray.100',
        color: 'gray.400',
        borderColor: 'gray.400',
        cursor: 'default',
      }}
      _focus={{
        boxShadow: 'none',
      }}
      {...rest}
    >
      {children}
    </Button>
  )
}

export const Pagination = <D extends Object>({
  showAll,
  canPreviousPage,
  canNextPage,
  previousPage,
  isLoading,
  nextPage,
  pageIndex,
  pageSize,
  total,
  setPageSize,
}: PaginationProps<D>) => {
  const [isMobile] = useIsMobile()
  const [tracking] = useTracking()

  if (showAll) {
    return (
      <Box
        bg='gray.100'
        px={6}
        mt='auto'
        minHeight='60px'
        display='flex'
        alignItems='center'
        fontSize='13px'
        color='gray1'
        fontWeight={500}
      >
        Showing all {total} results
      </Box>
    )
  }

  return (
    <Box
      bg='gray5'
      px={6}
      mt='auto'
      minHeight='50px'
      display='flex'
      alignItems='center'
      color='gray1'
    >
      {isLoading ? (
        <Skeleton height={5} width={200} />
      ) : (
        <>
          {!isMobile && (
            <PageSizeButton
              setPageSize={setPageSize}
              currentPageSize={pageSize}
            />
          )}
          <Box
            fontSize='13px'
            ml={isMobile ? 6 : 0}
            maxW={['120px', '200px', '200px']}
            fontWeight={500}
          >
            Showing {Math.min(total, pageIndex * pageSize + 1)} to{' '}
            {Math.min(total, (pageIndex + 1) * pageSize)} of {total} results
          </Box>
          {total > pageSize && (
            <Box
              ml='auto'
              mr={6}
              mb={1}
              overflow='hidden'
              display='flex'
              gap='16px'
            >
              <PageButton
                isDisabled={!canPreviousPage}
                onClick={() => {
                  previousPage()
                  tracking.tablePaginate({
                    action: 'PREV',
                    pageIndex: pageIndex - 1,
                  })
                }}
                leftIcon={<ChevronLeft size={16} />}
                iconSpacing='6px'
                fontSize='12px'
              >
                Previous
              </PageButton>
              <PageButton
                onClick={() => {
                  nextPage()
                  tracking.tablePaginate({
                    action: 'NEXT',
                    pageIndex: pageIndex + 1,
                  })
                }}
                isDisabled={!canNextPage}
                rightIcon={<ChevronRight size={16} />}
                iconSpacing='6px'
                fontSize='12px'
              >
                Next
              </PageButton>
            </Box>
          )}
        </>
      )}
    </Box>
  )
}

export default Pagination
