import { IApp } from 'interfaces/navigationApp.interface'

import { AccessGroups } from 'enums/AccessGroups'

import { VaccinesCustomPages } from 'config/apps/Covid19/Science360/Vaccines/VaccinesCustomPages'
import {
  VaccinesMenu,
  VaccinesSlugs,
} from 'config/apps/Covid19/Science360/Vaccines/VaccinesMenu'
import { VaccinesPages } from 'config/apps/Covid19/Science360/Vaccines/VaccinesPages'

const ModernaCovid19Science360App: IApp<VaccinesSlugs> = {
  name: 'COVID-19',
  slug: 'moderna-covid-19-science360',
  commonIdentifier: 'covid-19',
  accessGroups: [AccessGroups.Moderna],
  endpoint: 'demo-vaccines',
  menu: VaccinesMenu,
  pages: VaccinesPages,
  customPages: VaccinesCustomPages,
  disableAnalytics: true,
  createdAt: new Date(2022, 5, 30),
}

export default ModernaCovid19Science360App
