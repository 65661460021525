import { UseQueryOptions, useQueries } from '@tanstack/react-query'
import { AxiosError, AxiosInstance } from 'axios'

import { apps, appsListUnion } from 'config/apps'

import useAxios from '../../useAxios'
import { ReportCategory } from './types'

export default function ReportCategories(
  app: appsListUnion,
  slugOverwrites: string[],
  extraProps?: UseQueryOptions<any, any>,
  endpoint?: string
) {
  const axios = useAxios()

  const res = useQueries({
    queries: slugOverwrites.map<UseQueryOptions<ReportCategory[], AxiosError>>(
      (extraKey) => {
        return {
          queryKey: ['reportCategories', app, extraKey],
          queryFn: () => fetchReportCategories(axios, app, extraKey, endpoint),
          ...extraProps,
        }
      }
    ),
  })
  return res
}

export const fetchReportCategories = async (
  axios: AxiosInstance,
  app: appsListUnion,
  extraKey = 'updates',
  endpoint?: string
): Promise<ReportCategory[]> => {
  const appEndpoint = endpoint || apps[app].endpoint
  return axios
    .get(`/${appEndpoint}/analyst-${extraKey}-categories/`)
    .then((res) => res.data)
}
