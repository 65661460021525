import { INavigationMenu } from 'interfaces/navigationMenu.interface'

export type ResearchPowerIndexSlugs =
  | ''
  | 'tweets'
  | 'people'
  | 'research-institutions-ranking'
  | 'funding-agencies-ranking'
  | 'company-revenue-ranking'
  | 'countries-ranking'
  | 'by-funding-orgs'
  | 'by-author-affiliations'
  | 'by-kol-scores'
  | 'by-private-sector-company-revenue'
  | 'reports'

const ResearchPowerIndexMenu: Array<
  INavigationMenu<ResearchPowerIndexSlugs>[]
> = [
  [
    {
      title: 'Publications',
      slug: '',
      description: '',
      createdAt: new Date(2022, 2, 11),
    },
    {
      title: 'Reports',
      slug: 'reports',
      createdAt: new Date(2022, 3, 19),
    },
  ],
  [
    {
      title: 'Tweets',
      slug: 'tweets',
      description: '',
      createdAt: new Date(2022, 2, 11),
    },
    {
      title: 'People',
      slug: 'people',
      description: '',
      createdAt: new Date(2022, 2, 11),
    },
    {
      title: 'Research Institutions Ranking',
      slug: 'research-institutions-ranking',
      description: '',
      createdAt: new Date(2022, 2, 11),
    },
    {
      title: 'Funding Agencies Ranking',
      slug: 'funding-agencies-ranking',
      description: '',
      createdAt: new Date(2022, 2, 11),
    },
    {
      title: 'Private sector company revenue ranking',
      slug: 'company-revenue-ranking',
      description: '',
      createdAt: new Date(2022, 2, 29),
    },
    {
      title: 'Countries Ranking',
      slug: 'countries-ranking',
      description: '',
      createdAt: new Date(2022, 2, 11),
      children: [
        {
          title: 'By Funding Orgs',
          slug: 'by-funding-orgs',
          createdAt: new Date(2022, 2, 11),
        },
        {
          title: 'By Author Affiliations',
          slug: 'by-author-affiliations',
          createdAt: new Date(2022, 2, 11),
        },
        {
          title: 'By KOL Scores',
          slug: 'by-kol-scores',
          createdAt: new Date(2022, 2, 22),
        },
        {
          title: 'By Private Sector Company Revenue',
          slug: 'by-private-sector-company-revenue',
          createdAt: new Date(2022, 2, 29),
        },
      ],
    },
  ],
]

export { ResearchPowerIndexMenu }
