import { OverflowMenuHorizontal } from '@carbon/icons-react'
import { Button, Box } from '@chakra-ui/react'
import { useState } from 'react'

import { GraphFactory } from 'modules/Graphs'

import {
  Dropdown,
  DropdownMenuItem,
  Panel,
  PanelBody,
  PanelHeader,
  PanelTitle,
} from 'components'

import { IResponseBase } from 'api/types'

import { IChart } from 'interfaces/charts.interface'
import { IModel } from 'interfaces/model.interface'

type PropTypes = {
  model: IModel<any, any>
  chart: IChart
  data: IResponseBase<any>[] | undefined
  isLoading: boolean
}

export default function Dial({ model, chart, data, isLoading }: PropTypes) {
  const { defaultType, column } = chart

  const [type, toggleType] = useState(defaultType)

  return (
    <Panel
      border='1px solid'
      borderColor='gray3'
      background='white'
      borderRadius='8px'
      boxShadow='sm'
    >
      <PanelHeader>
        <Box display='flex' alignItems='center'>
          <PanelTitle>{chart?.title ?? ''}</PanelTitle>
          <Box ml='auto'>
            <Dropdown
              positions={['bottom', 'left']}
              align='end'
              content={
                <Box
                  shadow='lg'
                  bg='white'
                  border='1px solid'
                  borderColor='gray.200'
                  rounded='md'
                  width='160px'
                  p={1}
                >
                  <DropdownMenuItem
                    onClick={() => toggleType('Progress')}
                    isSelected={type === 'Progress'}
                  >
                    Progress Bars
                  </DropdownMenuItem>
                  <DropdownMenuItem
                    onClick={() => toggleType('Pie')}
                    isSelected={type === 'Pie'}
                  >
                    Pie Chart
                  </DropdownMenuItem>
                  <DropdownMenuItem
                    onClick={() => toggleType('Bar')}
                    isSelected={type === 'Bar'}
                  >
                    Bar Chart
                  </DropdownMenuItem>
                </Box>
              }
            >
              <Button
                variant='ghost'
                borderWidth={0}
                width='25px'
                height='25px'
                p='0'
                alignItems='center'
                justifyContent='center'
                display='flex'
              >
                <OverflowMenuHorizontal size={32} />
              </Button>
            </Dropdown>
          </Box>
        </Box>
      </PanelHeader>
      <PanelBody>
        {!isLoading && !!data ? (
          <GraphFactory data={data} column={column} type={type} model={model} />
        ) : null}
      </PanelBody>
    </Panel>
  )
}
