import { Box } from '@chakra-ui/react'
import { TooltipProps } from 'recharts'

import formatNumber from 'utils/formatNumber'

export default ({ payload, active }: TooltipProps<any, any>) => {
  const currentPayload = payload?.[0] ?? { name: '', value: '' }

  if (!active) {
    return null
  }

  return (
    <Box
      bg='gray.900'
      w='240px'
      shadow='md'
      rounded='md'
      overflow='hidden'
      color='white'
    >
      <Box
        height='5px'
        width='100%'
        bg={currentPayload?.payload?.fill || 'gray'}
        mr={2}
      />
      <Box px={4} py={3} lineHeight='none' fontSize='sm'>
        <Box
          display='flex'
          alignItems='center'
          lineHeight='1.2'
          mb={1}
          fontWeight={600}
        >
          {currentPayload?.payload?.name ?? ''}
        </Box>
        <Box ml='auto' fontWeight={600} opacity={0.75}>
          {formatNumber(currentPayload?.payload?.value ?? 0)}
        </Box>
      </Box>
    </Box>
  )
}
