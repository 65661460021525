import { Box, HStack, VStack } from '@chakra-ui/react'
import _ from 'lodash'
import { useContext, useState } from 'react'

import { AuthContext } from 'contexts'

import useIsMobile from 'utils/useIsMobile'

import AppsSelectorBody from './AppsSelectorBody'
import AppsSelectorHeader, {
  AppsSelectorDropdownType,
  selectAllOption,
} from './AppsSelectorHeader'
import ResearchBrief, { ResearchBriefsAppsWhitelist } from './ResearchBriefs'

const AppsSelectorPage = () => {
  const [isMobile] = useIsMobile()

  const { appletGroupsUserCanSee, userInfo } = useContext(AuthContext)

  const [selectedApps, setSelectedApps] = useState<string[] | undefined>()

  const [selectedCategory, setSelectedCategory] =
    useState<AppsSelectorDropdownType>(selectAllOption)

  const canSeeResearchBrief = userInfo?.groups?.some((group) =>
    (ResearchBriefsAppsWhitelist as string[]).includes(group)
  )

  const appletGroupsToShow = _.sortBy(
    selectedCategory.value === null
      ? Object.values(appletGroupsUserCanSee)
      : Object.values(appletGroupsUserCanSee).filter(
          (appletGroup) => appletGroup.category === selectedCategory.value
        ),
    'order'
  )
    .filter((appletGroup) => !appletGroup.hidden)
    .filter((appletGroup) =>
      selectedApps && selectedApps?.length > 0
        ? selectedApps?.includes(appletGroup.key)
        : true
    )
  if (isMobile) {
    return (
      <VStack
        px={[6, 6]}
        pt={10}
        mx={[4, 4]}
        marginX='auto'
        alignItems='flex-start'
        spacing={6}
      >
        <VStack
          maxW='1100px'
          pt={10}
          mx={[4, 4]}
          marginX='auto'
          alignItems='flex-start'
          spacing={10}
          w='100%'
        >
          <Box w='100%'>
            <AppsSelectorHeader
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
              hideSelector
            />
          </Box>
          <Box overflow='auto' height='100%'>
            <AppsSelectorBody appletGroupsToShow={appletGroupsToShow} />
          </Box>
        </VStack>
        {canSeeResearchBrief && (
          <ResearchBrief setSelectedApps={setSelectedApps} />
        )}
      </VStack>
    )
  }

  return (
    <HStack
      px={[6, 6]}
      pt={10}
      mx={[4, 4]}
      marginX='auto'
      alignItems='flex-start'
      spacing={6}
    >
      {canSeeResearchBrief && (
        <ResearchBrief setSelectedApps={setSelectedApps} />
      )}
      <VStack
        maxW='1100px'
        pt={10}
        mx={[4, 10]}
        marginX='auto'
        alignItems='flex-start'
        spacing={10}
        w='70%'
      >
        <Box w='100%'>
          <AppsSelectorHeader
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
            hideSelector
          />
        </Box>
        <Box overflow='auto' height='100%'>
          <AppsSelectorBody appletGroupsToShow={appletGroupsToShow} />
        </Box>
      </VStack>
    </HStack>
  )
}

export default AppsSelectorPage
