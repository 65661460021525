import {
  demoCommonProperties,
  mapSteps,
} from 'modules/Tables/Dashboard/dashboardsConfig'
import Joyride, {
  ACTIONS,
  CallBackProps,
  EVENTS,
  Events,
  STATUS,
  Status,
} from 'react-joyride'

const DashboardTour = ({
  setStepsEnabled,
  setStepIndex,
  stepsEnabled,
  stepIndex,
}: {
  setStepsEnabled: (value: boolean) => void
  setStepIndex: (value: number) => void
  stepsEnabled: boolean
  stepIndex: number
}) => {
  const handleJoyrideCallback = (data: CallBackProps) => {
    const { action, index, status, type } = data
    if (
      ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND] as Events[]).includes(type)
    ) {
      setStepIndex(index + (action === ACTIONS.PREV ? -1 : 1))
    } else if (
      ([STATUS.FINISHED, STATUS.SKIPPED] as Status[]).includes(status)
    ) {
      localStorage.setItem('trialsDashboardTourCompleted', 'true')
      setStepsEnabled(false)
    }
  }

  return (
    <Joyride
      {...demoCommonProperties}
      callback={handleJoyrideCallback}
      stepIndex={stepIndex}
      continuous
      scrollOffset={100}
      run={stepsEnabled}
      steps={mapSteps}
    />
  )
}

export default DashboardTour
