import { Box, Button, Center, VStack, Text, Flex } from '@chakra-ui/react'

import { Logo } from 'components'

import config from './config/oidc'

export const PoolRouterOptions = [
  // { title: 'Airfinity', id: 'gov', config: config.govConfig },
  { title: 'Glacier', id: 'glacier', config: config.glacierConfig },
  { title: 'Mammoth', id: 'mammoth', config: config.mammothConfig },
]

export const PoolRouter = () => {
  function handleClick(id: string) {
    window.location.replace(`?pool=${id}`)
  }
  return (
    <Center h='100vh' bg='gray.50'>
      <Box p={8} bg='white' rounded='lg' shadow='md' textAlign='center' w='sm'>
        <Flex w='100%' justifyContent={'center'}>
          <Box h={'70px'} w='180px' color={'primary'}>
            <Logo />
          </Box>
        </Flex>
        <Text mb={6}>Sign in with your ID</Text>
        <VStack spacing={4}>
          {PoolRouterOptions.map((option) => {
            return (
              <Button
                key={option.id}
                w='full'
                colorScheme='yellow'
                variant='solid'
                onClick={() => handleClick(option.id)}
              >
                {option.title}
              </Button>
            )
          })}
        </VStack>
      </Box>
    </Center>
  )
}

export default PoolRouter
