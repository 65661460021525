import { UseQueryOptions, useQuery } from '@tanstack/react-query'

import useAxios from '../useAxios'

export default function useAPIReadme(extraProps?: UseQueryOptions<any, any>) {
  const axios = useAxios()

  return useQuery<string>(
    ['apiToken'],
    async () => {
      const res = await axios.post('/public-api/token/')

      return res.data.token
    },
    extraProps
  )
}
