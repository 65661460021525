import { IResponseBase } from 'api/types'

export const customFilterNaNGraphData = (
  data: IResponseBase<any>[] | undefined,
  filterField = 'afUniqueTreatmentName'
): IResponseBase<any>[] | undefined => {
  return data?.map((dataItem) => {
    const name = dataItem[filterField]
    return {
      ...dataItem,
      [filterField]: name === null ? 'Non-specific' : name,
    }
  })
}
