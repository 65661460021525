import { Warning } from '@carbon/icons-react'
import { HStack, Icon, useToast, Text } from '@chakra-ui/react'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useContext } from 'react'

import { AuthContext } from 'contexts'
import { useClientType } from 'contexts/ClientType'

import useAxios from 'api/useAxios'

import {
  downloadPdfFromBytes,
  getPdfBytes,
  getWatermarkText,
} from './pdfFunctions'

export const usePdfData = (url: string, disableWatermark?: boolean) => {
  const axios = useAxios()
  const { isGov } = useClientType()
  const { userInfo } = useContext(AuthContext)
  const toast = useToast()
  const toastId = 'pdfFail'

  const watermarkText =
    disableWatermark || isGov
      ? ''
      : getWatermarkText(userInfo?.name as string, userInfo?.client as string)

  return useQuery(
    ['pdfData', url, watermarkText],
    () => getPdfBytes(axios, url, watermarkText),
    {
      enabled: false,
      onError: () => {
        if (!toast.isActive(toastId)) {
          toast({
            id: toastId,
            duration: 3000,
            position: 'bottom',
            render: () => (
              <HStack color='white' p={3} bg='red.400'>
                <Icon m='0 5px 2px 0' boxSize='20px' as={Warning} />
                <Text>Unable to load report. Please try again later.</Text>
              </HStack>
            ),
          })
        }
      },
    }
  )
}

export const useDownloadPdf = (url: string, title: string) => {
  const { data, refetch } = usePdfData(url)

  return useMutation(async () => {
    let pdfBytes = data
    if (!data?.length) {
      const pdfData = await refetch()
      pdfBytes = pdfData.data
    }
    await downloadPdfFromBytes(pdfBytes, title + '.pdf')
  })
}
