export default {
  'Not reported': 'rgb(209, 247, 196)',
  '100% had mild adverse events': 'rgb(184, 117, 3)',
  'Trial ongoing, not yet reported': 'rgb(184, 117, 3)',
  'No statistically significant difference between vaccine and placebo groups':
    'rgb(255, 158, 183)',
  'Trial complete, no results reported yet': 'rgb(255, 158, 183)',
  '6.7-17.2% with an AE related to vaccination': 'rgb(107, 28, 176)',
  'Similar between vaccine and placebo groups': 'rgb(24, 191, 255)',
}
