import { Box, TabPanels, TabPanel, TabPanelProps } from '@chakra-ui/react'
import moment from 'moment'

import {
  DetailDrawerHeader,
  DetailDrawerList,
  DetailDrawerListApprovals,
  DetailDrawerListTrial,
  DetailDrawerSummary,
  DetailDrawerTabs,
} from 'modules/DetailDrawer'

import { SlideOver } from 'components'

import { IResponseBase } from 'api/types'

import { IModel } from 'interfaces/model.interface'
import { ViewData } from 'interfaces/navigationPage.interface'

import {
  IDA360ApprovalsModel,
  IDA360DealsModel,
  IDA360PremiumRevenueModel,
  IDA360TrialsModel,
  NewsPressReleasesModel,
} from 'config/apps/IDA360/Custom/IDA360/IDA360Models'
import { CandidateRankingData } from 'config/common/baseModel'

import { DetailDrawerListDeal } from './Deals'
import { DetailDrawerListRevenue } from './DealsRevenue'
import { DetailDrawerListPressRelease } from './NewsPressRelease'

const StyledTabPanel: React.FC<React.PropsWithChildren<TabPanelProps>> = (
  props
) => <TabPanel height='100%' p={0} {...props} />

type PropTypes<D extends Record<CandidateRankingData, any>> = {
  handleClose: () => void
  candidate: IResponseBase<Extract<keyof D, string>>
  model: IModel<D>
  title: Extract<keyof D, string>
  viewData: ViewData
}

function Detail<D extends Record<CandidateRankingData, any>>(
  props: PropTypes<D>
) {
  const { handleClose, candidate, model, title, viewData } = props

  return (
    <SlideOver show={true}>
      <DetailDrawerHeader
        isLoading={false}
        title={candidate && candidate[title]}
        handleClose={handleClose}
      />
      <DetailDrawerTabs
        tabs={[
          'Summary',
          'Trials',
          'Approvals',
          'News & Press Releases',
          'Revenue',
          'Supply & Price',
        ]}
      >
        <Box flexGrow={1} flexBasis={0} overflow='hidden'>
          <TabPanels height='100%'>
            <StyledTabPanel p={6} overflow='auto'>
              <DetailDrawerSummary
                rowData={candidate}
                model={model}
                viewData={viewData}
              />
            </StyledTabPanel>
            <StyledTabPanel>
              <DetailDrawerList
                id={1}
                viewData={viewData}
                model={IDA360TrialsModel}
                sortOrder={(x) => moment(x.completedAt)}
                ids={candidate.clinicalTrialsIds}
                itemComponent={DetailDrawerListTrial}
              />
            </StyledTabPanel>
            <StyledTabPanel>
              <DetailDrawerList
                id={2}
                viewData={viewData}
                model={IDA360ApprovalsModel}
                sortOrder={(x) => moment(x.approvalDate)}
                ids={candidate.approvalIds}
                itemComponent={DetailDrawerListApprovals}
              />
            </StyledTabPanel>
            <StyledTabPanel>
              <DetailDrawerList
                id={3}
                viewData={viewData}
                model={NewsPressReleasesModel}
                sortOrder={(x) => moment(x.publishedAt)}
                ids={candidate.rssArticleIds}
                itemComponent={DetailDrawerListPressRelease}
              />
            </StyledTabPanel>
            <StyledTabPanel>
              <DetailDrawerList
                id={4}
                viewData={viewData}
                model={IDA360PremiumRevenueModel}
                sortOrder={(x) => moment(x.dateStart)}
                ids={candidate.revenueIds}
                itemComponent={DetailDrawerListRevenue}
              />
            </StyledTabPanel>
            <StyledTabPanel>
              <DetailDrawerList
                id={5}
                viewData={viewData}
                model={IDA360DealsModel}
                sortOrder={(x) => moment(x.dateStart)}
                ids={candidate.dealIds}
                itemComponent={DetailDrawerListDeal}
              />
            </StyledTabPanel>
          </TabPanels>
        </Box>
      </DetailDrawerTabs>
    </SlideOver>
  )
}

export default Detail
