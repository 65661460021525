import { Center, CenterProps } from '@chakra-ui/react'

import { ThreeDotsLoadingAnimation } from './ThreeDotsLoadingAnimation'

export const InlineLoading = ({ my = 2, ...rest }: CenterProps) => {
  return (
    <Center my={my} alignItems='center' {...rest}>
      <ThreeDotsLoadingAnimation />
    </Center>
  )
}
