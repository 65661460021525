import { Flex } from '@chakra-ui/react'

import AppsSelectorPage from 'modules/AppsSelector/AppsSelectorPage'
import Navigation from 'modules/Navigation'
import MobileNavigation from 'modules/Navigation/MobileNavigation'

import { Footer } from 'components'

import useIsMobile from 'utils/useIsMobile'

const Base = () => {
  const [isMobile] = useIsMobile()

  return (
    <>
      {isMobile ? <MobileNavigation /> : <Navigation />}

      <Flex flexDir='column' overflow='auto'>
        <AppsSelectorPage />

        <Flex mx='auto' py={5} alignItems='center' justifyContent='center'>
          <Footer />
        </Flex>
      </Flex>
    </>
  )
}

export default Base
