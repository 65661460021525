import { Box, Heading, Link } from '@chakra-ui/react'
import {
  Link as ReactRouterLink,
  Navigate,
  Route,
  Routes,
  useNavigate,
} from 'react-router-dom'

import Navigation from 'modules/Navigation'

import { useBookmark } from 'contexts/Bookmark'

import { LoadingAnimation } from 'components'

import BookmarkPage from './BookmarkPage'

const Bookmark = () => {
  const { bookmarkedItems, isLoading } = useBookmark()

  const appsWithBookmarksSlugs = [
    ...new Set(bookmarkedItems.map((appWithBookmark) => appWithBookmark.app)),
  ]

  const navigate = useNavigate()

  if (isLoading) {
    return <LoadingAnimation />
  }

  return (
    <>
      <Navigation showSearchBar={false} onBackButton={() => navigate(-1)} />
      <Routes>
        <Route
          path='/'
          element={
            <>
              {appsWithBookmarksSlugs.length > 0 ? (
                <Navigate
                  to={`/bookmarks/${appsWithBookmarksSlugs[0]}`}
                  replace
                />
              ) : (
                <Box
                  p={5}
                  textAlign='center'
                  rounded='md'
                  justifyContent='center'
                  width='100%'
                  height='100vh'
                  display='flex'
                  alignItems='center'
                >
                  <Box>
                    <Heading mb={3} fontSize='xl'>
                      You don't have any bookmarks yet
                    </Heading>
                    <Link
                      to='/'
                      as={ReactRouterLink}
                      textDecoration='underline'
                    >
                      Back to dashboard
                    </Link>
                  </Box>
                </Box>
              )}
            </>
          }
        />
        {appsWithBookmarksSlugs.map((appSlug) => (
          <Route key={appSlug} path={appSlug} element={<BookmarkPage />} />
        ))}
        <Route path='*' element={<Navigate to={`/bookmarks/`} replace />} />
      </Routes>
    </>
  )
}

export default Bookmark
