import { IModel } from 'interfaces/model.interface'
import { ValueType } from 'interfaces/valueType.interface'

import { SortOrders } from 'enums/SortOrders'

import AchievedPrimaryEndpoint from 'config/common/tagColours/AchievedPrimaryEndpoint'
import AdverseEventsMildModerate from 'config/common/tagColours/AdverseEventsMildModerate'
import AdverseEventsSevere from 'config/common/tagColours/AdverseEventsSevere'
import AdverseReactions from 'config/common/tagColours/AdverseReactions'
import CriteriaForPrimaryEndpoint from 'config/common/tagColours/CriteriaForPrimaryEndpoint'
import DataSource from 'config/common/tagColours/DataSource'
import DirectVSHostTargeted from 'config/common/tagColours/DirectVSHostTargeted'
import DosingInterval from 'config/common/tagColours/DosingInterval'
import EfficacyEffectiveness from 'config/common/tagColours/EfficacyEffectiveness'
import EfficacyResults from 'config/common/tagColours/EfficacyResults'
import Phase from 'config/common/tagColours/Phase'
import TargetPopulation from 'config/common/tagColours/TargetPopulation'
import TreatmentTiming from 'config/common/tagColours/TreatmentTiming'
import TreatmentType from 'config/common/tagColours/TreatmentType'
import TrialType from 'config/common/tagColours/TrialType'

export type RespiratoryToolMetaVaccineEfficacyData =
  | 'candidateNameFromVaccineCandidate'
  | 'companies'
  | 'clinicalTrialId'
  | 'phasesCleanFromClinicalTrialId'
  | 'trialSize'
  | 'studyPopulationType'
  | 'numberDoses'
  | 'dosingInterval'
  | 'dose'
  | 'achievedPrimaryEndpoint'
  | 'criteriaForPrimaryEndpoint'
  | 'adverseEventsMildModerate'
  | 'adverseEventsSevere'
  | 'efficacyEffectiveness'
  | 'confidenceIntervalOfEfficacy'
  | 'numberOfConfirmedCases'
  | 'numberOfCasesInVaccinatedGroup'
  | 'numberOfCasesOnPlaceboGroup'
  | 'genPop'
  | 'trialLocations'
  | 'articleLink'
  | 'publicationsId'
  | 'dataSource'
  | 'linkInfectiousDiseaseFromVaccineCandidate'
  | 'trialType'
  | 'candidateNameFromTreatmentCandidate'
  | 'manualLatestPhaseFromTreatmentCandidate'
  | 'linkCompaniesFromTreatmentCandidate'
  | 'published'
  | 'efficacyResults'
  | 'efficacyRelativeReductionInHospitalisation'
  | 'efficacyRelativeReductionInInfection'
  | 'efficacyAucMeanViralLoad'
  | 'efficacyAucMeanSymptomScore'
  | 'adverseReactions'
  | 'safetySevereAdverseReactions'
  | 'safetyGeGrade3'
  | 'safetyUpperRespiratoryInfection'
  | 'safetyPharyngitis'
  | 'safetyRhinitis'
  | 'safetyOtitisMedia'
  | 'safetyPain'
  | 'safetyHeadache'
  | 'safetyRash'
  | 'safetyHernia'
  | 'safetyGastrointestinalDisorders'
  | 'safetyDiarrhoea'
  | 'safetyDeath'
  | 'achievedPrimaryEndpoint'
  | 'treatmentCandidate'
  | 'treatmentTimingFromTreatmentCandidate'
  | 'treatmentTypeFromTreatmentCandidate'
  | 'directVsHostTargetedFromTreatmentCandidate'
  | 'trialPhase'
  | 'pooledEfficacyRelativeReductionInHospitalisation'
  | 'efficacyAdditionalSAndSScoreReduction'
  | 'efficacyAdditionalReductionViralLoad'

const allowedAirtables = [
  'results_rsv_vaccine_trials_frontend',
  'results_rsv_treatments_frontend',
] as const

export const RespiratoryToolMetaVaccineEfficacyModel: IModel<
  Record<RespiratoryToolMetaVaccineEfficacyData, string>,
  typeof allowedAirtables
> = {
  allowedAirtables,
  name: 'Non-Vaccine Efficacy',
  entityName: 'Non-Vaccine Efficacy',
  endpoint: 'tool-meta-vaccine-efficacy',
  detailViewType: 'Generic',
  displayKey: 'candidateNameFromVaccineCandidate',
  searchField: 'candidateNameFromVaccineCandidate',
  defaultSortObject: [
    { id: 'phasesCleanFromClinicalTrialId', sortOrder: SortOrders.DESC },
    { id: 'candidateNameFromVaccineCandidate', sortOrder: SortOrders.ASC },
  ],
  schema: {
    columns: [
      {
        key: 'candidateNameFromVaccineCandidate',
        label: 'Vaccine',
        width: 200,
        type: ValueType.MULTI,
      },
      {
        key: 'companies',
        label: 'Companies',
        width: 180,
        type: ValueType.MULTI,
      },
      {
        key: 'clinicalTrialId',
        label: 'Link to Clinical Trials',
        width: 170,
        type: ValueType.MULTI,
      },
      {
        key: 'phasesCleanFromClinicalTrialId',
        label: 'Phase',
        width: 100,
        type: ValueType.MULTI,
        cellFormat: {
          colours: Phase,
        },
      },
      {
        key: 'trialSize',
        label: 'Trial Size',
        width: 120,
        type: ValueType.NUMBER,
      },
      {
        key: 'studyPopulationType',
        label: 'Population',
        width: 100,
        type: ValueType.MULTI,
        cellFormat: {
          colours: TargetPopulation,
        },
      },
      {
        key: 'numberDoses',
        label: 'Number Doses',
        width: 110,
        type: ValueType.SINGLE,
      },
      {
        key: 'dosingInterval',
        label: 'Dosing Interval',
        width: 130,
        type: ValueType.SINGLE,
        cellFormat: {
          colours: DosingInterval,
        },
      },
      {
        key: 'dose',
        label: 'Dose',
        width: 130,
        type: ValueType.TEXT,
        tooltip: 'μg of antigen or infectious units dose size',
      },
      {
        key: 'achievedPrimaryEndpoint',
        label: 'Primary Endpoint Achieved',
        width: 200,
        type: ValueType.SINGLE,
        cellFormat: {
          colours: AchievedPrimaryEndpoint,
        },
      },
      {
        key: 'criteriaForPrimaryEndpoint',
        label: 'Primary Endpoint Criteria',
        width: 220,
        type: ValueType.MULTI,
        tooltip: 'Also known as outcome measure',
        cellFormat: {
          colours: CriteriaForPrimaryEndpoint,
        },
      },
      {
        key: 'adverseEventsMildModerate',
        label: 'Adverse Events - Mild/Moderate',
        width: 250,
        type: ValueType.SINGLE,
        cellFormat: {
          colours: AdverseEventsMildModerate,
        },
      },
      {
        key: 'adverseEventsSevere',
        label: 'Adverse Events - Severe',
        width: 250,
        type: ValueType.SINGLE,
        tooltip: 'Grade 3 and above',
        cellFormat: {
          colours: AdverseEventsSevere,
        },
      },
      {
        key: 'efficacyEffectiveness',
        label: 'Efficacy',
        width: 220,
        type: ValueType.SINGLE,
        cellFormat: {
          colours: EfficacyEffectiveness,
        },
      },
      {
        key: 'confidenceIntervalOfEfficacy',
        label: 'Confidence Interval Of Efficacy',
        width: 220,
        type: ValueType.TEXT,
      },
      {
        key: 'numberOfConfirmedCases',
        label: 'Number Of Confirmed Cases',
        width: 220,
        type: ValueType.TEXT,
      },
      {
        key: 'numberOfCasesInVaccinatedGroup',
        label: 'Number Of Cases In Vaccinated Group',
        width: 220,
        type: ValueType.TEXT,
      },
      {
        key: 'numberOfCasesOnPlaceboGroup',
        label: 'Number Of Cases In Placebo',
        width: 220,
        type: ValueType.TEXT,
      },
      {
        key: 'genPop',
        label: 'Listed Population',
        width: 220,
        type: ValueType.TEXT,
        cellFormat: {
          colours: TargetPopulation,
        },
      },
      {
        key: 'trialLocations',
        label: 'Locations',
        width: 200,
        type: ValueType.MULTI,
      },
      {
        key: 'articleLink',
        label: 'Article Link',
        width: 120,
        type: ValueType.URL,
      },
      {
        key: 'publicationsId',
        label: 'Publications Id',
        width: 200,
        type: ValueType.MULTI,
      },
      {
        key: 'dataSource',
        label: 'Data Source',
        width: 250,
        type: ValueType.MULTI,
        cellFormat: {
          colours: DataSource,
        },
      },
      {
        key: 'linkInfectiousDiseaseFromVaccineCandidate',
        label: 'Link Infectious Disease From Vaccine Candidate',
        width: 200,
        type: ValueType.MULTI,
      },
      {
        key: 'trialType',
        label: 'Trial Type',
        width: 150,
        type: ValueType.SINGLE,
        cellFormat: {
          colours: TrialType,
        },
      },
      {
        key: 'candidateNameFromTreatmentCandidate',
        label: 'Non-Vaccine',
        width: 200,
        type: ValueType.SINGLE,
      },
      {
        key: 'manualLatestPhaseFromTreatmentCandidate',
        label: 'Phase',
        width: 130,
        type: ValueType.MULTI,
        cellFormat: {
          colours: Phase,
        },
      },
      {
        key: 'linkCompaniesFromTreatmentCandidate',
        label: 'Companies',
        width: 220,
        type: ValueType.TEXT,
      },
      {
        key: 'published',
        label: 'Published',
        width: 200,
        type: ValueType.TEXT,
      },
      {
        key: 'efficacyResults',
        label: 'Efficacy Results',
        width: 200,
        type: ValueType.SINGLE,
        cellFormat: {
          colours: EfficacyResults,
        },
      },
      {
        key: 'efficacyRelativeReductionInHospitalisation',
        label: 'Relative Reduction In Hospitalisation',
        width: 200,
        type: ValueType.TEXT,
      },
      {
        key: 'efficacyRelativeReductionInInfection',
        label: 'Relative Reduction In RSV LRTD',
        width: 200,
        type: ValueType.TEXT,
      },
      {
        key: 'efficacyAucMeanViralLoad',
        label: 'AUC for Mean Viral Load',
        width: 200,
        type: ValueType.TEXT,
      },
      {
        key: 'efficacyAucMeanSymptomScore',
        label: 'AUC for Mean Total Symptom Score',
        width: 200,
        type: ValueType.TEXT,
      },
      {
        key: 'adverseReactions',
        label: 'Adverse Reactions',
        width: 200,
        type: ValueType.SINGLE,
        cellFormat: { colours: AdverseReactions },
      },
      {
        key: 'safetySevereAdverseReactions',
        label: 'Severe Adverse Reactions',
        width: 200,
        type: ValueType.NUMBER,
      },
      {
        key: 'safetyGeGrade3',
        label: 'Adverse Events ≥ Grade3',
        width: 200,
        type: ValueType.NUMBER,
      },
      {
        key: 'safetyUpperRespiratoryInfection',
        label: 'Upper Respiratory Infection',
        width: 200,
        type: ValueType.NUMBER,
      },
      {
        key: 'safetyPharyngitis',
        label: 'Pharyngitis',
        width: 120,
        type: ValueType.NUMBER,
      },
      {
        key: 'safetyRhinitis',
        label: 'Rhinitis',
        width: 120,
        type: ValueType.NUMBER,
      },
      {
        key: 'safetyOtitisMedia',
        label: 'Otitis Media',
        width: 120,
        type: ValueType.NUMBER,
      },
      {
        key: 'safetyPain',
        label: 'Pain',
        width: 120,
        type: ValueType.NUMBER,
      },
      {
        key: 'safetyHeadache',
        label: 'Headache',
        width: 120,
        type: ValueType.NUMBER,
      },
      {
        key: 'safetyRash',
        label: 'Rash',
        width: 120,
        type: ValueType.NUMBER,
      },
      {
        key: 'safetyHernia',
        label: 'Hernia',
        width: 120,
        type: ValueType.NUMBER,
      },
      {
        key: 'safetyGastrointestinalDisorders',
        label: 'Gastrointestinal Disorders',
        width: 120,
        type: ValueType.NUMBER,
      },
      {
        key: 'safetyDiarrhoea',
        label: 'Diarrhoea',
        width: 120,
        type: ValueType.NUMBER,
      },
      {
        key: 'safetyDeath',
        label: 'Death',
        width: 120,
        type: ValueType.NUMBER,
      },
      {
        key: 'achievedPrimaryEndpoint',
        label: 'Achieved Primary Endpoint',
        width: 200,
        type: ValueType.TEXT,
      },
      {
        key: 'treatmentCandidate',
        label: 'Treatment Candidate',
        width: 200,
        type: ValueType.MULTI,
      },
      {
        key: 'treatmentTimingFromTreatmentCandidate',
        label: 'Treatment Timing',
        width: 180,
        type: ValueType.MULTI,
        cellFormat: {
          colours: TreatmentTiming,
        },
      },
      {
        key: 'treatmentTypeFromTreatmentCandidate',
        label: 'Treatment Type',
        width: 230,
        type: ValueType.MULTI,
        cellFormat: { colours: TreatmentType },
        accessor: (d: any) => d.treatmentTypeFromTreatmentCandidate.flat(),
      },
      {
        key: 'directVsHostTargetedFromTreatmentCandidate',
        label: 'Direct vs Host Targeted',
        width: 180,
        type: ValueType.MULTI,
        cellFormat: {
          colours: DirectVSHostTargeted,
        },
      },
      {
        key: 'trialPhase',
        label: 'Trial Phase',
        width: 180,
        type: ValueType.SINGLE,
        cellFormat: {
          colours: Phase,
        },
      },
      {
        key: 'pooledEfficacyRelativeReductionInHospitalisation',
        label: 'Pooled Efficacy - Relative Reduction in Hospitalisation',
        width: 280,
        type: ValueType.TEXT,
      },
      {
        key: 'efficacyAdditionalSAndSScoreReduction',
        label: 'Additional Reduction in Sign & Symptom Score',
        width: 280,
        type: ValueType.TEXT,
      },
      {
        key: 'efficacyAdditionalReductionViralLoad',
        label: 'Additional Reduction in Viral Load',
        width: 280,
        type: ValueType.TEXT,
      },
    ],
  },
}
