import { Collapse } from '@chakra-ui/react'
import { useContext } from 'react'

import { ExpandableContext } from './ExpandableContainer'

const ExpandableBody: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const { isOpen } = useContext(ExpandableContext)

  return <Collapse in={isOpen}>{children}</Collapse>
}

export default ExpandableBody
