import { INavigationMenu } from 'interfaces/navigationMenu.interface'

export type DiagnosticsSlugs =
  | ''
  | 'candidates'
  | 'deals'
  | 'price'
  | 'supply'
  | 'production'
  | 'countries'
  | 'news'
  | 'press-releases'
  | 'media'
  | 'social'
  | 'tweets'
  | 'conferences'
  | 'testing-capacity'

const DiagnosticsMenu: Array<INavigationMenu<DiagnosticsSlugs>[]> = [
  [
    {
      title: 'Latest Information',
      slug: '',
      createdAt: new Date(2021, 2, 4),
      description:
        'Live fed of the most recent Airfinity reports and news covering COVID-19 diagnostic candidates',
    },
  ],
  [
    {
      title: 'Candidates',
      slug: 'candidates',
      createdAt: new Date(2021, 1, 18),
      description:
        'Detailed information on all approved, investigational diagnostic candidates',
    },
    {
      title: 'Testing Capacity',
      slug: 'testing-capacity',
      createdAt: new Date(2022, 0, 27),
      description: 'Testing Capacity dashboard',
    },
    {
      title: 'Conferences',
      slug: 'conferences',
      createdAt: new Date(2022, 0, 19),
      description: '',
    },
    {
      title: 'Deals',
      slug: 'deals',
      createdAt: new Date(2021, 3, 22),
      description: 'Manufacturing, supply and price deals for diagnostics',
      children: [
        {
          title: 'Price',
          slug: 'price',
          createdAt: new Date(2021, 3, 22),
        },
        {
          title: 'Supply',
          slug: 'supply',
          createdAt: new Date(2021, 3, 22),
        },
        {
          title: 'Production',
          slug: 'production',
          createdAt: new Date(2021, 3, 22),
        },
      ],
    },
    {
      title: 'Countries',
      slug: 'countries',
      createdAt: new Date(2021, 10, 8),
      description:
        'Information on current policy and infection rates by country',
    },
    {
      title: 'News',
      slug: 'news',
      createdAt: new Date(2021, 2, 4),
      description:
        'Archive of all tracked media and press articles in the COVID-19 diagnostic space',
      children: [
        {
          title: 'Press Releases',
          slug: 'press-releases',
          createdAt: new Date(2021, 2, 4),
        },
        {
          title: 'Media',
          slug: 'media',
          createdAt: new Date(2021, 2, 4),
        },
      ],
    },
    {
      title: 'Social',
      slug: 'social',
      createdAt: new Date(2021, 2, 4),
      description:
        'Keep up to date with the latest tweets from KOLs discussing COVID-19 diagnostics',
      children: [
        {
          title: 'Tweets',
          slug: 'tweets',
          createdAt: new Date(2021, 2, 4),
        },
      ],
    },
  ],
]

export { DiagnosticsMenu }
