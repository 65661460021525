import {
  NumberInput,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInputField,
  NumberInputStepper,
  Box,
} from '@chakra-ui/react'
import { useQuery } from '@tanstack/react-query'
import moment from 'moment'
import { useContext, useState } from 'react'
import { SingleDatePicker, DateRangePicker } from 'react-dates'
import instance from 'worker'

import { useTablePageData } from 'modules/Tables/TablePageProvider'

import { AuthContext } from 'contexts'

import { Select, Input } from 'components'
import { SelectOption } from 'components/Select'

import { IResponseBase } from 'api/types'

import { relationGetDisplayValue } from 'utils/relational'

import { InputHeight } from './'
import './react_dates_overrides.css'
import { SingleAdvancedFilter } from './useAdvancedFilters'

export type FilterPropTypes<T> = {
  data: IResponseBase<any>[]
  advancedFilter: SingleAdvancedFilter<any, T>
  filterValue: T
  setFilterValue: (updater: T | ((prevVal: T) => T)) => void
}

export const SimpleFilterInput = ({
  filterValue,
  setFilterValue,
}: FilterPropTypes<string>) => {
  return (
    <Input
      type='text'
      height={InputHeight}
      placeholder='Value'
      value={filterValue}
      color='primary'
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        const newVal = e.target.value
        setFilterValue(newVal)
      }}
    />
  )
}

export const MultiFilterInput = ({
  advancedFilter,
  filterValue,
  setFilterValue,
}: FilterPropTypes<SelectOption<any>>) => {
  const { app, model, viewData } = useTablePageData()

  const { userInfo } = useContext(AuthContext)
  const selectedApp = app
  const premiumGroup = selectedApp.premiumGroup || []
  const premiumAccess = userInfo?.groups.includes(premiumGroup[0])
  const endpoint = premiumAccess
    ? selectedApp.premiumSlug || selectedApp.endpoint
    : selectedApp.endpoint
  const columnType =
    model.schema.columns.find((column) => column.key === advancedFilter.column)
      ?.type ?? undefined

  // DEBT: React select loading indicator / incremental loading
  const { data } = useQuery(
    [endpoint, model.endpoint, viewData, advancedFilter.column],
    () => {
      return instance.getAdvancedFilterMultiOptions({
        appEndpoint: endpoint,
        modelEndpoint: model.endpoint,
        viewData,
        columnName: advancedFilter.column,
        columnType,
      })
    }
  )

  return (
    <Box zIndex={100} maxW={'220px'}>
      <Select
        value={filterValue}
        isMulti={true}
        height={InputHeight}
        onChange={(x: any) => setFilterValue(x)}
        options={
          data
            ? data
                .sort()
                .map(relationGetDisplayValue)
                .map((x) => ({
                  label: x,
                  value: x,
                }))
            : []
        }
      />
    </Box>
  )
}

function parseNumber(x: string, base: number) {
  const parsed = parseInt(x, base)
  if (isNaN(parsed)) return 0
  return parsed
}

export const NumberFilterInput = ({
  filterValue,
  setFilterValue,
}: FilterPropTypes<number>) => {
  return (
    <NumberInput
      value={filterValue}
      min={0}
      onChange={(val) => {
        setFilterValue(parseNumber(val, 10))
      }}
    >
      <NumberInputField height={InputHeight} />
      <NumberInputStepper>
        <NumberIncrementStepper />
        <NumberDecrementStepper />
      </NumberInputStepper>
    </NumberInput>
  )
}

export const NumberBetweenFilterInput = ({
  filterValue,
  setFilterValue,
}: FilterPropTypes<number[]>) => {
  return (
    <Box display='flex'>
      <Box flex={1}>
        <NumberInput
          value={filterValue[0]}
          min={0}
          onChange={(val) => {
            setFilterValue((prev) => [parseNumber(val, 10), prev[1]])
          }}
        >
          <NumberInputField height={InputHeight} />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
      </Box>
      <Box flex={1}>
        <NumberInput
          value={filterValue[1]}
          min={0}
          onChange={(val) => {
            setFilterValue((prev) => [prev[0], parseNumber(val, 10)])
          }}
        >
          <NumberInputField height={InputHeight} />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
      </Box>
    </Box>
  )
}

export const DateFilterInput = ({
  filterValue,
  setFilterValue,
}: FilterPropTypes<Date>) => {
  const [focused, setFocused] = useState(false)

  return (
    <Box position='relative' zIndex={100}>
      <SingleDatePicker
        id='datePicker'
        hideKeyboardShortcutsPanel
        focused={focused}
        isOutsideRange={() => false}
        onDateChange={(date) => setFilterValue(date?.toDate() ?? new Date())}
        onFocusChange={({ focused }) => setFocused(focused)}
        date={moment(filterValue)}
      />
    </Box>
  )
}

export const DateBetweenFilterInput = ({
  filterValue,
  setFilterValue,
}: FilterPropTypes<Date[]>) => {
  const [focused, setFocused] = useState<'startDate' | 'endDate' | null>(null)

  return (
    <Box position='relative' zIndex={100}>
      <DateRangePicker
        hideKeyboardShortcutsPanel={true}
        startDateId='startDatePicker'
        endDateId='endDatePicker'
        isOutsideRange={() => false}
        onDatesChange={({ startDate, endDate }) =>
          setFilterValue([
            startDate?.toDate() ?? new Date(),
            endDate?.toDate() ?? new Date(),
          ])
        }
        focusedInput={focused}
        onFocusChange={(focused) => setFocused(focused)}
        startDate={moment(filterValue[0])}
        endDate={moment(filterValue[1])}
      />
    </Box>
  )
}

export const BooleanFilterInput = ({
  filterValue,
  setFilterValue,
}: FilterPropTypes<boolean>) => {
  const options = [
    {
      label: 'No',
      value: false,
    },
    {
      label: 'Yes',
      value: true,
    },
  ]

  const label = options.find((x) => x.value === filterValue)?.label

  return (
    <Box zIndex={100} width={300}>
      <Select
        value={{ label, value: filterValue }}
        height={InputHeight}
        onChange={(x: any) => setFilterValue(x?.value as boolean)}
        options={options}
      />
    </Box>
  )
}
