import { IApp } from 'interfaces/navigationApp.interface'

import { AccessGroups } from 'enums/AccessGroups'

import { RSVEpidemiologyCustomPages } from 'config/apps/RSV/Epidemiology/RSVEpidemiologyCustomPages'
import {
  RSVEpidemiologyMenu,
  RSVEpidemiologySlugs,
} from 'config/apps/RSV/Epidemiology/RSVEpidemiologyMenu'

const ModernaRSVEpidemiologyApp: IApp<RSVEpidemiologySlugs> = {
  name: 'RSV',
  slug: 'moderna-rsv-epidemiology',
  accessGroups: [AccessGroups.Moderna],
  commonIdentifier: 'rsv',
  endpoint: '',
  menu: RSVEpidemiologyMenu,
  pages: {},
  customPages: RSVEpidemiologyCustomPages,
  createdAt: new Date(2022, 5, 30),
}

export default ModernaRSVEpidemiologyApp
