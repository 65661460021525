import { IApp } from 'interfaces/navigationApp.interface'

import { AccessGroups } from 'enums/AccessGroups'

import { InfluenzaEpidemiologyCustomPages } from './InfluenzaEpidemiologyCustomPages'
import {
  InfluenzaEpidemiologyMenu,
  InfluenzaEpidemiologySlugs,
} from './InfluenzaEpidemiologyMenu'

const InfluenzaEpidemiology: IApp<InfluenzaEpidemiologySlugs> = {
  name: 'Epidemiology',
  slug: 'influenza-epidemiology',
  accessGroups: [AccessGroups.InfluenzaEpidemiology],
  endpoint: 'influenza-disease360',
  menu: InfluenzaEpidemiologyMenu,
  pages: {},
  customPages: InfluenzaEpidemiologyCustomPages,
  createdAt: new Date(2022, 1, 7),
}

export default InfluenzaEpidemiology
