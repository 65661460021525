import { Box, BoxProps } from '@chakra-ui/react'

interface ArrowProps extends BoxProps {
  direction?: 'up' | 'right' | 'down'
}

const Arrow = (props: ArrowProps) => {
  const { direction, ...rest } = props
  let borderWidth = '5px 4px 0 4px'
  let borderColor = '#423d33 transparent transparent transparent'

  if (direction === 'up') {
    borderWidth = '0px 4px 5px 4px'
    borderColor = 'transparent transparent #423d33 transparent'
  }

  if (direction === 'right') {
    borderWidth = '4px 0px 4px 5px'
    borderColor = 'transparent transparent transparent #423d33'
  }

  return (
    <Box
      {...rest}
      borderRadius='2px'
      width={0}
      height={0}
      borderStyle='solid'
      borderWidth={borderWidth}
      borderColor={borderColor}
    />
  )
}

export default Arrow
