import { Input as InputChakra, InputProps } from '@chakra-ui/react'

const Input: React.FC<React.PropsWithChildren<InputProps>> = (props) => {
  const {
    id,
    name,
    onChange,
    onBlur,
    placeholder,
    value,
    px = 2,
    py,
    type,
    height = '30px',
    borderRadius,
  } = props

  return (
    <InputChakra
      id={id}
      name={name}
      type={type}
      onChange={onChange}
      onBlur={onBlur}
      borderRadius={borderRadius}
      height={height}
      fontSize='sm'
      px={px}
      py={py}
      backgroundColor='gray.50'
      placeholder={placeholder}
      value={value}
      autoComplete='off'
      _placeholder={{
        color: 'gray.700',
      }}
      _hover={{
        borderColor: 'gray.300',
        bg: 'gray.50',
      }}
      _invalid={{
        boxShadow: '0 0 0 4px rgba(241, 103, 103, 0.2)',
        borderColor: '#cc7171',
      }}
      _focus={{
        outline: 'none',
        bg: 'gray.50',
        // boxShadow: "0 0 0 3px rgba(00,00,00,0.1)",
        borderColor: 'gray.400',
      }}
      {...props}
    />
  )
}

export default Input
