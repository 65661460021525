import { Chat } from '@carbon/icons-react'
import {
  Icon,
  IconButton,
  CloseButton,
  Slide,
  useDisclosure,
  Box,
  Text,
  Flex,
} from '@chakra-ui/react'

import useSubmitQuestionMutation from 'api/useSubmitQuestion'

import useTracking from 'tracking/useTracking'

import styles from './ChatWidget.module.css'
import ChatWidgetBody from './ChatWidgetBody'

const ChatWidget = () => {
  const { isOpen, onToggle } = useDisclosure()
  const apiStatus = useSubmitQuestionMutation()
  const [tracking] = useTracking()

  return (
    <>
      <IconButton
        className={styles.pulse_animation}
        aria-label='Ask our analysts'
        icon={<Chat size={32} />}
        position='absolute'
        bottom='0'
        rounded='full'
        variant='solid'
        fontSize='30px'
        right={2}
        width='55px'
        height='55px'
        color='black'
        zIndex={20}
        p={3}
        colorScheme='yellow'
        onClick={() => {
          onToggle()
          tracking.chatWidgetTrigger({})
        }}
      />

      <Slide
        direction='bottom'
        in={isOpen}
        style={{ zIndex: 20, left: 'none', bottom: '2rem', right: '1rem' }}
        unmountOnExit
      >
        <Box
          minH='600px'
          backgroundColor='white'
          mt='4'
          boxShadow='dark-lg'
          right='14px'
          width='25vw'
        >
          <Box
            backgroundColor='black'
            fontSize='lg'
            color='white'
            py='1rem'
            px='0.5rem'
            display='flex'
            justifyContent='space-between'
            borderBottom='4px solid'
            borderColor='yellow.500'
          >
            <Flex flexDir='row' alignItems='center'>
              <Icon as={Chat} pr={2} boxSize='2rem' ml={2} />
              <Text as='span' fontWeight='semibold'>
                Ask our Analysts
              </Text>
            </Flex>
            <CloseButton
              color='white'
              _focus={{ boxShadow: 'none' }}
              _hover={{
                transform: 'scale(1.1)',
                background: 'legacy-primary.400',
              }}
              onClick={() => {
                onToggle()
                apiStatus.reset()
              }}
            />
          </Box>
          <ChatWidgetBody apiStatus={apiStatus} />
        </Box>
      </Slide>
    </>
  )
}

export default ChatWidget
