export default {
  'Non-Replicating Viral Vector': 'green.100',
  'RNA-based vaccine': 'purple.100',
  'Protein subunit': 'blue.100',
  'Virus-like Particle': 'orange.100',
  'Non-replicating viral vector': 'rgb(207, 223, 255)',
  'DNA-based': 'rgb(194, 245, 233)',
  'Replicating viral vector': 'rgb(254, 226, 213)',
  'Recombinant Protein': 'rgb(255, 220, 229)',
  'Peptide Based': 'rgb(255, 218, 246)',
  'Live Attenuated Virus': 'rgb(237, 226, 254)',
  'Inactivated virus': 'rgb(238, 238, 238)',
  'Cellular vaccine': 'rgb(156, 199, 255)',
  Unknown: 'rgb(119, 209, 243)',
  'Replicating bacterial vector': 'rgb(114, 221, 195)',
  'Live Attenuated Bacteria': 'rgb(147, 224, 136)',
  'Virus-like Peptide': 'rgb(255, 214, 110)',
  Conjugated: 'rgb(255, 169, 129)',
  Other: 'rgb(238, 238, 238)',
  Nanoparticle: 'rgb(255, 234, 182)',
}
