export default {
  tablet: 'rgb(208, 240, 253)',
  capsule: 'rgb(39, 80, 174)',
  syrup: 'rgb(208, 240, 253)',
  Unknown: 'rgb(207, 223, 255)',
  'nasal spray': 'rgb(249, 157, 226)',
  suspension: 'rgb(45, 127, 249)',
  liquid: 'rgb(194, 245, 233)',
  aerosolised: 'rgb(255, 220, 229)',
  powder: 'rgb(102, 102, 102)',
  solvent: 'rgb(178, 21, 139)',
}
