import { IApp } from 'interfaces/navigationApp.interface'

import { AccessGroups } from 'enums/AccessGroups'

import { CardioConferenceCustomPages } from './CardioConferenceCustomPages'
import {
  CardioConferenceMenu,
  CardioConferenceSlugs,
} from './CardioConferenceMenu'
import { CardioConferencePages } from './CardioConferencePages'

const CardioConference: IApp<CardioConferenceSlugs> = {
  name: 'Conference Reports',
  slug: 'conference-data',
  accessGroups: [AccessGroups.CardioConference],
  endpoint: 'esc-2021',
  menu: CardioConferenceMenu,
  pages: CardioConferencePages,
  customPages: CardioConferenceCustomPages,
  createdAt: new Date(2021, 9, 13),
}

export default CardioConference
