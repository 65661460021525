/**
 * This file is JS because react-table doesn't come type definition for its internal code
 * The code here is a modification of the useFilters & useGlobalFilter hook
 *
 * Abstract:
 * Advanced filter allows the user to filter rows by specifying 3 things:
 * - Column to filter
 * - Filter type
 * - Value to filter by
 *
 * useFilters do these but there are some limitations which we add in this file/hook:
 * - User can change filter type on the go
 * - User can filter a single column with multiple filter types (eg: "name" column contains X and Y and does not start with Z)
 *
 * Core concepts for this hook:
 * - Each filter aren't tied to a single column
 * - The filters type is different from the original hook to accommodate our change
 *
 * See the .d.ts of this file for more info
 *
 * Notes:
 * - We cheat a bit here. Our filters definition are referred directly from this file.
 *   This avoids us having to pass it to the useTable hook through the tree.
 *
 * Caveats:
 * - Currently doesn't handle subRows. This can be added if we ever need it (See example from useFilters and useGlobalFilter)
 */
import { useCallback, useMemo } from "react";

import { filterRowFromAdvancedFilters } from "./filterFunctions";
import { actions, functionalUpdate } from "./reactTablePublicUtils";

// Actions
actions.resetAdvancedFilters = "resetAdvancedFilters";
actions.setAdvancedFilters = "setAdvancedFilters";

export const useAdvancedFilters = (hooks) => {
  hooks.stateReducers.push(reducer);
  hooks.useInstance.push(useInstance);
};

useAdvancedFilters.pluginName = "useAdvancedFilters";

function reducer(state, action, previousState, instance) {
  if (action.type === actions.init) {
    return {
      advancedFilters: [],
      ...state,
    };
  }

  if (action.type === actions.resetAdvancedFilters) {
    return {
      ...state,
      advancedFilters: instance.initialState.advancedFilters || [],
    };
  }

  if (action.type === actions.setAdvancedFilters) {
    const { updater } = action;

    return {
      ...state,
      advancedFilters: functionalUpdate(updater, state.advancedFilters),
    };
  }
}

function useInstance(instance) {
  const {
    rows,
    state: { advancedFilters },
    dispatch,
    manualAdvancedFilters,
  } = instance;

  const setAdvancedFilters = useCallback(
    (updater) => {
      dispatch({
        type: actions.setAdvancedFilters,
        updater,
      });
    },
    [dispatch]
  );

  const resetAdvancedFilters = useCallback(() => {
    dispatch({ type: actions.resetAdvancedFilters });
  }, [dispatch]);

  const filteredRows = useMemo(() => {
    if (manualAdvancedFilters) {
      return rows;
    }
    return filterRowFromAdvancedFilters(
      rows.map((row) => row.original),
      advancedFilters
    ).map((row) => rows.find((rtRow) => row.id === rtRow.original.id));
  }, [manualAdvancedFilters, advancedFilters, rows]);

  Object.assign(instance, {
    rows: filteredRows,
    flatRows: filteredRows,
    setAdvancedFilters,
    resetAdvancedFilters,
  });
}
