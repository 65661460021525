import { SortOrders } from 'enums/SortOrders'

import { CountriesModel, DealsModel } from 'config/common/baseModel'
import { RevenueModel } from 'config/common/baseModel/Revenue'
import { IDTreatmentPremiumDealsModel } from 'config/common/baseModel/ida360/IDPremiumDeals'

import {
  whiteListColumn,
  compose,
  renameColumn,
  updateColumnAttributes,
} from 'utils/overrideModel'
import { RespiratoryImmunisationScheduleModel } from 'config/common/baseModel/respiratory360/RespiratoryImmunisationSchedule'

export const SupplyAndProductionProductionDealsModel = compose<
  typeof DealsModel
>(
  whiteListColumn([
    'lookupVaccineName',
    'afDealType',
    'organisations',
    'countries',
    'afExpectedProduction',
    'mediaUrls',
    'pressUrls',
    'afTypeOfProduction',
    'afExpectedProduction2022',
    'afDateExpectedProductionLastAnnounced',
    'afPublicExpectedProductionNotes',
  ]),
  renameColumn('countries', 'Country source of production')
)(DealsModel)
export const SupplyAndProductionDealsModel = compose<typeof DealsModel>(
  whiteListColumn([
    'lookupVaccineName',
    'afDealType',
    'countries',
    'organisations',
    'afPriceDealType',
    'companyRegions',
    'afPricePerDoseVaccineUsd',
    'afPricePerVaccineTreatment',
    'notes',
    'mediaUrls',
    'pressUrls',
  ]),
  renameColumn('countries', 'Countries that have paid this price'),
  renameColumn('afPricePerDoseVaccineUsd', 'Price per dose (USD)'),
  renameColumn('companyRegions', 'Regions that have paid this price'),
  renameColumn('notes', 'Description'),
  updateColumnAttributes('countries', {
    width: 250,
  }),
  updateColumnAttributes('linkRegions', {
    width: 250,
  })
)(DealsModel)

export const DeliveriesCountryModel = compose<typeof CountriesModel>(
  whiteListColumn([
    'name',
    'population',
    'afEconomyIncomeBand',
    'afContinent',
    'fTotalDeliveries',
    'fDeliveriesToDateExcThoseDonated',
    'fBilateralDeliveryToDate',
    'fCovaxDeliveryToDate',
    'fDirectDonationDeliveryToDate',
  ]),
  renameColumn('name', 'Country'),
  renameColumn('afEconomyIncomeBand', 'Income Band')
)(CountriesModel)

export const SupplyAndProductionRevenueModel = compose<typeof RevenueModel>(
  whiteListColumn([
    'organisations',
    'financialYear',
    'quarter',
    'candidates',
    'designationB',
    'valueUsd',
    'areas',
    'sources',
  ]),
  renameColumn('organisations', 'Company'),
  renameColumn('candidates', 'Candidate'),
  renameColumn('valueUsd', 'Revenue (USD)'),
  renameColumn('sources', 'Source'),
  updateColumnAttributes('quarter', {
    width: 100,
  })
)({
  ...RevenueModel,
  defaultSortKey: 'financialYear',
  defaultSortOrder: SortOrders.DESC,
})

export const SupplyDealsDeliveriesDonationsModel = compose<
  typeof IDTreatmentPremiumDealsModel
>(
  whiteListColumn([
    'recipients',
    'recipientIncomeBand',
    'recipientRegion',
    'donors',
    'suppliers',
    'candidates',
    'designationB',
    'procurementMechanism',
    'market',
    'quantity',
    'quantityUnit',
    'quantityPotentialExpansion',
    'quantityMethodologyType',
    'quantityMethodologyDescription',
    'delivery',
    'deliveryToDateLastUpdated',
    'deliveryMethodologyType',
    'deliveryMethodologyDescription',
    'pricePerUnitUsd',
    'pricePerUnitMethodologyType',
    'pricePerUnitMethodologyDescription',
    'dateStart',
    'dateEnd',
    'agreementDate',
    'sources',
  ]),
  renameColumn('suppliers', 'Manufacturer'),
  renameColumn('pricePerUnitMethodologyType', 'Price Source Type')
)({
  ...IDTreatmentPremiumDealsModel,
  defaultSortKey: 'dateStart',
  defaultSortOrder: SortOrders.DESC,
})

export const SupplyDealsSupplyOrientedModel = compose<
  typeof IDTreatmentPremiumDealsModel
>(
  whiteListColumn([
    'recipients',
    'recipientIncomeBand',
    'recipientRegion',
    'donors',
    'suppliers',
    'candidates',
    'designationB',
    'procurementMechanism',
    'market',
    'quantity',
    'quantityUnit',
    'quantityPotentialExpansion',
    'quantityMethodologyType',
    'quantityMethodologyDescription',
    'delivery',
    'deliveryToDateLastUpdated',
    'deliveryMethodologyType',
    'deliveryMethodologyDescription',
    'dateStart',
    'dateEnd',
    'agreementDate',
    'sources',
  ]),
  renameColumn('donors', 'Donor')
)({
  ...IDTreatmentPremiumDealsModel,
  defaultSortKey: 'dateStart',
  name: 'Supply Deals',
  defaultSortOrder: SortOrders.DESC,
})

export const SupplyDealsPriceOrientedModel = compose<
  typeof IDTreatmentPremiumDealsModel
>(
  whiteListColumn([
    'recipients',
    'recipientIncomeBand',
    'recipientRegion',
    'suppliers',
    'candidates',
    'designationB',
    'market',
    'pricePerUnitUsd',
    'pricePerUnitMethodologyType',
    'pricePerUnitMethodologyDescription',
    'dateStart',
    'dateEnd',
    'sources',
  ]),
  renameColumn('pricePerUnitMethodologyType', 'Price Source Type')
)({
  ...IDTreatmentPremiumDealsModel,
  defaultSortKey: 'dateStart',
  name: 'Supply Deals',
  defaultSortOrder: SortOrders.DESC,
})

export const VaccineCampaignsModel = compose<
  typeof RespiratoryImmunisationScheduleModel
>(
  whiteListColumn([
    'areas',
    'hemisphere',
    'autumn2024Policy',
    'autumn2024PolicySource',
    'spring2024Policy',
    'spring2024PolicySource',
    'autumn2023Policy',
    'autumn2023PolicySource',
  ]),
  updateColumnAttributes('areas', {
    width: 250,
  }),
  renameColumn('areas', 'Country')
)({
  ...RespiratoryImmunisationScheduleModel,
  defaultSortObject: [
    {
      id: 'autumn2024Policy',
      sortOrder: SortOrders.ASC,
    },
    {
      id: 'spring2024Policy',
      sortOrder: SortOrders.ASC,
    },
    {
      id: 'areas',
      sortOrder: SortOrders.ASC,
    },
  ],
})
