import { UseMutateFunction } from '@tanstack/react-query'
import { AxiosError, AxiosResponse } from 'axios'
import React, { useContext, useCallback } from 'react'

import useCreateBookmarkMutation from 'api/bookmark/useBookmarkCreateMutation'
import useDeleteBookmarkMutation from 'api/bookmark/useBookmarkDeleteMutation'
import useBookmarkData from 'api/bookmark/useBookmarkList'
import { Bookmark } from 'api/types'

import { appsListUnion } from 'config/apps'

type ProviderType = {
  bookmarkedItems: Bookmark[]
  isLoading: boolean
  findReportBookmark: (
    app: appsListUnion,
    contentfulUpdateId: string
  ) => Bookmark | undefined
  createBookmark: UseMutateFunction<
    AxiosResponse<Bookmark>,
    AxiosError<any>,
    Omit<Bookmark, 'id'>
  >
  deleteBookmark: UseMutateFunction<
    AxiosResponse<Bookmark>,
    AxiosError<any>,
    {
      id: number
    }
  >
}

export const BookmarkContext = React.createContext<ProviderType>({
  bookmarkedItems: [],
  isLoading: false,
  findReportBookmark: () => undefined,
  createBookmark: () => {},
  deleteBookmark: () => {},
})

export const useBookmark = (): ProviderType => {
  return useContext(BookmarkContext)
}

export const BookmarkConsumer = BookmarkContext.Consumer

export const BookmarkProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const { data: bookmarkedItems, isLoading } = useBookmarkData()

  const { mutate: createBookmark } = useCreateBookmarkMutation()
  const { mutate: deleteBookmark } = useDeleteBookmarkMutation()

  const findReportBookmark = useCallback(
    (app: appsListUnion, contentfulUpdateId: string) => {
      return bookmarkedItems?.find(
        (b) =>
          b.app === app &&
          b.type === 'report' &&
          b.metadata.id === contentfulUpdateId
      )
    },
    [bookmarkedItems]
  )

  return (
    <BookmarkContext.Provider
      value={{
        bookmarkedItems: bookmarkedItems ?? [],
        isLoading,
        findReportBookmark,
        createBookmark,
        deleteBookmark,
      }}
    >
      {children}
    </BookmarkContext.Provider>
  )
}
