import { Box } from '@chakra-ui/react'
import urlParse from 'url-parse'

import { CleanResponseData } from 'api/types'

export type LogoValueType = {
  url: string
  label: string
}

type PropTypes = {
  value: LogoValueType | CleanResponseData
}

const Logo = ({ value }: PropTypes) => {
  if (
    !value ||
    Array.isArray(value) ||
    typeof value !== 'object' ||
    // It could be null, so we want to check undefined instead
    value?.url === undefined
  ) {
    console.error('Invalid value passed to LOGO type')
    return null
  }

  const parsedUrl = urlParse(value.url, {})
  const finalUrl = 'https://logo.clearbit.com/' + parsedUrl.origin

  return (
    <Box display='flex' alignItems='center' whiteSpace='nowrap'>
      <Box
        backgroundImage={`url(${finalUrl})`}
        minWidth='28px'
        height='28px'
        border='1px solid'
        borderColor='gray.200'
        rounded='sm'
        mr={2}
        flexShrink={0}
        backgroundSize='80%'
        backgroundPosition='center'
        backgroundRepeat='no-repeat'
      ></Box>
      {value.label}
    </Box>
  )
}

export default Logo
