import { Heading, Box, Text } from '@chakra-ui/react'
import moment from 'moment'

import { ItemComponentPropTypes } from 'modules/DetailDrawer/DetailDrawerList'

import { IDTreatments360ClinicalTrialsData } from 'config/common/baseModel/idtreatments360/IDTreatment360ClinicalTrials'

import { formatDate } from 'utils/formatDate'

const PadDetailDrawerListTrial = ({
  item,
  handleSelect,
}: ItemComponentPropTypes<Record<IDTreatments360ClinicalTrialsData, any>>) => {
  const { primaryCompletionDate, title, phasesClean, govId, fStudyCompletion } =
    item

  const completeDate = primaryCompletionDate
    ? moment(primaryCompletionDate)
    : fStudyCompletion
    ? moment(fStudyCompletion)
    : null

  return (
    <Box
      px={8}
      py={4}
      cursor='pointer'
      borderBottom='1px solid'
      borderColor='gray.100'
      onClick={() => handleSelect(item)}
      _hover={{
        bg: 'gray.100',
      }}
    >
      <Box
        display='flex'
        alignItems='center'
        fontSize='xs'
        fontWeight={600}
        textTransform='uppercase'
        color='gray.700'
      >
        <Box mr={2}>
          {completeDate?.isValid()
            ? `Completes on ${formatDate(completeDate)}`
            : `Complete date unknown`}
        </Box>
      </Box>
      {govId && (
        <Text fontSize='xs' fontWeight={600} color='gray.700'>
          Clinical Trial ID: {govId}
        </Text>
      )}
      {phasesClean && (
        <Text fontSize='xs' fontWeight={600} color='gray.700'>
          Phase: {phasesClean}
        </Text>
      )}
      <Heading fontSize='md' fontWeight={500} mt={1}>
        {title}
      </Heading>
    </Box>
  )
}

export { PadDetailDrawerListTrial }
