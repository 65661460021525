import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query'
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios'

import { appsListUnion } from 'config/apps'

import { UserView } from './types'
import useAxios from './useAxios'
import { getQueryKey } from './useUserViewData'

export default function useDeleteUserViewMutation(
  app: appsListUnion,
  pageKey: string,
  extraProps?: UseMutationOptions<
    AxiosResponse<UserView>,
    AxiosError<any>,
    { id: number }
  >
) {
  const axios = useAxios()
  const queryKey = getQueryKey(app, pageKey)

  const queryClient = useQueryClient()

  return useMutation<AxiosResponse<UserView>, AxiosError<any>, { id: number }>(
    ({ id }: { id: number }) => deleteUserView(axios, id),
    {
      onMutate: (newData) => {
        queryClient.cancelQueries(queryKey)

        const previousData: UserView[] | undefined =
          queryClient.getQueryData(queryKey)

        queryClient.setQueryData(
          queryKey,
          previousData?.filter((x) => x.id !== newData.id)
        )

        return () => queryClient.setQueryData(queryKey, previousData)
      },
      onError: (err, newData, rollback: any) => {
        rollback()
        queryClient.invalidateQueries(queryKey)
      },
      ...extraProps,
    }
  )
}

export const deleteUserView = async (
  axios: AxiosInstance,
  id: number
): Promise<AxiosResponse<UserView>> => {
  return await axios.delete(`/user-views/${id}/`)
}
