const warningMessages = {
  eligiblePopulation:
    'The eligibility data is based on approvals records publicly available. For the European Union area, the data collected is based on the EMA recommendations that apply to each country member. However, the eligible population can vary across state members based on their national eligibility policies.',
  pricing:
    'Airfinity collects RSV vaccine and non-vaccine price information made publicly available via media, press releases and other secondary sites.',
  revenue:
    "Airfinity tracks pharmaceutical companies' publicly shared quarterly earnings press releases to collect RSV vaccine and non-vaccine product sales revenue data per quarter and region since 2012, where available. Annotation such as location (region) as well as vaccine name/s (candidates) are as stated in the source. If reported in currency other than USD in the source, values have been exchanged to USD based on exchange rate that year. If more than one company reported revenue for the same product, e.g. AstraZeneca and Sobi for Synagis, both are presented in the dataset.",
}

export default warningMessages
