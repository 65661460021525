import { SelectColumnFilter } from 'components/Table/ColumnFilters'

import { IModel } from 'interfaces/model.interface'
import { ValueType } from 'interfaces/valueType.interface'

import { SortOrders } from 'enums/SortOrders'

import AdministrationRoute from '../tagColours/AdministrationRoute'
import DesignationA from '../tagColours/DesignationATypes'
import Impact from '../tagColours/Impact'
import Phase from '../tagColours/Phase'
import Priority from '../tagColours/Priority'
import TargetPopulation from '../tagColours/TargetPopulation'
import TreatmentType from '../tagColours/TreatmentType'
import VaccineType from '../tagColours/VaccineType'
import diseaseCategory from '../tagColours/diseaseCategory'

export type CandidateRankingData =
  | 'title'
  | 'rank'
  | 'lastWeekRank'
  | 'vaccine'
  | 'type'
  | 'storage'
  | 'transportShipping'
  | 'stage'
  | 'actionMechanism'
  | 'antigenDiversity'
  | 'deliveryMethod'
  | 'productionCapacity'
  | 'leads'
  | 'priority'
  | 'funding'
  | 'researchInstitutions'
  | 'clinicalTrials'
  | 'presentations'
  | 'science'
  | 'pressReleases'
  | 'media'
  | 'views'
  | 'downloads'
  | 'tweets'
  | 'preprintMentions'
  | 'deals'
  | 'approvals'
  | 'trials'
  | 'numberOfArticles'
  | 'afAntibodyType'
  | 'stageOfIntervention'
  | 'linkAfTrUniqueTreatmentNameToBnf'
  | 'lookupAfTrTypeFromBnf'
  | 'companies'
  | 'lookupAfTrMechanismOfActionFromBnf'
  | 'lookupAfTrRouteOfAdministrationFromBnf'
  | 'lookupAfTrClinicalTrialStageFromBnf'
  | 'lookupAfTrRepurposedFromBnf'
  | 'afAntiInflammatory'
  | 'lookupAfTrBnfUrlFromUrl'
  | 'lookupAfTrLicensedIndicationFromUrl'
  | 'lookupAfTrGenericAvailableFromBnf'
  | 'lookupAfTrContraindicationsFromBnf'
  | 'lookupAfTrPharmacompassLinkFromBnf'
  | 'lookupNameFromAfApiHolder'
  | 'afFilingForApproval'
  | 'afProtocol_URL'
  | 'afNumberOfDoses'
  | 'afTreatmentsFullCountryApproval'
  | 'afSumSupplyDealsAgreed'
  | 'afSumProduction'
  | 'afEmergencyApproval'
  | 'afRisk'
  | 'countryFirstDetected'
  | 'afSynonymousMutations'
  | 'pressReleasesByTitle'
  | 'mediaByTitle'
  | 'lookupAfTrOtherNamesFromBnf'
  | 'linkClinicalTrials'
  | 'afImplications'
  | 'afImpactVaccineEfficacy'
  | 'afImpactTreatmentEfficacy'
  | 'keywordList'
  | 'afFullApproval'
  | 'targetVariant'
  | 'afImpactVaccineSera'
  | 'afLimitedUse'
  | 'afRejected'
  | 'afSetting'
  | 'luCompaniesCountry'
  | 'luPathogen'
  | 'luPathogenType'
  | 'luPathogenFamily'
  | 'afPriority'
  | 'afTargetType'
  | 'afTargetSubtype'
  | 'afDetectionPrinciple'
  | 'afDetectionPrincipleSubtype'
  | 'afSpecimenCollection'
  | 'afSensitivityPercent'
  | 'afSpecificityPercent'
  | 'afRapid'
  | 'afTestTimeMinutes'
  | 'candidateNumber'
  | 'afSuspendedUse'
  | 'afSumOfDeliveries'
  | 'afPanCoronavirusVaccine'
  | 'afVariantImpact'
  | 'treatmentStudyDataMeta'
  | 'afBoosterDoseTiming'
  | 'epitopeInformation'
  | 'epitopeInformationReference'
  | 'approvedInAntivirals'
  | 'antiviralMechanismClass'
  | 'directVsHostTargeted'
  | 'rctResults'
  | 'afDoseQuantity'
  | 'afPanSarsCov2Vaccine'
  | 'airfinityComment'
  | 'commentLastModified'
  | 'afShelfLife'
  | 'organisations'
  | 'checkedVisaguideVaccineInvalid'
  | 'checkedVisaguideVaccineValid'
  | 'trialNames'
  | 'treatmentNameAlias'
  | 'patientSettingFromClinicalTrials'
  | 'patientSettingFromClinicalTrialsRollup'
  | 'countryPricesTx'
  | 'routesOfAdminInInpatientTrials'
  | 'routesOfAdminInOutpatientTrials'
  | 'routesOfAdminInPreventionTrials'
  | 'approvedInIndication'
  | 'investigatedInIndication2Or3Gt300'
  | 'inpatientRctResults'
  | 'mildOrModOutpatientRctResults'
  | 'preventionRctResults'
  | 'pivotalInpatientTrialsWithResults'
  | 'pivotalMildOrModOutpatientTrialsWithResults'
  | 'pivotalPreventionTrialsWithResults'
  | 'rctInpatientTrialsGt300Gt2Or3'
  | 'rctMildOrModOutpatientTrialsGt300Gt2Or3'
  | 'rctPreventionTrialsGt300Gt2Or3'
  | 'sumMildOrModOutpatientTrialsGt300Gt2Or3'
  | 'sumInpatientTrialsGt300Gt2Or3Copy'
  | 'sumPreventionTrialsGt300Gt2Or3'
  | 'latestPhaseInpatientTrials'
  | 'latestPhaseMildOrModOutpatientTrials'
  | 'latestPhasePreventionTrials'
  | 'countryAndApprovedPepSlashPrep'
  | 'latestPhaseTt'
  | 'latestPhasePad'
  | 'dosingRegimenTx'
  | 'approvalStatus'
  | 'diagnosticMachine'
  | 'limitOfDetection'
  | 'combinationVac'
  | 'targetTreatments'
  | 'approvedPreventionOnly'
  | 'approvedInpatientOnly'
  | 'approvedOutpatientOnly'
  | 'companyLocationLinkedFromOrganisations'
  | 'afApiHolder'
  | 'linkClinicalTrials'
  | 'linkCompanies'
  | 'linkOrganisations'
  | 'treatmentTiming'
  | 'linkInfectiousDisease'
  | 'candidateName'
  | 'candidateClass'
  | 'highestClinicalTrialPhase'
  | 'detailedDeliverySystem'
  | 'routeOfAdministration'
  | 'countryFromOrganisationsCentralised'
  | 'organisationsCentralised'
  | 'name'
  | 'vaccineValency'
  | 'vaccineAdjuvants'
  | 'vaccineTargetPopulation'
  | 'latestPhaseManual'
  | 'treatmentMechanismOfActionCategory'
  | 'treatmentPatientSettings'
  | 'treatmentStagesOfIntervention'
  | 'treatmentIsRepurposed'
  | 'treatmentIsRepurposedDetails'
  | 'treatmentHostTargeting'
  | 'diseaseName'
  | 'designationB'
  | 'designationA'
  | 'alternativeNames'
  | 'routeOfAdministration'
  | 'targetDomains'
  | 'targetViralStrain'
  | 'subType'
  | 'covidPriority'
  | 'covidTargetTreatments'
  | 'covidLookupAfTrRouteOfAdministrationFromBnf'
  | 'covidLookupAfTrClinicalTrialStageFromBnf'
  | 'covidLookupAfTrRepurposedFromBnf'
  | 'covidStageOfIntervention'
  | 'indication1'
  | 'product'
  | 'linkAffiliatedInstitutions'
  | 'rssArticleIds'
  | 'revenueIds'
  | 'dealIds'
  | 'diseaseCategory'
  | 'target'
  | 'linkDisease'
  | 'patientSetting'
  | 'luCentralDisease'
  | 'pathogenTypes'
  | 'approvalCountry'
  | 'frontendNotes'
  | 'latestPhaseManualDetails'
  | 'deliverySystemName'
  | 'activeDevelopment'
  | 'sponsors'
  | 'adjuvant'

const allowedAirtables = [
  'api_treatments_all',
  'api_treatments_antivirals',
  'api_treatments_passive_immunotherapies',
  'api_treatments_anti_inflammatory',
  'api_treatments_other',
  'api_phase_i_or_ii',
  'api_preclinical',
  'api_diagnostics',
  'api_phase_iii_or_iv',
  'api_all',
  'tt_union_tab_prophylaxis_api',
  'tt_union_tab_outpatient_api',
  'tt_union_tab_inpatient_api',
  'api_diag_p1',
  'api_diag_p2',
  'PAD Candidates_API_All',
  'PAD Candidates - Coronaviridae',
  'RNA_API_Candidates',
  'RNA_API_Candidates_Treatments',
  'RNA_API_Candidates_Vaccine',
  'api_mucosal_immunity_vaccines',
  'api_monovalent_bivalent',
  'api_pan-Coronavirus',
  'api_Pan-SARS-CoV2',
  'api_combination',
  'hpv_therapeutics',
  'hpv_vaccines',
  'id_vaccines',
  'id_all',
  'id_treatments',
  'id_amr',
  'FE Long COVID Candidates table - Correct columns',
  'api_approved',
] as const

export const CandidateRankingModel: IModel<
  Record<CandidateRankingData, string>,
  typeof allowedAirtables
> = {
  allowedAirtables,
  endpoint: 'candidate',
  name: 'Candidates',
  entityName: 'Candidates',
  searchField: 'vaccine',
  displayKey: 'vaccine',
  defaultSortKey: 'priority',
  defaultSortOrder: SortOrders.DESC,
  schema: {
    columns: [
      {
        key: 'rank',
        width: 50,
        label: '#',
        type: ValueType.NUMBER,
        isFilterable: false,
        headerStyle: { textAlign: 'center' },
        showDetail: false,
      },
      {
        key: 'revenueIds',
        label: 'Revenue Ids',
        type: ValueType.MULTI,
        width: 115,
      },
      {
        key: 'dealIds',
        label: 'Deal Ids',
        type: ValueType.MULTI,
        width: 115,
      },
      {
        key: 'lastWeekRank',
        label: 'Last week',
        type: ValueType.NUMBER,
        showDetail: false,
        width: 115,
      },
      {
        label: 'Vaccine',
        key: 'vaccine',
        width: 250,
        showDetail: false,
        type: ValueType.SINGLE,
      },
      {
        key: 'type',
        label: 'Technology Type',
        width: 200,
        type: ValueType.MULTI,
        cellFormat: { colours: VaccineType },
        Filter: SelectColumnFilter,
        filter: 'includes',
      },
      {
        key: 'subType',
        label: 'Technology Sub-Type',
        width: 200,
        type: ValueType.MULTI,
        cellFormat: { colours: VaccineType },
        Filter: SelectColumnFilter,
        filter: 'includes',
      },
      {
        key: 'stage',
        label: 'Stage',
        type: ValueType.SINGLE,
        width: 120,
        cellFormat: {
          colours: Phase,
        },
      },
      {
        key: 'storage',
        label: 'Storage',
        type: ValueType.TEXT,
        width: 400,
      },
      {
        key: 'transportShipping',
        label: 'Transport shipping',
        type: ValueType.TEXT,
        width: 400,
      },
      {
        key: 'actionMechanism',
        label: 'Mechanism of action',
        width: 400,
        truncate: false,
      },
      {
        key: 'antigenDiversity',
        label: 'Antigen diversity',
        type: ValueType.MULTI,
        width: 400,
        truncate: false,
      },
      {
        key: 'deliveryMethod',
        label: 'Delivery method',
        type: ValueType.SINGLE,
        width: 200,
      },
      {
        key: 'productionCapacity',
        label: 'Production capacity',
        type: ValueType.SINGLE,
        width: 300,
      },
      {
        label: 'Companies',
        key: 'leads',
        type: ValueType.MULTI,
        width: 200,
      },
      {
        key: 'priority',
        type: ValueType.SINGLE,
        label: 'Priority',
        width: 100,
        sortOrderObject: [
          'Discontinued',
          'Failed',
          'Rejected',
          'Priority 3',
          'Priority 2',
          'Priority 1',
          'Approved',
        ],
        accessor: (d: any) => d.priority && d.priority.toString(),
        cellFormat: {
          colours: Priority,
        },
      },
      {
        key: 'covidPriority',
        type: ValueType.SINGLE,
        label: 'Priority',
        width: 100,
        sortOrderObject: [
          'Discontinued',
          'Failed',
          'Rejected',
          'Priority 3',
          'Priority 2',
          'Priority 1',
          'Approved',
        ],
        accessor: (d: any) => d.covidPriority && d.covidPriority.toString(),
        cellFormat: {
          colours: Priority,
        },
      },
      {
        key: 'researchInstitutions',
        label: 'Research institutions',
        type: ValueType.MULTI,
        width: 360,
      },
      {
        key: 'linkAffiliatedInstitutions',
        label: 'Research Institutions',
        type: ValueType.MULTI,
        width: 360,
      },
      {
        key: 'presentations',
        label: 'Presentations',
        type: ValueType.MULTI,
        cellFormat: {
          maxTags: 2,
        },
        showTable: false,
        showDetail: false,
        width: 200,
      },
      {
        key: 'views',
        label: 'Views',
        width: 120,
        headerStyle: { textAlign: 'right' },
        cellFormat: { format: 'NUMBER' },
        type: ValueType.NUMBER,
      },
      {
        key: 'downloads',
        label: 'Downloads',
        width: 120,
        headerStyle: { textAlign: 'right' },
        cellFormat: { format: 'NUMBER' },
        type: ValueType.NUMBER,
      },
      {
        key: 'tweets',
        label: 'Tweets',
        width: 120,
        headerStyle: { textAlign: 'right' },
        cellFormat: { format: 'NUMBER' },
        type: ValueType.NUMBER,
      },
      {
        key: 'preprintMentions',
        label: 'Preprint mentions',
        width: 120,
        headerStyle: { textAlign: 'right' },
        cellFormat: { format: 'NUMBER' },
        type: ValueType.NUMBER,
      },
      {
        key: 'numberOfArticles',
        label: 'Paper mentions',
        width: 120,
        headerStyle: { textAlign: 'right' },
        cellFormat: { format: 'NUMBER' },
        type: ValueType.NUMBER,
      },
      {
        key: 'afAntibodyType',
        label: 'Technology Sub-Type',
        width: 260,
        type: ValueType.MULTI,
      },
      {
        key: 'stageOfIntervention',
        label: 'Stage of Intervention',
        type: ValueType.MULTI,
        width: 160,
        cellFormat: {
          colours: Impact,
        },
      },
      {
        key: 'covidStageOfIntervention',
        label: 'Stage of Intervention',
        type: ValueType.MULTI,
        width: 160,
        cellFormat: {
          colours: Impact,
        },
      },
      {
        key: 'linkAfTrUniqueTreatmentNameToBnf',
        label: 'Name',
        width: 350,
        type: ValueType.SINGLE,
      },
      {
        key: 'lookupAfTrTypeFromBnf',
        label: 'Type',
        type: ValueType.SINGLE,
        width: 290,
        cellFormat: {
          colours: TreatmentType,
        },
      },
      {
        key: 'companies',
        label: 'Primary Developers',
        type: ValueType.MULTI,
        width: 400,
      },
      {
        key: 'lookupAfTrMechanismOfActionFromBnf',
        label: 'Mechanism of action',
        type: ValueType.LONG,
        width: 400,
      },
      {
        key: 'lookupAfTrRouteOfAdministrationFromBnf',
        label: 'Administration route',
        type: ValueType.MULTI,
        width: 280,
      },
      {
        key: 'covidLookupAfTrRouteOfAdministrationFromBnf',
        label: 'Administration route',
        type: ValueType.MULTI,
        width: 280,
      },
      {
        key: 'lookupAfTrClinicalTrialStageFromBnf',
        label: 'Clinical trial stage',
        type: ValueType.SINGLE,
        width: 140,
        cellFormat: {
          colours: Phase,
        },
      },
      {
        key: 'covidLookupAfTrClinicalTrialStageFromBnf',
        label: 'Clinical trial stage',
        type: ValueType.SINGLE,
        width: 140,
        cellFormat: {
          colours: Phase,
        },
      },
      {
        key: 'lookupAfTrRepurposedFromBnf',
        label: 'Repurposed',
        type: ValueType.MULTI,
        width: 100,
        cellFormat: {
          colours: {
            Yes: 'rgb(147, 224, 136)',
            No: 'rgb(248, 43, 96)',
            'N/A': 'rgb(238, 238, 238)',
          },
        },
      },
      {
        key: 'covidLookupAfTrRepurposedFromBnf',
        label: 'Repurposed',
        type: ValueType.SINGLE,
        width: 100,
        cellFormat: {
          colours: {
            Yes: 'rgb(147, 224, 136)',
            No: 'rgb(248, 43, 96)',
            'N/A': 'rgb(238, 238, 238)',
          },
        },
      },
      {
        key: 'afAntiInflammatory',
        label: 'Anti-inflammatory',
        type: ValueType.SINGLE,
        width: 140,
      },
      {
        key: 'lookupAfTrBnfUrlFromUrl',
        label: 'BNF URL',
        type: ValueType.URL,
        width: 120,
      },
      {
        key: 'lookupAfTrLicensedIndicationFromUrl',
        label: 'BNF Licensed indication',
        width: 320,
        type: ValueType.MULTI,
      },
      {
        key: 'lookupAfTrGenericAvailableFromBnf',
        label: 'Generic available',
        width: 130,
        type: ValueType.SINGLE,
        cellFormat: {
          colours: {
            Yes: 'rgb(147, 224, 136)',
            No: 'rgb(248, 43, 96)',
            'N/A': 'rgb(238, 238, 238)',
          },
        },
      },
      {
        key: 'lookupAfTrContraindicationsFromBnf',
        label: 'Contraindications',
        width: 320,
        type: ValueType.MULTI,
      },
      {
        key: 'lookupAfTrPharmacompassLinkFromBnf',
        width: 120,
        type: ValueType.URL,
        label: 'Pharmacompass link',
        showTable: false,
      },
      {
        key: 'lookupNameFromAfApiHolder',
        label: 'Manufacturers',
        width: 280,
        type: ValueType.MULTI,
      },
      {
        key: 'afProtocol_URL',
        label: 'Protocol URL',
        width: 120,
        type: ValueType.URL,
      },
      {
        key: 'afFilingForApproval',
        label: 'Filling For Approval',
        width: 300,
        type: ValueType.MULTI,
      },
      {
        key: 'afNumberOfDoses',
        label: 'Number of Doses',
        width: 140,
        type: ValueType.TEXT,
      },
      {
        key: 'afTreatmentsFullCountryApproval',
        label: 'Country Approval',
        width: 250,
        type: ValueType.MULTI,
      },
      {
        key: 'approvalCountry',
        label: 'Country Approval',
        width: 250,
        type: ValueType.MULTI,
      },
      {
        key: 'afSumSupplyDealsAgreed',
        label: 'Total Supply Sold in doses',
        width: 150,
        type: ValueType.NUMBER,
      },
      {
        key: 'afSumProduction',
        label: 'Total Production of doses Agreed in 2021',
        width: 230,
        type: ValueType.NUMBER,
      },
      {
        key: 'afEmergencyApproval',
        label: 'Emergency Approval',
        width: 250,
        type: ValueType.MULTI,
      },
      {
        key: 'afRisk',
        label: 'Risk',
        width: 120,
        type: ValueType.MULTI,
      },
      {
        key: 'countryFirstDetected',
        label: 'Country',
        width: 170,
        type: ValueType.MULTI,
      },
      {
        key: 'afSynonymousMutations',
        label: 'Synonymous Mutations',
        width: 270,
        type: ValueType.MULTI,
      },
      {
        key: 'pressReleasesByTitle',
        label: 'Press Releases',
        width: 200,
        type: ValueType.MULTI,
      },
      {
        key: 'mediaByTitle',
        label: 'Media',
        width: 200,
        type: ValueType.MULTI,
      },
      {
        key: 'lookupAfTrOtherNamesFromBnf',
        label: 'Other Names From BNF',
        width: 230,
        type: ValueType.MULTI,
      },
      {
        key: 'linkClinicalTrials',
        label: 'Clinical Trials',
        width: 200,
        type: ValueType.MULTI,
      },
      {
        key: 'afImplications',
        label: 'Implications',
        width: 300,
        type: ValueType.TEXT,
      },
      {
        key: 'afImpactVaccineEfficacy',
        label: 'Vaccine Efficacy Impact',
        width: 200,
        type: ValueType.TEXT,
      },
      {
        key: 'afImpactTreatmentEfficacy',
        label: 'Treatment Efficacy Impact',
        width: 200,
        type: ValueType.TEXT,
      },
      {
        key: 'keywordList',
        label: 'Keyword List',
        width: 250,
        type: ValueType.MULTI,
      },
      {
        key: 'afFullApproval',
        label: 'Full Approval',
        width: 250,
        type: ValueType.MULTI,
      },
      {
        key: 'targetVariant',
        label: 'Target Variant',
        width: 200,
        type: ValueType.MULTI,
      },
      {
        key: 'afImpactVaccineSera',
        label: 'Impact Vaccine Sera',
        width: 400,
        type: ValueType.TEXT,
      },
      {
        key: 'afLimitedUse',
        label: 'Limited Use',
        width: 230,
        type: ValueType.TEXT,
      },
      {
        key: 'afRejected',
        label: 'Rejected',
        width: 150,
        type: ValueType.MULTI,
      },
      {
        key: 'afTestTimeMinutes',
        label: 'Time to Result',
        width: 150,
        type: ValueType.TEXT,
      },
      {
        key: 'afRapid',
        label: 'Rapid (Y/N)',
        width: 150,
        type: ValueType.SINGLE,
        tooltip:
          '"Rapid" is designated by subtype; serological/antigen tests ≤ 30 minutes, molecular tests  ≤ 60 minutes',
      },
      {
        key: 'afSpecificityPercent',
        label: 'Specificity (%)',
        width: 150,
        type: ValueType.TEXT,
        tooltip:
          'Tests ability to rule out all true negative results (or its ability to determine exclusively the analyte it intends to measure in the presence of off-target templates or interfering substances under well-controlled laboratory conditions)',
      },
      {
        key: 'afSensitivityPercent',
        label: 'Sensitivity (%)',
        width: 200,
        type: ValueType.TEXT,
        tooltip:
          'Tests ability to capture all true positive results (or the lowest amount of analyte that can be accurately measured through an assay)',
      },
      {
        key: 'afSpecimenCollection',
        label: 'Specimen collected',
        width: 300,
        type: ValueType.MULTI,
      },
      {
        key: 'afDetectionPrincipleSubtype',
        label: 'Detection principle subtype',
        width: 150,
        type: ValueType.MULTI,
      },
      {
        key: 'afDetectionPrinciple',
        label: 'Detection principle',
        width: 200,
        type: ValueType.SINGLE,
      },
      {
        key: 'afTargetSubtype',
        label: 'Target subtype',
        width: 150,
        type: ValueType.MULTI,
      },
      {
        key: 'afTargetType',
        label: 'Target type',
        width: 150,
        type: ValueType.SINGLE,
      },
      {
        key: 'luCompaniesCountry',
        label: 'Country of manufacturer',
        width: 150,
        type: ValueType.MULTI,
      },
      {
        key: 'afPriority',
        label: 'Priority',
        width: 150,
        type: ValueType.MULTI,
        tooltip:
          'Priority 1: Tests approved by the WHO for emergency use, and those gaining industry traction',
        sortOrderObject: [
          'Discontinued',
          'Rejected',
          'Priority 3',
          'Priority 2',
          'Priority 1',
          'Approved',
        ],
        cellFormat: {
          colours: Priority,
        },
      },
      {
        key: 'afSetting',
        label: 'Setting',
        width: 150,
        type: ValueType.MULTI,
      },
      {
        key: 'candidateNumber',
        label: 'Candidate number',
        width: 150,
        type: ValueType.TEXT,
      },
      {
        key: 'afSuspendedUse',
        label: 'Suspended Use',
        width: 400,
        type: ValueType.MULTI,
      },
      {
        key: 'afSumOfDeliveries',
        label: 'Deliveries of doses to date',
        width: 170,
        type: ValueType.NUMBER,
      },
      {
        key: 'afPanCoronavirusVaccine',
        label: 'Pan-coronavirus Vaccine',
        width: 190,
        type: ValueType.MULTI,
      },
      {
        key: 'afVariantImpact',
        label: 'Known variant impact',
        width: 250,
        type: ValueType.MULTI,
      },
      {
        key: 'treatmentStudyDataMeta',
        label: 'Treatment Study Data Meta',
        width: 250,
        type: ValueType.MULTI,
      },
      {
        key: 'afBoosterDoseTiming',
        label: 'Booster Dose Timing',
        width: 170,
        type: ValueType.MULTI,
      },
      {
        key: 'epitopeInformation',
        label: 'Epitope Information',
        width: 250,
        type: ValueType.TEXT,
      },
      {
        key: 'epitopeInformationReference',
        label: 'Epitope Information Source',
        width: 140,
        type: ValueType.URL,
      },
      {
        key: 'approvedInAntivirals',
        label: 'Approved for use in infections with:',
        width: 150,
        type: ValueType.MULTI,
      },
      {
        key: 'antiviralMechanismClass',
        label: 'Mechanism of Action',
        width: 200,
        type: ValueType.MULTI,
      },
      {
        key: 'directVsHostTargeted',
        label: 'Direct Vs Host (Targeted)',
        width: 220,
        type: ValueType.MULTI,
      },
      {
        key: 'rctResults',
        label: 'RCT Results',
        width: 110,
        type: ValueType.MULTI,
      },
      {
        key: 'afDoseQuantity',
        label: 'Dose Quantity',
        width: 170,
        type: ValueType.TEXT,
      },
      {
        key: 'afPanSarsCov2Vaccine',
        label: 'Pan SARS-CoV-2 Vaccine',
        width: 190,
        type: ValueType.MULTI,
      },
      {
        key: 'airfinityComment',
        label: 'Airfinity Progress Comment',
        width: 240,
        type: ValueType.LONG,
        cellFormat: {
          maxLines: 1,
        },
      },
      {
        key: 'frontendNotes',
        label: 'Notes',
        width: 240,
        type: ValueType.LONG,
      },
      {
        key: 'commentLastModified',
        label: 'Airfinity Progress Comment Last Updated',
        width: 220,
        type: ValueType.DATE,
      },
      {
        key: 'afShelfLife',
        label: 'Shelf Life',
        width: 150,
        type: ValueType.TEXT,
      },
      {
        key: 'organisations',
        label: 'Primary Developers',
        width: 150,
        type: ValueType.MULTI,
      },
      {
        key: 'sponsors',
        label: 'Primary Developers',
        width: 150,
        type: ValueType.MULTI,
      },
      {
        key: 'checkedVisaguideVaccineInvalid',
        label: 'Countries vaccine is not authorised for entry',
        width: 180,
        type: ValueType.MULTI,
      },
      {
        key: 'checkedVisaguideVaccineValid',
        label: 'Countries vaccine is authorised for entry',
        width: 180,
        type: ValueType.MULTI,
      },
      {
        key: 'trialNames',
        label: 'Clinical Trials',
        width: 180,
        type: ValueType.MULTI,
      },
      {
        key: 'treatmentNameAlias',
        label: 'Alternative Names',
        width: 180,
        type: ValueType.MULTI,
      },
      {
        key: 'patientSettingFromClinicalTrials',
        label: 'Patient Setting',
        width: 180,
        type: ValueType.MULTI,
        cellFormat: {
          colours: Phase,
        },
      },
      {
        key: 'patientSettingFromClinicalTrialsRollup',
        label: 'Patient Setting',
        width: 180,
        type: ValueType.MULTI,
        cellFormat: {
          colours: Phase,
        },
      },
      {
        key: 'countryPricesTx',
        label: 'Price',
        width: 180,
        type: ValueType.MULTI,
      },
      {
        key: 'routesOfAdminInInpatientTrials',
        label: 'ROA Inpatient Trials',
        width: 180,
        type: ValueType.MULTI,
        cellFormat: {
          colours: AdministrationRoute,
        },
      },
      {
        key: 'routeOfAdministration',
        label: 'Routes of Administration',
        width: 180,
        type: ValueType.MULTI,
        cellFormat: {
          colours: AdministrationRoute,
        },
      },
      {
        key: 'targetViralStrain',
        label: 'Target Strains',
        width: 300,
        type: ValueType.MULTI,
        cellFormat: {
          colours: AdministrationRoute,
        },
      },
      {
        key: 'targetDomains',
        label: 'Target Domains',
        width: 180,
        type: ValueType.MULTI,
        cellFormat: {
          colours: AdministrationRoute,
        },
      },
      {
        key: 'routesOfAdminInOutpatientTrials',
        label: 'ROA Outpatient Trials',
        width: 180,
        type: ValueType.MULTI,
        cellFormat: {
          colours: AdministrationRoute,
        },
      },
      {
        key: 'routesOfAdminInPreventionTrials',
        label: 'ROA Prevention Trials',
        width: 180,
        type: ValueType.MULTI,
        cellFormat: {
          colours: AdministrationRoute,
        },
      },
      {
        key: 'approvedInIndication',
        label: 'Approved Indication',
        width: 180,
        type: ValueType.MULTI,
      },
      {
        key: 'investigatedInIndication2Or3Gt300',
        label: 'Indication Investigated In',
        width: 180,
        type: ValueType.MULTI,
        cellFormat: {
          colours: {
            Outpatient: 'rgb(193, 246, 233)',
            Inpatient: 'rgb(207, 241, 255)',
            Prevention: 'rgb(39, 80, 174)',
          },
        },
      },
      {
        key: 'inpatientRctResults',
        label: 'Has Inpatient RCT Results?',
        width: 180,
        type: ValueType.MULTI,
        cellFormat: {
          colours: {
            Yes: 'rgb(147, 224, 136)',
            No: 'rgb(248, 43, 96)',
            'N/A': 'rgb(238, 238, 238)',
          },
        },
      },
      {
        key: 'mildOrModOutpatientRctResults',
        label: 'Has Outpatient RCT Results?',
        width: 180,
        type: ValueType.MULTI,
        cellFormat: {
          colours: {
            Yes: 'rgb(147, 224, 136)',
            No: 'rgb(248, 43, 96)',
            'N/A': 'rgb(238, 238, 238)',
          },
        },
      },
      {
        key: 'preventionRctResults',
        label: 'Has Prevention RCT Results?',
        width: 180,
        type: ValueType.MULTI,
        cellFormat: {
          colours: {
            Yes: 'rgb(147, 224, 136)',
            No: 'rgb(248, 43, 96)',
            'N/A': 'rgb(238, 238, 238)',
          },
        },
      },
      {
        key: 'pivotalInpatientTrialsWithResults',
        label: 'Pivotal Inpatient Trials With Results',
        width: 180,
        type: ValueType.MULTI,
      },
      {
        key: 'pivotalMildOrModOutpatientTrialsWithResults',
        label: 'Pivotal Outpatient Trials With Results',
        width: 180,
        type: ValueType.MULTI,
      },
      {
        key: 'pivotalPreventionTrialsWithResults',
        label: 'Pivotal Prevention Trials With Results',
        width: 180,
        type: ValueType.MULTI,
      },
      {
        key: 'rctInpatientTrialsGt300Gt2Or3',
        label: 'Inpatient RCTs',
        width: 180,
        type: ValueType.MULTI,
      },
      {
        key: 'rctMildOrModOutpatientTrialsGt300Gt2Or3',
        label: 'Outpatient RCTs',
        width: 180,
        type: ValueType.MULTI,
      },
      {
        key: 'rctPreventionTrialsGt300Gt2Or3',
        label: 'Prevention RCTs',
        width: 180,
        type: ValueType.MULTI,
      },
      {
        key: 'sumMildOrModOutpatientTrialsGt300Gt2Or3',
        label: 'No. Outpatient Trials',
        width: 180,
        type: ValueType.NUMBER,
      },
      {
        key: 'sumInpatientTrialsGt300Gt2Or3Copy',
        label: 'No. Inpatient Trials',
        width: 180,
        type: ValueType.NUMBER,
      },
      {
        key: 'sumPreventionTrialsGt300Gt2Or3',
        label: 'No. Prevention Trials',
        width: 180,
        type: ValueType.NUMBER,
      },
      {
        key: 'latestPhaseInpatientTrials',
        label: 'Latest Inpatient Trial Stage',
        width: 180,
        type: ValueType.MULTI,
        cellFormat: {
          colours: Phase,
        },
      },
      {
        key: 'latestPhaseMildOrModOutpatientTrials',
        label: 'Latest Outpatient Trial Stage',
        width: 180,
        type: ValueType.MULTI,
        cellFormat: {
          colours: Phase,
        },
      },
      {
        key: 'latestPhasePreventionTrials',
        label: 'Latest Prevention Trial Stage',
        width: 180,
        type: ValueType.MULTI,
        cellFormat: {
          colours: Phase,
        },
      },
      {
        key: 'countryAndApprovedPepSlashPrep',
        label: 'Approved PEP/PrEP',
        width: 180,
        type: ValueType.MULTI,
      },
      {
        key: 'latestPhaseTt',
        label: 'Latest Phase (All Trials)',
        width: 180,
        type: ValueType.MULTI,
        sortOrderObject: ['I', 'II', 'III', 'IV'],
        accessor: (d: any) => d.latestPhaseTt && d.latestPhaseTt.toString(),
        cellFormat: {
          colours: Phase,
        },
      },
      {
        key: 'latestPhasePad',
        label: 'Latest Phase Pad',
        width: 180,
        type: ValueType.MULTI,
        cellFormat: {
          colours: Phase,
        },
      },
      {
        key: 'dosingRegimenTx',
        label: 'Dosing Regiment',
        width: 320,
        type: ValueType.TEXT,
      },
      {
        key: 'approvalStatus',
        label: 'Approval Status',
        width: 150,
        type: ValueType.MULTI,
      },
      {
        key: 'diagnosticMachine',
        label: 'Diagnostic Machine',
        width: 150,
        type: ValueType.MULTI,
      },
      {
        key: 'limitOfDetection',
        label: 'Limit Of Detection',
        width: 150,
        type: ValueType.TEXT,
      },
      {
        key: 'combinationVac',
        label: 'Combination Vaccine',
        width: 170,
        type: ValueType.MULTI,
      },
      {
        key: 'targetTreatments',
        label: 'Target',
        width: 200,
        type: ValueType.MULTI,
      },
      {
        key: 'covidTargetTreatments',
        label: 'Target',
        width: 200,
        type: ValueType.MULTI,
      },
      {
        key: 'approvedPreventionOnly',
        label: 'Approved for Prevention',
        width: 200,
        type: ValueType.MULTI,
      },
      {
        key: 'approvedInpatientOnly',
        label: 'Approved for Inpatient',
        width: 200,
        type: ValueType.MULTI,
      },
      {
        key: 'approvedOutpatientOnly',
        label: 'Approved for Outpatient',
        width: 200,
        type: ValueType.MULTI,
      },
      {
        key: 'companyLocationLinkedFromOrganisations',
        label: 'Developer Location',
        width: 180,
        type: ValueType.MULTI,
      },
      {
        key: 'afApiHolder',
        label: 'Manufacturers',
        width: 180,
        type: ValueType.MULTI,
      },
      {
        key: 'linkInfectiousDisease',
        label: 'Link Infectious Disease',
        width: 180,
        type: ValueType.MULTI,
      },
      {
        key: 'linkClinicalTrials',
        label: 'Clinical Trials',
        width: 180,
        type: ValueType.MULTI,
      },
      {
        key: 'linkCompanies',
        label: 'Link Companies',
        width: 180,
        type: ValueType.MULTI,
      },
      {
        key: 'linkOrganisations',
        label: 'Link Organisations',
        width: 180,
        type: ValueType.MULTI,
      },
      {
        key: 'luPathogen',
        label: 'Lu Pathogen',
        width: 180,
        type: ValueType.TEXT,
      },
      {
        key: 'luPathogenType',
        label: 'Lu Pathogen Type',
        width: 180,
        type: ValueType.MULTI,
      },
      {
        key: 'pathogenTypes',
        label: 'Pathogen Types',
        width: 180,
        type: ValueType.MULTI,
      },
      {
        key: 'luPathogenFamily',
        label: 'Lu Pathogen Family',
        width: 180,
      },
      {
        key: 'organisationsCentralised',
        label: 'Primary Developers',
        type: ValueType.MULTI,
        width: 150,
      },
      {
        key: 'countryFromOrganisationsCentralised',
        label: 'Developer Location',
        type: ValueType.MULTI,
        width: 150,
      },
      {
        key: 'candidateName',
        label: 'Name',
        type: ValueType.TEXT,
        width: 150,
      },
      {
        key: 'candidateClass',
        label: 'Candidate Class',
        type: ValueType.MULTI,
        width: 150,
      },
      {
        key: 'highestClinicalTrialPhase',
        label: 'Highest Clinical Trial Phase',
        type: ValueType.MULTI,
        width: 200,
        cellFormat: {
          colours: Phase,
        },
      },
      {
        key: 'detailedDeliverySystem',
        label: 'Delivery System',
        type: ValueType.MULTI,
        width: 150,
      },
      {
        key: 'treatmentTiming',
        label: 'Treatment Timing',
        width: 180,
        type: ValueType.MULTI,
      },
      {
        key: 'organisationsCentralised',
        label: 'Developers',
        type: ValueType.MULTI,
        width: 150,
      },
      {
        key: 'countryFromOrganisationsCentralised',
        label: 'Developer Location',
        type: ValueType.MULTI,
        width: 150,
      },
      {
        key: 'candidateName',
        label: 'Name',
        type: ValueType.TEXT,
        width: 150,
      },
      {
        key: 'candidateClass',
        label: 'Candidate Class',
        type: ValueType.MULTI,
        width: 150,
      },
      {
        key: 'highestClinicalTrialPhase',
        label: 'Highest Clinical Trial Phase',
        type: ValueType.MULTI,
        width: 200,
        cellFormat: {
          colours: Phase,
        },
      },
      {
        key: 'detailedDeliverySystem',
        label: 'Delivery System',
        type: ValueType.MULTI,
        width: 150,
      },
      {
        key: 'routeOfAdministration',
        label: 'Route of Administration',
        type: ValueType.MULTI,
        width: 180,
      },
      {
        key: 'organisationsCentralised',
        label: 'Developers',
        type: ValueType.MULTI,
        width: 150,
      },
      {
        key: 'countryFromOrganisationsCentralised',
        label: 'Developer Location',
        type: ValueType.MULTI,
        width: 150,
      },
      {
        key: 'name',
        label: 'Name',
        type: ValueType.TEXT,
        width: 150,
      },
      {
        key: 'vaccineValency',
        label: 'Valency',
        type: ValueType.TEXT,
        width: 150,
      },
      {
        key: 'vaccineAdjuvants',
        label: 'Adjuvant',
        width: 150,
        type: ValueType.MULTI,
      },
      {
        key: 'vaccineTargetPopulation',
        label: 'Target Population',
        width: 180,
        type: ValueType.MULTI,
        cellFormat: {
          colours: TargetPopulation,
        },
      },
      {
        key: 'latestPhaseManual',
        label: 'Phase',
        width: 180,
        type: ValueType.MULTI,
        cellFormat: {
          colours: Phase,
        },
      },
      {
        key: 'treatmentMechanismOfActionCategory',
        label: 'Mechanism of Action',
        width: 180,
        type: ValueType.MULTI,
      },
      {
        key: 'treatmentPatientSettings',
        label: 'Patient Settings',
        width: 180,
        type: ValueType.MULTI,
      },
      {
        key: 'treatmentStagesOfIntervention',
        label: 'Stages of Intervention',
        width: 180,
        type: ValueType.MULTI,
      },
      {
        key: 'treatmentIsRepurposed',
        label: 'Is Repurposed',
        width: 180,
        type: ValueType.MULTI,
      },
      {
        key: 'treatmentIsRepurposedDetails',
        label: 'Repurposed Details',
        width: 180,
        type: ValueType.MULTI,
      },
      {
        key: 'treatmentHostTargeting',
        label: 'Host Targeting',
        width: 180,
        type: ValueType.MULTI,
      },
      {
        key: 'diseaseName',
        label: 'Diseases',
        width: 180,
        type: ValueType.MULTI,
      },
      {
        key: 'designationA',
        label: 'Vaccine or Treatment',
        width: 180,
        type: ValueType.MULTI,
        cellFormat: {
          colours: DesignationA,
        },
      },
      {
        key: 'designationB',
        label: 'Category',
        width: 180,
        type: ValueType.MULTI,
      },

      {
        key: 'alternativeNames',
        label: 'Alternative Names',
        width: 180,
        type: ValueType.MULTI,
        fallbackValue: 'None Found',
      },
      {
        key: 'indication1',
        label: 'Indication',
        width: 180,
        type: ValueType.MULTI,
      },
      {
        key: 'product',
        label: 'Product',
        width: 180,
        type: ValueType.MULTI,
      },
      {
        key: 'rssArticleIds',
        label: 'Rss Feed Ids',
        width: 180,
        type: ValueType.MULTI,
      },
      {
        key: 'diseaseCategory',
        label: 'Disease Category',
        width: 180,
        type: ValueType.TEXT,
        cellFormat: {
          colours: diseaseCategory,
        },
      },
      {
        key: 'target',
        label: 'Target Domains',
        width: 180,
        type: ValueType.MULTI,
      },
      {
        key: 'linkDisease',
        label: 'Indication',
        width: 180,
        type: ValueType.MULTI,
      },
      {
        key: 'patientSetting',
        label: 'Patient Setting',
        width: 180,
        type: ValueType.MULTI,
      },
      {
        key: 'luCentralDisease',
        label: 'Disease',
        width: 180,
        type: ValueType.MULTI,
      },
      {
        key: 'latestPhaseManualDetails',
        label: 'Stage Detail',
        width: 180,
        type: ValueType.MULTI,
      },
      {
        key: 'activeDevelopment',
        label: 'Phase Detail',
        width: 180,
        type: ValueType.MULTI,
      },
      {
        key: 'deliverySystemName',
        label: 'Delivery System',
        width: 180,
        type: ValueType.MULTI,
      },
      {
        key: 'adjuvant',
        label: 'Adjuvant',
        width: 180,
        type: ValueType.MULTI,
      },
    ],
  },
}
