import GenericTable from 'modules/Tables'

import { IModel } from 'interfaces/model.interface'
import {
  INavigationPages,
  ISingleNavigationPage,
} from 'interfaces/navigationPage.interface'

import { CardioConferenceSlugs } from './CardioConferenceMenu'
import {
  EASD021ConferenceTweetsModel,
  CardioConferenceConferencePresentationsModel,
  CardioConferenceTweetsModel,
  CardioConferenceConferencePresentationsModelESAD,
} from './CardioConferenceModel'

type CardioConferenceNavigationPage<TModel extends IModel<any>> =
  ISingleNavigationPage<CardioConferenceSlugs, TModel>

const ConferencePresentationEASD: CardioConferenceNavigationPage<
  typeof CardioConferenceConferencePresentationsModel
> = {
  key: 'ConferencePresentations',
  path: ['conference-presentations', 'easd'],
  component: GenericTable,
  model: CardioConferenceConferencePresentationsModelESAD,
  views: [
    {
      name: 'Default',
      airtableName: 'api_easd_presentations_for_boehringer_ingelheim',
      airtableBase: 'cardiosov',
    },
  ],
}
const ConferencePresentationESC: CardioConferenceNavigationPage<
  typeof CardioConferenceConferencePresentationsModel
> = {
  key: 'ConferencePresentations',
  path: ['conference-presentations', 'esc'],
  component: GenericTable,
  model: CardioConferenceConferencePresentationsModel,
  views: [
    {
      name: 'Default',
      airtableName: 'api_esc_presentations_for_boehringer_ingelheim',
      airtableBase: 'cardiosov',
    },
  ],
}

const TweetsEASD: CardioConferenceNavigationPage<
  typeof EASD021ConferenceTweetsModel
> = {
  key: 'Tweets',
  path: ['social-media-activity', 'easd'],
  component: GenericTable,
  model: EASD021ConferenceTweetsModel,
  views: [
    {
      name: 'Default',
      airtableName: 'api_easd_2021_social_for_boehringer_ingelheim',
      airtableBase: 'cardiosov',
    },
  ],
}

const TweetsESC: CardioConferenceNavigationPage<
  typeof CardioConferenceTweetsModel
> = {
  key: 'Tweets',
  path: ['social-media-activity', 'esc'],
  component: GenericTable,
  model: CardioConferenceTweetsModel,
  views: [
    {
      name: 'Default',
      airtableName: 'api_esc_2021_social_for_boehringer_ingelheim',
      airtableBase: 'cardiosov',
    },
  ],
}

export const CardioConferencePages: INavigationPages<CardioConferenceSlugs> = {
  ConferencePresentationEASD,
  ConferencePresentationESC,
  TweetsESC,
  TweetsEASD,
}
