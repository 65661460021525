import { INavigationMenu } from 'interfaces/navigationMenu.interface'

export type EpidemiologySlugs =
  | 'overview'
  | 'vaccinations'
  | 'variants'
  | 'latest-news'
  | 'impact'
  | 'reports'
  | 'variant-prevalence'
  | 'cases-hospitalisation-deaths'
  | 'covid-pandemic-overview'
  | 'respiratory-disease-cases'
  | 'protection-vaccinations'
  | 'initial-and-boosters'
  | 'research-briefs'
  | 'standard'
  | 'wastewater'

const EpidemiologyMenu: Array<INavigationMenu<EpidemiologySlugs>[]> = [
  [
    {
      title: 'Epid Overview',
      slug: 'overview',
      defaultOpen: true,
      description: '',
      createdAt: new Date(2022, 0, 25),
      children: [
        {
          title: 'Cases, Hospitalisations, Deaths',
          slug: 'cases-hospitalisation-deaths',
          createdAt: new Date(2024, 1, 28),
        },
        {
          title: 'COVID-19 Pandemic Overview',
          slug: 'covid-pandemic-overview',
          createdAt: new Date(2024, 0, 1),
        },
        {
          title: 'Respiratory Disease Cases',
          slug: 'respiratory-disease-cases',
          createdAt: new Date(2024, 1, 28),
        },
        {
          title: 'Wastewater',
          slug: 'wastewater',
          createdAt: new Date(2024, 3, 11),
          updatedAt: new Date(2024, 5, 27),
        },
      ],
    },
    {
      title: 'Vaccinations',
      slug: 'vaccinations',
      createdAt: new Date(2022, 1, 7),
      children: [
        // {
        //   title: 'Overview',
        //   slug: 'overview',
        //   createdAt: new Date(2021, 11, 14),
        // },
        {
          title: 'COVID-19 Pandemic Overview',
          slug: 'covid-pandemic-overview',
          createdAt: new Date(2021, 11, 1),
          newUntil: new Date(2021, 11, 14),
        },
      ],
    },
    {
      title: 'Variants',
      description: '',
      slug: 'variants',
      createdAt: new Date(2022, 1, 7),
      children: [
        {
          title: 'Latest News',
          slug: 'latest-news',
          createdAt: new Date(2022, 1, 7),
        },
        {
          title: 'Variant Prevalence',
          slug: 'variant-prevalence',
          createdAt: new Date(2024, 3, 11),
        },
        {
          title: 'Impact of Variants',
          slug: 'impact',
          createdAt: new Date(2022, 1, 7),
        },
      ],
    },
    {
      title: 'Reports',
      slug: 'reports',
      createdAt: new Date(2022, 12, 6),
      children: [
        {
          title: 'Reports',
          slug: 'standard',
          createdAt: new Date(2022, 3, 13),
        },
        {
          title: 'Research Briefs',
          slug: 'research-briefs',
          createdAt: new Date(2022, 7, 24),
        },
      ],
    },
  ],
]

export { EpidemiologyMenu }
