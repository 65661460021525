import { Box, Button, Flex, Input } from '@chakra-ui/react'
import { useState } from 'react'

import {
  Panel,
  PanelBody,
  PanelHeader,
  PanelTitle,
  TableauEmbed,
} from 'components'
import { TableauPaths } from 'components/Embed/paths'

import useTableauAuth from 'api/useTableauAuth'

const cleanUrl = (url: string) => {
  url = url.trim()
  const PREFIX = 'https://tableau.airfinity.com/#/views'
  if (url.startsWith(PREFIX)) {
    url = url.slice(PREFIX.length)
  }
  return url.split('?')[0]
}

const TableauDemo = () => {
  const [path, setPath] = useState('')
  const [display, setDisplay] = useState(false)
  const { data, refetch } = useTableauAuth({
    cacheTime: 0,
    staleTime: 0,
  })
  return (
    <Panel overflow='hidden' height='100%'>
      <PanelHeader>
        <Box display='flex' flexDirection='row' alignItems='center'>
          <PanelTitle>Tableau Preview Tool</PanelTitle>
        </Box>
      </PanelHeader>
      <PanelBody>
        <Flex justifyContent='center' alignItems='center' gap='8px'>
          <Input
            value={path}
            placeholder='Enter Tableau Path'
            height='40px'
            width='800px'
            my={2}
            onChange={(e) => {
              setDisplay(false)
              setPath(cleanUrl(e.target.value))
            }}
          />
          <Button
            variant='yellow'
            onClick={() => {
              setDisplay(true)
              refetch()
            }}
            height='fit-content'
          >
            Display
          </Button>
        </Flex>
        {display && <TableauEmbed token={data} path={path as TableauPaths} />}
      </PanelBody>
    </Panel>
  )
}

export default TableauDemo
