import { IApp } from 'interfaces/navigationApp.interface'

import { AccessGroups } from 'enums/AccessGroups'

import { Covid19CommercialVaccinesCustomPages } from './Covid19CommercialVaccinesCustomPages'
import {
  Covid19CommercialVaccinesSlugs,
  Covid19CommercialVaccinesMenu,
} from './Covid19CommercialVaccinesMenu'
import { Covid19CommercialVaccinesPages } from './Covid19CommercialVaccinesPages'

const CovidCommercialVaccinesApp: IApp<Covid19CommercialVaccinesSlugs> = {
  name: 'Vaccines',
  slug: 'covid-19-commercial-vaccines',
  commonIdentifier: 'vaccines',
  accessGroups: [AccessGroups.CovidCommercialVaccines],
  endpoint: 'supply-and-production',
  menu: Covid19CommercialVaccinesMenu,
  pages: Covid19CommercialVaccinesPages,
  customPages: Covid19CommercialVaccinesCustomPages,
  createdAt: new Date(2021, 5, 8),
  defaultEntrySlug: ['demand', 'vaccine-uptake'],
}

export default CovidCommercialVaccinesApp
