import { IApp } from 'interfaces/navigationApp.interface'

import { AccessGroups } from 'enums/AccessGroups'

import { PNHCustomPages } from './PNHCustomPages'
import { PNHMenu, PNHSlugs } from './PNHMenu'
import { PNHPages } from './PNHPages'

const PNHApp: IApp<PNHSlugs> = {
  name: 'PNH',
  slug: 'pnh',
  accessGroups: [AccessGroups.PNH],
  endpoint: 'pnh-app',
  menu: PNHMenu,
  pages: PNHPages,
  customPages: PNHCustomPages,
  createdAt: new Date(2021, 10, 29),
}

export default PNHApp
