import { Box, VStack, CloseButton, Fade, Flex, Text } from '@chakra-ui/react'
import { NAV_HEIGHT } from 'constants/misc'
import { useMemo } from 'react'
import { animated, config, useSpring } from 'react-spring'
import { useDebounce } from 'use-debounce'

import { useAppRoute } from 'routes/utils'

import { useGlobalSearch } from 'contexts/GlobalSearch'

import { InlineError, InlineLoading } from 'components'

import useSearchEntities from 'api/useSearchEntities'
import useSearchReports from 'api/useSearchReports'
import useSearchTableau from 'api/useSearchTableau'

import { IModel } from 'interfaces/model.interface'
import { ISingleNavigationPage } from 'interfaces/navigationPage.interface'

import { useAppletFromApp } from 'config/applets'
import { apps } from 'config/apps'

import { nativeSortTypeFromList } from 'utils/sort'
import useThrottle from 'utils/useThrottle'

import EmptySearch from './EmptySearch'
import GlobalSearchInput from './GlobalSearchInput'
import useNewPages from './NewPage/useNewPages'
import { SearchResults } from './SearchResults'

const AnimatedBox = animated(Box)

type PropTypes = {
  children: React.ReactNode
}

const GlobalSearchOverlay = ({ children }: PropTypes) => {
  const { overlayShown, globalSearchString, reset } = useGlobalSearch()
  const searchString = useThrottle(globalSearchString, 200)
  const [reportSearchString] = useDebounce(globalSearchString, 1500)

  const selectedApp = useAppRoute()
  const selectedAppObject = useMemo(() => apps[selectedApp], [selectedApp])
  const selectedApplet = useAppletFromApp(selectedApp)
  const pages = selectedAppObject.pages
  const { hasReports } = selectedAppObject

  const { data, isLoading, error } = useSearchEntities(
    selectedApp,
    searchString
  )

  const {
    data: reportSearchData,
    isLoading: reportSearchIsLoading,
    error: reportSearchError,
  } = useSearchReports(selectedApp, reportSearchString, {}, hasReports)

  const { data: tableauSearchData, isLoading: tableauSearchIsLoading } =
    useSearchTableau(selectedApp, reportSearchString)

  const pageSectionToRender = useMemo(() => {
    if (!data) {
      return []
    }

    const pageList = Array.from(new Set(data.map((x) => x.endpoint)))
      .map((endpoint) =>
        Object.values(pages).find((page) => page.model.endpoint === endpoint)
      )
      .filter((page) => page !== undefined)
      .filter((page) => page?.path !== null) as ISingleNavigationPage<
      any,
      IModel<any, any>
    >[]

    const initialSortedPageList = pageList.sort((a, b) =>
      ('' + a.model.name).localeCompare(b.model.name)
    )

    const customSortedPageList = selectedAppObject.globalSearchSortModelEndpoint
      ? initialSortedPageList.sort(
          nativeSortTypeFromList(
            selectedAppObject.globalSearchSortModelEndpoint ?? [],
            'model.endpoint'
          )
        )
      : initialSortedPageList

    return customSortedPageList
  }, [data, pages, selectedAppObject.globalSearchSortModelEndpoint])

  const newPageData = useNewPages()

  const springAnim = useSpring({
    height: overlayShown ? '100%' : '0%',
    o: overlayShown ? 1 : 0,
    config: config.stiff,
  })

  return (
    <Box position='relative'>
      <Box
        display='flex'
        flexDirection='column'
        height={`calc(100vh - ${NAV_HEIGHT})`}
      >
        {children}
      </Box>

      <Fade in={overlayShown}>
        <Box
          height={`calc(100vh - ${NAV_HEIGHT})`}
          overflow='auto'
          bg='rgb(0, 0, 0, 0.5)'
          position='absolute'
          top={0}
          left={0}
          right={0}
          zIndex={100}
          pointerEvents={overlayShown ? 'all' : 'none'}
          onClick={reset}
        />
      </Fade>

      <AnimatedBox
        style={{
          height: springAnim.height,
          overflow: springAnim.o.to((o) => (o === 1 ? 'auto' : 'hidden')),
        }}
        position='absolute'
        top={0}
        left={0}
        right={0}
        zIndex={150}
        mx='32px'
        bgColor='white'
      >
        <Box pt={6} bg='white' height='100%'>
          <Box
            maxW='1080px'
            mx='auto'
            data-cy='global-search-overlay-container'
          >
            <Flex justifyContent='space-between' alignItems='center'>
              <Text fontWeight='semibold' fontSize='28px' color='primary'>
                Search in {selectedApplet?.name} - {selectedAppObject.name}
              </Text>

              <CloseButton onClick={reset} />
            </Flex>

            <Box mb='24px' />

            {overlayShown && <GlobalSearchInput />}

            <Box mb='32px' />

            <Flex>
              <Box w='100%'>
                {globalSearchString !== '' ? (
                  <>
                    {(isLoading || reportSearchIsLoading) && (
                      <VStack
                        display='flex'
                        py={6}
                        alignItems='center'
                        width='100%'
                        justifyContent='center'
                      >
                        <VStack display='flex' align='flex-start' mb='1rem'>
                          <Box display='flex'>
                            <Text mr='3' fontWeight='400' fontSize='lg'>
                              Searching in the Database
                            </Text>
                            {isLoading ? ' ...' : '✅'}
                          </Box>
                          <Box display='flex'>
                            <Text mr='3' fontWeight='400' fontSize='lg'>
                              Searching in Reports
                            </Text>
                            {reportSearchIsLoading ? '...' : '✅'}
                          </Box>
                          <Box display='flex' fontWeight='400' fontSize='lg'>
                            <Text mr='3'>Searching in Visualizations</Text>
                            {tableauSearchIsLoading ? '...' : '✅'}
                          </Box>
                        </VStack>
                        <InlineLoading />
                      </VStack>
                    )}
                    {(error || reportSearchError) && <InlineError />}
                    {data?.length === 0 &&
                      newPageData.length === 0 &&
                      reportSearchData?.length === 0 &&
                      tableauSearchData?.length === 0 && (
                        <Text>No results found.</Text>
                      )}
                    {data && !(isLoading || reportSearchIsLoading) && (
                      <SearchResults
                        data={data}
                        searchString={globalSearchString}
                        pageSectionToRender={pageSectionToRender}
                        reportSearchData={reportSearchData}
                        tableauSearchData={tableauSearchData}
                        newPageData={newPageData}
                      />
                    )}
                  </>
                ) : null}
                {globalSearchString === '' && <EmptySearch />}
              </Box>
            </Flex>
          </Box>
        </Box>
      </AnimatedBox>
    </Box>
  )
}

export default GlobalSearchOverlay
