import { Box } from '@chakra-ui/react'
import { useEffect } from 'react'
import {
  useFlexLayout,
  useResizeColumns,
  useSortBy,
  useTable,
} from 'react-table'

import { IResponseBase } from 'api/types'

import generateColumns, { CustomColumn } from 'utils/generateColumns'
import useScrollbarWidth from 'utils/useScrollBarWidth'

import TableBody from '../TableBody'
import TableHeader from '../TableHeader'
import { defaultColumn } from '../index'
import { useTableLayout } from '../provider/TableLayoutBox'
import { useTableParentProps } from '../provider/TableParentProps'
import { useTableUseTableProps } from '../provider/TableUseTableProps'
import { useTableState } from '../useTableState'

type PropTypes = {
  data: IResponseBase<any>[]
}

export default ({ data }: PropTypes) => {
  const { parentProps } = useTableParentProps()
  const { useTableData } = useTableUseTableProps()

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    visibleColumns,
    prepareRow,
    setState,
  } = useTable(
    {
      // Stops infinite loop (By opening multiple child table)
      // @ts-ignore
      autoResetHiddenColumns: false,
      // @ts-ignore
      autoResetResize: false,
      columns: parentProps
        ? (generateColumns(
            parentProps.model.schema.columns
          ) as CustomColumn<any>[])
        : [],
      data,
      defaultColumn,
    },
    useSortBy,
    useFlexLayout,
    useResizeColumns,
    useTableState
  )

  useEffect(() => {
    if (useTableData?.state && !!setState) {
      setState(useTableData?.state)
    }
  }, [setState, useTableData?.state])

  const onRowClick = parentProps.onRowClick

  // We make a shallow copy of each object here since this value is mutable from react-table.
  // If we use it directly, react memo will always think it's the same value.
  const headerGroupsCopy = headerGroups.map((x) => ({ ...x }))

  if (!parentProps) {
    return null
  }

  return (
    <ChildTableDynamicLayoutBox>
      <Box
        {...getTableProps()}
        minWidth='100% !important'
        className={!!onRowClick ? 'clickable' : ''}
        display='table'
        fontWeight={500}
      >
        <TableHeader
          headerGroups={headerGroupsCopy}
          isLoading={false}
          isChildTable={true}
        />
        <TableBody
          page={rows}
          isLoading={false}
          visibleColumns={visibleColumns}
          getTableBodyProps={getTableBodyProps}
          onRowClick={onRowClick}
          prepareRow={prepareRow}
          isChildTable={true}
        />
      </Box>
    </ChildTableDynamicLayoutBox>
  )
}

const ChildTableDynamicLayoutBox = (props: { children: React.ReactNode }) => {
  const { position, size } = useTableLayout()
  const sbw = useScrollbarWidth()

  return (
    <Box
      style={{
        width: size.width - (sbw ?? 0),
        transform: `translateX(${position.x}px)`,
      }}
      borderTop='1px solid'
      borderBottom='1px solid'
      borderColor={'gray3'}
      height='100%'
      overflowX='auto'
      overflowY='auto'
    >
      {props.children}
    </Box>
  )
}
