export default {
  '6-10 Hours After Surgery': 'rgb(11, 118, 183)',
  'Adjunct to a low-fat diet and other lipid-lowering treatments, including LDL apheresis where available':
    'rgb(147, 224, 136)',
  'Adjunct to diet in statin intolerance/contraindication':
    'rgb(207, 223, 255)',
  'Adjunct to statin if triglycerides/HDL-cholesterol inadequately controlled':
    'rgb(24, 191, 255)',
  'Aged <18 Years': 'rgb(248, 43, 96)',
  'Aged 75+ Years': 'rgb(24, 191, 255)',
  CHF: 'rgb(208, 240, 253)',
  'Completion of 5 days anti-coagulative treatment': 'rgb(207, 223, 255)',
  'Completion of 6 months anti-coagulative treatment': 'rgb(255, 218, 246)',
  'Completion of 10 day initial Dabigatran prophylaxis': 'rgb(252, 180, 0)',
  'Completion of initial treatment for DVT and PE (21 days)':
    'rgb(32, 201, 51)',
  'Complex co-morbidities': 'rgb(147, 224, 136)',
  'Congestive heart failure (CHF)': 'rgb(114, 221, 195)',
  'Diabetes ': 'rgb(194, 245, 233)',
  'Diabetes Mellitus (DM)': 'rgb(248, 43, 96)',
  'High risk of recurrence of DVT, PE  after 10mg Q.D Rivaroxaban)':
    'rgb(39, 80, 174)',
  'High triglycerides and low HDL-cholesterol levels': 'rgb(186, 30, 69)',
  Hypertension: 'rgb(209, 247, 196)',
  'In combination with other lipid-lowering therapies': 'rgb(207, 223, 255)',
  'Inadequate response to other appropriate lipid lowering measures (e.g. statins)':
    'rgb(11, 118, 183)',
  'Intolerence to statins': 'rgb(209, 247, 196)',
  'N/A': 'rgb(255, 8, 194)',
  'Not Indicated': 'rgb(215, 77, 38)',
  'Prior Stroke': 'rgb(207, 223, 255)',
  'Statin Contra-inidicated': 'rgb(107, 28, 176)',
  'Symptomatic Heart failure': 'rgb(32, 217, 210)',
  'Transient Ischaemic attack (TIA)': 'rgb(45, 127, 249)',
  'Weight: ≥50kg': 'rgb(147, 224, 136)',
  'Low Fat Diet': 'rgb(68, 68, 68)',
  'Aged 12+ Years': 'rgb(24, 191, 255)',
  'Not responded adequately to other appropriate measures':
    'rgb(205, 176, 255)',
  'Adjunct to other low-density lipoprotein-cholesterol lowering therapies':
    'rgb(102, 102, 102)',
  'Aged 10+ Years': 'rgb(32, 201, 51)',
  'In patients with triglycerides ≥150mg/dL and either established cardiovascular disease, or diabetes with at least 1 other cardiovascular risk factor':
    'rgb(207, 223, 255)',
  'Triglycerides ≥ 150 mg/dL': 'rgb(252, 180, 0)',
  'Elevated triglycerides (≥ 150 mg/dL) OR [≥ 1.7 mmol/L)':
    'rgb(255, 169, 129)',
  'Diabetes AND one other cardiovascular risk factor': 'rgb(209, 247, 196)',
  'Diabetes Mellitus AND 2+ risk factors for CVD': 'rgb(184, 117, 3)',
  'Hypertriglyceridemia (≥ 500 mg/dL) ': 'rgb(139, 70, 255)',
  'High risk of cardiovascular events': 'rgb(254, 226, 213)',
  'Adequate LDL-C levels following pravastatin use': 'rgb(156, 199, 255)',
  'Adjunct to diet': 'rgb(255, 218, 246)',
  'Genetic diagnosis of FCS': 'rgb(249, 157, 226)',
  'High risk of pancreatitis': 'rgb(207, 223, 255)',
  iPad: 'rgb(207, 223, 255)',
  'Creatinine clearance 50-95 mL/min': 'rgb(215, 77, 38)',
}
