import { INavigationMenu } from 'interfaces/navigationMenu.interface'

export type RSVCommercialSlugs =
  | ''
  | 'eligible-population'
  | 'supply-price'
  | 'pricing-table'
  | 'price-dashboard'
  | 'revenue'
  | 'table'
  | 'revenue-dashboard'
  | 'reports'
  | 'research-briefs'

const RSVCommercialMenu: Array<INavigationMenu<RSVCommercialSlugs>[]> = [
  [
    // {
    //   title: 'Demand Forecast',
    //   slug: '',
    //   createdAt: new Date(2023, 10, 16),
    // },
    {
      title: 'Eligible Population',
      slug: 'eligible-population',
      createdAt: new Date(2023, 10, 16),
    },
    {
      title: 'Supply & Price',
      slug: 'supply-price',
      createdAt: new Date(2023, 10, 16),
      children: [
        {
          title: 'Pricing Table',
          slug: 'pricing-table',
          createdAt: new Date(2023, 10, 16),
        },
        {
          title: 'Price Dashboard',
          slug: 'price-dashboard',
          createdAt: new Date(2023, 10, 30),
        },
      ],
    },
    {
      title: 'Revenue',
      slug: 'revenue',
      createdAt: new Date(2023, 10, 16),
      children: [
        {
          title: 'Table',
          slug: 'table',
          createdAt: new Date(2023, 10, 16),
        },
        {
          title: 'Revenue Dashboard',
          slug: 'revenue-dashboard',
          createdAt: new Date(2023, 10, 30),
        },
      ],
    },
    {
      title: 'Reports',
      slug: 'reports',
      createdAt: new Date(2022, 6, 11),
      children: [
        {
          title: 'Reports',
          slug: 'reports',
          createdAt: new Date(2022, 6, 11),
        },
        {
          title: 'Research Briefs',
          slug: 'research-briefs',
          createdAt: new Date(2022, 7, 24),
        },
      ],
    },
  ],
]

export { RSVCommercialMenu }
