import { Information, Launch } from '@carbon/icons-react'
import { Box, Text } from '@chakra-ui/react'
import { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { ArrowContainer, Popover } from 'react-tiny-popover'

const NewNotePopover = ({
  message,
  navigate,
  newUntil,
}: {
  message?: string
  navigate?: string
  newUntil?: Date
}) => {
  const [isOpen, setIsPopoverOpen] = useState(false)
  if (!message) return null

  if (newUntil && newUntil < new Date()) return null
  return (
    <Popover
      isOpen={isOpen}
      positions={['right', 'left', 'bottom', 'top']}
      onClickOutside={() => setIsPopoverOpen(false)}
      content={({ position, childRect, popoverRect }) => (
        <ArrowContainer
          position={position}
          childRect={childRect}
          popoverRect={popoverRect}
          arrowColor='lightgrey'
          arrowSize={10}
          arrowStyle={{
            opacity: 0.7,
          }}
        >
          <Box
            bgColor='white'
            maxW={'500px'}
            marginTop='24px'
            borderRadius={15}
            p='24px'
            fontSize={'sm'}
            boxShadow='lg'
            border={'1px solid'}
            borderColor='gray.200'
          >
            <Text>{message}</Text>
            {navigate && (
              <NavLink to={navigate}>
                <Box display={'flex'} alignItems={'center'} gap={1}>
                  <Text fontWeight={'semibold'} color='black'>
                    Navigate
                  </Text>
                  <Launch />
                </Box>
              </NavLink>
            )}
          </Box>
        </ArrowContainer>
      )}
    >
      <Box
        onClick={() => setIsPopoverOpen((x) => !x)}
        width='fit-content'
        cursor='pointer'
        ml='1rem'
        mt={1}
      >
        <Box
          display={'flex'}
          gap={1}
          color='black'
          bg={'yellow.500'}
          rounded={'sm'}
          alignItems={'center'}
          shadow={'sm'}
          fontSize='12px'
          px={2}
          py={1}
        >
          <Information size={14} />
          <Text>New</Text>
        </Box>
      </Box>
    </Popover>
  )
}

export default NewNotePopover
