import DashboardReports from 'modules/DashboardReports'

import { useAppRoute } from 'routes/utils'

import { apps } from 'config/apps'

type PropTypes = {
  extraKey?: string
}

export default function WidgetComponentReport({ extraKey }: PropTypes) {
  const app = useAppRoute()

  return <DashboardReports app={apps[app]} extraKey={extraKey} />
}
