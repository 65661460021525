import { Heading, Box } from '@chakra-ui/react'

import Details from 'modules/Summary'

import { EntityPreviewDetailViewPropTypes } from 'interfaces/entity.interface'

import { relationGetDisplayValue } from 'utils/relational'

export default function EntityPreviewDetailViewBase({
  model,
  data,
  viewData,
}: EntityPreviewDetailViewPropTypes) {
  return (
    <Box display='flex' overflow='hidden' flexDirection='column' height='100%'>
      <Heading
        as='h4'
        fontWeight={600}
        fontSize='md'
        bg='gray.100'
        color='legacy-primary.500'
        px={4}
        py={4}
        borderBottom='1px solid'
        borderColor='gray.400'
      >
        {relationGetDisplayValue(data[model.displayKey ?? ''])}
      </Heading>
      <Box flex={1} overflow='auto' p={4} zIndex={1000}>
        <Details
          data={data}
          model={model}
          titleKey={model.displayKey}
          viewData={viewData}
        />
      </Box>
    </Box>
  )
}
