export default {
  '3-ketoacyl-CoA thiolase inhibitor': 'rgb(255, 220, 229)',
  '5-lipoxygenase': 'rgb(248, 43, 96)',
  'adenosine A2 receptor agonist': 'rgb(205, 176, 255)',
  'adenosine triphosphate citrate lyase': 'rgb(139, 70, 255)',
  'advanced glycation end-products': 'rgb(6, 160, 155)',
  'aldose reductase': 'rgb(156, 199, 255)',
  'angiotensin-II receptor': 'rgb(102, 102, 102)',
  ANGPTL3: 'rgb(255, 169, 129)',
  'Anticoagulant Antidote': 'rgb(184, 117, 3)',
  'Anticoagulant Reversal Agent': 'rgb(32, 217, 210)',
  Apixaban: 'rgb(249, 157, 226)',
  ApoA: 'rgb(255, 158, 183)',
  'ApoC-III': 'rgb(156, 199, 255)',
  'ATP-sensitive potassium channel': 'rgb(184, 117, 3)',
  'atrial NPRA': 'rgb(186, 30, 69)',
  'BET domain': 'rgb(147, 224, 136)',
  'blood vessels': 'rgb(249, 157, 226)',
  'calcium channel': 'rgb(209, 247, 196)',
  'cardiac myosin': 'rgb(255, 169, 129)',
  'cardiac troponin C': 'rgb(139, 70, 255)',
  CETP: 'rgb(255, 220, 229)',
  'circulating copper': 'rgb(68, 68, 68)',
  Dabigatran: 'rgb(119, 209, 243)',
  'endothelial lipase': 'rgb(204, 204, 204)',
  'endothelin receptor': 'rgb(39, 80, 174)',
  'factor II inhibitor': 'rgb(32, 201, 51)',
  'Factor IX Stimulant': 'rgb(255, 234, 182)',
  'Factor VII Stimulant': 'rgb(156, 199, 255)',
  'Factor X Stimulant': 'rgb(237, 226, 254)',
  'Factor Xa Inhibitor': 'rgb(208, 240, 253)',
  'Factor XI Inhibitor': 'rgb(255, 8, 194)',
  'Factor XIa Inhibitor': 'rgb(51, 138, 23)',
  'Factor XII Inhibitor': 'rgb(184, 117, 3)',
  'Factor XIIa Inhibitor': 'rgb(255, 220, 229)',
  'fatty acid phospholipase A2': 'rgb(248, 43, 96)',
  'FGF-receptors': 'rgb(194, 245, 233)',
  'GLP-1R': 'rgb(114, 221, 195)',
  'GPCR auto-antibodies': 'rgb(184, 117, 3)',
  'gut microbiota': 'rgb(156, 199, 255)',
  'heart tissue': 'rgb(156, 199, 255)',
  HGF: 'rgb(194, 245, 233)',
  'HMG-CoA reductase': 'rgb(119, 209, 243)',
  lipids: 'rgb(252, 180, 0)',
  'Lp(a)': 'rgb(114, 221, 195)',
  MR: 'rgb(119, 209, 243)',
  'MTOR protein inhibitor': 'rgb(156, 199, 255)',
  MTTP: 'rgb(178, 21, 139)',
  myeloperoxidase: 'rgb(255, 220, 229)',
  'Na+/K+ ATPase': 'rgb(207, 223, 255)',
  neprilysin: 'rgb(107, 28, 176)',
  'neuregulin-1': 'rgb(102, 102, 102)',
  NKCC: 'rgb(207, 223, 255)',
  NPC1L1: 'rgb(255, 158, 183)',
  'oxidised low density lipoprotein': 'rgb(255, 111, 44)',
  'P2Y12 Receptor Inhibitor': 'rgb(255, 158, 183)',
  'p38 MAPK': 'rgb(107, 28, 176)',
  pace: 'rgb(254, 226, 213)',
  'pacemaker cation channel': 'rgb(107, 28, 176)',
  'partial fatty acid oxidation inhibitor': 'rgb(6, 160, 155)',
  PCSK9: 'rgb(139, 70, 255)',
  PDE9: 'rgb(11, 118, 183)',
  'PET enhancer': 'rgb(139, 70, 255)',
  'Plasminogen Activator': 'rgb(139, 70, 255)',
  pleiotropic: 'rgb(255, 220, 229)',
  'PPAR-alpha': 'rgb(255, 218, 246)',
  'PPAR-gamma': 'rgb(107, 28, 176)',
  'prostaglandin E1': 'rgb(252, 180, 0)',
  'Protein C Inhibitor': 'rgb(255, 220, 229)',
  'protein phosphatase 1': 'rgb(68, 68, 68)',
  'Prothrombin Stimulant': 'rgb(178, 21, 139)',
  'Relaxin ThP': 'rgb(255, 111, 44)',
  Rivaroxaban: 'rgb(249, 157, 226)',
  'sarcoplasmic reticulum': 'rgb(237, 226, 254)',
  'Serine Protease Inhibitor': 'rgb(156, 199, 255)',
  sGC: 'rgb(194, 245, 233)',
  SGLT2: 'rgb(11, 118, 183)',
  SLC22A12: 'rgb(207, 223, 255)',
  SREBP2: 'rgb(205, 176, 255)',
  'supplemental Factors II,VII,IX and X and inhibitor proteins S and C':
    'rgb(32, 201, 51)',
  'Thrombin Inhibitor': 'rgb(209, 247, 196)',
  'thyroid hormone receptor': 'rgb(186, 30, 69)',
  'tubulin beta chain': 'rgb(114, 221, 195)',
  unknown: 'rgb(238, 238, 238)',
  'vasopressin receptor': 'rgb(207, 223, 255)',
  'VEGF-A': 'rgb(178, 21, 139)',
  'Vitamin K Epoxide Reductase': 'rgb(32, 201, 51)',
  'VKORC1 Inhibitor': 'rgb(207, 223, 255)',
  'VKOR Inhibitor': 'rgb(207, 223, 255)',
  'Vitamin K Antagonist': 'rgb(207, 223, 255)',
}
