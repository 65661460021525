import { Box, Flex, Heading, Skeleton, Text } from '@chakra-ui/react'
import { memo, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { useGlobalSearch } from 'contexts/GlobalSearch'

import { tableauMapping } from 'components/Embed/tableauSearchIndex'

import { TableauSearchType } from 'api/types'
import useAxios from 'api/useAxios'
import { permissionCheck } from 'api/useTableauAuth'

import resolvePath from 'utils/resolvePath'

import { SearchResultsCount } from './SearchResultsCount'

type PropTypes = {
  tableauSearchData?: TableauSearchType[]
  searchString: string
}

const defaultShown = 4

const TableauPreview = ({ url }: any) => {
  const [token, setToken] = useState()
  const axios = useAxios()

  async function getToken() {
    const res = await permissionCheck(axios)
    setToken(res)
  }

  useEffect(() => {
    getToken()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url])

  return (
    <Box
      minW={'100%'}
      w={'100%'}
      maxH='180px'
      overflow='hidden'
      border='1px solid'
      borderColor={'gray3'}
      borderRadius='8px'
      flexGrow={1}
    >
      <Skeleton isLoaded={!!token}>
        {!!token && (
          <Box
            backgroundPosition={'center center'}
            backgroundImage={`https://tableau.airfinity.com/trusted/${token}/views${url}.png`}
            w={'500px'}
            h={'250px'}
            backgroundSize='contain'
            backgroundRepeat={'no-repeat'}
          />
        )}
      </Skeleton>
    </Box>
  )
}

const TableauGridItem = ({ pathMapping, tableauData }: any) => {
  return (
    <Link
      to={
        resolvePath([pathMapping?.app, pathMapping?.path ?? '']) +
        (pathMapping?.pathExtras ?? '')
      }
    >
      <Flex
        flexDirection={'column'}
        cursor='pointer'
        justifyContent={'space-between'}
        _hover={{
          bg: 'gray.50',
        }}
        p={3}
      >
        <ImageComponent url={tableauData.url} key={tableauData.url} />
        <Box pt={3}>
          <Text fontWeight={500} fontSize='14px' color='primary'>
            {tableauData.name}
          </Text>
          <Box
            display={'flex'}
            marginTop='12px'
            maxW={'90%'}
            flexWrap='wrap'
            gap='8px'
          >
            {tableauData?.tags.map((tag: string) => {
              return (
                <Box
                  whiteSpace={'nowrap'}
                  border='1px solid'
                  borderColor={'gray3'}
                  borderRadius='4px'
                  padding={'4px 12px'}
                >
                  <Text fontSize='12px' fontWeight={400} color='gray1'>
                    {tag}
                  </Text>
                </Box>
              )
            })}
          </Box>
        </Box>
      </Flex>
    </Link>
  )
}

const ImageComponent = memo(TableauPreview)

export default function SearchTableau({
  tableauSearchData = [],
  searchString,
}: PropTypes) {
  const [totalShown, setTotalShown] = useState(defaultShown)
  const { setOverlayShown } = useGlobalSearch()

  if (tableauSearchData?.length === 0) {
    return null
  }

  return (
    <Box mb={8}>
      <SearchResultsCount
        searchString={searchString}
        count={tableauSearchData?.length ?? 0}
      />
      <Box display='flex' alignItems='center' mb='12px'>
        <Heading fontSize='19px' fontWeight={600} color='primary'>
          Charts
        </Heading>
      </Box>
      <Box
        display='grid'
        gridTemplateColumns='1fr 1fr'
        gridColumnGap='32px'
        gridRowGap='16px'
        mb='12px'
      >
        {tableauSearchData.slice(0, totalShown).map((tableauData, i) => {
          const pathMapping = tableauMapping.find(
            (mapping) => mapping.tableauPath === tableauData.url
          )
          return (
            <Box onClick={() => setOverlayShown(false)}>
              <TableauGridItem
                key={i}
                pathMapping={pathMapping}
                tableauData={tableauData}
              />
            </Box>
          )
        })}
      </Box>
      {tableauSearchData?.length > totalShown && (
        <Box textAlign='center'>
          <Text
            color='primary'
            fontSize='14px'
            textDecor='underline'
            fontWeight={500}
            cursor='pointer'
            display='inline'
            onClick={() => setTotalShown((x) => x + defaultShown)}
          >
            View more
          </Text>
        </Box>
      )}
    </Box>
  )
}
