import { Box } from '@chakra-ui/react'
import { forwardRef } from 'react'

import EntityTag, { TagComponentProp } from 'modules/EntityTags'

import { Tag } from 'components'

import { ResponseData } from 'api/types'

type PropTypes = {
  value: ResponseData
  colours?: Record<string, string>
  wrap?: boolean
  columnWidth: number
}

const SingleTag = ({
  value,
  colours,
  wrap,
  columnWidth,
  ...rest
}: PropTypes) => {
  if (!value) return null

  const v = Array.isArray(value) ? (value.length > 0 ? value[0] : null) : value

  if (!v) return null

  return (
    <Box
      display='flex'
      flexWrap='nowrap'
      {...rest}
      pt={1}
      width='100%'
      overflow='hidden'
    >
      <EntityTag
        value={v}
        component={TagComponent}
        colours={colours}
        wrap={wrap}
        columnWidth={columnWidth}
      />
    </Box>
  )
}

const TagComponent = forwardRef<any, TagComponentProp>(
  ({ value, colours, wrap }, ref) => {
    return (
      <Tag
        ref={ref}
        color={colours && colours[value] ? colours[value] : 'gray.200'}
        whiteSpace={wrap ? 'normal' : 'nowrap'}
        px='8px'
        rounded='md'
      >
        {value}
      </Tag>
    )
  }
)

export default SingleTag
