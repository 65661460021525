import { Box } from '@chakra-ui/react'

export default function ({
  isSelected,
  customText = 'New',
  isDropdownChild = false,
}: {
  isSelected?: boolean
  customText?: string
  isDropdownChild?: boolean
}) {
  return (
    <Box
      position='absolute'
      color='black'
      bg={isSelected ? 'gray.100' : 'yellow.500'}
      right={isDropdownChild ? 5 : 10}
      rounded={'sm'}
      shadow={'sm'}
      fontSize='x-small'
      px={1}
      py={0.5}
    >
      {customText}
    </Box>
  )
}
