import { IModel } from 'interfaces/model.interface'
import { ValueType } from 'interfaces/valueType.interface'

import { SortOrders } from 'enums/SortOrders'

export type PNHPressReleasesData =
  | 'title'
  | 'datePublished'
  | 'lookupCandidateName'
  | 'lookupCompanies'
  | 'lookupDrugType'
  | 'lookupDrugTarget'
  | 'link'

const allowedAirtables = ['api_press_releases'] as const

export const PNHPressReleasesModel: IModel<
  Record<PNHPressReleasesData, string>,
  typeof allowedAirtables
> = {
  allowedAirtables,
  key: 'title',
  displayKey: 'title',
  searchField: 'title',
  detailViewType: 'Article',
  detailView: {
    link: 'link',
  },
  exportDisabled: true,
  name: 'Press Releases',
  entityName: 'Press Releases',
  endpoint: 'press-release',
  defaultSortKey: 'datePublished',
  defaultSortOrder: SortOrders.DESC,
  noPrint: true,
  schema: {
    columns: [
      {
        key: 'title',
        label: 'Title',
        width: 600,
        showDetail: false,
        cellFormat: {
          maxLines: 3,
        },
      },
      {
        key: 'datePublished',
        type: ValueType.DATE,
        label: 'Publication date',
        width: 160,
      },
      {
        key: 'lookupCandidateName',
        label: 'Candidate Name',
        type: ValueType.MULTI,
        width: 180,
      },
      {
        key: 'lookupDrugTarget',
        label: 'Drug Target',
        type: ValueType.MULTI,
        width: 180,
      },
      { key: 'link', label: 'URL', width: 120, type: ValueType.URL },
    ],
  },
}
