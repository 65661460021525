import { IApp } from 'interfaces/navigationApp.interface'

import { AccessGroups } from 'enums/AccessGroups'

import { IDA360CustomPages } from './IDA360CustomPages'
import { IDA360Menu, IDA360Slugs } from './IDA360Menu'
import { IDA360Pages } from './IDA360Pages'

const IDA360App: IApp<IDA360Slugs> = {
  name: 'Vaccines & Treatments',
  slug: 'ida-360',
  accessGroups: [
    AccessGroups.IDA360Vaccines,
    AccessGroups.IDA360Treatments,
    AccessGroups.IDA360ChikV,
    AccessGroups.IDA360Amr,
    AccessGroups.IDA360Malaria,
    AccessGroups.IDA360Dengue,
  ],
  endpoint: 'ida',
  menu: IDA360Menu,
  pages: IDA360Pages,
  customPages: IDA360CustomPages,
  createdAt: new Date(2022, 10, 24),
  globalReports: false,
}

export default IDA360App
