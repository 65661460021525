import { Box } from '@chakra-ui/react'
import hexToRgba from 'hex-to-rgba'
import { useMemo } from 'react'
import Truncate from 'react-truncate'
import {
  PieChart as RechartsPieChart,
  Pie,
  ResponsiveContainer,
  Tooltip,
} from 'recharts'
import theme from 'theme'

import { ChartPropTypes } from '.'
import CustomizedTooltip from './CustomizedTooltip'

const { colors } = theme
const colors500List = Object.values(colors)
  .map((x: any) => x[500])
  .filter((x) => !!x && !x.includes('rgba'))

const limit = 10

export function PieChart({ chartData, squashData = true }: ChartPropTypes) {
  const mappedChartData = useMemo(() => {
    const slicedData = chartData.data.slice(0, limit)

    if (squashData === false) {
      return {
        ...chartData,
        data: slicedData.map((data, i) => ({
          ...data,
          fill: colors500List[i % colors500List.length],
        })),
      }
    } else {
      // Squash entries if over the limit
      const totalOthers = chartData.data
        .slice(limit)
        .reduce((acc, val) => acc + val.value, 0)

      const squashedData =
        totalOthers > 0
          ? slicedData.concat({
              name: 'Other',
              value: totalOthers,
            })
          : slicedData

      return {
        ...chartData,
        data: squashedData.map((data, i) => ({
          ...data,
          fill: colors500List[i % colors500List.length],
        })),
      }
    }
  }, [chartData, squashData])

  return (
    <Box width='100%'>
      <Box height={300}>
        <ResponsiveContainer>
          <RechartsPieChart height={300}>
            <Pie
              isAnimationActive={false}
              nameKey='name'
              dataKey='value'
              data={mappedChartData.data}
              label
              labelLine
              innerRadius={75}
            />
            <Tooltip content={<CustomizedTooltip />} />
          </RechartsPieChart>
        </ResponsiveContainer>
      </Box>
      <Box display='flex' flexWrap='wrap' justifyContent='center'>
        {mappedChartData.data.map((entry, i) => (
          <Box
            key={i}
            display='flex'
            alignItems='center'
            fontSize='xs'
            lineHeight='none'
            fontWeight={500}
            mb={2}
            px={2}
            py={2}
            bg={hexToRgba(entry.fill, '0.1')}
            mr={2}
          >
            <Box width='10px' height='10px' bg={entry.fill} mr={1} />
            <Truncate lines={1}>
              <Box whiteSpace='nowrap'>{entry.name}</Box>
            </Truncate>
          </Box>
        ))}
      </Box>
    </Box>
  )
}
