import { Edit } from '@carbon/icons-react'
import {
  Button,
  ButtonProps,
  Flex,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react'

import RenameUserViewModal from './RenameUserViewModal'

type PropTypes = {
  currentViewName: string
  onRenameUserView: (title: string) => void
  isRenderingDashboard?: boolean
} & ButtonProps

export default function RenameUserViewButton({
  currentViewName,
  onRenameUserView,
  isRenderingDashboard = false,
  ...buttonProps
}: PropTypes) {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      {/* <Button variant='clean' onClick={onOpen} {...buttonProps}>
        <Edit size={16} />
        <Box mr={1} />
        <Text fontSize='12px'>Rename</Text>
      </Button> */}
      {/* <IconButton
        aria-label={'rename'}
        icon={<Edit size={18} />}
        variant={'solid'}
        h='32px'
        w='32px'
        borderRadius={'6px'}
      /> */}
      <Tooltip label='Rename' isDisabled={!isRenderingDashboard}>
        <Button
          size={'sm'}
          onClick={onOpen}
          {...buttonProps}
          h='32px'
          w={isRenderingDashboard ? '32px' : 'fit-content'}
          borderRadius={'6px'}
          aria-label='save changes'
          variant={'solid'}
        >
          <Flex gap={2} alignItems={'center'}>
            <Edit size={18} />
            {!isRenderingDashboard && <Text fontSize='12px'>Rename</Text>}
          </Flex>
        </Button>
      </Tooltip>
      <RenameUserViewModal
        currentViewName={currentViewName}
        isOpen={isOpen}
        onClose={onClose}
        onRenameUserView={onRenameUserView}
      />
    </>
  )
}
