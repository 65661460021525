import { IResponseBase } from 'api/types'

import { IModel } from 'interfaces/model.interface'
import { ViewData } from 'interfaces/navigationPage.interface'

import unCamelize from 'utils/unCamelize'

import Attribute from '../Attributes/Attribute'

type PropTypes<D extends Record<string, any> = Record<string, any>> = {
  data: IResponseBase<Extract<keyof D, string>>
  model: IModel<D>
  titleKey?: string
  viewData: ViewData
}

function Summary<D extends Record<string, any> = Record<string, any>>({
  data,
  model,
  titleKey,
  viewData,
}: PropTypes<D>) {
  return (
    <>
      {model.schema.columns.map((fieldModel, i) => {
        const { key, showDetail } = fieldModel

        if (showDetail === false || key === titleKey) {
          return null
        }
        return (
          <Attribute
            key={unCamelize(key)}
            collection={model.endpoint}
            fieldModel={fieldModel}
            rowId={data.id}
            value={data[key]}
            viewData={viewData}
          />
        )
      })}
    </>
  )
}

export default Summary
