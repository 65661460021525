import { Search, ArrowUp } from '@carbon/icons-react'
import {
  Button,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from '@chakra-ui/react'
import { KeyboardEvent } from 'react'

const InputSection = ({
  setQuery,
  search,
  query,
  placeholder = '',
}: {
  setQuery: React.Dispatch<React.SetStateAction<string>>
  search: () => void
  query: string
  placeholder: string
}) => {
  function handleKeyDown(e: KeyboardEvent) {
    if (e.key === 'Enter') {
      search()
    }
  }

  return (
    <InputGroup w='50vw'>
      <>
        <InputLeftElement pointerEvents='none'>
          <Icon
            as={Search}
            mt='auto'
            width='22px'
            height='22px'
            ml={6}
            mb='4px'
          />
        </InputLeftElement>
        <Input
          w='full'
          border='1px solid'
          borderColor='gray3'
          backgroundColor='gray5'
          rounded='lg'
          placeholder={placeholder}
          color='black'
          height='50px'
          pl='54px'
          fontWeight={400}
          fontSize='17px'
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          onKeyDown={handleKeyDown}
          _focus={{
            borderColor: 'gray3',
            boxShadow: 'none',
          }}
          _placeholder={{
            color: 'gray2',
          }}
        />
        <InputRightElement>
          <Button
            bg={'gray.300'}
            border='1px solid'
            height={'32px'}
            w='32px'
            borderColor={'gray.300'}
            _hover={{ bg: 'gray.200', borderColor: 'gray.500' }}
            rounded='lg'
            mr='2rem'
            mt='10px'
            color='black'
            onClick={search}
            fontSize='12px'
          >
            <Icon as={ArrowUp} width='18px' height='18px' />
          </Button>
        </InputRightElement>
      </>
    </InputGroup>
  )
}

export default InputSection
