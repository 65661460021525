import { Heading, Text, Box, Flex } from '@chakra-ui/react'

import { NoFile } from 'components/EmptyScreen/NoFile'

export const ReportNotUpdated = () => {
  return (
    <Flex
      flexDirection={'column'}
      alignItems={'center'}
      justifyContent={'center'}
      p={16}
      textAlign='center'
      data-cy='error'
    >
      <Heading mb={2} fontSize='2xl'>
        Report is not yet updated.
      </Heading>
      <Text maxW='lg' mx='auto'>
        It will be up shortly, please check back later.
      </Text>
      <Box w='fit-content' mt={5}>
        <NoFile />
      </Box>
    </Flex>
  )
}
