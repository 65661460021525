export default {
  Maternal: 'rgb(215, 77, 38)',
  Elderly: 'rgb(255, 158, 183)',
  Pediatric: 'rgb(207, 223, 255)',
  Paediatric: 'rgb(51, 138, 23)',
  'High Risk': 'rgb(248, 43, 96)',
  'General population': 'rgb(238, 238, 238)',
  Adult: 'rgb(255, 218, 246)',
  Adolescent: 'rgb(125, 228, 246)',
  Infants: 'rgb(200, 124, 234)',
  Immunocompromised: 'rgb(255, 100, 32)',
}
