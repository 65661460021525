export default {
  Active: '#af6005',
  Uncertain: '#ffba07',
  'Suspended production': '#0d52ac',
  'Candidate discontinued/rejected': '#166ee1',
  'Candidate in development': '#bfaefc',
  'Deal not likely to proceed': '#c4ecff',
  'Not active yet': '#f797ef',
  'Deal Cancelled': '#d1e2ff',
  Unknown: '#006400',
}
