import { TherapeuticsDealsView } from 'routes/apps/treatments/Deals'

import { SortOrders } from 'enums/SortOrders'

import { DealsModel } from 'config/common/baseModel'
import { RevenueModel } from 'config/common/baseModel/Revenue'
import { RespiratoryImmunisationScheduleModel } from 'config/common/baseModel/respiratory360/RespiratoryImmunisationSchedule'

import {
  compose,
  renameColumn,
  updateColumnAttributes,
  whiteListColumn,
} from 'utils/overrideModel'
import { IDTreatmentPremiumDealsModel } from 'config/common/baseModel/ida360/IDPremiumDeals'

export const TreatmentManufacturingAndProductionDealsModel = compose<
  typeof DealsModel
>(
  whiteListColumn([
    'linkAfTrUniqueTreatmentNameToBnfFromLinkCandidateList',
    'brandName',
    'lookupAfTrTypeFromBnfFromLinkCandidateList',
    'afDealType',
    'partyOne',
    'linkOrganisations',
    'licensingAgreement',
    'typeOfProductionDealTreatments',
    'status',
    'linkCountries',
    'afDateDealMade',
    'afPublicExpectedProductionNotes',
    'mediaUrl',
    'pressUrl',
    'source',
  ]),
  renameColumn('linkOrganisations', 'Party two'),
  renameColumn('licensingAgreement', 'Licensing Agreement'),
  renameColumn('afPublicExpectedProductionNotes', 'Notes'),
  renameColumn('afDateDealMade', 'Date Deal Announced'),
  renameColumn('source', 'Other Source(s)'),
  updateColumnAttributes(
    'linkAfTrUniqueTreatmentNameToBnfFromLinkCandidateList',
    {
      tooltip:
        'Name of the active ingredient or identifier chosen by the developing company.',
    }
  ),
  updateColumnAttributes('brandName', {
    tooltip:
      'Commercial name under which the individual product manufactured/distributed by the parties listed is being sold.',
  }),
  updateColumnAttributes('linkOrganisations', {
    tooltip:
      'This field denotes the organisation that will be carrying out the aspect of the treatment production chain (denoted in the Production Type field) under an agreement (if any, see Licensing Agreement column) with the organisation denoted in the Party one column. If no organisation is listed as Party One, then the work is not being carried out under an agreement.',
  }),
  updateColumnAttributes('lookupAfTrTypeFromBnfFromLinkCandidateList', {
    tooltip: 'Classification of the treatment based on its structure/function.',
  }),
  updateColumnAttributes('status', {
    tooltip:
      'Indicates the latest publicly available status of the manufacturer with regards to the production of the treatment indicated through the agreement indicated in Licensing agreement (if any).',
  }),
  updateColumnAttributes('licensingAgreement', {
    tooltip:
      'Indicates the type of agreement (if any) between the two parties listed.',
  }),
  updateColumnAttributes('typeOfProductionDealTreatments', {
    tooltip:
      'Indicates what stage of production the manufacturer or contracted manufacturer will reach for the treatment indicated',
  }),
  updateColumnAttributes('linkCountries', {
    tooltip: 'Location of Party Two.',
  }),
  updateColumnAttributes('partyOne', {
    tooltip:
      'Entity initiating the agreement between the two parties, and providing Party Two with the information necessary to synthesize the treatment in question. For manufacturing partnerships Party two may manufacture the treatment for Party One. For Licensing deals Party One will license the treatment to Party Two. Other deal types may not involve two parties, or may do so under specific circumstances.',
  }),
  updateColumnAttributes('mediaUrl', {
    tooltip: 'The link to the Media article announcing the deal.',
  }),
  updateColumnAttributes('pressUrl', {
    tooltip: 'The link to the Press release announcing the deal.',
  }),
  updateColumnAttributes('source', {
    tooltip:
      'Other sources related to the deal or product if no Media articles or Press releases are available. Additional sources adding context or information related to the deal.',
  }),
  updateColumnAttributes('afDateDealMade', {
    tooltip:
      'Date that the deal/intnent to carry out production chain work was announced.',
  })
)({
  ...DealsModel,
  defaultSortKey: 'afDateDealMade',
  defaultSortOrder: SortOrders.DESC,
  name: 'Production',
  detailViewType: 'Generic',
  renderDetailView: TherapeuticsDealsView,
})

export const TreatmentPriceDealsModel = compose<typeof DealsModel>(
  whiteListColumn([
    'lookupVaccineName',
    'lookupAfTrTypeFromBnfFromLinkCandidateList',
    'afDealType',
    'afEstimatedPricePerDoseUsd',
    'volume',
    'pricePerDoseSource',
    'linkCountries',
    'afDateDealMade',
    'notes',
    'source',
  ]),
  renameColumn('lookupVaccineName', 'Treatment Name'),
  renameColumn('afEstimatedPricePerDoseUsd', 'Estimated Price/Cost USD'),
  renameColumn('afDateDealMade', 'Date')
)({
  ...DealsModel,
  name: 'Price',
  defaultSortKey: 'afDateDealMade',
  defaultSortOrder: SortOrders.DESC,
})

export const TreatmentSupplyDealsModel = compose<typeof DealsModel>(
  whiteListColumn([
    'linkAfTrUniqueTreatmentNameToBnfFromLinkCandidateList',
    'lookupAfTrTypeFromBnfFromLinkCandidateList',
    'afDealType',
    'afPurchaseType',
    'linkManufacturer',
    'licensingAgreement',
    'linkCountries',
    'linkRegions',
    'countryIncomeBandReceivingSupply',
    'linkOrganisations',
    'afSupplyNumber',
    'afPotentialExpansion',
    'afSupplyCertainty',
    'afDeliveryToDate',
    'afDeliveryCertainty',
    'afDateDeliveryToDateWasLastUpdated',
    'afEstimatedPricePerDoseUsd',
    'supplyDealCostUsd',
    'supplyDealCostCertainty',
    'afDateDealMade',
    'notes',
    'mediaUrl',
    'pressUrl',
  ]),
  renameColumn('afSupplyNumber', 'Supply Number'),
  renameColumn('linkManufacturer', 'Manufacturer'),
  renameColumn('licensingAgreement', 'Manufacturer Licensing Agreement'),
  renameColumn('linkRegions', 'Regions that have secured these doses'),
  renameColumn(
    'countryIncomeBandReceivingSupply',
    'Income band receiving supply'
  ),
  renameColumn('afDeliveryToDate', 'Delivery to date'),
  renameColumn(
    'afDateDeliveryToDateWasLastUpdated',
    'Delivery to date was last updated'
  ),
  renameColumn('afPotentialExpansion', 'Potential Expansion'),
  renameColumn('afSupplyCertainty', 'Supply number certainty'),
  renameColumn('afEstimatedPricePerDoseUsd', 'Price per dose'),
  renameColumn('supplyDealCostCertainty', 'Supply cost certainty'),
  renameColumn('notes', 'Supply notes'),
  renameColumn('afDateDealMade', 'Date Deal Announced'),
  renameColumn('linkOrganisations', 'Organisation purchasing supply'),
  renameColumn('linkCountries', 'Countries that have secured these doses')
)({
  ...DealsModel,
  name: 'Supply',
  defaultSortKey: 'afDateDealMade',
  defaultSortOrder: SortOrders.DESC,
})

export const TreatmentEligiblePopulationModel = compose<
  typeof RespiratoryImmunisationScheduleModel
>(
  whiteListColumn([
    'areas',
    'candidates',
    'announcementDate',
    'targetPopulationDescription',
    'targetPopulationDetailed',
    'eligiblePopulation',
    'sourceComment',
    'sources',
  ])
)({
  ...RespiratoryImmunisationScheduleModel,
  name: 'Eligible Population',
  defaultSortObject: [
    {
      id: 'announcementDate',
      sortOrder: SortOrders.DESC,
    },
    {
      id: 'areas',
      sortOrder: SortOrders.ASC,
    },
  ],
})

export const TreatmentRevenueModel = compose<typeof RevenueModel>(
  whiteListColumn([
    'organisations',
    'financialYear',
    'quarter',
    'candidates',
    'designationB',
    'valueUsd',
    'areas',
    'sources',
  ]),
  renameColumn('organisations', 'Company'),
  renameColumn('candidates', 'Candidate'),
  renameColumn('valueUsd', 'Revenue (USD)'),
  renameColumn('sources', 'Source'),
  updateColumnAttributes('quarter', {
    width: 100,
  })
)({
  ...RevenueModel,
  defaultSortObject: [
    {
      id: 'financialYear',
      sortOrder: SortOrders.DESC,
    },
    {
      id: 'quarter',
      sortOrder: SortOrders.DESC,
    },
  ],
})

export const TreatmentSupplyDealsDeliveriesDonationsModel = compose<
  typeof IDTreatmentPremiumDealsModel
>(
  whiteListColumn([
    'recipients',
    'recipientIncomeBand',
    'recipientRegion',
    'distributors',
    'donors',
    'suppliers',
    'candidates',
    'designationB',
    'procurementMechanism',
    'market',
    'quantity',
    'quantityUnit',
    'quantityPotentialExpansion',
    'quantityMethodologyType',
    'quantityMethodologyDescription',
    'pricePerUnitUsd',
    'pricePerUnitMethodologyType',
    'pricePerUnitMethodologyDescription',
    'dateStart',
    'dateEnd',
    'sources',
  ]),
  renameColumn('suppliers', 'Manufacturer'),
  renameColumn('pricePerUnitMethodologyType', 'Price Source Type')
)({
  ...IDTreatmentPremiumDealsModel,
  defaultSortKey: 'dateStart',
  defaultSortOrder: SortOrders.DESC,
})

export const TreatmentSupplyDealsSupplyOrientedModel = compose<
  typeof IDTreatmentPremiumDealsModel
>(
  whiteListColumn([
    'recipients',
    'recipientIncomeBand',
    'recipientRegion',
    'distributors',
    'donors',
    'suppliers',
    'candidates',
    'designationB',
    'procurementMechanism',
    'market',
    'quantity',
    'quantityUnit',
    'quantityPotentialExpansion',
    'quantityMethodologyType',
    'quantityMethodologyDescription',
    'dateStart',
    'dateEnd',
    'sources',
  ])
)({
  ...IDTreatmentPremiumDealsModel,
  defaultSortKey: 'dateStart',
  name: 'Supply Deals',
  defaultSortOrder: SortOrders.DESC,
})

export const TreatmentSupplyDealsPriceOrientedModel = compose<
  typeof IDTreatmentPremiumDealsModel
>(
  whiteListColumn([
    'recipients',
    'recipientIncomeBand',
    'recipientRegion',
    'suppliers',
    'candidates',
    'designationB',
    'procurementMechanism',
    'market',
    'quantityUnit',
    'pricePerUnitUsd',
    'pricePerUnitMethodologyType',
    'pricePerUnitMethodologyDescription',
    'dateStart',
    'dateEnd',
    'sources',
  ]),
  renameColumn('pricePerUnitMethodologyType', 'Price Source Type')
)({
  ...IDTreatmentPremiumDealsModel,
  defaultSortKey: 'dateStart',
  name: 'Supply Deals',
  defaultSortOrder: SortOrders.DESC,
})
