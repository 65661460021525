import { TrashCan } from '@carbon/icons-react'
import {
  Button,
  ButtonProps,
  Flex,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react'

import DeleteUserViewModal from './DeleteUserViewModal'

type PropTypes = {
  isRenderingDashboard?: boolean
  onDeleteUserView: () => void
} & ButtonProps

export default function DeleteUserViewButton({
  onDeleteUserView,
  isRenderingDashboard = false,
  ...buttonProps
}: PropTypes) {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      {/* <Button variant='clean' onClick={onOpen} {...buttonProps}>
        <TrashCan size={16} />
        <Box mr={1} />
        <Text fontSize='12px'>Delete</Text>
      </Button> */}
      {/* <IconButton
        h='32px'
        w='32px'
        borderRadius={'6px'}
        aria-label='delete'
        icon={<TrashCan size={18} />}
        variant={'solid'}
      /> */}
      <Tooltip label='Delete' isDisabled={!isRenderingDashboard}>
        <Button
          size={'sm'}
          onClick={onOpen}
          {...buttonProps}
          h='32px'
          w={isRenderingDashboard ? '32px' : 'fit-content'}
          borderRadius={'6px'}
          aria-label='save changes'
          variant={'solid'}
        >
          <Flex gap={2} alignItems={'center'}>
            <TrashCan size={18} />
            {!isRenderingDashboard && <Text fontSize='12px'>Delete</Text>}
          </Flex>
        </Button>
      </Tooltip>
      <DeleteUserViewModal
        isOpen={isOpen}
        onClose={onClose}
        onDeleteUserView={onDeleteUserView}
      />
    </>
  )
}
