import { IApp } from 'interfaces/navigationApp.interface'

import { AccessGroups } from 'enums/AccessGroups'

import { ObesityCustomPages } from './ObesityCommercialCustomPages'
import { ObesityCommercialMenu, ObesitySlugs } from './ObesityCommercialMenu'
import { ObesityCommercialPages } from './ObesityCommercialPages'

const ObesityCommercial: IApp<ObesitySlugs> = {
  name: 'Commercial',
  slug: 'obesity-commercial',
  endpoint: 'obesity-360',
  accessGroups: [AccessGroups.Obesity360],
  menu: ObesityCommercialMenu,
  pages: ObesityCommercialPages,
  customPages: ObesityCustomPages,
  appIdentifier: 'obesity-commercial',
  createdAt: new Date(2022, 6, 8),
  disableAnalytics: true,
}

export default ObesityCommercial
