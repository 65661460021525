import { format, utcToZonedTime } from 'date-fns-tz'

export function formatDate(s?: any) {
  if (!s) return ''
  const dt = new Date(s)
  return dt.toLocaleString('en-us', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  })
}

export function formatUtc(date: Date, dateFormat: string) {
  return format(utcToZonedTime(date, 'UTC'), dateFormat, {
    timeZone: 'UTC',
  })
}
