import { UseMutationOptions, useMutation } from '@tanstack/react-query'
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios'

import useAxios from './useAxios'

export interface ChangePasswordParam {
  oldPassword: string
  newPassword: string
}

export default function useChangePasswordMutation(
  extraProps?: UseMutationOptions<
    AxiosResponse<any>,
    AxiosError<any>,
    ChangePasswordParam
  >
) {
  const axios = useAxios()

  return useMutation<AxiosResponse<any>, AxiosError<any>, ChangePasswordParam>(
    ({ oldPassword, newPassword }) => {
      return userChangePassword(axios, oldPassword, newPassword)
    },
    extraProps
  )
}

export const userChangePassword = async (
  axios: AxiosInstance,
  oldPassword: string,
  newPassword: string
) => {
  return await axios.post(`/user/change-password/`, {
    new_password: newPassword,
    old_password: oldPassword,
  })
}
