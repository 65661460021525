import { UseQueryOptions, useQuery } from '@tanstack/react-query'
import { AxiosError, AxiosInstance } from 'axios'
import { useLocation } from 'react-router-dom'

import { apps, appsListUnion } from 'config/apps'

import useAxios from './useAxios'

export default function usePermissionCheck(
  app: appsListUnion,
  extraProps?: UseQueryOptions<any, any>
) {
  const axios = useAxios()
  const location = useLocation()

  return useQuery<any, AxiosError>(
    ['permissionCheck', app, location],
    () => {
      return permissionCheck(axios, app)
    },
    extraProps
  )
}

export const permissionCheck = async (
  axios: AxiosInstance,
  app: appsListUnion
): Promise<any> => {
  return axios(`/${apps[app].endpoint}/permissions-check/`).then((x) => x.data)
}
