import { createContext, useContext, useState } from 'react'

interface ExportModalContextType {
  isModalOpen: boolean
  openModal: () => void
  closeModal: () => void
}

export const ExportModalContext = createContext<ExportModalContextType>({
  isModalOpen: false,
  openModal: () => {},
  closeModal: () => {},
})

export const useExportModal = (): ExportModalContextType => {
  return useContext(ExportModalContext)
}

export const ExportModalConsumer = ExportModalContext.Consumer

export const ExportModalProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const openModal = () => setIsModalOpen(true)
  const closeModal = () => setIsModalOpen(false)

  return (
    <ExportModalContext.Provider
      value={{
        isModalOpen,
        openModal,
        closeModal,
      }}
    >
      {children}
    </ExportModalContext.Provider>
  )
}
