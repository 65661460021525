import GenericTable from 'modules/Tables'

import { IModel } from 'interfaces/model.interface'
import {
  INavigationPages,
  ISingleNavigationPage,
} from 'interfaces/navigationPage.interface'

import { RNATherapeuticsSlugs } from './RNATherapeuticsMenu'
import {
  RNATherapeuticsApprovalNewsModel,
  RNATherapeuticsCandidatePapersModel,
  RNATherapeuticsCandidatesModel,
  RNATherapeuticsClinicalTrialsModel,
  RNATherapeuticsDeliverySystemsInvestigationalModel,
  RNATherapeuticsDeliverySystemsModel,
  RNATherapeuticsDeliverySystemsNewsModel,
  RNATherapeuticsFundingNewsModel,
  RNATherapeuticsMediaModel,
  RNATherapeuticsPressModel,
} from './RNATherapeuticsModels'

type RNATherapeuticsNavigationPage<TModel extends IModel<any>> =
  ISingleNavigationPage<RNATherapeuticsSlugs, TModel>

const Candidates: RNATherapeuticsNavigationPage<
  typeof RNATherapeuticsCandidatesModel
> = {
  key: 'Candidates',
  path: ['candidates', 'table'],
  component: GenericTable,
  model: RNATherapeuticsCandidatesModel,
  views: [
    {
      name: 'Default',
      airtableName: 'RNA_API_Candidates',
      airtableBase: 'advancedtherapies',
    },
    {
      name: 'Treatments',
      airtableName: 'RNA_API_Candidates_Treatments',
      airtableBase: 'advancedtherapies',
    },
    {
      name: 'Vaccines',
      airtableName: 'RNA_API_Candidates_Vaccine',
      airtableBase: 'advancedtherapies',
    },
  ],
}

const CandidatePapers: RNATherapeuticsNavigationPage<
  typeof RNATherapeuticsCandidatePapersModel
> = {
  key: 'Publications',
  path: ['new-science', 'publications'],
  component: GenericTable,
  model: RNATherapeuticsCandidatePapersModel,
  views: [
    {
      name: 'Default',
      airtableName: 'RNA_API_Publications',
      airtableBase: 'advancedtherapies',
    },
  ],
}

const ClinicalTrials: RNATherapeuticsNavigationPage<
  typeof RNATherapeuticsClinicalTrialsModel
> = {
  key: 'ClinicalTrials',
  path: ['trials', 'table'],
  component: GenericTable,
  model: RNATherapeuticsClinicalTrialsModel,
  views: [
    {
      name: 'Default',
      airtableName: 'RNA_API_ClinicalTrials',
      airtableBase: 'advancedtherapies',
    },
  ],
}

const PressReleases: RNATherapeuticsNavigationPage<
  typeof RNATherapeuticsPressModel
> = {
  key: 'PressReleases',
  path: ['news', 'press-releases'],
  component: GenericTable,
  model: RNATherapeuticsPressModel,
  views: [
    {
      name: 'Default',
      airtableName: 'RNA_API_PressReleases',
      airtableBase: 'advancedtherapies',
    },
  ],
}

const Media: RNATherapeuticsNavigationPage<typeof RNATherapeuticsMediaModel> = {
  key: 'Media',
  path: ['news', 'media'],
  component: GenericTable,
  model: RNATherapeuticsMediaModel,
  views: [
    {
      name: 'Default',
      airtableName: 'RNA_API_Media',
      airtableBase: 'advancedtherapies',
    },
  ],
}

const DeliverSystemsCandidateAssociatedTable: RNATherapeuticsNavigationPage<
  typeof RNATherapeuticsDeliverySystemsModel
> = {
  key: 'DeliverySystemsTable',
  path: ['delivery-systems', 'candidate-associated'],
  component: GenericTable,
  model: RNATherapeuticsDeliverySystemsModel,
  views: [
    {
      name: 'Default',
      airtableName: 'RNA_API_DeliverySystems V2 (Candidate Linked)',
      airtableBase: 'advancedtherapies',
    },
  ],
}
const DeliverSystemsInvestigationalTable: RNATherapeuticsNavigationPage<
  typeof RNATherapeuticsDeliverySystemsModel
> = {
  key: 'DeliverySystemsTable',
  path: ['delivery-systems', 'investigational'],
  component: GenericTable,
  model: RNATherapeuticsDeliverySystemsInvestigationalModel,
  views: [
    {
      name: 'Default',
      airtableName: 'RNA_API_DeliverySystems V2 (Investigational)',
      airtableBase: 'advancedtherapies',
    },
  ],
}

const DeliverSystemsNews: RNATherapeuticsNavigationPage<
  typeof RNATherapeuticsDeliverySystemsNewsModel
> = {
  key: 'DeliverySystemsTable',
  path: ['delivery-systems', 'news'],
  component: GenericTable,
  model: RNATherapeuticsDeliverySystemsNewsModel,
  views: [
    {
      name: 'Default',
      airtableName: 'RNA_API_DeliverySystemsNews',
      airtableBase: 'advancedtherapies',
    },
  ],
}
const ApprovalNews: RNATherapeuticsNavigationPage<
  typeof RNATherapeuticsApprovalNewsModel
> = {
  key: 'ApprovalNewsTable',
  path: ['regulatory', 'approval-news'],
  component: GenericTable,
  model: RNATherapeuticsApprovalNewsModel,
  views: [
    {
      name: 'Default',
      airtableName: 'RNA_API_RegulatoryNews_Approval',
      airtableBase: 'advancedtherapies',
    },
  ],
}

const FundingNews: RNATherapeuticsNavigationPage<
  typeof RNATherapeuticsFundingNewsModel
> = {
  key: 'Funding',
  path: ['funding'],
  component: GenericTable,
  model: RNATherapeuticsFundingNewsModel,
  views: [
    {
      name: 'Default',
      airtableName: 'RNA_API_FundingNews',
      airtableBase: 'advancedtherapies',
    },
  ],
}

export const RNATherapeuticsPages: INavigationPages<RNATherapeuticsSlugs> = {
  Candidates,
  CandidatePapers,
  ClinicalTrials,
  PressReleases,
  Media,
  DeliverSystemsCandidateAssociatedTable,
  DeliverSystemsInvestigationalTable,
  DeliverSystemsNews,
  ApprovalNews,
  FundingNews,
}
