import { IApp } from 'interfaces/navigationApp.interface'

import { AccessGroups } from 'enums/AccessGroups'

import { ResearchPowerIndexCustomPages } from './ResearchPowerIndexCustomPages'
import {
  ResearchPowerIndexMenu,
  ResearchPowerIndexSlugs,
} from './ResearchPowerIndexMenu'
import { ResearchPowerIndexPages } from './ResearchPowerIndexPages'

const ResearchPowerIndex: IApp<ResearchPowerIndexSlugs> = {
  name: 'Research Power Index',
  slug: 'research-power-index',
  endpoint: 'research-power-index',
  accessGroups: [AccessGroups.ResearchPowerIndex],
  menu: ResearchPowerIndexMenu,
  customPages: ResearchPowerIndexCustomPages,
  pages: ResearchPowerIndexPages,
  appIdentifier: 'researchPowerIndex',
  createdAt: new Date(2022, 2, 11),
}

export default ResearchPowerIndex
