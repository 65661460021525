import ReportCard from 'modules/DashboardReports/ReportCard'

import useBookmarkResolver from 'api/bookmark/useBookmarkResolver'
import { Bookmark } from 'api/types'

const BookmarkedReport = ({ report }: { report: Bookmark }) => {
  const { data } = useBookmarkResolver(report)

  if (!data) {
    return null
  }

  return <ReportCard reportId={report.id} report={data} />
}

export default BookmarkedReport
