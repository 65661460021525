import { Box } from '@chakra-ui/react'

import {
  Panel,
  PanelBody,
  PanelHeader,
  PanelTitle,
  TableauEmbed,
} from 'components'

import { ISingleCustomPage } from 'interfaces/navigationPage.interface'

import { CovidBoosterMarketForecastSlugs } from './CovidBoosterMarketForecastMenu'

const Dashboard: ISingleCustomPage<CovidBoosterMarketForecastSlugs> = {
  path: '',
  component: () => (
    <Panel overflow='hidden' height='100%'>
      <PanelHeader>
        <Box display='flex' py={3} flexDirection='row' alignItems='center'>
          <PanelTitle>COVID Supply & Vaccination Overview</PanelTitle>
        </Box>
      </PanelHeader>
      <PanelBody>
        <TableauEmbed
          path='/GSKboosterforecastdashboard/Region'
          height={`calc(1700px)`}
        />
      </PanelBody>
    </Panel>
  ),
}

export const CovidVaccineMarketCustomPages: Record<
  string,
  ISingleCustomPage<CovidBoosterMarketForecastSlugs>
> = {
  Dashboard,
}
