import { Box, Button, Flex, Image, Text, VStack } from '@chakra-ui/react'

import { useAppParams } from 'routes/utils'

import useAppUpgradeMutation from 'api/useAppUpgradeMutation'

import { IApp } from 'interfaces/navigationApp.interface'

export type RequestAccessPropTypes = {
  app: IApp<any>
  title: React.ReactNode
  imageSrc: string
  body: React.ReactNode
}

export const RequestAccess = ({
  app,
  title,
  imageSrc,
  body,
}: RequestAccessPropTypes) => {
  const {
    mutate: requestAppUpgrade,
    isLoading,
    isSuccess,
  } = useAppUpgradeMutation()
  const { pageSlug, subPageSlug } = useAppParams()

  const onRequest = () => {
    requestAppUpgrade({
      appEndpoint: app.endpoint,
      url: window.location.href,
      page: pageSlug,
      subpage: subPageSlug,
    })
  }

  return (
    <Flex
      top={0}
      bottom={0}
      left={0}
      right={0}
      position='absolute'
      alignItems='center'
      justifyContent='center'
    >
      <Box maxW='xl' bgColor='white' p={5}>
        <VStack alignItems='flex-start'>
          <Box>{title}</Box>
          <Flex>
            <Image w='160px' src={imageSrc} mr={3} />
            <Flex flexDir='column'>
              {body}
              <Box mb={4} />
              {isSuccess && (
                <Text fontSize='sm' fontWeight='bold'>
                  Thank you! We will contact you as soon as possible.
                </Text>
              )}
              {!isSuccess && (
                <Button
                  alignSelf='flex-end'
                  color='blue.500'
                  fontSize='md'
                  variant='link'
                  onClick={onRequest}
                  isLoading={isLoading}
                >
                  Click here if interested
                </Button>
              )}
            </Flex>
          </Flex>
        </VStack>
      </Box>
    </Flex>
  )
}
