const REDIRECT_URI = window.location.origin + '/implicit/callback'

const config = {
  authority: process.env.REACT_APP_OKTA_ISSUER,
  client_id: process.env.REACT_APP_OKTA_CLIENT_ID,
  redirect_uri: REDIRECT_URI,
  scope: 'openid profile email groups',
  post_logout_redirect_uri: `${window.location.origin}/`,
}

const govConfig = {
  authority: process.env.REACT_APP_ISSUER_GOV,
  client_id: process.env.REACT_APP_CLIENT_ID_GOV,
  redirect_uri: REDIRECT_URI,
  scope: 'openid profile email',
  post_logout_redirect_uri: `${window.location.origin}/`,
}

const glacierConfig = {
  authority: 'https://cognito-idp.us-east-1.amazonaws.com/us-east-1_xdGI6zVXl/',
  client_id: '210vt2rgul877uk923cr2id685',
  redirect_uri: REDIRECT_URI,
  scope: 'openid profile email',
  post_logout_redirect_uri: `${window.location.origin}/`,
}

const mammothConfig = {
  authority: 'https://cognito-idp.us-east-1.amazonaws.com/us-east-1_diYeAbmzn/',
  client_id: '4dd96rfg68j3i216u9rpu57vf4',
  redirect_uri: REDIRECT_URI,
  scope: 'openid profile email',
  post_logout_redirect_uri: `${window.location.origin}/`,
}

export default {
  resourceServer: {},
  config,
  govConfig,
  glacierConfig,
  mammothConfig,
}
