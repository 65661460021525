import { useQuery } from '@tanstack/react-query'
import { AxiosError, AxiosInstance } from 'axios'
import { compareDesc } from 'date-fns'

import { appsMapping } from 'modules/AppsSelector/ResearchBriefs'
import { removeDuplicates } from 'modules/AppsSelector/ResearchBriefs/Helpers'

import { apps, appsListUnion } from 'config/apps'

import { recursiveCamelCase } from '../../helper'
import useAxios from '../../useAxios'
import { BriefsT } from './types'

export function useAllBriefs() {
  const axios = useAxios()

  const briefsRes = useQuery<any, AxiosError>(['researchBrief'], () =>
    fetchAllResearchBriefs(axios)
  )
  const isFetched = briefsRes.isFetched
  const isError = briefsRes.isError

  const briefs = isFetched
    ? removeDuplicates(briefsRes?.data)
        .map((brief) => {
          return {
            ...brief,
            apps: brief.apps.map((app) => appsMapping[app]?.slug || '#'),
            appsNames: brief.apps.map((app) => appsMapping[app]?.name || '#'),
            parentApp: brief.apps.map((app) => appsMapping[app]?.parent || '#'),
          }
        })
        .sort((a, b) =>
          compareDesc(new Date(a.datePublished), new Date(b.datePublished))
        )
    : []

  return { briefs, isFetched, isError }
}

export function useBriefs(app: appsListUnion) {
  const axios = useAxios()
  return useQuery<any, AxiosError>(['researchBrief', app], () =>
    fetchResearchBriefs(axios, app)
  )
}

export const fetchAllResearchBriefs = async (
  axios: AxiosInstance
): Promise<BriefsT[]> => {
  return axios
    .get(`/all-research-briefs/`)
    .then((res) => recursiveCamelCase(res.data))
    .catch((err) => {
      console.error({ err })
      return null
    })
}

export const fetchResearchBriefs = async (
  axios: AxiosInstance,
  app: appsListUnion
): Promise<BriefsT[]> => {
  return axios
    .get(`/${apps[app].endpoint}/research-briefs/`)
    .then((res) => recursiveCamelCase(res.data))
    .catch((err) => {
      console.error({ err })
      return null
    })
}
