const Text = {
  variants: {
    body: {
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '22px',
    },
    'body-link': {
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '22px',
      textDecoration: 'underline',
    },
    'body-bold': {
      fontSize: '12px',
      fontWeight: 500,
      lineHeight: '22px',
    },
    'body-extra-bold': {
      fontSize: '12px',
      fontWeight: 600,
      lineHeight: '22px',
    },
    body2: {
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '20px',
    },
    'body2-link': {
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '20px',
      textDecoration: 'underline',
    },
    'body2-bold': {
      fontSize: '12px',
      fontWeight: 500,
      lineHeight: '20px',
      letterSpacing: '0.01em',
    },
    body3: {
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '20px',
    },
    'body3-link': {
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '20px',
      textDecoration: 'underline',
    },
    'body3-bold': {
      fontSize: '12px',
      fontWeight: 500,
      lineHeight: '20px',
      letterSpacing: '0.01em',
    },
    body4: {
      fontSize: '11px',
      fontWeight: 400,
      lineHeight: '18px',
      letterSpacing: '0.05px',
    },
    'body4-bold': {
      fontSize: '11px',
      fontWeight: 500,
      lineHeight: '18px',
      letterSpacing: '0.05px',
    },
  },
}

export default Text
