import { Box, Text, Flex } from '@chakra-ui/react'
import React from 'react'

import { formatUtc } from 'utils/formatDate'

const MainCard = ({
  keyStr,
  children,
  isMobile = false,
}: {
  keyStr: string
  children: React.ReactNode
  isMobile?: boolean
}) => {
  return (
    <Box mb={8}>
      <Box
        py={2}
        px={8}
        bg='gray5'
        borderWidth='1px 0px 1px 0px'
        borderColor='gray3'
      >
        <Text variant='body2-bold'>
          {formatUtc(new Date(keyStr), 'MMM yyyy')}
        </Text>
      </Box>
      <Flex
        width='full'
        flexDir='column'
        gap={8}
        maxW={isMobile ? '95%' : '60%'}
        pl={8}
        py={6}
      >
        {children}
      </Flex>
    </Box>
  )
}

export default MainCard
