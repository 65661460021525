import { CarbonIconType } from '@carbon/icons-react/lib/CarbonIcon'
import { MenuItem, Icon } from '@chakra-ui/react'
import { Link as ReactRouterLink } from 'react-router-dom'

export type DropdownMenuLinkPropTypes = {
  href: string
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  icon: CarbonIconType
}

export const DropdownMenuLink: React.FC<
  React.PropsWithChildren<DropdownMenuLinkPropTypes>
> = ({ href, onClick, children, icon }) => {
  return (
    <MenuItem
      icon={<Icon as={icon} boxSize='18px' />}
      as={ReactRouterLink}
      to={href}
      py={2}
      onClick={onClick}
      fontSize='sm'
      fontWeight={500}
      _hover={{
        color: 'brand.primary.base',
        bg: 'gray.50',
        cursor: 'pointer',
      }}
    >
      {children}
    </MenuItem>
  )
}

export type DropdownMenuButtonPropTypes = {
  onClick: React.MouseEventHandler<HTMLButtonElement>
  icon: CarbonIconType
}

export const DropdownMenuButton: React.FC<
  React.PropsWithChildren<DropdownMenuButtonPropTypes>
> = ({ onClick, children, icon }) => {
  return (
    <MenuItem
      icon={<Icon as={icon} boxSize='18px' />}
      py={2}
      onClick={onClick}
      fontSize='sm'
      fontWeight={500}
      _hover={{
        color: 'brand.primary.base',
        bg: 'gray.50',
        cursor: 'pointer',
      }}
    >
      {children}
    </MenuItem>
  )
}
