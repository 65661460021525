import GenericTable from 'modules/Tables'

import { IModel } from 'interfaces/model.interface'
import {
  INavigationPages,
  ISingleNavigationPage,
} from 'interfaces/navigationPage.interface'

import { PNHCandidateModel } from 'config/common/baseModel/pnh/PNHCandidates'
import { PNHClinicalTrialsModel } from 'config/common/baseModel/pnh/PNHClinicalTrials'
import { PNHMediaModel } from 'config/common/baseModel/pnh/PNHMedia'
import { PNHPressReleasesModel } from 'config/common/baseModel/pnh/PNHPressReleases'
import { PNHPublicationsModel } from 'config/common/baseModel/pnh/PNHPublications'
import { PNHTweetsModel } from 'config/common/baseModel/pnh/PNHTweets'

import { compose, whiteListColumn } from 'utils/overrideModel'

import { PNHSlugs } from './PNHMenu'

type PNHNavigationPage<TModel extends IModel<any>> = ISingleNavigationPage<
  PNHSlugs,
  TModel
>

const Candidates: PNHNavigationPage<typeof PNHCandidateModel> = {
  key: 'Candidates',
  path: ['candidates', 'all-data'],
  model: whiteListColumn<typeof PNHCandidateModel>([
    'candidateName',
    'companies',
    'candidateType',
    'target',
    'phaseInActiveClinicalTrials',
    'routeOfAdministration',
    'lookupCandidatePublications',
  ])(PNHCandidateModel),
  component: GenericTable,
  views: [
    {
      name: 'Default',
      airtableName: 'api_candidate_list',
      airtableBase: 'pnh',
    },
  ],
}

const PressReleases: PNHNavigationPage<typeof PNHPressReleasesModel> = {
  key: 'Press Releases',
  path: ['news', 'press-releases'],
  model: PNHPressReleasesModel,
  component: GenericTable,
  views: [
    {
      name: 'Default',
      airtableName: 'api_press_releases',
      airtableBase: 'pnh',
    },
  ],
}

const ClinicalTrials: PNHNavigationPage<typeof PNHClinicalTrialsModel> = {
  key: 'Clinical Trials',
  path: ['trials', 'table'],
  model: PNHClinicalTrialsModel,
  component: GenericTable,
  views: [
    {
      name: 'Default',
      airtableName: 'api_clinical_trials',
      airtableBase: 'pnh',
    },
  ],
}
const Publications: PNHNavigationPage<typeof PNHPublicationsModel> = {
  key: 'Publications',
  path: ['publications'],
  model: compose<typeof PNHPublicationsModel>(
    whiteListColumn([
      'title',
      'linkJournals',
      'publicationDate',
      'lookupCandidateList',
      'afTopics',
      'afArticleType',
      'afSentiment',
      'linkAfLeadAuthor',
      'linkAfLastAuthor',
      'doiUrl',
    ])
  )(PNHPublicationsModel),
  component: GenericTable,
  views: [
    {
      name: 'Default',
      airtableName: 'api_publications',
      airtableBase: 'pnh',
    },
  ],
}

const Media: PNHNavigationPage<typeof PNHMediaModel> = {
  key: 'Media',
  path: ['news', 'media'],
  model: PNHMediaModel,
  component: GenericTable,
  views: [
    {
      name: 'Default',
      airtableName: 'api_media',
      airtableBase: 'pnh',
    },
  ],
}

const Tweets: PNHNavigationPage<typeof PNHTweetsModel> = {
  key: 'Tweets',
  path: ['social', 'tweets'],
  model: PNHTweetsModel,
  component: GenericTable,
  views: [
    {
      name: 'Default',
      airtableName: 'api_tweets',
      airtableBase: 'pnh',
    },
  ],
}

export const PNHPages: INavigationPages<PNHSlugs> = {
  Candidates,
  Media,
  PressReleases,
  Tweets,
  ClinicalTrials,
  Publications,
}
