import { Box, Flex, Text } from '@chakra-ui/react'
import * as cheerio from 'cheerio'
import { truncate } from 'lodash'
import { memo, useEffect, useState } from 'react'

// @ts-ignore
import { Report } from 'api/types'

import { formatUtc } from 'utils/formatDate'

function getTextByRow(
  rowElements: cheerio.Cheerio<cheerio.Element>,
  summaryRowIndex: number
) {
  const summaryRow = rowElements.eq(summaryRowIndex)
  const textContent = summaryRow.find('td').text().trim()
  return textContent
}

type MiniCardPropTypes = {
  report: Report
  onClick: () => void
  summaryRowIndex: number
  isMobile?: boolean
}
const MiniCard = ({
  report,
  onClick,
  isMobile = false,
  summaryRowIndex,
}: MiniCardPropTypes) => {
  const [summaryText, setSummaryText] = useState<string>('')

  const title = report.title
  const date = new Date(report.date)
  const content = report.content

  useEffect(() => {
    const $ = cheerio.load(content)
    const rowElements = $('.contentbuilderBaseColumnRow')
    let textContent = getTextByRow(rowElements, summaryRowIndex)
    if (!textContent) {
      textContent = getTextByRow(rowElements, summaryRowIndex + 1)
    }
    setSummaryText(textContent)
  }, [content, summaryRowIndex])

  return (
    <Flex
      flexDir={isMobile ? 'column-reverse' : 'row'}
      justifyContent={'space-between'}
    >
      <Flex cursor='pointer' onClick={onClick} flexDir='column'>
        <Box>
          <Flex
            mb={2}
            justifyContent='space-between'
            flexDir={isMobile ? 'column' : 'row'}
            alignItems='flex-start'
          >
            <Text fontSize={'sm'} maxW='80%' variant='body-bold'>
              {title}
            </Text>
          </Flex>
          <Box
            maxW='480px'
            color='gray1'
            fontWeight={400}
            fontSize='14px'
            lineHeight='22px'
          >
            {truncate(summaryText, { length: 250 })}
          </Box>
        </Box>
      </Flex>
      <Text color='gray1' variant='body3-bold' whiteSpace={'nowrap'}>
        {formatUtc(date, 'dd MMM yyyy')}
      </Text>
    </Flex>
  )
}

export default memo(MiniCard)
