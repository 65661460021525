import { INavigationMenu } from 'interfaces/navigationMenu.interface'

export type CardioConferenceSlugs =
  | ''
  | 'conference-presentations'
  | 'social-media-activity'
  | 'easd'
  | 'esc'

const CardioConferenceMenu: Array<INavigationMenu<CardioConferenceSlugs>[]> = [
  [
    {
      title: 'Reports',
      slug: '',
      description: 'Bespoke Conference Reports by Airfinity',
      createdAt: new Date(2021, 8, 8),
    },
  ],
  [
    {
      title: 'Conference Presentations',
      slug: 'conference-presentations',
      description:
        'All relevant conference sessions annotated by active substance, sentiment, independence, and presenter',
      createdAt: new Date(2021, 8, 8),
      children: [
        {
          title: 'EASD',
          slug: 'easd',
          createdAt: new Date(2021, 9, 13),
        },
        {
          title: 'ESC',
          slug: 'esc',
          createdAt: new Date(2021, 9, 13),
        },
      ],
    },

    {
      title: 'Social media activity',
      slug: 'social-media-activity',
      description:
        'All therapy-area related KOL activity on Twitter before, during, and after the conference',
      createdAt: new Date(2021, 8, 8),
      children: [
        {
          title: 'EASD',
          slug: 'easd',
          createdAt: new Date(2021, 9, 13),
        },
        {
          title: 'ESC',
          slug: 'esc',
          createdAt: new Date(2021, 9, 13),
        },
      ],
    },
  ],
]

export { CardioConferenceMenu }
