import { Undo } from '@carbon/icons-react'
import { Box, CloseButton, Heading, Skeleton } from '@chakra-ui/react'
import { NAV_HEIGHT } from 'constants/misc'
import Truncate from 'react-truncate'

export const DetailDrawerHeader = ({
  title,
  handleClose,
  isLoading,
}: {
  title?: any
  isLoading?: boolean
  handleClose?: any
}) => {
  return (
    <Box
      display='flex'
      pr={4}
      pl={1}
      color='white'
      width='100%'
      minHeight={NAV_HEIGHT}
      bg='black'
      alignItems='center'
      zIndex={2}
      borderBottom='4px solid'
      borderColor='secondary'
    >
      <Box onClick={handleClose}>
        <CloseButton mr={2}>
          <Undo size={20} />
        </CloseButton>
      </Box>
      {isLoading ? (
        <Skeleton width='200px' />
      ) : (
        <Heading fontSize='lg' fontWeight={500} width='100%' title={title}>
          <Truncate>{title}</Truncate>
        </Heading>
      )}
      <Box onClick={handleClose} ml='auto'>
        <CloseButton />
      </Box>
    </Box>
  )
}
