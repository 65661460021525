import { IApp } from 'interfaces/navigationApp.interface'

import { AccessGroups } from 'enums/AccessGroups'

import { Covid19CommercialVaccinesCustomPages } from 'config/apps/Covid19/Commercial/Vaccines/Covid19CommercialVaccinesCustomPages'
import {
  Covid19CommercialVaccinesMenu,
  Covid19CommercialVaccinesSlugs,
} from 'config/apps/Covid19/Commercial/Vaccines/Covid19CommercialVaccinesMenu'
import { Covid19CommercialVaccinesPages } from 'config/apps/Covid19/Commercial/Vaccines/Covid19CommercialVaccinesPages'

const ModernaCovidCommercialApp: IApp<Covid19CommercialVaccinesSlugs> = {
  name: 'COVID-19',
  slug: 'moderna-covid-19-commercial',
  commonIdentifier: 'covid-19',
  accessGroups: [AccessGroups.Moderna],
  endpoint: 'supply-and-production',
  menu: Covid19CommercialVaccinesMenu,
  pages: Covid19CommercialVaccinesPages,
  customPages: Covid19CommercialVaccinesCustomPages,
  createdAt: new Date(2022, 5, 30),
}

export default ModernaCovidCommercialApp
