import { RNACandidatesDetailView } from 'routes/apps/rna/Candidates'

import { fetchCollection } from 'api/useCollectionData'

import { ValueType } from 'interfaces/valueType.interface'

import { PublicationsModel } from 'config/apps/ResearchPowerIndex/Custom/ResearchPowerIndex/ResearchPowerIndexModel'
import {
  CandidateRankingModel,
  ClinicalTrialsModel,
  MediaModel,
  PresentationsModel,
  PressReleasesModel,
} from 'config/common/baseModel'
import { DeliverySystemsModel } from 'config/common/baseModel/DeliverySystems'

import {
  compose,
  renameColumn,
  whiteListColumn,
  updateColumnAttributes,
} from 'utils/overrideModel'

export const RNATherapeuticsCandidatesModel = compose<
  typeof CandidateRankingModel
>(
  whiteListColumn<typeof CandidateRankingModel>([
    'candidateName',
    'product',
    'candidateClass',
    'highestClinicalTrialPhase',
    'detailedDeliverySystem',
    'diseaseCategory',
    'linkDisease',
    'target',
    'routeOfAdministration',
    'organisationsCentralised',
  ]),
  renameColumn('candidateClass', 'Technology Type'),
  renameColumn('product', 'Vaccine or Treatment'),
  renameColumn('highestClinicalTrialPhase', 'Phase'),
  renameColumn('linkDisease', 'Disease'),
  updateColumnAttributes('candidateName', {
    type: ValueType.MULTI,
  })
)({
  ...CandidateRankingModel,
  chartColumnBlackList: ['linkClinicalTrials'],
  detailViewType: 'Generic',
  displayKey: 'candidateName',
  renderDetailView: RNACandidatesDetailView,
})

export const RNATherapeuticsCandidatePapersModel = compose<
  typeof PresentationsModel
>(
  whiteListColumn([
    'title',
    'linkJournals',
    'publicationDate',
    'candidateName',
    'indications',
    'detailedDeliverySystem',
    'articleType',
    'articleLink',
  ])
)({
  ...PresentationsModel,
  name: 'Publications',
  endpoint: 'publication',
  detailView: {
    link: 'doiUrl',
    abstract: 'abstract',
  },
})

export const RNATherapeuticsClinicalTrialsModel = compose<
  typeof ClinicalTrialsModel
>(
  whiteListColumn([
    'clinicalTrialId',
    'title',
    'linkDisease',
    'acronym',
    'luCandidateName',
    'sponsor',
    'phasesClean',
    'status',
    'afStartDate',
    'enrolledAt',
    'primaryCompletionDate',
    'completedAt',
    'targetEnrollment',
    'participants',
    'studyType',
    'ages',
    'primaryOutcomeMeasures',
    'locations',
    'url',
  ]),
  renameColumn('clinicalTrialId', 'Trial ID'),
  renameColumn('luCandidateName', 'Candidate'),
  renameColumn('sponsor', 'Sponsors'),
  renameColumn('phasesClean', 'Phase'),
  updateColumnAttributes('phasesClean', {
    type: ValueType.MULTI,
    sortOrderObject: undefined,
  })
)({
  ...ClinicalTrialsModel,
  endpoint: 'clinical-trial',
  displayKey: 'clinicalTrialId',
})

export const RNATherapeuticsPressModel = compose<typeof PressReleasesModel>(
  whiteListColumn([
    'title',
    'candidateName',
    'datePublished',
    'topic',
    'company',
    'link',
    'indications',
    'candidateClass',
  ])
)({
  ...PressReleasesModel,
  detailView: {
    link: 'link',
    abstract: 'description',
  },
})

export const RNATherapeuticsMediaModel = compose<typeof MediaModel>(
  whiteListColumn([
    'title',
    'candidateName',
    'datePublished',
    'topics',
    'mediaProviderName',
    'link',
    'indications',
    'candidateClass',
  ])
)(MediaModel)

export const RNATherapeuticsDeliverySystemsModel = compose<
  typeof DeliverySystemsModel
>(
  whiteListColumn([
    'deliverySystem',
    'category',
    'candidateName',
    'technologyType',
    'disease',
    'highestClinicalTrialPhase',
    'ownership',
    'tissueCellTarget',
    'publicationsLinkObj',
    'mediaLinkObj',
    'pressReleasesLinkObj',
    'organisation',
  ]),
  renameColumn('nameFromPlatform', 'Platform')
)({ ...DeliverySystemsModel, chartColumnBlackList: ['candidateName'] })

export const RNATherapeuticsDeliverySystemsInvestigationalModel = compose<
  typeof DeliverySystemsModel
>(
  whiteListColumn([
    'deliverySystem',
    'category',
    'publicationsLinkObj',
    'mediaLinkObj',
    'pressReleasesLinkObj',
    'tissueCellTarget',
    'organisation',
    'ownership',
  ])
)(DeliverySystemsModel)

export const RNATherapeuticsDeliverySystemsNewsModel = compose<
  typeof MediaModel
>(
  whiteListColumn<typeof MediaModel>([
    'title',
    'datePublished',
    'companiesAndResearchInstitutions',
    'deliverySystem',
    // 'platform',
    'candidateName',
    'candidateClass',
    'indications',
    'link',
  ]),
  renameColumn('candidateClass', 'Technology Type')
)({
  ...MediaModel,
  name: 'Delivery Systems News',
  detailViewType: undefined,
  customAPIFunction: (axios) => {
    const resolveData = Promise.all([
      fetchCollection(axios, 'rna', PressReleasesModel.endpoint, {
        airtableBase: 'advancedtherapies',
        view: 'RNA_API_DeliverySystemsNews',
      }),
      fetchCollection(axios, 'rna', MediaModel.endpoint, {
        airtableBase: 'advancedtherapies',
        view: 'RNA_API_DeliverySystemsNews',
      }),
      fetchCollection(axios, 'rna', PublicationsModel.endpoint, {
        airtableBase: 'advancedtherapies',
        view: 'RNA_API_DeliverySystemsNews',
      }),
    ]).then((d) => d[0].results.concat(d[1].results, d[2].results))

    return () => resolveData
  },
})
export const RNATherapeuticsInvestigationalNewsModel = compose<
  typeof MediaModel
>(
  whiteListColumn<typeof MediaModel>([
    'title',
    'datePublished',
    'approvalType',
    'designation',
    'candidateName',
    'candidateClass',
    'indications',
    'linkCompany',
    'linkCountry',
    'regulatoryBody',
    'link',
  ])
)({
  ...MediaModel,
  name: 'Investigational News',
  detailViewType: undefined,
  customAPIFunction: (axios) => {
    const resolveData = Promise.all([
      fetchCollection(axios, 'rna', MediaModel.endpoint, {
        airtableBase: 'advancedtherapies',
        view: 'RNA_API_RegulatoryNews_Investigational',
      }),
      fetchCollection(axios, 'rna', PressReleasesModel.endpoint, {
        airtableBase: 'advancedtherapies',
        view: 'RNA_API_RegulatoryNews_Investigational',
      }),
    ]).then((d) => d[0].results.concat(d[1].results))

    return () => resolveData
  },
})
export const RNATherapeuticsApprovalNewsModel = compose<typeof MediaModel>(
  whiteListColumn<typeof MediaModel>([
    'title',
    'datePublished',
    'approvalType',
    'candidateName',
    'candidateClass',
    'indications',
    'linkCompany',
    'linkCountry',
    'regulatoryBody',
    'link',
  ])
)({
  ...MediaModel,
  name: 'Approval News',
  detailViewType: undefined,
  customAPIFunction: (axios) => {
    const resolveData = Promise.all([
      fetchCollection(axios, 'rna', MediaModel.endpoint, {
        airtableBase: 'advancedtherapies',
        view: 'RNA_API_RegulatoryNews_Approval',
      }),
      fetchCollection(axios, 'rna', PressReleasesModel.endpoint, {
        airtableBase: 'advancedtherapies',
        view: 'RNA_API_RegulatoryNews_Approval',
      }),
    ]).then((d) => d[0].results.concat(d[1].results))

    return () => resolveData
  },
})

export const RNATherapeuticsFundingNewsModel = compose<typeof MediaModel>(
  whiteListColumn<typeof MediaModel>([
    'title',
    'datePublished',
    'linkOrganisationsInvolved',
    'dealType',
    'dealValue',
    'linkCountryMarket',
    'linkRegion',
    'deliverySystem',
    'candidateName',
    'candidateClass',
    'linkDiseases',
    'link',
  ])
)({
  ...MediaModel,
  name: 'Funding News',
  detailViewType: undefined,
  customAPIFunction: (axios) => {
    const resolveData = Promise.all([
      fetchCollection(axios, 'rna', MediaModel.endpoint, {
        airtableBase: 'advancedtherapies',
        view: 'RNA_API_FundingNews',
      }),
      fetchCollection(axios, 'rna', PressReleasesModel.endpoint, {
        airtableBase: 'advancedtherapies',
        view: 'RNA_API_FundingNews',
      }),
    ]).then((d) => d[0].results.concat(d[1].results))

    return () => resolveData
  },
})
