import { Divider, Box } from '@chakra-ui/react'

import CountriesSummaryTable from 'modules/CountriesSummaryTable'

import { Panel, PanelBody, TableauEmbed } from 'components'

export default () => {
  return (
    <Panel overflow='hidden' height='100%'>
      <PanelBody>
        <Box my='2rem' />
        <TableauEmbed path='/OWID_HS_ML_2/Historicdatadashboard_1' />
        <Divider my='5rem' />
        <CountriesSummaryTable unit='million' />
      </PanelBody>
    </Panel>
  )
}
