import { Box, TabPanel, TabPanelProps, TabPanels } from '@chakra-ui/react'
import moment from 'moment'

import {
  DetailDrawerHeader,
  DetailDrawerList,
  DetailDrawerListMedia,
  DetailDrawerListPaper,
  DetailDrawerListPressRelease,
  DetailDrawerListTrial,
  DetailDrawerTabs,
  DetailDrawerSummary,
} from 'modules/DetailDrawer'

import TableView from 'routes/apps/TableView'
import { DetailDrawerListApprovals } from 'routes/apps/vaccines/CandidateRanking/Detail/DetailDrawerListApprovals'
import { useAppRoute } from 'routes/utils'

import { SlideOver } from 'components'

import { IResponseBase } from 'api/types'

import { IModel } from 'interfaces/model.interface'
import { ViewData } from 'interfaces/navigationPage.interface'

import { apps } from 'config/apps'
import {
  TreatmentClinicalTrialsModel,
  TreatmentResultsApprovedCandidatesModel,
} from 'config/apps/Covid19/Science360/Therapeutics/TreatmentsModels'
import {
  PresentationsModel,
  PressReleasesModel,
  MediaModel,
  CandidateRankingData,
} from 'config/common/baseModel'
import { ApprovalsModel } from 'config/common/baseModel/Approvals'
import { TreatmentStudyDataMetaModel } from 'config/common/baseModel/TreatmentStudyDataMeta'

const StyledTabPanel: React.FC<React.PropsWithChildren<TabPanelProps>> = (
  props
) => <TabPanel height='100%' p={0} {...props} />

type PropTypes<D extends Record<CandidateRankingData, any>> = {
  handleClose: () => void
  candidate: IResponseBase<Extract<keyof D, string>>
  model: IModel<D>
  title: Extract<keyof D, string>
  viewData: ViewData
}

function Detail<D extends Record<CandidateRankingData, any>>(
  props: PropTypes<D>
) {
  const { handleClose, candidate, model, title, viewData } = props

  const app = useAppRoute()

  const exportName = (modelTarget: IModel<any>) =>
    `${apps[app].name}_${model.name}_${candidate && candidate[title]}_${
      modelTarget.name
    }`

  return (
    <SlideOver show={true}>
      <DetailDrawerHeader
        isLoading={false}
        title={candidate && candidate[title]}
        handleClose={handleClose}
      />
      <DetailDrawerTabs
        tabs={[
          'Summary',
          'Media',
          'Press Releases',
          'Papers',
          'Trials',
          'Trial Results Analysis',
          'Approvals',
        ]}
      >
        <Box flexGrow={1} flexBasis={0} overflow='hidden'>
          <TabPanels height='100%'>
            <StyledTabPanel p={6} overflow='auto'>
              <DetailDrawerSummary
                rowData={candidate}
                model={model}
                viewData={viewData}
              />
            </StyledTabPanel>
            <StyledTabPanel>
              <DetailDrawerList
                id={1}
                viewData={viewData}
                type='Media'
                model={MediaModel}
                sortOrder={(x) => moment(x.datePublished)}
                ids={candidate.media}
                itemComponent={DetailDrawerListMedia}
              />
            </StyledTabPanel>
            <StyledTabPanel>
              <DetailDrawerList
                id={2}
                viewData={viewData}
                type='PressReleases'
                sortOrder={(x) => moment(x.datePublished)}
                model={PressReleasesModel}
                ids={candidate.pressReleases}
                itemComponent={DetailDrawerListPressRelease}
              />
            </StyledTabPanel>
            <StyledTabPanel>
              <DetailDrawerList
                id={3}
                viewData={viewData}
                type='Papers'
                model={PresentationsModel}
                sortOrder={(x) => moment(x.publicationDate)}
                ids={candidate.linkPapers}
                itemComponent={DetailDrawerListPaper}
              />
            </StyledTabPanel>
            <StyledTabPanel>
              <DetailDrawerList
                id={4}
                viewData={viewData}
                sortOrder={(x) => moment(x.completedAt)}
                model={{
                  ...TreatmentClinicalTrialsModel,
                  endpoint: 'clinical-trial',
                }}
                ids={candidate.linkClinicalTrials}
                itemComponent={DetailDrawerListTrial}
              />
            </StyledTabPanel>
            <StyledTabPanel>
              <TableView
                id={5}
                viewData={viewData}
                model={TreatmentResultsApprovedCandidatesModel}
                filterData={(data) =>
                  data?.filter((row) => {
                    if (
                      Array.isArray(row['linkInfectiousDisease']) &&
                      row['linkInfectiousDisease'][0] === 'COVID-19'
                    ) {
                      return (
                        row['afParentOrChild'] !== 'Parent' &&
                        row['dataType'] === 'Trial Results'
                      )
                    } else {
                      return row['afParentOrChild'] !== 'Parent'
                    }
                  })
                }
                ids={candidate.treatmentStudyDataMeta}
                exportName={exportName(TreatmentStudyDataMetaModel)}
              />
            </StyledTabPanel>
            <StyledTabPanel>
              <DetailDrawerList
                id={6}
                viewData={viewData}
                model={ApprovalsModel}
                ids={candidate.approvals}
                itemComponent={DetailDrawerListApprovals}
              />
            </StyledTabPanel>
          </TabPanels>
        </Box>
      </DetailDrawerTabs>
    </SlideOver>
  )
}

export default Detail
