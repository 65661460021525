import { Download } from '@carbon/icons-react'
import { Box, Link } from '@chakra-ui/react'

type PropTypes = {
  inModal?: boolean
}

export function Upsell({ inModal = false }: PropTypes) {
  return (
    <Box
      py={4}
      px={5}
      borderTop='1px solid'
      borderColor='gray.100'
      display='flex'
      alignItems='center'
    >
      <Box
        width='32px'
        height='32px'
        mr={inModal ? 10 : 2}
        display='flex'
        bg='white'
        rounded='full'
        shadow='sm'
        alignItems='center'
        justifyContent='center'
      >
        <Download size={inModal ? 32 : 16} />
      </Box>

      <div>
        Weekly updates are only available to higher subscription tiers. Please
        contact your account manager or email{' '}
        <Link href='mailto:support@airfinity.com' color='legacy-primary.500'>
          support@airfinity.com
        </Link>{' '}
        to unlock this feature.
      </div>
    </Box>
  )
}
