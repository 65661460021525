import { Image } from '@chakra-ui/react'

import { ViewPdfReport } from 'components'

import { FileObject, ResponseData } from 'api/types'

type FileProptypes = {
  file: FileObject
  handleClick: (e: any) => void
}

const File = ({ file, handleClick }: FileProptypes) => {
  return (
    <Image
      bg='gray.50'
      rounded='md'
      p={5}
      mb={2}
      cursor='pointer'
      display='flex'
      alignItems='center'
      onClick={(e: any) => {
        e.stopPropagation()
        handleClick(e)
      }}
      src={file.thumbnails.large.url}
      alt={file.filename}
    />
  )
}

type PropTypes = {
  value: ResponseData
}

const Files = ({ value }: PropTypes) => {
  if (value && Array.isArray(value) && value.length > 0) {
    return (
      <>
        {(value as FileObject[]).map((file: FileObject) => (
          <ViewPdfReport
            url={file.url}
            triggerComponent={({ handleClick }) => (
              <File file={file} handleClick={handleClick} />
            )}
            key={file.url}
          />
        ))}
      </>
    )
  }
  return null
}

export default Files
