import GenericTable from 'modules/Tables'

import { IModel } from 'interfaces/model.interface'
import {
  INavigationPages,
  ISingleNavigationPage,
} from 'interfaces/navigationPage.interface'

import { CardioAntithromboticsSlugs } from './CardioAntithromboticsMenu'
import {
  CardioAntithromboticsCandidatesModel,
  CardioAntithromboticsClinicalTrialsModel,
  CardioAntithromboticsConferencePresentationsModel,
  CardioAntithromboticsIndicationsDataModel,
  CardioAntithromboticsKolsModel,
  CardioAntithromboticsNewsModel,
  CardioAntithromboticsPressReleasesModel,
  CardioAntithromboticsPublicationsModel,
  CardioAntithromboticsTweetsModel,
} from './CardioAntithromboticsModel'

type CardioAntithromboticsNavigationPage<TModel extends IModel<any>> =
  ISingleNavigationPage<CardioAntithromboticsSlugs, TModel>

const CardioAntithromboticsCandidates: CardioAntithromboticsNavigationPage<
  typeof CardioAntithromboticsCandidatesModel
> = {
  key: 'CardioAntithromboticsCandidates',
  path: ['candidates', 'table'],
  component: GenericTable,
  model: CardioAntithromboticsCandidatesModel,
  views: [
    {
      name: 'Default',
      airtableName: 'api_anti-thrombotics_candidate_overview',
      airtableBase: 'cardio360',
    },
  ],
}

const CardioAntithromboticsClinicalTrials: CardioAntithromboticsNavigationPage<
  typeof CardioAntithromboticsClinicalTrialsModel
> = {
  key: 'CardioAntithromboticsClinicalTrials',
  path: ['clinical-trials', 'table'],
  component: GenericTable,
  model: CardioAntithromboticsClinicalTrialsModel,
  views: [
    {
      name: 'Default',
      airtableName: 'api_anti-thrombotics_clinical_trials',
      airtableBase: 'cardio360',
    },
  ],
}

const CardioAntithromboticsIndicationsData: CardioAntithromboticsNavigationPage<
  typeof CardioAntithromboticsIndicationsDataModel
> = {
  key: 'CardioAntithromboticsIndicationsData',
  path: ['approved-indications'],
  component: GenericTable,
  model: CardioAntithromboticsIndicationsDataModel,
  views: [
    {
      name: 'Default',
      airtableName: 'api_anti-thrombotics_indications',
      airtableBase: 'cardio360',
    },
  ],
}

const CardioAntithromboticsPublications: CardioAntithromboticsNavigationPage<
  typeof CardioAntithromboticsPublicationsModel
> = {
  key: 'CardioAntithromboticsPublications',
  path: ['publications'],
  component: GenericTable,
  model: CardioAntithromboticsPublicationsModel,
  views: [
    {
      name: 'Default',
      airtableName: 'api_anti_thrombotics_publications',
      airtableBase: 'cardio360',
    },
  ],
}

const CardioAntithromboticsConferencePresentations: CardioAntithromboticsNavigationPage<
  typeof CardioAntithromboticsConferencePresentationsModel
> = {
  key: 'CardioAntithromboticsConferencePresentations',
  path: ['conference-presentations'],
  component: GenericTable,
  model: CardioAntithromboticsConferencePresentationsModel,
  views: [
    {
      name: 'Default',
      airtableName: 'api_conference_presentations_antithrombotics',
      airtableBase: 'cardio360',
    },
  ],
}

const CardioAntithromboticsPressReleases: CardioAntithromboticsNavigationPage<
  typeof CardioAntithromboticsPressReleasesModel
> = {
  key: 'CardioAntithromboticsPressReleases',
  path: ['news', 'press-releases'],
  component: GenericTable,
  model: CardioAntithromboticsPressReleasesModel,
  views: [
    {
      name: 'Default',
      airtableName: 'api_anti-thrombotics_press_releases',
      airtableBase: 'cardio360',
    },
  ],
}

const CardioAntithromboticsTweets: CardioAntithromboticsNavigationPage<
  typeof CardioAntithromboticsTweetsModel
> = {
  key: 'CardioAntithromboticsTweets',
  path: ['tweets'],
  component: GenericTable,
  model: CardioAntithromboticsTweetsModel,
  views: [
    {
      name: 'Default',
      airtableName: 'api_anti-thrombotics_tweets',
      airtableBase: 'cardio360',
    },
  ],
}

const CardioAntithromboticsKols: CardioAntithromboticsNavigationPage<
  typeof CardioAntithromboticsKolsModel
> = {
  key: 'kols',
  path: 'kols',
  component: GenericTable,
  model: CardioAntithromboticsKolsModel,
  views: [
    {
      name: 'Default',
      airtableName: 'api_KOLs_AT_360',
      airtableBase: 'cardio360',
    },
  ],
}

const CardioAntithromboticsNews: CardioAntithromboticsNavigationPage<
  typeof CardioAntithromboticsNewsModel
> = {
  key: 'CardioAntithromboticsNews',
  path: ['news', 'media'],
  component: GenericTable,
  model: CardioAntithromboticsNewsModel,
  views: [
    {
      name: 'Default',
      airtableName: 'api_antithromobotics_news_articles',
      airtableBase: 'cardio360',
    },
  ],
}

export const CardioAntithromboticsPages: INavigationPages<CardioAntithromboticsSlugs> =
  {
    CardioAntithromboticsCandidates,
    CardioAntithromboticsClinicalTrials,
    CardioAntithromboticsIndicationsData,
    CardioAntithromboticsPublications,
    CardioAntithromboticsConferencePresentations,
    CardioAntithromboticsPressReleases,
    CardioAntithromboticsTweets,
    CardioAntithromboticsKols,
    CardioAntithromboticsNews,
  }
