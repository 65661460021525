import { IApp } from 'interfaces/navigationApp.interface'

import { AccessGroups } from 'enums/AccessGroups'

import { RespiratoryTimelineModel } from 'config/common/baseModel/respiratory360/RespiratoryTimeline'

import { InfluenzaCustomPages } from './InfluenzaCustomPages'
import { InfluenzaMenu, InfluenzaSlugs } from './InfluenzaMenu'
import { InfluenzaPages } from './InfluenzaPages'

const influenza: IApp<InfluenzaSlugs> = {
  name: 'Vaccines & Therapeutics',
  slug: 'influenza',
  accessGroups: [AccessGroups.Influenza],
  endpoint: 'influenza',
  menu: InfluenzaMenu,
  pages: InfluenzaPages,
  baseModels: [RespiratoryTimelineModel],
  customPages: InfluenzaCustomPages,
  // We want to group this on covid intentionally. Only affects where it shows on base page
  createdAt: new Date(2021, 10, 11),
  tutorial: [
    {
      url: 'https://airfinity-production.s3.amazonaws.com/public/walkthroughs/influenza-science360.mp4',
      name: 'Influenza - Vaccines & Therapeutics',
    },
  ],
}

export default influenza
