import { clamp } from './math'

const colorRange = [
  'red.300',
  'red.400',
  'red.500',
  'red.600',
  'red.700',
  'red.800',
  'green.800',
  'green.700',
  'green.600',
  'green.500',
  'green.400',
  'green.300',
]

/**
 * Used to get the color of a text based on the value between a range
 */
export const calculateStatColor = (
  value: number,
  min: number = 100,
  max: number = -100
) => {
  if (value === null) {
    return 'gray.600'
  }

  if (value === 0) {
    return 'black'
  }

  const normalized = ((value - min) / (max - min)) * (colorRange.length - 1)
  return colorRange[clamp(Math.round(normalized), 0, colorRange.length - 1)]
}
