import { CardioSovConferencePresentationsModel } from 'config/common/baseModel/cardiosov/CardioSovConferencePresentations'
import { CardioSovESCTweetsModel } from 'config/common/baseModel/cardiosov/CardioSovTweets'

import { compose, whiteListColumn } from 'utils/overrideModel'

export const CardioConferenceConferencePresentationsModel = compose<
  typeof CardioSovConferencePresentationsModel
>(
  whiteListColumn<typeof CardioSovConferencePresentationsModel>([
    'sessionName',
    'titleClean',
    'afTopics',
    'drugClass',
    'activeSubstance',
    'afSentiment',
    'lookupMappedSessionType',
    'presentingAuthor',
    'lookupHIndex',
    'likes',
    'views',
    'comments',
    'afIndependent',
  ])
)({
  ...CardioSovConferencePresentationsModel,
  name: 'Conference Presentations',
})

export const CardioConferenceConferencePresentationsModelESAD = compose<
  typeof CardioSovConferencePresentationsModel
>(
  whiteListColumn<typeof CardioSovConferencePresentationsModel>([
    'sessionName',
    'titleClean',
    'afTopics',
    'drugClass',
    'activeSubstance',
    'afSentiment',
    'lookupMappedSessionType',
    'presentingAuthor',
    'lookupHIndex',
    'likes',
    'views',
    'comments',
    'afIndependent',
    'sponsors',
  ])
)({
  ...CardioSovConferencePresentationsModel,
  name: 'Conference Presentations',
})

export const CardioConferenceTweetsModel = compose<
  typeof CardioSovESCTweetsModel
>(
  whiteListColumn<typeof CardioSovESCTweetsModel>([
    'screenName',
    'createdAt',
    'tweetUrl',
    'fullText',
    'ecActiveSubstance',
    'sentiment',
    'retweetCount',
    'favoriteCount',
    'lang',
    'hashtags',
    'cashtags',
    'urls',
  ])
)({
  ...CardioSovESCTweetsModel,
  name: 'Social Media Activity',
})
export const EASD021ConferenceTweetsModel = compose<
  typeof CardioSovESCTweetsModel
>(
  whiteListColumn<typeof CardioSovESCTweetsModel>([
    'screenName',
    'createdAt',
    'tweetUrl',
    'fullText',
    'ecActiveSubstance',
    'sentiment',
    'retweetCount',
    'favoriteCount',
    'lang',
    'hashtags',
    'cashtags',
    'urls',
  ])
)({
  ...CardioSovESCTweetsModel,
  endpoint: 'easd-2021-s-sov-tweet',
  name: 'Social Media Activity',
})
