import { ISingleCustomPage } from 'interfaces/navigationPage.interface'

import { IEGPolicySurveillanceSlugs } from './IEGPolicySurveillanceMenu'
import PolicySurveillanceDashboard from './components/PolicySurveillanceDashboard'

const DashboardPage: ISingleCustomPage<IEGPolicySurveillanceSlugs> = {
  path: '',
  component: () => <PolicySurveillanceDashboard />,
}

export const IEGPolicySurveillanceCustomPage: Record<
  string,
  ISingleCustomPage<IEGPolicySurveillanceSlugs>
> = {
  DashboardPage,
}
