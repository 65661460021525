import { Box, Flex, Heading, Text } from '@chakra-ui/react'

const Header = () => {
  return (
    <Flex padding={'0'} h='50px' alignItems='center' pb={4}>
      <Box
        bgColor='yellow.500'
        rounded={'md'}
        w='100px'
        mr='1rem'
        justifyContent={'center'}
      >
        <Text textAlign={'center'} variant={'body-bold'}>
          Beta
        </Text>
      </Box>
      <Flex alignItems={'center'} justifyContent='center'>
        <Heading
          fontSize='20px'
          color='primary'
          lineHeight='38px'
          fontWeight='semibold'
          letterSpacing='-0.1px'
        >
          Biorisk's Reports AI-Search
        </Heading>
        <Text ml='1rem' as='i' textColor={'gray.800'} fontWeight='semibold'>
          (Reports + Alerts )
        </Text>
      </Flex>
    </Flex>
  )
}

export default Header
