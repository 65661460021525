import { Box } from '@chakra-ui/react'
import { TinyColor } from '@ctrl/tinycolor'

import styles from './Heartrate.module.css'

export type LoadingAnimationPropTypes = {
  backgroundColor?: string
  height?: string
}

export const LoadingAnimation = ({
  backgroundColor = 'white',
  height = '100vh',
}: LoadingAnimationPropTypes) => {
  const color = new TinyColor(backgroundColor)
  const { r, g, b } = color.toRgb()

  const gradient = `linear-gradient(to right, rgba(${r}, ${g}, ${b}, 1) 0%, rgba(${r}, ${g}, ${b}, 1) 80%, rgba(${r}, ${g}, ${b}, 0) 100%)`

  return (
    <Box
      height={height}
      p={8}
      textAlign='center'
      display='flex'
      alignItems='center'
      justifyContent='center'
      flexDirection='column'
      data-cy='loading'
    >
      <div className={styles['heart-rate']}>
        <svg
          x='0px'
          y='0px'
          width='150px'
          height='73px'
          viewBox='0 0 150 73'
          enableBackground='new 0 0 150 73'
        >
          <polyline
            fill='none'
            stroke='black'
            strokeWidth='3'
            strokeMiterlimit='10'
            points='0,45.486 38.514,45.486 44.595,33.324 50.676,45.486 57.771,45.486 62.838,55.622 71.959,9 80.067,63.729 84.122,45.486 97.297,45.486 103.379,40.419 110.473,45.486 150,45.486'
          />
        </svg>
        <div
          className={styles['fade-in']}
          style={{ backgroundColor: `rgba(${r}, ${g}, ${b}, 1)` }}
        ></div>
        <div
          className={styles['fade-out']}
          style={{ background: gradient }}
        ></div>
      </div>
    </Box>
  )
}
