import { UseQueryOptions, useQuery } from '@tanstack/react-query'
import { AxiosError, AxiosInstance } from 'axios'

import { getIsGov } from 'contexts/ClientType'

import useAxios from './useAxios'

export default function useTableauAuth(extraProps?: UseQueryOptions<any, any>) {
  const axios = useAxios()

  return useQuery<any, AxiosError>(
    ['tableauAuthentication'],
    () => {
      return permissionCheck(axios)
    },
    extraProps
  )
}

export const permissionCheck = async (axios: AxiosInstance): Promise<any> => {
  const baseUrl = getIsGov()
    ? process.env.REACT_APP_API_BASE_URL_GOV
    : process.env.REACT_APP_API_BASE_URL
  return axios.post(`${baseUrl}/tableau/token/`).then((x) => x.data?.token)
}
