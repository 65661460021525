export default {
  Oral: 'rgb(207, 223, 255)',
  Injection: 'rgb(208, 240, 253)',
  Surgical: 'rgb(249, 157, 226)',
  unknown: 'rgb(255, 218, 246)',
  Intravenous: 'rgb(255, 8, 194)',
  'Subcutaneous Injection': 'rgb(237, 226, 254)',
  Infusion: 'rgb(237, 226, 254)',
  Catheter: 'rgb(255, 169, 129)',
  Unspecified: 'rgb(249, 157, 226)',
}
