import TabbedDisplay from 'modules/TabbedDisplay'

import Dashboard from 'routes/dashboard'

import { TableauEmbed } from 'components'

import { ISingleCustomPage } from 'interfaces/navigationPage.interface'

import { getMediaDefaultWidget } from 'config/common/baseModel/Media'
import { getPresentationDefaultWidget } from 'config/common/baseModel/Presentations'
import { getPressReleasesDefaultWidget } from 'config/common/baseModel/PressReleases'

import { RNATherapeuticsSlugs } from './RNATherapeuticsMenu'
import { RNATherapeuticsPages } from './RNATherapeuticsPages'

const LatestInformation: ISingleCustomPage<RNATherapeuticsSlugs> = {
  path: '',
  component: () => {
    return (
      <>
        <Dashboard
          hideReports
          widgetTabs={[
            getMediaDefaultWidget({
              view: RNATherapeuticsPages['Media'].views[0],
            }),
            getPressReleasesDefaultWidget({
              view: RNATherapeuticsPages['PressReleases'].views[0],
            }),
            getPresentationDefaultWidget({
              view: RNATherapeuticsPages['CandidatePapers'].views[0],
              model: RNATherapeuticsPages.CandidatePapers.model,
            }),
          ]}
        />
      </>
    )
  },
}

const AllRNAPipeline: ISingleCustomPage<RNATherapeuticsSlugs> = {
  path: ['candidates', 'pipeline'],
  component: () => (
    <TabbedDisplay
      tabs={[
        {
          heading: 'By Technology Type',
          body: (
            <TableauEmbed path='/RNAPipelineAnalyserV2onco_Vxadditions/RNATech_type_Overview' />
          ),
        },
        {
          heading: 'By Delivery System',
          body: (
            <TableauEmbed path='/RNAPipelineAnalyserV2onco_Vxadditions/RNADS_Overview' />
          ),
        },
      ]}
    />
  ),
}

const FacilitiesMapView: ISingleCustomPage<RNATherapeuticsSlugs> = {
  path: 'facilities-map-view',
  component: () => <TableauEmbed path='/FacilitiesRNA/Dashboard2' />,
}
const ApprovalsMapDashboard: ISingleCustomPage<RNATherapeuticsSlugs> = {
  path: ['regulatory', 'approvals-map'],
  component: () => <TableauEmbed path='/V1RNAApprovals/RNAApprovals' />,
}
const ApprovalsTimelineDashboard: ISingleCustomPage<RNATherapeuticsSlugs> = {
  path: ['regulatory', 'approvals-timeline'],
  component: () => (
    <TableauEmbed
      path='/V2RNAApprovalTimeline/RNAApprovalsTimeline'
      height={'150vh'}
    />
  ),
}
const ExpeditedStatusDesignationsDashboard: ISingleCustomPage<RNATherapeuticsSlugs> =
  {
    path: ['regulatory', 'expedited-status-designations'],
    component: () => (
      <TableauEmbed
        path='/V2RNAExpeditedStatus/RNAExpeditedStatus'
        height='150vh'
      />
    ),
  }

export const RNATherapeuticsCustomPages: Record<
  string,
  ISingleCustomPage<RNATherapeuticsSlugs>
> = {
  LatestInformation,
  AllRNAPipeline,
  FacilitiesMapView,
  ApprovalsMapDashboard,
  ApprovalsTimelineDashboard,
  ExpeditedStatusDesignationsDashboard,
}
