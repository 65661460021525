import { Box, Text } from '@chakra-ui/react'

import AttributeLabel from 'modules/Attributes/AttributeLabel'

import { Tag } from 'components'

import { CleanResponseData } from 'api/types'

import { DealsData } from 'config/common/baseModel'

import numberWithCommas from 'utils/formatNumber'
import { relationGetDisplayValue } from 'utils/relational'

import { ItemComponentPropTypes } from '.'

const DetailDrawerListDeal = ({
  item,
  handleSelect,
}: ItemComponentPropTypes<Record<DealsData, any>>) => {
  const {
    afDealType,
    organisations,
    countries,
    afFundingFigure,
    afExpectedProduction,
    afSupplyNumber,
  } = item

  let titleField: string[] =
    !!organisations && Array.isArray(organisations) && organisations.length > 0
      ? (relationGetDisplayValue(organisations) as string[])
      : !!countries && Array.isArray(countries) && countries.length > 0
      ? (relationGetDisplayValue(countries) as string[])
      : []

  return (
    <Box
      cursor='pointer'
      px={8}
      py={4}
      display='flex'
      flexDirection='column'
      onClick={() => handleSelect(item)}
      borderBottom='1px solid'
      borderColor='gray.100'
      _hover={{
        bg: 'gray.50',
      }}
    >
      <Box display='flex' alignItems='center'>
        <Box fontSize='base' fontWeight='500'>
          {titleField?.join(', ')}
        </Box>

        <Tag fontSize='xs' color='blue' ml='auto' px='8px' rounded='none'>
          {afDealType}
        </Tag>
      </Box>
      <Box>
        <Field label='Funding Figure' value={afFundingFigure} />
        <Field label='Expected Production' value={afExpectedProduction} />
        <Field label='Supply' value={afSupplyNumber} />
      </Box>
    </Box>
  )
}

type FieldPropTypes = {
  label: string
  value: CleanResponseData
}
const Field = ({ label, value }: FieldPropTypes) => {
  return (
    <Box py={2}>
      <AttributeLabel label={label} mb={2} />
      <Text>{numberWithCommas(value) ?? 'N/A'}</Text>
    </Box>
  )
}

export { DetailDrawerListDeal }
