export default {
  Asymptomatic: 'RGB(255, 8,194)',
  Transmission: 'RGB(205, 176, 255)',
  'Covid related death': 'RGB(237, 226, 254)',
  'Overall efficacy/effectiveness': 'RGB(119, 209, 243)',
  'Mild COVID-19': 'RGB(156, 199, 255)',
  'Hospitalisations/severe disease': 'rgb(11, 118, 183)',
  Infection: 'rgb(102, 102, 102)',
  'General Population': 'RGB(207, 223, 255)',
}
