import { Box, Tooltip as ChakraTooltip } from '@chakra-ui/react'
import hexToRgba from 'hex-to-rgba'
import { PureComponent, useMemo } from 'react'
import Truncate from 'react-truncate'
import {
  BarChart,
  Bar,
  ResponsiveContainer,
  Tooltip,
  CartesianGrid,
  XAxis,
  YAxis,
} from 'recharts'
import theme from 'theme'

import { ChartPropTypes } from '.'
import CustomizedTooltip from './CustomizedTooltip'

const { colors } = theme
const colors500List = Object.values(colors)
  .map((x: any) => x[500])
  .filter((x) => !!x && !x.includes('rgba'))

const limit = 10

export default ({ chartData, squashData = true }: ChartPropTypes) => {
  const mappedChartData = useMemo(() => {
    const slicedData = chartData.data.slice(0, limit)

    if (squashData === false) {
      return {
        ...chartData,
        data: slicedData.map((data, i) => ({
          ...data,
          fill: colors500List[i % colors500List.length],
        })),
      }
    } else {
      // Squash entries if over the limit
      const totalOthers = chartData.data
        .slice(limit)
        .reduce((acc, val) => acc + val.value, 0)

      const squashedData =
        totalOthers > 0
          ? slicedData.concat({
              name: 'Other',
              value: totalOthers,
            })
          : slicedData

      return {
        ...chartData,
        data: squashedData.map((data, i) => ({
          ...data,
          fill: colors500List[i % colors500List.length],
        })),
      }
    }
  }, [chartData, squashData])

  return (
    <Box width='100%'>
      <Box height={350}>
        <ResponsiveContainer>
          <BarChart data={mappedChartData.data} height={350}>
            <CartesianGrid stroke='#e5e5e5' vertical={false} />
            <XAxis
              height={50}
              dataKey='name'
              tick={<CustomizedAxisTick />}
              tickLine={{
                stroke: '#e5e5e5',
              }}
              interval={0}
              axisLine={{ stroke: '#e5e5e5' }}
            />
            <YAxis
              domain={['dataMin', 'auto']}
              tick={{ fontSize: 10 }}
              tickLine={{
                stroke: '#e5e5e5',
              }}
              axisLine={{ stroke: '#fff' }}
            />

            <Bar dataKey='value' radius={[4, 4, 0, 0]} />
            <Tooltip
              content={<CustomizedTooltip />}
              cursor={{ fill: 'rgba(00,00,00,0.05)' }}
            />
          </BarChart>
        </ResponsiveContainer>
      </Box>
      <Box display='flex' flexWrap='wrap' alignItems='center'>
        {mappedChartData.data.map((entry, i) => (
          <Box
            key={i}
            display='flex'
            alignItems='center'
            fontSize='xs'
            lineHeight='none'
            fontWeight={500}
            mb={2}
            px={2}
            py={2}
            flex={1}
            whiteSpace='nowrap'
            bg={hexToRgba(entry.fill, '0.1')}
            mr={2}
          >
            <Box
              width='10px'
              height='10px'
              rounded='full'
              bg={entry.fill}
              mr={1}
            />
            <Truncate lines={1}>
              <Box whiteSpace='nowrap'>{entry.name}</Box>
            </Truncate>
          </Box>
        ))}
      </Box>
    </Box>
  )
}

class CustomizedAxisTick extends PureComponent {
  render() {
    const { x, y, payload } = this.props as any

    return (
      <ChakraTooltip label={payload.value}>
        <g transform={`translate(${x},${y})`}>
          <text
            x={0}
            y={0}
            dy={16}
            textAnchor='end'
            fill='#666'
            transform='rotate(-35)'
            fontSize={10}
            xlinkTitle={payload.value}
          >
            {payload.value.length > 10
              ? `${payload.value.slice(0, 10)}...`
              : payload.value}
          </text>
        </g>
      </ChakraTooltip>
    )
  }
}
