import TabbedDisplay, { TabData } from 'modules/TabbedDisplay'

import useIsMobile from 'utils/useIsMobile'

import WidgetComponentReport from './WidgetComponents/WidgetReport'

export type PropTypes = {
  tabs: TabData[]
  hideReports?: boolean
  analysisExtraKey?: string
}

export default function WidgetList({
  tabs,
  hideReports,
  analysisExtraKey,
}: PropTypes) {
  const [isMobile] = useIsMobile()

  return (
    <TabbedDisplay
      variant='line'
      tabListWrapper={null}
      tabs={[
        ...tabs,
        ...(!hideReports && isMobile
          ? [
              {
                heading: 'Reports',
                body: <WidgetComponentReport extraKey={analysisExtraKey} />,
              },
            ]
          : []),
      ]}
    />
  )
}
