import { Box, Text } from '@chakra-ui/react'
import { formatDistanceToNow } from 'date-fns'

import { MenuWithMetaData } from 'interfaces/navigationMenu.interface'

type NewPageMiniCardPropTypes = {
  menuWithMetaData: MenuWithMetaData
}

const NewPageMiniCard = ({
  menuWithMetaData: menu,
}: NewPageMiniCardPropTypes) => {
  return (
    <Box>
      <Text fontSize='13px' color='gray1' fontWeight='400' whiteSpace='nowrap'>
        {formatDistanceToNow(menu.createdAt, {
          addSuffix: true,
        })}
      </Text>
      <Text fontWeight='semibold' fontSize='14px' color='primary'>
        {menu.title}
      </Text>
      <Box mb='2px' />
      <Text fontWeight='400' fontSize='14px' color='gray1'>
        {menu.description}
      </Text>
      <Box mb='4px' />
      <Text fontWeight='400' fontSize='13px' color='data6'>
        {menu.displayPath.join(' » ')}
      </Text>
    </Box>
  )
}

export default NewPageMiniCard
