import { INavigationMenu } from 'interfaces/navigationMenu.interface'

export type CardioLipidSlugs =
  | ''
  | 'reports'
  | 'candidates'
  | 'progress-tracker'
  | 'science'
  | 'publications'
  | 'conference-presentations'
  | 'trials'
  | 'all'
  | 'global-revenue'
  | 'kols'
  | 'table'
  | 'results'
  | 'lipid-lowering-efficacy'
  | 'cardiovacular-outcomes'
  | 'news'
  | 'press-releases'
  | 'media'
  | 'social'
  | 'tweets'
  | 'approvals-forecast'
  | 'pipeline'
  | 'approved-indications'
  | 'timeline'
  | 'trials-reaching-completion'
  | 'efficacy-heat-map'
  | 'cardiovascular-events-reduction-comparator'
  | 'safety-overview'

const CardioLipidMenu: Array<INavigationMenu<CardioLipidSlugs>[]> = [
  [
    {
      title: 'Latest Information',
      slug: '',
      description:
        'Front page with latest dyslipidemia news, press releases, KOL activity on Twitter, and monthly reports',
      createdAt: new Date(2021, 3, 19),
    },
    {
      title: 'Reports',
      slug: 'reports',
      description:
        'Overview of our candidate coverage, top-level analytics, and epidemiological data',
      createdAt: new Date(2021, 3, 27),
    },
  ],
  [
    {
      title: 'Candidates',
      slug: 'candidates',
      description:
        'Comprehensive data for approved drugs as well as drugs in development.',
      createdAt: new Date(2021, 4, 7),
      children: [
        {
          title: 'Table',
          slug: 'table',
          createdAt: new Date(2024, 0, 1),
        },
        {
          title: 'Pipeline',
          slug: 'pipeline',
          createdAt: new Date(2022, 8, 8),
        },
        {
          title: 'Approved Indications',
          slug: 'approved-indications',
          createdAt: new Date(2022, 8, 8),
        },
        {
          title: 'Progress Tracker',
          slug: 'progress-tracker',
          createdAt: new Date(2022, 4, 31),
        },
      ],
    },
    {
      title: 'New Science',
      slug: 'science',
      description:
        'All data reported in scientific papers and at conference sessions.',
      createdAt: new Date(2021, 4, 7),
      children: [
        {
          title: 'Publications',
          slug: 'publications',
          createdAt: new Date(2021, 4, 7),
        },
        {
          title: 'Conference Presentations',
          slug: 'conference-presentations',
          createdAt: new Date(2021, 4, 13),
        },
      ],
    },
    {
      title: 'Trials',
      slug: 'trials',
      description: 'Ongoing and concluded clinical trials for dyslipidemia.',
      createdAt: new Date(2021, 4, 7),
      children: [
        {
          title: 'All Data',
          slug: 'all',
          createdAt: new Date(2021, 4, 7),
        },
        {
          title: 'Timeline',
          slug: 'timeline',
          createdAt: new Date(2022, 8, 8),
        },
        {
          title: 'Trials Reaching Completion',
          slug: 'trials-reaching-completion',
          createdAt: new Date(2022, 8, 8),
        },
        {
          title: 'Approvals Forecast',
          slug: 'approvals-forecast',
          createdAt: new Date(2021, 10, 10),
        },
      ],
    },
    {
      title: 'Global Revenue',
      slug: 'global-revenue',
      description: '',
      createdAt: new Date(2021, 3, 22),
    },
    {
      title: 'KOLS',
      slug: 'kols',
      description:
        'Key opinion leaders in dyslipidemia and their interactions on social media.',
      createdAt: new Date(2021, 7, 11),
    },
    {
      title: 'Results',
      slug: 'results',
      description:
        'Reported efficacy data for each candidate for both lipid lowering and cardiovascular outcomes.',
      createdAt: new Date(2021, 6, 7),
      children: [
        {
          title: 'Lipid-lowering efficacy',
          slug: 'lipid-lowering-efficacy',
          createdAt: new Date(2021, 6, 7),
        },
        {
          title: 'Efficacy Heat Map',
          slug: 'efficacy-heat-map',
          createdAt: new Date(2022, 8, 8),
        },
        {
          title: 'Cardiovascular Events Reduction Comparator',
          slug: 'cardiovascular-events-reduction-comparator',
          createdAt: new Date(2022, 8, 8),
        },
        {
          title: 'Cardiovacular outcomes',
          slug: 'cardiovacular-outcomes',
          createdAt: new Date(2021, 6, 7),
        },
        {
          title: 'Safety Overview',
          slug: 'safety-overview',
          createdAt: new Date(2021, 6, 7),
        },
      ],
    },
    {
      title: 'News',
      slug: 'news',
      description: 'Archive of dyslipidemia-related media and press releases.',
      createdAt: new Date(2021, 4, 7),
      children: [
        {
          title: 'Press Releases',
          slug: 'press-releases',
          createdAt: new Date(2021, 4, 7),
        },
        {
          title: 'Media',
          slug: 'media',
          createdAt: new Date(2021, 4, 7),
        },
      ],
    },
    {
      title: 'Social',
      slug: 'social',
      description: 'Archive of dyslipidemia-related KOL activity on Twitter.',
      createdAt: new Date(2021, 4, 7),
      children: [
        {
          title: 'Tweets',
          slug: 'tweets',
          createdAt: new Date(2021, 4, 7),
        },
      ],
    },
  ],
]

export { CardioLipidMenu }
