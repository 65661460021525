import { Flex, Heading, Text } from '@chakra-ui/react'

interface NothingFoundPropTypes {
  searchString: string
  briefs?: boolean
}

const NothingFound = ({ searchString, briefs }: NothingFoundPropTypes) => {
  const title = searchString
    ? '0 Results'
    : `No ${briefs ? 'research briefs' : 'reports'} have been uploaded yet`
  const subTitle = searchString
    ? ` We found no results for "${searchString}", let's try searching something else.`
    : 'Please try again later.'

  return (
    <Flex px={8} py={4} flexDir='column'>
      <Heading variant='h3'>{title}</Heading>
      <Text variant='body-bold' color='gray2'>
        {subTitle}
      </Text>
    </Flex>
  )
}

export default NothingFound
