import { Download } from '@carbon/icons-react'
import { Box, Button, Icon, Skeleton, Text } from '@chakra-ui/react'
import { PDFDocument } from 'pdf-lib'
import { useContext, useEffect, useState } from 'react'
import { Document, Page } from 'react-pdf'

import { useAppRoute } from 'routes/utils'

import { AuthContext } from 'contexts'

import { useDownloadPdf, usePdfData } from 'components/PdfDownload/usePdfData'

import { apps } from 'config/apps'

import UpSellSection from '../FullReportPage/components/UpSell'

const ReportPage = ({
  url,
  page,
  title,
  allPages,
  idCore,
}: {
  url: string
  page: number | number[]
  title?: string
  allPages?: boolean
  idCore?: boolean
}) => {
  const [nPages, setNPages] = useState<number[]>([])

  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  const selectedApp = useAppRoute()
  const { userIsBasicWithinApp } = useContext(AuthContext)

  const isBasic = userIsBasicWithinApp(apps[selectedApp])

  const multiPage = Array.isArray(page)
  const todayDate = new Date().toLocaleDateString()

  const { data: pdfData, refetch: loadPdf } = usePdfData(url, true)
  const { mutate: downloadPdf } = useDownloadPdf(
    url || '',
    `${title} - ${todayDate}` ||
      `${multiPage ? 'Slides' : 'Slide'} - ${todayDate}`
  )

  async function getPages() {
    if (!pdfData) return
    const pdfDoc = await PDFDocument.load(pdfData)
    if (!pdfDoc) return
    setNPages(pdfDoc?.getPages().map((_, index) => index + 1))
  }

  //TODO: add tracking
  // function  onDownload={() => {
  //   tracking['download' + type]({
  //     [_.lowerFirst(type)]: title,
  //   })
  // }}

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    loadPdf()
    getPages()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pdfData?.length])

  async function handleClick() {
    await downloadPdf()
  }

  if (isBasic && idCore) {
    return (
      <Box
        width='700px'
        margin={'auto'}
        height='80vh'
        display={'flex'}
        flexDir='column'
        justifyContent='center'
        alignItems={'center'}
        gap='1rem'
      >
        <Text fontSize='2xl'>Upgrade to a higher Tier to read the report.</Text>
        <UpSellSection
          details={`User wants to read Wastewater Report from ${window.location.href}`}
          title='Wastewater'
        />
      </Box>
    )
  }

  if (!pdfData) return null

  const largeScreen = windowWidth > 1600

  const pages = allPages ? nPages : page

  return (
    <Box pos={'relative'}>
      <Box
        display={'flex'}
        w='100%'
        justifyContent={'flex-end'}
        // pos='sticky'
        // top='0'
        zIndex={999}
      >
        <Button variant={'yellow'} marginLeft={'auto'} onClick={handleClick}>
          <Icon w='14px' as={Download} /> Download{' '}
          {multiPage ? 'Slides' : 'Slide'}
        </Button>
      </Box>
      <Document
        file={{ data: pdfData }}
        loading={
          <Box maxHeight={'700px'} maxW='fit-content' m='auto'>
            <Skeleton width='500px' height='200px' maxH={'300px'} m='auto' />
          </Box>
        }
        error={<Skeleton width='1200px' height='600px' m='auto' />}
      >
        {multiPage && Array.isArray(pages) ? (
          pages.map((x) => (
            <Box maxHeight={'700px'} maxW='fit-content' m={'auto'} mb='1rem'>
              <Page
                width={largeScreen ? 1200 : 1000}
                height={300}
                pageNumber={x}
                onError={() => console.error('error')}
                renderTextLayer={false}
                renderAnnotationLayer={false}
              />
            </Box>
          ))
        ) : (
          <Box maxHeight={'700px'} maxW='fit-content' m={'auto'} mb='1rem'>
            <Page
              width={largeScreen ? 1200 : 1000}
              height={700}
              pageNumber={page as number}
              onError={() => console.error('error')}
              renderTextLayer={false}
              renderAnnotationLayer={false}
            />
          </Box>
        )}
      </Document>
    </Box>
  )
}

export default ReportPage
