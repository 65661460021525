import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query'
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios'

import { Bookmark } from 'api/types'
import useAxios from 'api/useAxios'

import { bookmarkQueryKey } from './useBookmarkList'

const queryKey = bookmarkQueryKey

export default function useDeleteBookmarkMutation(
  extraProps?: UseMutationOptions<
    AxiosResponse<Bookmark>,
    AxiosError<any>,
    { id: number }
  >
) {
  const axios = useAxios()

  const queryClient = useQueryClient()

  return useMutation<AxiosResponse<Bookmark>, AxiosError<any>, { id: number }>(
    ({ id }: { id: number }) => deleteBookmark(axios, id),
    {
      onMutate: (newData) => {
        queryClient.cancelQueries(queryKey)

        const previousData: Bookmark[] | undefined =
          queryClient.getQueryData(queryKey)

        queryClient.setQueryData(
          queryKey,
          previousData?.filter((x) => x.id !== newData.id)
        )

        return () => queryClient.setQueryData(queryKey, previousData)
      },
      onError: (err, newData, rollback: any) => {
        rollback()
        queryClient.invalidateQueries(queryKey)
      },
      ...extraProps,
    }
  )
}

export const deleteBookmark = async (
  axios: AxiosInstance,
  id: number
): Promise<AxiosResponse<Bookmark>> => {
  return await axios.delete(`/bookmark/${id}/`)
}
