import { fetchCollection } from 'api/useCollectionData'

import { RWEInfosetMediaModel } from 'config/common/baseModel/rweinfoset/RWEInfosetMedia'
import { RWEInfosetPressReleasesModel } from 'config/common/baseModel/rweinfoset/RWEInfosetPressReleases'

import { compose, whiteListColumn } from 'utils/overrideModel'

export const AllNewsModel = compose<typeof RWEInfosetMediaModel>(
  whiteListColumn<typeof RWEInfosetMediaModel>([
    'datePublished',
    'finalSummary',
    'country',
    'link',
    'companies',
    'keywordsClean',
    'automatedTopicsCategory',
    'summary',
    'category',
    'subcategory',
    'sourceType',
  ])
)({
  ...RWEInfosetMediaModel,
  name: 'All News',
  customAPIFunction: (axios) => {
    const resolveData = Promise.all([
      fetchCollection(
        axios,
        'ieg-policy-surveillance',
        RWEInfosetMediaModel.endpoint,
        { airtableBase: 'rweinfoset', view: 'api_bayer_examples_final' }
      ),
      fetchCollection(
        axios,
        'ieg-policy-surveillance',
        RWEInfosetPressReleasesModel.endpoint,
        { airtableBase: 'rweinfoset', view: 'api_bayer_examples_final' }
      ),
    ]).then((d) => d[0].results.concat(d[1].results))

    return () => resolveData
  },
})
