import { Box, Button } from '@chakra-ui/react'
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalContent,
  ModalCloseButton,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react'

import { ProgressBar } from 'components'

import { ChartPropTypes } from '.'

export const ProgressBars = ({ chartData }: ChartPropTypes) => {
  const { isOpen, onClose, onOpen } = useDisclosure()

  return (
    <Box width='100%'>
      <Box>
        {chartData.data.slice(0, 5).map((data, i) => (
          <ProgressBar
            key={`progressbar_${i}`}
            label={data.name}
            total={chartData.total}
            value={data.value}
          />
        ))}
      </Box>
      <Button py={2} width='100%' onClick={onOpen}>
        See more
      </Button>
      <Modal
        isOpen={isOpen}
        size='2xl'
        onClose={onClose}
        scrollBehavior='inside'
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader>
            <Box>{chartData.columnName}</Box>
          </ModalHeader>
          <ModalBody>
            {chartData.data.map((data, i) => {
              return (
                <ProgressBar
                  key={`progressbar_more_${i}`}
                  label={data.name}
                  total={chartData.total}
                  value={data.value}
                />
              )
            })}
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  )
}
