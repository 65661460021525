import { Heading, Box } from '@chakra-ui/react'
import Truncate from 'react-truncate'

import { Tag } from 'components'

import { PresentationsData } from 'config/common/baseModel'

import { formatDate } from 'utils/formatDate'
import { relationGetDisplayValue } from 'utils/relational'

import { ItemComponentPropTypes } from '.'
import ArticleFooter from './components/ArticleFooter'

const DetailDrawerListPaper = ({
  item,
  handleSelect,
}: ItemComponentPropTypes<Record<PresentationsData, any>>) => {
  const {
    title,
    publicationDate,
    abstract,
    articleLink,
    topics,
    journals,
    concatOrganisations,
    doiUrl,
  } = item

  const publishedBy =
    journals && Array.isArray(journals) && journals.length > 0
      ? relationGetDisplayValue(journals[0])
      : concatOrganisations

  const externalLink = articleLink ?? doiUrl

  return (
    <Box
      px={8}
      py={4}
      borderBottom='1px solid'
      borderColor='gray.100'
      cursor='pointer'
      onClick={() => handleSelect(item)}
      _hover={{
        bg: 'gray.100',
      }}
    >
      <Box
        mb={1}
        display='flex'
        alignItems='center'
        fontSize='xs'
        fontWeight={600}
        textTransform='uppercase'
        color='gray.700'
      >
        <Box mr={2}>{formatDate(publicationDate)}</Box>
      </Box>
      {topics && Array.isArray(topics) && (
        <Box mb={2}>
          {topics.map((topic: any, i: number) => {
            return (
              <Tag
                key={i}
                fontSize='xs'
                color='blue'
                mr={1}
                px='8px'
                rounded='full'
              >
                {topic}
              </Tag>
            )
          })}
        </Box>
      )}
      <Heading fontSize='md' mb={2} fontWeight={500}>
        {title}
      </Heading>
      <Box mb={1} display='flex'>
        <Box mr={2} color='gray.700' fontSize='sm' fontWeight={600}>
          Published by {publishedBy}
        </Box>
      </Box>
      <Box mb={4} color='gray.800' fontSize='sm'>
        <Truncate lines={3} ellipsis={<span>...</span>} trimWhitespace={true}>
          {abstract}
        </Truncate>
      </Box>
      <ArticleFooter externalLink={externalLink as string} />
    </Box>
  )
}

export { DetailDrawerListPaper }
