import { IModel } from 'interfaces/model.interface'
import { ValueType } from 'interfaces/valueType.interface'

import { SortOrders } from 'enums/SortOrders'

import Tag from '../../tagColours/Tag'
import Topic from '../../tagColours/Topic'

export type IDTreatments360PressReleasesData =
  | 'title'
  | 'microlink'
  | 'datePublished'
  | 'afTopic'
  | 'link'
  | 'candidateNumber'
  | 'company'
  | 'afTags'
  | 'afProject'
  | 'published'
  | 'sourceUrl'
  | 'vaccine'
  | 'linkInfectiousDisease'
  | 'afProjectSubtype'
  | 'typeOfPathogenFromLinkInfectiousDisease'
  | 'niaidPathogenFromLinkInfectiousDisease'

const allowedAirtables = [
  'api_app_press_releases_all',
  'api_app_pad_coronaviridae',
  'api_app_pad_paramyxoviridae',
  'api_app_pad_orthomyxoviridae',
] as const

export const IDTreatments360PressReleasesModel: IModel<
  Record<IDTreatments360PressReleasesData, string>,
  typeof allowedAirtables
> = {
  allowedAirtables,
  key: 'title',
  displayKey: 'title',
  searchField: 'title',
  detailViewType: 'Article',
  detailView: {
    link: 'sourceUrl',
    abstract: 'description',
  },
  exportDisabled: true,
  name: 'Press Releases',
  entityName: 'Press Releases',
  endpoint: 'press-release',
  defaultSortKey: 'datePublished',
  defaultSortOrder: SortOrders.DESC,
  noPrint: true,
  schema: {
    columns: [
      {
        key: 'title',
        label: 'Title',
        width: 600,
        showDetail: false,
        cellFormat: {
          maxLines: 3,
        },
      },
      {
        key: 'microlink',
        label: 'Microlink',
        type: ValueType.TEXT,
        width: 200,
      },
      {
        key: 'datePublished',
        type: ValueType.DATE,
        label: 'Publication date',
        width: 160,
      },
      {
        key: 'afTopic',
        type: ValueType.SINGLE,
        label: 'Topic',
        width: 200,
        graphType: 'Bar',
        cellFormat: {
          colours: Topic,
        },
      },
      { key: 'link', label: 'Link', width: 120, type: ValueType.URL },
      {
        key: 'candidateNumber',
        label: 'Candidate Number',
        type: ValueType.MULTI,
        width: 200,
      },
      {
        key: 'company',
        label: 'Company',
        type: ValueType.MULTI,
        width: 200,
      },
      {
        key: 'afTags',
        label: 'Tags',
        type: ValueType.MULTI,
        width: 260,
        graphType: 'Pie',
        cellFormat: {
          colours: Tag,
        },
      },
      { key: 'afProject', label: 'Project', width: 200, type: ValueType.MULTI },
      {
        key: 'published',
        label: 'Published',
        width: 150,
        type: ValueType.TEXT,
      },
      {
        key: 'sourceUrl',
        type: ValueType.URL,
        label: 'Link',
        width: 120,
      },
      {
        key: 'vaccine',
        type: ValueType.MULTI,
        label: 'Treatment',
        width: 200,
        graphType: 'Pie',
      },
      {
        key: 'linkInfectiousDisease',
        label: 'Infectious Disease',
        width: 200,
        type: ValueType.MULTI,
      },
      {
        key: 'afProjectSubtype',
        label: 'Project Subtype',
        type: ValueType.MULTI,
        width: 200,
      },
      {
        key: 'typeOfPathogenFromLinkInfectiousDisease',
        label: 'Type Of Pathogen',
        type: ValueType.MULTI,
        width: 150,
      },
      {
        key: 'niaidPathogenFromLinkInfectiousDisease',
        label: 'Viral Family',
        type: ValueType.MULTI,
        width: 150,
      },
    ],
  },
}
