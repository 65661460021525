import { UseQueryOptions, useQuery } from '@tanstack/react-query'
import { AxiosError, AxiosInstance } from 'axios'

import { apps, appsListUnion } from 'config/apps'

import { recursiveCamelCase } from './helper'
import { EntitySearchType } from './types'
import useAxios from './useAxios'

export default function useSearchEntities(
  app: appsListUnion,
  searchString: string,
  extraProps?: UseQueryOptions<any, any>
) {
  const axios = useAxios()

  return useQuery<EntitySearchType[], AxiosError>(
    ['globalSearch', app, searchString],
    () => searchEntities(axios, app, searchString),
    { enabled: searchString !== '', ...extraProps }
  )
}

export const searchEntities = async (
  axios: AxiosInstance,
  app: appsListUnion,
  searchString: string
): Promise<EntitySearchType[]> => {
  return axios
    .get(`/${apps[app].endpoint}/search/?search_term=${searchString}`)
    .then((res) => recursiveCamelCase(res.data))
}
