import { IModel } from 'interfaces/model.interface'
import { ValueType } from 'interfaces/valueType.interface'

import DealType from 'config/common/tagColours/DealType'
import EconomyIncomeBand from 'config/common/tagColours/EconomyIncomeBand'
import ProductionDealType from 'config/common/tagColours/ProductionDealType'

export type RespiratoryDealsData =
  | 'afDealId'
  | 'linkInfectiousDisease'
  | 'candidateNameFromLinkVaccinesCandidateList'
  | 'candidateNameFromLinkTreatmentsCandidateList'
  | 'afDealType'
  | 'linkOrganisations'
  | 'regionFromLinkCountries'
  | 'linkCountries'
  | 'afSupplyNumber'
  | 'dateSupplyExpd'
  | 'supplyFromProductionDeal'
  | 'linkFromPressReleases'
  | 'lookupCountrySourceOfSupply'
  | 'linkFromMediaReleases'
  | 'afPricePerVaccineTreatment'
  | 'countryProduction'
  | 'afExpectedProduction'
  | 'afProductionDealType'
  | 'dateDealMade'
  | 'afSupplyProductionNotes'
  | 'published'
  | 'afEconomyIncomeBand'
  | 'countryFromLinkCountriesSupplied'
  | 'supplyNumber'
  | 'dateSigned'
  | 'yearSigned'
  | 'unlinkedCandidateName'
  | 'luCandidateName'
  | 'linkDisease'
  | 'secondarySource'
  | 'sourceUrl'
  | 'nameFromLinkOrganisations'

const allowedAirtables = [
  'front_end_export_supply',
  'front_end_export_price',
  'front_end_export_company_stated_production',
  'API-SUPPLY-DEALS-INFLUENZA',
] as const

export const RespiratoryDealsModel: IModel<
  Record<RespiratoryDealsData, string>,
  typeof allowedAirtables
> = {
  allowedAirtables,
  displayKey: 'afDealId',
  name: 'Deals',
  entityName: 'Deals',
  searchField: 'candidateNameFromLinkVaccinesCandidateList',
  endpoint: 'deal',
  excludeGeneratedCharts: true,
  schema: {
    columns: [
      {
        key: 'afDealId',
        label: 'Deal ID',
        width: 200,
        type: ValueType.TEXT,
      },
      {
        key: 'linkInfectiousDisease',
        label: 'Infectious Disease',
        width: 150,
        type: ValueType.MULTI,
      },
      {
        key: 'candidateNameFromLinkVaccinesCandidateList',
        label: 'Vaccines Candidate List',
        width: 240,
        type: ValueType.MULTI,
      },
      {
        key: 'candidateNameFromLinkTreatmentsCandidateList',
        label: 'Treatments Candidate List',
        width: 240,
        type: ValueType.MULTI,
      },
      {
        key: 'afDealType',
        label: 'Deal Type',
        width: 150,
        type: ValueType.SINGLE,
        cellFormat: {
          colours: DealType,
        },
      },
      {
        key: 'linkOrganisations',
        label: 'Organisations',
        width: 250,
        type: ValueType.MULTI,
      },
      {
        key: 'nameFromLinkOrganisations',
        label: 'Vaccine Producer',
        width: 250,
        type: ValueType.MULTI,
      },
      {
        key: 'regionFromLinkCountries',
        label: 'Regions',
        width: 160,
        type: ValueType.MULTI,
      },
      {
        key: 'linkCountries',
        label: 'Countries',
        width: 160,
        type: ValueType.MULTI,
      },
      {
        key: 'afSupplyNumber',
        label: 'Supply Number',
        width: 140,
        type: ValueType.NUMBER,
      },
      {
        key: 'dateSupplyExpd',
        label: 'Date Supply Expd',
        width: 160,
        type: ValueType.DATE,
      },
      {
        key: 'supplyFromProductionDeal',
        label: 'Supply From Production Deal',
        width: 200,
        type: ValueType.MULTI,
      },
      {
        key: 'linkFromPressReleases',
        label: 'Press Releases',
        width: 120,
        type: ValueType.URL,
      },
      {
        key: 'lookupCountrySourceOfSupply',
        label: 'Source Of Supply',
        width: 200,
        type: ValueType.MULTI,
      },
      {
        key: 'linkFromMediaReleases',
        label: 'Media',
        width: 120,
        type: ValueType.URL,
      },
      {
        key: 'afPricePerVaccineTreatment',
        label: 'Price Per Vaccine Treatment',
        width: 200,
        type: ValueType.TEXT,
      },
      {
        key: 'countryProduction',
        label: 'Country Production',
        width: 200,
        type: ValueType.MULTI,
      },
      {
        key: 'afExpectedProduction',
        label: 'Expected Production',
        width: 200,
        type: ValueType.NUMBER,
      },
      {
        key: 'afProductionDealType',
        label: 'Production Deal Type',
        width: 180,
        type: ValueType.SINGLE,
        cellFormat: {
          colours: ProductionDealType,
        },
      },
      {
        key: 'dateDealMade',
        label: 'Date Deal Made',
        width: 120,
        type: ValueType.DATE,
      },
      {
        key: 'afSupplyProductionNotes',
        label: 'Supply Production Notes',
        width: 200,
        type: ValueType.TEXT,
      },
      {
        key: 'published',
        label: 'Published',
        width: 200,
        type: ValueType.TEXT,
      },
      {
        key: 'afEconomyIncomeBand',
        label: 'Economy Income Band',
        width: 180,
        type: ValueType.MULTI,
        cellFormat: {
          colours: EconomyIncomeBand,
        },
      },
      {
        key: 'countryFromLinkCountriesSupplied',
        label: 'Countries Supplied',
        width: 180,
        type: ValueType.MULTI,
      },
      {
        key: 'supplyNumber',
        label: 'Doses Supplied',
        width: 180,
        type: ValueType.NUMBER,
      },
      {
        key: 'dateSigned',
        label: 'Date Signed',
        width: 180,
        type: ValueType.DATE,
      },
      {
        key: 'yearSigned',
        label: 'Year Signed',
        width: 180,
        type: ValueType.SINGLE,
      },
      {
        key: 'unlinkedCandidateName',
        label: 'Vaccine Name from Source',
        width: 181,
        type: ValueType.TEXT,
      },
      {
        key: 'luCandidateName',
        label: 'Vaccine Name',
        width: 180,
        type: ValueType.MULTI,
      },
      {
        key: 'linkDisease',
        label: 'Diseases',
        width: 180,
        type: ValueType.MULTI,
      },
      {
        key: 'secondarySource',
        label: 'Source Name',
        width: 181,
        type: ValueType.MULTI,
      },
      {
        key: 'sourceUrl',
        label: 'Source URL',
        width: 180,
        type: ValueType.URL,
      },
    ],
  },
}
