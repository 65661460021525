import { IApp } from 'interfaces/navigationApp.interface'

import { AccessGroups } from 'enums/AccessGroups'

import { VaccinesCustomPages } from './VaccinesCustomPages'
import { VaccinesMenu, VaccinesSlugs } from './VaccinesMenu'
import { VaccinesPages } from './VaccinesPages'

const demo: IApp<VaccinesSlugs> = {
  name: 'Vaccines',
  slug: 'covid-19-vaccines',
  commonIdentifier: 'vaccines',
  accessGroups: [AccessGroups.Vaccines],
  endpoint: 'demo-vaccines',
  menu: VaccinesMenu,
  pages: VaccinesPages,
  customPages: VaccinesCustomPages,
  disableAnalytics: true,
  tutorial: [
    {
      url: 'https://airfinity-production.s3.amazonaws.com/public/walkthroughs/covid19-science360.mp4',
      name: 'COVID-19 -  Vaccines',
    },
  ],
  createdAt: new Date(2020, 7, 21),
}

export default demo
