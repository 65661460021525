import { IApp } from 'interfaces/navigationApp.interface'

import { AccessGroups } from 'enums/AccessGroups'

import { ObesityCustomPages } from './ObesityCustomPages'
import { ObesityMenu, ObesitySlugs } from './ObesityMenu'
import { ObesityPages } from './ObesityPages'

const Obesity: IApp<ObesitySlugs> = {
  name: 'Science360',
  slug: 'obesity-360',
  endpoint: 'obesity-360',
  accessGroups: [AccessGroups.Obesity360],
  menu: ObesityMenu,
  pages: ObesityPages,
  customPages: ObesityCustomPages,
  appIdentifier: 'obesity',
  createdAt: new Date(2022, 6, 8),
  disableAnalytics: true,
}

export default Obesity
