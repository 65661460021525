import { DragVertical, OpenPanelLeft, View, ViewOff } from '@carbon/icons-react'
import { Box, Button } from '@chakra-ui/react'
import arrayMove from 'array-move'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { DropTargetMonitor, useDrag, useDrop, XYCoord } from 'react-dnd'
import { ColumnInstance, IdType, TableInstance } from 'react-table'

import { Dropdown } from 'components'

import useTracking from 'tracking/useTracking'

type PropTypes = {
  rtAllColumns: ColumnInstance<any>[]
  toggleHideColumn: TableInstance<any>['toggleHideColumn']
  setColumnOrder: (
    updater: ((columnOrder: IdType<any>[]) => IdType<any>[]) | IdType<any>[]
  ) => void
}

const ColumnButton = React.memo(
  ({ rtAllColumns, toggleHideColumn, setColumnOrder }: PropTypes) => {
    const [columnsIsDragging, setColumnsIsDragging] = useState<boolean[]>(
      Array(rtAllColumns.length).fill(false)
    )
    const [dropTargetIndex, setDropTargetIndex] = useState(0)

    const dragIndex = columnsIsDragging.indexOf(true)

    const isDragging = columnsIsDragging.reduce((acc, val) => acc || val, false)

    const columnIds = useMemo(
      () => rtAllColumns.map((x) => x.id),
      [rtAllColumns]
    )

    const onDropTargetHover = useCallback(
      (target: number) => setDropTargetIndex(target),
      [setDropTargetIndex]
    )

    const onDrop = useCallback(
      (origin: number, target: number) => {
        // origin & target are indexes based on the filtered column list.
        // When reordering this, we need to convert it to unfiltered index first
        const originId = columnIds[origin]
        const targetId = columnIds[target]

        setColumnOrder(
          arrayMove(
            columnIds,
            columnIds.indexOf(originId),
            columnIds.indexOf(targetId)
          )
        )
      },
      [setColumnOrder, columnIds]
    )

    const [tracking] = useTracking()

    const onToggle = useCallback(
      (index: number, val?: boolean) => {
        tracking.tableColumnHide({ column: columnIds[index], isVisible: val })
        toggleHideColumn(columnIds[index])
      },
      [toggleHideColumn, columnIds, tracking]
    )

    return (
      <Box>
        <Dropdown
          positions={['bottom', 'right']}
          align='end'
          content={
            <Box
              shadow='lg'
              bg='white'
              border='1px solid'
              borderColor='gray.200'
              rounded='8px'
              width='246px'
            >
              <Box maxHeight='300px' overflowY='auto' position='relative'>
                {/* Dragging indicator */}
                {isDragging && dragIndex !== dropTargetIndex && (
                  <Box
                    height='2px'
                    rounded='full'
                    width='100%'
                    position='absolute'
                    backgroundColor='secondary'
                    top={
                      (dropTargetIndex > dragIndex
                        ? (dropTargetIndex + 1) * 44
                        : dropTargetIndex * 44) + 'px'
                    }
                  />
                )}
                {rtAllColumns.length > 0 ? (
                  rtAllColumns.map((column, i) => {
                    return (
                      <ColumnEntry
                        key={column.id}
                        setColumnsIsDragging={setColumnsIsDragging}
                        onDropTargetHover={onDropTargetHover}
                        onDrop={onDrop}
                        isVisible={column.isVisible}
                        onToggle={onToggle}
                        index={i}
                        columnName={column.Header?.toString() ?? ''}
                      />
                    )
                  })
                ) : (
                  <Box
                    textAlign='center'
                    color='gray.500'
                    fontSize='xs'
                    py={2}
                    fontWeight={500}
                  >
                    No columns found.
                  </Box>
                )}
              </Box>
            </Box>
          }
        >
          <Button variant='clean' fontSize='12px'>
            <Box mr={1}>
              <OpenPanelLeft size={16} />
            </Box>
            Columns
          </Button>
        </Dropdown>
      </Box>
    )
  }
)

export default ColumnButton

// Calculate where the hover should be counted within a drop target
// Taken & modified from the library's example
const calculateHoverIndex = (
  ref: React.RefObject<HTMLDivElement>,
  dragIndex: number,
  hoverIndex: number,
  monitor: DropTargetMonitor
) => {
  const hoverBoundingRect: DOMRect =
    ref.current?.getBoundingClientRect() ?? new DOMRect(0, 0, 0, 0)
  const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
  const clientOffset = monitor.getClientOffset()
  const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top
  if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
    return hoverIndex - 1
  }
  return hoverIndex
}

interface DragItem {
  index: number
  type: string
}

type ColumnEntryPropTypes = {
  onToggle: (index: number, value?: boolean) => void
  isVisible: boolean
  columnName: string
  onDropTargetHover: (target: number) => void
  onDrop: (origin: number, target: number) => void
  setColumnsIsDragging: React.Dispatch<React.SetStateAction<boolean[]>>
  index: number
}

const ColumnEntry = React.memo(
  ({
    isVisible,
    onToggle,
    columnName,
    onDropTargetHover,
    onDrop,
    setColumnsIsDragging,
    index,
  }: ColumnEntryPropTypes) => {
    const ref = useRef<HTMLDivElement>(null)

    const [{ isDragging }, drag] = useDrag({
      type: 'column',
      item: { index },
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    })

    useEffect(() => {
      setColumnsIsDragging((prev) =>
        prev.map((x, i) => (i === index ? isDragging : x))
      )
    }, [isDragging, setColumnsIsDragging, index])

    const [, drop] = useDrop({
      accept: 'column',
      canDrop: (item: DragItem) => {
        return item.index !== index
      },
      drop: (item: DragItem, monitor) => {
        onDrop(item.index, calculateHoverIndex(ref, item.index, index, monitor))
      },
      hover: (item: DragItem, monitor) => {
        onDropTargetHover(calculateHoverIndex(ref, item.index, index, monitor))
      },
    })

    drag(drop(ref))

    return (
      <Box
        ref={ref}
        height='44px'
        py={2}
        _hover={{ bg: 'gray.50' }}
        lineHeight='none'
        px='16px'
        cursor='grab'
        display='flex'
        alignItems='center'
        textTransform='capitalize'
        opacity={isDragging ? 0.3 : 1}
        borderBottom='1px solid'
        borderColor='gray3'
        color={isVisible ? 'black' : 'gray2'}
      >
        <Box mr={2}>
          <DragVertical size={16} />
        </Box>
        <Box fontSize='13px'>{columnName}</Box>
        <Box ml='auto' display='flex'>
          <Box
            cursor='pointer'
            onClick={(e) => {
              e.stopPropagation()
              onToggle(index)
            }}
          >
            {isVisible ? <View size={16} /> : <ViewOff size={16} />}
          </Box>
        </Box>
      </Box>
    )
  }
)
