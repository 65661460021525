import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query'
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios'

import { appsListUnion } from 'config/apps'

import { UserView } from './types'
import useAxios from './useAxios'
import { getQueryKey } from './useUserViewData'

// This is for fallback if for some reason we can't find the entry in the previous data
const fallbackUserViewObject = {
  id: -1,
  name: '',
  appId: '',
  pageId: '',
  view: {},
}

export default function useCreateUserViewMutation(
  app: appsListUnion,
  pageKey: string,
  extraProps?: UseMutationOptions<
    AxiosResponse<UserView>,
    AxiosError<any>,
    {
      id: number
      userView: Partial<Omit<UserView, 'id'>>
    }
  >
) {
  const axios = useAxios()
  const queryKey = getQueryKey(app, pageKey)

  const queryClient = useQueryClient()

  return useMutation<
    AxiosResponse<UserView>,
    AxiosError<any>,
    {
      id: number
      userView: Partial<Omit<UserView, 'id'>>
    }
  >(({ id, userView }) => updateUserView(axios, id, userView), {
    onMutate: (newData) => {
      queryClient.cancelQueries(queryKey)

      const previousData: UserView[] | undefined =
        queryClient.getQueryData(queryKey)

      queryClient.setQueryData(
        queryKey,
        previousData
          ?.filter((x) => x.id !== newData.id)
          .concat({
            ...fallbackUserViewObject,
            ...previousData?.find((x) => x.id === newData.id),
            ...newData.userView,
          })
          .sort((a, b) => a.id - b.id)
      )

      return () => queryClient.setQueryData(queryKey, previousData)
    },
    onError: (err, newData, rollback: any) => {
      rollback()
      queryClient.invalidateQueries(queryKey)
    },
    onSuccess: (data) => {
      const currentData: UserView[] | undefined =
        queryClient.getQueryData(queryKey)

      queryClient.setQueryData(
        queryKey,
        currentData
          ?.filter((x) => x.id !== data.data.id)
          .concat(data.data)
          .sort((a, b) => a.id - b.id)
      )
    },
    ...extraProps,
  })
}

export const updateUserView = async (
  axios: AxiosInstance,
  id: number,
  stateData: Partial<Omit<UserView, 'id'>>
): Promise<AxiosResponse<UserView>> => {
  return await axios.patch(`/user-views/${id}/`, stateData)
}
