import GenericTable from 'modules/Tables'

import { IModel } from 'interfaces/model.interface'
import {
  INavigationPages,
  ISingleNavigationPage,
} from 'interfaces/navigationPage.interface'

import {
  ObesityApprovalsModel,
  ObesityMediaModel,
  ObesityPressReleasesModel,
} from '../Obesity360/ObesityModels'
import { ObesitySlugs } from './ObesityCommercialMenu'
import { ObesityApprovalModel } from './ObesityCommercialModels'

type ObesityCommercialPage<TModel extends IModel<any>> = ISingleNavigationPage<
  ObesitySlugs,
  TModel
>

const Media: ObesityCommercialPage<typeof ObesityMediaModel> = {
  key: 'Media',
  path: ['media'],
  model: {
    ...ObesityMediaModel,
  },
  component: GenericTable,
  views: [
    {
      name: 'All',
      airtableName: 'Obesity Media - Platform View - Commercial',
      airtableBase: 'cardio360',
    },
  ],
}

const PressRelease: ObesityCommercialPage<typeof ObesityPressReleasesModel> = {
  key: 'PressRelease',
  path: ['press-release'],
  model: {
    ...ObesityPressReleasesModel,
  },
  component: GenericTable,
  views: [
    {
      name: 'All',
      airtableName: 'Obesity Press Releases - Platform View - Commercial',
      airtableBase: 'cardio360',
    },
  ],
}

const RegulatoryNews: ObesityCommercialPage<typeof ObesityApprovalsModel> = {
  key: 'Approvals',
  path: ['regulatory', 'news'],
  component: GenericTable,
  model: { ...ObesityApprovalsModel, name: 'News' },
  views: [
    {
      name: 'Default',
      airtableName: 'Obesity Press Releases - Platform View - Regulatory',
      airtableBase: 'cardio360',
    },
  ],
}

const RegulatoryApprovals: ObesityCommercialPage<typeof ObesityApprovalModel> =
  {
    key: 'RegulatoryApprovals',
    path: ['regulatory', 'approvals-table'],
    model: {
      ...ObesityApprovalModel,
      name: 'Regulatory Approvals',
      detailViewType: 'Generic',
    },
    component: GenericTable,
    views: [
      {
        name: 'All',
        airtableName: 'Obesity_Approvals_Frontend',
        airtableBase: 'cardio360',
      },
    ],
  }

export const ObesityCommercialPages: INavigationPages<ObesitySlugs> = {
  Media,
  RegulatoryApprobals: RegulatoryApprovals,
  PressRelease,
  RegulatoryNews,
}
