export default {
  '79.00%': 'rgb(207, 223, 255)',
  '54.60%': 'rgb(208, 240, 253)',
  '51.90%': 'rgb(194, 245, 233)',
  '80.00%': 'rgb(209, 247, 196)',
  '100.00%': 'rgb(255, 234, 182)',
  '44.40%': 'rgb(254, 226, 213)',
  'Safety Trial': 'rgb(139, 70, 255)',
  'Trial ongoing, not yet reported': 'rgb(255, 169, 129)',
  'Safety and Antibody Titres': 'rgb(6, 160, 155)',
  '84.7%': 'rgb(51, 138, 23)',
  '79.3%': 'rgb(186, 30, 69)',
}
