import { appsListUnion } from 'config/apps'

export * from './cms/highlights/types'
export * from './cms/reports/types'

export type RelationObject = {
  id: string
  displayText: string
  model: string
  base: string
  endpoint: string
}

export type LinkObject = {
  link: string
  title: string
}

export type FileObject = {
  id: string
  filename: string
  size: number
  type: string
  url: string
  thumbnails: {
    large: {
      height: number
      url: string
      width: number
    }
    small: {
      height: number
      url: string
      width: number
    }
  }
}

// Data raw from the API
export type ResponseData =
  | null
  | number
  | string
  | string[]
  | RelationObject
  | RelationObject[]
  | FileObject
  | FileObject[]
  | LinkObject
  | LinkObject[]

// Data after we clean it up (Handle relation)
export type CleanResponseData = null | number | string | string[]

/**
 * Response data type
 */
export type IBaseResponse<T extends string = string> = Record<T, any> & {
  id: string | number
  [k: string]: any
}
export type IResponseBase<T extends string = string> = Record<
  T,
  ResponseData
> & {
  id: string | number
  [k: string]: ResponseData
}
export type ICleanResponse<T extends string = string> = Record<
  T,
  CleanResponseData
> & {
  id: string | number
  [k: string]: CleanResponseData
}

// ===========================================================================

export interface Paginated<T> {
  count: number
  results: T
  // Used for incremental loading to know if everything is fetched
  globalLoaded?: number
  globalTotal?: number
}

export type EntitySearchType = {
  id: string
  displayText: string
  view?: string
  base: string
  endpoint: string
}

export type ReportSearchType = {
  pages: number[]
  fileUrl: string
  fileName: string
  content: string
  date: Date
  contentfulReportUrl: string
}

export type TableauSearchType = {
  name: string
  url: string
  tags: string[]
}

export type UserView = {
  id: number
  name: string
  pageId: string
  appId: string
  view: Record<string, any>
}

export type HistoricalData = {
  date: Date
  value: any
}

export type TermsAndConditions = {
  name: string
  content: string
  lastUpdated: string
  message: string
}

export type WebMeta = {
  url: string
  publisher: string
  title: string
  description: string
  date?: string
  logo: {
    url: string
  }
  lang: string
  image: {
    url: string
  }
  author: string
}

export const defaultWebMeta = {
  url: '',
  publisher: '',
  title: '',
  description: '',
  logo: {
    url: '',
  },
  lang: '',
  image: {
    url: '',
  },
  author: '',
}

export type BookmarkTypeLists = 'report' | 'page'

export type BookmarkBase = {
  id: number
  type: BookmarkTypeLists
  app: appsListUnion
  createdAt: Date
}

export type Bookmark = BookmarkReport | BookmarkPage

export type BookmarkReport = BookmarkBase & {
  type: Extract<BookmarkTypeLists, 'report'>
  metadata: {
    id: string
    extraKey: string
  }
}

export type BookmarkPage = BookmarkBase & {
  type: Extract<BookmarkTypeLists, 'page'>
  metadata: {
    paths: string[]
  }
}
