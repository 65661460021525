import { IModel } from 'interfaces/model.interface'
import { ValueType } from 'interfaces/valueType.interface'

import Phase from '../tagColours/Phase'

export type DeliverSystemsData =
  | 'deliverySystem'
  | 'category'
  | 'candidateName'
  | 'technologyType'
  | 'indication'
  | 'highestClinicalTrialPhase'
  | 'companies'
  | 'researchInstitutions'
  | 'nameFromPlatform'
  | 'media'
  | 'pressReleases'
  | 'publications'
  | 'ownership'
  | 'publicationsLink'
  | 'tissueCellTarget'
  | 'mediaLinkObj'
  | 'pressReleasesLinkObj'
  | 'publicationsLinkObj'
  | 'disease'
  | 'organisation'

const allowedAirtables = [
  'RNA_API_DeliverySystems V2 (Candidate Linked)',
  'RNA_API_DeliverySystems V2 (Investigational)',
] as const

export const DeliverySystemsModel: IModel<
  Record<DeliverSystemsData, string>,
  typeof allowedAirtables
> = {
  allowedAirtables,
  endpoint: 'delivery-system',
  name: 'Delivery Systems',
  entityName: 'Delivery System',
  searchField: 'deliverySystem',
  displayKey: 'deliverySystem',
  detailViewType: 'Generic',
  schema: {
    columns: [
      {
        key: 'deliverySystem',
        type: ValueType.TEXT,
        label: 'Delivery System',
        width: 150,
      },
      {
        key: 'category',
        label: 'Category',
        type: ValueType.MULTI,
        width: 200,
      },
      {
        key: 'candidateName',
        label: 'Candidate',
        type: ValueType.MULTI,
        width: 150,
      },
      {
        key: 'technologyType',
        label: 'Technology Type',
        type: ValueType.MULTI,
        width: 150,
      },
      {
        key: 'indication',
        label: 'Indication',
        type: ValueType.MULTI,
        width: 280,
      },
      {
        key: 'highestClinicalTrialPhase',
        label: 'Highest Clinical Trial Phase',
        width: 180,
        type: ValueType.MULTI,
        cellFormat: {
          colours: Phase,
        },
      },
      {
        key: 'companies',
        label: 'Companies',
        width: 180,
        type: ValueType.MULTI,
      },
      {
        key: 'researchInstitutions',
        label: 'Research Institutions',
        width: 180,
        type: ValueType.MULTI,
      },
      {
        key: 'nameFromPlatform',
        label: 'Name',
        width: 180,
        type: ValueType.MULTI,
      },
      {
        key: 'media',
        label: 'Media',
        width: 180,
        type: ValueType.MULTI,
      },
      {
        key: 'pressReleases',
        label: 'Press Releases',
        width: 180,
        type: ValueType.MULTI,
      },
      {
        key: 'publications',
        label: 'Publications',
        width: 300,
        type: ValueType.MULTI,
      },
      {
        key: 'ownership',
        label: 'Ownership',
        width: 180,
        type: ValueType.MULTI,
      },
      {
        key: 'publicationsLink',
        label: 'URL',
        width: 180,
        type: ValueType.URL,
      },
      {
        key: 'tissueCellTarget',
        label: 'Tissue/Cell Target',
        width: 180,
        type: ValueType.MULTI,
      },

      {
        key: 'mediaLinkObj',
        label: 'Media',
        width: 180,
        type: ValueType.URL,
      },
      {
        key: 'pressReleasesLinkObj',
        label: 'Press Releases',
        width: 180,
        type: ValueType.URL,
      },
      {
        key: 'publicationsLinkObj',
        label: 'Publications',
        width: 300,
        type: ValueType.URL,
      },
      {
        key: 'disease',
        label: 'Diseases',
        width: 180,
        type: ValueType.MULTI,
      },
      {
        key: 'organisation',
        label: 'Organisations',
        width: 180,
        type: ValueType.MULTI,
      },
    ],
  },
}
