import { IModel } from 'interfaces/model.interface'
import { ValueType } from 'interfaces/valueType.interface'

import { SortOrders } from 'enums/SortOrders'

import Sentiments from '../../tagColours/Sentiments'

export type CardioSovConferencePresentationsData =
  | 'sessionName'
  | 'titleClean'
  | 'afTopics'
  | 'drugClass'
  | 'activeSubstance'
  | 'afSentiment'
  | 'lookupMappedSessionType'
  | 'presentingAuthor'
  | 'likes'
  | 'views'
  | 'comments'
  | 'afIndependent'
  | 'lookupHIndex'
  | 'sponsors'

const allowedAirtables = [
  'api_esc_presentations_for_boehringer_ingelheim',
  'api_easd_presentations_for_boehringer_ingelheim',
] as const

export const CardioSovConferencePresentationsModel: IModel<
  Record<CardioSovConferencePresentationsData, string>,
  typeof allowedAirtables
> = {
  allowedAirtables,
  endpoint: 'conference-presentation',
  entityName: 'Conference Presentations',
  name: 'Conference Presentations',
  searchField: 'titleClean',
  detailViewType: 'Generic',
  defaultSortObject: [
    {
      id: 'lookupMappedSessionType',
      sortOrder: SortOrders.ASC,
    },
    {
      id: 'sessionName',
      sortOrder: SortOrders.ASC,
    },
    {
      id: 'titleClean',
      sortOrder: SortOrders.ASC,
    },
  ],
  displayKey: 'titleClean',
  schema: {
    columns: [
      {
        key: 'sessionName',
        label: 'Session Name',
        width: 320,
        type: ValueType.SINGLE,
      },
      {
        key: 'titleClean',
        label: 'Title',
        width: 500,
        showDetail: false,
        type: ValueType.TEXT,
        cellFormat: {
          maxLines: 3,
        },
      },
      {
        key: 'afTopics',
        label: 'Topics',
        width: 300,
        type: ValueType.MULTI,
      },
      {
        key: 'drugClass',
        label: 'Drug Class',
        width: 250,
        type: ValueType.MULTI,
      },
      {
        key: 'activeSubstance',
        label: 'Active Substance',
        width: 250,
        type: ValueType.MULTI,
      },
      {
        key: 'afSentiment',
        label: 'Sentiment',
        width: 100,
        type: ValueType.SINGLE,
        cellFormat: {
          colours: Sentiments,
        },
      },
      {
        key: 'lookupMappedSessionType',
        label: 'Session Type',
        width: 140,
        type: ValueType.MULTI,
      },
      {
        key: 'presentingAuthor',
        label: 'Presenting Author',
        width: 200,
        type: ValueType.MULTI,
      },
      {
        key: 'likes',
        label: 'Likes',
        width: 100,
        type: ValueType.NUMBER,
      },
      {
        key: 'views',
        label: 'Views',
        width: 100,
        type: ValueType.NUMBER,
      },
      {
        key: 'comments',
        label: 'Comments',
        width: 100,
        type: ValueType.NUMBER,
      },
      {
        key: 'afIndependent',
        label: 'Independent',
        width: 130,
        type: ValueType.SINGLE,
      },
      {
        key: 'lookupHIndex',
        label: 'H Index',
        width: 100,
        type: ValueType.NUMBER,
      },
      {
        key: 'sponsors',
        label: 'Sponsors',
        width: 200,
        type: ValueType.MULTI,
      },
    ],
  },
}
