export function GBPTransformFormat(value: string) {
  return parseFloat(value).toLocaleString([], {
    style: 'currency',
    currency: 'GBP',
    minimumFractionDigits: 0,
  })
}

export function USDTransformFormat(value: string) {
  return parseFloat(value).toLocaleString([], {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  })
}

export function EURTransformFormat(value: string) {
  return parseFloat(value).toLocaleString([], {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 0,
  })
}
