import { Box, Text, Flex } from '@chakra-ui/react'
import React from 'react'

import { formatUtc } from 'utils/formatDate'

const MainCard = ({
  keyStr,
  children,
}: {
  keyStr: string
  children: React.ReactNode
}) => {
  return (
    <Box mb={8}>
      <Box
        py={2}
        px={8}
        bg='gray5'
        borderWidth='1px 0px 1px 0px'
        borderColor='gray3'
      >
        <Text variant='body2-bold'>
          {formatUtc(new Date(keyStr), 'MMM yyyy')}
        </Text>
      </Box>
      <Flex width='full' flexDir='column' gap={6} maxW='613px' pl={8} py={6}>
        {children}
      </Flex>
    </Box>
  )
}

export default MainCard
