import { BriefsT } from 'api/cms/briefs/types'

export function getUniqueValues(arr: any[], property: string): string[] {
  if (!arr) return []
  const uniqueValuesSet = new Set()

  arr.forEach((obj) => {
    if (Array.isArray(obj[property])) {
      obj[property].forEach((value: string) => {
        uniqueValuesSet.add(value)
      })
    }
  })

  return Array.from(uniqueValuesSet) as string[]
}

export function removeDuplicates(array: any[]): BriefsT[] {
  const uniqueMap = new Map()
  for (const item of array) {
    if (!!item) {
      const keyValue = item?.id
      if (!uniqueMap.has(keyValue)) uniqueMap.set(keyValue, item)
    }
  }
  return Array.from(uniqueMap.values())
}

export const areasMapping = {
  TR: 'Treatments',
  VA: 'Vaccines',
}

export function filterBriefs(
  briefs: BriefsT[] | undefined,
  products: string[] | undefined,
  area: string | undefined,
  content: string[] | undefined
) {
  if (!briefs) return []
  const productFilter =
    products && products?.length > 0
      ? briefs.filter((brief) =>
          brief.parentApp.some((app) => {
            return products.includes(app)
          })
        )
      : briefs
  const areaFilter = area
    ? productFilter.filter(
        (brief) => areasMapping[brief.areaOfInterest] === area
      )
    : productFilter

  const contentFilter =
    content && content.length > 0
      ? areaFilter.filter((brief) =>
          brief.areasOfContentType.some((contentType) =>
            content.includes(contentType)
          )
        )
      : areaFilter
  return contentFilter
}
