import { IApp } from 'interfaces/navigationApp.interface'

import { AccessGroups } from 'enums/AccessGroups'

import { PredictiveAnalyticsCustomPages } from './PredictiveAnalyticsCustomPages'
import {
  PredictiveAnalyticsMenu,
  PredictiveAnalyticsSlugs,
} from './PredictiveAnalyticsMenu'
import { PredictiveAnalyticsPages } from './PredictiveAnalyticsPages'

const ObesityPredictiveAnalytics: IApp<PredictiveAnalyticsSlugs> = {
  name: 'Predictive Analytics',
  slug: 'obesity-predictive-analytics',
  endpoint: 'obesity-360',
  accessGroups: [AccessGroups.Obesity360],
  menu: PredictiveAnalyticsMenu,
  pages: PredictiveAnalyticsPages,
  customPages: PredictiveAnalyticsCustomPages,
  appIdentifier: 'obesity-360',
  createdAt: new Date(2024, 3, 25),
  disableAnalytics: true,
}

export default ObesityPredictiveAnalytics
