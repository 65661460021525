import { INavigationMenu } from 'interfaces/navigationMenu.interface'

export type ModernaRSVCommercialSlugs = '' | 'reports'

const ModernaRSVCommercialMenu: Array<
  INavigationMenu<ModernaRSVCommercialSlugs>[]
> = [
  [
    {
      title: 'Demand Forecast',
      slug: '',
      createdAt: new Date(2022, 5, 21),
    },
    {
      title: 'Reports',
      slug: 'reports',
      createdAt: new Date(2022, 5, 25),
    },
  ],
]

export { ModernaRSVCommercialMenu }
