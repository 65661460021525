import { INavigationMenu } from 'interfaces/navigationMenu.interface'

export type CovidBoosterMarketForecastSlugs =
  | ''
  | 'covid_booster_market_forecast'
  | 'supply-deals-and-donations'
  | 'booster-demand-forecast'

const CovidBoosterMarketForecastMenu: Array<
  INavigationMenu<CovidBoosterMarketForecastSlugs>[]
> = [
  [
    {
      title: 'Dashboard',
      slug: '',
      description: 'COVID Booster market forecast dashboard',
      createdAt: new Date(2021, 9, 11),
    },
  ],
  [
    {
      title: 'Supply Deals & Donations',
      slug: 'supply-deals-and-donations',
      description:
        'A database of supply deals and donations for COVID-19 vaccines',
      createdAt: new Date(2022, 0, 17),
    },
  ],
]

export { CovidBoosterMarketForecastMenu }
