import { IApp } from 'interfaces/navigationApp.interface'

import { AccessGroups } from 'enums/AccessGroups'

import { RespiratoryTimelineModel } from 'config/common/baseModel/respiratory360/RespiratoryTimeline'

import { RSVCustomPages } from './RSVCustomPages'
import { RSVMenu, RSVSlugs } from './RSVMenu'
import { RSVPages } from './RSVPages'

const rsv: IApp<RSVSlugs> = {
  name: 'Vaccines & Therapeutics',
  slug: 'rsv',
  accessGroups: [AccessGroups.RSV],
  endpoint: 'rsv',
  menu: RSVMenu,
  pages: RSVPages,
  baseModels: [RespiratoryTimelineModel],
  customPages: RSVCustomPages,
  createdAt: new Date(2021, 10, 11),
  tutorial: [
    {
      url: 'https://airfinity-production.s3.amazonaws.com/public/walkthroughs/rsv-science360.mp4',
      name: 'RSV - Vaccines & Therapeutics',
    },
  ],
}

export default rsv
