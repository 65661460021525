import { IApp } from 'interfaces/navigationApp.interface'

import { AccessGroups } from 'enums/AccessGroups'

import bioriskApp from 'config/apps/Biorisk/Custom/Biorisk/biorisk'

import { HealthSecurityCustomPages } from './HealthSecurityCustomPages'
import { HealthSecurityMenu, HealthSecuritySlugs } from './HealthSecurityMenu'
import { HealthSecurityPremiumCustomPages } from './Premium/HealthSecurityPremiumCustomPages'
import {
  HealthSecurityPremiumMenu,
  HealthSecurityPremiumSlugs,
} from './Premium/HealthSecurityPremiumMenu'
import { HealthSecurityPremiumPages } from './Premium/HealthSecurityPremiumPages'

const healthSecurity: IApp<HealthSecuritySlugs> = {
  ...bioriskApp,
  // Name is on purpose
  name: 'Health Security',
  slug: 'health-security',
  endpoint: 'health-security-standard',
  accessGroups: [
    AccessGroups.HealthSecurity,
    AccessGroups.HealthSecurityPremium,
  ],
  menu: HealthSecurityMenu,
  pages: {},
  customPages: HealthSecurityCustomPages,
  appIdentifier: 'healthSecurity',
  appPriority: 1,
}

export const healthSecurityPremium: IApp<HealthSecurityPremiumSlugs> = {
  ...healthSecurity,
  slug: 'health-security-premium',
  endpoint: 'health-security-premium',
  accessGroups: [AccessGroups.HealthSecurityPremium],
  menu: HealthSecurityPremiumMenu,
  pages: HealthSecurityPremiumPages,
  customPages: HealthSecurityPremiumCustomPages,
  appIdentifier: 'healthSecurity',
  appPriority: 2,
}

export default healthSecurity
