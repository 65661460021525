import { Box, Input as ChakraInput, Select } from '@chakra-ui/react'
import { useMemo } from 'react'
import { ColumnInstance, UseFiltersColumnProps } from 'react-table'

export type FilterComponentPropTypes<D extends Object> = {
  column: UseFiltersColumnProps<D> & ColumnInstance<D>
}

export function DefaultColumnFilter<D extends Object>({
  column,
}: FilterComponentPropTypes<D>) {
  const { filterValue, setFilter } = column

  return (
    <ChakraInput
      py={0}
      px={0}
      border='none'
      fontSize='sm'
      fontWeight={500}
      pl={3}
      bg={filterValue !== undefined ? 'gray.50' : 'transparent'}
      value={filterValue || ''}
      type='text'
      height='30px'
      _focus={{
        border: 'none',
        outline: 'none',
      }}
      onChange={(e: any) => {
        setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
      }}
      placeholder={`Search...`}
    />
  )
}

// This is a custom filter UI for selecting
// a unique option from a list
export function SelectColumnFilter<D extends Object>({
  column,
}: FilterComponentPropTypes<D>) {
  const { filterValue, setFilter, preFilteredRows, id } = column
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = useMemo(() => {
    const options = new Set()
    preFilteredRows.forEach((row: any) => {
      if (
        row.values[id] !== null &&
        row.values[id] !== '' &&
        !Array.isArray(row.values[id])
      ) {
        options.add(row.values[id])
      }
    })

    return [...options.values()]
  }, [id, preFilteredRows])

  // Render a multi-select box
  return (
    <Select
      width='100%'
      height='30px'
      px={3}
      fontWeight={500}
      fontFamily='body'
      border='none'
      outline='none'
      fontSize='13px'
      minWidth='130px'
      bg={filterValue !== undefined ? 'gray.50' : 'transparent'}
      borderRadius='none'
      cursor='pointer'
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined)
      }}
      _focus={{
        outline: 'none',
      }}
    >
      <option value=''>All</option>
      {options.map((option: any, i: any) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </Select>
  )
}

export const NumberRangeColumnFilter = ({ column }: { column: any }) => {
  const { filterValue = [], preFilteredRows, setFilter, id } = column

  const [min, max] = useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
    preFilteredRows.forEach((row: any) => {
      min = Math.min(row.values[id], min)
      max = Math.max(row.values[id], max)
    })
    return [min, max]
  }, [id, preFilteredRows])

  return (
    <Box display='flex' alignItems='center' position='relative'>
      <NumberInput
        value={filterValue[0] || ''}
        onChange={(e: any) => {
          const val = e.target.value
          setFilter((old = []) => [val ? parseInt(val, 10) : undefined, old[1]])
        }}
        placeholder={`Min (${min})`}
      />
      <NumberInput
        value={filterValue[1] || ''}
        onChange={(e: any) => {
          const val = e.target.value
          setFilter((old = []) => [old[0], val ? parseInt(val, 10) : undefined])
        }}
        placeholder={`Max (${max})`}
      />
    </Box>
  )
}

const NumberInput = ({
  value,
  onChange,
  placeholder,
}: {
  value: string
  onChange: any
  placeholder: string
}) => {
  return (
    <ChakraInput
      type='number'
      value={value}
      flex={1}
      height='30px'
      minWidth='70px'
      textAlign='center'
      px={0}
      border='none'
      fontSize='13px'
      onChange={onChange}
      placeholder={placeholder}
      _focus={{
        outline: 'none',
      }}
    />
  )
}
