import {
  Box,
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'
import { useState } from 'react'

import useTracking from 'tracking/useTracking'

type PropTypes = {
  defaultViewName: string
  isOpen: boolean
  onClose: () => void
  onCreateNewUserView: (title: string) => void
}

export default function AddUserViewModal({
  defaultViewName,
  isOpen,
  onClose,
  onCreateNewUserView,
}: PropTypes) {
  const [tracking] = useTracking()
  const [viewName, setViewName] = useState('')

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader color='primary' fontWeight='600'>
          Save View
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text color='primary' fontSize='14px' lineHeight='22px'>
            You’re about to create a custom view. It will be based on the '
            {defaultViewName}' view. Give it a name and you’re ready to go.
          </Text>
          <Box mb='24px' />
          <Input
            placeholder='Enter view name'
            value={viewName}
            onChange={(e) => setViewName(e.target.value)}
          />
        </ModalBody>

        <ModalFooter>
          <Button
            variant='yellow'
            onClick={() => {
              tracking.createNewUserView({})
              setViewName('')
              onCreateNewUserView(viewName)
              onClose()
            }}
          >
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
