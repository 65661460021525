import { Button, Text, VStack } from '@chakra-ui/react'

export type InlineErrorPropTypes = {
  message?: string
  handleReloadClick?: () => void
}

export const InlineError = ({
  message,
  handleReloadClick = () => window.location.reload(),
}: InlineErrorPropTypes) => {
  return (
    <VStack textAlign='center' p={6} data-cy='error'>
      <Text>
        {message ?? 'Something went wrong. Please reload to try again.'}
      </Text>
      <Button variant='yellow' onClick={handleReloadClick}>
        Reload
      </Button>
    </VStack>
  )
}
