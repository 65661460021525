const Heading = {
  variants: {
    h1: {
      fontSize: '26px',
      fontWeight: 600,
      lineHeight: '34px',
      letterSpacing: '-0.02px',
    },
    h2: {
      fontSize: '23px',
      fontWeight: 600,
      lineHeight: '35px',
      letterSpacing: '-0.05px',
    },
    h3: {
      fontSize: '19px',
      fontWeight: 600,
      lineHeight: '30px',
      letterSpacing: '-0.05px',
    },
    h4: {
      fontSize: '17px',
      fontWeight: 500,
      lineHeight: '22px',
      letterSpacing: '0.05px',
    },
    h5: {
      fontSize: '16px',
      fontWeight: 500,
      lineHeight: '23px',
    },
    'h5-light': {
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '23px',
    },
    h6: {
      fontSize: '15px',
      fontWeight: 500,
      lineHeight: '23px',
    },
  },
}

export default Heading
