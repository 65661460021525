import { RenderDetailViewProps } from 'interfaces/model.interface'

import { IDTreatments360DealsData } from 'config/common/baseModel/ida360/IDPremiumDeals'

import Detail from './Detail'

export const IDASupplyDealsDetailView = ({
  model,
  data,
  setSelectedRow,
  viewData,
}: RenderDetailViewProps<Record<IDTreatments360DealsData, string>>) => {
  if (!data) {
    return null
  }
  return (
    <Detail
      model={model}
      title='id'
      deals={data}
      handleClose={() => setSelectedRow(null)}
      viewData={viewData}
    />
  )
}
