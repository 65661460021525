import { UseMutationOptions, useMutation } from '@tanstack/react-query'
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios'

import useAxios from './useAxios'

export default function useTnCAgreeMutation(
  extraProps?: UseMutationOptions<
    AxiosResponse<any>,
    AxiosError<any>,
    { date: string }
  >
) {
  const axios = useAxios()

  return useMutation<AxiosResponse<any>, AxiosError<any>, { date: string }>(
    ({ date }) => {
      return termsAndConditionsAgree(axios, date)
    },
    extraProps
  )
}

export const termsAndConditionsAgree = async (
  axios: AxiosInstance,
  date: string
) => {
  return await axios
    .post(`/terms-and-conditions/`, { date })
    .then((x) => x.data)
}
