import { Box } from '@chakra-ui/react'

import ReportPage from 'routes/common/ReportPage'
import ReportPageByID from 'routes/common/ReportPageByID'

import { TableauEmbed } from 'components'

import { ISingleCustomPage } from 'interfaces/navigationPage.interface'

import { PredictiveAnalyticsSlugs } from './PredictiveAnalyticsMenu'

const KeyUpcomingDevelopments: ISingleCustomPage<PredictiveAnalyticsSlugs> = {
  path: [''],
  component: () => <ReportPageByID id='1394' />,
}

const ExcessComorbid: ISingleCustomPage<PredictiveAnalyticsSlugs> = {
  path: ['excess-comorbid', ''],
  component: () => (
    <TableauEmbed
      path='/ObesityEpid/Obesity-relateddiseaseburden2'
      height={'170vh'}
    />
  ),
}

const ObstructiveSleepApnoea: ISingleCustomPage<PredictiveAnalyticsSlugs> = {
  path: ['excess-comorbid', 'sleep-apnoea'],
  component: () => (
    <ReportPage
      url='https://d25cno014nd6u0.cloudfront.net/Obstructive_sleep_apnoea.pdf'
      page={[1, 2, 3, 4]}
      title='Obstructive sleep apnoea'
    />
  ),
}
const Hypertension: ISingleCustomPage<PredictiveAnalyticsSlugs> = {
  path: ['excess-comorbid', 'hypertension'],
  component: () => (
    <ReportPage
      url='https://d25cno014nd6u0.cloudfront.net/Hypertension.pdf'
      page={[1, 2, 3, 4]}
      title='Hypertension'
    />
  ),
}
const UnstableAnginaMi: ISingleCustomPage<PredictiveAnalyticsSlugs> = {
  path: ['excess-comorbid', 'unstable-angina-mi'],
  component: () => (
    <ReportPage
      url='https://d25cno014nd6u0.cloudfront.net/Unstable_angina__MI.pdf'
      page={[]}
      title='Unstable angina & MI'
      allPages
    />
  ),
}
const ChronicKidneyDisease: ISingleCustomPage<PredictiveAnalyticsSlugs> = {
  path: ['excess-comorbid', 'chronic-kidney-disease'],
  component: () => <ReportPageByID id='1418' />,
}
const HeartFailure: ISingleCustomPage<PredictiveAnalyticsSlugs> = {
  path: ['excess-comorbid', 'heart-failure'],
  component: () => (
    <ReportPage
      url='https://d25cno014nd6u0.cloudfront.net/heart-failure.pdf'
      page={[]}
      allPages
      title='Heart Failure'
    />
  ),
}

const CostPerOutcomeAvoided: ISingleCustomPage<PredictiveAnalyticsSlugs> = {
  path: ['health-economic-assessments', ''],
  component: () => (
    <Box display={'flex'} flexDirection={'column'} overflowY={'auto'}>
      <ReportPageByID id='1397' />
    </Box>
  ),
}

const MetabolicSteatohepatitis: ISingleCustomPage<PredictiveAnalyticsSlugs> = {
  path: ['excess-comorbid', 'metabolic-steatohepatitis'],
  component: () => <ReportPageByID id='1459' />,
}

const ObesityWithType2Diabetes: ISingleCustomPage<PredictiveAnalyticsSlugs> = {
  path: ['safety-endpoints', 'obesity-with-diabetes'],
  component: () => <ReportPageByID id='1474' />,
}

const ObesityWithoutType2Diabetes: ISingleCustomPage<PredictiveAnalyticsSlugs> =
  {
    path: ['safety-endpoints', 'obesity-without-diabetes'],
    component: () => <ReportPageByID id='1475' />,
  }

const BloodGlucose: ISingleCustomPage<PredictiveAnalyticsSlugs> = {
  path: ['cardiometabolic-endpoints', 'blood-glucose'],
  component: () => <ReportPageByID id='1476' />,
}

const DiastolicBloodGlucose: ISingleCustomPage<PredictiveAnalyticsSlugs> = {
  path: ['cardiometabolic-endpoints', 'diastolic-blood-pressure'],
  component: () => <ReportPageByID id='1477' />,
}

const GlycatedHaemoglobin: ISingleCustomPage<PredictiveAnalyticsSlugs> = {
  path: ['cardiometabolic-endpoints', 'glycated-haemoglobin'],
  component: () => <ReportPageByID id='1478' />,
}

const Inflammation: ISingleCustomPage<PredictiveAnalyticsSlugs> = {
  path: ['cardiometabolic-endpoints', 'inflammation'],
  component: () => <ReportPageByID id='1479' />,
}

const SystolicBloodPressure: ISingleCustomPage<PredictiveAnalyticsSlugs> = {
  path: ['cardiometabolic-endpoints', 'systolic-blood-pressure'],
  component: () => <ReportPageByID id='1480' />,
}

export const PredictiveAnalyticsCustomPages: Record<
  string,
  ISingleCustomPage<PredictiveAnalyticsSlugs>
> = {
  KeyUpcomingDevelopments,
  ExcessComorbid,
  ObstructiveSleepApnoea,
  Hypertension,
  CostPerOutcomeAvoided,
  UnstableAnginaMi,
  ChronicKidneyDisease,
  HeartFailure,
  MetabolicSteatohepatitis,
  ObesityWithType2Diabetes,
  ObesityWithoutType2Diabetes,
  BloodGlucose,
  DiastolicBloodGlucose,
  GlycatedHaemoglobin,
  Inflammation,
  SystolicBloodPressure,
}
