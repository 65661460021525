import { IApp } from 'interfaces/navigationApp.interface'

import { AccessGroups } from 'enums/AccessGroups'

import { CardioAntithromboticsCustomPages } from './CardioAntithromboticsCustomPages'
import {
  CardioAntithromboticsMenu,
  CardioAntithromboticsSlugs,
} from './CardioAntithromboticsMenu'
import { CardioAntithromboticsPages } from './CardioAntithromboticsPages'

const CardioAntithrombotics: IApp<CardioAntithromboticsSlugs> = {
  name: 'Antithrombotics',
  slug: 'cardio-antithrombotics',
  accessGroups: [AccessGroups.CardioAntithrombotics],
  endpoint: 'cardio-anti-thrombotics',
  menu: CardioAntithromboticsMenu,
  pages: CardioAntithromboticsPages,
  customPages: CardioAntithromboticsCustomPages,
  createdAt: new Date(2022, 2, 21),
}

export default CardioAntithrombotics
