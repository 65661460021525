import { ListItem, Text, UnorderedList } from '@chakra-ui/react'

import PandemicEpidemiology from 'modules/Pandemic/Epidemiology'

import { BlurredFullPageUpsell } from 'components'

import { ISingleCustomPage } from 'interfaces/navigationPage.interface'

import resolvePath from 'utils/resolvePath'

import { BioriskCustomPages } from '../Biorisk/Custom/Biorisk/BioriskCustomPages'
import { HealthSecurityMenu, HealthSecuritySlugs } from './HealthSecurityMenu'
import healthSecurity from './healthSecurity'

// This should be a list of paths that is used above
const pathsUsed = [[''], ['all-reports']]

// First get all end paths from the menu (except the ones to bring over)
const allCombinedPaths = HealthSecurityMenu.flat()
  .map((menu) =>
    menu.children
      ? menu.children.map((child) => [menu.slug, child.slug])
      : [[menu.slug]]
  )
  .flat()
  // And only get the ones that we don't handle yet
  .filter((path) => !pathsUsed.map(resolvePath).includes(resolvePath(path)))

// Generate BlurredFullPageUpsell for all the paths we don't manually bring over
const HealthSecurityBlurredCustomPages = Object.fromEntries(
  allCombinedPaths
    .map(
      (path) =>
        ({
          path,
          component: () => (
            <BlurredFullPageUpsell
              app={healthSecurity}
              title={
                <>
                  <Text fontWeight='bold' fontSize='lg'>
                    Health Security - Premium
                  </Text>
                  <Text color='gray.800'>
                    Let us know if you want to upgrade to our premium offering.
                    Get <b>access</b> to the latest:
                  </Text>
                </>
              }
              body={
                <>
                  <UnorderedList flex={1}>
                    <ListItem>
                      Flight restrictions, lockdown information, entry
                      requirements, and more - by country.
                    </ListItem>
                    <ListItem>
                      Vaccine mandates, booster strategies, approvals, and
                      supply - by country.
                    </ListItem>
                    <ListItem>
                      The spread of Variants of concern across the globe.
                    </ListItem>
                  </UnorderedList>
                </>
              }
              imageSrc='/images/epid_plus.png'
            />
          ),
        } as ISingleCustomPage<HealthSecuritySlugs>)
    )
    .map((val, i) => [i, val])
)

const EpidData: ISingleCustomPage<HealthSecuritySlugs> = {
  path: [''],
  component: PandemicEpidemiology,
}

export const HealthSecurityCustomPages: Record<
  string,
  ISingleCustomPage<HealthSecuritySlugs>
> = {
  EpidData,
  Reports: { ...BioriskCustomPages.Reports, path: ['reports'] },
  ...HealthSecurityBlurredCustomPages,
}
