import { Flex, Button } from '@chakra-ui/react'
import DateHeader from './Map/DatePicker'
import { exportToImage } from './utils'
import { useExportModal } from 'contexts/ExportModal'
import { Panel } from 'components/Panel'

const Header = ({
  dataLength,
  setStepsEnabled,
  setStepIndex,
}: {
  dataLength: number
  setStepsEnabled: (value: boolean) => void
  setStepIndex: (value: number) => void
}) => {
  const { openModal } = useExportModal()
  return (
    <Panel
      border='1px solid'
      borderColor='gray3'
      background='white'
      borderRadius='8px'
      flex={1}
      display={'flex'}
      py={1}
      px={2}
      mb={2}
      alignItems={'center'}
    >
      <Flex>
        {/* <Flex
          border={'1px'}
          borderColor={'gray.200'}
          h={8}
          w='max-content'
          px={2}
          alignItems={'center'}
          bg='gray.100'
          py={1}
          gap={1}
          borderRadius={'sm'}
        >
          <Text
            fontWeight={'semibold'}
            color={'gray.800'}
            lineHeight={'14px'}
            fontSize={'xs'}
          >
            Total trials
          </Text>
          <Text lineHeight={'14px'} fontSize={'sm'} fontWeight={'semibold'}>
            {dataLength}
          </Text>
        </Flex> */}
        <DateHeader />
      </Flex>
      <Flex gap={2} className='exportButton'>
        <Button
          size={'xs'}
          border={'1px'}
          borderColor={'gray.200'}
          borderRadius={'sm'}
          h={8}
          zIndex={100}
          variant={'solid'}
          onClick={() => {
            localStorage.removeItem('trialsDashboardTourCompleted')
            setStepsEnabled(true)
            setStepIndex(0)
          }}
        >
          View Demo
        </Button>
        <Button
          size={'xs'}
          border={'1px'}
          borderColor={'gray.200'}
          borderRadius={'sm'}
          h={8}
          variant={'solid'}
          onClick={exportToImage}
        >
          Export Image
        </Button>
        <Button
          size={'xs'}
          border={'1px'}
          borderColor={'gray.200'}
          h={8}
          variant={'solid'}
          borderRadius={'sm'}
          onClick={() => openModal()}
        >
          Export Data
        </Button>
      </Flex>
    </Panel>
  )
}

export default Header
