import { IApp } from 'interfaces/navigationApp.interface'

import { AccessGroups } from 'enums/AccessGroups'

import { RSVCustomPages } from 'config/apps/RSV/Science360/VaccinesTherapeutics/RSVCustomPages'
import {
  RSVMenu,
  RSVSlugs,
} from 'config/apps/RSV/Science360/VaccinesTherapeutics/RSVMenu'
import { RSVPages } from 'config/apps/RSV/Science360/VaccinesTherapeutics/RSVPages'
import { RespiratoryTimelineModel } from 'config/common/baseModel/respiratory360/RespiratoryTimeline'

const ModernaRSVScience360App: IApp<RSVSlugs> = {
  name: 'RSV',
  slug: 'moderna-rsv-science360',
  accessGroups: [AccessGroups.Moderna],
  endpoint: 'rsv',
  commonIdentifier: 'rsv',
  menu: RSVMenu,
  pages: RSVPages,
  baseModels: [RespiratoryTimelineModel],
  customPages: RSVCustomPages,
  createdAt: new Date(2022, 5, 30),
}

export default ModernaRSVScience360App
