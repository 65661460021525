import { Heading, Box } from '@chakra-ui/react'
import Truncate from 'react-truncate'

import { ItemComponentPropTypes } from 'modules/DetailDrawer'
import ArticleFooter from 'modules/DetailDrawer/DetailDrawerList/components/ArticleFooter'

import { IDTreatments360NewsPressData } from 'config/common/baseModel/ida360/IDTreatment360NewsAndPressReleases'

import { formatDate } from 'utils/formatDate'

const DetailDrawerListPressRelease = ({
  item,
  handleSelect,
}: ItemComponentPropTypes<Record<IDTreatments360NewsPressData, any>>) => {
  const { title, publishedAt, descriptionClean, sourceUrl, link } = item

  let externalLink = sourceUrl ?? link

  const titleCleaned = typeof title === 'object' ? (title as any)?.title : title

  return (
    <Box
      px={8}
      py={4}
      borderBottom='1px solid'
      borderColor='gray.100'
      cursor='pointer'
      onClick={() => handleSelect(item)}
      _hover={{
        bg: 'gray.100',
      }}
    >
      <Box
        mb={1}
        display='flex'
        alignItems='center'
        fontSize='xs'
        fontWeight={600}
        textTransform='uppercase'
        color='gray.700'
      >
        <Box mr={2}>{formatDate(publishedAt)}</Box>
      </Box>
      <Heading fontSize='md' mb={2} fontWeight={500}>
        {titleCleaned}
      </Heading>
      <Box mb={4} color='gray.800' fontSize='sm'>
        <Truncate lines={3} ellipsis={<span>...</span>} trimWhitespace={true}>
          {descriptionClean}
        </Truncate>
      </Box>
      <ArticleFooter
        externalLink={externalLink as string}
        showAbstract={false}
      />
    </Box>
  )
}

export { DetailDrawerListPressRelease }
