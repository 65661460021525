import { IModel } from 'interfaces/model.interface'
import { ValueType } from 'interfaces/valueType.interface'

import { fourDecimalPlaces } from 'config/common/transformValue/decimal'

export type ResearchPowerIndexCountriesData =
  | 'country'
  | 'fundingSourceLocationsPaperNumbersUniqueValues'
  | 'ssovNormalisedCount2021And2020FundingBodyIncludingReviews'
  | 'ssovFinalRenormalisedQualityPublicationFundingLocInclReviews20202021'
  | 'allTimeSsovFinalImpactReNormalisedPublicationFundingLocInclReviews'
  | 'threeMetricAverageIncludingReviewsFundingSource2020And2021'
  | 'leadAndLastAuthorAffiliationCountriesPaperNumbersUniqueValues'
  | 'ssovNormalisedCount2021And2020AuthorAffiliationIncludingReviews'
  | 'ssovFinalRenormalisedQualityPublicationAuthorLocInclReviews20202021'
  | 'allTimeSsovFinalImpactReNormalisedPublicationAuthorLocInclReviews'
  | 'threeMetricAverageIncludingReviewsAuthorAffiliation2020And2021'
  | 'publicationCountByAuthorAffiliationIncReviews2021And2020'
  | 'publicationCountByFundingSourceIncReviews2021And2020'
  | 'finalQuantityKol2020And2021RollupFromPersons'
  | 'normalisedTweetQuantityPerCountry20202021'
  | 'finalReNormalisedQualityKol2021And2022'
  | 'qualityAndQualityReNormalisedAverage2020And2021'
  | 'privateSectorCompanies'
  | 'reNormalisedRevenueRpiScores'
  | 'rankByRevenueScores'
  | 'rankBy2MetricKolTwitterScore'
  | 'rankBy3MetricPublicationRpiScoresByAuthorAffiliation'
  | 'rankBy3MetricPublicationRpiByFundingAgency'

const allowedAirtables = [
  'api_2020_2021_by_funding_loc_incl_reviews',
  'api_2020_2021_by_author_affiliation_incl_reviews',
  'api_2020_and_2021_by_kol_scores',
  'api_2020_and_2021_by_private_sector_company_revenue',
] as const

export const ResearchPowerIndexCountriesModel: IModel<
  Record<ResearchPowerIndexCountriesData, string>,
  typeof allowedAirtables
> = {
  allowedAirtables,
  name: 'Countries',
  entityName: 'Countries',
  searchField: 'country',
  displayKey: 'country',
  detailViewType: 'Generic',
  endpoint: 'country',
  schema: {
    columns: [
      { key: 'country', label: 'Country', width: 180, type: ValueType.TEXT },
      {
        key: 'fundingSourceLocationsPaperNumbersUniqueValues',
        label: 'Linked publications',
        width: 220,
        type: ValueType.MULTI,
      },
      {
        key: 'ssovNormalisedCount2021And2020FundingBodyIncludingReviews',
        label: 'Country Quantity normalised score',
        width: 200,
        type: ValueType.NUMBER,
        transformValue: fourDecimalPlaces,
      },
      {
        key: 'ssovFinalRenormalisedQualityPublicationFundingLocInclReviews20202021',
        label: 'Country Quality re-normalised score',
        width: 200,
        type: ValueType.NUMBER,
        transformValue: fourDecimalPlaces,
      },
      {
        key: 'allTimeSsovFinalImpactReNormalisedPublicationFundingLocInclReviews',
        label: 'Country Impact re-normalised score',
        width: 200,
        type: ValueType.NUMBER,
        transformValue: fourDecimalPlaces,
      },
      {
        key: 'threeMetricAverageIncludingReviewsFundingSource2020And2021',
        label: 'Country 3-metric RPI by Publications only',
        width: 200,
        type: ValueType.NUMBER,
        transformValue: fourDecimalPlaces,
      },
      {
        key: 'leadAndLastAuthorAffiliationCountriesPaperNumbersUniqueValues',
        label: 'Linked publications',
        width: 200,
        type: ValueType.MULTI,
      },
      {
        key: 'ssovNormalisedCount2021And2020AuthorAffiliationIncludingReviews',
        label: 'Country Quantity normalised score',
        width: 200,
        type: ValueType.NUMBER,
        transformValue: fourDecimalPlaces,
      },
      {
        key: 'ssovFinalRenormalisedQualityPublicationAuthorLocInclReviews20202021',
        label: 'Country Quality re-normalised score',
        width: 200,
        type: ValueType.NUMBER,
        transformValue: fourDecimalPlaces,
      },
      {
        key: 'allTimeSsovFinalImpactReNormalisedPublicationAuthorLocInclReviews',
        label: 'Country Impact re-normalised score',
        width: 200,
        type: ValueType.TEXT,
        transformValue: fourDecimalPlaces,
      },
      {
        key: 'threeMetricAverageIncludingReviewsAuthorAffiliation2020And2021',
        label: 'Country 3-metric RPI by Publications only',
        width: 200,
        type: ValueType.NUMBER,
        transformValue: fourDecimalPlaces,
      },
      {
        key: 'publicationCountByAuthorAffiliationIncReviews2021And2020',
        label: 'Number of publications',
        width: 180,
        type: ValueType.NUMBER,
      },
      {
        key: 'publicationCountByFundingSourceIncReviews2021And2020',
        label: 'Number of publications',
        width: 180,
        type: ValueType.NUMBER,
      },
      {
        key: 'finalQuantityKol2020And2021RollupFromPersons',
        label: 'Number of Tweets',
        width: 180,
        type: ValueType.NUMBER,
      },
      {
        key: 'normalisedTweetQuantityPerCountry20202021',
        label: 'Tweet Quantity normalised score',
        width: 180,
        type: ValueType.NUMBER,
      },
      {
        key: 'finalReNormalisedQualityKol2021And2022',
        label: 'Tweet Quality Re-normalised score',
        width: 180,
        type: ValueType.NUMBER,
      },
      {
        key: 'qualityAndQualityReNormalisedAverage2020And2021',
        label: '2-metric RPI by KOL social media activity',
        width: 180,
        type: ValueType.NUMBER,
      },
      {
        key: 'privateSectorCompanies',
        label: 'Private Sector Companies',
        width: 300,
        type: ValueType.MULTI,
      },
      {
        key: 'reNormalisedRevenueRpiScores',
        label: 'Re-Normalised Revenue RPI Scores',
        width: 130,
        type: ValueType.NUMBER,
      },
      {
        key: 'rankByRevenueScores',
        label: 'Rank By Revenue Scores',
        width: 110,
        type: ValueType.NUMBER,
      },
      {
        key: 'rankBy2MetricKolTwitterScore',
        label: 'Rank by 2-metric KOL Twitter Score',
        width: 180,
        type: ValueType.NUMBER,
      },
      {
        key: 'rankBy3MetricPublicationRpiScoresByAuthorAffiliation',
        label: 'Rank by 3-metric Publication RPI Scores by Author Affiliation',
        width: 180,
        type: ValueType.NUMBER,
      },
      {
        key: 'rankBy3MetricPublicationRpiByFundingAgency',
        label: 'Rank by 3-metric Publication RPI by Funding Agency',
        width: 180,
        type: ValueType.NUMBER,
      },
    ],
  },
}
