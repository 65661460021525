import { RenderDetailViewProps } from 'interfaces/model.interface'

import { CandidateRankingData } from 'config/common/baseModel'

import Detail from './Detail'

const Candidates = ({
  model,
  data,
  setSelectedRow,
  viewData,
}: RenderDetailViewProps<Record<CandidateRankingData, string>>) => {
  if (!data) {
    return null
  }
  return (
    <Detail
      model={model}
      title='vaccine'
      candidate={data}
      handleClose={() => setSelectedRow(null)}
      viewData={viewData}
    />
  )
}

export default Candidates
