import GenericTable from 'modules/Tables'

import CardioLipidDetailView from 'routes/apps/cardioLipid/CandidateRanking'

import { IModel } from 'interfaces/model.interface'
import {
  INavigationPages,
  ISingleNavigationPage,
} from 'interfaces/navigationPage.interface'

import { CardioLipidSlugs } from './CardioLipidMenu'
import {
  CardioLipidCandidateCardiovascularOutcomesModel,
  CardioLipidCandidateLoweringEfficacyModel,
  CardioLipidCandidateModel,
  CardioLipidConferencePresentationsModel,
  CardioLipidKolsModel,
  CardioLipidMediaModel,
  CardioLipidPressReleasesModel,
  CardioLipidPublicationsModel,
  CardioLipidTrialsModel,
  CardioLipidTweetsModel,
} from './CardioLipidModel'

type CardioLipidNavigationPage<TModel extends IModel<any>> =
  ISingleNavigationPage<CardioLipidSlugs, TModel>

const Candidates: CardioLipidNavigationPage<typeof CardioLipidCandidateModel> =
  {
    key: 'Candidates',
    path: ['candidates', 'table'],
    component: GenericTable,
    model: {
      ...CardioLipidCandidateModel,
      renderDetailView: CardioLipidDetailView,
    },
    views: [
      {
        name: 'CardioLipid',
        airtableName: 'api_lipid_360',
        airtableBase: 'cardio360',
      },
    ],
  }

const Publications: CardioLipidNavigationPage<
  typeof CardioLipidPublicationsModel
> = {
  key: 'Publications',
  path: ['science', 'publications'],
  component: GenericTable,
  model: CardioLipidPublicationsModel,
  views: [
    {
      name: 'CardioLipid',
      airtableName: 'api_lipid_360',
      airtableBase: 'cardio360',
    },
  ],
}
const ConferencePresentations: CardioLipidNavigationPage<
  typeof CardioLipidConferencePresentationsModel
> = {
  key: 'ConferencePresentations',
  path: ['science', 'conference-presentations'],
  component: GenericTable,
  model: CardioLipidConferencePresentationsModel,
  views: [
    {
      name: 'Default',
      airtableName: 'api_conference_presentations',
      airtableBase: 'cardio360',
    },
  ],
}

const ClinicalTrials: CardioLipidNavigationPage<typeof CardioLipidTrialsModel> =
  {
    key: 'ClinicalTrials',
    path: ['trials', 'all'],
    component: GenericTable,
    model: CardioLipidTrialsModel,
    views: [
      {
        name: 'CardioLipid',
        airtableName: 'api_lipid_360',
        airtableBase: 'cardio360',
      },
    ],
  }

const ResultsLoweringEfficacy: CardioLipidNavigationPage<
  typeof CardioLipidCandidateLoweringEfficacyModel
> = {
  key: 'ResultsLoweringEfficacy',
  path: ['results', 'lipid-lowering-efficacy'],
  component: GenericTable,
  model: CardioLipidCandidateLoweringEfficacyModel,
  views: [
    {
      name: 'CardioLipid',
      airtableName: 'api_lipid_lowering_efficacy',
      airtableBase: 'cardio360',
    },
  ],
}

const ResultsCardiovacularOutcomes: CardioLipidNavigationPage<
  typeof CardioLipidCandidateCardiovascularOutcomesModel
> = {
  key: 'ResultsCardiovacularOutcomes',
  path: ['results', 'cardiovacular-outcomes'],
  component: GenericTable,
  model: CardioLipidCandidateCardiovascularOutcomesModel,
  views: [
    {
      name: 'CardioLipid',
      airtableName: 'api_cardiovascular_outcomes',
      airtableBase: 'cardio360',
    },
  ],
}

const PressReleases: CardioLipidNavigationPage<
  typeof CardioLipidPressReleasesModel
> = {
  key: 'PressReleases',
  path: ['news', 'press-releases'],
  component: GenericTable,
  model: CardioLipidPressReleasesModel,
  views: [
    {
      name: 'CardioLipid',
      airtableName: 'api_lipid_360',
      airtableBase: 'cardio360',
    },
  ],
}

const Media: CardioLipidNavigationPage<typeof CardioLipidMediaModel> = {
  key: 'Media',
  path: ['news', 'media'],
  component: GenericTable,
  model: CardioLipidMediaModel,
  views: [
    {
      name: 'CardioLipid',
      airtableName: 'api_lipid_360',
      airtableBase: 'cardio360',
    },
  ],
}

const Tweets: CardioLipidNavigationPage<typeof CardioLipidTweetsModel> = {
  key: 'Tweets',
  path: ['social', 'tweets'],
  component: GenericTable,
  model: CardioLipidTweetsModel,
  views: [
    {
      name: 'CardioLipid',
      airtableName: 'api_lipid_360',
      airtableBase: 'cardio360',
    },
  ],
}

const kols: CardioLipidNavigationPage<typeof CardioLipidKolsModel> = {
  key: 'kols',
  path: ['kols'],
  component: GenericTable,
  model: CardioLipidKolsModel,
  views: [
    {
      name: 'Default',
      airtableName: 'api_kols_lipid_360',
      airtableBase: 'cardio360',
    },
  ],
}

export const CardioLipidPages: INavigationPages<CardioLipidSlugs> = {
  Candidates,
  Publications,
  ConferencePresentations,
  ClinicalTrials,
  ResultsLoweringEfficacy,
  ResultsCardiovacularOutcomes,
  PressReleases,
  Media,
  Tweets,
  kols,
}
