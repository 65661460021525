export enum ValueType {
  URL = 'URL',
  DATE = 'DATE',
  MULTI = 'MULTI',
  NUMBER = 'NUMBER',
  LOGO = 'LOGO',
  SINGLE = 'SINGLE',
  LONG = 'LONG',
  FILES = 'FILES',
  TWEET = 'TWEET',
  TEXT = 'TEXT',
  STAR = 'STAR',
  BOOLEAN = 'BOOLEAN',
}
