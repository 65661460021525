import { IModel } from 'interfaces/model.interface'
import { ValueType } from 'interfaces/valueType.interface'

import { SortOrders } from 'enums/SortOrders'

export type ConferencesData =
  | 'name'
  | 'eventName'
  | 'status'
  | 'startDate'
  | 'eventStart'
  | 'endDate'
  | 'eventEnd'
  | 'eventUrl'
  | 'eventStatus'
  | 'linkCountry'

const allowedAirtables = [
  'api_vaccines',
  'api_treatments',
  'API_RSV',
  'API_Flu',
] as const

export const ConferencesModel: IModel<
  Record<ConferencesData, string>,
  typeof allowedAirtables
> = {
  allowedAirtables,
  endpoint: 'conference',
  name: 'Conferences',
  entityName: 'Conferences',
  searchField: 'name',
  detailViewType: 'Generic',
  displayKey: 'name',
  defaultSortKey: 'startDate',
  defaultSortOrder: SortOrders.DESC,
  schema: {
    columns: [
      { label: 'Name', key: 'name', width: 500 },
      { label: 'Name', key: 'eventName', width: 500 },
      { label: 'Status', key: 'status', type: ValueType.SINGLE, width: 200 },
      {
        key: 'startDate',
        type: ValueType.DATE,
        width: 200,
        label: 'Start date',
      },
      {
        key: 'eventStart',
        type: ValueType.DATE,
        width: 200,
        label: 'Start date',
      },
      {
        key: 'endDate',
        type: ValueType.DATE,
        width: 200,
        label: 'End date',
      },
      {
        key: 'eventEnd',
        type: ValueType.DATE,
        width: 200,
        label: 'End date',
      },
      { key: 'eventUrl', type: ValueType.URL, width: 300, label: 'Event URL' },
      {
        key: 'eventStatus',
        type: ValueType.MULTI,
        width: 200,
        label: 'Status',
      },
      {
        key: 'linkCountry',
        type: ValueType.MULTI,
        width: 200,
        label: 'Country',
      },
    ],
  },
}
