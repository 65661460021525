import GenericTable from 'modules/Tables'

import { IModel } from 'interfaces/model.interface'
import {
  INavigationPages,
  ISingleNavigationPage,
} from 'interfaces/navigationPage.interface'

import { ResearchPowerIndexSlugs } from './ResearchPowerIndexMenu'
import {
  CompanyRevenueRankingModel,
  CountriesByAuthorAffiliationModel,
  CountriesByFundingOrgsModel,
  CountriesByKOLScoresModel,
  CountriesByPrivateSectorCompanyRevenueModel,
  FundingAgenciesModel,
  PersonModel,
  PublicationsModel,
  ResearchInstitutionsModel,
  TweetsModel,
} from './ResearchPowerIndexModel'

type ResearchPowerIndexNavigationPage<TModel extends IModel<any>> =
  ISingleNavigationPage<ResearchPowerIndexSlugs, TModel>

const Publications: ResearchPowerIndexNavigationPage<typeof PublicationsModel> =
  {
    key: 'Publications',
    path: '',
    component: GenericTable,
    model: PublicationsModel,
    views: [
      {
        name: 'Default',
        airtableName: 'api_front_end_view_publications',
        airtableBase: 'researchpowerindex',
      },
    ],
  }

const Tweets: ResearchPowerIndexNavigationPage<typeof TweetsModel> = {
  key: 'Tweets',
  path: 'tweets',
  component: GenericTable,
  model: TweetsModel,
  views: [
    {
      name: 'Default',
      airtableName: 'api_front_end_view_twitter',
      airtableBase: 'researchpowerindex',
    },
  ],
}

const Persons: ResearchPowerIndexNavigationPage<typeof PersonModel> = {
  key: 'Persons',
  path: 'people',
  component: GenericTable,
  model: { ...PersonModel, name: 'People' },
  views: [
    {
      name: 'Default',
      airtableName: 'api_kol_twitter_scoring_2020_and_2021',
      airtableBase: 'researchpowerindex',
    },
  ],
}

const ResearchInstitutions: ResearchPowerIndexNavigationPage<
  typeof ResearchInstitutionsModel
> = {
  key: 'ResearchInstitutions',
  path: 'research-institutions-ranking',
  component: GenericTable,
  model: { ...ResearchInstitutionsModel, name: 'Research Institutions' },
  views: [
    {
      name: 'Default',
      airtableName: 'api_research_orgs_with_reviews',
      airtableBase: 'researchpowerindex',
    },
  ],
}

const FundingAgencies: ResearchPowerIndexNavigationPage<
  typeof FundingAgenciesModel
> = {
  key: 'FundingAgencies',
  path: 'funding-agencies-ranking',
  component: GenericTable,
  model: { ...FundingAgenciesModel, name: 'Funding Agencies' },
  views: [
    {
      name: 'Default',
      airtableName: 'api_funding_agency_with_reviews',
      airtableBase: 'researchpowerindex',
    },
  ],
}

const CompanyRevenueRanking: ResearchPowerIndexNavigationPage<
  typeof CompanyRevenueRankingModel
> = {
  key: 'CompanyRevenueRanking',
  path: 'company-revenue-ranking',
  component: GenericTable,
  model: CompanyRevenueRankingModel,
  views: [
    {
      name: 'Default',
      airtableName: 'api_private_sector_company_revenue_score_ranking',
      airtableBase: 'researchpowerindex',
    },
  ],
}

const CountriesByFundingOrgs: ResearchPowerIndexNavigationPage<
  typeof CountriesByFundingOrgsModel
> = {
  key: 'CountriesByFundingOrgs',
  path: ['countries-ranking', 'by-funding-orgs'],
  component: GenericTable,
  model: {
    ...CountriesByFundingOrgsModel,
    name: 'Countries ranked by funding organisations',
  },
  views: [
    {
      name: 'Default',
      airtableName: 'api_2020_2021_by_funding_loc_incl_reviews',
      airtableBase: 'researchpowerindex',
    },
  ],
}
const CountriesByAuthorAffiliationOrgs: ResearchPowerIndexNavigationPage<
  typeof CountriesByAuthorAffiliationModel
> = {
  key: 'CountriesByAuthorAffiliationOrgs',
  path: ['countries-ranking', 'by-author-affiliations'],
  component: GenericTable,
  model: {
    ...CountriesByAuthorAffiliationModel,
    name: 'Countries ranked by author affiliations to research institutions',
  },
  views: [
    {
      name: 'Default',
      airtableName: 'api_2020_2021_by_author_affiliation_incl_reviews',
      airtableBase: 'researchpowerindex',
    },
  ],
}
const CountriesByKOLScores: ResearchPowerIndexNavigationPage<
  typeof CountriesByKOLScoresModel
> = {
  key: 'CountriesByKOLScores',
  path: ['countries-ranking', 'by-kol-scores'],
  component: GenericTable,
  model: {
    ...CountriesByKOLScoresModel,
    name: 'Countries ranked by KOL Scores',
  },
  views: [
    {
      name: 'Default',
      airtableName: 'api_2020_and_2021_by_kol_scores',
      airtableBase: 'researchpowerindex',
    },
  ],
}
const CountriesByPrivateSectorCompanyRevenue: ResearchPowerIndexNavigationPage<
  typeof CountriesByPrivateSectorCompanyRevenueModel
> = {
  key: 'CountriesByPrivateSectorCompanyRevenue',
  path: ['countries-ranking', 'by-private-sector-company-revenue'],
  component: GenericTable,
  model: {
    ...CountriesByPrivateSectorCompanyRevenueModel,
    name: 'Countries ranked by Private Sector Company Revenue',
  },
  views: [
    {
      name: 'Default',
      airtableName: 'api_2020_and_2021_by_private_sector_company_revenue',
      airtableBase: 'researchpowerindex',
    },
  ],
}

export const ResearchPowerIndexPages: INavigationPages<ResearchPowerIndexSlugs> =
  {
    Publications,
    Tweets,
    Persons,
    ResearchInstitutions,
    FundingAgencies,
    CompanyRevenueRanking,
    CountriesByFundingOrgs,
    CountriesByAuthorAffiliationOrgs,
    CountriesByKOLScores,
    CountriesByPrivateSectorCompanyRevenue,
  }
