import { Box, Text as TextChakra } from '@chakra-ui/react'

import { CleanResponseData } from 'api/types'

import formatNumber from 'utils/formatNumber'

type PropTypes = {
  value: CleanResponseData
  format?: string
}

const Text = ({ value, format, ...rest }: PropTypes) => {
  let v = value

  if (format === 'NUMBER' && Number.isSafeInteger(v)) {
    v = formatNumber(v)
  }

  return (
    <Box
      height='100%'
      width='100%'
      overflow='hidden'
      display='flex'
      alignItems='center'
      whiteSpace='nowrap'
      {...rest}
    >
      <TextChakra
        as='p'
        overflow='hidden'
        width='100%'
        paddingY={1}
        paddingRight={2}
        textOverflow='ellipsis'
        display='inline-block'
        fontSize='12px'
        fontWeight={400}
      >
        {v}
      </TextChakra>
    </Box>
  )
}

export default Text
