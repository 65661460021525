const unCamelize = (str: string, separator = ' ') => {
  // Replace all capital letters by separator followed by lowercase one
  str = str.replace(/[A-Z]/g, function (letter) {
    return separator + letter.toLowerCase()
  })
  // Remove first separator
  return str.replace('/^' + separator + '/', '')
}

export default unCamelize
