import { SortOrders } from 'enums/SortOrders'

import { ResearchPowerIndexCountriesModel } from 'config/common/baseModel/researchpowerindex/ResearchPowerIndexCountries'
import { ResearchPowerIndexOrganisationsModel } from 'config/common/baseModel/researchpowerindex/ResearchPowerIndexOrganisations'
import { ResearchPowerIndexPersonModel } from 'config/common/baseModel/researchpowerindex/ResearchPowerIndexPerson'
import { ResearchPowerIndexPublicationsModel } from 'config/common/baseModel/researchpowerindex/ResearchPowerIndexPublications'
import { ResearchPowerIndexTweetsModel } from 'config/common/baseModel/researchpowerindex/ResearchPowerIndexTweets'

import { compose, whiteListColumn } from 'utils/overrideModel'

export const PublicationsModel = compose<
  typeof ResearchPowerIndexPublicationsModel
>(
  whiteListColumn<typeof ResearchPowerIndexPublicationsModel>([
    'title',
    'link',
    'description',
    'afSentiment',
    'publicationDate',
    'linkAfLeadAuthor',
    'linkAfLastAuthor',
    'lookupLeadAuthorHIndex',
    'lookupLastAuthorHIndex',
    'linkJournals',
    'lookupJournalImpactScore',
    'articleViews',
    'pdfDownloads',
    'tweetMentions',
    'citations',
    'ssovFinalQualityLeadLastJifAverageNormPublicationsIncludingReviews',
    'ssovFinalImpactTweetCitationsArticleViewsPdfAverageNormPublications',
    'leadAuthorAffiliation',
    'lastAuthorAffiliation',
    'funding',
    'keywordsClean',
    'countryFromLeadAuthorAffiliation',
    'countryFromLastAuthorAffiliation',
    'countryFromFunding',
  ])
)(ResearchPowerIndexPublicationsModel)

export const TweetsModel = compose<typeof ResearchPowerIndexTweetsModel>(
  whiteListColumn<typeof ResearchPowerIndexTweetsModel>([
    'persons',
    'screenName',
    'createdAt',
    'fullText',
    'favoriteCount',
    'retweetCount',
  ])
)(ResearchPowerIndexTweetsModel)

export const PersonModel = compose<typeof ResearchPowerIndexPersonModel>(
  whiteListColumn<typeof ResearchPowerIndexPersonModel>([
    'afName',
    'kolRankBy2MetricTwitterScore',
    'centralAffiliation',
    'kolTweets',
    'kolTweetQuantityNormalisedMetric2020And2021',
    'kolTweetQualityReNormalisedMetric',
    'kolTweet2MetricQuantityAndQualityScore',
  ])
)(ResearchPowerIndexPersonModel)

export const ResearchInstitutionsModel = compose<
  typeof ResearchPowerIndexOrganisationsModel
>(
  whiteListColumn<typeof ResearchPowerIndexOrganisationsModel>([
    'name',
    'country',
    'researchInstitutionRankBy3MetricPublicationScore',
    'leadAndLastAuthorPublicationLink',
    'normalisedQuantityByOrganisation20202021IncludingReviewsAuthorAffiliation',
    'ssovFinalRenormalisedQualityPublicationAuthorLocAllTimeInclReviews',
    'rpiImpactReNormalisedAuthorAffiliation2020And2021ByOrganisationIncludingReviews',
    'rpi3MetricByOrganisationIncludingReviewsByAuthorAffiliation',
    'countsQuantityAuthorAffiliationAllPapers20202021ByOrganisation',
  ])
)({
  ...ResearchPowerIndexOrganisationsModel,
  defaultSortObject: [
    {
      id: 'rpi3MetricByOrganisationIncludingReviewsByAuthorAffiliation',
      sortOrder: SortOrders.DESC,
    },
  ],
})

export const FundingAgenciesModel = compose<
  typeof ResearchPowerIndexOrganisationsModel
>(
  whiteListColumn<typeof ResearchPowerIndexOrganisationsModel>([
    'name',
    'fundingAgencyRankBy3MetricPublicationScore',
    'country',
    'fundingSource',
    'normalisedQuantityByOrganisation20202021IncludingReviewsFundingSource',
    'ssovFinalReNormalisedQualityPublicationFundingAllTimeInclReviews',
    'rpiImpactReNormalisedFundingSources20202021ByOrganisationIncludingReviews',
    'rpi3MetricByOrganisationIncludingReviewsByFundingSource',
    'counts20202021QuantityFundingSourcesIncludingReviewsByOrganisation',
  ])
)({
  ...ResearchPowerIndexOrganisationsModel,
  defaultSortObject: [
    {
      id: 'rpi3MetricByOrganisationIncludingReviewsByFundingSource',
      sortOrder: SortOrders.DESC,
    },
  ],
})

export const CompanyRevenueRankingModel = compose<
  typeof ResearchPowerIndexOrganisationsModel
>(
  whiteListColumn<typeof ResearchPowerIndexOrganisationsModel>([
    'name',
    'rankByRevenueScore',
    'country',
    'averageRevenue2020And2021',
    'normalisedRevenueScore',
  ])
)({
  ...ResearchPowerIndexOrganisationsModel,
  defaultSortObject: [
    {
      id: 'normalisedRevenueScore',
      sortOrder: SortOrders.DESC,
    },
  ],
})

export const CountriesByFundingOrgsModel = compose<
  typeof ResearchPowerIndexCountriesModel
>(
  whiteListColumn<typeof ResearchPowerIndexCountriesModel>([
    'country',
    'rankBy3MetricPublicationRpiByFundingAgency',
    'fundingSourceLocationsPaperNumbersUniqueValues',
    'ssovNormalisedCount2021And2020FundingBodyIncludingReviews',
    'ssovFinalRenormalisedQualityPublicationFundingLocInclReviews20202021',
    'allTimeSsovFinalImpactReNormalisedPublicationFundingLocInclReviews',
    'threeMetricAverageIncludingReviewsFundingSource2020And2021',
    'publicationCountByFundingSourceIncReviews2021And2020',
  ])
)({
  ...ResearchPowerIndexCountriesModel,
  defaultSortObject: [
    {
      id: 'threeMetricAverageIncludingReviewsFundingSource2020And2021',
      sortOrder: SortOrders.DESC,
    },
  ],
})

export const CountriesByAuthorAffiliationModel = compose<
  typeof ResearchPowerIndexCountriesModel
>(
  whiteListColumn<typeof ResearchPowerIndexCountriesModel>([
    'country',
    'rankBy3MetricPublicationRpiScoresByAuthorAffiliation',
    'leadAndLastAuthorAffiliationCountriesPaperNumbersUniqueValues',
    'publicationCountByAuthorAffiliationIncReviews2021And2020',
    'ssovNormalisedCount2021And2020AuthorAffiliationIncludingReviews',
    'ssovFinalRenormalisedQualityPublicationAuthorLocInclReviews20202021',
    'allTimeSsovFinalImpactReNormalisedPublicationAuthorLocInclReviews',
    'threeMetricAverageIncludingReviewsAuthorAffiliation2020And2021',
  ])
)({
  ...ResearchPowerIndexCountriesModel,
  defaultSortObject: [
    {
      id: 'threeMetricAverageIncludingReviewsAuthorAffiliation2020And2021',
      sortOrder: SortOrders.DESC,
    },
    {
      id: 'ssovFinalRenormalisedQualityPublicationAuthorLocInclReviews20202021',
      sortOrder: SortOrders.DESC,
    },
  ],
})

export const CountriesByKOLScoresModel = compose<
  typeof ResearchPowerIndexCountriesModel
>(
  whiteListColumn<typeof ResearchPowerIndexCountriesModel>([
    'country',
    'rankBy2MetricKolTwitterScore',
    'finalQuantityKol2020And2021RollupFromPersons',
    'normalisedTweetQuantityPerCountry20202021',
    'finalReNormalisedQualityKol2021And2022',
    'qualityAndQualityReNormalisedAverage2020And2021',
  ])
)({
  ...ResearchPowerIndexCountriesModel,
  defaultSortObject: [
    {
      id: 'qualityAndQualityReNormalisedAverage2020And2021',
      sortOrder: SortOrders.DESC,
    },
  ],
})

export const CountriesByPrivateSectorCompanyRevenueModel = compose<
  typeof ResearchPowerIndexCountriesModel
>(
  whiteListColumn<typeof ResearchPowerIndexCountriesModel>([
    'country',
    'rankByRevenueScores',
    'privateSectorCompanies',
    'reNormalisedRevenueRpiScores',
  ])
)({
  ...ResearchPowerIndexCountriesModel,
  defaultSortObject: [
    {
      id: 'reNormalisedRevenueRpiScores',
      sortOrder: SortOrders.DESC,
    },
  ],
})
