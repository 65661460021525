import { ISingleCustomPage } from 'interfaces/navigationPage.interface'

import { InternalToolingSlugs } from './InternalToolingMenu'
import TableauDemo from './TableauPreview'

const TableauPreview: ISingleCustomPage<InternalToolingSlugs> = {
  path: [''],
  component: () => <TableauDemo />,
}

export const InternalToolingCustomPages: Record<
  string,
  ISingleCustomPage<InternalToolingSlugs>
> = {
  TableauPreview,
}
