import { ChevronDown } from '@carbon/icons-react'
import { Flex, Tooltip, useToken, Text, Box } from '@chakra-ui/react'
import ReactSelect, { StylesConfig } from 'react-select'
import theme from 'theme'

export type SelectOption<T> = { label: string; value: T }

export const Select = ({
  value,
  onChange,
  height,
  isMulti,
  options,
  isSearchable,
  backgroundColor,
  color,
  borderColor,
  placeholder,
  fontSize,
  noBorder,
  menuHeight,
}: {
  value?: any
  isMulti?: boolean
  height?: string
  onChange?: any
  options?: any
  isSearchable?: boolean
  backgroundColor?: string
  color?: string
  borderColor?: string
  fontSize?: string
  placeholder?: string
  noBorder?: boolean
  menuHeight?: string
}) => {
  const primaryColor = useToken('colors', 'primary')

  const customStyles: Partial<StylesConfig> = {
    placeholder: (provided: any, state: any) => ({
      ...provided,
      color: color,
    }),
    menu: (provided: any, state: any) => ({
      ...provided,
      width: 'max-content',
      maxWidth: '400px',
      minWidth: '200px',
      color: state.selectProps.menuColor,
      padding: theme.sizes[1],
      fontSize: fontSize || theme.fontSizes.sm,
      cursor: 'pointer',
      borderRadius: '6px',
      zIndex: 60,
    }),
    menuList: (provided: any, state: any) => ({
      ...provided,
      ...(menuHeight && { maxHeight: `${menuHeight}` }),
      zIndex: 60,
    }),

    option: (provided: any, state: any) => ({
      ...provided,
      borderColor: borderColor ? borderColor : theme.colors.gray[200],
      backgroundColor: backgroundColor
        ? backgroundColor
        : state.isFocused
          ? theme.colors.primary[50]
          : state.isSelected
            ? theme.colors.primary[100]
            : 'white',
      cursor: 'pointer',
      height: 'auto',
      overflow: 'hidden',
      fontSize: fontSize || theme.fontSizes.sm,
      fontWeight: 500,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      color: color ? color : theme.colors.gray[800],
      borderRadius: theme.radii.md,
    }),

    control: (provided: any, state: any) => ({
      ...provided,
      minHeight: height ? height : '32px',
      fontSize: fontSize || theme.fontSizes.sm,
      outline: 'none',
      fontWeight: 500,
      borderColor: borderColor
        ? borderColor
        : state.isFocused
          ? theme.colors.gray[400]
          : theme.colors.gray[200],
      backgroundColor: backgroundColor || 'transparent',
      maxHeight: '200px',
      overflowY: 'auto',
      cursor: 'pointer',
      boxShadow: noBorder ? 'none' : '',
      border: noBorder ? 'none' : '',
    }),

    singleValue: (provided: any, state: any) => {
      const opacity = state.isDisabled ? 0.5 : 1
      const transition = 'opacity 300ms'

      return {
        ...provided,
        opacity,
        transition,
        color: primaryColor,
        fontWeight: 400,
        fontSize: 13,
      }
    },

    valueContainer: (provided) => {
      return { ...provided, paddingLeft: '16px', paddingRight: '16px' }
    },
  }

  return (
    <ReactSelect
      styles={customStyles}
      value={value}
      isMulti={isMulti}
      formatOptionLabel={CustomOption}
      onChange={onChange}
      options={options}
      {...(placeholder ? { placeholder } : {})}
      isSearchable={isSearchable}
      classNamePrefix='react-select'
      components={{
        IndicatorsContainer: () => (
          <Box mr='16px'>
            <ChevronDown size={16} />
          </Box>
        ),
        DownChevron: () => null,
        IndicatorSeparator: () => null,
        ClearIndicator: () => null,
      }}
    />
  )
}

const CustomOption = ({ label }: any) => {
  if (label.length > 50) {
    return (
      <Tooltip label={label}>
        <Text>{label}</Text>
      </Tooltip>
    )
  }
  return (
    <Flex>
      <Text>{label}</Text>
    </Flex>
  )
}
