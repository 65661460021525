import { Launch } from '@carbon/icons-react'
import { Link } from '@chakra-ui/react'
import { Box } from '@chakra-ui/react'

const SmallButton = ({
  children,
  external,
}: {
  external?: any
  children?: any
}) => {
  return (
    <Box
      border='1px solid'
      borderColor='gray.300'
      px={4}
      fontSize='xs'
      height='28px'
      bg='white'
      fontWeight={500}
      cursor='pointer'
      rounded='none'
      lineHeight='none'
      display='inline-flex'
      alignItems='center'
      _hover={{
        bg: 'gray.200',
      }}
    >
      {external !== false && (
        <Box mr={1}>
          <Launch size={16} />
        </Box>
      )}
      {children}
    </Box>
  )
}

const ArticleFooter = ({
  externalLink,
  showAbstract = true,
}: {
  externalLink: string
  showAbstract?: boolean
}) => {
  return (
    <Box display='flex'>
      {showAbstract && (
        <Box mr={2}>
          <SmallButton external={false}>
            <Box>Read abstract</Box>
          </SmallButton>
        </Box>
      )}
      {externalLink && (
        <SmallButton>
          <Link
            as='a'
            href={externalLink}
            target='_blank'
            onClick={(e: any) => e.stopPropagation()}
          >
            Full article
          </Link>
        </SmallButton>
      )}
    </Box>
  )
}

export default ArticleFooter
