import { BoxProps } from '@chakra-ui/react'
import { Fragment } from 'react'
import {
  CellProps,
  Column,
  ColumnInterface,
  ColumnInterfaceBasedOnValue,
  SortByFn,
  UseFiltersColumnOptions,
  UseSortByColumnOptions,
} from 'react-table'

import {
  DefaultColumnFilter,
  FilterComponentPropTypes,
  SelectColumnFilter,
  NumberRangeColumnFilter,
} from 'components/Table/ColumnFilters'

import { IModelField } from 'interfaces/model.interface'
import { ValueType } from 'interfaces/valueType.interface'

import formatValues from './formatValues'
import { sortTypeFromList } from './sort'

export interface InternalColumn<D extends object = {}>
  extends ColumnInterface<D>,
    ColumnInterfaceBasedOnValue<D>,
    UseFiltersColumnOptions<D>,
    UseSortByColumnOptions<D> {}

export type CustomColumn<D extends Object> = Column<D> & {
  style: Partial<BoxProps>
  internalColumnDefinition: IModelField<D>
}

export default function generateColumns<D extends Object>(
  cols: Array<IModelField<D>>
): Array<CustomColumn<D>> {
  return cols.reduce(
    (
      acc: Array<CustomColumn<D>>,
      col: IModelField<D>
    ): Array<CustomColumn<D>> => {
      if (col.showTable === false) {
        return acc
      }

      const {
        label,
        key,
        type,
        field,
        showTable,
        headerStyle,
        ...restOfColumn
        // We do this so that restOfColumn doesn't include anything that isn't part of Column
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      } = col as Omit<IModelField<D>, keyof InternalColumn<D>>

      const columnToRender: CustomColumn<D> = {
        // Default value
        accessor: `${col.field ? col.field : col.key}`,
        id: `${col.field ? col.field : col.key}`,
        Filter: getColumnFilterComponent(type),
        filter: getColumnFilterType(type),
        sortType: !!col.sortOrderObject
          ? sortTypeFromList(col.sortOrderObject)
          : getDefaultSort(type),

        ...restOfColumn,

        // Override values
        Header: col.label || col.key,
        style: {
          // Default value
          textAlign: 'left',
          left: 0,

          ...col.headerStyle,
        },
        Cell: (cell: CellProps<D>) => {
          let { value } = cell
          const { cellFormat, transformValue, width } = col
          return (
            <Fragment>
              {formatValues({
                ...cellFormat,
                type: col.type || ValueType.TEXT,
                value,
                width,
                transformValue,
                fallbackValue: col.fallbackValue,
              })}
            </Fragment>
          )
        },
        internalColumnDefinition: col,
      }
      return [...acc, columnToRender]
    },
    []
  )
}

const getColumnFilterComponent = (
  type?: ValueType
): React.FC<FilterComponentPropTypes<any>> => {
  switch (type) {
    case ValueType.SINGLE:
      return SelectColumnFilter
    case ValueType.NUMBER:
      return NumberRangeColumnFilter
    default:
      return DefaultColumnFilter
  }
}

const getColumnFilterType = (type?: ValueType) => {
  switch (type) {
    case ValueType.DATE:
      return 'date'
    case ValueType.SINGLE:
      return 'includes'
    case ValueType.NUMBER:
      return 'between'
    default:
      return 'fuzzytext'
  }
}

const getDefaultSort = (type?: ValueType): string | SortByFn<any> => {
  switch (type) {
    case ValueType.SINGLE:
      return (a, b, col) => {
        const aRawData = a.values[col]
        const bRawData = b.values[col]

        let aData = aRawData
        let bData = bRawData

        if (Array.isArray(aRawData) && aRawData.length > 0) {
          aData = aRawData[0]?.toUpperCase()
        }
        if (Array.isArray(bRawData) && bRawData.length > 0) {
          bData = bRawData[0]?.toUpperCase()
        }

        if (aData === bData) {
          return 0
        }

        if (aData < bData) {
          return -1
        } else {
          return 1
        }
      }
    default:
      return 'alphanumeric'
  }
}
