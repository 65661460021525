import { Card, Box, Text } from '@chakra-ui/react'
import { dataLayer } from '../Map/map-styles'

const HeatMapLegend = ({ highestNum }: { highestNum: number }) => {
  const stops = dataLayer(highestNum).paint['fill-color'].stops
  const maxValue = stops[stops.length - 1][0]
  const gradientStops = stops
    .map(
      ([value, color]) =>
        `${color} ${((value as number) / (maxValue as number)) * 100}%`
    )
    .join(', ')

  return (
    <Card
      py={2}
      px={3}
      w={'60%'}
      maxW='350px'
      border='1px'
      borderColor={'gray.200'}
      bg='white'
      zIndex={30}
      position={'absolute'}
      bottom={3}
      transform='translateX(-50%)'
      left='50%'
    >
      <Box
        h={5}
        inset={0}
        style={{
          background: `linear-gradient(to right, ${gradientStops})`,
          opacity: dataLayer(highestNum).paint['fill-opacity'],
        }}
      />
      <Box display={'flex'} justifyContent={'space-between'}>
        {stops.map(([value, _], index) => (
          <Box
            key={index}
            display={'flex'}
            flexDir={'column'}
            alignItems={'center'}
          >
            <Text fontSize={'xs'} color='gray.700' fontWeight={'medium'}>
              {index === stops.length - 1 ? `${value}+` : value}
            </Text>
          </Box>
        ))}
      </Box>
    </Card>
  )
}

export default HeatMapLegend
