import { produce } from 'immer'
import { cloneDeep } from 'lodash'

import { IModel, IModelField } from 'interfaces/model.interface'

import {
  ExtractModelData,
  ExtractModelDataUnion,
} from './../interfaces/model.interface'

export function compose<TModel extends IModel<any>>(
  ...fns: ((model: TModel) => TModel)[]
) {
  return (xs: TModel): TModel => fns.reduceRight((v, f) => f(v), xs)
}

export function whiteListColumn<TModel extends IModel<any>>(
  columns: Array<ExtractModelDataUnion<TModel>>
) {
  return (model: TModel) => {
    const newModel = cloneDeep(model)

    const newColumns: IModelField<ExtractModelData<TModel>>[] = columns
      // We do it this way to follow the order of the given columns
      .map((columnName) =>
        model.schema.columns.find((column) => column.key === columnName)
      )
      .filter(
        (x: any): x is IModelField<ExtractModelData<TModel>> => x !== undefined
      )

    newModel.schema.columns = newColumns

    return newModel
  }
}

export function blackListColumn<TModel extends IModel<any>>(
  columns: Array<ExtractModelDataUnion<TModel>>
) {
  return (model: TModel) => {
    const newModel = cloneDeep(model)
    newModel.schema.columns = model.schema.columns.filter(
      (x) => !columns.includes(x.key as any)
    )
    return newModel
  }
}

/**
 * Update the specified attribute of a column with a new value
 */
export function updateColumnAttributes<TModel extends IModel<any>>(
  column: ExtractModelDataUnion<TModel>,
  newObject: Partial<IModelField<any>>
) {
  return (model: TModel) => {
    return produce(model, (newModel) => {
      const index = newModel.schema.columns.findIndex((x) => x.key === column)

      if (index >= 0) {
        newModel.schema.columns[index] = {
          ...newModel.schema.columns[index],
          ...newObject,
        } as Required<IModel<any>>
      }
    })
  }
}

export function renameColumn<TModel extends IModel<any>>(
  column: ExtractModelDataUnion<TModel>,
  newLabel: IModelField<any>['label']
) {
  return updateColumnAttributes(column, { label: newLabel })
}

/**
 * Change the graphType of a column
 */
export function setColumnGraphType<TModel extends IModel<any>>(
  column: ExtractModelDataUnion<TModel>,
  newGraphType: IModelField<any>['graphType']
) {
  return updateColumnAttributes(column, { graphType: newGraphType })
}

/**
 * Add column on specified index
 * If -1, then put on end of array
 */
export function addOrOverrideColumn<TModel extends IModel<any>>(
  column: IModelField<ExtractModelData<TModel>>,
  index: number = -1
) {
  return (model: TModel) => {
    const newModel = cloneDeep(model)

    const filteredColumn = model.schema.columns.filter(
      (x) => x.key !== column.key
    )

    filteredColumn.splice(
      index === -1 ? filteredColumn.length : index,
      0,
      column
    )

    newModel.schema.columns = filteredColumn

    return newModel
  }
}
