import { IApp } from 'interfaces/navigationApp.interface'

import { AccessGroups } from 'enums/AccessGroups'

import { RSVEpidemiologyCustomPages } from './RSVEpidemiologyCustomPages'
import {
  RSVEpidemiologyMenu,
  RSVEpidemiologySlugs,
} from './RSVEpidemiologyMenu'

const RSVEpidemiologyApp: IApp<RSVEpidemiologySlugs> = {
  name: 'Epidemiology',
  slug: 'rsv-epidemiology',
  accessGroups: [AccessGroups.RSVEpidemiology],
  endpoint: 'epidemiology-rsv',
  menu: RSVEpidemiologyMenu,
  pages: {},
  customPages: RSVEpidemiologyCustomPages,
  createdAt: new Date(2022, 1, 7),
  hasReports: false,
  tutorial: [
    {
      url: 'https://airfinity-production.s3.amazonaws.com/public/walkthroughs/rsv-epidemiology.mp4',
      name: 'RSV - Epidemiology',
    },
  ],
}

export default RSVEpidemiologyApp
