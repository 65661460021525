import { Search } from '@carbon/icons-react'
import {
  Input,
  InputProps,
  InputGroup,
  Icon,
  InputLeftElement,
} from '@chakra-ui/react'

import { useGlobalSearch } from 'contexts/GlobalSearch'

import useTracking from 'tracking/useTracking'

export default (props: InputProps) => {
  const { globalSearchString, setGlobalSearchString } = useGlobalSearch()
  const [tracking] = useTracking()

  return (
    <InputGroup>
      <InputLeftElement height='100%' w='54px' pl='22px' pr='12px'>
        <Icon as={Search} boxSize='20px' height='100%' color='gray.600' />
      </InputLeftElement>
      <Input
        autoFocus
        pl='54px'
        placeholder='Search...'
        value={globalSearchString}
        border='1px'
        borderColor='gray3'
        shadow='sm'
        height='62px'
        fontSize='21px'
        fontWeight={500}
        backgroundColor='gray4'
        borderRadius='6px'
        _focus={{
          border: 'none',
          shadow: 'none',
        }}
        _placeholder={{
          color: 'gray.600',
        }}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setGlobalSearchString(e.target.value)
        }}
        onBlur={() =>
          globalSearchString &&
          tracking.globalSearch({ searchValue: globalSearchString })
        }
        color='black'
        data-cy='global-search-input'
        {...props}
      />
    </InputGroup>
  )
}
