import { UseQueryOptions, useQuery } from '@tanstack/react-query'
import { AxiosError, AxiosInstance } from 'axios'

import { tableauMapping } from 'components/Embed/tableauSearchIndex'

import { appsListUnion } from 'config/apps'

import { recursiveCamelCase } from './helper'
import { TableauSearchType } from './types'
import useAxios from './useAxios'

export default function useSearchTableau(
  app: appsListUnion,
  searchString: string,
  extraProps?: UseQueryOptions<any, any>
) {
  const axios = useAxios()

  return useQuery<TableauSearchType[], AxiosError>(
    ['searchTableau', app, searchString],
    () => searchTableau(axios, app, searchString),
    { enabled: searchString !== '', ...extraProps }
  )
}

export const searchTableau = async (
  axios: AxiosInstance,
  app: appsListUnion,
  searchString: string
): Promise<TableauSearchType[]> => {
  const res = axios
    .get(`/tableau-search/?search_term=${searchString}`)
    .then((res) =>
      (recursiveCamelCase(res.data) as TableauSearchType[]).filter((x) =>
        tableauMapping.find((mapping) => {
          return mapping.tableauPath === x.url && mapping.app === app
        })
      )
    )
    .catch((err) => {
      console.error('Tableau search', err)
      return []
    })
  return res
}
