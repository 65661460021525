import { UseQueryOptions, useQueries, useQuery } from '@tanstack/react-query'
import { AxiosError, AxiosInstance } from 'axios'

import { apps, appsListUnion } from 'config/apps'

import { recursiveCamelCase } from '../../helper'
import useAxios from '../../useAxios'
import { Report } from './types'

type FetchReportsQueries = {
  categoryName?: string
  categoryType?: string
  searchText?: string
}

export default function useReports(
  app: appsListUnion,
  paths: string[],
  queries?: FetchReportsQueries,
  extraProps?: UseQueryOptions<any, any>,
  endpoint?: string
) {
  const axios = useAxios()
  const res = useQueries({
    queries: paths.map<UseQueryOptions<Report[], AxiosError>>((newSlug) => {
      return {
        queryKey: ['reports', app, queries, newSlug],
        queryFn: () => fetchReports(axios, app, queries, newSlug, endpoint),
        ...extraProps,
        enabled: !!app,
      }
    }),
  })
  return res
}
export function useReportById(app: appsListUnion, id: string) {
  const axios = useAxios()

  return useQuery<Report, AxiosError>(
    ['collectionDataSingleRaw', app, id],
    () => fetchReportById(axios, app, id)
  )
}

export const fetchReportById = async (
  axios: AxiosInstance,
  app: appsListUnion,
  id: string
): Promise<Report> => {
  const appEndpoint = apps[app].endpoint

  const reports = axios
    .get(`/${appEndpoint}/analyst-updates-hidden/${id}`, {})
    .then((res) => recursiveCamelCase(res.data))

  return reports
}
export const fetchReports = async (
  axios: AxiosInstance,
  app: appsListUnion,
  queries?: FetchReportsQueries,
  newSlug?: string,
  endpoint?: string
): Promise<Report[]> => {
  const appEndpoint = endpoint || apps[app].endpoint

  const reports = axios
    .get(`/${appEndpoint}/analyst-${newSlug}/`, {
      params: {
        ...(queries?.categoryName !== ''
          ? { category_name: queries?.categoryName }
          : {}),
        ...(queries?.categoryType !== ''
          ? { category_type: queries?.categoryType }
          : {}),
        ...(queries?.searchText !== ''
          ? { search_text: queries?.searchText }
          : {}),
      },
    })
    .then((res) => recursiveCamelCase(res.data))

  return reports
}
