import { Heading, Text, Box } from '@chakra-ui/react'
import * as Sentry from '@sentry/react'
import { useEffect } from 'react'

export type FullPageErrorPropTypes = {
  error?: any
}

export const FullPageError = ({ error }: FullPageErrorPropTypes) => {
  useEffect(() => {
    Sentry.captureException(error)
  }, [error])

  return (
    <Box p={16} textAlign='center' data-cy='error'>
      <Heading mb={2} fontSize='2xl'>
        Sorry, something has gone wrong.
      </Heading>
      <Text maxW='lg' mx='auto'>
        We've been notified. If you see this error again, please get in touch
        with us at support@airfinity.com.
      </Text>
    </Box>
  )
}
