import { IModel } from 'interfaces/model.interface'
import { ValueType } from 'interfaces/valueType.interface'

import { SortOrders } from 'enums/SortOrders'

import ArticleTypes from '../../tagColours/ArticleTypes'
import AuthorTypes from '../../tagColours/AuthorTypes'
import ReviewStatus from '../../tagColours/ReviewStatus'
import Sentiments from '../../tagColours/Sentiments'

export type IDTreatments360PublicationsData =
  | 'number'
  | 'title'
  | 'publicationDate'
  | 'sentiment'
  | 'articleType'
  | 'typeFromCandidateList'
  | 'linkAfTrUniqueTreatmentNameToBnfFromCandidateList'
  | 'afIndependent'
  | 'automatedTopicsCategory'
  | 'targetTreatments'
  | 'topics'
  | 'journals'
  | 'journalImpactScore'
  | 'authorType'
  | 'linkAfLeadAuthor'
  | 'linkAfLastAuthor'
  | 'lookupLeadAuthorHIndex'
  | 'lookupLastAuthorHIndex'
  | 'luPathogenFamily'
  | 'fundingBody'
  | 'countryFromFundingBody'
  | 'countryFromResearchInstitute'
  | 'reviewStatus'
  | 'afTrTrialDataType'
  | 'afTrTrialDataSize'
  | 'stageOfIntervention'
  | 'articleViews'
  | 'pdfDownloads'
  | 'tweetMentions'
  | 'socialScore'
  | 'automatedTopicsSubCategory'
  | 'indicationLinkInfectiousDisease'
  | 'researchInstitute'
  | 'luAntiviralClass'
  | 'articleLink'

const allowedAirtables = [
  'API_[PAD]_All',
  'API_[PAD]_Coronaviridae',
  'API_[PAD]_Paramyxoviridae',
  'API_[PAD]_Orthomyxoviridae',
] as const

export const IDTreatments360PublicationsModel: IModel<
  Record<IDTreatments360PublicationsData, string>,
  typeof allowedAirtables
> = {
  allowedAirtables,
  displayKey: 'title',
  name: 'Candidate Papers',
  entityName: 'Presentations',
  searchField: 'title',
  endpoint: 'publication',
  defaultSortKey: 'publicationDate',
  defaultSortOrder: SortOrders.DESC,
  detailViewType: 'Article',
  detailView: {
    link: 'articleLink',
    abstract: 'abstract',
  },
  noPrint: true,
  schema: {
    columns: [
      {
        key: 'title',
        label: 'Title',
        width: 450,
      },

      {
        key: 'publicationDate',
        label: 'Publication date',
        type: ValueType.DATE,
        width: 150,
      },

      {
        key: 'sentiment',
        label: 'Sentiment',
        width: 120,
        type: ValueType.SINGLE,
        cellFormat: { colours: Sentiments },
      },

      {
        key: 'topics',
        width: 230,
        type: ValueType.MULTI,
        label: 'Topics',
      },

      {
        key: 'authorType',
        type: ValueType.SINGLE,
        label: 'Author type',
        showDetail: false,
        cellFormat: {
          colours: AuthorTypes,
        },
      },
      {
        key: 'reviewStatus',
        type: ValueType.SINGLE,
        width: 160,
        cellFormat: { colours: ReviewStatus },
        label: 'Review status',
      },
      {
        key: 'articleType',
        type: ValueType.MULTI,
        width: 300,
        cellFormat: { colours: ArticleTypes },
        label: 'Article type',
      },
      {
        key: 'articleViews',
        width: 120,
        headerStyle: { textAlign: 'right' },
        label: 'Views',
        cellFormat: {
          format: 'NUMBER',
        },
        type: ValueType.TEXT,
      },
      {
        key: 'pdfDownloads',
        label: 'PDF downloads',
        width: 120,
        headerStyle: { textAlign: 'right' },
        cellFormat: { format: 'NUMBER' },
        type: ValueType.TEXT,
      },
      {
        key: 'journalImpactScore',
        width: 160,
        label: 'Journal impact score',
        headerStyle: { textAlign: 'right' },
        cellFormat: { format: 'NUMBER' },
        type: ValueType.TEXT,
      },
      {
        key: 'tweetMentions',
        width: 120,
        headerStyle: { textAlign: 'right' },
        label: 'Tweets',
        cellFormat: { format: 'NUMBER' },
        type: ValueType.TEXT,
      },
      {
        key: 'socialScore',
        label: 'Social score',
        showDetail: false,
        width: 120,
        headerStyle: { textAlign: 'right' },
        cellFormat: { format: 'NUMBER' },
        type: ValueType.TEXT,
      },

      {
        key: 'journals',
        label: 'Journal',
        type: ValueType.MULTI,
        width: 300,
      },
      {
        key: 'stageOfIntervention',
        label: 'Stage of Intervention',
        type: ValueType.MULTI,
        width: 200,
      },
      {
        key: 'afTrTrialDataType',
        label: 'Trial Data Type',
        type: ValueType.SINGLE,
        width: 200,
      },

      {
        key: 'afTrTrialDataSize',
        label: 'Trial Data Size',
        type: ValueType.NUMBER,
        width: 120,
      },

      {
        key: 'linkAfTrUniqueTreatmentNameToBnfFromCandidateList',
        label: 'Candidates',
        type: ValueType.MULTI,
        width: 250,
      },

      {
        key: 'automatedTopicsCategory',
        label: 'Topics',
        width: 200,
        type: ValueType.MULTI,
      },
      {
        key: 'automatedTopicsSubCategory',
        label: 'Subtopics',
        width: 200,
        type: ValueType.MULTI,
      },

      {
        key: 'linkAfLeadAuthor',
        label: 'Lead Author',
        width: 170,
        type: ValueType.MULTI,
      },
      {
        key: 'linkAfLastAuthor',
        label: 'Last Author',
        width: 170,
        type: ValueType.MULTI,
      },

      {
        key: 'typeFromCandidateList',
        label: 'Treatment Type',
        width: 150,
        type: ValueType.MULTI,
      },

      {
        key: 'fundingBody',
        label: 'Funding Body',
        width: 150,
        type: ValueType.MULTI,
      },
      {
        key: 'countryFromFundingBody',
        label: 'Funding Body Country',
        width: 310,
        type: ValueType.MULTI,
      },

      {
        key: 'countryFromResearchInstitute',
        label: 'Research Institute Country',
        width: 180,
        type: ValueType.MULTI,
      },
      {
        key: 'targetTreatments',
        label: 'Target (Treatments)',
        width: 180,
        type: ValueType.MULTI,
      },
      {
        key: 'number',
        label: 'Number',
        width: 180,
        type: ValueType.NUMBER,
      },
      {
        key: 'afIndependent',
        label: 'Af Independent',
        width: 180,
        type: ValueType.MULTI,
      },
      {
        key: 'lookupLeadAuthorHIndex',
        label: 'Lead Author H-Index',
        width: 180,
        type: ValueType.NUMBER,
      },
      {
        key: 'luPathogenFamily',
        label: 'Pathogen Family',
        width: 180,
        type: ValueType.MULTI,
      },
      {
        key: 'indicationLinkInfectiousDisease',
        label: 'Disease',
        width: 180,
        type: ValueType.MULTI,
      },
      {
        key: 'researchInstitute',
        label: 'Research Institute',
        width: 200,
        type: ValueType.MULTI,
      },
      {
        key: 'luAntiviralClass',
        label: 'Antiviral Class',
        width: 180,
        type: ValueType.MULTI,
      },
      {
        key: 'articleLink',
        label: 'Article Link',
        width: 180,
        type: ValueType.URL,
      },
    ],
  },
}
