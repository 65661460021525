import TabbedDisplay from 'modules/TabbedDisplay'

import { TableauEmbed } from 'components'

import { ISingleCustomPage } from 'interfaces/navigationPage.interface'

import { BioriskCustomPages } from 'config/apps/Biorisk/Custom/Biorisk/BioriskCustomPages'

import { HealthSecurityCustomPages } from '../HealthSecurityCustomPages'
import { HealthSecurityPremiumSlugs } from './HealthSecurityPremiumMenu'

export const HealthSecurityPremiumCustomPages: Record<
  string,
  ISingleCustomPage<HealthSecurityPremiumSlugs>
> = {
  EpidData: { ...HealthSecurityCustomPages.EpidData, path: [''] },

  RestrictionsRisk: {
    ...BioriskCustomPages.RestrictionsRisk,
    path: ['policies', 'restrictions-risk'],
  },

  VariantsVirologyPrevalenceTrends: {
    component: () => (
      <TabbedDisplay
        tabs={[
          {
            heading: 'Global Map',
            body: (
              <TableauEmbed
                path='/Pandemic_VariantDashboards/GlobalMap'
                height={'calc(100vh - 150px)'}
              />
            ),
          },
          {
            heading: 'Proportion of Cases',
            body: (
              <TableauEmbed path='/Pandemic_VariantDashboards/Proportionofcases' />
            ),
          },
        ]}
      />
    ),

    path: ['spread-of-variants'],
  },

  Reports: { ...BioriskCustomPages.Reports, path: ['reports'] },
}
