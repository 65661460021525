import { Box, BoxProps, Text } from '@chakra-ui/react'

import { ThreeDotsLoadingAnimation } from './ThreeDotsLoadingAnimation'

export type FullPageLoadingPropTypes = {
  text?: string
  boxProps?: BoxProps
}

export const FullPageLoading = ({
  text = 'Loading Data',
  boxProps,
}: FullPageLoadingPropTypes) => {
  return (
    <Box
      d='flex'
      justifyContent='center'
      alignItems='center'
      height='100%'
      flexDir='column'
      {...boxProps}
    >
      <ThreeDotsLoadingAnimation />
      <Text as='span' fontSize='md' mt='1rem'>
        {text}...
      </Text>
    </Box>
  )
}
