import { Box, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'

import { useAppRoute } from 'routes/utils'

import {
  EntitySearchType,
  ReportSearchType,
  TableauSearchType,
} from 'api/types'

import { IModel } from 'interfaces/model.interface'
import { MenuWithMetaData } from 'interfaces/navigationMenu.interface'
import { ISingleNavigationPage } from 'interfaces/navigationPage.interface'

import EntitySection from './EntitySection'
import NewPage from './NewPage'
import SearchReports from './SearchReports'
import SearchTableau from './SearchTableau'

interface Props {
  pageSectionToRender: ISingleNavigationPage<any, IModel<any, any>>[]
  data: EntitySearchType[]
  searchString: string
  newPageData: MenuWithMetaData[]
  reportSearchData?: ReportSearchType[] | never[]
  tableauSearchData?: TableauSearchType[]
}

export const SearchResults: React.FC<Props> = ({
  reportSearchData,
  tableauSearchData,
  pageSectionToRender,
  data,
  searchString,
  newPageData,
}) => {
  const selectedApp = useAppRoute()
  return (
    <Tabs>
      <TabList>
        {reportSearchData && reportSearchData.length > 0 && (
          <Tab
            _selected={{
              borderColor: 'transparent',
              fontWeight: 600,
            }}
          >
            Reports
          </Tab>
        )}
        {tableauSearchData && tableauSearchData.length > 0 && (
          <Tab
            _selected={{
              borderColor: 'transparent',
              fontWeight: 600,
            }}
          >
            Charts
          </Tab>
        )}
        {pageSectionToRender.map((page, i) => {
          const filteredData = data.filter(
            (x) => x.endpoint === page!.model.endpoint
          )
          if (filteredData.length === 0) return null
          return (
            <Tab
              _selected={{
                borderColor: 'transparent',
                fontWeight: 600,
              }}
              key={i}
            >
              {page.model.entityName}
            </Tab>
          )
        })}
      </TabList>

      <TabPanels>
        {reportSearchData && reportSearchData.length > 0 && (
          <TabPanel
            display={newPageData.length > 0 ? 'flex' : 'block'}
            justifyContent='space-between'
          >
            <SearchReports
              reportSearchData={reportSearchData}
              searchString={searchString}
            />
            {newPageData.length > 0 && (
              <Box maxW='370px' w='100%'>
                <NewPage newPageData={newPageData} />
              </Box>
            )}
          </TabPanel>
        )}
        {tableauSearchData && tableauSearchData.length > 0 && (
          <TabPanel
            display={newPageData.length > 0 ? 'flex' : 'block'}
            justifyContent='space-between'
          >
            <SearchTableau
              tableauSearchData={tableauSearchData}
              searchString={searchString}
            />
            {newPageData.length > 0 && (
              <Box maxW='370px' w='100%'>
                <NewPage newPageData={newPageData} />
              </Box>
            )}
          </TabPanel>
        )}
        {pageSectionToRender.map((page, i) => {
          const filteredData = data.filter(
            (x) => x.endpoint === page!.model.endpoint
          )
          if (filteredData.length === 0) return null
          return (
            <TabPanel
              key={i}
              display={newPageData.length > 0 ? 'flex' : 'block'}
              justifyContent='space-between'
            >
              <EntitySection
                app={selectedApp}
                page={page!}
                data={filteredData}
                searchString={searchString}
              />
              {newPageData.length > 0 && (
                <Box maxW='370px' w='100%'>
                  <NewPage newPageData={newPageData} />
                </Box>
              )}
            </TabPanel>
          )
        })}
      </TabPanels>
    </Tabs>
  )
}
