export const Logo = () => {
  return (
    <svg id='icon-logo' viewBox='0 0 99 33'>
      <path
        fill='currentColor'
        d='M9.081 15.115v3.603c-0.327 0.108-1.104 0.3-2.473 0.3-0.962 0-3.198-0.195-3.198-2.001 0-0.538 0.158-0.945 0.483-1.244 0.622-0.573 1.839-0.744 3.214-0.744 0.638 0 1.311 0.037 1.974 0.088zM12.397 17.629v-5.046c0-2.674-1.049-5.862-6.043-5.862-3.332 0-5.169 0.95-5.246 0.991l-0.276 0.146 1.048 2.511c0 0 1.529-0.929 4.003-0.929 2.036 0 3.135 0.711 3.135 2.557v0.749c-3.898-0.256-6.474 0.248-7.861 1.534-0.768 0.712-1.157 1.663-1.157 2.827 0 2.717 2.265 4.405 5.912 4.405 3.712 0 6.226-0.696 6.331-0.725l0.264-0.074-0.016-0.274c-0.001-0.017-0.094-1.662-0.094-2.81z'
      ></path>
      <path
        fill='currentColor'
        d='M17.821-0c-1.266 0-2.22 0.874-2.22 2.032 0 1.14 0.925 2.001 2.152 2.001 0.711 0 1.33-0.237 1.745-0.667 0.347-0.361 0.529-0.839 0.51-1.334 0-1.178-0.919-2.032-2.186-2.032z'
      ></path>
      <path fill='currentColor' d='M16.115 21.205h3.41v-14.177h-3.41z'></path>
      <path
        fill='currentColor'
        d='M37.363 6.7c0-2.435 1.192-3.522 3.465-3.522 2.869 0 4.459 0.895 4.459 0.895l1.035-2.494c0 0-1.488-1.158-5.457-1.158-5.687 0-6.881 3.385-6.881 6.224v14.561h3.379v-11.286h6.013v11.286h3.379v-14.177h-9.391v-0.328z'
      ></path>
      <path
        fill='currentColor'
        d='M56.957 6.721c-3.514 0-6.038 0.914-6.144 0.953l-0.224 0.083 0.001 0.239c0 0 0.007 1.633 0.007 3.026v10.183h3.409v-11.276c0.353-0.154 1.368-0.49 3.502-0.49 2.136 0 3.13 1.048 3.13 3.297v8.469h3.41v-8.748c0-5.083-3.789-5.735-7.090-5.735z'
      ></path>
      <path
        fill='currentColor'
        d='M69.502 0.004c-1.263 0-2.216 0.872-2.216 2.028 0 1.139 0.923 1.997 2.149 1.997 0.709 0 1.327-0.236 1.741-0.665 0.347-0.36 0.528-0.838 0.509-1.332 0-1.176-0.917-2.028-2.182-2.028z'
      ></path>
      <path fill='currentColor' d='M67.796 21.205h3.41v-14.177h-3.41z'></path>
      <path
        fill='currentColor'
        d='M29.727 6.721c-3.514 0-6.038 0.914-6.144 0.953l-0.224 0.083 0.001 0.239c0 0 0.006 1.633 0.006 3.026v10.183h3.41v-11.232c1.659-0.483 3.691-0.56 5.251-0.191l0.134-2.889c-0.702-0.115-1.498-0.172-2.434-0.172z'
      ></path>
      <path
        fill='currentColor'
        d='M95.059 7.028l-5.067 9.040c-0.106-0.294-0.212-0.58-0.315-0.849l-3.372-8.186-7.974-0.006-0.019-4.991-3.327 0.806v11.708c0 4.67 2.356 6.939 7.202 6.939h0.341l0.003-3.036h-0.342c-2.739 0-3.857-1.155-3.857-3.987v-4.548h5.715l4.063 9.502-6.997 12.387h3.808l13.944-24.78h-3.807z'
      ></path>
    </svg>
  )
}
