import { IResponseBase } from './../../../api/types'
import { allFilterTypes } from './filters'
import { SingleAdvancedFilter } from './useAdvancedFilters'

export function filterRowFromAdvancedFilters(
  rows: IResponseBase[],
  advancedFilters: Array<SingleAdvancedFilter<any>>
) {
  if (!advancedFilters.length) {
    return rows
  }

  const filteredRows = advancedFilters.reduce(
    (filteredSoFar, { column, type, filterValue }) => {
      if (
        !column ||
        column === '' ||
        !type ||
        type === '' ||
        filterValue === undefined ||
        filterValue === null ||
        filterValue === ''
      )
        return filteredSoFar

      const filterType = allFilterTypes.find(
        (filterType) => filterType.key === type
      )

      if (!filterType) {
        throw new Error('Fatal Error: Filter Type not found')
      }

      if (
        filterType.shouldCalculateFunc &&
        filterType.shouldCalculateFunc(filterValue) === false
      ) {
        return filteredSoFar
      }

      return filterType.func(filteredSoFar, column, filterValue)
    },
    rows
  )

  return filteredRows
}
