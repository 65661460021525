import { IApp } from 'interfaces/navigationApp.interface'

import { AccessGroups } from 'enums/AccessGroups'

import { Covid19CommercialTherapeuticsCustomPages } from './Covid19CommercialTherapeuticsCustomPages'
import {
  Covid19CommercialTherapeuticsMenu,
  Covid19CommercialTherapeuticsSlugs,
} from './Covid19CommercialTherapeuticsMenu'
import { Covid19CommercialTherapeuticsPages } from './Covid19CommercialTherapeuticsPages'

const CovidCommercialTherapeuticsApp: IApp<Covid19CommercialTherapeuticsSlugs> =
  {
    name: 'Therapeutics',
    slug: 'covid-19-commercial-therapeutics',
    commonIdentifier: 'therapeutics',
    accessGroups: [AccessGroups.CovidCommercialTherapeutics],
    endpoint: 'covid-treatments-market-and-production',
    menu: Covid19CommercialTherapeuticsMenu,
    pages: Covid19CommercialTherapeuticsPages,
    customPages: Covid19CommercialTherapeuticsCustomPages,
    disableAnalytics: true,
    createdAt: new Date(2022, 1, 7),
    defaultEntrySlug: ['demand', 'treatment-uptake'],
  }

export default CovidCommercialTherapeuticsApp
