import TabbedDisplay from 'modules/TabbedDisplay'

import FullReportPage from 'routes/common/FullReportPage'
import Dashboard from 'routes/dashboard'
import WidgetComponentArticle from 'routes/dashboard/WidgetComponents/WidgetArticle'

import { TableauEmbed } from 'components'

import { ISingleCustomPage } from 'interfaces/navigationPage.interface'

import { getMediaDefaultWidget } from 'config/common/baseModel/Media'
import { getPressReleasesDefaultWidget } from 'config/common/baseModel/PressReleases'

import { PadSlugs } from './PadMenu'
import { PadPages } from './PadPages'

const DashboardPage: ISingleCustomPage<PadSlugs> = {
  path: '',
  component: () => (
    <Dashboard
      widgetTabs={[
        getMediaDefaultWidget({
          view: PadPages['Media'].views[0],
        }),
        getPressReleasesDefaultWidget({
          view: PadPages['PressReleases'].views[0],
        }),
        {
          heading: 'Candidate Papers',
          body: (
            <WidgetComponentArticle
              model={PadPages['Publications'].model}
              view={PadPages['Publications'].views[0]}
              url='doiUrl'
              title='title'
              date='publicationDate'
            />
          ),
        },
      ]}
    />
  ),
}

const CandidatesPipelinePage: ISingleCustomPage<PadSlugs> = {
  path: ['candidates', 'pipeline'],
  component: () => (
    <TableauEmbed path='/PAD_Pipeline_Analyzer_v2/PP_PAD_Pipeline_AnalyzerDashboard' />
  ),
}

const CandidatesTargetAnalyserPage: ISingleCustomPage<PadSlugs> = {
  path: ['candidates', 'target-analyser'],
  component: () => (
    <TableauEmbed path='/PADPipelineAnalyzer/PADTargetAnalyzerDashboard' />
  ),
}

const RecentTrialsMapPage: ISingleCustomPage<PadSlugs> = {
  path: ['trials', 'recent-trials-map'],
  component: () => <TableauEmbed path='/PAD_CTs/PPPADCTMapDashboard' />,
}
const OverviewPage: ISingleCustomPage<PadSlugs> = {
  path: ['regulatory', 'overview'],
  component: () => (
    <TableauEmbed path='/PAD360Approvals/RegulatoryOverviewDashboard' />
  ),
}

const FundingEfficacyPage: ISingleCustomPage<PadSlugs> = {
  path: ['funding-efficacy'],
  component: () => (
    <TabbedDisplay
      tabs={[
        {
          heading: 'By Country',
          body: (
            <TableauEmbed path='/PAD_FundingEfficacyv2/ResearchPerformance_ScorebyCountryMapDashboard' />
          ),
        },
        {
          heading: 'By Organisation',
          body: (
            <TableauEmbed path='/PAD_FundingEfficacyv2/ResearchPerformancebyOrganisationDashboard' />
          ),
        },
        {
          heading: 'By Author',
          body: (
            <TableauEmbed path='/PAD_FundingEfficacyv2/ResearchPerformancebyAuthors' />
          ),
        },
      ]}
    />
  ),
}

const Reports: ISingleCustomPage<PadSlugs> = {
  path: 'reports',
  component: () => <FullReportPage />,
}

export const PadCustomPages: Record<string, ISingleCustomPage<PadSlugs>> = {
  DashboardPage,
  CandidatesPipelinePage,
  CandidatesTargetAnalyserPage,
  RecentTrialsMapPage,
  Reports,
  OverviewPage,
  FundingEfficacyPage,
}
