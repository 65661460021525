import { IModel } from 'interfaces/model.interface'
import { ValueType } from 'interfaces/valueType.interface'

import { SortOrders } from 'enums/SortOrders'

export type DiagnosticsUpcomingConferencesData =
  | 'conference'
  | 'company'
  | 'date'
  | 'link'

const allowedAirtables = ['api_diag'] as const

export const DiagnosticsUpcomingConferenesModel: IModel<
  Record<DiagnosticsUpcomingConferencesData, string>,
  typeof allowedAirtables
> = {
  allowedAirtables,
  endpoint: 'diag-upcoming-conference',
  name: 'Upcoming Conferences',
  entityName: 'Upcoming Conferences',
  searchField: 'conference',
  detailViewType: 'Generic',
  displayKey: 'conference',
  defaultSortKey: 'date',
  defaultSortOrder: SortOrders.DESC,
  excludeGeneratedCharts: true,
  schema: {
    columns: [
      { label: 'Conference', key: 'conference', width: 500 },
      { label: 'Company', key: 'company', type: ValueType.MULTI, width: 200 },
      {
        label: 'Date',
        key: 'date',
        type: ValueType.DATE,
        width: 150,
      },
      { label: 'Link', key: 'link', type: ValueType.URL, width: 150 },
    ],
  },
}
