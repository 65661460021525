import * as Sentry from '@sentry/react'
import { AxiosInstance } from 'axios'
import { PDFDocument, StandardFonts, rgb, degrees } from 'pdf-lib'

function getPages(pdfDoc: PDFDocument) {
  try {
    return pdfDoc.getPages()
  } catch (err) {
    console.error()
    return null
  }
}

export const loadAndWatermarkPdf = async (
  axios: AxiosInstance,
  url: string,
  watermarkText: string
) => {
  const existingPdfBytes = await axios(url, {
    responseType: 'arraybuffer',
  }).then((res) => res.data)
  const pdfDoc = await PDFDocument.load(existingPdfBytes)
  const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica)

  const fontSize = 14

  const calculatedTextWidth = helveticaFont.widthOfTextAtSize(
    watermarkText,
    fontSize
  )

  const pages = getPages(pdfDoc)
  if (!pages) return null

  const pageSize = pages[0].getSize()
  const textHeight = calculatedTextWidth * Math.sin(35)
  const textWidth = calculatedTextWidth * Math.cos(35)
  const x = pageSize.width / 2 - Math.abs(textWidth / 2)
  // Middle based on text width
  const y = pageSize.height / 2 - Math.abs(textHeight / 1.5)
  pages?.forEach((page, i) => {
    const color = i === 0 ? rgb(1, 1, 1) : rgb(0.1, 0.1, 0.1)
    pages[i].drawText(watermarkText, {
      x: x,
      y: y,
      size: fontSize,
      font: helveticaFont,
      color: color,
      opacity: 0.3,
      rotate: degrees(35),
    })
  })

  return await pdfDoc.save()
}

export const downloadPdfFromBytes = async (
  bytes: Uint8Array | null | undefined,
  fileName: string
) => {
  if (!bytes) return
  const blob = new Blob([bytes])
  // @ts-ignore
  if (navigator.msSaveBlob) {
    // IE 10+
    // @ts-ignore
    navigator.msSaveBlob(blob, fileName)
  } else {
    const link = document.createElement('a')
    // Browsers that support HTML5 download attribute
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob)
      link.setAttribute('href', url)
      link.setAttribute('download', fileName)
      link.style.visibility = 'hidden'
      document.body.appendChild(link)
      link.click()
    }
  }
}

export const getWatermarkText = (userName: string, client: string) => {
  return `This Airfinity report is intended to be used by ${userName} at ${client} exclusively. Not for reproduction or redistribution.`
}

export const getPdfBytes = async (
  axios: AxiosInstance,
  url: string,
  watermarkText: string
) => {
  let newPdfBytes = null
  try {
    newPdfBytes = await loadAndWatermarkPdf(axios, url, watermarkText)
  } catch (e) {
    Sentry.captureException(e)
    console.error(e)
    throw e
  }
  return newPdfBytes
}
