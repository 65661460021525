import { IModel } from 'interfaces/model.interface'
import { ValueType } from 'interfaces/valueType.interface'

import { SortOrders } from 'enums/SortOrders'

import { fourDecimalPlaces } from 'config/common/transformValue/decimal'

export type ResearchPowerIndexPersonData =
  | 'afName'
  | 'afHIndex'
  | 'centralAffiliation'
  | 'kolTweets'
  | 'kolTweetQuantityNormalisedMetric2020And2021'
  | 'kolTweetQualityReNormalisedMetric'
  | 'kolTweet2MetricQuantityAndQualityScore'
  | 'kolRankBy2MetricTwitterScore'

const allowedAirtables = ['api_kol_twitter_scoring_2020_and_2021'] as const

export const ResearchPowerIndexPersonModel: IModel<
  Record<ResearchPowerIndexPersonData, string>,
  typeof allowedAirtables
> = {
  allowedAirtables,
  endpoint: 'person',
  name: 'Person',
  entityName: 'People',
  detailViewType: 'Generic',
  searchField: 'afName',
  displayKey: 'afName',
  defaultSortObject: [
    {
      id: 'kolTweet2MetricQuantityAndQualityScore',
      sortOrder: SortOrders.DESC,
    },
  ],
  schema: {
    columns: [
      { key: 'afName', label: 'KOL Name', width: 200, type: ValueType.TEXT },
      {
        key: 'afHIndex',
        label: 'H-Index',
        width: 200,
        type: ValueType.NUMBER,
      },
      {
        key: 'centralAffiliation',
        label: 'Central Affiliation',
        width: 200,
        type: ValueType.MULTI,
      },
      {
        key: 'kolTweets',
        label: 'KOL Tweets',
        width: 200,
        type: ValueType.MULTI,
      },
      {
        key: 'kolTweetQuantityNormalisedMetric2020And2021',
        label: 'KOL Tweet Quantity Normalised Metric',
        width: 200,
        type: ValueType.TEXT,
        transformValue: fourDecimalPlaces,
      },
      {
        key: 'kolTweetQualityReNormalisedMetric',
        label: 'KOL Tweet Quality Re Normalised Metric',
        width: 200,
        type: ValueType.TEXT,
        transformValue: fourDecimalPlaces,
      },
      {
        key: 'kolTweet2MetricQuantityAndQualityScore',
        label: 'KOL Tweet 2 Metric Quantity And Quality Score',
        width: 200,
        type: ValueType.TEXT,
        transformValue: fourDecimalPlaces,
      },
      {
        key: 'kolRankBy2MetricTwitterScore',
        label: 'Kol rank by 2-metric Twitter Score',
        width: 230,
        type: ValueType.NUMBER,
      },
    ],
  },
}
