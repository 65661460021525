import { INavigationMenu } from 'interfaces/navigationMenu.interface'

export type PredictiveAnalyticsSlugs =
  | ''
  | 'health-economic-assessments'
  | 'excess-comorbid'
  | 'sleep-apnoea'
  | 'hypertension'
  | 'unstable-angina-mi'
  | 'chronic-kidney-disease'
  | 'heart-failure'
  | 'metabolic-steatohepatitis'
  | 'cardiometabolic-endpoints'
  | 'safety-endpoints'
  | 'blood-glucose'
  | 'diastolic-blood-pressure'
  | 'glycated-haemoglobin'
  | 'inflammation'
  | 'systolic-blood-pressure'
  | 'obesity-with-diabetes'
  | 'obesity-without-diabetes'

const PredictiveAnalyticsMenu: Array<
  INavigationMenu<PredictiveAnalyticsSlugs>[]
> = [
  [
    {
      title: 'Key upcoming developments',
      slug: '',
      createdAt: new Date(2023, 4, 1),
    },
    {
      title: 'AOM-mediated weight loss and cardiometabolic endpoints',
      slug: 'cardiometabolic-endpoints',
      createdAt: new Date(2024, 6, 11),
      children: [
        {
          title: 'Blood glucose',
          slug: 'blood-glucose',
          createdAt: new Date(2024, 6, 11),
        },
        {
          title: 'Diastolic blood pressure',
          slug: 'diastolic-blood-pressure',
          createdAt: new Date(2024, 6, 11),
        },
        {
          title: 'Glycated haemoglobin',
          slug: 'glycated-haemoglobin',
          createdAt: new Date(2024, 6, 11),
        },
        {
          title: 'Inflammation',
          slug: 'inflammation',
          createdAt: new Date(2024, 6, 11),
        },
        {
          title: 'Systolic blood pressure',
          slug: 'systolic-blood-pressure',
          createdAt: new Date(2024, 6, 11),
        },
      ],
    },
    {
      title: 'AOM-mediated weight loss and safety endpoints',
      slug: 'safety-endpoints',
      createdAt: new Date(2024, 6, 11),
      children: [
        {
          title: 'Obesity with type 2 diabetes',
          slug: 'obesity-with-diabetes',
          createdAt: new Date(2024, 6, 11),
        },
        {
          title: 'Obesity without type 2 diabetes',
          slug: 'obesity-without-diabetes',
          createdAt: new Date(2024, 6, 11),
        },
      ],
    },
    {
      title: 'Excess comorbid disease burden and impact of AOMs',
      slug: 'excess-comorbid',
      createdAt: new Date(2024, 4, 1),
      children: [
        {
          title: 'Overview',
          slug: '',
          createdAt: new Date(2023, 4, 1),
        },
        {
          title: 'Chronic kidney disease',
          slug: 'chronic-kidney-disease',
          createdAt: new Date(2024, 4, 19),
        },
        {
          title: 'Heart Failure',
          slug: 'heart-failure',
          createdAt: new Date(2024, 4, 24),
        },
        {
          title: 'Hypertension',
          slug: 'hypertension',
          createdAt: new Date(2023, 4, 1),
        },
        {
          title: 'Metabolic dysfunction-associated steatohepatitis',
          slug: 'metabolic-steatohepatitis',
          createdAt: new Date(2023, 6, 26),
        },
        {
          title: 'Obstructive sleep apnoea',
          slug: 'sleep-apnoea',
          createdAt: new Date(2023, 4, 1),
        },
        {
          title: 'Unstable angina & MI',
          slug: 'unstable-angina-mi',
          createdAt: new Date(2024, 4, 19),
        },
      ],
    },
    {
      title: 'Health economic assessments',
      slug: 'health-economic-assessments',
      createdAt: new Date(2023, 4, 1),
      children: [
        {
          title: 'Cost per outcome avoided',
          slug: '',
          createdAt: new Date(2023, 4, 1),
        },
      ],
    },
  ],
]

export { PredictiveAnalyticsMenu }
