import { IModel } from 'interfaces/model.interface'
import { ValueType } from 'interfaces/valueType.interface'

import { SortOrders } from 'enums/SortOrders'

import Sentiments from 'config/common/tagColours/Sentiments'

export type PNHTweetsData =
  | 'createdAt'
  | 'fullText'
  | 'tweetUrl'
  | 'candidateNameFromLinkedCandidateIds'
  | 'persons'
  | 'screenName'
  | 'afTopics'
  | 'afSentiment'
  | 'followersFromPersons'
  | 'retweetCount'
  | 'favoriteCount'
  | 'luHIndex'

const allowedAirtables = ['api_tweets'] as const

export const PNHTweetsModel: IModel<
  Record<PNHTweetsData, string>,
  typeof allowedAirtables
> = {
  allowedAirtables,
  name: 'Tweets',
  entityName: 'Tweets',
  searchField: 'fullText',
  endpoint: 'tweet',
  displayKey: 'screenName',
  detailViewType: 'Generic',
  defaultSortKey: 'createdAt',
  defaultSortOrder: SortOrders.DESC,
  schema: {
    columns: [
      {
        key: 'createdAt',
        label: 'Date',
        width: 150,
        type: ValueType.DATE,
      },
      {
        key: 'fullText',
        label: 'Tweet',
        width: 280,
        type: ValueType.LONG,
        cellFormat: {
          maxLines: 2,
        },
      },
      {
        key: 'tweetUrl',
        label: 'Tweet URL',
        width: 150,
        type: ValueType.URL,
      },
      {
        key: 'candidateNameFromLinkedCandidateIds',
        label: 'Candidate Name',
        width: 150,
        type: ValueType.MULTI,
      },
      {
        key: 'persons',
        label: 'Persons',
        width: 150,
        type: ValueType.MULTI,
      },
      {
        key: 'afTopics',
        label: 'Topics',
        width: 150,
        type: ValueType.MULTI,
      },
      {
        key: 'afSentiment',
        label: 'Sentiment',
        width: 150,
        type: ValueType.MULTI,
        cellFormat: {
          colours: Sentiments,
        },
      },
      {
        key: 'followersFromPersons',
        label: 'Followers',
        width: 150,
        type: ValueType.MULTI,
      },
      {
        key: 'luHIndex',
        label: 'H Index',
        width: 150,
        type: ValueType.MULTI,
      },
    ],
  },
}
