import { Box, useToken, BoxProps } from '@chakra-ui/react'
import { TinyColor } from '@ctrl/tinycolor'
// @ts-ignore
import ContrastColor from 'contrast-color'
import { forwardRef } from 'react'

import rgbHex from 'utils/rgb-hex'

const cc = new ContrastColor({
  defaultColor: '#1f1f1f',
  fgDarkColor: '#1f1f1f',
})

type PropTypes = Omit<BoxProps, 'colors'> & {
  color: string
  isRelational?: boolean
  children?: React.ReactNode
  // Changes the style to border
  showAsOutline?: boolean
}

const chakraColors = [
  'gray',
  'red',
  'orange',
  'yellow',
  'green',
  'teal',
  'blue',
  'cyan',
  'purple',
  'pink',
]

const Tag = forwardRef<HTMLDivElement, PropTypes>((props, ref) => {
  const { children, color, isRelational, showAsOutline, ...rest } = props

  // Resolve chakra color
  const bgColor = chakraColors.includes(color) ? `${color}.100` : color
  const rgbToHexColor = bgColor.includes('rgb')
    ? `#${rgbHex(bgColor)}`
    : bgColor

  // We need the color to be darker if using outline
  const handledOutlineColor =
    showAsOutline &&
    rgbToHexColor.includes('#') &&
    new TinyColor(rgbToHexColor).isLight()
      ? new TinyColor(rgbToHexColor).darken(40).toString()
      : rgbToHexColor

  const tokenColor = useToken('colors', handledOutlineColor)

  const formattedColor = tokenColor ?? handledOutlineColor

  if (isRelational === true) {
    return (
      <Box
        ref={ref}
        px='6px'
        py={'2px'}
        bg='white'
        rounded='none'
        fontSize='12px'
        display='inline-block'
        fontWeight={400}
        border='1px solid'
        borderColor='gray.500'
        boxShadow='0 0 0 1px rgba(00, 00, 00, 0.15), 0 2px 1px 0 rgba(00,00,00,0.125)'
        lineHeight='normal'
        _hover={{
          textDecoration: 'underline',
        }}
        {...rest}
      >
        {props.children}
      </Box>
    )
  }

  return (
    <Box
      ref={ref}
      px='6px'
      py={'2px'}
      rounded='sm'
      display='inline-block'
      fontSize='12px'
      fontWeight={400}
      lineHeight='normal'
      {...(!showAsOutline
        ? {
            bg: formattedColor,
            color: cc.contrastColor({
              bgColor: formattedColor,
            }),
          }
        : {
            bg: 'transparent',
            border: '1px solid',
            borderColor: formattedColor,
            color: formattedColor,
            rounded: 'md',
          })}
      {...rest}
    >
      {props.children}
    </Box>
  )
})

export default Tag
