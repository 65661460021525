import { IModel } from 'interfaces/model.interface'
import { ValueType } from 'interfaces/valueType.interface'

import Phase from 'config/common/tagColours/Phase'

export type RespiratoryTimelineData =
  | 'linkInfectiousDiseaseFromVaxCandidateId'
  | 'vaxCandidateId'
  | 'treatmentCandidateId'
  | 'announcementSource'
  | 'notes'
  | 'startYear'
  | 'endYear'
  | 'startQuarter'
  | 'endQuarter'
  | 'startMonth'
  | 'endMonth'
  | 'manualLatestPhaseFromVaxCandidateId'

const allowedAirtables = ['front_end_timeline_details'] as const

export const RespiratoryTimelineModel: IModel<
  Record<RespiratoryTimelineData, string>,
  typeof allowedAirtables
> = {
  allowedAirtables,
  displayKey: 'notes',
  name: 'Timeline',
  entityName: 'Timeline',
  searchField: 'notes',
  endpoint: 'timeline',
  schema: {
    columns: [
      {
        key: 'linkInfectiousDiseaseFromVaxCandidateId',
        label: 'Infectious Disease',
        width: 200,
        type: ValueType.MULTI,
      },
      {
        key: 'vaxCandidateId',
        label: 'Vaccine Candidate',
        width: 200,
        type: ValueType.MULTI,
      },
      {
        key: 'treatmentCandidateId',
        label: 'Treatment Candidate',
        width: 200,
        type: ValueType.MULTI,
      },
      {
        key: 'announcementSource',
        label: 'Announcement Source',
        width: 120,
        type: ValueType.URL,
      },
      {
        key: 'notes',
        label: 'Notes',
        width: 200,
        type: ValueType.TEXT,
        cellFormat: { boxStyles: { flexDirection: 'column' } },
      },
      {
        key: 'startYear',
        label: 'Start Year',
        width: 200,
        type: ValueType.TEXT,
      },
      { key: 'endYear', label: 'End Year', width: 200, type: ValueType.TEXT },
      {
        key: 'startQuarter',
        label: 'Start Quarter',
        width: 200,
        type: ValueType.TEXT,
      },
      {
        key: 'endQuarter',
        label: 'End Quarter',
        width: 200,
        type: ValueType.TEXT,
      },
      {
        key: 'startMonth',
        label: 'Start Month',
        width: 200,
        type: ValueType.TEXT,
      },
      { key: 'endMonth', label: 'End Month', width: 200, type: ValueType.TEXT },
      {
        key: 'manualLatestPhaseFromVaxCandidateId',
        label: 'Phase',
        width: 170,
        type: ValueType.SINGLE,
        cellFormat: {
          colours: Phase,
        },
        sortOrderObject: [
          'III',
          'II',
          'I/II',
          'I',
          'IV',
          'Approved',
          'Pre-clinical',
          'Discovery',
          'Planned/Announced',
          'Inactive',
          'Terminated',
        ],
      },
    ],
  },
}
