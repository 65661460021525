import { IApp } from 'interfaces/navigationApp.interface'

import { AccessGroups } from 'enums/AccessGroups'

import { InfluenzaCommercialCustomPages } from 'config/apps/Influenza/Commercial/VaccinesTherapeutics/InfluenzaCommercialCustomPages'
import {
  InfluenzaCommercialMenu,
  InfluenzaCommercialSlugs,
} from 'config/apps/Influenza/Commercial/VaccinesTherapeutics/InfluenzaCommercialMenu'
import { InfluenzaCommercialPages } from 'config/apps/Influenza/Commercial/VaccinesTherapeutics/InfluenzaCommercialPages'
import { RespiratoryTimelineModel } from 'config/common/baseModel/respiratory360/RespiratoryTimeline'

const ModernaInfluenzaCommercialApp: IApp<InfluenzaCommercialSlugs> = {
  name: 'Influenza',
  slug: 'moderna-influenza-commercial',
  accessGroups: [AccessGroups.Moderna],
  commonIdentifier: 'influenza',
  endpoint: 'influenza-market-and-production',
  menu: InfluenzaCommercialMenu,
  pages: InfluenzaCommercialPages,
  baseModels: [RespiratoryTimelineModel],
  customPages: InfluenzaCommercialCustomPages,
  createdAt: new Date(2022, 5, 30),
}

export default ModernaInfluenzaCommercialApp
