export default {
  Business: 'rgb(114, 221, 195)',
  'Collaboration/Merger and Acquisition': 'rgb(255, 158, 183)',
  Deals: 'rgb(207, 223, 255)',
  'Flu-Covid Combo': 'rgb(209, 247, 196)',
  Guidance: 'rgb(255, 220, 229)',
  Hesitancy: 'rgb(254, 226, 213)',
  Legal: 'rgb(255, 111, 44)',
  Market: 'rgb(194, 245, 233)',
  Approvals: 'rgb(255, 234, 182)',
  Repurposed: 'rgb(255, 218, 246)',
  'Research and Development': 'rgb(6, 160, 155)',
  Revenue: 'rgb(207, 223, 255)',
  Schedule: 'rgb(255, 220, 229)',
  Stockpiles: 'rgb(208, 240, 253)',
  Technology: 'rgb(238, 238, 238)',
  Trials: 'rgb(178, 21, 139)',
  Uptake: 'rgb(237, 226, 254)',
  Forecast: 'rgb(139, 70, 255)',
  'Adaptive Immune Responses': 'rgb(45, 127, 249)',
  Adjuvants: 'rgb(194, 245, 233)',
  'Antibody Responses': 'rgb(238, 238, 238)',
  Antigens: 'rgb(139, 70, 255)',
  Antivirals: 'rgb(39, 80, 174)',
  'Attenuated Viruses': 'rgb(24, 191, 255)',
  Autoimmunity: 'rgb(119, 209, 243)',
  'B Cell Responses': 'rgb(208, 240, 253)',
  'Bacterial Vectors': 'rgb(11, 118, 183)',
  Biomarkers: 'rgb(51, 138, 23)',
  'Cell Lines': 'rgb(255, 218, 246)',
  'Child Mortality': 'rgb(255, 111, 44)',
  Children: 'rgb(255, 234, 182)',
  'Combined Treatments': 'rgb(178, 21, 139)',
  'Comparing Effectiveness': 'rgb(208, 240, 253)',
  'Cross-Protection': 'rgb(248, 43, 96)',
  'Current Treatments': 'rgb(68, 68, 68)',
  'Current Vaccines': 'rgb(39, 80, 174)',
  'Delivery Efficiency': 'rgb(68, 68, 68)',
  Detection: 'rgb(156, 199, 255)',
  'Disease Monitoring': 'rgb(32, 201, 51)',
  'Drug Resistance': 'rgb(68, 68, 68)',
  'Enterococcus faecium L3': 'rgb(102, 102, 102)',
  'Evaluation of VLP-based Vaccines': 'rgb(186, 30, 69)',
  'Healthcare Worker (HCWs)': 'rgb(147, 224, 136)',
  'Herbalism and Chinese Traditional Medicine (CTM)': 'rgb(254, 226, 213)',
  'HSCT Patients': 'rgb(11, 118, 183)',
  HSV: 'rgb(119, 209, 243)',
  'Immune System Development': 'rgb(255, 234, 182)',
  'Immunocompromised Individuals': 'rgb(204, 204, 204)',
  Immunogenicity: 'rgb(119, 209, 243)',
  'Immunogenicity Enhancement': 'rgb(45, 127, 249)',
  Immunoprophylaxis: 'rgb(255, 218, 246)',
  'Impact of Maternal Vaccination': 'rgb(215, 77, 38)',
  'Infection Mimicry': 'rgb(248, 43, 96)',
  'Inflammatory Reactions': 'rgb(147, 224, 136)',
  'Inflammatory Response': 'rgb(114, 221, 195)',
  'Influenza Subtypes': 'rgb(205, 176, 255)',
  'Innate Immune Responses': 'rgb(204, 204, 204)',
  'Intranasal Treatments': 'rgb(248, 43, 96)',
  'Leprosy Patients': 'rgb(194, 245, 233)',
  'Level of Protection': 'rgb(45, 127, 249)',
  'Meta Analysis': 'rgb(255, 214, 110)',
  Microbiota: 'rgb(114, 221, 195)',
  'Mouse Model': 'rgb(119, 209, 243)',
  'Myocardial infarction': 'rgb(32, 217, 210)',
  'NA Vaccines': 'rgb(184, 117, 3)',
  'Nasal Bacteria': 'rgb(255, 169, 129)',
  Newborns: 'rgb(255, 158, 183)',
  'Older adults': 'rgb(139, 70, 255)',
  Pharmacovigilance: 'rgb(237, 226, 254)',
  'Porcine Model': 'rgb(209, 247, 196)',
  'Pregnant Women': 'rgb(204, 204, 204)',
  'Preventable Deaths': 'rgb(178, 21, 139)',
  'Prevention Against Influenza': 'rgb(102, 102, 102)',
  'Prevention Against SARS-CoV-2': 'rgb(186, 30, 69)',
  'Recombinant Vaccines': 'rgb(68, 68, 68)',
  'Risk Monitoring': 'rgb(237, 226, 254)',
  'Safety Surveillance': 'rgb(255, 8, 194)',
  'Structural Analysis': 'rgb(254, 226, 213)',
  'Structural Analysis of Mutants': 'rgb(39, 80, 174)',
  'T Cell Responses': 'rgb(51, 138, 23)',
  'Transient Autoimmunity': 'rgb(208, 240, 253)',
  'Treatment protocols': 'rgb(255, 218, 246)',
  'Treatments in Development': 'rgb(255, 111, 44)',
  Underutilisation: 'rgb(255, 220, 229)',
  'Unusable publication': 'rgb(186, 30, 69)',
  'Vaccination Program': 'rgb(204, 204, 204)',
  'Vaccination Records': 'rgb(249, 157, 226)',
  'Vaccine Acceptance': 'rgb(208, 240, 253)',
  'Vaccine Effectiveness': 'rgb(255, 111, 44)',
  'Vaccine improvement': 'rgb(51, 138, 23)',
  'Vaccine Knowledge': 'rgb(32, 201, 51)',
  'Vaccine Misapplication': 'rgb(255, 169, 129)',
  'Vaccine Practices': 'rgb(208, 240, 253)',
  'Vaccines in Development': 'rgb(255, 111, 44)',
  'Vulnerable Populations': 'rgb(255, 8, 194)',
  'Willing to Administer Vaccine': 'rgb(254, 226, 213)',
  'Would Recommend Vaccine': 'rgb(184, 117, 3)',
  'Antigenic Drift': 'rgb(204, 204, 204)',
  'Universal Vaccines': 'rgb(205, 176, 255)',
  'Preclinical Model': 'rgb(32, 217, 210)',
  'Vaccine Targets': 'rgb(11, 118, 183)',
  'Animal Models': 'rgb(255, 111, 44)',
  'Plant-derived Vaccines': 'rgb(204, 204, 204)',
  'Egg-derived Vaccines': 'rgb(156, 199, 255)',
  'Medical Students': 'rgb(255, 218, 246)',
  'Vaccine Coverage': 'rgb(252, 180, 0)',
  'Cytokine Response': 'rgb(39, 80, 174)',
  'Cell-mediated Immunity': 'rgb(107, 28, 176)',
  Interferon: 'rgb(68, 68, 68)',
  'Vaccine Design': 'rgb(6, 160, 155)',
  'Immune Senescence': 'rgb(238, 238, 238)',
  'Live Vaccine Vectors': 'rgb(39, 80, 174)',
  'Risk Perception': 'rgb(215, 77, 38)',
  'Vaccine Improvement': 'rgb(255, 158, 183)',
  'Partial Vaccination': 'rgb(24, 191, 255)',
  'Intradermal Vaccinations': 'rgb(255, 8, 194)',
  'Gene Delivery': 'rgb(24, 191, 255)',
  'Ex-vivo Model': 'rgb(51, 138, 23)',
  'Drug Repositioning': 'rgb(186, 30, 69)',
  'Immune Memory': 'rgb(248, 43, 96)',
  Variants: 'rgb(68, 68, 68)',
  'Cross-Reactivity': 'rgb(68, 68, 68)',
  'Vaccine efficacy ': 'rgb(207, 223, 255)',
  'Viral Inactivation': 'rgb(207, 223, 255)',
  'Pediatric Vaccination': 'rgb(208, 240, 253)',
  'KAP ': 'rgb(194, 245, 233)',
  'Vacine Coverage Rate ( VCR)': 'rgb(209, 247, 196)',
  'Virus Detection ': 'rgb(255, 234, 182)',
  'Viral Variats': 'rgb(254, 226, 213)',
  Infants: 'rgb(255, 220, 229)',
  'Vaccine  Route': 'rgb(255, 218, 246)',
  'Vaccine Uptake': 'rgb(207, 223, 255)',
}
