import { UseQueryOptions, useQuery } from '@tanstack/react-query'
import { AxiosError, AxiosInstance } from 'axios'
import { useContext } from 'react'

import { AuthContext } from 'contexts'

import { IBaseView } from 'interfaces/navigationPage.interface'

import { apps, appsListUnion } from 'config/apps'

import { relationGetDisplayValue } from 'utils/relational'

import { queryBuilder, recursiveCamelCase } from './helper'
import { ICleanResponse, IResponseBase } from './types'
import useAxios from './useAxios'

export type FetchSingleQueries = Partial<{
  airtableBase: IBaseView<any>['airtableBase']
  view: string | null
  fields: string[] | null
}>

export function useCollectionDataSingleRaw(
  app: appsListUnion,
  collection: string,
  id: string,
  queries: FetchSingleQueries,
  extraProps?: UseQueryOptions<any, any>
) {
  const axios = useAxios()

  const { userInfo } = useContext(AuthContext)
  const selectedApp = apps[app as appsListUnion]
  const premiumGroup = selectedApp.premiumGroup || []
  const premiumAccess = userInfo?.groups.includes(premiumGroup[0])
  const endpoint = premiumAccess
    ? apps[app].premiumSlug || apps[app].endpoint
    : apps[app].endpoint

  return useQuery<IResponseBase<any>, AxiosError>(
    ['collectionDataSingleRaw', app, collection, id, queries],
    () => {
      return fetchSingleRaw(axios, app, collection, id, queries, endpoint)
    },
    extraProps
  )
}

export async function fetchSingleRaw(
  axios: AxiosInstance,
  app: appsListUnion,
  collection: string,
  id: string,
  queries: FetchSingleQueries,
  endpoint?: string
): Promise<IResponseBase<any>> {
  const appEndpoint = endpoint || apps[app].endpoint
  const { airtableBase, ...queryStringObj } = queries
  const query = queryBuilder(queryStringObj)

  const res = await axios(
    `${airtableBase ?? 'covid'}/${appEndpoint}/${collection}/${id}/?${query}`
  )

  return recursiveCamelCase(res.data)
}

// Map relational data so we don't have to deal with it everywhere
export async function fetchSingle(
  axios: AxiosInstance,
  app: appsListUnion,
  collection: string,
  id: string,
  queries: FetchSingleQueries
): Promise<ICleanResponse<any>> {
  const data = await fetchSingleRaw(axios, app, collection, id, queries)

  return {
    id: data.id,
    ...Object.fromEntries(
      Object.entries(data).map(([key, val]) => [
        key,
        relationGetDisplayValue(val),
      ])
    ),
  }
}
