import { ChevronDown, ChevronUp } from '@carbon/icons-react'
import {
  Box,
  Button,
  Collapse,
  Flex,
  HStack,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  VStack,
  Wrap,
} from '@chakra-ui/react'
import { isToday } from 'date-fns'
import urlParse from 'url-parse'

import { formatDate } from 'utils/formatDate'

import { IEGPolicySurveillanceAPIData } from './types'

export type RegulatoryDashboardCardPropTypes = IEGPolicySurveillanceAPIData & {
  showMetadata: boolean
  setShowMetadata: React.Dispatch<React.SetStateAction<boolean>>
}

export const RegulatoryDashboardCard = ({
  category,
  sourceUrl,
  alpha2CountryCodes,
  date,
  title,
  content,
  finalContent,
  companies,
  metadata,
  showMetadata,
  setShowMetadata,
}: RegulatoryDashboardCardPropTypes) => {
  const { isOpen, onClose, onOpen } = useDisclosure()

  const displayMetadata =
    metadata.tags.length > 0 || metadata.keywords.length > 0

  return (
    <HStack
      w='100%'
      bgColor='white'
      alignItems='flex-start'
      p='24px'
      spacing={6}
      border='1px #E5E5E5 solid'
      boxShadow='-2px 2px 15px 0px #0000000D'
      borderRadius='sm'
    >
      <VStack alignItems='flex-start' minW='120px' w='120px' overflow='hidden'>
        <VStack alignItems='flex-start' spacing='16px' width='100%'>
          <Box>
            <Text
              fontSize='12px'
              fontWeight={500}
              color='primary'
              textOverflow='ellipsis'
            >
              Category:
            </Text>
            <Text fontSize='12px' color='gray1' textOverflow='ellipsis'>
              {category}
            </Text>
          </Box>
          <Box w='100%'>
            <Text fontSize='12px' fontWeight={500} color='primary'>
              Source:
            </Text>
            <Link
              display='block'
              w='100%'
              fontSize='12px'
              color='gray1'
              href={sourceUrl}
              isExternal
              textDecor='underline'
              whiteSpace='nowrap'
              overflow='hidden'
              textOverflow='ellipsis'
            >
              {urlParse(sourceUrl, {}).hostname}
            </Link>
          </Box>
          <Wrap>
            {alpha2CountryCodes &&
              alpha2CountryCodes.map((countryCode, i) => (
                <img
                  key={i}
                  src={`https://flagcdn.com/${countryCode.toLowerCase()}.svg`}
                  width='21'
                  alt='Country Flag'
                />
              ))}
          </Wrap>
        </VStack>
      </VStack>

      <Box w='full' alignItems='flex-start'>
        <Text color='primary' fontSize='12px' fontWeight={500}>
          {formatDate(date)}
        </Text>
        <Box mb='4px' />
        <Text color='primary' fontSize='15px' fontWeight={600}>
          {!!date && isToday(date) ? 'Breaking News: ' : ''}
          {title}
        </Text>
        <Box mb='4px' />
        <Text color='primary' fontSize='12px'>
          {finalContent}
        </Text>

        <Box mb='12px' />

        <HStack fontSize='11px' color='primary'>
          {companies.map((company, i) => (
            <Box key={i} px='8px' py='7px' bgColor='gray4' borderRadius='sm'>
              <Text color='primary' fontSize='11px'>
                {company}
              </Text>
            </Box>
          ))}
        </HStack>

        <Box mb='16px' />

        {displayMetadata && (
          <Collapse in={showMetadata} animateOpacity>
            <Wrap spacing='24px' w='100%'>
              {metadata.tags.length > 0 && (
                <Box maxW='271px' w='100%'>
                  <Text fontSize='12px' fontWeight={500} mb='6px'>
                    Tags:
                  </Text>
                  <Wrap>
                    {metadata.tags.map((tag, i) => (
                      <Text
                        key={i}
                        fontSize='12px'
                        fontWeight={300}
                        color='gray1'
                        lineHeight='21px'
                      >
                        {tag}
                      </Text>
                    ))}
                    {metadata.tags.length === 0 && (
                      <Text
                        fontSize='12px'
                        fontWeight={300}
                        color='gray1'
                        lineHeight='21px'
                      >
                        -
                      </Text>
                    )}
                  </Wrap>
                </Box>
              )}
              {metadata.keywords.length > 0 && (
                <Box maxW='271px' w='100%'>
                  <Text fontSize='12px' fontWeight={500} mb='6px'>
                    Keywords:
                  </Text>
                  <Text
                    fontSize='12px'
                    fontWeight={300}
                    color='gray1'
                    lineHeight='21px'
                  >
                    {metadata.keywords.join(', ')}
                  </Text>
                </Box>
              )}
            </Wrap>
          </Collapse>
        )}

        <Box mb='16px' />

        <Flex w='100%' gap={5} justifyContent='space-between'>
          <Button
            bgColor='secondary'
            px='16px'
            py='8px'
            fontSize='11px'
            fontWeight='500'
            color='primary'
            height='auto'
            variant='solid'
            _hover={{
              textDecor: 'none',
              boxShadow: 'base',
            }}
            disabled={!content || content === ''}
            onClick={onOpen}
          >
            {!content || content === ''
              ? 'Analysis in Progress'
              : 'View Summary'}
          </Button>
          <Modal isOpen={isOpen} onClose={onClose} isCentered>
            <ModalOverlay />
            <ModalContent maxW='540px' overflow='hidden'>
              <ModalHeader pt='32px' px='32px' pb='2px'>
                <Text color='primary' fontSize='12px' fontWeight={500}>
                  {formatDate(date)}
                </Text>
                <Text color='primary' fontSize='15px' fontWeight={600}>
                  {title}
                </Text>
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody pb='32px' px='32px' pt={0}>
                <Box mb='6px' />
                <Text color='primary' fontSize='12px'>
                  {content}
                </Text>
              </ModalBody>
            </ModalContent>
          </Modal>

          {displayMetadata && (
            <HStack
              cursor='pointer'
              onClick={() => setShowMetadata(!showMetadata)}
            >
              <Text
                textDecor='underline'
                color='primary'
                fontSize='11px'
                fontWeight={500}
              >
                {showMetadata ? 'Hide' : 'Show'} metadata
              </Text>
              {showMetadata ? (
                <ChevronUp size={16} />
              ) : (
                <ChevronDown size={16} />
              )}
            </HStack>
          )}
        </Flex>
      </Box>
    </HStack>
  )
}
