import { INavigationMenu } from 'interfaces/navigationMenu.interface'

export type ObesitySlugs =
  | ''
  | 'revenue'
  | 'regulatory'
  | 'media'
  | 'press-release'
  | 'table'
  | 'dashboard'
  | 'news'
  | 'eligible-population'
  | 'approvals-map'
  | 'approvals-table'

const ObesityCommercialMenu: Array<INavigationMenu<ObesitySlugs>[]> = [
  [
    { title: 'Pricing', slug: '', createdAt: new Date(2023, 12, 6) },
    { title: 'Revenue', slug: 'revenue', createdAt: new Date(2023, 12, 6) },
    {
      title: 'Regulatory',
      slug: 'regulatory',
      createdAt: new Date(2024, 0, 17),
      children: [
        {
          title: 'News',
          slug: 'news',
          createdAt: new Date(2024, 0, 17),
        },
        {
          title: 'Approvals Table',
          slug: 'approvals-table',
          createdAt: new Date(2024, 0, 17),
        },
        {
          title: 'Approvals Map',
          slug: 'approvals-map',
          createdAt: new Date(2024, 0, 17),
        },
        {
          title: 'Eligible Population',
          slug: 'eligible-population',
          createdAt: new Date(2024, 0, 17),
        },
      ],
    },
    {
      title: 'Media',
      slug: 'media',
      createdAt: new Date(2023, 12, 6),
    },
    {
      title: 'Press Releases',
      slug: 'press-release',
      createdAt: new Date(2023, 12, 6),
    },
  ],
]

export { ObesityCommercialMenu }
