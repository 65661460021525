import { Text } from '@chakra-ui/react'

export const SearchResultsCount = ({
  count,
  searchString,
}: {
  count: number
  searchString: string
}) => (
  <Text
    fontWeight='medium'
    fontSize='20px'
    color='gray2'
    lineHeight='30px'
    letterSpacing='-0.05px'
    mb='16px'
  >
    We found {count} results for "{searchString}"
  </Text>
)
