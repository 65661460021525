import { INavigationMenu } from 'interfaces/navigationMenu.interface'

export type RNATherapeuticsSlugs =
  | ''
  | 'candidates'
  | 'delivery-systems'
  | 'table'
  | 'new-science'
  | 'candidate-papers'
  | 'trials'
  | 'news'
  | 'press-releases'
  | 'media'
  | 'pipeline'
  | 'facilities-map-view'
  | 'regulatory'
  | 'approval-news'
  | 'funding'
  | 'approvals-map'
  | 'approvals-timeline'
  | 'candidate-associated'
  | 'investigational'
  | 'expedited-status-designations'
  | 'publications'

const RNATherapeuticsMenu: Array<INavigationMenu<RNATherapeuticsSlugs>[]> = [
  [
    {
      title: 'Latest Information',
      slug: '',
      createdAt: new Date(2022, 7, 23),
    },
    {
      title: 'Candidates',
      slug: 'candidates',
      createdAt: new Date(2022, 7, 23),
      children: [
        {
          title: 'Table',
          slug: 'table',
          createdAt: new Date(2024, 1, 14),
        },
        {
          title: 'Pipeline',
          slug: 'pipeline',
          createdAt: new Date(2022, 7, 23),
        },
      ],
    },
    {
      title: 'Delivery Systems',
      slug: 'delivery-systems',
      createdAt: new Date(2023, 1, 23),
      children: [
        {
          title: 'Candidate Associated',
          slug: 'candidate-associated',
          createdAt: new Date(2023, 1, 23),
        },
        {
          title: 'Investigational',
          slug: 'investigational',
          createdAt: new Date(2023, 1, 23),
        },
        {
          title: 'News',
          slug: 'news',
          createdAt: new Date(2023, 1, 23),
        },
      ],
    },
    {
      title: 'Facilities Map View',
      slug: 'facilities-map-view',
      createdAt: new Date(2023, 2, 8),
    },
    {
      title: 'Regulatory',
      slug: 'regulatory',
      createdAt: new Date(2023, 1, 23),
      children: [
        {
          title: 'Approvals Map',
          slug: 'approvals-map',
          createdAt: new Date(2023, 1, 23),
        },
        {
          title: 'Approvals Timeline',
          slug: 'approvals-timeline',
          createdAt: new Date(2023, 1, 23),
        },
        {
          title: 'Expedited Status Designations',
          slug: 'expedited-status-designations',
          createdAt: new Date(2023, 1, 23),
        },
        {
          title: 'Approval News',
          slug: 'approval-news',
          createdAt: new Date(2023, 1, 23),
        },
      ],
    },
    {
      title: 'Funding',
      slug: 'funding',
      createdAt: new Date(2023, 2, 8),
    },
    {
      title: 'New Science',
      slug: 'new-science',
      createdAt: new Date(2022, 7, 23),
      children: [
        {
          title: 'Publications',
          slug: 'publications',
          createdAt: new Date(2022, 7, 23),
        },
      ],
    },
    {
      title: 'Trials',
      slug: 'trials',
      createdAt: new Date(2022, 7, 23),
      children: [
        {
          title: 'Table',
          slug: 'table',
          createdAt: new Date(2022, 7, 23),
        },
      ],
    },
    {
      title: 'News',
      slug: 'news',
      createdAt: new Date(2022, 7, 23),
      children: [
        {
          title: 'Press Releases',
          slug: 'press-releases',
          createdAt: new Date(2022, 7, 23),
        },
        {
          title: 'Media',
          slug: 'media',
          createdAt: new Date(2022, 7, 23),
        },
      ],
    },
  ],
]

export { RNATherapeuticsMenu }
