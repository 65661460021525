import { Switcher } from '@carbon/icons-react'
import {
  Button,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import _ from 'lodash'
import { useContext, useState } from 'react'

import { useAppParams } from 'routes/utils'

import { AuthContext } from 'contexts'

import useTracking from 'tracking/useTracking'

import { appsListUnion } from 'config/apps'

import AppsSelectorBody from './AppsSelectorBody'
import AppsSelectorHeader, {
  AppsSelectorDropdownType,
  selectAllOption,
} from './AppsSelectorHeader'

const AppsSelectorModal = () => {
  const { appletGroupsUserCanSee, appletsUserCanSee } = useContext(AuthContext)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const appParams = useAppParams()
  const [tracking] = useTracking()
  const [selectedCategory, setSelectedCategory] =
    useState<AppsSelectorDropdownType>(selectAllOption)

  const appletGroupsToShow = _.sortBy(
    selectedCategory.value === null
      ? Object.values(appletGroupsUserCanSee)
      : Object.values(appletGroupsUserCanSee).filter(
          (appletGroup) => appletGroup.category === selectedCategory.value
        ),
    'order'
  ).filter((appletGroup) => !appletGroup.hidden)

  const selectedAppSlug = appParams.app as appsListUnion

  const selectedApplet = Object.values(appletsUserCanSee).find((applet) =>
    applet.apps.includes(selectedAppSlug)
  )

  const selectedAppletGroup = Object.values(appletGroupsUserCanSee).find(
    (appletGroup) => appletGroup.applets.includes(selectedApplet?.key!)
  )

  return (
    <>
      <Button
        variant='ghost'
        onClick={() => {
          onOpen()
          tracking.appsModalTrigger({})
        }}
        display='flex'
        alignItems='center'
        p={0}
        rounded='md'
        _hover={{
          background: 'none',
        }}
        _active={{
          backgroundColor: 'none',
        }}
        _focus={{
          boxShadow: 'none',
        }}
        ml={8}
      >
        <Icon as={Switcher} boxSize='18px' color='white' />
        <Text
          flexShrink={0}
          color='white'
          ml={3}
          fontWeight='600'
          fontSize='17px'
          lineHeight='18px'
          letterSpacing='0.1px'
        >
          {selectedAppletGroup?.name}
        </Text>
      </Button>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior='inside'
        isCentered
      >
        <ModalOverlay />
        <ModalContent
          maxW='1100px'
          borderRadius='md'
          p={10}
          height='100%'
          maxHeight='688px'
          mx={[4, 10]}
        >
          <ModalHeader>
            <AppsSelectorHeader
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
              hideSelector={false}
            />
          </ModalHeader>
          <ModalCloseButton
            color='black'
            _focus={{ boxShadow: 'none' }}
            onClick={() => {
              onClose()
            }}
            fontSize='15px'
            rounded='sm'
            position='absolute'
            top={3.5}
          />
          <ModalBody>
            <AppsSelectorBody
              appletGroupsToShow={appletGroupsToShow}
              onSelectApp={onClose}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

export default AppsSelectorModal
