import { IApp } from 'interfaces/navigationApp.interface'

import { AccessGroups } from 'enums/AccessGroups'

import { CovidEpidDataCustomPages } from './CovidEpidDataCustomPages'
import { CovidEpidDataMenu, CovidEpidDataSlugs } from './CovidEpidDataMenu'
import { CovidEpidDataPages } from './CovidEpidDataPages'

const covidEpidData: IApp<CovidEpidDataSlugs> = {
  name: 'Covid Epid Data',
  slug: 'covid-epid-data',
  accessGroups: [AccessGroups.CovidEpidData],
  menu: CovidEpidDataMenu,
  endpoint: '',
  pages: CovidEpidDataPages,
  customPages: CovidEpidDataCustomPages,
  createdAt: new Date(2021, 8, 30),
}

export default covidEpidData
