import { IApp } from 'interfaces/navigationApp.interface'

import { AccessGroups } from 'enums/AccessGroups'

import {
  CovidBoosterMarketForecastMenu,
  CovidBoosterMarketForecastSlugs,
} from './CovidBoosterMarketForecastMenu'
import { CovidBoosterMarketForecastPages } from './CovidBoosterMarketForecastPages'
import { CovidVaccineMarketCustomPages } from './CovidVaccineMarketCustomPages'

const covidBoosterMarketForecast: IApp<CovidBoosterMarketForecastSlugs> = {
  name: 'COVID-19 Booster Forecasts',
  slug: 'booster-demand-forecast',
  accessGroups: [AccessGroups.CovidVaccineMarketForecast],
  menu: CovidBoosterMarketForecastMenu,
  endpoint: 'covid-vaccine-market',
  pages: CovidBoosterMarketForecastPages,
  customPages: CovidVaccineMarketCustomPages,
  createdAt: new Date(2022, 2, 8),
}

export default covidBoosterMarketForecast
