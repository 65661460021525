import { IApp } from 'interfaces/navigationApp.interface'

import { AccessGroups } from 'enums/AccessGroups'

import { PadCustomPages } from './PadCustomPages'
import { PadMenu, PadSlugs } from './PadMenu'
import { PadPages } from './PadPages'

const Pad: IApp<PadSlugs> = {
  name: 'Pandemic Antiviral Discovery',
  slug: 'pad',
  endpoint: 'pad',
  accessGroups: [AccessGroups.PAD],
  menu: PadMenu,
  pages: PadPages,
  customPages: PadCustomPages,
  appIdentifier: 'pad',
  createdAt: new Date(2022, 6, 8),
  disableAnalytics: true,
}

export default Pad
