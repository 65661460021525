import { Box, BoxProps } from '@chakra-ui/react'

type PropTypes = {
  label: string
} & BoxProps

const AttributeLabel = ({ label, ...rest }: PropTypes) => {
  return (
    <Box
      paddingTop='3px'
      fontWeight={500}
      color='gray.700'
      fontSize='14px'
      lineHeight='none'
      textTransform='capitalize'
      {...rest}
    >
      {label}
    </Box>
  )
}

export default AttributeLabel
