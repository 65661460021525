import { INavigationMenu } from 'interfaces/navigationMenu.interface'

export type ObesityEpidemiologySlugs =
  | ''
  | 'obesity-related-disease-burden'
  | 'media'
  | 'publications'

const ObesityEpidemiologyMenu: Array<
  INavigationMenu<ObesityEpidemiologySlugs>[]
> = [
  [
    {
      title: 'Obesity Epidemiology Forecast',
      slug: '',
      createdAt: new Date(2023, 12, 6),
    },
    {
      title: 'Obesity-Related Disease Burden',
      slug: 'obesity-related-disease-burden',
      createdAt: new Date(2023, 12, 6),
    },
    {
      title: 'Publications',
      slug: 'publications',
      createdAt: new Date(2023, 12, 6),
    },
    {
      title: 'Media',
      slug: 'media',
      createdAt: new Date(2023, 12, 6),
    },
  ],
]

export { ObesityEpidemiologyMenu }
