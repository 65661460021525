import { createContext, useContext } from 'react'

import { TableProps } from '../types'

// THIS IS A PROVIDER MODEL
// Make sure to use this properly. This file is here due to that. Only scoped to handling the table

// We provide this for more advanced feature of the table such as child tables.
// Be careful when using this as it might lead to bugs if not used correctly. Make sure the intermediate
// tree nodes are re-rendered as needed.

type ProviderType = { parentProps: TableProps<any> }

export const TableParentPropsContext = createContext<ProviderType>({
  parentProps: {
    columns: [],
    data: [],
    model: {
      allowedAirtables: [],
      endpoint: '',
      name: '',
      entityName: '',
      schema: { columns: [] },
    },
  },
})

export const useTableParentProps = (): ProviderType => {
  return useContext(TableParentPropsContext)
}
