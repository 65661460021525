import { Box, Button } from '@chakra-ui/react'
import axios from 'axios'
import { proxy } from 'comlink'
import { memo, useEffect, useMemo, useState } from 'react'
// @ts-ignore
import { CSVLink } from 'react-csv'
import { ColumnInstance, TableState } from 'react-table'
import instance from 'worker'
import { writeFile } from 'xlsx'

import { useTablePageData } from 'modules/Tables/TablePageProvider'

import { InlineLoading, Select } from 'components'

import { IResponseBase, Paginated } from 'api/types'
import { useAxiosRequestConfig } from 'api/useAxios'
import { mapStateFromTableState } from 'api/useTableData'

import { apps } from 'config/apps'
import { getIndexedDBKeys } from 'config/indexedDBKeys'

import { useExportData } from 'utils/export'
import { getCustomSortOrderFromModel } from 'utils/model'

const exportOptions = {
  xlsx: { label: 'Excel', value: 'xlsx' },
  csv: { label: 'CSV', value: 'csv' },
}

type PropTypes = {
  state: TableState
  columns: ColumnInstance<any>[]
  exportName: string
  exportDataCallback: () => void
  onClose: () => void
}

const Download = ({
  state,
  columns,
  exportName,
  exportDataCallback,
  onClose,
}: PropTypes) => {
  const { app, model, viewData } = useTablePageData()

  // // We get the list of columns since we want to perform search on all columns, not just visible ones
  const tableColumns = useMemo(
    () => model.schema.columns.map((x) => x.key),
    [model]
  )
  const mappedTableState = mapStateFromTableState(state, tableColumns)

  const [selectedFileType, setSelectedFileType] = useState(exportOptions.xlsx)
  const [loading, setLoading] = useState<boolean>(false)
  const [preparedData, setPreparedData] = useState<Paginated<any>>()

  const appEndpoint = apps[app.slug].endpoint
  const axiosRequestConfig = useAxiosRequestConfig()
  const appPage = Object.values(apps?.[app.slug]?.pages).find(
    (page) => page?.model?.endpoint === model.endpoint
  )
  const columnSchema = appPage?.model?.schema?.columns?.map((column) => ({
    type: column?.type,
    key: column?.key,
  }))

  const extraConfig = {
    customAPIFunction: model.customAPIFunction,
  }

  useEffect(() => {
    const getPreparedData = async () => {
      setLoading(true)
      const tableData = await instance.getData(
        {
          indexedDBKeys: getIndexedDBKeys(apps),
          axiosRequestConfig,
          appEndpoint: appEndpoint,
          modelEndpoint: model.endpoint,
          viewData,
          state: mappedTableState,
          config: {
            paginate: false,
            customSortOrder: getCustomSortOrderFromModel(model),
            grouped: false,
          },
          schema: columnSchema,
        },
        extraConfig.customAPIFunction
          ? proxy(
              extraConfig.customAPIFunction(axios.create(axiosRequestConfig))
            )
          : undefined
      )

      setPreparedData(tableData)
      setLoading(false)
    }
    getPreparedData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { workbook, aoaData, getFileName } = useExportData({
    exportName,
    // @ts-ignore
    columns: columns as Array<CustomColumn<any>>,
    data: preparedData?.results as IResponseBase<string>[],
  })

  if (loading) {
    return <InlineLoading />
  }

  return (
    <>
      <Select
        isSearchable={false}
        onChange={(x: any) => {
          setSelectedFileType(x)
        }}
        value={selectedFileType}
        options={Object.values(exportOptions)}
      />
      <Box
        mt='24px'
        mb='16px'
        display='flex'
        gap='16px'
        justifyContent='flex-end'
      >
        <Button variant='outline' onClick={onClose}>
          Cancel
        </Button>
        {selectedFileType.value === 'csv' ? (
          <CSVLink
            data={aoaData}
            filename={getFileName('csv')}
            onClick={exportDataCallback}
          >
            <Button data-cy='report-download-csv' variant='yellow'>
              Download
            </Button>
          </CSVLink>
        ) : (
          <Button
            data-cy='report-download-excel'
            variant='yellow'
            onClick={() => {
              writeFile(workbook, getFileName('xlsx'))
              exportDataCallback()
            }}
          >
            Download
          </Button>
        )}
      </Box>
    </>
  )
}

export default memo(Download)
