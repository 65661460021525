import { IApp } from 'interfaces/navigationApp.interface'

import { AccessGroups } from 'enums/AccessGroups'

import { RNATherapeuticsCustomPages } from './RNATherapeuticsCustomPages'
import {
  RNATherapeuticsMenu,
  RNATherapeuticsSlugs,
} from './RNATherapeuticsMenu'
import { RNATherapeuticsPages } from './RNATherapeuticsPages'

const RNATherapeuticsApp: IApp<RNATherapeuticsSlugs> = {
  name: 'RNA',
  slug: 'rna',
  accessGroups: [AccessGroups.RNATherapeutics],
  commonIdentifier: 'rna',
  endpoint: 'rna-therapeutics',
  menu: RNATherapeuticsMenu,
  pages: RNATherapeuticsPages,
  customPages: RNATherapeuticsCustomPages,
  createdAt: new Date(2022, 7, 23),
}

export default RNATherapeuticsApp
