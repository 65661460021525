import GenericTable from 'modules/Tables'

import { IModel } from 'interfaces/model.interface'
import {
  INavigationPages,
  ISingleNavigationPage,
} from 'interfaces/navigationPage.interface'

import {
  ObesityMediaModel,
  ObesityPublicationsModel,
} from '../Obesity360/ObesityModels'
import { ObesityEpidemiologySlugs } from './ObesityEpidemiologyMenu'

type ObesityEpidemiologyPage<TModel extends IModel<any>> =
  ISingleNavigationPage<ObesityEpidemiologySlugs, TModel>

const Publications: ObesityEpidemiologyPage<typeof ObesityPublicationsModel> = {
  key: 'Publications',
  path: ['publications'],
  model: {
    ...ObesityPublicationsModel,
  },
  component: GenericTable,
  views: [
    {
      name: 'All',
      airtableName: 'Obesity Publications - Platform View - Epidemiology',
      airtableBase: 'cardio360',
    },
  ],
}

const Media: ObesityEpidemiologyPage<typeof ObesityMediaModel> = {
  key: 'Media',
  path: ['media'],
  model: {
    ...ObesityMediaModel,
  },
  component: GenericTable,
  views: [
    {
      name: 'All',
      airtableName: 'Obesity Media - Platform View - Epidemiology',
      airtableBase: 'cardio360',
    },
  ],
}

export const ObesityEpidemiologyPages: INavigationPages<ObesityEpidemiologySlugs> =
  { Publications, Media }
