import FullReportPage from 'routes/common/FullReportPage'

import { TableauEmbed } from 'components'

import { ISingleCustomPage } from 'interfaces/navigationPage.interface'

import { ModernaRSVCommercialSlugs } from './ModernaRSVCommercialMenu'

const AbsoluteCases: ISingleCustomPage<ModernaRSVCommercialSlugs> = {
  path: '',
  component: () => (
    <TableauEmbed path='/RSVdemandplotsUpdated/GuideRSVvaccinedemandforecast' />
  ),
}

const Reports: ISingleCustomPage<ModernaRSVCommercialSlugs> = {
  path: 'reports',
  component: () => <FullReportPage />,
}

export const ModernaRSVCommercialCustomPages: Record<
  string,
  ISingleCustomPage<ModernaRSVCommercialSlugs>
> = {
  AbsoluteCases,
  Reports,
}
