import { IModel } from 'interfaces/model.interface'
import { ValueType } from 'interfaces/valueType.interface'

import { SortOrders } from 'enums/SortOrders'

import { fourDecimalPlaces } from 'config/common/transformValue/decimal'

export type ResearchPowerIndexPublicationsData =
  | 'title'
  | 'link'
  | 'description'
  | 'afSentiment'
  | 'publicationDate'
  | 'linkAfLeadAuthor'
  | 'linkAfLastAuthor'
  | 'linkJournals'
  | 'articleViews'
  | 'pdfDownloads'
  | 'tweetMentions'
  | 'citations'
  | 'ssovFinalQualityLeadLastJifAverageNormPublicationsIncludingReviews'
  | 'ssovFinalImpactTweetCitationsArticleViewsPdfAverageNormPublications'
  | 'leadAuthorAffiliation'
  | 'lastAuthorAffiliation'
  | 'funding'
  | 'afRelevant'
  | 'lookupLeadAuthorHIndex'
  | 'lookupLastAuthorHIndex'
  | 'lookupJournalImpactScore'
  | 'keywordsClean'
  | 'countryFromLeadAuthorAffiliation'
  | 'countryFromLastAuthorAffiliation'
  | 'countryFromFunding'

const allowedAirtables = ['api_front_end_view_publications'] as const

export const ResearchPowerIndexPublicationsModel: IModel<
  Record<ResearchPowerIndexPublicationsData, string>,
  typeof allowedAirtables
> = {
  allowedAirtables,
  displayKey: 'title',
  name: 'Publications',
  entityName: 'Publications',
  searchField: 'title',
  endpoint: 'publication',
  defaultSortObject: [{ id: 'publicationDate', sortOrder: SortOrders.DESC }],
  detailViewType: 'Article',
  detailView: {
    link: 'articleLink',
    abstract: 'description',
  },
  schema: {
    columns: [
      { key: 'title', label: 'Title', width: 280, type: ValueType.TEXT },
      { key: 'link', label: 'Link', width: 120, type: ValueType.URL },
      {
        key: 'description',
        label: 'Abstract',
        width: 250,
        type: ValueType.TEXT,
      },
      {
        key: 'afSentiment',
        label: 'Sentiment',
        width: 120,
        type: ValueType.TEXT,
      },
      {
        key: 'publicationDate',
        label: 'Publication Date',
        width: 120,
        type: ValueType.DATE,
      },
      {
        key: 'linkAfLeadAuthor',
        label: 'Lead Author',
        width: 200,
        type: ValueType.MULTI,
      },
      {
        key: 'linkAfLastAuthor',
        label: 'Last Author',
        width: 200,
        type: ValueType.MULTI,
      },
      {
        key: 'linkJournals',
        label: 'Journals',
        width: 220,
        type: ValueType.MULTI,
      },
      {
        key: 'articleViews',
        label: 'Article Views',
        width: 100,
        type: ValueType.NUMBER,
      },
      {
        key: 'pdfDownloads',
        label: 'PDF Downloads',
        width: 120,
        type: ValueType.NUMBER,
      },
      {
        key: 'tweetMentions',
        label: 'Tweet Mentions',
        width: 120,
        type: ValueType.NUMBER,
      },
      {
        key: 'citations',
        label: 'Citations',
        width: 100,
        type: ValueType.NUMBER,
      },
      {
        key: 'ssovFinalQualityLeadLastJifAverageNormPublicationsIncludingReviews',
        label: 'Publication Quality normalised metric',
        width: 200,
        type: ValueType.NUMBER,
        transformValue: fourDecimalPlaces,
      },
      {
        key: 'ssovFinalImpactTweetCitationsArticleViewsPdfAverageNormPublications',
        label: 'Publication Impact re-normalised metric',
        width: 200,
        type: ValueType.NUMBER,
        transformValue: fourDecimalPlaces,
      },
      {
        key: 'leadAuthorAffiliation',
        label: 'Lead Author Affiliation',
        width: 260,
        type: ValueType.MULTI,
      },
      {
        key: 'lastAuthorAffiliation',
        label: 'Last Author Affiliation',
        width: 260,
        type: ValueType.MULTI,
      },
      { key: 'funding', label: 'Funding', width: 260, type: ValueType.MULTI },
      {
        key: 'afRelevant',
        label: 'Relevant',
        width: 200,
        type: ValueType.MULTI,
      },
      {
        key: 'lookupLeadAuthorHIndex',
        label: 'Lead Author H Index',
        width: 180,
        type: ValueType.NUMBER,
      },
      {
        key: 'lookupLastAuthorHIndex',
        label: 'Last Author H Index',
        width: 180,
        type: ValueType.NUMBER,
      },
      {
        key: 'lookupJournalImpactScore',
        label: 'Journal Impact Factor',
        width: 180,
        type: ValueType.NUMBER,
      },
      {
        key: 'keywordsClean',
        label: 'Keywords',
        width: 180,
        type: ValueType.MULTI,
      },
      {
        key: 'countryFromLeadAuthorAffiliation',
        label: 'Lead Author Country Affiliations',
        width: 230,
        type: ValueType.MULTI,
      },
      {
        key: 'countryFromLastAuthorAffiliation',
        label: 'Last Author Country Affiliations',
        width: 230,
        type: ValueType.MULTI,
      },
      {
        key: 'countryFromFunding',
        label: 'Funding Agency Country Affiliations',
        width: 230,
        type: ValueType.MULTI,
      },
    ],
  },
}
