import { Checkmark, Close } from '@carbon/icons-react'

import { CleanResponseData } from 'api/types'

type PropTypes = {
  value: CleanResponseData
}

export default ({ value }: PropTypes) => {
  return (
    <>
      {value ? (
        <Checkmark size={16} color='#53B869' />
      ) : (
        <Close size={16} color='#EE4E6B' />
      )}
    </>
  )
}
