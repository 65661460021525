import { IModel } from 'interfaces/model.interface'
import { ValueType } from 'interfaces/valueType.interface'

import { SortOrders } from 'enums/SortOrders'

import Sentiments from '../../tagColours/Sentiments'

export type CardioConferencePresentationsData =
  | 'titleClean'
  | 'eventName'
  | 'sessionName'
  | 'lookupMappedSessionType'
  | 'linkOrganisation'
  | 'descriptionClean'
  | 'activeSubstance'
  | 'candidateList'
  | 'afTopics'
  | 'afSentiment'
  | 'presentingAuthor'
  | 'afIndependent'
  | 'likes'
  | 'views'
  | 'comments'

const allowedAirtables = [
  'api_conference_presentations',
  'api_conference_presentations_antithrombotics',
] as const

export const CardioConferencePresentationsModel: IModel<
  Record<CardioConferencePresentationsData, string>,
  typeof allowedAirtables
> = {
  allowedAirtables,
  endpoint: 'conference-presentation',
  name: 'Conference Presentations',
  entityName: 'Conference Presentations',
  searchField: 'titleClean',
  detailViewType: 'Generic',
  exportDisabled: true,
  defaultSortObject: [
    {
      id: 'lookupMappedSessionType',
      sortOrder: SortOrders.ASC,
    },
    {
      id: 'sessionName',
      sortOrder: SortOrders.DESC,
    },
    {
      id: 'eventName',
      sortOrder: SortOrders.DESC,
    },
  ],
  displayKey: 'titleClean',
  noPrint: true,
  schema: {
    columns: [
      {
        key: 'titleClean',
        label: 'Title',
        width: 500,
        showDetail: false,
        type: ValueType.TEXT,
        cellFormat: {
          maxLines: 3,
        },
      },
      {
        key: 'eventName',
        label: 'Event Name',
        width: 130,
        type: ValueType.TEXT,
      },
      {
        key: 'sessionName',
        label: 'Session Name',
        width: 300,
        type: ValueType.SINGLE,
      },
      {
        key: 'lookupMappedSessionType',
        label: 'Session Type',
        width: 140,
        type: ValueType.MULTI,
      },
      {
        key: 'linkOrganisation',
        label: 'Organisation',
        width: 200,
        type: ValueType.MULTI,
      },
      {
        key: 'descriptionClean',
        label: 'Description',
        type: ValueType.LONG,
        width: 600,
        cellFormat: {
          maxLines: 4,
        },
        showTable: false,
      },
      {
        key: 'candidateList',
        label: 'Candidate List',
        width: 200,
        type: ValueType.MULTI,
      },
      {
        key: 'afTopics',
        label: 'Topics',
        width: 250,
        type: ValueType.MULTI,
      },
      {
        key: 'afSentiment',
        label: 'Sentiment',
        width: 100,
        type: ValueType.SINGLE,
        cellFormat: {
          colours: Sentiments,
        },
      },
      {
        key: 'presentingAuthor',
        label: 'Presenting Author',
        width: 200,
        type: ValueType.MULTI,
      },
      {
        key: 'afIndependent',
        label: 'Independent',
        width: 130,
        type: ValueType.SINGLE,
      },
    ],
  },
}
