import {
  UseInfiniteQueryResult,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
} from '@tanstack/react-query'
import axios, { AxiosError, AxiosRequestConfig } from 'axios'
import { proxy } from 'comlink'
import { useContext } from 'react'
import instance from 'worker'

import { QueryProp } from 'webworker'

import { AuthContext } from 'contexts'

import { ICleanResponse, IResponseBase, Paginated } from 'api/types'
import { useAxiosRequestConfig } from 'api/useAxios'

import { IModel } from 'interfaces/model.interface'
import { IBaseView } from 'interfaces/navigationPage.interface'

import { apps, appsListUnion } from 'config/apps'
import { getIndexedDBKeys } from 'config/indexedDBKeys'

import { relationGetDisplayValue } from 'utils/relational'

import { TableState } from './types'

export type FetchCollectionQueries = Partial<{
  airtableBase: IBaseView<any>['airtableBase']
  view: string | null
  offset: number | null
  sort: string | null
  limit: number | null
  fields: string[] | null
  ids: string[] | null
}>

function getNextPageParam<TResponse extends IResponseBase>(
  lastPage: Paginated<TResponse[]>,
  allPages: Paginated<TResponse[]>[]
) {
  const totalRowsFetched = allPages.reduce(
    (acc, val) => acc + val.results.length,
    0
  )
  const totalOverall = lastPage.count

  if (totalRowsFetched === totalOverall) {
    return false
  }

  const offset = totalRowsFetched

  return offset
}

type ExtraConfig = {
  customAPIFunction?: IModel<any>['customAPIFunction']
}

export default function useCollectionData(
  app: appsListUnion,
  collection: string,
  queries: FetchCollectionQueries,
  state: TableState,
  config: QueryProp['config'],
  extraConfig: ExtraConfig,
  extraProps?: UseInfiniteQueryOptions<any, any>
): UseInfiniteQueryResult<Paginated<ICleanResponse[]>, AxiosError<any>> {
  const axiosRequestConfig = useAxiosRequestConfig()

  const query = useInfiniteQuery<Paginated<ICleanResponse[]>, AxiosError>(
    ['collectionData', app, collection, queries, state],
    ({ pageParam: offset }) => {
      return fetchCollection(
        axiosRequestConfig,
        app,
        collection,
        { ...queries, offset },
        state,
        config,
        extraConfig
      )
    },
    {
      getNextPageParam,
      ...extraProps,
    }
  )
  return query
}

export function useCollectionDataRaw(
  app: appsListUnion,
  collection: string,
  queries: Omit<FetchCollectionQueries, 'offset'>,
  state: TableState,
  config: QueryProp['config'],
  extraConfig: ExtraConfig,
  extraProps?: UseInfiniteQueryOptions<any, any>
): UseInfiniteQueryResult<Paginated<IResponseBase[]>, AxiosError<any>> {
  const axiosRequestConfig = useAxiosRequestConfig()

  const { userInfo } = useContext(AuthContext)
  const selectedApp = apps[app as appsListUnion]
  const premiumGroup = selectedApp.premiumGroup || []
  const premiumAccess = userInfo?.groups.includes(premiumGroup[0])
  const endpoint = premiumAccess
    ? apps[app].premiumSlug || apps[app].endpoint
    : apps[app].endpoint

  const query = useInfiniteQuery<Paginated<IResponseBase[]>, AxiosError>(
    ['collectionDataRaw', app, collection, queries, state],
    ({ pageParam: offset }) => {
      return fetchCollectionRaw(
        axiosRequestConfig,
        app,
        collection,
        { ...queries, offset },
        state,
        config,
        extraConfig,
        endpoint
      )
    },
    {
      getNextPageParam,
      ...extraProps,
    }
  )

  return query
}

export const fetchCollectionRaw = async (
  axiosRequestConfig: AxiosRequestConfig,
  app: appsListUnion,
  collection: string,
  queries: FetchCollectionQueries,
  state: TableState,
  config: QueryProp['config'],
  extraConfig: ExtraConfig,
  endpoint?: string
): Promise<Paginated<IResponseBase[]>> => {
  const appEndpoint = endpoint || apps[app].endpoint

  const viewData = {
    airtableName: queries.view,
    airtableBase: queries.airtableBase,
  }

  let appPage = Object.values(apps?.[app]?.pages).find(
    (page) => page?.key === (config?.key || '')
  )

  // This is a temp fix to make sure we always have an appPage
  if (!appPage) {
    appPage = Object.values(apps?.[app]?.pages).find(
      (page) => page?.model?.endpoint === collection
    )
  }

  const columnSchema = appPage?.model?.schema?.columns?.map((column) => ({
    type: column?.type,
    key: column?.key,
  }))

  return instance.getData(
    {
      tableEmptyThreshold: appPage?.model.emptyThreshold,
      url: window ? window.location.href : '',
      indexedDBKeys: getIndexedDBKeys(apps),
      axiosRequestConfig,
      appEndpoint: appEndpoint,
      modelEndpoint: collection,
      viewData,
      state,
      config,
      schema: columnSchema,
    },
    extraConfig.customAPIFunction
      ? proxy(extraConfig.customAPIFunction(axios.create(axiosRequestConfig)))
      : undefined
  )
}

// Map relational data so we don't have to deal with it everywhere
export const fetchCollection = async (
  axiosRequestConfig: AxiosRequestConfig,
  app: appsListUnion,
  collection: string,
  queries: FetchCollectionQueries,
  state: TableState,
  config: QueryProp['config'],
  extraConfig: ExtraConfig
): Promise<Paginated<ICleanResponse[]>> => {
  const data = await fetchCollectionRaw(
    axiosRequestConfig,
    app,
    collection,
    queries,
    state,
    config,
    extraConfig
  )

  return {
    count: data.count,
    results: data.results.map((row) =>
      Object.fromEntries(
        Object.entries(row).map(([key, val]) => [
          key,
          relationGetDisplayValue(val),
        ])
      )
    ) as ICleanResponse[],
  }
}
