import { Box, Text } from '@chakra-ui/react'
import moment from 'moment'

import { ItemComponentPropTypes } from 'modules/DetailDrawer'

import { ApprovalsData } from 'config/common/baseModel/Approvals'

import { formatDate } from 'utils/formatDate'

export const DetailDrawerListApprovals = ({
  item,
  handleSelect,
}: ItemComponentPropTypes<Record<ApprovalsData, any>>) => {
  const {
    luCompany,
    singleDate,
    regulatoryBody,
    approvalType,
    countryOrganisationGrantingDecision,
    luCandidateName,
  } = item

  const approvedDate = moment(singleDate)

  return (
    <Box
      px={8}
      py={4}
      cursor='pointer'
      borderBottom='1px solid'
      borderColor='gray.100'
      onClick={() => handleSelect(item)}
      _hover={{
        bg: 'gray.100',
      }}
    >
      <Box
        display='flex'
        alignItems='center'
        fontSize='xs'
        fontWeight={600}
        textTransform='uppercase'
        color='gray.700'
      >
        <Box mr={2}>
          {approvedDate?.isValid()
            ? `Approved on ${formatDate(approvedDate)}`
            : `Approval date unknown`}
        </Box>
      </Box>
      {luCandidateName && (
        <Text fontSize='xs' fontWeight={400} color='gray.800'>
          Candidate:{' '}
          <b>
            {luCandidateName} - {luCompany}
          </b>
        </Text>
      )}
      {regulatoryBody && (
        <Text fontSize='xs' fontWeight={400} color='gray.800'>
          Regulatory Body: <b>{regulatoryBody}</b>
        </Text>
      )}
      {approvalType && (
        <Text fontSize='xs' fontWeight={400} color='gray.800'>
          Approval Type: <b>{approvalType}</b>
        </Text>
      )}
      {countryOrganisationGrantingDecision && (
        <Text fontSize='xs' fontWeight={400} color='gray.800'>
          Country Organisation Granting Decision:{' '}
          <b>{countryOrganisationGrantingDecision}</b>
        </Text>
      )}
    </Box>
  )
}
