import GenericTable from 'modules/Tables'

import { IModel } from 'interfaces/model.interface'
import {
  INavigationPages,
  ISingleNavigationPage,
} from 'interfaces/navigationPage.interface'

import { SortOrders } from 'enums/SortOrders'

import { VaccinesProductionDealsModel } from '../../Science360/Vaccines/VaccinesModels'
import {
  SupplyAndProductionProductionDealsModel,
  SupplyAndProductionRevenueModel,
  SupplyDealsDeliveriesDonationsModel,
  SupplyDealsPriceOrientedModel,
  SupplyDealsSupplyOrientedModel,
  VaccineCampaignsModel,
} from './Covid19CommercialVaccineModels'
import { Covid19CommercialVaccinesSlugs } from './Covid19CommercialVaccinesMenu'
import warningMessages from './Covid19CommercialVaccinesWarningMessages'

type Covid19CommercialVaccinesPage<TModel extends IModel<any>> =
  ISingleNavigationPage<Covid19CommercialVaccinesSlugs, TModel>

const DealsProduction: Covid19CommercialVaccinesPage<
  typeof SupplyAndProductionProductionDealsModel
> = {
  key: 'DealsProduction',
  path: ['production', 'table'],
  model: {
    ...VaccinesProductionDealsModel,
    defaultSortKey: 'afDateDealMade',
    defaultSortOrder: SortOrders.DESC,
    detailViewType: 'Generic',
  },
  component: GenericTable,
  views: [
    {
      name: 'Default',
      airtableName: 'api_production_deals',
    },
  ],
}

const Revenue: Covid19CommercialVaccinesPage<
  typeof SupplyAndProductionRevenueModel
> = {
  key: 'Revenue',
  path: ['revenue', 'table'],
  model: {
    ...SupplyAndProductionRevenueModel,
    warningMessage: warningMessages.revenue,
    newNote: {
      text: warningMessages.revenueNewNote,
      newUntil: new Date(2024, 8, 24),
    },
  },
  component: GenericTable,
  views: [
    {
      name: 'Default',
      airtableName: 'covid_commercial_revenue_vaccine',
      airtableBase: 'lzdb',
    },
  ],
}

const SupplyDealsDeliveriesDonations: Covid19CommercialVaccinesPage<
  typeof SupplyDealsDeliveriesDonationsModel
> = {
  key: 'Deals',
  path: ['supply', 'table'],
  model: {
    ...SupplyDealsDeliveriesDonationsModel,
    warningMessage: warningMessages.supplyDeals,
    name: 'Supply Deals',
    detailViewType: 'Generic',
  },
  component: GenericTable,
  views: [
    {
      name: 'All',
      airtableName: 'covid_commercial_deals_vaccine_all',
      airtableBase: 'lzdb',
    },
    {
      name: 'Vaccine Supply',
      airtableName: 'covid_commercial_deals_vaccine_supply',
      airtableBase: 'lzdb',
      overrideModel: SupplyDealsSupplyOrientedModel,
    },
    {
      name: 'Vaccine Prices',
      airtableName: 'covid_commercial_deals_vaccine_price',
      airtableBase: 'lzdb',
      overrideModel: SupplyDealsPriceOrientedModel,
    },
  ],
}

const VaccineCampaigns: Covid19CommercialVaccinesPage<
  typeof VaccineCampaignsModel
> = {
  key: 'VaccineCampaigns',
  path: ['demand', 'vaccination-campaigns'],
  model: {
    ...VaccineCampaignsModel,
    endpoint: 'immunisation-schedule',
    name: 'Vaccine Campaigns',
  },
  component: GenericTable,
  views: [
    {
      name: 'Default',
      airtableName: 'COVID Vaccine Campaigns',
      airtableBase: 'lzdb',
    },
  ],
}

export const Covid19CommercialVaccinesPages: INavigationPages<Covid19CommercialVaccinesSlugs> =
  {
    DealsProduction,
    Revenue,
    SupplyDealsDeliveriesDonations,
    VaccineCampaigns,
  }
