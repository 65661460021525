import { IApp } from 'interfaces/navigationApp.interface'

import { AccessGroups } from 'enums/AccessGroups'

import { RSVCommercialCustomPages } from './RSVCommercialCustomPages'
import { RSVCommercialMenu, RSVCommercialSlugs } from './RSVCommercialMenu'
import { RSVCommercialPages } from './RSVCommercialPages'

const RSVCommercialApp: IApp<RSVCommercialSlugs> = {
  name: 'Vaccines',
  slug: 'rsv-commercial',
  accessGroups: [AccessGroups.RSVCommercial],
  endpoint: 'rsv-market-and-production',
  menu: RSVCommercialMenu,
  pages: RSVCommercialPages,
  customPages: RSVCommercialCustomPages,
  createdAt: new Date(2022, 5, 21),
  defaultEntrySlug: ['eligible-population'],
}

export default RSVCommercialApp
