import { Box, Center, Checkbox, Flex, Text, VStack } from '@chakra-ui/react'
import { useMemo, useState } from 'react'

import { useAppRoute } from 'routes/utils'

import { FullPageError, LoadingAnimation, Select } from 'components'

import countryCodeAlpha2To3Mapping from 'api/data/countryCodeAlpha2To3Mapping'
import countryCodeToNameMapping from 'api/data/countryCodeToNameMapping'
import { flattenPaginatedData } from 'api/helper'
import useCollectionData, {
  FetchCollectionQueries,
} from 'api/useCollectionData'

import { RWEInfosetMediaModel } from 'config/common/baseModel/rweinfoset/RWEInfosetMedia'
import { RWEInfosetPressReleasesModel } from 'config/common/baseModel/rweinfoset/RWEInfosetPressReleases'

import { RegulatoryDashboardCard } from './RegulatoryDashboardCard'
import { IEGPolicySurveillanceAPIData } from './types'

type SortOptionValue = 'recent' | 'oldest'
type SortOptionType = { label: string; value: SortOptionValue }
const sortOptions: SortOptionType[] = [
  { label: 'Most Recent', value: 'recent' },
  { label: 'Oldest', value: 'oldest' },
]

export default () => {
  const app = useAppRoute()

  const {
    data: mediaPaginatedData,
    isLoading: mediaIsLoading,
    isError: mediaIsError,
  } = useCollectionData(app, RWEInfosetMediaModel.endpoint, {
    airtableBase: 'rweinfoset',
    view: 'api_bayer_examples_final',
  } as FetchCollectionQueries & { view: typeof RWEInfosetMediaModel['allowedAirtables'][number] })
  const {
    data: pressPaginatedData,
    isLoading: pressIsLoading,
    isError: pressIsError,
  } = useCollectionData(app, RWEInfosetPressReleasesModel.endpoint, {
    airtableBase: 'rweinfoset',
    view: 'api_bayer_examples_final',
  } as FetchCollectionQueries & { view: typeof RWEInfosetPressReleasesModel['allowedAirtables'][number] })

  const isLoading = mediaIsLoading || pressIsLoading

  const data = useMemo(() => {
    const mediaData = flattenPaginatedData(mediaPaginatedData?.pages)?.results!
    const pressData = flattenPaginatedData(pressPaginatedData?.pages)?.results!
    return mediaData.concat(pressData)
  }, [mediaPaginatedData?.pages, pressPaginatedData?.pages])

  const countriesList = useMemo(
    () =>
      Object.entries(countryCodeAlpha2To3Mapping).map(([alpha2, alpha3]) => ({
        alpha2,
        alpha3,
        countryName:
          countryCodeToNameMapping[
            alpha3 as keyof typeof countryCodeToNameMapping
          ],
      })),
    []
  )

  const finalData: IEGPolicySurveillanceAPIData[] = useMemo(
    () =>
      data.map(
        (d) =>
          ({
            category: d.category as string,
            sourceUrl: d.link as string,
            alpha2CountryCodes: countriesList
              .filter(({ countryName }) =>
                (d.country as Array<any>)?.includes(countryName)
              )
              .map((country) => country.alpha2),
            date:
              typeof d.datePublished === 'string'
                ? new Date(d.datePublished)
                : null,
            title: d.title as string,
            content: d.summary as string,
            finalContent: d.finalSummary as string,
            companies: d.companies as string[],
            metadata: {
              tags: (d.automatedTopicsCategory as string[]) ?? [],
              keywords: (d.keywordsClean as string[]) ?? [],
            },
          } as IEGPolicySurveillanceAPIData)
      ),
    [countriesList, data]
  )

  if (mediaIsError || pressIsError) {
    return <FullPageError />
  }

  return (
    <Box>
      <Text color='primary' fontSize='26px' fontWeight={600} mb='24px'>
        Dashboard
      </Text>

      <Text color='primary' fontSize='18px' fontWeight={600} mb='8px'>
        {data.length} Results
      </Text>

      {isLoading && <LoadingAnimation />}
      {!isLoading && <RegulatoryDashboardInner data={finalData} />}
    </Box>
  )
}

type RegulatoryDashboardInnerPropTypes = {
  data: IEGPolicySurveillanceAPIData[]
}
const RegulatoryDashboardInner = ({
  data,
}: RegulatoryDashboardInnerPropTypes) => {
  const [openedMetadata, setOpenedMetadata] = useState<number[]>([])
  const allChecked = openedMetadata.length === data.length
  const isIndeterminate =
    openedMetadata.length > 0 && openedMetadata.length < data.length

  const [sortByOption, setSortByOption] = useState<SortOptionType>(
    sortOptions[0]
  )

  const finalData = useMemo(
    () =>
      data.sort(
        (a, b) =>
          ((a.date?.getTime() ?? 0) - (b.date?.getTime() ?? 0)) *
          (sortByOption.value === 'oldest' ? 1 : -1)
      ),
    [data, sortByOption.value]
  )

  return (
    <>
      <Flex
        w='100%'
        justifyContent='space-between'
        alignItems='center'
        mb='20px'
        px={1}
      >
        <Checkbox
          isChecked={allChecked}
          isIndeterminate={isIndeterminate}
          onChange={(e) => {
            if (allChecked) {
              setOpenedMetadata([])
            } else {
              setOpenedMetadata(Array.from(Array(finalData.length).keys()))
            }
          }}
        >
          <Text fontSize='13px' color='primary'>
            Reveal all metadata
          </Text>
        </Checkbox>
        <Box minW='220px'>
          <Select
            value={sortByOption}
            onChange={setSortByOption}
            options={sortOptions}
          />
        </Box>
      </Flex>

      <VStack spacing='32px'>
        {finalData.map((d, i) => (
          <RegulatoryDashboardCard
            key={i}
            {...d}
            showMetadata={openedMetadata.includes(i)}
            setShowMetadata={(showMetadata) =>
              setOpenedMetadata((prev) =>
                showMetadata ? prev.concat(i) : prev.filter((x) => x !== i)
              )
            }
          />
        ))}

        {finalData.length === 0 && (
          <Center minHeight='60vh'>
            <Text>No records found.</Text>
          </Center>
        )}
      </VStack>
    </>
  )
}
