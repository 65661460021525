import { IModel } from 'interfaces/model.interface'
import { ValueType } from 'interfaces/valueType.interface'

import { SortOrders } from 'enums/SortOrders'

export type PNHMediaData =
  | 'title'
  | 'datePublished'
  | 'author'
  | 'companiesFromLinkedCandidates'
  | 'lookupCandidateName'
  | 'typeFromLinkedCandidates'
  | 'targetFromLinkedCandidates'
  | 'link'

const allowedAirtables = ['api_media'] as const

export const PNHMediaModel: IModel<
  Record<PNHMediaData, string>,
  typeof allowedAirtables
> = {
  allowedAirtables,
  displayKey: 'title',
  name: 'Media',
  entityName: 'News',
  searchField: 'title',
  endpoint: 'media',
  defaultSortKey: 'datePublished',
  defaultSortOrder: SortOrders.DESC,
  detailViewType: 'Article',
  exportDisabled: true,
  detailView: {
    link: 'link',
    abstract: 'description',
  },
  noPrint: true,
  schema: {
    columns: [
      {
        key: 'title',
        width: 500,
        showDetail: false,
        cellFormat: {
          maxLines: 3,
        },
      },
      {
        key: 'datePublished',
        type: ValueType.DATE,
        label: 'Publication date',
        width: 120,
      },
      {
        key: 'lookupCandidateName',
        label: 'Candidate Name',
        type: ValueType.MULTI,
        width: 180,
      },
      {
        key: 'targetFromLinkedCandidates',
        label: 'Drug Target',
        type: ValueType.MULTI,
        width: 180,
      },
      { key: 'link', type: ValueType.URL, label: 'URL', width: 120 },
    ],
  },
}
