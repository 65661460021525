import { WidgetProps } from 'routes/dashboard/WidgetComponents'
import WidgetComponentArticle from 'routes/dashboard/WidgetComponents/WidgetArticle'

import { IModel } from 'interfaces/model.interface'
import { ValueType } from 'interfaces/valueType.interface'

import { SortOrders } from 'enums/SortOrders'

import Tag from '../tagColours/Tag'
import Topic from '../tagColours/Topic'

export type MediaData =
  | 'title'
  | 'source'
  | 'afTopic'
  | 'datePublished'
  | 'link'
  | 'vaccine'
  | 'diagnostics'
  | 'name'
  | 'description'
  | 'language'
  | 'mediaProvider'
  | 'author'
  | 'companyResearchInstitution'
  | 'afTags'
  | 'afProject'
  | 'afUniqueTreatmentName'
  | 'id'
  | 'candidateList'
  | 'candidateName'
  | 'luDrugClass'
  | 'afSentimentAnalysis'
  | 'afSubtopic'
  | 'afIndication'
  | 'projectKeywordHit'
  | 'afRelevant'
  | 'published'
  | 'companies'
  | 'lookupCandidateName'
  | 'type'
  | 'target'
  | 'keyNewsAlert'
  | 'automatedTopicsCategory'
  | 'automatedTopicsSubCategory'
  | 'linkInfectiousDisease'
  | 'company'
  | 'candidateNameFromVaccinesCandidateList'
  | 'candidateNameFromTreatmentsCandidateList'
  | 'topics'
  | 'mediaProviderName'
  | 'indications'
  | 'candidateClass'
  | 'infectiousDisease'
  | 'grouping2'
  | 'organisations'
  | 'deliverySystem'
  | 'platform'
  | 'companiesAndResearchInstitutions'
  | 'approvalType'
  | 'designation'
  | 'linkCompany'
  | 'linkCountry'
  | 'regulatoryBody'
  | 'linkOrganisationsInvolved'
  | 'dealType'
  | 'dealValue'
  | 'linkCountryMarket'
  | 'linkRegion'
  | 'linkDiseases'

const allowedAirtables = [
  'api_app_treatments',
  'api',
  'api_variants',
  'api_igm',
  'api_multiple_myeloma_360',
  'api_lipid_360',
  'front_end_export_influenza_default_view',
  'front_end_export_influenza_vaccines_view',
  'front_end_export_influenza_treatments_view',
  'front_end_export_rsv_default_view',
  'front_end_export_rsv_vaccines_view',
  'front_end_export_rsv_treatments_view',
  'api_antithromobotics_news_articles',
  'RNA_API_Media',
  'API Front End Export',
  'api_diagnostics',
  'RNA_API_DeliverySystemsNews',
  'RNA_API_RegulatoryNews_ManufacturingApproval',
  'RNA_API_FundingNews',
  'RNA_API_RegulatoryNews_Approval',
  'RNA_API_RegulatoryNews_Investigational',
  'Obesity Media - Platform View',
  'Obesity Media - Platform View - Epidemiology',
  'Obesity Media - Platform View - Commercial',
  'Obesity Media - Platform View - Regulatory',
] as const

export const getMediaDefaultWidget = ({ view, model, title }: WidgetProps) => ({
  heading: title ?? 'Media',
  body: (
    <WidgetComponentArticle<MediaData>
      view={view}
      model={model ?? MediaModel}
      title='title'
      url='link'
      date='datePublished'
      keyNews='keyNewsAlert'
    />
  ),
})

export const MediaModel: IModel<
  Record<MediaData, string>,
  typeof allowedAirtables
> = {
  allowedAirtables,
  displayKey: 'title',
  name: 'Media',
  entityName: 'News',
  searchField: 'title',
  endpoint: 'media',
  defaultSortKey: 'datePublished',
  defaultSortOrder: SortOrders.DESC,
  detailViewType: 'Article',
  exportDisabled: true,
  detailView: {
    link: 'link',
    abstract: 'description',
  },
  noPrint: true,
  schema: {
    columns: [
      {
        key: 'title',
        width: 700,
        showDetail: false,
        cellFormat: {
          maxLines: 3,
        },
      },
      {
        key: 'source',
        type: ValueType.SINGLE,
        width: 160,
        label: 'Media Provider',
      },
      {
        key: 'afTopic',
        type: ValueType.SINGLE,
        label: 'Topic',
        width: 200,
        graphType: 'Bar',
        cellFormat: {
          colours: Topic,
        },
      },
      {
        key: 'datePublished',
        type: ValueType.DATE,
        label: 'Publication date',
        width: 120,
      },
      { key: 'link', type: ValueType.URL, label: 'Link', width: 120 },
      {
        key: 'description',
        width: 600,
        detailType: 'LONG',
        showTable: false,
        label: 'Summary',
      },
      {
        key: 'language',
        width: 100,
        type: ValueType.SINGLE,
        label: 'Language',
        graphType: 'Bar',
      },
      {
        key: 'mediaProvider',
        label: 'Media Provider',
        type: ValueType.MULTI,
        width: 230,
      },
      {
        key: 'author',
        type: ValueType.MULTI,
        label: 'Authors',
        width: 200,
        customDataFilter: (data) => {
          return data?.filter((dataItem) => dataItem.author !== null)
        },
      },
      {
        key: 'companyResearchInstitution',
        label: 'Company Research Institution',
        type: ValueType.MULTI,
        width: 270,
        graphType: 'Pie',
      },
      {
        key: 'afTags',
        label: 'Tags',
        type: ValueType.MULTI,
        width: 260,
        cellFormat: {
          colours: Tag,
        },
      },
      { key: 'afProject', label: 'Project', type: ValueType.MULTI, width: 200 },
      {
        key: 'afUniqueTreatmentName',
        label: 'Name',
        type: ValueType.MULTI,
        width: 350,
        graphType: 'Pie',
      },
      {
        key: 'candidateList',
        label: 'Candidate List',
        width: 150,
        type: ValueType.MULTI,
      },
      {
        key: 'candidateName',
        label: 'Candidate Name',
        width: 230,
        type: ValueType.MULTI,
      },
      {
        key: 'luDrugClass',
        label: 'Drug Class',
        width: 250,
        type: ValueType.MULTI,
      },
      {
        key: 'afSentimentAnalysis',
        label: 'Sentiment Analysis',
        width: 150,
        type: ValueType.MULTI,
      },
      {
        key: 'afSubtopic',
        label: 'Subtopic',
        width: 150,
        type: ValueType.MULTI,
      },
      {
        key: 'afIndication',
        label: 'Indication',
        width: 250,
        type: ValueType.MULTI,
      },
      {
        key: 'projectKeywordHit',
        label: 'Project Keyword Hit',
        width: 150,
        type: ValueType.MULTI,
      },
      {
        key: 'afRelevant',
        label: 'Relevant',
        width: 150,
        type: ValueType.MULTI,
      },
      {
        key: 'published',
        label: 'Published',
        width: 150,
        type: ValueType.TEXT,
      },
      {
        key: 'companies',
        label: 'Companies',
        width: 220,
        type: ValueType.MULTI,
      },
      {
        key: 'lookupCandidateName',
        label: 'Lookup Candidate Name',
        width: 200,
        type: ValueType.MULTI,
      },
      { key: 'type', label: 'Type', width: 180, type: ValueType.MULTI },
      { key: 'target', label: 'Target', width: 200, type: ValueType.MULTI },
      {
        key: 'keyNewsAlert',
        label: 'Key News Alert',
        width: 120,
        type: ValueType.BOOLEAN,
      },
      {
        key: 'automatedTopicsSubCategory',
        label: 'Subtopic',
        width: 150,
        type: ValueType.MULTI,
      },
      {
        key: 'automatedTopicsCategory',
        label: 'Topic',
        width: 150,
        type: ValueType.MULTI,
      },
      {
        key: 'linkInfectiousDisease',
        label: 'Infectious Disease',
        width: 200,
        type: ValueType.MULTI,
      },
      { key: 'company', label: 'Company', width: 200, type: ValueType.MULTI },
      {
        key: 'candidateNameFromVaccinesCandidateList',
        label: 'Vaccines Candidate List',
        width: 240,
        type: ValueType.MULTI,
      },
      {
        key: 'candidateNameFromTreatmentsCandidateList',
        label: 'Treatments Candidate List',
        width: 240,
        type: ValueType.MULTI,
      },
      {
        key: 'topics',
        label: 'Topic',
        width: 150,
        type: ValueType.MULTI,
      },
      {
        key: 'mediaProviderName',
        label: 'Media Provider',
        width: 150,
        type: ValueType.MULTI,
      },
      {
        key: 'indications',
        label: 'Indication',
        width: 150,
        type: ValueType.MULTI,
      },
      {
        key: 'candidateClass',
        label: 'Candidate Class',
        width: 150,
        type: ValueType.MULTI,
      },
      {
        key: 'infectiousDisease',
        label: 'Disease',
        width: 150,
        type: ValueType.MULTI,
      },
      {
        key: 'grouping2',
        label: 'Transmission Mode',
        width: 150,
        type: ValueType.MULTI,
      },
      {
        key: 'organisations',
        label: 'Company Research Institution',
        width: 150,
        type: ValueType.MULTI,
      },
      {
        key: 'deliverySystem',
        label: 'Delivery System',
        width: 150,
        type: ValueType.MULTI,
      },
      {
        key: 'platform',
        label: 'Platform',
        width: 150,
        type: ValueType.MULTI,
      },
      {
        key: 'companiesAndResearchInstitutions',
        label: 'Company & Research Institution',
        width: 150,
        type: ValueType.MULTI,
      },
      {
        key: 'approvalType',
        label: 'Approval Type',
        width: 150,
        type: ValueType.MULTI,
      },
      {
        key: 'designation',
        label: 'Designation',
        width: 150,
        type: ValueType.MULTI,
      },
      {
        key: 'linkCompany',
        label: 'Company',
        width: 150,
        type: ValueType.MULTI,
      },
      {
        key: 'linkCountry',
        label: 'Country',
        width: 150,
        type: ValueType.MULTI,
      },
      {
        key: 'regulatoryBody',
        label: 'Regulatory Body',
        width: 150,
        type: ValueType.MULTI,
      },
      {
        key: 'linkOrganisationsInvolved',
        label: 'Organisations',
        width: 150,
        type: ValueType.MULTI,
      },
      {
        key: 'dealType',
        label: 'Deal Type',
        width: 150,
        type: ValueType.MULTI,
      },
      {
        key: 'dealValue',
        label: 'Deal Value',
        width: 150,
        type: ValueType.MULTI,
      },
      {
        key: 'linkCountryMarket',
        label: 'Country Market',
        width: 150,
        type: ValueType.MULTI,
      },
      {
        key: 'linkRegion',
        label: 'Region',
        width: 150,
        type: ValueType.MULTI,
      },
      {
        key: 'linkDiseases',
        label: 'Diseases',
        width: 150,
        type: ValueType.MULTI,
      },
    ],
  },
}
