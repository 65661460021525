export default {
  15: 'rgb(214,31,38)',
  14: 'rgb(214,31,38)',
  13: 'rgb(214,31,38)',
  12: 'rgb(214,31,38)',
  11: 'rgb(214,31,38)',
  10: 'rgb(219,134,9)',
  9: 'rgb(219,134,9)',
  8: 'rgb(219,134,9)',
  7: 'rgb(219,134,9)',
  6: 'rgb(219,134,9)',
  5: 'rgb(255,211,83)',
  4: 'rgb(255,211,83)',
  3: 'rgb(255,211,83)',
  2: 'rgb(255,211,83)',
  1: 'rgb(255,211,83)',
  0: 'rgb(255,211,83)',
}
