import { SortOrders } from 'enums/SortOrders'

import {
  ClinicalTrialsModel,
  MediaModel,
  PersonModel,
  PresentationsModel,
  PressReleasesModel,
  TweetsModel,
} from 'config/common/baseModel'
import { CardioCandidateModel } from 'config/common/baseModel/cardio360/Cardio360Candidate'
import { CardioConferencePresentationsModel } from 'config/common/baseModel/cardio360/Cardio360ConferencePresentations'
import { CardioIndicationsDataModel } from 'config/common/baseModel/cardio360/Cardio360IndicationsData'

import { groupByFnDefault } from 'utils/grouping'
import { compose, renameColumn, whiteListColumn } from 'utils/overrideModel'

export const CardioAntithromboticsCandidatesModel = compose<
  typeof CardioCandidateModel
>(
  whiteListColumn<typeof CardioCandidateModel>([
    'candidateName',
    'companies',
    'candidateType',
    'target',
    'phaseInActiveClinicalTrials',
    'routeOfAdministration',
    'lookupCandidatePublications',
  ]),
  renameColumn('target', 'Mechanism of Action')
)(CardioCandidateModel)

export const CardioAntithromboticsClinicalTrialsModel = compose<
  typeof ClinicalTrialsModel
>(
  whiteListColumn<typeof ClinicalTrialsModel>([
    'trialId',
    'title',
    'acronym',
    'lookupCandidateName',
    'sponsorOrgSync',
    'phasesClean',
    'status',
    'afStartDate',
    'enrolledAt',
    'primaryCompletionDate',
    'completedAt',
    'targetEnrollment',
    'participants',
    'studyType',
    'ages',
    'locations',
    'url',
  ]),
  renameColumn('lookupCandidateName', 'Candidate'),
  renameColumn('sponsorOrgSync', 'Sponsors')
)({
  ...ClinicalTrialsModel,
  endpoint: 'clinical-trial',
  defaultSortObject: [
    { id: 'registeredAt', sortOrder: SortOrders.DESC },
    { id: 'primaryCompletionDate', sortOrder: SortOrders.DESC },
  ],
})

export const CardioAntithromboticsIndicationsDataModel = compose<
  typeof CardioIndicationsDataModel
>(
  whiteListColumn<typeof CardioIndicationsDataModel>([
    'parentOrChild',
    'drugName',
    'organisation',
    'indication',
    'guidelineSource',
    'prerequisites',
    'doseSizeMg',
    'dosing',
    'durationOfTreatmentDays',
    'factorsModulatingTreatmentDuration',
    'factorsModulatingDose',
    'alteredDoseMg',
    'contraindications',
    'source',
  ])
)({
  ...CardioIndicationsDataModel,
  grouping: {
    groupBy: 'drugName',
    groupByFn: groupByFnDefault,
    parentColumns: ['drugName'],
  },
})

export const CardioAntithromboticsPublicationsModel = compose<
  typeof PresentationsModel
>(
  whiteListColumn<typeof PresentationsModel>([
    'title',
    'journalTitle',
    'publicationDate',
    'lookupCandidateList',
    'afTopics',
    'afArticleType',
    'afSentiment',
    'linkAfLeadAuthor',
    'linkAfLastAuthor',
    'doiUrl',
  ])
)({ ...PresentationsModel, endpoint: 'publication', name: 'Publications' })

export const CardioAntithromboticsConferencePresentationsModel = compose<
  typeof CardioConferencePresentationsModel
>(
  whiteListColumn([
    'titleClean',
    'eventName',
    'sessionName',
    'lookupMappedSessionType',
    'linkOrganisation',
    'descriptionClean',
    'candidateList',
    'afTopics',
    'afSentiment',
    'presentingAuthor',
    'afIndependent',
  ])
)(CardioConferencePresentationsModel)

export const CardioAntithromboticsPressReleasesModel = whiteListColumn<
  typeof PressReleasesModel
>([
  'title',
  'datePublished',
  'lookupCandidateName',
  'lookupDrugTarget',
  'link',
])({
  ...PressReleasesModel,
  detailView: {
    link: 'link',
    abstract: 'description',
  },
})

export const CardioAntithromboticsTweetsModel = whiteListColumn<
  typeof TweetsModel
>([
  'createdAt',
  'fullText',
  'tweetUrl',
  'candidateName',
  'persons',
  'afTopics',
  'afSentiment',
  'followers',
  'luHIndex',
])(TweetsModel)

export const CardioAntithromboticsKolsModel = whiteListColumn<
  typeof PersonModel
>([
  'afName',
  'afHIndex',
  'affiliations',
  'country',
  'afInvestigatorOrCommitteeMemberOfClinicalTrial',
  'leadAuthorOfPublication',
  'lastAuthorOfPublication',
  'lookupFullTextFromTweets',
  'lookupTrialFromInvestigators',
  'clinicalTrialsSponsor',
  'lookupTitleFromConferencePresentations2',
])(PersonModel)

export const CardioAntithromboticsNewsModel = compose<typeof MediaModel>(
  whiteListColumn([
    'title',
    'datePublished',
    'lookupCandidateName',
    'target',
    'link',
  ]),
  renameColumn('lookupCandidateName', 'Candidate Name')
)(MediaModel)
