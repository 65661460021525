// For more information on data-driven styles, see https://www.mapbox.com/help/gl-dds-ref/
export const dataLayer = (highestNum?: number) => ({
  id: 'data',
  type: 'fill',
  paint: {
    'fill-color': {
      property: 'count',
      stops: highestNum
        ? createColorScale([
            0,
            Math.max(1, Math.ceil(highestNum * 0.1)),
            Math.max(2, Math.ceil(highestNum * 0.2)),
            Math.max(3, Math.ceil(highestNum * 0.3)),
            Math.max(4, Math.ceil(highestNum * 0.4)),
            Math.max(5, Math.ceil(highestNum * 0.5)),
            Math.max(6, Math.ceil(highestNum * 0.6)),
            Math.max(7, Math.ceil(highestNum * 0.7)),
            Math.max(8, Math.ceil(highestNum * 0.8)),
            highestNum,
          ])
        : [
            [0, '#f7fbff'],
            [20, '#deebf7'],
            [40, '#c6dbef'],
            [60, '#9ecae1'],
            [80, '#6baed6'],
            [100, '#4292c6'],
            [120, '#2171b5'],
            [140, '#08519c'],
            [160, '#08306b'],
          ],
    },
    'fill-opacity': 0.8,
  },
})

interface ColorScaleOptions {
  minColor?: [number, number, number]
  maxColor?: [number, number, number]
}
type ColorStop = [number, string]

const createColorScale = (
  dataValues: number[],
  options: ColorScaleOptions = {}
): ColorStop[] => {
  const sortedValues = [...new Set(dataValues)].sort((a, b) => a - b)
  const minValue = sortedValues[0]
  const maxValue = sortedValues[sortedValues.length - 1]

  const breakpoints: number[] = [
    0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1,
  ]

  const colorScale: ColorStop[] = breakpoints.map((point) => {
    if (point === 0) {
      return [0, '#ffffff'] // White for 0 values
    }
    const scaledValue =
      Math.exp(point * Math.log(maxValue + 1 - minValue)) + minValue - 1
    return [
      Math.max(1, Math.ceil(scaledValue / 5) * 5),
      getColor(point, options),
    ]
  })

  const uniqueValuesColorScale = colorScale.reduce(
    (acc, [key, value]) => {
      if (!acc[key]) {
        acc[key] = [key, value]
      }
      return acc
    },
    {} as Record<number, ColorStop>
  )

  return Object.values(uniqueValuesColorScale)
}

const getColor = (t: number, options: ColorScaleOptions): string => {
  const minColor = '#41b6c4' // Bright, vibrant light blue
  const maxColor = '#253494' // Deep, saturated dark blue

  const parseHexColor = (hex: string): [number, number, number] => {
    const r = parseInt(hex.slice(1, 3), 16)
    const g = parseInt(hex.slice(3, 5), 16)
    const b = parseInt(hex.slice(5, 7), 16)
    return [r, g, b]
  }

  const [rStart, gStart, bStart] = parseHexColor(minColor)
  const [rEnd, gEnd, bEnd] = parseHexColor(maxColor)

  const r = Math.round(rStart + (rEnd - rStart) * t)
  const g = Math.round(gStart + (gEnd - gStart) * t)
  const b = Math.round(bStart + (bEnd - bStart) * t)

  return `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`
}
