import { VStack, Text } from '@chakra-ui/react'
import { isValid } from 'date-fns'

import { calculateStatColor } from 'utils/calculateStatColor'
import { formatUtc } from 'utils/formatDate'

export type StatsCellPropTypes = {
  totalCount: number
  deltaCount: number
  lastUpdated?: Date
}

export const StatsCell = ({
  totalCount,
  deltaCount,
  lastUpdated,
}: StatsCellPropTypes) => {
  return (
    <VStack
      w='100%'
      spacing={0}
      py={1}
      minHeight='57px'
      justifyContent='center'
    >
      <Text
        fontSize='lg'
        fontWeight='bold'
        color={calculateStatColor(deltaCount)}
      >
        {deltaCount === null ? (
          '-'
        ) : (
          <>
            {deltaCount >= 0 ? '+ ' : '- '}
            {Math.abs(deltaCount)}
          </>
        )}
      </Text>
      {totalCount !== null && (
        <Text fontSize='sm' color='gray.800'>
          {totalCount}
        </Text>
      )}
      {lastUpdated && isValid(lastUpdated) && (
        <Text fontSize='xs' color='gray.500'>
          Updated {formatUtc(lastUpdated, 'yyyy-MM-dd')}
        </Text>
      )}
    </VStack>
  )
}
