import { INavigationMenu } from 'interfaces/navigationMenu.interface'

export type IDA360Slugs =
  | ''
  | 'candidates'
  | 'dashboard'
  | 'table'
  | 'pipeline'
  | 'trials'
  | 'map'
  | 'approvals'
  | 'reports'
  | 'map'
  | 'revenue'
  | 'supply-price'
  | 'price-dashboard'
  | 'supply-dashboard'
  | 'news-press-releases'
  | 'revenue-dashboard'
  | 'research-briefs'

const IDA360Menu: Array<INavigationMenu<IDA360Slugs>[]> = [
  [
    {
      title: 'Latest Information',
      slug: '',
      createdAt: new Date(2024, 2, 20),
    },
  ],
  [
    {
      title: 'Candidates',
      slug: 'candidates',
      createdAt: new Date(2024, 8, 6),
    },
    {
      title: 'Trials',
      slug: 'trials',
      createdAt: new Date(2024, 9, 18),
      newUntil: new Date(2024, 10, 18),
    },
    {
      title: 'Approvals',
      slug: 'approvals',
      createdAt: new Date(2022, 10, 24),
      children: [
        {
          title: 'Table',
          slug: 'table',
          createdAt: new Date(2022, 10, 24),
        },
        {
          title: 'Map',
          slug: 'map',
          createdAt: new Date(2023, 2, 17),
        },
      ],
    },
    {
      title: 'News & Press Releases',
      slug: 'news-press-releases',
      createdAt: new Date(2022, 10, 24),
    },
    {
      title: 'Revenue',
      slug: 'revenue',
      createdAt: new Date(2022, 10, 24),
      children: [
        {
          title: 'Table',
          slug: 'table',
          createdAt: new Date(2022, 10, 24),
        },
        {
          title: 'Revenue Dashboard',
          slug: 'revenue-dashboard',
          createdAt: new Date(2022, 10, 24),
        },
      ],
    },
    {
      title: 'Supply & Price',
      slug: 'supply-price',
      createdAt: new Date(2022, 10, 24),
      children: [
        {
          title: 'Table',
          slug: 'table',
          createdAt: new Date(2024, 8, 6),
        },
        {
          title: 'Supply Dashboard',
          slug: 'supply-dashboard',
          createdAt: new Date(2022, 10, 24),
        },
        {
          title: 'Price Dashboard',
          slug: 'price-dashboard',
          createdAt: new Date(2022, 10, 24),
        },
      ],
    },
    {
      title: 'Reports',
      slug: 'reports',
      createdAt: new Date(2023, 1, 10),
      children: [
        { title: 'Reports', slug: 'reports', createdAt: new Date(2023, 1, 10) },
        {
          title: 'Research Briefs',
          slug: 'research-briefs',
          createdAt: new Date(2023, 1, 10),
        },
      ],
    },
  ],
]

export { IDA360Menu }
