import { ChevronDown } from '@carbon/icons-react'
import { Box } from '@chakra-ui/react'
import { memo } from 'react'

import { Dropdown, DropdownMenuItem } from 'components'

import { PAGINATION_SIZES } from 'config/common/model/state'

type PropTypes = {
  setPageSize: (size: number) => void
  currentPageSize: number
}

const PageSizeButton = memo(({ setPageSize, currentPageSize }: PropTypes) => {
  return (
    <Box mr={2} display='flex' alignItems='center'>
      <Box mr={2} fontSize='13px' color='gray1' fontWeight={500}>
        Rows per page:
      </Box>
      <Dropdown
        closeOnClick
        positions={['top', 'right']}
        padding={2}
        content={
          <Box
            boxShadow='-3px 3px 20px rgba(0, 0, 0, 0.09)'
            bg='white'
            border='1px solid'
            borderColor='gray3'
            borderTopRadius='4px'
            borderBottom='none'
          >
            {PAGINATION_SIZES.map((size) => {
              return (
                <DropdownMenuItem
                  key={size}
                  isSelected={size === currentPageSize}
                  onClick={() => setPageSize(size)}
                  fontWeight={size === currentPageSize ? 500 : 400}
                  color='black'
                  bg='transparent'
                  py={3}
                  px='16px'
                  fontSize='13px'
                  textAlign='center'
                  borderBottom='1px solid'
                  borderColor='gray3'
                  width='58px'
                  _hover={{
                    bg: 'gray4',
                  }}
                >
                  {size}
                </DropdownMenuItem>
              )
            })}
          </Box>
        }
      >
        <Box
          fontSize='12px'
          border='1px solid'
          borderColor='gray3'
          color='black'
          bg='white'
          py={2}
          px={3}
          display='flex'
          alignItems='center'
          cursor='pointer'
          borderRadius='4px'
          fontWeight={500}
        >
          {currentPageSize}
          <Box ml={2}>
            <ChevronDown size={16} />
          </Box>
        </Box>
      </Dropdown>
    </Box>
  )
})

export default PageSizeButton
