import { Menu as MenuIcon } from '@carbon/icons-react'
import { Box, Collapse, IconButton, Text, Link, Flex } from '@chakra-ui/react'
import { NAV_HEIGHT } from 'constants/misc'
import { useContext, useState } from 'react'
import { useAuth } from 'react-oidc-context'
import OutsideClickHandler from 'react-outside-click-handler'
import { useLocation } from 'react-router-dom'
import { Link as ReactRouterLink } from 'react-router-dom'

import { useAppParams, useAppRoute } from 'routes/utils'

import { AuthContext } from 'contexts'

import useTracking from 'tracking/useTracking'

import { apps, appsListUnion } from 'config/apps'

import { Logout as LogOutAction } from 'utils/logOut'
import resolvePath from 'utils/resolvePath'

import { Logo } from './Logo'
import { MenuLink, MenuButtonLink } from './RightMenu'
import { useClientType } from 'contexts/ClientType'

type PropTypes = {
  showSearchBar?: boolean
  onBackButton?: () => void
  customLinkArr?: { name: string; href: string }[]
}

const MobileNavigation = ({
  showSearchBar = true,
  onBackButton,
  customLinkArr,
}: PropTypes) => {
  const {
    appletGroupsUserCanSee,
    appletsUserCanSee,
    appListUserCanAccess,
    userCanAccessTutorial,
  } = useContext(AuthContext)

  const { isGov } = useClientType()

  const appParams = useAppParams()
  const [isOpen, setIsOpen] = useState(false)

  const [tracking] = useTracking()
  const location = useLocation()

  const isHome = location.pathname === '/'

  // const { overlayShown, reset, setOverlayShown } = useGlobalSearch()

  const selectedAppSlug = appParams.app as appsListUnion
  const selectedApp = apps[selectedAppSlug]
  const selectedAppFromRoute = useAppRoute()

  const selectedApplet = Object.values(appletsUserCanSee).find((applet) =>
    applet.apps.includes(selectedAppSlug)
  )

  const selectedAppletGroup = Object.values(appletGroupsUserCanSee).find(
    (appletGroup) => appletGroup.applets.includes(selectedApplet?.key!)
  )

  const appsWithTutorials = Object.values(appListUserCanAccess).filter(
    (app) => !!app.tutorial
  )
  const appsWithTutorialSlugs = appsWithTutorials.map((app) => app.slug)
  const showTutorials =
    appsWithTutorialSlugs.includes(selectedAppFromRoute) &&
    userCanAccessTutorial
  const auth = useAuth()
  return (
    <Box
      height={NAV_HEIGHT}
      minHeight={NAV_HEIGHT}
      bg='black'
      borderBottom='4px solid'
      borderColor='yellow.500'
      color='white'
      display='flex'
      alignItems='center'
      justifyContent={isHome ? 'center' : 'space-between'}
      position={'relative'}
    >
      <Flex alignItems={'center'} bg='black' w='fit-content' h='44px'>
        <Box
          width={!isHome ? '70px' : '100px'}
          ml={isHome ? '0px' : '20px'}
          mt='6px'
          mr={-4}
        >
          <ReactRouterLink
            onClick={() => !isHome && tracking.navigatedToHomePage({})}
            to='/'
          >
            <Logo color={'white'} />
          </ReactRouterLink>
        </Box>
      </Flex>
      <OutsideClickHandler
        display='contents'
        onOutsideClick={() => {
          setIsOpen(false)
        }}
      >
        {!isHome && selectedApplet && (
          <Flex
            alignItems={'center'}
            bgColor='yellow.500'
            h='full'
            px={2}
            cursor='pointer'
            onClick={() => setIsOpen((x) => !x)}
            color='black'
          >
            <Text fontSize='sm' fontWeight={500}>
              {selectedApplet?.name}
            </Text>
          </Flex>
        )}
        <IconButton
          position={isHome ? 'absolute' : 'initial'}
          right={0}
          icon={<MenuIcon size={20} />}
          aria-label='right-menu'
          display='flex'
          alignItems='center'
          overflow='hidden'
          color='white'
          height='100%'
          cursor='pointer'
          onClick={() => setIsOpen((x) => !x)}
          variant='ghost'
          _hover={{
            bg: 'none',
          }}
          _active={{
            bg: 'none',
          }}
          _focus={{
            boxShadow: 'none',
          }}
          ml={3}
          mr={6}
        />
        <Collapse
          in={isOpen}
          style={{
            position: 'absolute',
            left: 0,
            right: 0,
            top: NAV_HEIGHT,
            zIndex: 900,
          }}
          animateOpacity={false}
        >
          <Box bgColor={'brand'} pb={'10px'}>
            <Box left={0} right={0} bgColor='brand'>
              <Text px={4} py={2} fontWeight={'bold'} fontSize={'18px'}>
                {selectedAppletGroup?.name}
              </Text>

              {customLinkArr && customLinkArr.length > 0
                ? customLinkArr.map(({ name, href }, i) => (
                    <Link
                      key={i}
                      _hover={{ textDecor: 'none' }}
                      as={ReactRouterLink}
                      to={href}
                      alignItems={'center'}
                      px={8}
                      py={2}
                      display='flex'
                      fontSize='md'
                      fontWeight={500}
                      cursor='pointer'
                      backgroundColor={
                        location.pathname === href.split('?')[0]
                          ? 'yellow.500'
                          : ''
                      }
                      color={
                        location.pathname === href.split('?')[0] ? 'black' : ''
                      }
                    >
                      {name}
                    </Link>
                  ))
                : selectedAppletGroup?.showApplets && (
                    <>
                      {selectedAppletGroup.applets.map((appletKey, i) => {
                        const applet = appletsUserCanSee[appletKey]
                        const appletsPath =
                          applet?.apps.find(
                            (app) =>
                              apps[app].commonIdentifier ===
                              selectedApp.commonIdentifier
                          ) ?? applet?.apps[0]

                        const defaultEntryPoint =
                          apps[appletsPath as appsListUnion].defaultEntrySlug ??
                          ''
                        const defaultEntryPointUrlPath = defaultEntryPoint
                          ? resolvePath([defaultEntryPoint])
                          : ''

                        return (
                          <Link
                            key={i}
                            _hover={{ textDecor: 'none' }}
                            as={ReactRouterLink}
                            to={`/${appletsPath}${defaultEntryPointUrlPath}`}
                            alignItems={'center'}
                            px={8}
                            py={2}
                            display='flex'
                            fontSize='md'
                            fontWeight={500}
                            cursor='pointer'
                            backgroundColor={
                              applet?.key === selectedApplet?.key
                                ? 'yellow.500'
                                : ''
                            }
                            color={
                              applet?.key === selectedApplet?.key ? 'black' : ''
                            }
                          >
                            {applet?.name}
                          </Link>
                        )
                      })}
                      <hr
                        style={{
                          marginTop: '5px',
                          marginBottom: '5px',
                          borderColor: '#454545',
                        }}
                      />
                    </>
                  )}
            </Box>

            <Box
              left={0}
              mt={5}
              textColor={'gray.300'}
              right={0}
              bgColor='brand'
            >
              <MenuLink to={`/bookmarks?ref=${location.pathname}`}>
                Bookmarks
              </MenuLink>
              <MenuLink
                to={`/settings?ref=${location.pathname}`}
                onClick={() => tracking.openSettings({})}
              >
                Settings
              </MenuLink>
              {showTutorials && (
                <MenuLink
                  to={`/tutorial/${selectedApp}`}
                  onClick={() => tracking.openTutorial({})}
                >
                  Tutorials
                </MenuLink>
              )}
              <MenuButtonLink onClick={() => LogOutAction(auth, isGov)}>
                Logout
              </MenuButtonLink>
            </Box>
          </Box>
        </Collapse>
      </OutsideClickHandler>
    </Box>
  )
}

export default MobileNavigation
