import { Box } from '@chakra-ui/react'

import { DetailDrawerHeader } from 'modules/DetailDrawer'
import Details from 'modules/Summary'

import { InlineLoading, SlideOver } from 'components'

import { IResponseBase } from 'api/types'

import { IModel } from 'interfaces/model.interface'
import { ViewData } from 'interfaces/navigationPage.interface'

import { relationGetDisplayValue } from 'utils/relational'

type PropTypes<D extends Record<string, any> = Record<string, any>> = {
  entry?: IResponseBase<Extract<keyof D, string>>
  title?: Extract<keyof D, string>
  model: IModel<D>
  width?: any
  isLoading?: boolean
  handleClose?: () => void
  viewData: ViewData
}

function GenericDrawer<D extends Record<string, any> = Record<string, any>>({
  entry,
  title,
  model,
  width,
  isLoading,
  handleClose,
  viewData,
}: PropTypes<D>) {
  return (
    <SlideOver show={true} {...(width ? { width } : {})}>
      {isLoading || !entry ? (
        <InlineLoading />
      ) : (
        <>
          <DetailDrawerHeader
            title={title ? relationGetDisplayValue(entry[title]) : ''}
            handleClose={handleClose}
          />
          <Box overflowY='scroll' p={6} overflowX='hidden'>
            <Details
              data={entry}
              model={model}
              titleKey={title}
              viewData={viewData}
            />
          </Box>
        </>
      )}
    </SlideOver>
  )
}

export default GenericDrawer
