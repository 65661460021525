import { IApp } from 'interfaces/navigationApp.interface'

import { AccessGroups } from 'enums/AccessGroups'

import { InfluenzaEpidemiologyCustomPages } from 'config/apps/Influenza/Epidemiology/Epidemiology/InfluenzaEpidemiologyCustomPages'
import {
  InfluenzaEpidemiologyMenu,
  InfluenzaEpidemiologySlugs,
} from 'config/apps/Influenza/Epidemiology/Epidemiology/InfluenzaEpidemiologyMenu'

const ModernaInfluenzaEpidemiologyApp: IApp<InfluenzaEpidemiologySlugs> = {
  name: 'Influenza',
  slug: 'moderna-influenza-epidemiology',
  accessGroups: [AccessGroups.Moderna],
  commonIdentifier: 'influenza',
  endpoint: 'influenza',
  menu: InfluenzaEpidemiologyMenu,
  pages: {},
  customPages: InfluenzaEpidemiologyCustomPages,
  createdAt: new Date(2022, 5, 30),
}

export default ModernaInfluenzaEpidemiologyApp
