import { Box, Text, Flex } from '@chakra-ui/layout'

import { alertColors, alertLevel } from 'modules/Alerts'

import { AlertType } from 'api/cms/alerts/useCountryAlerts'

import useIsMobile from 'utils/useIsMobile'

const alertOrder = [
  'HR',
  'MR',
  'LR',
  'NR',
  'UR',
  'AI',
  'ZR',
  'IN',
  'WA',
] as AlertType[]

const Legend = () => {
  const [isMobile] = useIsMobile()
  return (
    <Box
      display={'flex'}
      w='100%'
      justifyContent={'flex-end'}
      flexDir={isMobile ? 'column' : 'row'}
    >
      <Text variant={'body-bold'}>Risk Type: </Text>
      <Flex flexWrap={isMobile ? 'wrap' : 'nowrap'}>
        {alertOrder.map((type, index) => {
          const color = alertColors[type]
          const content = alertLevel[type]
          return (
            <Box
              minW={isMobile ? '122px' : ''}
              ml={isMobile ? '0' : '8px'}
              key={index}
              display='flex'
              justifyContent={'flex-start'}
              alignItems='center'
            >
              <Box
                color={`${color}`}
                bg={`${color}`}
                w='40px'
                h='20px'
                border='1px solid black'
              />
              <Text variant={'body'} ml='4px'>
                {content}
              </Text>
            </Box>
          )
        })}
      </Flex>
    </Box>
  )
}

export default Legend
