import { Box, Text } from '@chakra-ui/react'

import { ItemComponentPropTypes } from 'modules/DetailDrawer'

import { IDTreatments360DealsData } from 'config/common/baseModel/ida360/IDPremiumDeals'

import { formatDate } from 'utils/formatDate'

const DetailDrawerListDeal = ({
  item,
  handleSelect,
}: ItemComponentPropTypes<Record<IDTreatments360DealsData, any>>) => {
  const {
    recipients,
    suppliers,
    recipientRegion,
    quantity,
    quantityUnit,
    pricePerUnitUsd,
    dateStart,
  } = item

  const location = Array.isArray(recipients)
    ? recipients?.join(', ')
    : recipients

  return (
    <Box
      cursor='pointer'
      px={8}
      py={4}
      display='flex'
      flexDirection='column'
      onClick={() => handleSelect(item)}
      borderBottom='1px solid'
      borderColor='gray.100'
      _hover={{
        bg: 'gray.50',
      }}
    >
      <Box display='flex' alignItems='center'>
        <Box fontSize='base' fontWeight='500'>
          {location || recipientRegion}
        </Box>
      </Box>
      <Text fontSize='xs' fontWeight={600} color='gray.700'>
        Supplier: {Array.isArray(suppliers) ? suppliers?.join(', ') : suppliers}
      </Text>
      <Text fontSize='xs' fontWeight={600} color='gray.700'>
        Quantity: {quantity} {quantityUnit}
      </Text>
      <Text fontSize='xs' fontWeight={600} color='gray.700'>
        Price per Unit (USD): {pricePerUnitUsd}
      </Text>
      <Text fontSize='xs' fontWeight={600} color='gray.700'>
        Date Start: {formatDate(dateStart)}
      </Text>
    </Box>
  )
}

export { DetailDrawerListDeal }
