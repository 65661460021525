import React, { useContext, useRef } from 'react'

type ProviderType = {
  mainBodyRef: React.RefObject<HTMLDivElement> | null
}

export const MainBodyContainerContext = React.createContext<ProviderType>({
  mainBodyRef: null,
})

export const useMainBodyContainer = (): ProviderType => {
  return useContext(MainBodyContainerContext)
}

export const MainBodyContainerConsumer = MainBodyContainerContext.Consumer

// This provider is use to provide the ref of the main body container
// It's useful for virtualized window scrolling
export const MainBodyContainerProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const mainBodyRef = useRef<HTMLDivElement>(null)

  return (
    <MainBodyContainerContext.Provider
      value={{
        mainBodyRef,
      }}
    >
      {children}
    </MainBodyContainerContext.Provider>
  )
}
