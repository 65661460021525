import { Center, Text } from '@chakra-ui/react'

export default () => {
  return (
    <Center height='100%' mt='1rem'>
      <Text fontSize='19px' fontWeight='semibold' color='gray2' margin='auto'>
        Your results will show here
      </Text>
    </Center>
  )
}
