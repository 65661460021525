import { Heading, Box, Text } from '@chakra-ui/react'
import moment from 'moment'

import { ClinicalTrialsData } from 'config/common/baseModel'

import { formatDate } from 'utils/formatDate'

import { ItemComponentPropTypes } from '.'

export const DetailDrawerListTrial = ({
  item,
  handleSelect,
}: ItemComponentPropTypes<Record<ClinicalTrialsData, any>>) => {
  const {
    completedAt,
    title,
    phasesClean,
    govId,
    id,
    clinicalTrialId,
    latestPhaseFinal,
    fStudyCompletion,
  } = item

  const completeDate = completedAt
    ? moment(completedAt)
    : fStudyCompletion
    ? moment(fStudyCompletion)
    : null

  return (
    <Box
      px={8}
      py={4}
      cursor='pointer'
      borderBottom='1px solid'
      borderColor='gray.100'
      onClick={() => handleSelect(item)}
      _hover={{
        bg: 'gray.100',
      }}
    >
      <Box
        display='flex'
        alignItems='center'
        fontSize='xs'
        fontWeight={600}
        textTransform='uppercase'
        color='gray.700'
      >
        <Box mr={2}>
          {completeDate?.isValid()
            ? `Completes on ${formatDate(completeDate)}`
            : `Complete date unknown`}
        </Box>
      </Box>
      {(govId || id) && (
        <Text fontSize='xs' fontWeight={600} color='gray.700'>
          Clinical Trial ID: {clinicalTrialId || govId || id}
        </Text>
      )}
      {(phasesClean || latestPhaseFinal) && (
        <Text fontSize='xs' fontWeight={600} color='gray.700'>
          Phase: {phasesClean || latestPhaseFinal}
        </Text>
      )}
      <Heading fontSize='md' fontWeight={500} mt={1}>
        {title}
      </Heading>
    </Box>
  )
}
