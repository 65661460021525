import { UseMutationOptions, useMutation } from '@tanstack/react-query'
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios'

import { useAppRoute } from 'routes/utils'

import { apps } from 'config/apps'

import useAxios from './useAxios'

export default function useUpSellReport(
  extraProps?: UseMutationOptions<AxiosResponse<any>, AxiosError<any>, string>
) {
  const axios = useAxios()

  // now we get the app slug from the context

  const app = useAppRoute()
  const selectedApp = apps[app]

  const slug = selectedApp.endpoint

  return useMutation<AxiosResponse<any>, AxiosError<any>, string>(
    (details) => upSellReport(axios, slug, details),
    extraProps
  )
}

export const upSellReport = async (
  axios: AxiosInstance,
  slug: string,
  details: string
) => {
  return await axios.post(`/${slug}/upgrade/`, {
    details,
  })
}
