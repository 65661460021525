import { Add, TrashCan } from '@carbon/icons-react'
import { Box } from '@chakra-ui/react'
import { capitalize } from 'lodash'
import { createElement, useCallback } from 'react'

import { Select } from 'components'

import { IResponseBase } from 'api/types'

import { IModel } from 'interfaces/model.interface'

import { SimpleFilterInput } from './FilterComponents'
import {
  FilterTypeKeys,
  getAllowedFilterType,
  getDefaultValueFromFilterTypeKey,
} from './filters'
import { allFilterTypesWithComponents } from './filtersWithComponents'
import { functionalUpdate } from './reactTablePublicUtils'
import {
  AdvancedFilterUpdaterProp,
  SingleAdvancedFilter,
} from './useAdvancedFilters'

export const InputHeight = '36px'

type PropTypes<D extends Object> = {
  model: IModel<D, any>
  data: IResponseBase<any>[]
  advancedFilters: SingleAdvancedFilter<D>[]
  setAdvancedFilters: (updater: AdvancedFilterUpdaterProp) => void
}

export default function AdvancedSearch<D extends Object>({
  model,
  data,
  advancedFilters,
  setAdvancedFilters,
}: PropTypes<D>) {
  // Creates a function that only modify the specific filter
  const setAdvancedFilterPrep = useCallback(
    (i: number) => {
      return (
        newVal: (
          prevFilterValue: SingleAdvancedFilter<D>
        ) => SingleAdvancedFilter<D>
      ) =>
        setAdvancedFilters((prevAdvancedFilter) => {
          const newFilter = [...prevAdvancedFilter]
          newFilter[i] = newVal(newFilter[i])
          return newFilter
        })
    },
    [setAdvancedFilters]
  )

  return (
    <Box>
      {advancedFilters.length > 0 ? (
        advancedFilters.map((advancedFilter, i) => (
          <FilterRow
            key={i}
            model={model}
            data={data}
            advancedFilter={advancedFilter}
            setFilter={setAdvancedFilterPrep(i)}
            removeFilter={() => {
              setAdvancedFilters((prevAdvancedFilter) =>
                prevAdvancedFilter.filter((_, j) => j !== i)
              )
            }}
          />
        ))
      ) : (
        <Box color='gray.400' fontSize='sm'>
          No filters set.
        </Box>
      )}

      <Box mb='16px' />

      <Box
        _focus={{
          outline: 'none',
          boxShadow: 'none',
        }}
        borderRadius='4px'
        border='1px solid'
        borderColor='primary'
        height='40px'
        fontSize='base'
        lineHeight='normal'
        px={2}
        display='flex'
        alignItems='center'
        cursor='pointer'
        justifyContent='center'
        fontWeight={600}
        color='black'
        onClick={() => {
          const column = model.schema.columns[0].key as string
          const type = getAllowedFilterType(model.schema.columns[0].type)[0].key
          setAdvancedFilters((prevAdvancedFilter) => [
            ...prevAdvancedFilter,
            {
              column,
              type,
              filterValue: getDefaultValueFromFilterTypeKey(type),
            },
          ])
        }}
      >
        <Box mr={1}>
          <Add size={20} />
        </Box>
        Add Filter
      </Box>
    </Box>
  )
}

type FilterRowPropType<D extends Object> = {
  model: IModel<D, any>
  data: IResponseBase<any>[]
  advancedFilter: SingleAdvancedFilter<D>
  setFilter: (
    newVal: (
      prevFilterValue: SingleAdvancedFilter<D>
    ) => SingleAdvancedFilter<D>
  ) => void
  removeFilter: () => void
}

export function FilterRow<D extends Object>({
  model,
  data,
  advancedFilter,
  setFilter,
  removeFilter,
}: FilterRowPropType<D>) {
  const columnOptions = model.schema.columns.map((column) => ({
    label: column.label || capitalize(column.key),
    value: column.key,
  }))

  const currentColumnLabel = columnOptions.find(
    (x) => x.value === advancedFilter.column
  )?.label

  const allowedFiltersBasedOnColumn = getAllowedFilterType(
    model.schema.columns.find((column) => column.key === advancedFilter.column)
      ?.type
  )

  const filtersOptions = allowedFiltersBasedOnColumn.map((allowedFilter) => ({
    label: allowedFilter.label || capitalize(allowedFilter.key),
    value: allowedFilter.key,
  }))

  const currentFilterLabel = filtersOptions.find(
    (x) => x.value === advancedFilter.type
  )?.label

  const spacing = '16px'

  return (
    <Box display='flex' mt={spacing}>
      <Box mr={spacing} flex={1}>
        <Select
          value={{ label: currentColumnLabel, value: advancedFilter.column }}
          height={InputHeight}
          onChange={(x: any) => {
            // @ts-ignore
            const newVal = x?.value
            const type = getAllowedFilterType(
              model.schema.columns.find((x) => x.key === newVal)?.type
            )[0].key

            setFilter((prevFilter) => {
              return {
                ...prevFilter,
                column: newVal,
                type,
                filterValue: getDefaultValueFromFilterTypeKey(type),
              }
            })
          }}
          options={columnOptions}
        />
      </Box>
      <Box mr={spacing} flex={1}>
        <Select
          height={InputHeight}
          value={{ label: currentFilterLabel, value: advancedFilter.type }}
          onChange={(x: any) => {
            // @ts-ignore
            const newVal = x?.value as FilterTypeKeys

            setFilter((prevFilter) => {
              return {
                ...prevFilter,
                type: newVal,
                filterValue: getDefaultValueFromFilterTypeKey(newVal),
              }
            })
          }}
          options={filtersOptions}
        />
      </Box>
      <Box mr={spacing} flex={1}>
        {createElement(
          allFilterTypesWithComponents.find(
            (x) => x.key === advancedFilter.type
          )?.component ?? SimpleFilterInput,
          {
            data,
            advancedFilter,
            filterValue: advancedFilter.filterValue,
            setFilterValue: (updater) => {
              setFilter((prevFilter) => ({
                ...prevFilter,
                filterValue: functionalUpdate(updater, prevFilter.filterValue),
              }))
            },
          }
        )}
      </Box>
      <Box
        onClick={() => {
          removeFilter()
        }}
        display='flex'
        alignItems='center'
        justifyContent='center'
        minWidth={InputHeight}
        height={InputHeight}
        rounded='md'
        cursor='pointer'
        color='error'
        _hover={{
          bg: 'gray.100',
        }}
      >
        <TrashCan size={16} />
      </Box>
    </Box>
  )
}
